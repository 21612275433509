// Dependancies
import { combineEpics } from "redux-observable";

// Epics
import { interactionEpics } from "./interactionEpics";
import { offlineTasksEpics } from "./offlineTasksEpics";
import { readerEpics } from "./readerEpics";
import { guidedReadingEpics } from "./guidedReadingEpics";
import { tasksEpics } from "./tasksEpics";
import { textEpics } from "./textEpics";
import { eMentorEpics } from "./eMentorEpics";

export const rootEpic = combineEpics(
  interactionEpics,
  offlineTasksEpics,
  readerEpics,
  guidedReadingEpics,
  tasksEpics,
  textEpics,
  eMentorEpics
);
