import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { Prompt } from "react-router-dom";
import { useIntl } from "react-intl";
import clsx from "clsx";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import makeStyles from "@mui/styles/makeStyles";
import { enqueueFlashMessage } from "../../../redux/userSlice";
import ClearIcon from "@mui/icons-material/Clear";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from "@mui/material";
import { selectTextDirection } from "../../../redux/firestoreSelectors";

const useStyles = makeStyles(theme => ({
  // this is styling the paper component within the dialog
  modal: {
    position: "relative",
    width: "50%",
    padding: "10px",
    textAlign: "center"
  },
  modalCancel: {
    position: "absolute",
    top: "10px",
    right: "10px",
    cursor: "pointer"
  },
  modalActions: {
    justifyContent: "center",
    "& button": {
      fontSize: "inharit",
      fontWeight: "600"
    }
  },
  buttonDiscard: {
    color: "#787877"
  },
  button: { textTransform: "none" }
}));

export default function RouteLeavingGuard({
  when,
  setTask,
  isDirty,
  setIsDirty,
  msg,
  additionalStep,
  additionalStepButton,
  additionalMsg,
  navigate
}) {
  const [modalVisible, setModalVisible] = useState(false);
  const [lastLocation, setLastLocation] = useState(null);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);
  const classes = useStyles();
  const dispatch = useDispatch();
  const intl = useIntl();
  // This will disply the brower's default prompt when the user leaves the page via the navigation bar, reloading, or closing a tab
  const alertsDuration = useSelector(
    state => state.user.userProfile.alertsDuration
  );

  useEffect(() => {
    const handleUnload = e => {
      if (isDirty) {
        e.preventDefault();
        e.returnValue = "";
      }
    };
    window.addEventListener("beforeunload", handleUnload);
    return () => {
      window.removeEventListener("beforeunload", handleUnload);
    };
  }, [isDirty]);

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      // Navigate to the previously blocked location with the passes in navigate function
      navigate(lastLocation.pathname + lastLocation.search);
    }
  }, [confirmedNavigation, lastLocation, navigate]);

  const closeModal = () => {
    setModalVisible(false);
  };

  const handleBlockedNavigation = nextLocation => {
    if (!confirmedNavigation) {
      setModalVisible(true);
      setLastLocation(nextLocation);
      return false;
    }
    setTask && setTask(false);
    setIsDirty && setIsDirty(false);
    return true;
  };

  const handleConfirmNavigationClick = () => {
    setModalVisible(false);
    setConfirmedNavigation(true);
  };

  async function handlePassedInStep() {
    additionalStep();
    dispatch(
      enqueueFlashMessage({
        message: additionalMsg
          ? additionalMsg
          : intl.formatMessage({
              id: "task.savedDraft",
              defaultMessage: "Task saved as draft"
            }),
        duration: alertsDuration
      })
    );
    setModalVisible(false);
    setConfirmedNavigation(true);
  }

  const textDirection = useSelector(state => selectTextDirection(state));

  return (
    <>
      <Prompt when={when} message={handleBlockedNavigation} />
      <div>
        <Dialog
          open={modalVisible}
          onClose={closeModal}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          PaperProps={{
            className: classes.modal,
            style: {
              direction: textDirection
            }
          }}
        >
          <ClearIcon className={classes.modalCancel} onClick={closeModal} />
          <DialogTitle id="alert-dialog-title">
            <FormattedMessage
              id="tasks.create.beforYouLeave"
              defaultMessage="Before you leave"
            />
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {msg
                ? msg
                : intl.formatMessage({
                    id: "tasks.create.SaveAsDraft",
                    defaultMessage: "Save task as draft?"
                  })}
            </DialogContentText>
          </DialogContent>
          <DialogActions className={classes.modalActions}>
            <Button
              onClick={handleConfirmNavigationClick}
              className={clsx(classes.button, classes.buttonDiscard)}
            >
              <FormattedMessage id="discard" defaultMessage="Discard" />
            </Button>
            {additionalStep && additionalStepButton ? (
              <Button
                className={classes.button}
                onClick={handlePassedInStep}
                color="primary"
                autoFocus
              >
                {additionalStepButton}
              </Button>
            ) : (
              ""
            )}
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
}

RouteLeavingGuard.propTypes = {
  when: PropTypes.bool.isRequired,
  setTask: PropTypes.func,
  isDirty: PropTypes.bool.isRequired,
  setIsDirty: PropTypes.func,
  msg: PropTypes.string,
  additionalStep: PropTypes.func,
  additionalStepButton: PropTypes.string,
  additionalMsg: PropTypes.string,
  navigate: PropTypes.func.isRequired
};
