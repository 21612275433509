import PropTypes from "prop-types";

const COLOR = PropTypes.oneOf(["secondary", "primary"]);

const INTERACTION_SUBTYPE = PropTypes.oneOf([
  "FIND_IN_TEXT",
  "OPEN_ENDED",
  "MULTI_CHOICE",
  "GUIDED_READING"
]).isRequired;

const INTERACTION_TYPE = PropTypes.oneOf(["QUESTION"]).isRequired;

const PRIVACY = PropTypes.oneOf(["ME_ONLY"]).isRequired;

const USER_TYPE = PropTypes.oneOf(["STUDENT", "TEACHER"]).isRequired;

const CONCEPTS = PropTypes.arrayOf(PropTypes.string);

const HIGHLIGHT = PropTypes.shape({
  cfi: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  course_id: PropTypes.number.isRequired,
  id: PropTypes.string.isRequired,
  interaction_id: PropTypes.string,
  text_id: PropTypes.number.isRequired
});

const QUESTION = PropTypes.shape({
  content: PropTypes.string.isRequired,
  course_id: PropTypes.number.isRequired,
  id: PropTypes.string.isRequired,
  interaction_id: PropTypes.string,
  interaction_subtype: INTERACTION_SUBTYPE,
  interaction_type: INTERACTION_TYPE,
  order: PropTypes.number.isRequired,
  privacy: PRIVACY,
  text_id: PropTypes.number.isRequired,
  submission_id: PropTypes.string,
  task_id: PropTypes.string,
  user_type: USER_TYPE,
  user_uid: PropTypes.string.isRequired
});

const TYPES = {
  COLOR,
  INTERACTION_SUBTYPE,
  CONCEPTS,
  HIGHLIGHT,
  QUESTION
};

export default TYPES;
