// Dependencies
import React, { useEffect, useState } from "react";
import { remoteConfig } from "./firebase";
import { useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import { useLocalStorage } from "./hooks/useLocalStorage";

// Redux dependencies
import { useFirestoreConnect } from "react-redux-firebase";

// Components

// Material UI
import {
  Typography,
  Button,
  Dialog,
  DialogContent,
  DialogActions
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles(theme => {
  return {
    heatmapBtn: {
      backgroundColor: "#168fee",
      color: "#ffffff",
      textTransform: "none",
      "&:hover": {
        background: "#1172be"
      }
    }
  };
});

export default function VersionUpdateDialog() {
  const classes = useStyles();

  const firestoreAppVersion = useSelector(
    state => state.firestore.data?.defaultConfig?.VERSION
  );

  useFirestoreConnect([
    {
      collection: "userProfiles",
      doc: "defaultConfig",
      storeAs: "defaultConfig"
    }
  ]);

  const [currentAppVersion, setCurrentAppVersion] =
    useState(firestoreAppVersion);
  const [storedClientVersion, setStoredClientVersion] = useLocalStorage(
    "conf.lastRemoteConfig",
    {}
  );
  // Behavior

  useEffect(() => {
    let clientStoredVersion =
      storedClientVersion && typeof storedClientVersion === "string"
        ? JSON.parse(storedClientVersion)
        : storedClientVersion;
    if (clientStoredVersion.version !== currentAppVersion) {
      setCurrentAppVersion(currentAppVersion);
    }
  }, [currentAppVersion, storedClientVersion]);

  if (currentAppVersion !== firestoreAppVersion) {
    return (
      <Dialog
        open={currentAppVersion !== firestoreAppVersion}
        onClose={() => {}}
      >
        <DialogContent>
          <Typography>
            <FormattedMessage
              id="upgrade.msg"
              defaultMessage="A new Alethea version is available, click ‘Reload’ to update"
            />
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            aria-label={"Reload"}
            className={classes.heatmapBtn}
            onClick={() => {
              const payload = {
                recaptcha_key: remoteConfig
                  .getValue("recaptcha_key")
                  .asString(),
                version: firestoreAppVersion
              };
              setStoredClientVersion(payload);
              window.location.reload(true);
            }}
          >
            <FormattedMessage id="upgrade.reload" defaultMessage="Reload" />
          </Button>
        </DialogActions>
      </Dialog>
    );
  } else return <></>;
}
