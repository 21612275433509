// Dependencies
import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { useDrag } from "react-dnd";
import { useSelector, useDispatch } from "react-redux";
import { interactionsAPI, grAPI } from "../../api";
import { appActions } from "../../consts";
import { useRendition } from "../../RenditionContext";
import { showInText } from "../../utils/showInTextHelper";

// Redux dependencies
import { setSelectedInteractionId } from "../../redux/interactionsSlice";
import { selectCurrentText } from "../../redux/textsSlice";

//Components
import CitationDialog from "../Tasks/CreateTask/CitationDialog";
import {
  InteractionCardEditableHeader,
  InteractionCardContent,
  InteractionCardFooter,
  InteractionCard,
  InteractionCardAction
} from "../SharedComponents/card";

// Material UI
import makeStyles from "@mui/styles/makeStyles";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";

import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";

const useStyles = makeStyles(theme => ({}));

export function QuestionQuoteCard({
  item,
  question,
  index,
  color,
  locked = false
}) {
  // Hooks
  const classes = useStyles();
  const dispatch = useDispatch();
  const { rendition } = useRendition();
  const titleRef = useRef();

  // Redux
  const stage = useSelector(state => state.gr.stage);
  const grMode = useSelector(state => state.gr.mode);
  const selectedText = useSelector(selectCurrentText);
  const showAnswers = useSelector(state => state.gr.showAnswers);
  const selectedQuestionId = useSelector(state => state.gr.selectedQuestionId);

  const user_id = useSelector(state => state.user.auth.uid);

  const [showCitation, setShowCitation] = useState(false);

  //Variables
  const cardItem = item;
  const ANSWER_ITEM = "GR.Answer";

  const [, drag] = useDrag({
    item: {
      type: ANSWER_ITEM,
      id: cardItem.cfi,
      index,
      item: cardItem,
      questionId: question.id
    },
    collect: monitor => ({
      isDragging: monitor.isDragging()
    }),
    canDrag: !locked
  });

  const showInTextHandler = item => {
    const cb = determineShowInTextCB(item);
    showInText(appActions.SHOULD_LOG, item, user_id, rendition, cb);
  };

  const determineShowInTextCB = item => {
    switch (true) {
      case grMode === "full" && stage < 4:
      case grMode === "light" && stage === 1 && !showAnswers:
        return () => {
          if (selectedQuestionId !== item.interaction_id) {
            grAPI.updateGrState({ grQuestionId: question.id });
          }
          dispatch(setSelectedInteractionId(item.interaction_id));
        };
      case grMode === "full" && stage === 4:
      case grMode === "light" && stage === 1 && showAnswers:
      case grMode === "light" && stage === 2:
        return () => {
          setShowCitation(item);
        };
      default:
        break;
    }
  };

  return (
    <>
      <InteractionCard
        ref={drag}
        collapseContent={true}
        showInText={showInText}
        interaction={item}
      >
        <InteractionCardEditableHeader ref={titleRef} />
        <InteractionCardContent />
        <InteractionCardFooter>
          {/* show in text */}
          <InteractionCardAction
            onClick={() => {
              showInTextHandler(item);
            }}
            intlStringId="highlights.showInText"
            defaultMessage="Show in text"
          >
            <LocationOnOutlinedIcon />
          </InteractionCardAction>

          {/* edit title */}
          <InteractionCardAction
            onClick={() => {
              titleRef.current.focus();
            }}
            intlStringId={
              !item?.title ? "highlights.addTitle" : "highlights.editTitle"
            }
            defaultMessage={!item?.title ? "Add title" : "Edit title"}
          >
            <EditOutlinedIcon />
          </InteractionCardAction>

          {/* delete highlight */}
          <InteractionCardAction
            onClick={() => {
              interactionsAPI.deleteHighlight(item);
            }}
            intlStringId="highlights.delete"
            defaultMessage="Delete highlight"
          >
            <DeleteOutlineOutlinedIcon />
          </InteractionCardAction>
        </InteractionCardFooter>
      </InteractionCard>

      {showCitation && ( // This is not the best place for this dialog, I was left here in order to avoid breaking the code and should be refactored
        <CitationDialog
          color={color}
          setOpenDialog={setShowCitation}
          citationTitleFormattedMessage={
            <FormattedMessage
              id="show.location.inText"
              defaultMessage="Show in text"
            />
          }
          url={selectedText.url}
          location={selectedText.file_location}
          highlights={[showCitation]}
          openDialog={!!showCitation}
          removeHighlight={() => {
            interactionsAPI.deleteHighlight(item);
            setShowCitation(false);
          }}
        />
      )}
    </>
  );
}

QuestionQuoteCard.propTypes = {
  rendition: PropTypes.object,
  item: PropTypes.object,
  question: PropTypes.object,
  index: PropTypes.number,
  color: PropTypes.string,
  locked: PropTypes.bool
};
