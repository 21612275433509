// Dependencies
import { useState } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { getHighlightColor } from "../../../utils/colors";
import { FormattedMessage } from "react-intl";
// Redux dependencies
import { setCurrentLink } from "../../../redux/LinkSlice";

// Components
//TODO: this should be moved to a more genric location
import CitationDialog from "../../Tasks/CreateTask/CitationDialog";

// Material UI
import { Typography } from "@mui/material";

// Styles
function Link({ contentState, entityKey, children }) {
  //Hooks
  const dispatch = useDispatch();
  const { type, cfis, range } = contentState.getEntity(entityKey).getData();

  // Redux State
  const text = useSelector(state => state.texts.selectedText);
  const darkMode = useSelector(state => state.user.userProfile.darkMode);

  //Ephemeral State
  const [openDialog, setOpenDialog] = useState(false);

  //Behavior
  const rendeHighlightsDialog = () => {
    return (
      <CitationDialog
        setOpenDialog={setOpenDialog}
        url={text && (text.link || text.url)}
        location={text && text.defaultLocation}
        highlights={cfis.cfis.map((cfi, i) => {
          let hlColor = cfi.color ? cfi.color : "#04E3FD";
          return {
            cfi: cfi && cfi.cfi ? cfi.cfi : cfi,
            color: getHighlightColor(hlColor, darkMode)
          };
        })}
        openDialog={openDialog}
        citationTitleFormattedMessage={
          <FormattedMessage id="text.mark" defaultMessage="Mark in Text" />
        }
      />
    );
  };

  // Render
  return (
    <Typography
      component="span"
      onClick={() => {
        if (type === "text") {
          text && setOpenDialog(true);
        }
        if (type === "editor") {
          dispatch(setCurrentLink(contentState.getEntity(entityKey).getData()));
        }
        if (type === "answer") {
          let element = document.getElementById("answer-text");
          if (element) {
            var sel = document.createRange();
            sel.setStart(element.firstChild, range.start);
            sel.setEnd(element.firstChild, range.end);
            if (window.getSelection().empty) {
              // Chrome
              window.getSelection().empty();
            } else if (window.getSelection().removeAllRanges) {
              // Firefox
              window.getSelection().removeAllRanges();
            }

            window.getSelection().addRange(sel);
          }
        }
      }}
      style={{
        color: "#168FEE",
        textDecoration: "underline",
        cursor: "pointer",
        fontFamily: "revert",
        fontSize: "revert"
      }}
    >
      {children}
      {openDialog && rendeHighlightsDialog()}
    </Typography>
  );
}

Link.propTypes = {};

export default Link;
