// dependancies
import { httpCallables } from "../../../firebase";

// Redux

// Rx
import { ofType } from "redux-observable";
import { EMPTY } from "rxjs";
import { tap, map, catchError, switchMap } from "rxjs/operators";
import {
  fetchTeacherCourseTasks,
  setTaskAndSubmissions
} from "../../tasksSlice";
import { captureException } from "../../../utils/errorHandlers";
import { hideLoader, showLoader } from "../utils";

const CALLBACK = httpCallables.taskFunctions;
const ACTION = "readTeacherCourseTasks";
const ERROR_MSG = `FETCH_TEACHER_COURSE_TASKS`;

export const fetchTeacherCourseTasksEpic = (
  action$,
  state$,
  { fetchAsObservable }
) => {
  return action$.pipe(
    ofType(fetchTeacherCourseTasks.type),
    tap(() => showLoader()), // temp solution, until we get rid of loading state
    map(({ payload }) => ({ course_id: Number(payload.course_id) })),
    switchMap(args =>
      fetchAsObservable(CALLBACK, ACTION, ERROR_MSG, args).pipe(
        map(payload => ({
          type: setTaskAndSubmissions.type,
          payload: payload
        })),
        tap(() => hideLoader()),

        catchError(error => {
          // TODO: handle error
          hideLoader();
          captureException(error, "Error in submitTaskEpic");
          return EMPTY;
        })
      )
    )
  );
};
