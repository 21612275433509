import { createSlice } from "@reduxjs/toolkit";
export const FAB_STATUS = {
  VISIBLE: "VISIBLE",
  HIDDEN: "HIDDEN"
};

export const FAB_TYPE = {
  LOADING: "LOADING",
  MENTOR: "MENTOR"
};

const initialState = {
  status: FAB_STATUS.HIDDEN,
  type: FAB_TYPE.MENTOR
};

export const fabSlice = createSlice({
  name: "fab",
  initialState,
  extraReducers: {
    "@@router/LOCATION_CHANGE": state => {
      state.status = FAB_STATUS.HIDDEN;
    },
    "chat/openChatbox": (state, value) => {
      state.status = FAB_STATUS.HIDDEN;
    },
    "chat/closeChatbox": (state, value) => {
      state.status = FAB_STATUS.VISIBLE;
    }
  },
  reducers: {
    showFab: state => {
      state.status = FAB_STATUS.VISIBLE;
    },
    hideFab: state => {
      state.status = FAB_STATUS.HIDDEN;
    },
    updateFabType: (state, value) => {
      const { type } = value.payload;
      state.type = type;
    },

    // Actions for epics
    mentorReviewFabClick: () => {}
  }
});

export const {
  showFab,
  hideFab,
  updateFabType,

  // actions for epics
  mentorReviewFabClick
} = fabSlice.actions;

// Selectors
// Utils

export default fabSlice.reducer;
