// Dependancies
import React, { useEffect, useState, useRef, useLayoutEffect } from "react";
import PropTypes from "prop-types";
import { useLayer, Arrow } from "react-laag";
import { useIntl } from "react-intl";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import { isEmpty } from "lodash";
import { useRendition } from "../../RenditionContext";
//Redux
import {
  selectIsComments,
  selectIsSuggestions
} from "../../redux/firestoreSelectors";
import { useSelector, useDispatch } from "react-redux";
import {
  setSecondarySidebarViewMode,
  suggestionsIconClicked
} from "../../redux/layoutSlice";
import { setCommentPanelState } from "../../redux/realtimeInteractionsSlice";
import { clearSelection } from "../reader/utils";
import {
  closeAnnotatorBar,
  selectSelectedHighlight
} from "../../redux/highlightSlice";

// Components
import ReaderAction from "./ReaderAction";
import HighlightColor from "./HighlightColor";

// Material UI
import makeStyles from "@mui/styles/makeStyles";
import { Box, Divider } from "@mui/material";
import BorderColor from "@mui/icons-material/BorderColor";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import CommentOutlinedIcon from "@mui/icons-material/CommentOutlined";
import {
  COMMENT_PANEL_VIEW,
  INTERACTION_SUBTYPES,
  INTERACTION_TYPES,
  SECONDARY_SIDEBAR_STATE
} from "../../consts";
import { enqueueFlashMessage } from "../../redux/userSlice";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useOnClickOutside } from "../../hooks";

// Styles
const useStyles = makeStyles(theme => ({
  container: {
    height: "100%",
    position: "relative"
  },
  readerActions: {
    display: "flex",
    alignItems: "center",
    zIndex: 1500,
    padding: 1,
    borderRadius: 4,
    paddingInline: theme.spacing(1),
    backgroundColor: "#333333",
    color: "#fafafa",
    height: 48
  },
  highlightAction: {
    "& :hover": {
      color: "#BEF3BF"
    }
  },
  divider: {
    color: "#757575",
    backgroundColor: "#757575",
    margin: theme.spacing(1)
  }
}));

export default function ReactReaderActions({
  addHighlight,
  minimal,
  children,
  deleteHighlight = false,
  canComment = false
}) {
  //Hooks
  const classes = useStyles();
  const dispatch = useDispatch();
  const intl = useIntl();
  let highlightRef = useRef(null);
  let containerRef = useRef();
  const { rendition } = useRendition();

  // Redux state

  const annotatorMode = useSelector(
    state => state.readerActions.persistentActionState.annotatorMode
  );

  const clientRectangle = useSelector(
    state => state.highlighter.clientRectangle
  );

  const selectedHighlight = useSelector(state =>
    selectSelectedHighlight(state)
  );
  const highlight_element = useSelector(
    state => state.highlighter.selectedHighlight
  );
  const alertsDuration = useSelector(
    state => state.user.userProfile.alertsDuration
  );

  // This refers to the text selection that the user marked on the source text
  const selectedText = useSelector(state => state.highlighter.selectedText);

  const isAnnotatorBarOpen = useSelector(
    state => state.highlighter.isAnnotatorBarOpen
  );
  const minimalFromState = useSelector(state => state.highlighter.isMinimal);

  const isClickOnMark = useSelector(state => state.highlighter.isClickOnMark);
  const isComments = useSelector(state => selectIsComments(state));
  const isSuggestions = useSelector(state => selectIsSuggestions(state));

  const selectedQuestion = useSelector(state => {
    if (state.interactions.questions?.length) {
      let filtered = state.interactions.questions.filter(
        q => q.id === state.gr.selectedQuestionId
      );
      if (filtered && filtered.length) return filtered[0];
      else return false;
    }
  });

  const grStage = useSelector(state => state.gr.stage);

  // Ephemeral state
  const [isOpen, setIsOpen] = useState(false);

  // Derived state
  const isMinimal = minimal || minimalFromState;

  const colors = [
    {
      color: "#BEF3BF",
      text: "color.green",
      hlColor: "#BEF3BF"
    },
    {
      color: "#B9E7FF",
      text: "color.blue",
      hlColor: "#B9E7FF"
    },
    {
      color: "#ECC5FF",
      text: "color.pink",
      hlColor: "#ECC5FF"
    },
    {
      color: "#FF7F74",
      text: "color.red",
      hlColor: "#FF7F74"
    },
    {
      color: "#FFE690",
      text: "color.yellow",
      hlColor: "#FFE690"
    }
  ];
  const showSocialAction =
    ["poc", "", "highlight"].includes(annotatorMode) && isClickOnMark;
  const showHighlightColors = !isMinimal && isClickOnMark;

  //Behavior

  function adjustHighlightedRect(highlightedRect) {
    const currentRect = { ...highlightedRect };
    if (currentRect["top"] < 50 && currentRect["bottom"] > 200) {
      currentRect["top"] = 100;
      currentRect["y"] = currentRect["top"];
      currentRect["height"] = currentRect["bottom"] - currentRect["top"] ;
    }
  
    return currentRect;
  }

  useEffect(() => {
    clearSelection(rendition);
  }, [grStage]); // eslint-disable-line

  const { layerProps, renderLayer, arrowProps } = useLayer({
    isOpen,
    overflowContainer: false,
    trigger: {
      getBounds: () => adjustHighlightedRect(clientRectangle)
    },
    container: containerRef.current,
    // DON'T REMOVE USEFULL FOR DEBUGGING
    // placement: "center"
    // placement: "top-center",
    possiblePlacements: ["top-center", "bottom-center"],
    preferY: "top",
    auto: true,
    arrowOffset: 8,
    containerOffset: 8,
    triggerOffset: 12
  });

  useScrollPosition(({ prevPos, currPos }) => {
    if (currPos.y !== prevPos.y) {
      setIsOpen(false);
    }
  });
  useLayoutEffect(() => {
    if (
      isAnnotatorBarOpen &&
      !isOpen &&
      (!annotatorMode ||
        isClickOnMark ||
        (annotatorMode === "poc" && !selectedQuestion))
    ) {
      setIsOpen(true);
    } else {
      if (!isAnnotatorBarOpen) {
        setIsOpen(false);
      }
    }
  }, [
    annotatorMode,
    dispatch,
    isAnnotatorBarOpen,
    isClickOnMark,
    isOpen,
    selectedQuestion
  ]);

  async function handleMarkAction() {
    dispatch(closeAnnotatorBar());
    clearSelection(rendition);
    if (isEmpty(selectedHighlight) && isClickOnMark)
      await deleteHighlight(highlight_element);
    else if (!isEmpty(selectedHighlight) && isClickOnMark) {
      await deleteHighlight(selectedHighlight);
    } else if (!isEmpty(selectedText)) {
      let newHighlight = { ...selectedText };
      newHighlight = Object.assign(newHighlight, {
        interaction_type: INTERACTION_TYPES.READER,
        interaction_subtype: INTERACTION_SUBTYPES.QUOTE
      });
      await addHighlight(newHighlight);
    }
  }

  async function handleSocialAction() {
    if (isSuggestions) {
      dispatch(suggestionsIconClicked(SECONDARY_SIDEBAR_STATE.COLLAPSED));
    }
    if (!isComments) {
      dispatch(setSecondarySidebarViewMode(SECONDARY_SIDEBAR_STATE.COMMENTS));
    }
    dispatch(setCommentPanelState(COMMENT_PANEL_VIEW.NEW_COMMENT));
    dispatch(closeAnnotatorBar());
  }

  function copySelectedText() {
    navigator.clipboard.writeText(selectedHighlight.content);
    dispatch(closeAnnotatorBar());
    dispatch(
      enqueueFlashMessage({
        message: intl.formatMessage({
          id: "textCopied.success",
          defaultMessage: "Text copied successfully"
        }),
        duration: alertsDuration
      })
    );
  }

  useOnClickOutside(containerRef, () => {
    if (isAnnotatorBarOpen) dispatch(closeAnnotatorBar());
  });

  return (
    <div ref={containerRef} className={classes.container}>
      {isOpen &&
        // DON'T REMOVE USEFULL FOR DEBUGGING
        renderLayer(
          // <Box {...layerProps}>
          //   <Box
          //     style={{
          //       height: clientRectangle.height,
          //       width: clientRectangle.width,
          //       background: "rgba(0,0,0,0.3)"
          //     }}
          //   />
          // </Box>

          <Box {...layerProps} className={classes.readerActions} boxShadow={4}>
            {showHighlightColors && (
              <>
                {colors.map(item => (
                  <HighlightColor key={item.color} color={item.color} />
                ))}
                <Divider
                  className={classes.divider}
                  orientation="vertical"
                  flexItem
                />
              </>
            )}
            <ReaderAction
              minimal={isMinimal}
              ref={highlightRef}
              Icon={isClickOnMark ? DeleteOutlinedIcon : BorderColor}
              className={classes.highlightAction}
              handleClick={handleMarkAction}
              intlStringId="menu.tooltip.deleteHighlight"
              defaultMessage="Delete highlight"
              placement="bottom"
            />
            <ReaderAction
              ref={highlightRef}
              Icon={ContentCopyIcon}
              className={classes.highlightAction}
              handleClick={() => {
                copySelectedText();
              }}
              intlStringId="menu.tooltip.copyText"
              defaultMessage="Copy text"
              placement="bottom"
            />
            {showSocialAction && canComment && (
              <ReaderAction
                ref={highlightRef}
                Icon={CommentOutlinedIcon}
                className={classes.highlightAction}
                handleClick={handleSocialAction}
                intlStringId="menu.tooltip.createComment"
                defaultMessage="Create comment"
                placement="bottom"
              />
            )}
            <Arrow
              {...arrowProps}
              borderColor="#333333"
              backgroundColor="#333333"
            />
          </Box>
        )}
      {React.Children.map(children, child => {
        const newChild = React.cloneElement(child, {
          container: containerRef
        }); // add props here
        return newChild;
      })}
    </div>
  );
}

ReactReaderActions.propTypes = {
  deleteHighlight: PropTypes.func,
  children: PropTypes.node,
  addHighlight: PropTypes.func,
  minimal: PropTypes.bool,
  canComment: PropTypes.bool
};
