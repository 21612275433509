// Dependencies
import { FEATURES, FEATURE_FLAGS } from "../../../consts";
import { captureException } from "../../../utils/errorHandlers";
import { CHATBOX_STATUS, CONVERSATION_TYPES, initChat } from "../../chatSlice";

import { updateTextPosition } from "../../readerSlice";
import {
  isFeatureFlagEnabled,
  fetchMentor,
  mapStateToFetchProps
} from "./utils";
// rxJs
import { ofType } from "redux-observable";
import { of, interval, empty } from "rxjs";
import {
  debounceTime,
  map,
  delayWhen,
  switchMap,
  filter,
  withLatestFrom,
  takeWhile,
  catchError
} from "rxjs/operators";

export const initMentorOnTimerEpic = (action$, state$) => {
  const initMentor$ = action$.pipe(
    // This stream currently return the delay in ms
    ofType(updateTextPosition.type),
    debounceTime(200),
    filter(() =>
      isFeatureFlagEnabled(state$.value, FEATURE_FLAGS.E_MENTOR_READER)
    ),
    filter(() => state$.value.reader.readingSession.length > 0),
    filter(() => isReader(state$.value)),
    map(action => action.payload),
    switchMap(delayBasedOnContent)
  );

  return initMentor$.pipe(
    withLatestFrom(state$),
    takeWhile(([action, state]) => state.chat.status === CHATBOX_STATUS.CLOSED),
    map(([action, state]) => mapStateToFetchProps(action, state)),
    switchMap(props => fetchMentor(props)),
    map(({ mentorResponse, sessionId }) => ({
      content: mentorResponse,
      sessionId
    })),
    map(payload => ({ type: initChat.type, payload })),
    catchError(error => {
      captureException(error, "Error in initMentorOnTimerEpic");
      return empty();
    })
  );
};

function delayBasedOnContent(payload) {
  return of(payload).pipe(
    map(payload => calculateTimer(payload)),
    // delayWhen(delayValue => interval(delayValue))
    delayWhen(delayValue => interval(2000))
  );
}

function calculateTimer(position) {
  const { content } = position;
  const numberOfWordsInView = content.length;
  const expectedMillisPerView = (numberOfWordsInView / 250) * 60 * 1000;
  return expectedMillisPerView;
}

function isReader(state) {
  const { router } = state;
  const locationPath = router.location.pathname;
  const feature = locationPath.substring(1);
  if (feature.toUpperCase() === FEATURES.READER) return true;
  else return false;
}
