import React, { useRef, useState, useEffect } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { useIntl } from "react-intl";
import { motion } from "framer-motion";
import { useGetTheme } from "../../hooks";
import { useSelector, useDispatch } from "react-redux";
import { updateSq3r } from "../../redux/firebaseMiddleware";
import {
  setSelectedInteractionId,
  updateInteraction
} from "../../redux/interactionsSlice";
import QuestionEditMenu from "../menus/QuestionEditMenu";
import EditingButton from "../EditingButton";
import { alpha } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import { Typography } from "@mui/material";
import { USER_TYPE } from "../../consts";
import { grAPI } from "../../api";

const useStyles = makeStyles(theme => ({
  li: {
    display: "flex",
    fontSize: "16px",
    lineHeight: "24px",
    position: "relative",
    cursor: "pointer",
    "&:hover button, &:focus-within button": {
      opacity: 1,
      transition: ".5s ease-out"
    },
    alignItems: "center"
  },
  liLtr: {
    textAlign: "left",
    "& button": {
      right: "0px"
    },
    paddingInlineEnd: theme.spacing(2)
  },
  liRtl: {
    textAlign: "right",
    "& button": {
      left: "0px"
    }
  },
  questionTextWrapper: {
    display: "flex",
    flexDirection: "column",
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
    paddingInlineEnd: theme.spacing(0.5),
    paddingInlineStart: theme.spacing(0.5),
    flex: 1
  },
  questionText: {
    fontSize: "16px",
    textAlign: "initial",
    color: "inherit"
  },
  isRequired: {
    opacity: 0.7
  }
}));

function QuestionBox({
  icon,
  editable = true,
  question,
  index,
  onDelete,
  color
}) {
  const inputRef = useRef(null);
  const questionRef = useRef(null);
  const classes = useStyles();
  const dispatch = useDispatch();
  const theme = useGetTheme({ alwase: "dark" });
  const highlights = useSelector(state => state.interactions.highlights);
  const questions = useSelector(state => state.interactions.questions);
  const selectedTextId = useSelector(state => state.texts.selectedTextId);
  const selectedQuestionId = useSelector(state => state.gr.selectedQuestionId);
  const stage = useSelector(state => state.gr.stage);
  const grMode = useSelector(state => state.gr.mode);
  const [mousePos, setMousePos] = useState({});
  const [editingMode, setEditingMode] = useState(null);
  const intl = useIntl();
  const isGrTaskQuesiton =
    question.user_type === USER_TYPE.TEACHER && question.task_id !== null;
  useEffect(() => {
    if (editingMode && inputRef.current) {
      inputRef.current.focus();
      if (inputRef.current.setSelectionRange) {
        //  tempRef.current.setSelectionRange(text.length, text.length);
      }
    }
  }, [editingMode, inputRef]);

  function updateQuestion(question, e) {
    dispatch(
      updateInteraction({
        interaction: question,
        update: { content: e.target.value }
      })
    );
  }

  function deleteQuestion() {
    setMousePos({});
    onDelete && onDelete(question, index); // this sets the undo
  }

  const renderEditControl = () => {
    return (
      <EditingButton
        rows={2}
        multiline={true}
        text={question.content}
        editingMode={question.id === editingMode}
        editIcon={<span />}
        onFocusOut={value => {
          if (!value) {
            dispatch(
              updateSq3r({
                text_id: selectedTextId,
                questions: questions.filter(el => el.id !== question.id),
                highlights: highlights
              })
            );
          }
          setEditingMode(null);
        }}
        onKeyPress={e => {
          if (e.key === "Enter") {
            setEditingMode(null);
            updateQuestion(question, e);
            e.preventDefault();
          }
        }}
      />
    );
  };

  const enableQuestionSelection =
    (grMode === "light" && stage !== 0) || (grMode === "full" && stage > 1);
  // Render
  return (
    <motion.div
      ref={questionRef}
      className={clsx(classes.li, classes.liLtr)}
      initial={
        (selectedQuestionId === question.id ||
          Boolean(editingMode === question.id)) &&
        enableQuestionSelection
          ? "selected"
          : "default"
      }
      variants={{
        selected: {
          backgroundColor: theme.palette[color].light,
          color: "#000"
        },
        default: {
          backgroundColor: alpha(theme.palette[color].main, 0),
          color: "#FFF"
        }
      }}
      animate={
        (selectedQuestionId === question.id ||
          Boolean(editingMode === question.id)) &&
        enableQuestionSelection
          ? "selected"
          : "default"
      }
      style={{
        cursor: editable ? "inherit" : "pointer"
      }}
      transition={{
        duration:
          selectedQuestionId === question.id || Boolean(editingMode) ? 0.3 : 0
      }}
      onClick={() => {
        if (!enableQuestionSelection) return;
        // drag handle not focused at drag start
        // https://github.com/atlassian/react-beautiful-dnd/blob/master/docs/guides/browser-focus.md#drag-handle-not-focused-at-drag-start
        !editingMode && questionRef && questionRef.current.focus();
        switch (true) {
          case editingMode !== question.id &&
            selectedQuestionId !== question.id:
            // TODO: the first dispatch should go once using react-redux-firebase
            dispatch(setSelectedInteractionId(question.id));
            grAPI.updateGrState({ grQuestionId: question.id });
            break;
          case editingMode !== question.id &&
            selectedQuestionId === question.id &&
            stage < 2:
            // TODO: the first dispatch should go once using react-redux-firebase
            dispatch(setSelectedInteractionId(null));
            grAPI.updateGrState({ grQuestionId: null, showAnswers: false });
            break;
          default:
            break;
        }
      }}
      role="button"
      tabIndex="0"
      aria-pressed={selectedQuestionId === question.id && "true"}
      aria-label="question-input"
    >
      {icon}
      {editable && editingMode === question.id ? (
        renderEditControl()
      ) : (
        <div className={classes.questionTextWrapper}>
          <Typography dir="auto" className={clsx(classes.questionText)}>
            {question.content}
          </Typography>
          {isGrTaskQuesiton && (
            <Typography
              dir="auto"
              variant="body2"
              className={clsx(classes.isRequired)}
            >
              {intl.formatMessage({
                id: "gr.requiredQuestion",
                defaultMessage: "Required question"
              })}
            </Typography>
          )}
        </div>
      )}
      {editable && (
        <QuestionEditMenu
          selected={
            selectedQuestionId === question.id && enableQuestionSelection
          }
          open={"X" in mousePos}
          handleClose={() => {
            setMousePos({});
          }}
          onDelete={deleteQuestion}
          onEdit={e => {
            grAPI.updateGrState({ grQuestionId: question.id });
            setEditingMode(question.id);
            setMousePos({});
          }}
          mouseX={mousePos ? mousePos.X : null}
          mouseY={mousePos ? mousePos.Y : null}
        />
      )}
    </motion.div>
  );
}

QuestionBox.propTypes = {
  icon: PropTypes.node,
  editable: PropTypes.bool,
  question: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  onDelete: PropTypes.func,
  color: PropTypes.oneOf(["secondary", "primary"]).isRequired
};

export default QuestionBox;
