import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { FormControlLabel, Switch } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { FormattedMessage } from "react-intl";
import TYPES from "../../types";

const useStyles = makeStyles(theme => ({
  switchRoot: {
    marginInlineEnd: 4,
  },
  controllLableRoot: {
    marginRight: 0,
    marginLeft: 0,
    marginInline: 0
  }
}));

export default function HighlightViewSwitch({
  on = false,
  color = "primary",
  onChange = () => {},
  className
}) {
  const classes = useStyles();

  return (
    <div className={clsx(className)}>
      <FormControlLabel
        classes={{ root: classes.controllLableRoot }}
        control={
          <Switch
            checked={on}
            color={color}
            classes={{ root: classes.switchRoot }}
            onChange={e => {
              onChange();
            }}
          />
        }
        label={
          <FormattedMessage
            id="interactions.highlightsView"
            defaultMessage="interactions.highlightsView"
          />
        }
      />
    </div>
  );
}

HighlightViewSwitch.propTypes = {
  on: PropTypes.bool.isRequired,
  color: TYPES.COLOR,
  onChange: PropTypes.func,
  className: PropTypes.string
};
