// Dependencies
import React from "react";

import makeStyles from '@mui/styles/makeStyles';
import { Tabs, Tab } from "@mui/material";

// Styles
const useStyles = makeStyles(theme => ({
  container: {
    background: theme.palette.background.default,
    borderBlockEnd: `1px solid ${theme.palette.divider}`,
    zIndex: 2
  },
  tab: {
    textTransform: "capitalize"
  }
}));

export default function PeerReviewTabs({
  currentTab,
  setCurrentTab,
  answerBookmark,
  zenMode,
  saveCurrentTabLocation
}) {
  // Hooks
  const classes = useStyles();

  // Redux state

  // Ephemeral state

  // Variables

  // Behavior

  const handleTabChange = (event, newValue) => {
    saveCurrentTabLocation();
    setCurrentTab(newValue);
  };

  // Render
  return (
    <Tabs
      className={classes.container}
      value={currentTab}
      onChange={handleTabChange}
      indicatorColor="primary"
      textColor="primary"
      aria-label="tabs"
    >
      <Tab
        className={classes.tab}
        disabled={zenMode}
        label="Reflection"
        id="answer-tab"
        aria-controls="answer-tabpanel"
      />
      <Tab
        className={classes.tab}
        disabled={zenMode}
        label="Text"
        id="text-tab"
        aria-controls="text-tabpanel"
      />
    </Tabs>
  );
}
