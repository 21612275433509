import {
  COMMENT_PANEL_VIEW,
  PRIMARY_SIDEBAR_STATE,
  SECONDARY_SIDEBAR_STATE
} from "../../../consts";

import {} from "../../../redux/firestoreSelectors";
import {
  setCommentPanelState,
  setSelectedThreadId
} from "../../../redux/realtimeInteractionsSlice";
import {
  pdfThumbnailsIconClicked,
  setSecondarySidebarViewMode,
  suggestionsIconClicked
} from "../../../redux/layoutSlice";

import store from "../../../redux/store";

export const handleSuggestionsPanel = () => {
  const state = store.getState();
  const isSuggestions = isFeatureActive(
    state,
    SECONDARY_SIDEBAR_STATE.SUGGESTIONS
  );

  if (isSuggestions) {
    store.dispatch(
      setSecondarySidebarViewMode(SECONDARY_SIDEBAR_STATE.COLLAPSED)
    );
    store.dispatch(suggestionsIconClicked(SECONDARY_SIDEBAR_STATE.COLLAPSED));
  } else {
    store.dispatch(suggestionsIconClicked(PRIMARY_SIDEBAR_STATE.EXPANDED));
    store.dispatch(
      setSecondarySidebarViewMode(SECONDARY_SIDEBAR_STATE.SUGGESTIONS)
    );
  }
};

export const handleCommentPanel = () => {
  const state = store.getState();
  const isAllThreads =
    state.realtimeInteractions.commentPanelState ===
    COMMENT_PANEL_VIEW.ALL_THREADS;
  const isCommentsInSecondarySidebar = isFeatureActive(
    state,
    SECONDARY_SIDEBAR_STATE.COMMENTS
  );
  const isSuggestions = state.firestore?.data?.customConfig?.suggestions;
  if (isSuggestions) {
    store.dispatch(suggestionsIconClicked(SECONDARY_SIDEBAR_STATE.COLLAPSED));
  }
  if (!isCommentsInSecondarySidebar) {
    store.dispatch(
      setSecondarySidebarViewMode(SECONDARY_SIDEBAR_STATE.COMMENTS)
    );
  } else {
    if (!isAllThreads) {
      store.dispatch(setCommentPanelState(COMMENT_PANEL_VIEW.ALL_THREADS));
    } else
      store.dispatch(
        setSecondarySidebarViewMode(SECONDARY_SIDEBAR_STATE.COLLAPSED)
      );
    store.dispatch(setSelectedThreadId(null));
  }
};

export const handleThumbnailPanel = () => {
  const state = store.getState();
  const isSuggestions = state.firestore?.data?.customConfig?.suggestions;
  if (isSuggestions) {
    store.dispatch(suggestionsIconClicked(SECONDARY_SIDEBAR_STATE.COLLAPSED));
  }
  store.dispatch(pdfThumbnailsIconClicked());
};

export function isFeatureActive(state, flag) {
  const { firestore } = state;
  const feature = firestore?.data?.customConfig?.secondarySidebarState;
  return feature === flag ? feature : false; // return false if featureFlags is undefined
}
