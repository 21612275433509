// Dependencies
import React, { useRef } from "react";
import clsx from "clsx";
import { peerReviewAPI } from "../../../api";
import { isEmpty } from "lodash";
import { useQuery } from "../../../hooks";

// Redux dependencies
import { useSelector } from "react-redux";
import { selectTask, selectSubmission } from "../../../redux/tasksSlice";
import {
  selectPeerReviewReview,
  selectPeerReviewReply
} from "../../../redux/interactionsSlice";

// Components
import RenderEditorContent from "../../SharedComponents/RenderEditorContent";
import {
  TextEditor,
  TextEditorHeader,
  TextEditorFooter,
  TextEditorInput,
  TextEditorButton
} from "../../SharedComponents/textEditor";

import makeStyles from "@mui/styles/makeStyles";
import { Box } from "@mui/material";
import { INTERACTION_SUBTYPES, INTERACTION_TYPES } from "../../../consts";
import { useIntl } from "react-intl";

// Styles
const useStyles = makeStyles(theme => ({
  replyContainer: {
    marginBlockStart: theme.spacing(1.5),
    paddingBlockStart: theme.spacing(1.5)
  },
  replyHeader: {
    color: theme.palette.text.blue,
    fontSize: "12px",
    fontWeight: 600,
    letterSpacing: "1px",
    textTransform: "uppercase"
  },
  active: {
    paddingInline: theme.spacing(2.5)
  },
  outlined: {
    border: `1px solid ${theme.palette.divider}`
  },

  replyBtn: {
    color: theme.palette.text.blue,
    fontSize: "12px",
    marginBlockEnd: "8px",
    fontWeight: 600,
    textTransform: "uppercase",
    letterSpacing: "1px",
    "&:hover": {
      textDecoration: "none"
    }
  },
  userInput: {
    fontFamily: '"Crimson Pro", "Frank Ruhl Libre"',
    lineHeight: 1.5,
    fontSize: "16px"
  },
  hide: {
    display: "none"
  }
}));

export default function PeerReviewReply({}) {
  // Hooks
  const classes = useStyles();
  const { submission_id } = useQuery();
  const intl = useIntl();
  // Redux state
  const submission = useSelector(state =>
    selectSubmission(state, Number(submission_id))
  );
  const task = useSelector(state => selectTask(state, submission.task_id));
  const review = useSelector(state =>
    selectPeerReviewReview(state, Number(submission_id))
  );

  const reply = useSelector(state =>
    selectPeerReviewReply(state, Number(submission_id))
  );

  // Ephemeral state

  // Variables

  const isActive = isEmpty(reply);
  // Behavior

  function createComment(content) {
    if (content.richText.blocks.every(element => element.text === "")) return;
    const { richText, plainText, wordCount } = content;
    peerReviewAPI.createComment({
      content: plainText,
      rich_text: richText,
      course_id: task.course_id,
      task_id: task.id,
      submission_id: submission.id,
      text_id: task.text_id,
      interaction_id: review.id,
      word_count: wordCount,
      interaction_type: INTERACTION_TYPES.COMMENT,
      interaction_subtype: INTERACTION_SUBTYPES.REPLY
    });
  }

  if (!["Submitted", "Graded"].includes(submission.status)) return null;
  else if (!isActive) {
    return (
      <>
        <Box className={classes.replyHeader}>Reply</Box>
        <Box className={classes.userInput}>
          <RenderEditorContent>{reply.rich_text}</RenderEditorContent>
        </Box>
      </>
    );
  } else {
    return (
      <TextEditor content={reply.rich_text}>
        <TextEditorHeader title="Reply" />
        <TextEditorInput
          fontSize="small"
          placeholder={intl.formatMessage({
            id: "task.open.placeholder.reply",
            defaultMessage: "Type your reply here"
          })}
        />
        <TextEditorFooter>
          <TextEditorButton
            color="secondary"
            onClick={content => createComment(content)}
          >
            Send
          </TextEditorButton>
        </TextEditorFooter>
      </TextEditor>
    );
  }
}
