// Dependencies
import { captureException } from "../../../utils/errorHandlers";

// Redux
import store from "../../store";
import { initMentorReview, openChatbox, CHAT_TYPE } from "../../chatSlice";
import { mentorReviewFabClick } from "../../fabSlice";
// rxJs
import { ofType } from "redux-observable";
import { empty } from "rxjs";
import { tap, map, withLatestFrom, catchError } from "rxjs/operators";

export const mentorReviewFabClickEpic = (action$, state$) => {
  return action$.pipe(
    ofType(mentorReviewFabClick.type),
    withLatestFrom(state$),
    tap(([_, state]) => initMentorIfNeeded(state)),
    map(() => ({ type: openChatbox.type, payload: {} })),
    catchError(error => {
      captureException(error, "Error in mentorReviewFabClickEpic");
      return empty();
    })
  );
};

// utils
function initMentorIfNeeded(state) {
  if (state.chat.conversation.length === 0) {
    store.dispatch(initMentorReview({ chatType: CHAT_TYPE.REVIEW }));
  }
}
