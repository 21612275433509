import { createSlice, createSelector, current } from "@reduxjs/toolkit";
import { selectCurrentTask } from "./tasksSlice";
import ePub from "epubjs";
import { uniqBy } from "lodash";
import { findIndexToInsert } from "../utils/text-utils";
import { USER_TYPE, INTERACTION_SUBTYPES, INTERACTION_TYPES } from "../consts";
import { shallowEqual } from "react-redux";
import { captureException } from "../utils/errorHandlers";

function mapInteractionToBucket(interaction) {
  const { interaction_type: type, interaction_subtype: subtype } = interaction;
  // TODO: there are currently type: CONTAINER and THEME that are not handled here
  if (type === "QUESTION") return "questions";
  else if (type === "SUGGESTION") return "suggestions";
  else if (type === "REASON") return "reasons";
  else if (subtype === "QUOTE") return "highlights";
  else if (type === "ANSWER") return "answers";
  else if (type === "FEEDBACK") return "feedbacks";
  else if (type === "REVIEW") return "reviews";
  else if (type === "COMMENT") return "comments";
}

export const STATUSES = {
  PENDING: "PENDING",
  IDLE: "IDLE"
};

const initialState = {
  selectedInteractionId: null,
  questions: [],
  highlights: [],
  answers: [],
  feedbacks: [],
  reviews: [],
  comments: [],
  suggestions: [],
  reasons: [],
  selectedInteractionForChat: {},
  selectedSuggestion: {}
};

export const interactionsSlice = createSlice({
  name: "interactions",
  initialState,
  extraReducers: {
    "realtimeInteractions/resetThreadsState": (state, value) => {
      return initialState;
    },
    gotSq3r: (state, value) => {
      if (window.location.pathname.includes("task")) return; // Tasks is hack that can be removed in case this callback is not fired when in the tasks route
      state.selectedInteractionId = value.payload.grQuestionId;
    },
    gotAnswers: (state, value) => {
      state.selectedInteractionId = value.payload.selectedQuestion;
    }
  },
  reducers: {
    setSelectedInteractionId: (state, value) => {
      state.selectedInteractionId = value.payload;
    },

    setInteractions: (state, value) => {
      // map the interactions to buckets
      const buckets = value.payload.reduce((accumulator, current) => {
        try {
          const bucket = mapInteractionToBucket(current);
          if (!accumulator[bucket]) accumulator[bucket] = [];
          if (current.interaction_type === INTERACTION_TYPES.SUGGESTION) {
            current.color = "#BBDEFB";
            accumulator[bucket].push(current);
          } else accumulator[bucket].push(current);
        } catch (error) {
          captureException(
            error,
            `Failed to map interaction to bucket. type: ${current.interaction_type}, subtype: ${current.interaction_subtype}`
          );
        }
        return accumulator;
      }, {});

      // update the state with the new buckets
      Object.keys(buckets).forEach(bucket => {
        state[bucket] = buckets[bucket];
      });
    },

    updateInteraction: (state, value) => {
      // Optimistic UI: we're synchronously updating the state while updateInteractionEpic handles updating the server and rolling back if needed
      const { interaction: interactionToUpdate, update } = value.payload;
      const bucket = mapInteractionToBucket(interactionToUpdate);

      const index = state[bucket].findIndex(
        interaction => interaction.id === interactionToUpdate.id
      );

      state[bucket][index] = {
        ...interactionToUpdate,
        ...update,
        status: STATUSES.PENDING
      };
    },

    interactionUpdatedSuccessfully: (state, value) => {
      // interaction was updated successfully on the server, so we can update the state to reflect that

      const { interaction: interactionToUpdate } = value.payload;
      const bucket = mapInteractionToBucket(interactionToUpdate);

      const index = state[bucket].findIndex(
        interaction => interaction.id === interactionToUpdate.id
      );

      state[bucket][index]["status"] = STATUSES.IDLE;
    },

    deleteInteraction: (state, value) => {
      const { id, interactionType } = value.payload;
      state[interactionType] = state[interactionType].filter(
        interaction => interaction.id !== id
      );
    },
    resetQuestions: (state, value) => {
      state.questions = [];
    },
    deleteQuestion: (state, value) => {
      state.questions = state.questions.filter(q => q.id !== value.payload);
      state.highlights = state.highlights.filter(
        h => h.interaction_id !== value.payload
      );
      state.answers = state.answers.filter(
        h => h.interaction_id !== value.payload
      );
    },

    addHighlight: (state, value) => {
      const { cfi } = value.payload;
      let insertAt = findIndexToInsert(state.highlights, value.payload);

      state.highlights.splice(insertAt, 0, value.payload);
    },

    deleteHighlight: (state, value) => {
      const id = value.payload;
      state.highlights = state.highlights.filter(
        highlight => highlight.id !== id
      );
    },
    createQuestion: (state, value) => {
      state.questions.push(Object.assign(value.payload));
    },
    createAnswer: (state, value) => {
      state.answers.push(value.payload);
    },
    createReview: (state, value) => {
      state.reviews.push(value.payload);
    },
    deleteReview: (state, value) => {
      const { id } = value.payload;
      state.reviews = state.reviews.filter(feedback => feedback.id !== id);
    },
    createFeedback: (state, value) => {
      state.feedbacks.push(value.payload);
    },
    deleteFeedback: (state, value) => {
      const { id } = value.payload;
      state.feedbacks = state.feedbacks.filter(feedback => feedback.id !== id);
    },
    createComment: (state, value) => {
      state.comments.push(value.payload);
    },
    deleteComment: (state, value) => {
      const { id } = value.payload;
      state.comments = state.comments.filter(comment => comment.id !== id);
    },
    createInteraction: (state, value) => {
      const {
        id,
        content,
        interactionId = null,
        text_id = null,
        interactionType,
        order,
        title,
        points,
        user_type,
        interaction_type,
        interaction_subtype,
        rich_text
      } = value.payload;

      state[interactionType].push({
        id,
        content,
        interaction_id: interactionId,
        text_id: text_id,
        order,
        title,
        points,
        rich_text,
        user_type,
        interaction_type,
        interaction_subtype
      });
    },
    setInteractionsOrder: (state, value) => {
      const { order, interactionType } = value.payload;
      const staticInteractions = state[interactionType].filter(
        interaction => !order.includes(interaction.id)
      );
      const newOrder = order.map((id, index) => {
        const currentInteraction = state[interactionType].find(
          interaction => interaction.id === id
        );
        return { ...currentInteraction, order: index };
      });
      state[interactionType] = [...staticInteractions, ...newOrder];
    },
    setSelectedInteractionForChat: (state, value) => {
      state.selectedInteractionForChat = value.payload;
    },
    setSelectedSuggestion: (state, value) => {
      state.selectedSuggestion = value.payload;
    }
  }
});

export const {
  setSelectedInteractionId,
  setInteractions,
  createInteraction,
  updateInteraction,
  interactionUpdatedSuccessfully,
  updateInteractionId,
  deleteInteraction,
  resetQuestions,
  addHighlight,
  deleteHighlight,
  createQuestion,
  createAnswer,
  deleteAnswer,
  createReview,
  deleteReview,
  createFeedback,
  deleteFeedback,
  createComment,
  deleteComment,
  deleteQuestion,
  setInteractionsOrder,
  setSelectedInteractionForChat,
  setSelectedSuggestion
} = interactionsSlice.actions;

// Selectors
export const selectQuestionAnswers = createSelector(
  [state => state.interactions.answers, (state, questionId) => questionId],
  (answers, questionId) => {
    return answers.filter(answer => answer.interaction_id === questionId);
  }
);

export const selectAnswerComment = createSelector(
  [state => state.interactions.answers, (state, questionId) => questionId],
  (answers, questionId) => {
    return answers.find(answer => answer.interaction_id === questionId) || {};
  },
  { memoizeOptions: { resultEqualityCheck: shallowEqual } }
);

export const selectQuestionHighlights = createSelector(
  [state => state.interactions.highlights, (state, questionId) => questionId],
  (highlights, questionId) => {
    const filteredHighlights = [...highlights]
      .filter(highlight => highlight.interaction_id === questionId)
      .sort((a, b) => {
        if (a?.order && b?.order) return sortByOrder(a, b);
        else return sortByCfi(a, b);
      });
    return uniqBy(filteredHighlights, "cfi");
  }
);

export const selectPeerReviewQuestion = createSelector(
  [state => state.interactions.questions, (state, task_id) => Number(task_id)],
  (questions, task_id) =>
    questions.find(question => question.task_id === task_id) || {},
  { memoizeOptions: { resultEqualityCheck: shallowEqual } }
);

//TODO: this needs a better name that describes why we are getting the questions without submission ID
export const selectQuestions = createSelector(
  [state => state.interactions.questions, (state, task_id) => task_id],
  (questions, task_id) => {
    return [...questions]
      .filter(question => question.task_id === Number(task_id))
      .filter(question => !question.submission_id)
      .sort((a, b) => sortByOrder(a, b));
  }
);

export const selectSubmissionQuestions = createSelector(
  [state => state.interactions.questions],
  questions => {
    return [...questions].sort((a, b) => sortByUserTypeAndOrder(a, b));
  }
);

export const selectGrQuestions = createSelector(
  state => state.interactions.questions,
  questions => {
    return [...questions]
      .filter(question => question.interaction_type === "QUESTION")
      .filter(question => question.interaction_subtype === "GUIDED_READING");
  }
);

export const selectUserGrQuestions = createSelector(
  selectGrQuestions,
  questions =>
    [...questions]
      .filter(question => question.task_id == null) // using loose equlity becouse the initial UI update returns undefind when the DB returns null
      .sort((a, b) => sortByOrder(a, b))
);

export const selectGrTaskQuestions = createSelector(
  selectGrQuestions,
  questions => {
    return [...questions]
      .filter(question => question.task_id != null) // using loose equlity becouse the initial UI update returns undefind when the DB returns null
      .sort((a, b) => sortByOrder(a, b));
  }
);

export const selectReaderHighlights = createSelector(
  state => state.interactions.highlights,
  highlights => {
    const filteredHighlights = [...highlights]
      .filter(highlight => highlight.interaction_type === "READER")
      .sort((a, b) => {
        if (a?.order && b?.order) return sortByOrder(a, b);
        else return sortByCfi(a, b);
      });
    return uniqBy(filteredHighlights, "cfi");
  }
);

export const selectTextHighlights = createSelector(
  state => state.interactions.highlights,
  state => state.interactions.answers,
  (highlights, answers) => {
    const filteredHighlights = [...highlights, ...answers];
    // return uniqBy(filteredHighlights, "cfi");
    return filteredHighlights;
  }
);
export const selectGrStepOneHighlights = createSelector(
  state => state.interactions.highlights,
  highlights => {
    const filteredHighlights = [...highlights]
      .filter(
        highlight =>
          // exclude READER highlights
          highlight.interaction_type === "ANSWER" &&
          !highlight.interaction_id &&
          !highlight.task_id
      )
      .sort((a, b) => sortByOrder(a, b));
    return uniqBy(filteredHighlights, "cfi");
  }
);

export const selectCurrentTaskHighlights = createSelector(
  state => state.interactions.highlights,
  selectCurrentTask,
  (highlights, task) => {
    const filteredHighlights = highlights
      .filter(
        highlight =>
          highlight.task_id === task.id &&
          highlight.user_type !== USER_TYPE.MENTOR
      )
      .sort((a, b) => sortByOrder(a, b));
    return uniqBy(filteredHighlights, "cfi");
  }
);

export const selectedQuestionHighlights = createSelector(
  state => state.interactions.highlights,
  state => state.interactions.selectedInteractionId,
  (highlights, questionId) => {
    const filteredHighlights = [...highlights]
      .filter(
        highlight =>
          highlight.interaction_id === questionId &&
          highlight.user_type !== USER_TYPE.MENTOR
      )
      .sort((a, b) => {
        if (a.hasOwnProperty("order") && b.hasOwnProperty("order"))
          return sortByOrder(a, b);
        else return sortByCfi(a, b);
      });
    return uniqBy(filteredHighlights, "cfi");
  }
);

export const questionHighlights = createSelector(
  [state => state.interactions.highlights, (state, questionId) => questionId],
  (highlights, questionId) => {
    const filteredHighlights = [...highlights]
      .filter(
        highlight =>
          highlight.interaction_id === questionId &&
          highlight.user_type !== USER_TYPE.MENTOR
      )
      .sort((a, b) => {
        if (a?.order && b?.order) return sortByOrder(a, b);
        else return sortByCfi(a, b);
      });
    return uniqBy(filteredHighlights, "cfi");
  }
);

export const selectCurrentGrStepHighlights = createSelector(
  [
    state => state.interactions.highlights,
    state => state.gr.stage,
    state => state.gr.selectedQuestionId
  ],
  (highlights, step, questionId) => {
    if (step === 0) {
      const filteredHighlights = [...highlights]
        .filter(highlight => !highlight.interaction_id)
        .sort((a, b) => sortByOrder(a, b));
      return uniqBy(filteredHighlights, "cfi");
    } else if (step === 1) {
      const filteredHighlights = [...highlights]
        .filter(highlight => highlight.interaction_id === questionId)
        .sort((a, b) => sortByOrder(a, b));
      return uniqBy(filteredHighlights, "cfi");
    } else return [];
  }
);

// TODO: selectCurrentQuestion and selectCurrentInteraction are redundent
// Since we want to save the state of every part of the app seperatly, we won't be able to use selectedInteractionId. We need to refactor this and save a seperate attribute for, grQuestion, task question ect.

export const selectCurrentQuestion = createSelector(
  state => state.interactions.questions,
  state => state.interactions.selectedInteractionId,
  (questions, questionId) => {
    return questions.find(question => question.id === questionId) || {};
  },
  { memoizeOptions: { resultEqualityCheck: shallowEqual } }
);

export const selectCurrentInteraction = createSelector(
  state => state.interactions.questions,
  state => state.interactions.selectedInteractionId,
  (questions, questionId) => {
    return questions.find(question => question.id === questionId) || {};
  },
  { memoizeOptions: { resultEqualityCheck: shallowEqual } }
);

export const selectSubmissionFeedback = createSelector(
  [
    state => state.interactions.feedbacks,
    (state, submission_id) => Number(submission_id)
  ],
  (feedbacks, submission_id) => {
    return (
      feedbacks
        .filter(feedback => !feedback.interaction_id)
        .find(feedback => feedback.submission_id === submission_id) || {}
    );
  },
  { memoizeOptions: { resultEqualityCheck: shallowEqual } }
);

export const selectTaskFeedbacks = createSelector(
  [state => state.interactions.feedbacks, (state, task_id) => task_id],
  (feedbacks, task_id) => {
    return feedbacks.filter(feedback => feedback.task_id === task_id);
  }
);

export const selectSubmissionTaskFeedback = createSelector(
  [
    state => state.interactions.feedbacks,
    (state, submission_id) => submission_id
  ],
  (feedbacks, submission_id) => {
    return (
      feedbacks
        .filter(feedback => feedback.submission_id === submission_id)
        .find(
          feedback => feedback.interaction_subtype === INTERACTION_SUBTYPES.TASK
        ) || {}
    );
  }
);

export const selectQuestionFeedback = createSelector(
  [selectCurrentInteraction, state => state.interactions.feedbacks],
  (question, feedbacks) => {
    return (
      feedbacks.filter(feedback => feedback.interaction_id === question.id) ||
      []
    );
  }
);

export const selectTeacherQuestionFeedback = createSelector(
  [selectQuestionFeedback],
  questionFeedbacks => {
    return (
      questionFeedbacks.find(
        feedback => feedback.user_type === USER_TYPE.TEACHER
      ) || {}
    );
  },
  { memoizeOptions: { resultEqualityCheck: shallowEqual } }
);

export const selectMentorQuestionFeedback = createSelector(
  [selectQuestionFeedback],
  questionFeedbacks => {
    return (
      questionFeedbacks.find(
        feedback => feedback.user_type === USER_TYPE.MENTOR
      ) || {}
    );
  },
  { memoizeOptions: { resultEqualityCheck: shallowEqual } }
);

export const selectInteractionHighlight = createSelector(
  state => state.interactions.highlights,
  (state, interactionId) => interactionId,
  (highlights, interactionId) => {
    return highlights.filter(
      highlight => highlight.interaction_id === interactionId
    );
  }
);

export const selectPeerReviewReflection = createSelector(
  [
    state => state.interactions.answers,
    (state, submission_id) => submission_id
  ],
  (answers, submission_id) => {
    return answers.find(answer => answer.submission_id === submission_id) || {};
  },
  { memoizeOptions: { resultEqualityCheck: shallowEqual } }
);

export const selectPeerReviewReview = createSelector(
  [
    state => state.interactions.reviews,
    (state, submission_id) => submission_id
  ],
  (reviews, submission_id) => {
    return reviews.find(review => review.submission_id === submission_id) || {};
  },
  { memoizeOptions: { resultEqualityCheck: shallowEqual } }
);

export const selectPeerReviewReply = createSelector(
  [
    state => state.interactions.comments,
    (state, submission_id) => submission_id
  ],
  (comments, submission_id) => {
    return (
      comments.find(comment => comment.submission_id === submission_id) || {}
    );
  },
  { memoizeOptions: { resultEqualityCheck: shallowEqual } }
);

export const selectSuggestionsByQuestionId = createSelector(
  [
    state => state.interactions.suggestions,
    state => state.interactions.selectedInteractionId
  ],
  (suggestions, selectedInteractionId) => {
    let filteredSuggestions = suggestions
      .filter(suggestion => {
        return suggestion.interaction_id === selectedInteractionId;
      })
      .sort((a, b) => {
        return sortByCfi(a, b);
      });
    return filteredSuggestions;
  }
);
export const findCurrentSuggestionIndex = createSelector(
  [
    state => state.interactions.suggestions,
    state => state.interactions.selectedSuggestion,
    state => state.interactions.selectedInteractionId
  ],
  (suggestions, selectedSuggestion, selectedInteractionId) => {
    const filteredSuggestions = suggestions.filter(suggestion => {
      return suggestion.interaction_id === selectedInteractionId;
    });
    if (!filteredSuggestions.length) return 0;
    const index = filteredSuggestions
      .sort((a, b) => sortByCfi(a, b))
      .findIndex(obj => obj.id === selectedSuggestion.id);
    if (index < 0) return filteredSuggestions.length - 1;
    else return index;
  }
);

export const selectedReasonBySuggestionId = createSelector(
  [
    state => state.interactions.selectedSuggestion,
    state => state.interactions.reasons
  ],
  (selectedSuggestion, reasons) => {
    if (!Object.hasOwn(selectedSuggestion, "id")) return reasons[0];
    const selectedReason = reasons.find(
      reason => reason.interaction_id === selectedSuggestion.id
    );
    return selectedReason;
  }
);

// Utils
export function sortByOrder(a, b) {
  if (!("order" in a) || a.order > b.order) return 1;
  else if (!("order" in b) || a.order < b.order) return -1;
  else return 0;
}

function sortByCfi(a, b) {
  let EpubCFI = new ePub.CFI();
  return EpubCFI.compare(a.cfi, b.cfi);
}

function sortByUserTypeAndOrder(a, b) {
  if (!("order" in a)) return 1;

  // Sort by user type (teacher first) and order
  if (a.user_type === b.user_type) return a.order - b.order;
  else if (a.user_type === USER_TYPE.TEACHER) return -1;
  else if (a.user_type !== USER_TYPE.TEACHER) return 1;
}

export default interactionsSlice.reducer;
