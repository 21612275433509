// Dependencies
import React from "react";
import clsx from "clsx";
import { useHistory } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { firebaseFunctions } from "../../../firebase";
import { captureException } from "../../../utils/errorHandlers";
import { useQuery } from "../../../hooks";
import { v4 as uuid } from "uuid";

// Redux dependencies
import { useSelector, useDispatch } from "react-redux";
import { setSubmittedStudentReview } from "../../../redux/taskSlice";
import { enqueueFlashMessage } from "../../../redux/userSlice";
import { setIsLoading } from "../../../redux/readerActionsSlice";
import { removeActiveTaskPerText } from "../../../redux/firebaseMiddleware";
import {
  selectTask,
  selectSubmissionCollaboration,
  updateTaskSubmissionStatus
} from "../../../redux/tasksSlice";
import { selectCourseByTaskId } from "../../../redux/coursesSlice";

import makeStyles from '@mui/styles/makeStyles';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from "@mui/material";
import { selectTextDirection } from "../../../redux/firestoreSelectors";
import { addSnackbarItem } from "../../../redux/snackbarSlice";

// Styles
const useStyles = makeStyles(theme => ({
  // this is styling the paper component within the dialog

  reviewModal: {
    width: "50%",
    padding: "10px",
    textAlign: "center"
  },
  modalCancel: {
    position: "absolute",
    top: "10px",
    right: "10px",
    cursor: "pointer"
  },
  reviewModalActions: {
    justifyContent: "center",
    "& button": {
      fontSize: "inharit",
      fontWeight: "600"
    }
  },

  button: { textTransform: "none" },
  buttonDiscard: {
    color: "#787877"
  }
}));

export default function PeerReviewReviewModal({
  dialogOpen,
  setDialogOpen,
  co_author
}) {
  //Hooks
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { submission_id } = useQuery();

  //Redux state
  const submission = useSelector(state =>
    selectSubmissionCollaboration(state, Number(submission_id))
  );
  const task = useSelector(state => selectTask(state, submission.task_id));
  const course = useSelector(state =>
    selectCourseByTaskId(state, submission.task_id)
  );
  const textDirection = useSelector(state => selectTextDirection(state));
  const userDisplayName = useSelector(state => state.user.auth.displayName);
  const alertsDuration = useSelector(
    state => state.user.userProfile.alertsDuration
  );

  //Ephemeral state

  // Variables

  //Behavior

  const closeModal = () => {
    setDialogOpen(false);
  };

  async function handleSubmitReview() {
    try {
      dispatch(setIsLoading(true));
      const submitReview = firebaseFunctions.httpsCallable(
        "courses-submitPeerReviewReview"
      );
      let submission_id = submission.id;
      let related_submission_id = submission.related_submission_id;

      const { data } = await submitReview({
        task_id: task.id,
        submission_id: submission_id,
        student_name: userDisplayName
      });
      if (data) {
        dispatch(
          updateTaskSubmissionStatus({
            id: submission_id,
            status: "Submitted",
            submission_date: new Date().toJSON()
          })
        );
        dispatch(setSubmittedStudentReview(task.id));
        dispatch(
          addSnackbarItem({
            related_submission_id: related_submission_id,
            submission_id: submission_id,
            actions: [
              {
                intlId: "undo",
                intlDefaultMsg: "undo",
                callBack: "undoReviewSubmission"
              }
            ],
            intlId: "tasks.submitted",
            intlDefaultMessage: "Review was shared successfully",
            id: uuid() // TODO: we are not capturing this id, why do we need to pass it and not create it in the SnackBar component?
          })
        );
        dispatch(removeActiveTaskPerText({ text_id: task.text_id }));
        dispatch(setIsLoading(false));
        history.push(`/tasks?course_id=${course.id}`);
      } else {
        throw new Error("Failed to submit peer review review");
      }
    } catch (err) {
      dispatch(setIsLoading(false));
      dispatch(
        enqueueFlashMessage({
          message:
            "There was a problem submittion the review. Please check your connection and try again",
          duration: alertsDuration
        })
      );
      captureException(err, `task: ${task.id}, submission: ${submission.id}`);
    }
  }

  // Render
  return (
    <>
      <div>
        <Dialog
          open={dialogOpen}
          onClose={closeModal}
          aria-labelledby="alert-dialog-title"
          PaperProps={{
            className: classes.reviewModal,
            style: {
              direction: textDirection
            }
          }}
        >
          <DialogTitle id="alert-dialog-title">
            <FormattedMessage
              id="task.peerReview.modal.title"
              defaultMessage="Submit review"
            />
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <FormattedMessage
                id="task.peerReview.modal.content"
                // TODO: get teacher and student name
                defaultMessage={`Your review will be avaliable to ${co_author} and to your professor`}
              />
            </DialogContentText>
          </DialogContent>
          <DialogActions className={classes.reviewModalActions}>
            <Button
              onClick={closeModal}
              className={clsx(classes.button, classes.buttonDiscard)}
            >
              <FormattedMessage id="cancel" defaultMessage="Cancel" />
            </Button>
            <Button
              className={classes.button}
              onClick={handleSubmitReview}
              color="primary"
              disableElevation
            >
              <FormattedMessage id="task.submit" defaultMessage="Submit" />
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
}
