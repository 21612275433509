import React, { useState, useEffect, useCallback } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import update from "immutability-helper";
import ePub from "epubjs";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { useSelector, useDispatch } from "react-redux";
import { updateSq3r } from "../../../redux/firebaseMiddleware";
import {
  selectQuestions,
  selectUserGrQuestions,
  selectGrTaskQuestions
} from "../../../redux/interactionsSlice";
import { grAPI, interactionsAPI } from "../../../api";

import { setOpenQuestions } from "../../../redux/grSlice";
import Question from "./Question";
import NewQuestion from "./NewQuestion";
import makeStyles from "@mui/styles/makeStyles";
import {
  List,
  ListItem,
  Box,
  StyledEngineProvider,
  ThemeProvider,
  Skeleton
} from "@mui/material";
import AddBtn from "./AddBtn";
import { useGetTheme } from "../../../hooks";
import { selectIsCloningQuestions } from "../../../redux/queuedTaskSelectors";
import { ScrollBox } from "../../SharedComponents";

const useStyles = makeStyles(theme => ({
  questionsManager: {
    paddingInline: 0
  },
  question: {
    paddingBlock: 0,
    "&:not(:last-child)": {
      borderBottom: "1px solid rgba(255, 255, 255, 0.1)"
    }
  },
  forceBottomBorder: {
    "&:last-child": {
      borderBottom: "1px solid rgba(255, 255, 255, 0.1)"
    }
  },
  panelHeader: {
    color: "white",
    background: theme.palette.background.sidePanel,
    display: "flex",
    justifyContent: "space-between",
    paddingBlock: theme.spacing(2),
    paddingInline: theme.spacing(1.5),
    zIndex: 10,
    width: "100%"
  },
  left: {
    textAlign: "left"
  }
}));

export default function QuestionManager({
  scrollToBottom,
  color = "secondary",
  locked = false
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const EpubCFI = new ePub.CFI();
  const theme = useGetTheme({ alwase: "dark" });

  const questions = useSelector(selectQuestions);

  const questionsCloning = useSelector(state =>
    selectIsCloningQuestions(state)
  );

  const grTaskQuestions = useSelector(selectGrTaskQuestions);
  const userQuestions = useSelector(selectUserGrQuestions);
  const highlights = useSelector(state => state.interactions.highlights);
  const answers = useSelector(state => state.interactions.answers);
  const selectedTextId = useSelector(state => state.texts.selectedTextId);
  const stage = useSelector(state => state.gr.stage);
  const openQuestions = useSelector(state => state.gr.openQuestions);
  const grMode = useSelector(state => state.gr.mode);
  const selectedQuestionId = useSelector(
    state => state.interactions.selectedInteractionId
  );
  const renderAddBtn =
    (grMode === "full" && stage === 1) ||
    (grMode === "light" && [0, 1].includes(stage));

  const [newQuestionMode, setNewQuestionMode] = useState({
    adding: false,
    value: ""
  });

  useEffect(() => {
    if (stage === 4) {
      dispatch(setOpenQuestions([])); //TODO: find out why we are clearing this when in stage 4
    }
    if (
      stage > 0 &&
      grMode === "light" &&
      !selectedQuestionId &&
      !newQuestionMode.adding
    ) {
      const qId = grTaskQuestions[0]?.id || userQuestions[0]?.id || null;
      grAPI.updateSelectedQuestionId(qId);
    }
    /* eslint-disable */
  }, [dispatch, stage, grMode, grTaskQuestions, userQuestions]);
  /* eslint-enable */

  const addingNewQuestion =
    ((grMode === "full" && stage === 1) || (grMode === "light" && stage < 2)) &&
    newQuestionMode.adding;

  const toggleOpenQuestion = id => {
    if (openQuestions.includes(id)) {
      dispatch(setOpenQuestions(openQuestions.filter(el => el !== id)));
    } else {
      dispatch(setOpenQuestions([...openQuestions, id]));
    }
  };

  useEffect(() => {
    if (newQuestionMode.addValue && newQuestionMode.shouldAdd) {
      setNewQuestionMode({
        value: "",
        adding: newQuestionMode.adding,
        addValue: "",
        shouldAdd: false
      });
    }
  }, [newQuestionMode, dispatch, highlights, questions, selectedTextId]);

  const onDragEnd = result => {
    if (!result.destination) return;

    const rollback = userQuestions.map(q => q.id);
    const order = [...rollback];
    order.splice(result.source.index, 1);
    order.splice(
      result.destination.index,
      0,
      userQuestions[result.source.index].id
    );
    interactionsAPI.updateQuestionOrder(order, rollback);
  };

  const onDelete = async question => {
    interactionsAPI.deleteQuestion(
      question,
      highlights.filter(h => h.interaction_id === question.id),
      answers.find(c => c.interaction_id === question.id)
    );
  };

  const moveCard = useCallback(
    (dragIndex, hoverIndex) => {
      const dragCard = questions[dragIndex];
      let updatedQuestions = update(questions, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragCard]
        ]
      });

      dispatch(
        updateSq3r({
          text_id: selectedTextId,
          questions: updatedQuestions,
          highlights: highlights
        })
      );
    },
    [questions, dispatch, highlights, selectedTextId]
  );

  function renderQuestionComponent(question, index, allowQuestionEdit) {
    return (
      <Question
        index={index}
        openQuestions={openQuestions}
        onDelete={onDelete}
        moveCard={moveCard}
        color={color}
        question={question}
        locked={locked}
        allowQuestionEdit={allowQuestionEdit && !locked}
        toggleOpenQuestion={toggleOpenQuestion}
        epubCFI={EpubCFI}
      />
    );
  }

  return (
    <>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          {renderAddBtn && (
            <Box className={clsx(classes.panelHeader, classes.left)}>
              <AddBtn
                editable={!locked}
                color={color}
                scrollToBottom={scrollToBottom}
                newQuestionMode={newQuestionMode}
                setNewQuestionMode={setNewQuestionMode}
              />
            </Box>
          )}
          <>
            <ScrollBox className={clsx(classes.questionsManager)}>
              <List disablePadding dense>
                {!!grTaskQuestions &&
                  grTaskQuestions.map((question, index) => {
                    return (
                      <ListItem
                        key={index}
                        disableGutters
                        className={clsx(classes.question, {
                          [classes.forceBottomBorder]: userQuestions.length > 0
                        })}
                      >
                        {renderQuestionComponent(question, index, false)}
                      </ListItem>
                    );
                  })}
              </List>

              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable">
                  {(provided, snapshot) => (
                    <List
                      disablePadding
                      dense
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                    >
                      {!!userQuestions &&
                        userQuestions.map((question, index) => {
                          return (
                            <ListItem
                              key={index}
                              disableGutters
                              className={classes.question}
                            >
                              {renderQuestionComponent(question, index, true)}
                            </ListItem>
                          );
                        })}
                      {provided.placeholder}
                      {questionsCloning && (
                        <ListItem disablePadding>
                          <Skeleton
                            variant="rectangle"
                            height={48}
                            width="100%"
                            animation="wave"
                          />
                        </ListItem>
                      )}
                    </List>
                  )}
                </Droppable>
              </DragDropContext>

              {addingNewQuestion && (
                <NewQuestion
                  color={color}
                  newQuestionMode={newQuestionMode}
                  setNewQuestionMode={setNewQuestionMode}
                  scrollToBottom={scrollToBottom}
                />
              )}
            </ScrollBox>
          </>
        </ThemeProvider>
      </StyledEngineProvider>
    </>
  );
}

QuestionManager.propTypes = {
  color: PropTypes.oneOf(["secondary", "primary"]),
  scrollToBottom: PropTypes.func,
  locked: PropTypes.bool
};
