import { firestore, auth } from "../firebase";
import { setSelectedQuestion } from "../redux/grSlice";
import { updateTaskSubmissionStatus } from "../redux/tasksSlice";
import { httpCallables } from "../firebase";
import store from "../redux/store";
import { addSnackbarItem } from "../redux/snackbarSlice";
import { v4 as uuid } from "uuid";
import { captureException } from "../utils/errorHandlers";
import userAPI from './user';
import { updateGrStateUiOnly } from "../redux/grSlice";
import { add } from "date-fns";
import {
  getFirestore
} from "redux-firestore";
const grAPI = {

  updateGrState:(payload) =>{
    const state = store.getState();
    const userId = state.firebase.auth.uid;
    const textId = state.texts.selectedTextId;
    const isImpersonation = state.user?.original_auth !== -1;

    if (isImpersonation) store.dispatch(updateGrStateUiOnly(payload));
    else
      getFirestore()
        .set(`sq3r/${userId}/texts/${textId}`, payload, { merge: true })
        .then(() => {
          const now = new Date();
          userAPI.log({
            action_name: "GR_STATE_UPDATE",
            user_id: userId,
            text_id: textId,
            created_at: now,
            ttl: add(now, { months: 1 }),
            payload: payload
          });
        })
        .catch(err => {
          captureException(err, `Failed to save GR status to firebase`);
        })
},


  updateSelectedQuestionId: async function (qid) {
    const currentSelectedQuestionId =
      store.getState().interactions.selectedInteractionId;
    const selectedTextId = store.getState().texts.selectedTextId;
    try {
      store.dispatch(setSelectedQuestion(qid));
      await firestore
        .doc(`sq3r/${auth.currentUser.uid}/texts/${selectedTextId}`)
        .update({
          grQuestionId: qid
        });
    } catch (err) {
      captureException(
        err,
        `"Failed to update selected question id. user: ${auth.currentUser.uid}, questionId: ${qid} `
      );
      store.dispatch(setSelectedQuestion(currentSelectedQuestionId));
    }
  },
  updateGrTaskSubmission: async function (submission_id, text_id, taskId) {
    try {
      store.dispatch(
        updateTaskSubmissionStatus({
          id: submission_id,
          status: "Submitted",
          submission_date: new Date().toJSON()
        })
      );
      await httpCallables.taskFunctions({ func_name:'updateGrTaskSubmission', submission_id });
      store.dispatch(
        addSnackbarItem({
          actions: [
            {
              intlId: "undo",
              intlDefaultMsg: "undo",
              callBack: "undoSubmitGr"
            },
            {
              intlId: "task.submission.continue",
              intlDefaultMsg: "Continue work in reader",
              callBack: "goToReader"
            }
          ],
          intlId: "tasks.submitted",
          intlDefaultMessage: "Task submitted successfully",
          id: uuid(),
          submission_id,
          text_id,
          taskId
        })
      );
      return true;
    } catch (err) {
      captureException(
        err,
        `Failed to submit task, user: ${auth.currentUser.uid} submissionId: ${submission_id}`
      );
      return false;
    }
  },
  undoGrTaskSubmission: async function (submission_id) {
    try {
      store.dispatch(
        updateTaskSubmissionStatus({
          id: submission_id,
          status: "Pending",
          submission_date: null
        })
      );
      await httpCallables.taskFunctions({func_name:'undoGrTaskSubmission', submission_id });
      store.dispatch(
        addSnackbarItem({
          intlId: "tasks.submission.canceled",
          intlDefaultMessage: "Task submission canceled",
          id: uuid()
        })
      );
    } catch (err) {
      captureException(
        err,
        `Failed to un-submit gr task, user: ${auth.currentUser.uid} submissionId: ${submission_id}`
      );
      return false;
    }
  }
};

export default grAPI;
