// Dependencies
import React, { useRef } from "react";
import clsx from "clsx";
import { Link as RouterLink, useLocation } from "react-router-dom";

// Components

//Redux
import { useSelector, useDispatch } from "react-redux";
import { setProfile } from "./redux/userSlice";

import makeStyles from "@mui/styles/makeStyles";
import { AppBar, Toolbar, Link, Box, Breadcrumbs } from "@mui/material";
import { setBreadcrumbsClicked } from "./redux/readerActionsSlice";
import {
  selectIsSuggestions,
  selectTextDirection
} from "./redux/firestoreSelectors";
import DirectionalChevronIcon from "./components/SharedComponents/DirectionalChevronIcon";
import HeaderButtonConstructor from "./components/SharedComponents/HeadeButtonConstructor";
import Brightness2Icon from "@mui/icons-material/Brightness2";
import Brightness2OutlinedIcon from "@mui/icons-material/Brightness2Outlined";
// Styles
const useStyles = makeStyles(theme => {
  return {
    readerToolbar: {
      display: "flex",
      flexFlow: "row",
      height: "56px",
      minHeight: "56px",
      position: "relative",
      justifyContent: "space-between",
      alignItems: "center",
      paddingLeft: "20px"
    },
    breadcrumbs: {
      height: "56px",
      display: "flex",
      flexFlow: "row",
      scrollbarWidth: "0px",
      scrollbarHeight: "0px",
      color: "#FFFFFF",
      backgroundImage: "none",
      "_& ol": {
        height: "100%",
        flexWrap: "nowrap"
      },
      get "& ol"() {
        return this["_& ol"];
      },
      set "& ol"(value) {
        this["_& ol"] = value;
      },
      "&::-webkit-scrollbar": {
        width: "0",
        height: "0" /* Remove scrollbar space */,
        background: "transparent" /* Optional: just make scrollbar invisible */
      },
      whiteSpace: "nowrap",
      width: "auto",
      overflow: "hidden",
      textOverflow: "ellipsis"
    },
    chivo: {
      fontFamily: theme.typography.fontFamily
    },
    pointer: {
      cursor: "pointer",
      display: "flex",
      flexFlow: "row"
    },
    readerBar: {
      backgroundImage: "none",
      backgroundColor: "#2E7D32",
      position: "sticky",
      top: "0",
      zIndex: 0
    },
    blueBg: {
      backgroundColor: "#003C8F",
      backgroundImage: "none"
    },
    headerActionsContainer: {
      display: "flex",
      flexFlow: "row",
      justifyContent: "flex-end"
    },
    headerControls: {
      display: "flex",
      flexFlow: "row",
      flexGrow: 1,
      height: "56px"
    },
    globalAction: {
      display: "inline-block",
      "& button": {
        paddingInline: theme.spacing(2),
        marginInlineStart: theme.spacing(3),
        marginTop: theme.spacing(1),
        fontSize: "13px",
        fontWeight: "bold",
        letterSpacing: "1px",
        backgroundColor: "#ffffff",
        color: "#168FEE",
        "&:hover": {
          backgroundColor: "#E8E8E8",
          color: "#168FEE"
        },
        "&:disabled": {
          backgroundColor: theme.palette.action.disabledBackground,
          color: theme.palette.text.disabled
        }
      }
    },
    pdfNavButtons: {
      flexGrow: 1,
      display: "flex",
      justifyContent: "center"
    },
    lightIcon: {
      color: "#FFFFFF",
      backgroundColor: "transparent",
      "&:hover": {
        backgroundColor: "rgba(255, 255, 255, 0.1)"
      }
    },
    darkModeBtn: {
      transform:
        theme.direction === "rtl" ? "rotate(-120deg)" : "rotate(120deg)"
    }
  };
});

export default function TopBar() {
  // Hooks
  const classes = useStyles();
  const dispatch = useDispatch();

  const appBarRef = useRef(null);
  const headerMenuRef = useRef();
  const userProfile = useSelector(state => state.user.userProfile);

  const isDarkMode = userProfile.darkMode;

  const textDirection = useSelector(state => selectTextDirection(state));
  const breadcrumbs = useSelector(state => state.readerActions.breadcrumbs);
  const isSuggestions = useSelector(state => selectIsSuggestions(state));

  const location = useLocation();
  const activeGrTaskId = useSelector(state => state.tasks.activeGrTaskId);
  const taskId = useSelector(state => state.router.location.query.taskId);

  const blueBreadcrumbs =
    location.pathname.includes("task") ||
    (location.pathname.includes("text") && (activeGrTaskId || taskId));

  const getMenuWidth = () => {
    let rect =
      headerMenuRef &&
      headerMenuRef.current &&
      headerMenuRef.current.getBoundingClientRect();

    return rect && rect.width ? "" + (rect.width + 32) + "px" : "0px";
  };

  const renderBreadcrumbs = () => {
    let menuWidth = getMenuWidth();
    let styleObj = { maxWidth: "calc(100vw - " + menuWidth + ")" };
    return (
      breadcrumbs && (
        <Breadcrumbs
          className={classes.breadcrumbs}
          style={styleObj}
          maxItems={2}
          separator={<DirectionalChevronIcon fontSize={"small"} />}
          aria-label="breadcrumb"
        >
          {breadcrumbs
            .filter(a => a?.text)
            .map((part, i) => (
              <Link
                color="inherit"
                data-test-class="bradcrumb"
                component={RouterLink}
                to={part.url ? part.url : "#"}
                underline="hover"
                key={i}
                className={clsx(
                  classes.chivo,
                  classes.pointer,
                  classes.breadcrumbItem
                )}
                onClick={() => {
                  dispatch(setBreadcrumbsClicked(part));
                }}
                role="link" //FIXME: Change this element to a link and get rid of role and tabindex
                tabIndex="0"
                aria-current={i === breadcrumbs.length - 1 ? "page" : false} // TODO: find better way to check if this is the current page
              >
                {part.text}
              </Link>
            ))}
        </Breadcrumbs>
      )
    );
  };

  return (
    <AppBar
      component="div"
      className={clsx(classes.readerBar, blueBreadcrumbs && classes.blueBg)}
      ref={appBarRef}
      elevation={1}
      dir={textDirection}
    >
      <Toolbar className={classes.readerToolbar}>
        <Box>{renderBreadcrumbs()}</Box>
        <Box className={classes.headerControls}>
          <Box
            className={classes.pdfNavButtons}
            id="global-pdfControl-btn"
          ></Box>
          <Box ref={headerMenuRef} className={classes.headerActionsContainer}>
            <Box
              id="global-headerToolBar-btn"
              className={classes.headerToolBar}
            />
            <Box
              id="global-thumbnails-btn"
              className={classes.globalThumbnails}
            />
            <Box id="global-comments-btn" className={classes.globalComments} />
            <Box id="global-annonymize-btn" className={classes.headerToolBar} />
            <Box
              id="global-suggestions-btn"
              className={isSuggestions && classes.globalSuggestions}
            />
            <Box component="span">
              <HeaderButtonConstructor
                intlStringId="menu.tooltip.darkMode"
                defaultMessage="Dark mode"
                placement="bottom"
                aria-label="Dark mode"
                className={clsx(classes.lightIcon, classes.darkModeBtn)}
                handleClick={() => {
                  dispatch(
                    setProfile({ ...userProfile, darkMode: !isDarkMode })
                  );
                }}
                icon={
                  isDarkMode ? <Brightness2Icon /> : <Brightness2OutlinedIcon />
                }
              />
            </Box>
            <Box id="global-action-btn" className={classes.globalAction} />
          </Box>
        </Box>
      </Toolbar>
    </AppBar>
  );
}
