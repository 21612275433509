// Dependencies
import React, { useEffect, useState } from "react";
import { useQuery } from "../../../../hooks";
import { v4 as uuid } from "uuid";

// Redux
import { selectCourse } from "../../../../redux/coursesSlice";
import { useDispatch, useSelector } from "react-redux";

// Components
import { ScrollBox } from "../../../SharedComponents";
import DateRangeSelection from "../../../SharedComponents/DateRangeSelection";
import StudentProgressChart from "./StudentProgressChart";
import ClassGradeDistChart from "./ClassGradeDistChart";
import AvaragesWidget from "./AvaragesWidget";
import TaskListTable from "./TaskListTable";
import EngagementChart from "../../../admin/reports/courseActivity/charts/classEngagementChart/EngagmentChart";

// Material UI
import { Box, Grid, Paper, Skeleton, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { startOfDay } from "date-fns";
import { firebaseFunctions, httpCallables } from "../../../../firebase";
import { addSnackbarItem } from "../../../../redux/snackbarSlice";
import { captureException } from "@sentry/react";
import {
  calculateStudentsAvarages,
  calculateSubmissionsGradeAvarage,
  getStudentIdsFromSubmissions
} from "../utils";

// Styles
const useStyles = makeStyles(theme => ({
  container: {
    paddingBlock: theme.spacing(3),
    paddingInline: theme.spacing(15)
  },
  header: {
    marginBlock: theme.spacing(5)
  },
  widgetHeader: {
    display: "flex",
    justifyContent: "space-between",
    padding: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.divider}`
  },

  engagementChart: { height: 550, flexGrow: 1 }
}));

function StudentReport() {
  // Hooks
  const dispatch = useDispatch();
  const { course_id, user_id } = useQuery();
  const classes = useStyles();

  // Global state
  const course = useSelector(state => selectCourse(state, Number(course_id)));

  // Derived state
  const today = startOfDay(new Date());
  const courseStart = startOfDay(new Date(course.course_start));

  const includeInEngagementChart = [
    "ENGAGEMENT",
    "SESSIONS",
    "READING_SESSIONS",
    "WRITING_SESSIONS",
    "COMMENTS_CREATED",
    "CITATIONS_CREATED",
    "QUESTIONS_CREATED"
  ];

  // Ephemeral State
  const [student, setStudent] = useState(null);
  const [start, setStart] = useState(courseStart);
  const [end, setEnd] = useState(today);
  const [classAvaragesPerStudent, setClassAvaragesPerStudent] = useState([]);
  const [classAvarage, setClassAvarage] = useState(0);
  const [studentAvarage, setStudentAvarage] = useState(0);
  const [studentName, setStudentName] = useState("Student");
  // Behavior

  useEffect(() => {
    httpCallables.getUser({ user_id }).then(({ data }) => {
      const { success } = data;
      if (success) {
        const parsedData = JSON.parse(data.payload);
        const { user } = parsedData;
        setStudent(user);
      } else {
        const { error } = data;
        dispatch(
          addSnackbarItem({
            intlId: "error.generateUserActivityReportFailed",
            intlDefaultMessage:
              "There was a problem getting the student's information. Please check your connection and try again",
            id: uuid()
          })
        );

        captureException(error, `Faild to get user`);
      }
    });
  }, [dispatch, user_id]);

  useEffect(() => {
    const taskFunctions = firebaseFunctions.httpsCallable(
      "tasks-taskFunctions"
    );
    taskFunctions({
      func_name: "readTeacherCourseTasks",
      course_id: Number(course_id),
      start,
      end
    }).then(({ data }) => {
      const { submissions } = JSON.parse(data.payload);
      // class per student
      const students = getStudentIdsFromSubmissions(submissions);
      const classAvarages = calculateStudentsAvarages(students, submissions);
      setClassAvaragesPerStudent(classAvarages);

      // class
      const classAvarage = calculateSubmissionsGradeAvarage(submissions);
      setClassAvarage(classAvarage);

      // Student
      const studentSubmissions = submissions.filter(
        submission => submission.owner === user_id
      );
      const studentAvarage =
        calculateSubmissionsGradeAvarage(studentSubmissions);

      setStudentAvarage(studentAvarage);
      studentSubmissions.length &&
        setStudentName(studentSubmissions[0].user_name);
    });
  }, [course_id, end, start, user_id]);

  return (
    <ScrollBox className={classes.container}>
      <Box className={classes.header}>
        <Typography variant="h4">Student Dashboard</Typography>
        <Typography variant="h5">
          {student ? student.name : <Skeleton width={200} />}
        </Typography>
      </Box>
      <DateRangeSelection
        start={start}
        setStart={setStart}
        end={end}
        setEnd={setEnd}
        maxDate={today}
      />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TaskListTable start={start} end={end} />
        </Grid>

        <Grid item xs={12}>
          <Paper elevation={0} className={classes.engagementChart}>
            <EngagementChart
              title={"Engagement"}
              course_id={course_id}
              users={user_id}
              start={start}
              end={end}
              showUsers={true}
              includeClass={true}
              includeTypes={includeInEngagementChart}
            />
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper elevation={0}>
            <StudentProgressChart
              user={user_id}
              course={course_id}
              start={start}
              end={end}
            />
          </Paper>
        </Grid>
        <Grid item xs={12} md={4} lg={3} xl={2}>
          <Paper elevation={0}>
            <AvaragesWidget
              studentName={studentName}
              classAvarage={classAvarage}
              studentAvarage={studentAvarage}
            />
          </Paper>
        </Grid>
        <Grid item xs={12} md={8} lg={9} xl={10}>
          <Paper elevation={0}>
            <ClassGradeDistChart
              classAvarages={classAvaragesPerStudent}
              user={user_id}
            />
          </Paper>
        </Grid>
      </Grid>
    </ScrollBox>
  );
}

export default StudentReport;
