import { createSelector } from "@reduxjs/toolkit";
import { shallowEqual } from "react-redux";
import { map, entries } from "lodash";
// Selectors

const selectQueuedOfflineTasks = createSelector(
  // returns an array of all the queued tasks
  state => state,
  state => {
    const queuedTasks = state.firestore.data?.queuedTasks;
    return queuedTasks
      ? map(entries(queuedTasks), ([id, value]) => ({ id, ...value }))
      : [];
  }
);

const selectQueuedSubmitTasks = createSelector(
  // returns all queued tasks of type submitTask
  selectQueuedOfflineTasks,
  queuedTasks => queuedTasks.filter(task => task.type === "submitTask")
);

const selectPendingQueuedSubmitTasks = createSelector(
  // returns pending queued tasks of type submitTask
  selectQueuedSubmitTasks,
  queuedTasks => queuedTasks.filter(task => task.status === "PENDING")
);

export const selectQueuedSubmissions = createSelector(
  // returns an array of submissions ID that are in the prossess of submitting
  selectPendingQueuedSubmitTasks,
  queuedTasks => queuedTasks.map(task => task.payload.submission_id),
  { memoizeOptions: { resultEqualityCheck: shallowEqual } }
);

export const selectIsCloningQuestions = createSelector(
  // returns an array of submissions ID that are in the prossess of submitting
  selectPendingQueuedSubmitTasks,
  queuedTasks => {
    return queuedTasks.some(task => task.payload?.copyInteractionsToReader);
  }
);

// Publish task queue
const selectQueuedPublishTask = createSelector(
  // returns all queued tasks of type submitTask
  selectQueuedOfflineTasks,
  queuedTasks => {
    return queuedTasks.filter(task => task.type === "publishTask");
  }
);

const selectPendingQueuedPublishTasks = createSelector(
  // returns pending queued tasks of type submitTask
  selectQueuedPublishTask,
  queuedTasks => {
    return queuedTasks.filter(task => task.status === "PENDING");
  }
);

export const selectQueuedTasks = createSelector(
  // returns an array of submissions ID that are in the prossess of submitting
  selectPendingQueuedPublishTasks,
  queuedTasks => queuedTasks.map(task => task.payload.task_id),
  { memoizeOptions: { resultEqualityCheck: shallowEqual } }
);
