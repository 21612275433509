// Dependencies
import React, { useState, useEffect, useRef } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import clsx from "clsx";
import { auth, googleAuthProvider } from "../../firebase";
import { useHistory, useLocation } from "react-router-dom";
import { useIntl } from "react-intl";
import { captureException } from "../../utils/errorHandlers";
// Redux dependencies
import { useSelector } from "react-redux";

// Components
import SendPasswordReset from "./SendPasswordReset";
import PasswordReset from "./PasswordReset";
import Signup from "./Signup";
import { useWindowSize } from "../../hooks";

import makeStyles from '@mui/styles/makeStyles';
import {
  Box,
  InputAdornment,
  TextField,
  Typography,
  IconButton,
  Button,
  Link
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { selectTextDirection } from "../../redux/firestoreSelectors";

//Styles
const useStyles = makeStyles(theme => {
  return {
    container: {
      width: "100%",
      height: "100vh",
      display: "flex"
    },
    img: {
      width: "50%",
      height: "100%",
      backgroundImage: 'url("/resources/landingImage20211023.jpg")',
      backgroundSize: "cover",
      backgroundPosition: "center center",
      backgroundRepeat: "no-repeat"
    },
    backBtn: {
      position: "absolute",
      left: "-48px"
    },
    newAccount: {
      color: theme.palette.secondary.main,
      marginInlineStart: "8px"
    },
    resetLink: {
      right: "0px",
      color: theme.palette.secondary.main,
      marginBlockEnd: "24px",
      alignSelf: "flex-end"
    },

    main: {
      width: "50%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: theme.palette.background.paper
    },
    scrollable: {
      alignItems: "flex-start",
      overflow: "auto",
      paddingBlock: 86
    },
    header: {
      marginBottom: "60px"
    },
    loginBox: {
      width: "50%",
      alignItems: "center",
      justifyContent: "space-between",
      textAlign: "center",
      position: "relative"
    },
    googleLoginBtn: {
      padding: "18px",
      marginBottom: "36px",
      width: "100%",
      "& .MuiButton-startIcon": {}
    },
    googleLoginBtnText: {
      fontSize: "16px"
    },
    alternativeSignInText: {
      textTransform: "uppercase",
      fontSize: "12px",
      letterSpacing: "1px",
      marginBottom: "36px"
    },
    emailLoginBox: {
      width: "100%",
      display: "flex",
      flexFlow: "column nowrap",
      justifyContent: "space-between"
    },
    emailLoginInput: {
      marginBlockEnd: "8px"
    },
    emailLoginBtn: {
      paddingBlock: "16px",
      color: "white"
    }
  };
});

export default function SignIn() {
  // Hooks
  const history = useHistory();
  const location = useLocation();
  const classes = useStyles();
  const intl = useIntl();
  const passwordFieldRef = useRef(null);
  const windowSize = useWindowSize();

  // Redux state
  const textDirection = useSelector(state => selectTextDirection(state));

  // Ephemeral state
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailValidationMessage, setEmailValidationMessage] = useState(null);
  const [passwordValidationMessage, setPasswordValidationMessage] =
    useState(null);
  const [pending, setPending] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showSendPasswordReset, setShowSendPasswordReset] = useState(false);
  const [showPasswordReset, setShowPasswordReset] = useState(false);
  const [showSignupScreen, setShowSignupScreen] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  // Behavior
  useEffect(() => {
    let sp = new URLSearchParams(location.search);
    let action = sp.get("action");
    let code = sp.get("code");
    if (action === "passwordReset" && code) {
      setShowPasswordReset(true);
    }
    if (auth.isSignInWithEmailLink(window.location.href)) {
      // Additional state parameters can also be passed via URL.
      // This can be used to continue the user's intended action before triggering
      // the sign-in operation.
      // Get the email if available. This should be available if the user completes
      // the flow on the same device where they started it.
      var savedEmail = window.localStorage.getItem("emailForSignIn");
      if (!savedEmail) {
        // User opened the link on a different device. To prevent session fixation
        // attacks, ask the user to provide the associated email again. For example:
        savedEmail = window.prompt(
          "Please provide your email for confirmation"
        );
      }
      // The client SDK will parse the code from the link for you.
      auth
        .signInWithEmailLink(savedEmail, window.location.href)
        .then(result => {
          // Clear email from storage.
          window.localStorage.removeItem("emailForSignIn");
          // You can access the new user via result.user
          // Additional user info profile not available via:
          // result.additionalUserInfo.profile == null
          // You can check if the user is new or existing:
          // result.additionalUserInfo.isNewUser
        })
        .catch(error => {
          captureException(error);
          // Some error occurred, you can inspect the code: error.code
          // Common errors could be invalid email and invalid or expired OTPs.
        });
    }
  }, [setShowPasswordReset, location]);

  function loginWithGoogle() {
    auth
      .signInWithPopup(googleAuthProvider)
      .then(credentisl => {})
      .catch(error => {
        captureException(error, `Failed to login error in token creation`);
        if (
          error.code === "auth/internal-error" &&
          error.message?.includes(
            "needs to be verified before access is granted"
          )
        ) {
          setLoading(false);
          setEmailValidationMessage(
            "Wait for Alethea to contact you to receive access"
          );
        } else setEmailValidationMessage("Can't login, contact Alethea");

        // history.push("/permissionDenied");
      });
  }

  function loginWithPassword() {
    auth
      .signInWithEmailAndPassword(email, password)
      .then(value => {})
      .catch(error => {
        if (error.code === "auth/user-not-found") {
          setEmailValidationMessage("User for email not found");
        } else if (error.code === "auth/wrong-password") {
          setPasswordValidationMessage("Wrong email/password combination");
        } else if (
          error.code === "auth/internal-error" &&
          error.message?.includes(
            "needs to be verified before access is granted"
          )
        ) {
          setLoading(false);
          setEmailValidationMessage(
            "User not authorized, Alethea will contact you"
          );
        } else setEmailValidationMessage("Can't login, contact Alethea");
        captureException(error, `Failed to login error in token creation`);
      });
  }

  function loginWithEmail(e) {
    if (validateForm()) {
      loginWithPassword();
    } else return false;
  }

  function validateForm() {
    if (email === "") {
      setEmailValidationMessage(
        intl.formatMessage({
          id: "signin.validation.emailEmpty",
          defaultMessage: "Please enter an email address"
        })
      );
      return false;
    } else if (!validateEmail(email)) {
      setEmailValidationMessage(
        intl.formatMessage({
          id: "signin.validation.emailInvalid",
          defaultMessage: "Please enter a valid email address"
        })
      );
      return false;
    } else {
      setEmailValidationMessage(null);
      return true;
    }
  }

  function validateEmail(userInput) {
    if (
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        userInput
      )
    ) {
      return true;
    } else return false;
  }

  const renderLoader = () => {
    if (loading) {
      return (
        <Box>
          <ClipLoader color="#5ec891" loading="true" size={150} />
        </Box>
      );
    }
  };

  const renderLoginScreen = () => {
    return <>
      <Typography
        component="h1"
        variant="h3"
        className={classes.header}
        data-test="login-tagline"
      >
        {intl.formatMessage({
          id: "signin.header",
          defaultMessage: "Sign in to Alethea"
        })}
      </Typography>
      <Button
        onClick={loginWithGoogle}
        className={classes.googleLoginBtn}
        variant="outlined"
        startIcon={
          <img
            alt="Google logo"
            src="/resources/btn_google_light_normal_ios.svg"
            width="24"
            height="24"
          />
        }
        data-test="signin-google-auth-button"
      >
        <Typography
          component="span"
          className={classes.googleLoginBtnText}
          variant="h5"
        >
          {intl.formatMessage({
            id: "signin.googleSignin",
            defaultMessage: "Sign in with Google"
          })}
        </Typography>
      </Button>
      <Box className={classes.userPassowrdContainer}>
        <Typography variant="body2" className={classes.alternativeSignInText}>
          {intl.formatMessage({
            id: "signin.emailSignin",
            defaultMessage: "Or use email and password"
          })}
        </Typography>

        {!loading && ( // Hide the login form when showing spinner
          <form
            className={classes.emailLoginBox}
            noValidate
            autoComplete="off"
          >
            <TextField
              required
              fullWidth
              type="email"
              aria-label="signin-email-input"
              defaultValue={email}
              onChange={e => setEmail(e.target.value)}
              label={intl.formatMessage({
                id: "signin.emailAddress",
                defaultMessage: "Email:"
              })}
              onKeyPress={e => {
                if (e.key === "Enter") {
                  e.preventDefault();
                  passwordFieldRef.current.focus();
                }
              }}
              variant="outlined"
              // variant="standard"
              id="email"
              className={classes.emailLoginInput}
              error={emailValidationMessage && true}
              helperText={emailValidationMessage || " "}
              data-test="signin-email-input"
            />

            <TextField
              required
              fullWidth
              type={showPassword ? "text" : "password"}
              aria-label="signin-password-input"
              defaultValue={password}
              inputRef={passwordFieldRef}
              className={classes.emailLoginInput}
              onChange={e => setPassword(e.target.value)}
              onKeyPress={e => {
                if (e.key === "Enter") {
                  e.preventDefault();
                  loginWithEmail();
                }
              }}
              label={intl.formatMessage({
                id: "signin.password",
                defaultMessage: "Password:"
              })}
              variant="outlined"
              // variant="standard"
              id="password"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => {
                        setShowPassword(!showPassword);
                      }}
                      onMouseDown={e => {
                        e.preventDefault();
                      }}
                      size="large">
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
              error={passwordValidationMessage && true}
              helperText={passwordValidationMessage || null}
              data-test="signin-password-input"
            />
            <Link
              component="button"
              onClick={() => {
                setShowSendPasswordReset(true);
              }}
              className={classes.resetLink}
            >
              Reset password
            </Link>
            <Button
              data-test-id="login-btn"
              disableElevation
              className={classes.emailLoginBtn}
              color="secondary"
              variant="contained"
              onClick={loginWithEmail}
              data-test="signin-with-email-button"
            >
              {intl.formatMessage({
                id: "signin.getLink",
                defaultMessage: "Sign In"
              })}
            </Button>
            <Box>
              New here?
              <Link
                component="button"
                onClick={() => {
                  setShowSignupScreen(true);
                }}
                className={classes.newAccount}
              >
                Create an account
              </Link>
            </Box>
          </form>
        )}
      </Box>
      {renderLoader()}
    </>;
  };

  const renderBody = () => {
    if (showSendPasswordReset) {
      return pending ? (
        <Typography variant="h4" data-test="signin-tagline">
          Check your email for the password reset link to Alethea
        </Typography>
      ) : (
        <SendPasswordReset
          pending={pending}
          setPending={setPending}
          back={() => {
            setShowSendPasswordReset(false);
          }}
        />
      );
    }

    if (showPasswordReset) {
      return pending ? (
        <>
          <Typography variant="h4">Password Successfully Reset</Typography>
          <Button
            onClick={() => {
              setShowPasswordReset(false);
              setPending(false);
            }}
            className={classes.resetLink}
            variant="text"
          >
            Back to login
          </Button>
        </>
      ) : (
        <PasswordReset
          pending={pending}
          setPending={setPending}
          back={() => {
            setShowPasswordReset(false);
            setPending(false);
            setLoading(false);
            history.push("/");
          }}
        />
      );
    }

    if (showSignupScreen) {
      return pending ? (
        <Typography variant="h4">
          A Alethea representative will contact you shortly.
        </Typography>
      ) : (
        <Signup
          pending={pending}
          setPending={setPending}
          back={() => setShowSignupScreen(false)}
        />
      );
    }
    // the pending is true only when an login email was sent
    // when not pending, showing the login page

    return renderLoginScreen();
  };

  // Render
  return (
    <Box className={classes.container} dir={textDirection}>
      <Box
        className={clsx(classes.main, {
          [classes.scrollable]: windowSize.height < 580
        })}
      >
        <Box className={classes.loginBox}>{renderBody()}</Box>
      </Box>
      <div className={classes.img} />
    </Box>
  );
}
