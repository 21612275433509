import React from "react";
import PropTypes from "prop-types";
import * as d3 from "d3";

import Chart from "../Chart";
import StackedBars from "../chartPrimitives/StackedBars";
import Axis from "../chartPrimitives/Axis";
import { accessorPropsType } from "../utils";
import { useChartDimensions } from "../../../../hooks";

function HorizontalStackedBarChart({
  data,
  yAccessor,
  zDomain,
  colors,
  formatTick,
  hideY = false,
  hideX = true,
  hideAxisLines,
  barHeight,
  label
}) {
  const { ref, dimensions } = useChartDimensions({
    marginLeft: 100,
    marginTop: 0,
    marginBottom: 0,
    height: data.length * (barHeight + barHeight / 2) // bar height + padding
  });

  // TODO: set a default to colors
  // TODO: we can assume that the z domain is all the passed in numerical values - the x
  const stack = d3.stack().keys(zDomain);

  const stackedData = stack(data);
  const xMax = d3.max(stackedData.flat(2));
  const padding = 0.25;

  const yScale = d3
    .scaleBand()
    .domain(data.map(yAccessor))
    .range([dimensions.boundedHeight, 0])
    .rangeRound([0, data.length * (barHeight + barHeight / 2)])
    .padding(padding);
  const xScale = d3
    .scaleLinear()
    .domain([0, xMax])
    .range([0, dimensions.boundedWidth]);

  const zScale = d3.scaleOrdinal().domain(zDomain).range(colors);

  const keyAccessor = (d, i) => i;
  const yAccessorScaled = d => yScale(d.data.id);
  const xAccessorScaled = d => Math.min(xScale(d[0]), xScale(d[1]));
  const zAccessorScaled = d => zScale(d.key);
  const widthAccessorScaled = d => Math.abs(xScale(d[0]) - xScale(d[1]));
  const heightAccessorScaled = d => yScale.bandwidth();

  return (
    <Chart ref={ref} dimensions={dimensions}>
      {!hideX && (
        <Axis
          dimensions={dimensions}
          dimension="x"
          scale={xScale}
          hideAxisLines={hideAxisLines}
        />
      )}

      {!hideY && (
        <Axis
          dimension="y"
          ticks={data.map(yAccessor)}
          formatTick={formatTick}
          scale={yScale}
          hideAxisLines={hideAxisLines}
        />
      )}

      <StackedBars
        data={stackedData}
        keyAccessor={keyAccessor}
        xAccessor={xAccessorScaled}
        yAccessor={yAccessorScaled}
        zAccessor={zAccessorScaled}
        widthAccessor={widthAccessorScaled}
        heightAccessor={heightAccessorScaled}
      />
    </Chart>
  );
}

HorizontalStackedBarChart.propTypes = {
  xAccessor: accessorPropsType,
  yAccessor: accessorPropsType,
  xLabel: PropTypes.string,
  yLabel: PropTypes.string
};

HorizontalStackedBarChart.defaultProps = {
  xAccessor: d => d.x,
  yAccessor: d => d.y,
  hideAxisLines: false,
  allowExpand: false
};
export default HorizontalStackedBarChart;
