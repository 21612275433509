import scrollIntoView from "scroll-into-view-if-needed";

export function scrollPageAndThumbnailIntoView(pageNumber, options = {}) {
  scrollPageIntoView(pageNumber, options);
  scrollThumbnailIntoView(pageNumber, options);
}

export function scrollPageIntoView(pageNumber, options = {}) {
  const pageNode = document.querySelector(`[data-page-number='${pageNumber}']
  `);
  if (!pageNode) return;
  scrollIntoView(pageNode, {
    behavior: options.behavior || "auto",
    block: options.block || "center",
    inline: options.inline || "center"
  });
}

export function scrollAnnotationIntoView(annotationId, options = {}) {
  const node = document.querySelector(`[data-id='${annotationId}']
  `);
  if (!node) return;

  scrollIntoView(node, {
    behavior: "auto",
    block: options.block || "center",
    inline: options.inline || "center"
  });
}

export function scrollThumbnailIntoView(pageNumber, options = {}) {
  const pageNode = document.querySelector(
    `[data-thumbnail-number='${pageNumber}']`
  );
  if (!pageNode) return;
  scrollIntoView(pageNode, {
    behavior: options.behavior || "auto",
    block: options.block || "center",
    inline: options.inline || "center"
  });
}

export const determinePDFPagePosition = (
  currentPageVisibility,
  previousPageVisibility,
  nextPageVisibility
) => {
  const onlyOnePageVisible = !nextPageVisibility && !previousPageVisibility;

  if (currentPageVisibility > 50 || onlyOnePageVisible) return "center";
  else if (nextPageVisibility) return "end";
  else if (previousPageVisibility) return "start";
};
