// Dependencies
import React from "react";
import clsx from "clsx";
// Redux
import { useSelector } from "react-redux";
// Redux

import makeStyles from "@mui/styles/makeStyles";
import { Paper, Box } from "@mui/material";
import { selectIsSuggestions } from "../../redux/firestoreSelectors";
import Comment from "../comments/Comment";
import {
  findCurrentSuggestionIndex,
  selectedReasonBySuggestionId
} from "../../redux/interactionsSlice";

// Styles
const useStyles = makeStyles(theme => ({
  container: {
    width: "250px",
    height: "100%",
    background: theme.palette.background.paper,
    display: "flex",
    border: "none",
    justifyContent: "center",
    transition: ".3s ease-out",
    position: "relative"
  },
  containerWithSuggestions: {
    height: "calc(100% - 48px)",
    alignSelf: "flex-end"
  }
}));

const JourneyPanel = ({}) => {
  // Hooks
  const classes = useStyles();
  // Redux State
  const isSuggestions = useSelector(state => selectIsSuggestions(state));
  const currentSuggestionIndex = useSelector(findCurrentSuggestionIndex) + 1;

  const activeReason = useSelector(state =>
    selectedReasonBySuggestionId(state)
  );

  return (
    <Paper
      className={clsx(
        classes.container,
        isSuggestions && classes.containerWithSuggestions
      )}
    >
      <Box className={classes.SuggestionBox}>
        <Comment comment={activeReason} stopNumber={currentSuggestionIndex} />
      </Box>
    </Paper>
  );
};
export default JourneyPanel;
