// Dependencies
import React, { useCallback } from "react";

import PdfView from "../reader/pdf/PdfView";
import EpubView from "../reader/EpubView/EpubView";

// Redux Dependencies
import { useSelector, useDispatch } from "react-redux";

import { selectZoom } from "../../redux/pdfSlice";
import {
  setSelectedHighlight,
  openAnnotatorBar,
  closeAnnotatorBar
} from "../../redux/highlightSlice";
import { selectCurrentText, isPdfSelector } from "../../redux/textsSlice";
import { getLocationByEntity } from "../reader/utils";

// Components

// Material UI

//Style

const DialogContentBook = ({
  underlines,
  highlights,
  onTextSelected,
  onRenditionCreated,
  containerRef = null,
  dialogLocked = false
}) => {
  // Hooks

  const dispatch = useDispatch();

  // Redux state
  const zoom = useSelector(selectZoom);
  const isAnnotatorBarOpen = useSelector(
    state => state.highlighter.isAnnotatorBarOpen
  );

  const fontSizeValue = useSelector(state => state.user.userProfile.fontSize);
  const fontSize = useSelector(state => state.user.fontSizeOptions);
  const isPdf = useSelector(isPdfSelector);
  const text = useSelector(selectCurrentText);

  // Ephemeral state

  // Variables
  const highlightClicked = useCallback(
    (clickEvent, highlight) => {
      if (isAnnotatorBarOpen) {
        dispatch(closeAnnotatorBar());
      } else {
        const clientRect = clickEvent.target.getBoundingClientRect();
        const parentPos = containerRef.current.getBoundingClientRect();
        const highlightedRect = {
          top: clientRect.top + containerRef.current.scrollTop - parentPos.top,
          left:
            clientRect.left + containerRef.current.scrollLeft - parentPos.left,
          x: clientRect.left + containerRef.current.scrollLeft - parentPos.left,
          y: clientRect.top + containerRef.current.scrollTop - parentPos.top,

          width: clientRect.width,
          height: clientRect.height,
          bottom:
            clientRect.bottom + containerRef.current.bottom - parentPos.bottom,
          right: clientRect.right + containerRef.current.right - parentPos.right
        };

        dispatch(
          setSelectedHighlight({
            clientRectangle: highlightedRect,
            selectedHighlight: highlight.id,
            isHover: true,
            isClickOnMark: true,
            minimal: highlight.minimal
          })
        );
        dispatch(openAnnotatorBar());
      }
    },
    [dispatch, isAnnotatorBarOpen]
  );
  // Render
  return (
    <>
      {isPdf ? (
        <PdfView
          url={text.url}
          highlights={highlights}
          underlines={underlines}
          highlightClicked={!dialogLocked && highlightClicked}
          handleTextSelected={!dialogLocked && onTextSelected}
          isVisible={true}
          scrollToPosition={
            highlights.length && getLocationByEntity(highlights[0])
          }
        />
      ) : (
        <EpubView
          fullSize={true}
          container={containerRef}
          fontSize={fontSize[fontSizeValue]}
          epubOptions={{ flow: "scrolled-doc" }}
          textDirection={text.text_language === "he" ? "rtl" : "ltr"}
          url={text.url}
          location={text.file_location}
          doNotMove={true}
          highlights={highlights}
          handleTextSelected={onTextSelected}
          onRenditionCreated={onRenditionCreated}
          updateRenditionContext={false}
          readOnly={dialogLocked}
        />
      )}
    </>
  );
};

export default DialogContentBook;
