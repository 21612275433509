// Dependencies
import React, { useEffect, useState } from "react";
import {
  firebaseApp,
  firebaseFunctions,
  httpCallables
} from "../../../firebase";
import clsx from "clsx";

import { useHistory } from "react-router-dom";
import { dateToEodUtc } from "./utils.js";
import { v4 as uuid } from "uuid";
import { captureException } from "../../../utils/errorHandlers";
import { isEmpty } from "lodash";
import { useQuery } from "../../../hooks";

// Redux dependencies
import { useDispatch, useSelector } from "react-redux";
import { setBreadcrumbs } from "../../../redux/readerActionsSlice";
import { selectCourse } from "../../../redux/coursesSlice";
import { selectTexts, selectText, setTextUrl } from "../../../redux/textsSlice";
import {
  setTasks,
  setTask,
  publishTask,
  STATUS
} from "../../../redux/tasksSlice";
import {
  selectQuestions,
  setInteractions,
  resetQuestions
} from "../../../redux/interactionsSlice";
import { addSnackbarItem } from "../../../redux/snackbarSlice";

// Components
import SelectReading from "./StepSelectReadings";
import StepAddQuestions from "./StepAddQuestions";
import SubmitTask from "./StepSubmitTask";
import TaskStepper from "./TaskStepper";
import RouteLeavingGuard from "./RouteLeavingGuard";
import PangeaSpinner from "../../SharedComponents/PangeaSpinner";
import { TASK, QUESTION } from "../../../consts";

import makeStyles from "@mui/styles/makeStyles";
import { Box, Button, Typography } from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";

// Styles
const useStyles = makeStyles(theme => ({
  right: {
    textAlign: "right"
  },
  left: {
    textAlign: "left"
  },
  taskHeader: {
    position: "relative"
  },
  navigateBack: {
    top: "-8px",
    left: "-60px",
    position: "absolute"
  },
  tasksHeader: {
    marginTop: "40px",
    width: "100%",
    position: "relative"
  },
  blueBg: {
    backgroundColor: "#168FEE",
    color: "#FFFFFF",
    "&:hover": {
      backgroundColor: "#1172be"
    }
  },

  stepper: {
    marginTop: "20px",
    width: "100%",
    paddingLeft: "0px",
    paddingRight: "0px"
  },
  btnContainer: {
    marginTop: "40px",
    marginBottom: "40px"
  },
  btn: {
    borderRadius: "8px",
    marginLeft: "4px",
    marginRight: "4px"
  },
  saveDraftlBtn: {
    position: "absolute"
  },
  saveDraftlBtnRtl: {
    left: "0px"
  },
  saveDraftlBtnLtr: {
    right: "0px"
  },
  stepLabel: {
    fontSize: "24px"
  },
  stepRtl: {
    "& .MuiStepLabel-iconContainer": {
      paddingLeft: "8px",
      paddingRight: "0px"
    }
  },
  stepFirstLtr: {
    paddingLeft: "0px"
  },
  stepFirstRtl: {
    paddingRight: "0px"
  },
  stepLast: {
    paddingRight: "0px"
  },
  step: {},
  stepIcon: {
    width: "36px",
    height: "36px"
  }
}));

function CreateTask({ task, isDirty, setIsDirty, ...props }) {
  //Hooks
  const dispatch = useDispatch();
  const classes = useStyles();
  let history = useHistory();
  const intl = useIntl();
  const { course_id } = useQuery();
  //Ephemeral State
  const [activeStep, setActiveStep] = useState(0);
  const [textId, setTextId] = useState(task.text_id || null);
  const [taskTitle, setTaskTitle] = useState("");
  const [taskType, setTaskType] = useState(TASK.TYPE.STANDARD);
  const [taskDescription, setTaskDescription] = useState(
    task.task_description || ""
  );

  const [deadline, setDeadline] = useState(null);
  const [collaboratorDeadline, setCollaboratorDeadline] = useState(null);
  const [questions, setQuestions] = useState([QUESTION.OPEN_ENDED]);
  const [activeGrTasks, setActiveGrTasks] = useState([]);
  const [showSpinner, setShowSpinner] = useState(false); // When true a spinner will show to indicate that the task is saving

  const course = useSelector(state => selectCourse(state, Number(course_id)));
  const texts = useSelector(state => selectTexts(state, Number(course_id)));
  const text = useSelector(state => selectText(state, textId));
  const questionsFromDb = useSelector(state => selectQuestions(state, task.id));
  const status = useSelector(state => state.tasks.status);

  // Derived State
  const courseTimezone = course.timezone;

  //Variables
  const TaskContext = React.createContext();
  const stepOne = !showSpinner && activeStep === 0;
  const stepTwo = !showSpinner && activeStep === 1;
  const stepThree = !showSpinner && activeStep === 2;

  //Behavior
  useEffect(() => {
    if (!text.id) return;
    const readActiveGrTasks = firebaseFunctions.httpsCallable(
      "tasks-taskFunctions"
    );
    readActiveGrTasks({
      func_name: "readGr",
      text_id: text.id,
      course_id: Number(course.id)
    }).then(({ data }) => {
      setActiveGrTasks(data);
    });
  }, [text.id]);

  useEffect(() => {
    if (task.name) setTaskTitle(task.name);
  }, [task.name]);

  useEffect(() => {
    if (task.text_id) setTextId(task.text_id);
  }, [task.text_id]);

  useEffect(() => {
    //TODO: once we implament thunks we can move this logic to a slice which will take care of fetching the URL all across the app
    if (!isEmpty(text) && !text.url) {
      firebaseApp
        .storage()
        .ref("courseTexts/" + course.id)
        .child(text.file_url)
        .getDownloadURL()
        .then(url => {
          dispatch(setTextUrl({ url, text_id: text.id }));
        });
    }
  }, [course.id, dispatch, text]);
  useEffect(() => {
    if (questionsFromDb.length) setQuestions(questionsFromDb);
  }, [questionsFromDb]);

  useEffect(() => {
    if (task.original_due_date) setDeadline(new Date(task.original_due_date));
  }, [task.original_due_date]);

  useEffect(() => {
    if (task.collaborator_due_date)
      setCollaboratorDeadline(new Date(task.collaborator_due_date));
  }, [task.collaborator_due_date]);

  const setWrapper = setFunc => state => {
    setFunc(state);
    setIsDirty(true);
  };

  useEffect(() => {
    let parts = [];
    parts.push({
      url: "/tasks",
      resetCourse: true,
      text: intl.formatMessage({
        id: "appBar.tasks",
        resetTask: true,
        defaultMessage: "Tasks"
      })
    });
    course &&
      course.name &&
      parts.push({
        url: `/tasks?course_id=${course.id}`,
        text: course.name,
        resetTask: true,
        course: course
      });
    taskTitle &&
      parts.push({
        url: `/tasks?course_id=${course.id}`,
        resetTask: true,
        text: taskTitle
          ? taskTitle
          : intl.formatMessage({
              id: "new.task",
              defaultMessage: "New Task"
            })
      });
    dispatch(setBreadcrumbs({ breadcrumbs: parts, blue: true }));
  }, [taskTitle, course, dispatch, intl]);

  async function handlePublishTask() {
    const dueDate = dateToEodUtc(deadline, courseTimezone);
    const collaboratorDueDate = dateToEodUtc(
      collaboratorDeadline,
      courseTimezone
    );

    dispatch(
      publishTask({
        id: task.id,
        task_id: task.id,
        isDraft: false,
        course_id: course.id,
        text_id: text.id,
        name: taskTitle,
        description: taskDescription,
        due_date: dueDate,
        collaborator_due_date: collaboratorDueDate,
        task_type: taskType,
        questions: questions
      })
    );
  }
  async function saveTaskDraft() {
    setShowSpinner(true);
    setIsDirty(false);
    const { data } = await httpCallables.taskFunctions({
      func_name: "publishTask",
      isDraft: true,
      id: task.id,
      course_id: course.id,
      text_id: text.id,
      name: taskTitle,
      description: taskDescription,
      dueDate: dateToEodUtc(deadline),
      collaboratorDueDate: dateToEodUtc(collaboratorDeadline),
      taskType: taskType,
      questions: questions
    });
    if (data.success) {
      const { interactions, task: draft } = JSON.parse(data.payload);
      // We are replacing the interaction rather then updating them
      dispatch(resetQuestions());
      dispatch(setInteractions(interactions));
      dispatch(setTask(draft));
      dispatch(
        addSnackbarItem({
          intlId: "task.savedDraft",
          intlDefaultMessage: "Task saved as draft",
          id: uuid()
        })
      );
      setShowSpinner(false);
      return draft;
    } else {
      const { error } = data.payload;
      setIsDirty(true);
      dispatch(
        addSnackbarItem({
          intlId: "task.saveing.error",
          intlDefaultMessage: "Task saved as draft",
          id: uuid()
        })
      );
      setShowSpinner(false);
      captureException(error, `Failed to save a task draft`);
      return null;
    }
  }

  async function saveDraftAndUpdateUrl() {
    const draft = await saveTaskDraft();
    draft &&
      history.replace(
        `/tasks/new?course_id=${draft.course_id}&task_id=${draft.id}`
      );
  }

  if (status === STATUS.PENDING) return <PangeaSpinner />;
  else
    return (
      <>
        <RouteLeavingGuard
          setIsDirty={setIsDirty}
          isDirty={isDirty}
          selectCourse={props.selectCourse}
          setTask={props.setTask}
          // Start blocking navigation when text is selected
          when={isDirty}
          // Navigation function provided by react router's useHistory
          navigate={path => history.push(path)}
          // adds a third button which will run a function before navigating
          additionalStep={saveTaskDraft}
          additionalStepButton={intl.formatMessage({
            id: "tasks.create.SaveDraft",
            defaultMessage: "Save draft"
          })}
        />
        <Box className={classes.taskHeader}>
          <Typography
            className={clsx(classes.tasksHeader, classes.left)}
            variant="h4"
          >
            {taskTitle ? (
              taskTitle
            ) : (
              <FormattedMessage id="new.task" defaultMessage="New Task" />
            )}

            <Button
              variant="outlined"
              // className={classes.btn}
              className={clsx(classes.saveDraftlBtn, classes.saveDraftlBtnLtr)}
              disabled={!Boolean(textId)}
              onClick={() => {
                saveDraftAndUpdateUrl();
              }}
            >
              <FormattedMessage defaultMessage="Save draft" id="save.draft" />
            </Button>
          </Typography>

          <TaskContext.Provider value={task}>
            <TaskStepper rtl={false} activeStep={activeStep} />
          </TaskContext.Provider>
        </Box>
        {showSpinner && <PangeaSpinner />}
        {stepOne && (
          <SelectReading
            course={course}
            texts={texts}
            text={text}
            setTextId={setTextId}
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            publishTask={handlePublishTask}
            setIsDirty={setIsDirty}
            resetQuestions={() => {
              //  TODO: reset quotes when changing text
            }}
          />
        )}
        {stepTwo && (
          <StepAddQuestions
            task={task}
            text={text}
            questions={questions}
            setQuestions={setWrapper(setQuestions)}
            taskTitle={taskTitle}
            setTaskTitle={setWrapper(setTaskTitle)}
            taskType={taskType}
            setTaskType={setWrapper(setTaskType)}
            taskDescription={taskDescription}
            setTaskDescription={setWrapper(setTaskDescription)}
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            publishTask={handlePublishTask}
            activeGrTasks={activeGrTasks}
          />
        )}
        {stepThree && (
          <SubmitTask
            taskType={taskType}
            deadline={deadline}
            setDeadline={setWrapper(setDeadline)}
            collaboratorDeadline={collaboratorDeadline}
            setCollaboratorDeadline={setWrapper(setCollaboratorDeadline)}
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            publishTask={handlePublishTask}
            setIsDirty={setIsDirty}
          />
        )}
      </>
    );
}
export default CreateTask;
