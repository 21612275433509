// Dependancies
import React from "react";
import PropTypes from "prop-types";
import * as d3 from "d3";
import { accessorPropsType, callAccessor } from "../utils";

import makeStyles from "@mui/styles/makeStyles";

// Styles
const useStyles = makeStyles(theme => ({
  bars: {
    // fill: "#9980FA",
    transition: "all 0.3s ease-out"
  }
}));

function Bars({
  data,
  keyAccessor,
  xAccessor,
  yAccessor,
  zAccessor,
  widthAccessor,
  heightAccessor,
  fill,
  ...props
}) {
  const classes = useStyles();
  return (
    <>
      {data.map((d, i) => {
        return (
          <rect
            {...props}
            fill={zAccessor ? callAccessor(zAccessor, d, i) : fill}
            className={classes.bars}
            key={callAccessor(keyAccessor, d, i)}
            x={callAccessor(xAccessor, d, i)}
            y={callAccessor(yAccessor, d, i)}
            width={d3.max([callAccessor(widthAccessor, d, i), 0])}
            height={d3.max([callAccessor(heightAccessor, d, i), 0])}
          />
        );
      })}
    </>
  );
}

Bars.propTypes = {
  data: PropTypes.array,
  fill: PropTypes.string,
  keyAccessor: accessorPropsType,
  xAccessor: accessorPropsType,
  yAccessor: accessorPropsType,
  zAccessor: accessorPropsType,
  widthAccessor: accessorPropsType,
  heightAccessor: accessorPropsType
};

Bars.defaultProps = {};

export default Bars;
