import PropTypes from "prop-types";
import {
  isWeekend,
  fromUnixTime,
  startOfDay,
  eachDayOfInterval,
  isSameDay
} from "date-fns";

export const callAccessor = (accessor, d, i) =>
  typeof accessor === "function" ? accessor(d, i) : accessor;

export const dimensionsPropsType = PropTypes.shape({
  height: PropTypes.number,
  width: PropTypes.number,
  marginTop: PropTypes.number,
  marginRight: PropTypes.number,
  marginBottom: PropTypes.number,
  marginLeft: PropTypes.number
});

export const accessorPropsType = PropTypes.oneOfType([
  PropTypes.func,
  PropTypes.number
]);

export function fillArrayMissionDates(
  data = [],
  dateAccessor,
  start,
  end,
  filler = {}
) {
  // create an array of all days in range
  const eachDayInRange = eachDayOfInterval({ start, end });

  // Make sure that the dates are at the start of the day and...
  // ...That the array is sorted
  const sortedData = data
    .map(entry => {
      const date = new Date(entry[dateAccessor]);
      const dateAtstartOfDay = startOfDay(date);

      return { ...entry, [dateAccessor]: dateAtstartOfDay };
    })
    .sort((a, b) => a[dateAccessor] - b[dateAccessor]);

  // loop over the range of dates returning the input data id exsist or a filler
  return eachDayInRange.map(dayFromRange => {
    const fillerWithDate = { ...filler, [dateAccessor]: dayFromRange };
    if (!sortedData.length) return fillerWithDate;

    if (isSameDay(dayFromRange, sortedData[0][dateAccessor])) {
      const match = sortedData.shift();
      return match;
    } else return fillerWithDate;
  });
}
