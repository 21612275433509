// Dependancies
import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import * as d3 from "d3";
import { accessorPropsType } from "../utils";

// Material UI
import { useTheme } from "@mui/material/styles";

import makeStyles from '@mui/styles/makeStyles';

// Styles
const useStyles = makeStyles(theme => ({
  base: {
    transition: "all 0.3s ease-out"
  },
  line: {
    fill: "none",
    strokeWidth: 3,
    strokeLinecap: "round"
  },
  area: {
    fill: "rgba(152, 128, 250, 0.185)",
    strokeWidth: 0
  }
}));

function Line({
  type,
  data,
  xAccessor,
  yAccessor,
  y0Accessor,
  interpolation,
  stroke = "rgba(0, 0, 0, 0.6)",
  ...props
}) {
  // Hooks
  const classes = useStyles();
  // Derived state
  const lineGenerator = d3[type]()
    .x(xAccessor)
    .y(yAccessor)
    .curve(interpolation);

  if (type === "area") {
    lineGenerator.y0(y0Accessor).y1(yAccessor);
  }

  return (
    <path
      {...props}
      stroke={stroke}
      className={clsx(classes.base, classes[type])}
      d={lineGenerator(data)}
    />
  );
}

Line.propTypes = {
  type: PropTypes.oneOf(["line", "area"]),
  data: PropTypes.array,
  xAccessor: accessorPropsType,
  yAccessor: accessorPropsType,
  y0Accessor: accessorPropsType,
  interpolation: PropTypes.func,
  stroke: PropTypes.string
};

Line.defaultProps = {
  data: [],
  type: "line",
  y0Accessor: 0,
  interpolation: d3.curveMonotoneX
};

export default Line;
