import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { useSelector, useDispatch } from "react-redux";
import {
  updateInteraction,
  selectQuestions,
  selectAnswerComment
} from "../../../redux/interactionsSlice";
import makeStyles from "@mui/styles/makeStyles";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { Button, Box } from "@mui/material";
import { isEmpty } from "lodash";
import { grAPI } from "../../../api";

const useStyles = makeStyles(theme => ({
  title: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1.25),
    fontSize: 16
  },
  btn: {
    marginRight: theme.spacing(1)
  },
  nextBtnLtr: {
    right: 0
  },
  nextBtn: {
    borderRadius: 30,
    padding: 0,
    height: 45,
    width: 45,
    minWidth: 0
  },
  feedbackContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: 37
  },
  feedback: {
    marginTop: theme.spacing(5)
  },
  dark: {
    background: "#424242",
    color: "#fff",
    "&::placeholder": {
      color: "rgba(255, 255, 255, 0.7)"
    },
    "& textarea": {
      color: "inherit",
      "&::placeholder": {
        color: "rgba(255, 255, 255, 0.7)"
      }
    }
  }
}));

function SummaryActions({ color = "secondary" }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const userProfile = useSelector(state => state.user.userProfile);
  const questions = useSelector(selectQuestions);
  const selectedQuestionId = useSelector(state => state.gr.selectedQuestionId);
  const questionIndex = questions.findIndex(el => el.id === selectedQuestionId);
  const darkTheme = useSelector(state => state.user.userProfile.darkMode);
  const summary = useSelector(state =>
    selectAnswerComment(state, selectedQuestionId)
  );

  // Derived state and variables

  const rtl = userProfile.rtl;
  const ownFeedbackOptions = {
    AWESOME: {
      score: 1,
      displayName: (
        <FormattedMessage id="exerciseFeedback.1" defaultMessage="Awesome" />
      )
    },
    NOT_BAD: {
      score: 0,
      displayName: (
        <FormattedMessage id="exerciseFeedback.2" defaultMessage="Not bad" />
      )
    },
    NOT_GREAT: {
      score: -1,
      displayName: (
        <FormattedMessage id="exerciseFeedback.3" defaultMessage="Not great" />
      )
    }
  };
  //Behavior
  function setOwnFeedback(summary, score) {
    dispatch(
      updateInteraction({
        interaction: summary,
        update: { ownFeedback: score }
      })
    );
  }

  //Render
  return (
    <Box className={classes.feedback}>
      <Box className={classes.title}>
        <FormattedMessage
          id="gr.feedback.question"
          defaultMessage="How did I do?"
        />{" "}
      </Box>
      <Box
        className={clsx(classes.feedbackContainer, {
          [classes.darkTheme]: darkTheme
        })}
      >
        <Box>
          {Object.keys(ownFeedbackOptions).map(key => {
            const { score, displayName } = ownFeedbackOptions[key];
            const isSelected = summary.ownFeedback === score;

            return (
              <Button
                key={key}
                data-test-class="gr-self-feedback-btn"
                onClick={() => setOwnFeedback(summary, score)}
                className={clsx(classes.btn, {
                  [classes.feedbackSelected]: isSelected
                })}
                variant={isSelected ? "contained" : "outlined"}
                color={color}
                disabled={isEmpty(summary)}
              >
                {displayName}
              </Button>
            );
          })}
        </Box>
        {questionIndex < questions.length - 1 && (
          <Button
            onClick={() =>
              grAPI.updateGrState({
                selectedQuestionId: questions[questionIndex + 1].id
              })
            }
            variant="contained"
            className={clsx(classes.nextBtn, classes.nextBtnLtr)}
            color={color}
          >
            {rtl ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </Button>
        )}
      </Box>
    </Box>
  );
}

SummaryActions.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary"])
};

export default SummaryActions;
