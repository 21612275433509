// Dependencies
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { STATUSES } from "../../../redux/interactionsSlice";
// Material UI
// styles
const useStyles = makeStyles(theme => ({
  draftStatus: { color: theme.palette.text.secondary }
}));

function TextEditorSavingIndicator({ status = null }) {
  //Hooks
  const classes = useStyles();

  // Ephemeral State
  const [display, setDisplay] = useState("");

  // Derived state

  // Behavior
  useEffect(() => {
    if (status === STATUSES.PENDING) {
      setDisplay("Saving...");
    } else if (status === STATUSES.IDLE) {
      setDisplay("Saved");
    }
  }, [status]);

  //Render
  if (!status) return null;
  return (
    <Typography variant="caption" className={classes.draftStatus}>
      {display}
    </Typography>
  );
}

TextEditorSavingIndicator.propTypes = {
  status: PropTypes.oneOf(["PENDING", "IDLE"])
};

export default TextEditorSavingIndicator;
