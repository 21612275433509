import {
  configureStore,
  getDefaultMiddleware,
  combineReducers,
  applyMiddleware
} from "@reduxjs/toolkit";
import { firebaseReducer } from "react-redux-firebase";
import { createEpicMiddleware } from "redux-observable";
import { fetchAsObservable } from "./epics/utils";
import { rootEpic } from "./epics/rootEpic";
import {
  getFirebase,
  actionTypes as rrfActionTypes
} from "react-redux-firebase";
import {
  constants as rfConstants,
  getFirestore,
  firestoreReducer
} from "redux-firestore";
import { connectRouter, routerMiddleware } from "connected-react-router";

import readerActionsReducer from "./readerActionsSlice";
import highlightReducer from "./highlightSlice";
import grReducer from "./grSlice";
import linkSlice from "./LinkSlice";
import pdfSlice from "./pdfSlice";
import taskSlice from "./taskSlice";
import commentReducer from "./commentsSlice";
import textReducer from "./textsSlice";
import userReducer from "./userSlice";
import coursesSlice from "./coursesSlice";
import tasksSlice from "./tasksSlice";
import interactionsSlice from "./interactionsSlice";
import realtimeInteractionsSlice from "./realtimeInteractionsSlice";
import snackbarSlice from "./snackbarSlice";
import firebaseMiddleware from "./firebaseMiddleware";
import history from "../history";
import layoutSlice from "./layoutSlice";
import readerSlice from "./readerSlice";
import chatSlice from "./chatSlice";
import fabSlice from "./fabSlice";

const extraArgument = {
  getFirebase,
  getFirestore
};
const epicMiddleware = createEpicMiddleware({
  dependencies: { fetchAsObservable }
});

const getMiddleware = getDefaultMiddleware => [
  firebaseMiddleware,
  epicMiddleware,
  routerMiddleware(history),
  ...getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [
        // just ignore every redux-firebase and react-redux-firebase action type
        ...Object.keys(rfConstants.actionTypes).map(
          type => `${rfConstants.actionsPrefix}/${type}`
        ),
        ...Object.keys(rrfActionTypes).map(
          type => `@@reactReduxFirebase/${type}`
        )
      ],
      ignoredPaths: ["firebase", "firestore"]
    },
    thunk: {
      extraArgument: { getFirebase, getFirestore }
    }
  })
];

const combinedReducer = combineReducers({
  firebase: firebaseReducer,
  firestore: firestoreReducer,
  router: connectRouter(history),
  layout: layoutSlice,
  readerActions: readerActionsReducer,
  highlighter: highlightReducer,
  gr: grReducer,
  texts: textReducer,
  task: taskSlice,
  user: userReducer,
  link: linkSlice,
  comments: commentReducer,
  courses: coursesSlice,
  tasks: tasksSlice,
  interactions: interactionsSlice,
  realtimeInteractions: realtimeInteractionsSlice,
  snackbar: snackbarSlice,
  pdf: pdfSlice,
  reader: readerSlice,
  chat: chatSlice,
  fab: fabSlice
});

const rootReducer = (state, action) => {
  if (action.type === "user/logout") {
    state = undefined;
  }
  return combinedReducer(state, action);
};

export default configureStore({
  reducer: rootReducer,
  middleware: getMiddleware
});

epicMiddleware.run(rootEpic);
