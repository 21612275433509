// Dependencies
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useImpersonate, useQuery } from "../../../../hooks";
import _ from "lodash";
import { v4 as uuid } from "uuid";

import makeStyles from "@mui/styles/makeStyles";

import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  Checkbox,
  Link
} from "@mui/material";

import UserSubmissionBarChart from "./charts/UserSubmissionsBarChart";
import EngagementChip from "./EngagementChip";
import { isEmpty } from "lodash";
import { Skeleton } from "@mui/material";
import { httpCallables } from "../../../../firebase";
import { systemTimezoneToUtc } from "../../../../utils/dateUtils";
import { useDispatch } from "react-redux";
import { addSnackbarItem } from "../../../../redux/snackbarSlice";
import { captureException } from "@sentry/react";

// Styles
const useStyles = makeStyles(theme => ({
  table: {
    marginBlockEnd: theme.spacing(3)
  },
  submissionChartContainer: {
    maxWidth: 270
  }
}));

export default function UsersTable({ users, dispatchUsers, start, end }) {
  //dispatchUsers excpect a type of "set" or "toggle" and a payload

  //Hooks
  const classes = useStyles();
  const dispatch = useDispatch();
  const { course_id } = useQuery();

  //Redux
  const { impersonate } = useImpersonate();

  // Ephemeral State
  const [submissions, setSubmissions] = useState([]);
  const [engagement, setEngagement] = useState({});

  // Derived state
  const sortedUsers = Object.values(users)
    .sort((a, b) => sortByRole(a, b))
    .map(user => user.course_user);

  //Behavior
  useEffect(() => {
    httpCallables
      .readTasksAndSubmissionsInRange({
        course_id: Number(course_id),
        start: systemTimezoneToUtc(start),
        end: systemTimezoneToUtc(end)
      })
      .then(({ data }) => {
        if (data.success) {
          const { submissions } = JSON.parse(data.payload);
          setSubmissions(submissions);
        } else {
          const { error } = data.payload;
          dispatch(
            addSnackbarItem({
              intlId: "error.readCourseUsersFailed",
              intlDefaultMessage:
                "There was a problem getting the course information. Please check your connection and try again",
              id: uuid()
            })
          );
          captureException(error, `Faild to get course tasks and submissions`);
        }
      });
  }, [course_id, dispatch, end, start]);

  useEffect(() => {
    if (!start || !end) return;
    httpCallables
      .readAggregatedCourseActivity({
        course_id: Number(course_id),
        start: systemTimezoneToUtc(start),
        end: systemTimezoneToUtc(end),
        includeTypes: ["ENGAGEMENT"]
      })
      .then(({ data }) => {
        const { success } = data;

        if (success) {
          const parsedData = JSON.parse(data.payload);
          setEngagement(parsedData);
        } else {
          const { error } = data;
          dispatch(
            addSnackbarItem({
              intlId: "error.generateUserActivityReportFailed",
              intlDefaultMessage:
                "There was a problem getting the course information. Please check your connection and try again",
              id: uuid()
            })
          );

          captureException(error, `Faild to get course users`);
        }
      });
  }, [course_id, dispatch, end, start]);

  function sortByRole(a, b) {
    // Sort teacher to top of the list
    if (a.course_role === "Teacher" && !b.course_role !== "Teacher") {
      return -1;
    } else if (b.course_role === "Teacher" && !a.course_role !== "Teacher") {
      return 1;
    } else return 0;
  }
  return (
    <TableContainer>
      <Table className={classes.table} aria-label="users table">
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell>Active users</TableCell>
            <TableCell>Task submissions</TableCell>
            <TableCell>Role</TableCell>
            <TableCell>Engagement</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {isEmpty(sortedUsers)
            ? // Loading state
              _.fill(Array(3)).map((placeholder, i) => (
                <TableRow key={i}>
                  <TableCell>
                    <Skeleton variant="circular" width={20} height={20} />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>

                  <TableCell>
                    <Skeleton />
                  </TableCell>

                  <TableCell>
                    <Skeleton />
                  </TableCell>

                  <TableCell>
                    <Skeleton />
                  </TableCell>
                </TableRow>
              ))
            : sortedUsers.map(userId => {
                // Loaded state
                const user = users[userId];
                const score = engagement[userId]
                  ? engagement[userId].ENGAGEMENT
                  : 0;
                return (
                  <TableRow key={user.course_user}>
                    <TableCell padding="checkbox" component="th" scope="row">
                      <Checkbox
                        checked={user.selected}
                        onChange={() =>
                          dispatchUsers({
                            type: "toggle",
                            payload: { user: userId }
                          })
                        }
                      />
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <Link
                        component="button"
                        onClick={() => {
                          impersonate(user.course_user);
                        }}
                        underline="hover"
                      >
                        {user.course_user}
                      </Link>
                    </TableCell>
                    <TableCell className={classes.submissionChartContainer}>
                      <UserSubmissionBarChart
                        submissions={submissions.filter(
                          submission => submission.owner === userId
                        )}
                      />
                    </TableCell>
                    <TableCell>{user.course_role}</TableCell>
                    <TableCell>
                      <EngagementChip engagement={score} user={user} />
                    </TableCell>
                  </TableRow>
                );
              })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

UsersTable.propTypes = {
  users: PropTypes.object.isRequired,
  dispatchUsers: PropTypes.func.isRequired,
  submissions: PropTypes.array.isRequired
};
