import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { Box } from "@mui/material";
import { useGetTheme } from "../../../hooks";
import { useSelector, useDispatch } from "react-redux";
import { selectCurrentText } from "../../../redux/textsSlice";
import {
  selectUserGrQuestions,
  setSelectedInteractionId
} from "../../../redux/interactionsSlice";
import { interactionsAPI, grAPI } from "../../../api";
import EditingButton from "../../EditingButton";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles(theme => ({
  liLtr: {
    "& button": {
      right: "0px"
    }
  },
  editmodeLine: {
    paddingLeft: theme.spacing(2),
    minHeight: "40px"
  },
  selectedQuestion: {
    backgroundColor: theme.palette.secondary.main,
    color: "black"
  }
}));

function NewQuestion({
  newQuestionMode,
  setNewQuestionMode,
  scrollToBottom,
  color
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const questions = useSelector(selectUserGrQuestions);
  const selectedTextId = useSelector(state => state.texts.selectedTextId);
  const text = useSelector(selectCurrentText);
  const stage = useSelector(state => state.gr.stage);
  const theme = useGetTheme({ alwase: "dark" });
  const newQuestionModeRef = useRef(newQuestionMode); // we use a ref to store the correct value of "newQuestionMode" since it is not updated inside the addEventListener callback

  useEffect(() => {
    newQuestionModeRef.current = newQuestionMode;
  }, [JSON.stringify(newQuestionMode)]); // eslint-disable-line

  useEffect(() => {
    // Useing an onBlur instead of a clickOutside becouse clicking on the reader's Iframe won't trigger a click outside
    window.addEventListener("blur", onBlur);
    function onBlur() {
      addQuestion();
    }
    return () => {
      window.removeEventListener("blur", onBlur);
    };
  }, []);

  function handleKeyPress(e) {
    if (e.key === "Enter") {
      addQuestion();
      e.preventDefault();
    }
  }

  async function addQuestion() {
    if (newQuestionModeRef.current.value) {
      interactionsAPI.createQuestion(
        {
          content: newQuestionModeRef.current.value,
          text_id: selectedTextId,
          course_id: text.course_id,
          order: questions.length
        },
        selectedTextId,
        text.course_id,
        {
          afterReduxCallback: id => {
            if (stage > 0) {
              // TODO: the first dispatch should go once switching to react-redux-firebase
              dispatch(setSelectedInteractionId(id));
              grAPI.updateGrState({ grQuestionId: id });
            }
          }
        }
      );
      if (stage === 0) {
        setNewQuestionMode({
          adding: true,
          value: "",
          addValue: "",
          shouldAdd: true
        });
      } else {
        setNewQuestionMode({
          adding: false,
          value: "",
          addValue: "",
          shouldAdd: false
        });
      }
      scrollToBottom();
    }
  }
  return (
    <Box
      className={clsx(
        classes.liLtr,
        classes.editmodeLine,
        classes.selectedQuestion
      )}
      style={{
        backgroundColor: theme.palette[color].light
      }}
      aria-labely="new-question-input"
    >
      <EditingButton
        editIcon={<React.Fragment />}
        colorClass="black"
        onFocusOut={() => {
          addQuestion();
          setNewQuestionMode({
            adding: false,
            value: "",
            addValue: "",
            shouldAdd: false
          });
        }}
        multiline={true}
        key={questions.length}
        text={newQuestionMode.value}
        onChange={e => {
          setNewQuestionMode({ adding: true, value: e.target.value });
        }}
        editingMode={true}
        onKeyPress={handleKeyPress}
      />
    </Box>
  );
}

NewQuestion.propTypes = {
  newQuestionMode: PropTypes.object.isRequired,
  setNewQuestionMode: PropTypes.func.isRequired,
  scrollToBottom: PropTypes.func.isRequired,
  color: PropTypes.oneOf(["secondary", "primary"])
};

export default NewQuestion;
