// Dependencies
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useIntl } from "react-intl";
import { he, enUS } from "date-fns/locale";

// Redux dependencies
import { useSelector, useDispatch } from "react-redux";
import { markNotificationAsRead } from "../../redux/firebaseMiddleware";
import { setAction } from "../../redux/readerActionsSlice";
// Components
import {
  generateNotificationContent,
  navigateToCommentNotification
} from "../../utils/comments-utils";
import makeStyles from "@mui/styles/makeStyles";
import {
  Box,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Typography,
  Paper,
  Popper
} from "@mui/material";
import { formatDistanceStrict } from "date-fns";
import { selectIsComments, selectLocale } from "../../redux/firestoreSelectors";
import {
  selectIsSingleThread,
  setCommentPanelState,
  setSelectedThreadId
} from "../../redux/realtimeInteractionsSlice";
import { COMMENT_PANEL_VIEW, SECONDARY_SIDEBAR_STATE } from "../../consts";
import { setSecondarySidebarViewMode } from "../../redux/layoutSlice";

// Styles
const ITEM_HEIGHT = 56; //px;

const useStyles = makeStyles(theme => ({
  notifications: {
    zIndex: 2
  },
  paper: {
    maxHeight: `${ITEM_HEIGHT * 8 + 12}px`,
    overflow: "auto"
  },
  notificationContainer: {
    width: "360px"
  },
  notificationHeader: {
    display: "flex",
    justifyContent: "space-between"
  },

  notificationContent: {},
  courseName: {
    fontWeight: "bold"
  },
  time: {
    color: theme.palette.text.secondary
  },
  notification: { height: `${ITEM_HEIGHT}px` },
  showAll: {}
}));

export default function NotificationsMenu({
  notifications,
  setIsNotificationsOpen,
  isNotificationsOpen,
  notificationIconsRef,
  handleClose
}) {
  // Hooks
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const intl = useIntl();

  // Redux state
  const userId = useSelector(state => state.user.auth.uid);
  const userRole = useSelector(
    state => state.user.userProfile.selectedCourse.course_role
  );
  const locale = useSelector(state => selectLocale(state));

  const isSingleThread = useSelector(state => selectIsSingleThread(state)); // display in panel a single selected thread
  const isComments = useSelector(state => selectIsComments(state)); // display in panel several selected threads

  // Ephemeral state
  const [sortedNotifications, setSortedNotifications] = useState(null);

  // Locale State
  const localeToDateFnsOnj = {
    en: enUS,
    he: he
  };

  //Behavior

  // Sorting the notifications based on time created from newest to oldest
  useEffect(() => {
    if (notifications) {
      setSortedNotifications(
        notifications
          .filter(
            notification => notification.isRead === false
            // &&
            // notification?.course_id === course_id  <-- Optional filtering by course id, won't take into account older generated notifications
          )
          .sort((a, b) => {
            return (
              new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
            );
          })
      );
    }
  }, [notifications]);

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setIsNotificationsOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(isNotificationsOpen);
  React.useEffect(() => {
    if (prevOpen.current === true && isNotificationsOpen === false) {
      notificationIconsRef.current.focus();
    }

    prevOpen.current = isNotificationsOpen;
  }, [isNotificationsOpen, notificationIconsRef]);

  function markAsRead(notificationId) {
    dispatch(
      markNotificationAsRead({
        notificationId: notificationId,
        userId: userId
      })
    );
  }

  async function navigateToTask(notification) {
    await navigateToCommentNotification(
      notification,
      isComments,
      isSingleThread
    );
    history.push(notification.link);
  }

  // Render
  return (
    <Popper
      open={isNotificationsOpen}
      anchorEl={notificationIconsRef.current}
      placement="bottom-end"
      transition
      disablePortal
      style={{ zIndex: 3 }}
    >
      {({ TransitionProps }) => (
        <Grow {...TransitionProps}>
          <Paper className={classes.paper}>
            <ClickAwayListener onClickAway={handleClose}>
              <MenuList
                autoFocusItem={isNotificationsOpen}
                id="menu-list-grow"
                onKeyDown={handleListKeyDown}
                className={classes.notifications}
              >
                {sortedNotifications?.map(notification => {
                  return (
                    <MenuItem
                      key={notification.id}
                      className={classes.notification}
                      onClick={async () => {
                        markAsRead(notification.id);
                        await navigateToTask(notification);
                      }}
                    >
                      <Box className={classes.notificationContainer}>
                        <Box className={classes.notificationHeader}>
                          <Typography
                            component="span"
                            variant="subtitle2"
                            className={classes.courseName}
                          >
                            {notification.course}
                          </Typography>
                          <Typography
                            component="span"
                            variant="subtitle2"
                            className={classes.time}
                          >
                            {formatDistanceStrict(
                              new Date(notification.createdAt),
                              new Date(),
                              {
                                addSuffix: true,
                                locale: localeToDateFnsOnj[locale]
                              }
                            )}
                          </Typography>
                        </Box>
                        <Box className={classes.notificationContent}>
                          <Typography
                            component="span"
                            variant="subtitle2"
                            className={classes.notification}
                          >
                            {generateNotificationContent(
                              notification,
                              intl,
                              userRole
                            )}
                          </Typography>
                        </Box>
                      </Box>
                    </MenuItem>
                  );
                })}
                <MenuItem
                  onClick={() => {
                    history.push("/notifications");
                  }}
                >
                  <Typography variant="body2" color="secondary">
                    Show all
                  </Typography>
                </MenuItem>
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
}
