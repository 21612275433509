// Dependencies
import React from "react";
import clsx from "clsx";

// Components

import makeStyles from "@mui/styles/makeStyles";
import { Box, Typography, Link } from "@mui/material";

//Styles
const useStyles = makeStyles(theme => {
  return {
    container: {
      width: "100%",
      height: "100vh",
      display: "flex"
    },
    main: {
      width: "50%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: theme.palette.background.paper
    },
    banner: {
      marginBottom: theme.spacing(2)
    },
    img: {
      width: "50%",
      height: "100%",
      backgroundImage: 'url("/resources/landingImage20211023.jpg")',
      backgroundSize: "cover",
      backgroundPosition: "center center",
      backgroundRepeat: "no-repeat"
    }
  };
});

export default function RedirectBanner() {
  // Hooks
  const classes = useStyles();

  // Redux state

  // Ephemeral state

  // Behavior

  // Render
  return (
    <Box className={classes.container}>
      <Box className={clsx(classes.main)}>
        <Typography
          className={classes.banner}
          variant="h2"
          component="span"
          align="center"
        >
          Alethea has moved to a new location!
        </Typography>

        <Box>
          <Link
            href="https://app.alethea.co/"
            target="_blank"
            rel="noopener"
            className={classes.link}
          >
            Click here to visit the new location
          </Link>
        </Box>
      </Box>
      <div className={classes.img} />
    </Box>
  );
}
