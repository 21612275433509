// Dependencies
import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";

// Redux
import { useSelector } from "react-redux";
import { selectTask, selectSubmission } from "../../../redux/tasksSlice";

import makeStyles from "@mui/styles/makeStyles";
import {
  Typography,
  Card,
  CardContent,
  CardActions,
  Tooltip
} from "@mui/material";
import { useIntl } from "react-intl";
import clsx from "clsx";

// Styles
const useStyles = makeStyles(theme => {
  return {
    answer: {
      marginBottom: theme.spacing(2),
      breakInside: "avoid",
      pageBreakInside: "avoid",
      cursor: "pointer"
    },
    answerBody: {
      display: "-webkit-box",
      overflow: "hidden",
      "-webkit-line-clamp": "6",
      "-webkit-box-orient": "vertical",
      textOverflow: "ellipsis"
    },
    answerBodyExpanded: {
      fontFamily: theme.typography.fontFamily,
      fontSize: "16px",
      textAlign: "start"
    },
    answerActions: {
      padding: "16px",
      color: "#168FEE",
      display: "flex",
      justifyContent: "space-between"
    },
    showQuoteBtn: { color: "secondary" },
    showQuoteBtnOnly: {
      display: "flex",
      justifyContent: "flex-end"
    }
  };
});

export default function OpenQuestionStats({ answer, index, annonymizeForm }) {
  // Hooks
  const classes = useStyles();
  const history = useHistory();
  const intl = useIntl();
  const divRef = useRef();

  // Redux State
  const task = useSelector(state => selectTask(state, answer.task_id));
  const submission = useSelector(state =>
    selectSubmission(state, answer.submission_id)
  );

  // Ephemeral State
  const [quoteTextExpanded, setQuoteTextExpanded] = useState(false);
  const [shortText, setShortText] = useState(false);

  useEffect(() => {
    if (divRef.current)
      if (divRef.current.offsetHeight < divRef.current.scrollHeight) {
        setShortText(false);
      } else {
        setShortText(true);
      }
  }, [answer.content]);

  // Derived State
  return (
    <Tooltip title="Click to see list" placement="right" arrow>
      <Card className={classes.answer} variant="outlined">
        <CardContent
          onClick={() => {
            history.push(
              `/task?submission_id=${answer.submission_id}&question_id=${index}`
            );
          }}
        >
          <Typography
            className={clsx(
              !quoteTextExpanded && classes.answerBody,
              quoteTextExpanded && classes.answerBodyExpanded
            )}
            variant="body1"
            component="p"
            ref={divRef}
          >
            {answer.content || "(No answer)"}
          </Typography>
        </CardContent>
        <CardActions
          className={clsx(
            classes.answerActions,
            annonymizeForm && classes.showQuoteBtnOnly
          )}
        >
          {!annonymizeForm && (
            <Typography size="small">{submission.user_name}</Typography>
          )}
          <Typography
            variant="subtitle2"
            // color={color}
            color={"secondary"}
            className={classes.showQuoteBtn}
            onClick={() => {
              setQuoteTextExpanded(!quoteTextExpanded);
            }}
          >
            {!shortText && (
              <>
                {intl.formatMessage({
                  id: !quoteTextExpanded ? "showLess.text" : "show.text",
                  defaultMessage: !quoteTextExpanded ? "Show more" : "Show less"
                })}
              </>
            )}
          </Typography>
        </CardActions>
      </Card>
    </Tooltip>
  );
}
