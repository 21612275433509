// Dependancies
import { eachDayOfInterval } from "date-fns";
import { times, random, sample } from "lodash";

const fakeDates = eachDayOfInterval({
  start: new Date(2014, 9, 1),
  end: new Date(2014, 10, 1)
});

const fakeGroups = ["class", "user"];

const fakeDueDates = times(fakeDates.length, () =>
  sample([null, null, `task`])
);
export const fakeData = fakeGroups.reduce((accumulator, current) => {
  times(fakeDates.length, i => {
    accumulator.push({
      date: fakeDates[i],
      value: Math.floor(random(100)),
      taskDueDate: fakeDueDates[i],
      ReadingTime: `${random(10)}h ${random(59)}min`,
      writingTime: `${random(59)}min`,
      citationsCreated: `${random(100)}`,
      questionsCreated: `${random(100)}`,
      comments: `${random(100)}`,
      group: current
    });
  });

  return accumulator;
}, []);
