// TODO: this should be made into a reusable component and moved to the layout level

// Dependencies
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { captureException } from "../../../utils/errorHandlers";
import { firebaseFunctions } from "../../../firebase";
import { useQuery } from "../../../hooks";
import { v4 as uuid } from "uuid";

// Redux dependencies
import { useSelector, useDispatch } from "react-redux";
import { setSubmittedStudentReflection } from "../../../redux/taskSlice";
import { enqueueFlashMessage } from "../../../redux/userSlice";
import { setIsLoading } from "../../../redux/readerActionsSlice";
import { removeActiveTaskPerText } from "../../../redux/firebaseMiddleware";
import {
  selectTask,
  selectSubmission,
  updateTaskSubmissionStatus
} from "../../../redux/tasksSlice";
import { selectCourseByTaskId } from "../../../redux/coursesSlice";

import makeStyles from '@mui/styles/makeStyles';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from "@mui/material/CircularProgress";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField
} from "@mui/material";
import { selectTextDirection } from "../../../redux/firestoreSelectors";
import { addSnackbarItem } from "../../../redux/snackbarSlice";

// Styles
const useStyles = makeStyles(theme => ({
  // this is styling the paper component within the dialog
  reflectionModal: {
    position: "relative",
    width: "50%",
    padding: "10px",
    textAlign: "start"
  },
  reflectionModalActions: {
    justifyContent: "end",
    "& button": {
      fontSize: "inharit",
      fontWeight: "600"
    }
  },
  button: { textTransform: "none" },
  buttonDiscard: {
    color: "#787877"
  }
}));

export default function PeerReviewReflectionModal({
  dialogOpen,
  setDialogOpen
}) {
  //Hooks
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const { submission_id } = useQuery();

  //Redux state
  const submission = useSelector(state =>
    selectSubmission(state, Number(submission_id))
  );
  const task = useSelector(state => selectTask(state, submission.task_id));
  const course = useSelector(state =>
    selectCourseByTaskId(state, submission.task_id)
  );
  const textDirection = useSelector(state => selectTextDirection(state));
  const userDisplayName = useSelector(state => state.user.auth.displayName);

  // const task = useSelector(state => state.task.taskRow);
  const alertsDuration = useSelector(
    state => state.user.userProfile.alertsDuration
  );

  //Ephemeral state
  const [openDropdown, setOpenDropdown] = useState(false);
  const [options, setOptions] = useState([]);
  const [reviewerId, setReviewerId] = useState(null);
  const [submitButtonHandler, setSubmitButtonHandler] = useState(false);

  // Variables
  const loading = openDropdown && options.length === 0;

  //Behavior

  useEffect(() => {
    let active = true;

    if (!loading) return undefined;
    // Get the students list
    let getCourseStudentsNamesAndIds = firebaseFunctions.httpsCallable(
      "courses-getOptionalCollaborators"
    );
    getCourseStudentsNamesAndIds({
      course: course.id,
      task: task.id
    }).then(result => {
      if (active) {
        setOptions(result.data);
      }
    });

    return () => {
      active = false;
    };
  }, [course.id, loading, task]);

  useEffect(() => {
    if (!openDropdown) {
      setOptions([]);
    }
  }, [openDropdown]);

  const closeModal = () => {
    setDialogOpen(false);
  };

  async function handleSubmitReflection() {
    try {
      const submitReflection = firebaseFunctions.httpsCallable(
        "courses-submitPeerReviewReflection"
      );
      let submission_id = submission.id;
      const { data } = await submitReflection({
        task_id: task.id,
        collaborator_id: reviewerId,
        submission_id: submission_id,
        student_name: userDisplayName
      });
      if (data) {
        dispatch(
          updateTaskSubmissionStatus({
            id: submission_id,
            status: "pendingResponse",
            submission_date: new Date().toJSON()
          })
        );
        dispatch(setSubmittedStudentReflection(task.id));
        dispatch(
          addSnackbarItem({
            submission_id,
            actions: [
              {
                intlId: "undo",
                intlDefaultMsg: "undo",
                callBack: "undoReflectionSubmission"
              }
            ],
            intlId: "tasks.submitted",
            intlDefaultMessage: "Reflection was shared successfully",
            id: uuid() // TODO: we are not capturing this id, why do we need to pass it and not create it in the SnackBar component?
          })
        );
        dispatch(removeActiveTaskPerText({ text_id: task.text_id }));
        dispatch(setIsLoading(false));
        history.push(`/tasks?course_id=${course.id}`);
      } else {
        throw new Error("Failed to submit peer review reflection");
      }
    } catch (err) {
      dispatch(setIsLoading(false));
      dispatch(
        enqueueFlashMessage({
          message:
            "There was a problem submittion the reflection. Please check your connection and try again",
          duration: alertsDuration
        })
      );
      captureException(err, `task: ${task.id}, submission: ${submission.id}`);
    }
  }

  // Render
  return <>
    <div>
      <Dialog
        open={dialogOpen}
        onClose={closeModal}
        aria-labelledby="alert-dialog-title"
        PaperProps={{
          className: classes.reflectionModal,
          style: {
            direction: textDirection
          }
        }}
      >
        <DialogTitle id="alert-dialog-title">
          <FormattedMessage
            id="task.peerReview.modal.title"
            defaultMessage="Share with peer to get a review"
          />
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <FormattedMessage
              id="task.peerReview.modal.content"
              defaultMessage="Choose peer to share with"
            />
          </DialogContentText>
          <Autocomplete
            id="select-reviewr"
            fullWidth
            onChange={(e, value) => setReviewerId(value?.uid)}
            open={openDropdown}
            onOpen={() => {
              setOpenDropdown(true);
            }}
            onClose={() => {
              setOpenDropdown(false);
            }}
            isOptionEqualToValue={(option, value) => option.name === value.name}
            getOptionLabel={option => option.name}
            options={options}
            loading={loading}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Peers"
                variant="outlined"
                aria-label="Peer-review-reflection-peers-input"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {loading ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  )
                }}
              />
            )}
          />
        </DialogContent>
        <DialogActions className={classes.reflectionModalActions}>
          <Button
            onClick={closeModal}
            className={classes.button}
            color="primary"
          >
            <FormattedMessage id="cancel" defaultMessage="Cancel" />
          </Button>
          <Button
            disabled={!reviewerId || submitButtonHandler}
            className={classes.button}
            onClick={async () => {
              setSubmitButtonHandler(true);
              dispatch(setIsLoading(true));
              await handleSubmitReflection();
            }}
            color="primary"
            variant="contained"
            disableElevation
          >
            <FormattedMessage id="task.share" defaultMessage="Share" />
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  </>;
}
