import React, { useEffect } from "react";
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import { Button, Typography, Paper } from "@mui/material";
import { useHistory } from "react-router-dom";
import { useIntl } from "react-intl";
import CloseIcon from "@mui/icons-material/Close";
import clsx from "clsx";
import useGetTheme from "./hooks/useGetTheme";
import { motion } from "framer-motion";
import PropTypes from "prop-types";
import { selectCourseByTextId } from "./redux/coursesSlice";
import { removeSnackbarItem } from "./redux/snackbarSlice";
import {
  interactionsAPI,
  grAPI,
  standardTaskAPI,
  tasksAPI,
  commentsAPI,
  peerReviewAPI
} from "./api";
import TYPES from "./types";
import { useDispatch, useSelector } from "react-redux";
import { selectTextDirection } from "./redux/firestoreSelectors";
import textsApi from "./api/texts";
import { enqueueFlashMessage } from "./redux/userSlice";
import { undoCreateText } from "./redux/textsSlice";

const useStyles = makeStyles(theme => ({
  SnackbarsWrapper: {
    pointerEvents: "none",
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(4),
    zIndex: 1
  },
  snackBarItemWrapper: {
    pointerEvents: "all",
    opacity: 0,
    marginTop: theme.spacing(1)
  },
  inner: {
    paddingInline: theme.spacing(2),
    paddingBlock: theme.spacing(1),
    display: "inline-flex",
    alignItems: "center"
  },
  btn: {
    textTransform: "uppercase",
    transform: "translateY(1px)",
    minWidth: "unset",
    paddingInline: 16
  }
}));

function SnackBarItem({ queueItem, onTimeOut }) {
  const classes = useStyles();
  const intl = useIntl();
  const theme = useGetTheme({ alwase: "dark" });
  const alertsDuration = useSelector(
    state => state.user.userProfile.alertsDuration
  );
  const dispatch = useDispatch();
  const history = useHistory();
  const selectedCourseId = useSelector(selectCourseByTextId)?.id;
  const selectedTextId = useSelector(state => state.texts.selectedTextId);
  const textDirection = useSelector(state => selectTextDirection(state));

  useEffect(() => {
    const timeOut = setTimeout(() => {
      onTimeOut();
    }, alertsDuration);
    return () => {
      clearTimeout(timeOut);
    };
  }, []); // eslint-disable-line

  async function callback(callBack) {
    switch (callBack) {
      case "undoDeleteComment":
        const { comment } = queueItem;
        if (comment.createWithContainer) commentsAPI.createThread(comment);
        else commentsAPI.createComment(comment);
        break;
      case "undoDeleteTaskDraft":
        tasksAPI.undoDeleteTaskDraft(queueItem.task, queueItem.interactions);
        break;
      case "undoPublishTask":
        tasksAPI.undoPublishTask(queueItem.taskId, queueItem.offline_task_id);
        break;
      case "undoStandardTaskSubmission":
        standardTaskAPI.undoStandardTaskSubmission(
          queueItem.submission_id,
          queueItem.offline_task_id
        );
        break;
      case "undoTaskSubmission":
        standardTaskAPI.undoTaskSubmission(
          queueItem.submission_id,
          queueItem.offline_task_id
        );
        break;
      case "undoReviewSubmission":
        peerReviewAPI.undoReviewSubmission(queueItem);
        break;
      case "undoReflectionSubmission":
        peerReviewAPI.undoReflectionSubmission(queueItem.submission_id);
        break;
      case "undoSubmitGr":
        grAPI.undoGrTaskSubmission(queueItem.submission_id);
        if (queueItem.text_id && queueItem.task_id) {
          history.push(`/task?submission_id=${queueItem.submission_id}`);
        }
        break;
      case "goToReader":
        history.push(`/reader?text_id=${queueItem.text_id}`);
        break;
      case "createHighlight":
        interactionsAPI.createHighlight(
          queueItem.highlight,
          selectedTextId,
          selectedCourseId
        );
        break;

      case "undoDueDateChangeForAllStudents":
        const { task } = queueItem;
        // instead of const { task_id, prev_due_date } = queueItem;
        //Doesnt let prev_due_date to be redclared in same scope
        tasksAPI.updateDueDateForAllStudents(task, queueItem.prev_due_date);
        break;

      case "undoDueDateChangeForStudent":
        const { submission, prev_due_date } = queueItem;
        tasksAPI.updateDueDateForStudent(submission, prev_due_date);

        break;

      case "createQuestion":
        const resp = await interactionsAPI.createQuestion(
          queueItem.question,
          selectedTextId,
          selectedCourseId,
          {
            highlights: queueItem.highlights,
            summary: queueItem.summary
          }
        );
        if (resp) {
          grAPI.updateSelectedQuestionId(queueItem.question.id);
        }
        break;

      case "undoUploadText":
        const { text, offline_task_id } = queueItem;
        dispatch(undoCreateText({ text, offline_task_id }));
        break;

      default:
        break;
    }
  }

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <motion.div
          className={classes.snackBarItemWrapper}
          initial={{
            opacity: 0,
            scale: 0.5
          }}
          animate={{ opacity: 1, scale: 1 }}
        >
          <Paper className={classes.inner} dir={textDirection}>
            <Typography variant="body1" style={{ marginInlineEnd: 16 }}>
              {intl.formatMessage({
                id: queueItem.intlId,
                defaultMessage: queueItem.intlDefaultMessage
              })}
            </Typography>
            {queueItem.actions?.map(action => (
              <Button
                key={`${queueItem.id}+${action.intlDefaultMsg}`}
                onClick={() => {
                  dispatch(removeSnackbarItem(queueItem));
                  callback(action.callBack);
                }}
                className={clsx(classes.btn, classes.addColor)}
                color="primary"
                style={{
                  color: theme.palette.primary.light
                }}
                size="small"
              >
                {intl.formatMessage({
                  id: action.intlId,
                  defaultMessage: action.intlDefaultMsg
                })}
              </Button>
            ))}
            <Button
              onClick={() => {
                dispatch(removeSnackbarItem(queueItem));
              }}
              style={{
                minWidth: "unset"
              }}
              size="small"
            >
              <CloseIcon />
            </Button>
          </Paper>
        </motion.div>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

SnackBarItem.propTypes = {
  queueItem: PropTypes.shape({
    id: PropTypes.string.isRequired,
    intlId: PropTypes.string.isRequired,
    intlDefaultMessage: PropTypes.string.isRequired,
    submission_id: PropTypes.number,
    text_id: PropTypes.number,
    taskId: PropTypes.number,
    highlight: TYPES.HIGHLIGHT,
    question: TYPES.QUESTION,
    actions: PropTypes.arrayOf(
      PropTypes.shape({
        intlId: PropTypes.string.isRequired,
        intlDefaultMsg: PropTypes.string.isRequired,
        callBack: PropTypes.string
      })
    )
  }),
  onTimeOut: PropTypes.func.isRequired
};

export default function Snackbars() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const snackBarQueue = useSelector(state => state.snackbar.que);

  return (
    <div className={classes.SnackbarsWrapper} id="snackbars">
      {snackBarQueue.map(queueItem => (
        <SnackBarItem
          queueItem={queueItem}
          key={queueItem.id}
          onTimeOut={() => {
            dispatch(removeSnackbarItem(queueItem));
          }}
        />
      ))}
    </div>
  );
}
