// Dependancies
import React from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import clsx from "clsx";

import makeStyles from '@mui/styles/makeStyles';
import VisibilityIcon from "@mui/icons-material/Visibility";
import InsertChartIcon from "@mui/icons-material/InsertChart";
import {
  Table,
  TableContainer,
  TableCell,
  TableBody,
  TableRow,
  TableHead,
  IconButton
} from "@mui/material";

// Styles
const useStyles = makeStyles(theme => ({
  tableContainer: {
    overflow: "visible",
    marginBottom: theme.spacing(3),
    width: "83.333333%" // 10 culumns on a 12 column grid
  },
  tableHead: {
    fontWeight: "800"
  }
}));

function CoursesAdmin({ courses }) {
  const classes = useStyles();
  const history = useHistory();

  return <>
    <TableContainer classes={{ root: classes.tableContainer }}>
      <Table aria-label="course table">
        <TableHead>
          <TableRow className={classes.tableHead}>
            <TableCell className={classes.cell}>Id</TableCell>
            <TableCell className={classes.cell}>Year</TableCell>
            <TableCell className={classes.cell}>Semester</TableCell>
            <TableCell className={classes.cell}>Name</TableCell>
            <TableCell className={classes.cell}></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {courses.map(course => (
            <TableRow key={course.id} className={clsx(classes.courseRow)}>
              <TableCell className={classes.cell} align="left">
                {course.id}
              </TableCell>

              <TableCell className={classes.cell} component="th" scope="row">
                {course.academic_year}
              </TableCell>
              <TableCell className={classes.cell} align="left">
                {course.semester}
              </TableCell>
              <TableCell className={classes.cell} align="left">
                {course.name}
              </TableCell>
              <TableCell className={classes.cell} align="left">
                <IconButton
                  onClick={() => {
                    history.push(
                      `admin/CourseActivityReport?course_id=${course.id}`
                    );
                  }}
                  size="large">
                  <InsertChartIcon />
                </IconButton>
                <IconButton
                  onClick={() => {
                    history.push(`admin/course?course_id=${course.id}`);
                  }}
                  size="large">
                  <VisibilityIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  </>;
}

CoursesAdmin.propTypes = {
  courses: PropTypes.array.isRequired
};

export default CoursesAdmin;
