// Dependancies
import React from "react";
import PropTypes from "prop-types";
import * as d3 from "d3";
import { useChartDimensions } from "../../../../hooks";

// Redux

// Components
import WidgetHeader from "../../../SharedComponents/WidgetHeader";
import Chart from "../../../SharedComponents/chart/Chart";
import Axis from "../../../SharedComponents/chart/chartPrimitives/Axis";
import Line from "../../../SharedComponents/chart/chartPrimitives/Line";

// Mui
import { Box, useTheme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

// styles
const useStyles = makeStyles(theme => ({
  container: {
    height: 440,
    minHeight: 400,
    flexGrow: 1,
    position: "relative",
    display: "flex",
    flexDirection: "column"
  },
  chartContainer: {
    height: "100%",
    position: "relative"
  },
  outlined: {
    fill: theme.palette.background.paper,
    strokeWidth: 3
  }
}));

function ClassGradeDistChart({ classAvarages, user }) {
  // Hooks
  const theme = useTheme();
  const classes = useStyles();
  const { ref, dimensions } = useChartDimensions({
    marginBottom: 56,
    marginLeft: 80
  });

  // Ephemeral State
  // Derived State
  const STROKE_COLOR = theme.palette.secondary.light;
  const MARKER_RADIUS = 12;
  // D3;
  // Accessors
  const yAccessor = d => d.length; // number of students who is in this grade bin
  const xAccessor = d => `${d.x0}-${d.x1}`; // the grade bin

  // Data bins
  const binGenerator = d3
    // returns a functions that generates 10 bins from submissions grades
    .histogram()
    .domain([0, 100])
    .value(d => d.avarage)
    .thresholds([0, 50, 60, 70, 80, 90]);

  const bins = binGenerator(classAvarages);
  // !: bins is an array of arrays so we need to map over the user bin even if theres only one item in it
  const userBins = bins.filter(bin => bin.some(entry => entry.user === user));

  // Scales
  const xDomain = bins.map(xAccessor);
  const xScale = d3
    .scaleBand()
    .domain(xDomain)
    .range([0, dimensions.boundedWidth]);

  const yDomain = d3.extent(bins.map(yAccessor));
  const yScale = d3
    .scaleLinear()
    .domain(yDomain)
    .range([dimensions.boundedHeight, 0]);

  // Utils
  const xAccessorScaled = d => xScale(xAccessor(d));
  const yAccessorScaled = d => yScale(yAccessor(d));

  // Behavior

  function sumAvarages(avarages) {
    avarages.reduce((accumulator, current) => accumulator + current.avarage, 0);
  }
  if (sumAvarages <= 0 || classAvarages.length < 1) return null;
  return (
    <Box className={classes.container}>
      <WidgetHeader title="Class grade distribution (Gaussian curve)" />
      <Box style={{ height: "100%", position: "relative" }}>
        <Chart ref={ref} dimensions={dimensions}>
          <Axis dimension="x" scale={xScale} ticks={xDomain} hideAxisLines />

          <Line
            stroke={STROKE_COLOR}
            xAccessor={xAccessorScaled}
            yAccessor={yAccessorScaled}
            data={bins}
            interpolation={d3.curveLinear}
          />

          {userBins.map(bin => (
            <circle
              key={bin.x0}
              className={classes.outlined}
              cx={xAccessorScaled(bin)}
              cy={yAccessorScaled(bin)}
              r={MARKER_RADIUS}
              stroke={STROKE_COLOR}
            />
          ))}
        </Chart>
        <Box // TODO: this overlay should be it's own reuseable component
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            transform: `translate(${dimensions.marginLeft}px, ${dimensions.marginTop}px)`,
            width: dimensions.boundedWidth,
            height: dimensions.boundedHeight
          }}
        >
          {userBins.map(bin => {
            const displayName = bin.find(
              entry => entry.user === user
            ).displayName;
            return (
              <Box
                key={bin.x0}
                component="span"
                style={{
                  position: "absolute",
                  // place the text and give it some space
                  top: `${yAccessorScaled(bin) - MARKER_RADIUS * 1.2}px`,
                  left: `${xAccessorScaled(bin) + MARKER_RADIUS * 2}px`,
                  transformOrigin: "left bottom",
                  transform: "rotate(0deg)"
                }}
              >
                {displayName}
              </Box>
            );
          })}
        </Box>
      </Box>
    </Box>
  );
}

ClassGradeDistChart.propTypes = {
  classAvarages: PropTypes.array.isRequired,
  user: PropTypes.string.isRequired
};

export default ClassGradeDistChart;
