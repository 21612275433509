import React from "react";
import * as ReactDOM from "react-dom";

const useCreatePortal = el => {
  const [portal, setPortal] = React.useState({
    render: () => null,
    remove: () => null
  });

  const createPortal = React.useCallback(el => {
    const Portal = ({ children }) => el && ReactDOM.createPortal(children, el);
    const remove = () => el && ReactDOM.unmountComponentAtNode(el);
    return { render: Portal, remove };
  }, []);

  React.useEffect(() => {
    const newPortal = createPortal(el);
    setPortal(newPortal);
    return () => {
      if (newPortal && el) newPortal.remove(el);
    };
  }, [el, createPortal]);

  return portal.render;
};

export default useCreatePortal;
