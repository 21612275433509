// Dependancies
import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
  useReducer
} from "react";
import PropTypes from "prop-types";
import { pdfjs } from "react-pdf";
import clsx from "clsx";
import { Document } from "react-pdf/dist/esm/entry.webpack5";
import {
  getPagesFromRange,
  getWindow,
  getClientRects,
  viewportToScaled
} from "../../../utils/pdf-utils";
import PdfCFI from "../../../utils/pdf-cfi";
import { useLocalStorage } from "../../../hooks/useLocalStorage";

// Redux dependancies
import { useDispatch, useSelector } from "react-redux";
import { closeAnnotatorBar } from "../../../redux/highlightSlice";
import {
  setPageRenderMode,
  setPdfTotalPages,
  setThumbnailsAreReady
} from "../../../redux/pdfSlice";
import { selectCurrentText } from "../../../redux/textsSlice";
import { updateTextLocation } from "../../../redux/userSlice";

// Components
import PdfPageWithHighlights from "./PdfPageWithHighlights";
import { PdfHighlight } from "./PdfTypes";

import makeStyles from "@mui/styles/makeStyles";
import { Box } from "@mui/material";
import {
  scrollAnnotationIntoView,
  scrollPageAndThumbnailIntoView
} from "./utils";
import useResizeObserver from "../../../hooks/useResizeObserver";
import { debounce } from "lodash";
import { SAVED_THUMBNAILS_CONFIG, TEXT_TYPE } from "../../../consts";
import { logLocationChangeEvent } from "../utils";
import { isEmpty, useFirestoreConnect } from "react-redux-firebase";
import { selectBookmarkedPosition } from "../../../redux/firestoreSelectors";

//pdf worker - version should match the one expected by react-pdf library
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@2.14.305/build/pdf.worker.min.js`;

// Styles
const useStyles = makeStyles(theme => ({
  bookContainer: {
    overflow: "auto",
    overflowX: "auto",
    width: "100%",
    height: "100%",
    display: "flex",
    paddingTop: "10px",
    flexDirection: "column"
  }
}));

const initialState = {};

function pagesReducer(state, action) {
  switch (action.type) {
    case "setPages": {
      const { numberOfPages } = action.payload;
      const updatedState = [...Array(numberOfPages)].reduce(
        (accumulator, curent, index) => {
          accumulator[index + 1] = false;
          return accumulator;
        },
        {}
      );

      return updatedState;
    }
    case "setIsRendered": {
      const { pageNumber, isRendered } = action.payload;
      const updatedState = { ...state };
      updatedState[pageNumber] = isRendered;
      return updatedState;
    }

    default:
      throw new Error();
  }
}

function PdfView({
  url,
  highlights = [],
  underlines = [],
  highlightClicked,
  handleTextSelected,
  backgroundColor,
  isVisible,
  onLoaded,
  scrollToPosition = {}
}) {
  // Hooks
  const { ref, width } = useResizeObserver();
  const dispatch = useDispatch();
  const classes = useStyles();
  const documentRef = useRef();
  const firstRenderRef = useRef(true);
  const firstScrollRef = useRef(true);

  // Redux state
  const user = useSelector(state => state.firebase.auth.uid);
  const text = useSelector(state => selectCurrentText(state));
  const currentPage = useSelector(state => state.pdf.currentPage);
  const isAnnotatorBarOpen = useSelector(
    state => state.highlighter.isAnnotatorBarOpen
  );
  const zoom = useSelector(state => state.pdf.zoom);

  useFirestoreConnect([
    {
      collection: "textLocations",
      doc: `${user}`,
      subcollections: [{ collection: "texts" }],
      storeAs: "textLocations"
    }
  ]);

  const bookmarkedPosition = useSelector(state =>
    selectBookmarkedPosition(state, text.id)
  );

  // Ephemeral state
  // Similar to useState but first arg is key to the value in local storage.
  const [thumbnails, setThumbnails] = useLocalStorage(
    `${text.id}-thumbnails`,
    []
  );

  // Derived State

  //whether highlight is interactive or not
  const [interactive, setInteractive] = useState(true);
  const [numberOfPages, setNumberOfPages] = useState(0);
  const [renderedPages, dispatchRenderedPages] = useReducer(
    pagesReducer,
    initialState
  );
  // Derived state
  scrollToPosition = isEmpty(scrollToPosition)
    ? bookmarkedPosition
    : scrollToPosition;
  // Behavior;

  useEffect(() => {
    function dispatchCloseAnnotatorBar() {
      if (isAnnotatorBarOpen) dispatch(closeAnnotatorBar());
      return;
    }

    document.addEventListener("scroll", dispatchCloseAnnotatorBar, true);
    return () => {
      document.removeEventListener("scroll", dispatchCloseAnnotatorBar, true);
    };
  }, [isAnnotatorBarOpen, dispatch]);

  const calculatePageRenderMode = useCallback(
    pageNumber => {
      if (Number(scrollToPosition.lastPage) === pageNumber) return "canvas";
      else {
        // We alow for max 5 canvas elements since they slow down rendering
        if (Math.abs(currentPage - pageNumber) <= 2) return "canvas";
        else {
          renderedPages[pageNumber] &&
            dispatchRenderedPages({
              type: "setIsRendered",
              payload: { pageNumber, isRendered: false }
            });
          return "none";
        }
      }
    },
    [scrollToPosition, currentPage, renderedPages]
  );

  const scrollToBookmark = useCallback(() => {
    scrollPageAndThumbnailIntoView(scrollToPosition.lastPage);
  }, [scrollToPosition?.lastPage]);

  // This is here to keep the reader on the last saved ...
  // ... page when cahnging the size or zoon on the reader,
  useEffect(() => {
    isVisible && scrollToBookmark();
  }, [width, zoom, isVisible]);
  useEffect(() => {
    if (!scrollToPosition.lastPage) return;
    else if (
      firstScrollRef.current &&
      renderedPages[scrollToPosition.lastPage]
    ) {
      const page = scrollToPosition.lastPage;
      const block = scrollToPosition.position;
      scrollPageAndThumbnailIntoView(page, { block: block });
      if (scrollToPosition?.id) scrollAnnotationIntoView(scrollToPosition?.id);
      firstScrollRef.current = false;
    }
  }, [
    renderedPages,
    scrollToPosition.position,
    scrollToPosition.lastPage,
    scrollToPosition?.id
  ]);

  function viewportPositionToScaled(pdfRects, pages) {
    //create scaled rectanges (with width and height of the fuill container)
    const pagesDict = pages.reduce((acc, entry) => {
      acc[entry.number] = entry.node.getBoundingClientRect();
      return acc;
    }, {});
    return pdfRects.map(pageRect => {
      return {
        ...pageRect,
        pageRects: (pageRect.pageRects || []).map(rect =>
          viewportToScaled(rect, pagesDict[pageRect.pageNumber])
        )
      };
    });
  }

  function handleMouseUp() {
    onSelectionFinished();
    setInteractive(true);
  }

  function onSelectionFinished() {
    const container = documentRef.current;
    const selection = getWindow(container).getSelection();
    if (selection.isCollapsed) {
      isAnnotatorBarOpen && dispatch(closeAnnotatorBar());
      return;
    }

    const range = selection.rangeCount > 0 ? selection.getRangeAt(0) : null;

    if (
      !range ||
      !container ||
      !container.contains(range.commonAncestorContainer)
    ) {
      return;
    }

    const pages = getPagesFromRange(range);

    if (!pages || pages.length === 0) return;

    const pdfRects = getClientRects(range, pages, container);
    if (pdfRects.length === 0) return;

    const cfi = new PdfCFI(
      range,
      pages[0].node.getElementsByClassName("textLayer")[0],
      pages[0].number,
      pages[pages.length - 1].node.getElementsByClassName("textLayer")[0],
      pages[pages.length - 1].number
    );

    //pdf ranges have <br/> that are not preserved or changed to newlines,
    //so this code will keep the text content and change br tags to spaces
    const rangeChildren = range.cloneContents().childNodes;
    let textContent = Array.from(rangeChildren)
      .reduce((result, node) => {
        let tempRes = result + node.textContent;
        if (node.tagName === "BR") {
          tempRes += " ";
        }
        return tempRes;
      }, "")
      .replace("  ", " ");

    const scaledPosition = viewportPositionToScaled(pdfRects, pages);
    //anomalous cfi selection may look like that: /4/4!,/218/1:22,/4/16/6/2/4/2/2/2/2/2/2/6/8/2/1:0)
    //preventing it from entering the system.
    if (cfi.toString().match(/\//g).length > 15) return;
    handleTextSelected({
      selection: {
        isPdf: true,
        cfi: cfi.toString(),
        content: textContent,
        pdfPosition: scaledPosition
      },
      pos: scaledPosition,
      clientRect: pdfRects
    });
  }

  function displaySpinner() {
    return (
      <lottie-player
        src="/loading_book_lottie.json"
        mode="bounce"
        background="transparent"
        speed="1"
        style={{
          width: "300px",
          height: "300px",
          position: "absolute",
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)"
        }}
        loop
        autoplay
      />
    );
  }

  function handleLoadSuccess(pdf) {
    const { numPages } = pdf;

    createThumbnails(pdf);
    dispatch(setPdfTotalPages(numPages));
    dispatchRenderedPages({
      type: "setPages",
      payload: { numberOfPages: numPages }
    });
    setNumberOfPages(numPages);

    onLoaded && onLoaded();
  }

  function handleScroll(e) {
    const target = e.currentTarget;
    updatePdfTextLocation(target);
    debouncedLogReadingAction(currentPage);
  }

  const updatePdfTextLocation = useCallback(
    target => {
      // Hack: skipping the first render otherwise it will set the location to page 1 on mount
      if (firstRenderRef.current) firstRenderRef.current = false;
      else {
        let text_id = text.id;
        dispatch(
          updateTextLocation({
            text_id,
            position: null,
            lastPage: currentPage,
            type: TEXT_TYPE.PDF
          })
        );
      }
    },
    [dispatch, currentPage, text.id]
  );

  const logReadingAction = useCallback(
    currentPage => {
      // for PDF, the start and end are currently the same (current page)
      logLocationChangeEvent(
        text.id,
        "",
        text.course_id,
        user,
        currentPage,
        currentPage,
        TEXT_TYPE.PDF
      );
    },
    [text.course_id, text.id, user]
  );

  const debouncedLogReadingAction = useMemo(
    () => debounce(logReadingAction, 5000),
    [logReadingAction]
  );

  function createThumbnails(pdf) {
    Promise.all(
      // crearing an numberOfPages length array of undefinds and looping over the indexes
      [...Array(pdf.numPages).keys()].map(pageIndex => {
        return pdf
          .getPage(pageIndex + 1)
          .then(page => createThumbnail(page))
          .then(url => {
            return {
              thumbnailSrc: url,
              pageNum: pageIndex + 1
            };
          });
      })
    ).then(thumbnails => {
      setThumbnails(thumbnails);
      dispatch(setThumbnailsAreReady(true));
    });
  }

  function createThumbnail(page) {
    // If we save the thumbnails as is we get 500kb files
    // scaleing down to .5 and compressing .9 gets us to <100k
    // we can probably get better results if don't scale and prosses the image before exporting
    const viewport = page.getViewport({ scale: 0.5 });
    const canvas = document.createElement("canvas");

    canvas.height = viewport.height;
    canvas.width = viewport.width;
    const pageParam = {
      canvasContext: canvas.getContext("2d"),
      viewport
    };
    return page.render(pageParam).promise.then(() => {
      const src = canvas.toDataURL(
        `image/${SAVED_THUMBNAILS_CONFIG.FORMAT}`,
        `${SAVED_THUMBNAILS_CONFIG.QUALITY}`
      );
      canvas.width = 0;
      canvas.height = 0;
      return src;
    });
  }

  const setPageRendered = useCallback(pageNumber => {
    dispatchRenderedPages({
      type: "setIsRendered",
      payload: { pageNumber, isRendered: true }
    });
  }, []);

  return (
    <Box style={{ height: "100%" }} ref={ref}>
      {url && (
        <Document
          className={clsx(classes.bookContainer)}
          style={{ backgroundColor }}
          options={{
            cMapUrl: `https://unpkg.com/pdfjs-dist@2.14.305/cmaps/`,
            standardFontDataUrl: `https://unpkg.com/pdfjs-dist@2.14.305/standard_fonts`,
            cMapPacked: true
          }}
          loading={displaySpinner}
          file={url}
          onLoadError={error => {
            console.log("eror", error);
          }}
          onLoadSuccess={handleLoadSuccess}
          onMouseUp={handleMouseUp}
          onMouseDown={() => {
            setInteractive(false);
          }}
          inputRef={documentRef}
          onScroll={e => handleScroll(e)}
        >
          {[...Array(numberOfPages)].map((k, i) => {
            const pageNumber = i + 1;
            //resetting styles because otherwise text layer and pdf canvas are misaligned
            return (
              <PdfPageWithHighlights
                key={pageNumber}
                highlights={highlights}
                underlines={underlines}
                scale={1}
                pageNumber={pageNumber}
                onHighlightClick={highlightClicked}
                isVisible={isVisible}
                width={width}
                setPageRendered={setPageRendered}
                renderMode={calculatePageRenderMode(pageNumber)}
              />
            );
          })}
        </Document>
      )}
    </Box>
  );
}

PdfView.propTypes = {
  url: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(ArrayBuffer)
  ]),
  highlightClicked: PropTypes.func.isRequired,
  backgroundColor: PropTypes.string,
  zoom: PropTypes.number,
  handleTextSelected: PropTypes.func.isRequired,
  isVisible: PropTypes.bool,
  onLoaded: PropTypes.func,
  location: PropTypes.shape(PdfHighlight),
  highlights: PropTypes.arrayOf(PropTypes.shape(PdfHighlight)),
  underlines: PropTypes.arrayOf(PropTypes.shape(PdfHighlight)),
  locationChanged: PropTypes.func
};

// export default EpubView;
export default PdfView;
