// Dependencies
import React from "react";
import clsx from "clsx";
import { useSelector, useDispatch } from "react-redux";
import { useQuery } from "../../hooks";

// Redux
import { updateSq3r } from "../../redux/firebaseMiddleware";

// Components
import PocBar from "../../components/poc/pocBar";

import makeStyles from "@mui/styles/makeStyles";
import { Box } from "@mui/material";

// Styles
const useStyles = makeStyles(theme => {
  return {
    drawerLtr: {
      left: 0
    },
    drawer: {
      display: "flex",
      transition: ".3s ease-out",
      flexShrink: 0,
      zIndex: 3,
      borderLeft: "1px solid grey",
      overflow: "auto",
      overflowX: "hidden",
      position: "relative"
    },
    drawerOpen: {
      overflow: "hidden",
      height: "100%",
      width: 336,
      [theme.breakpoints.up("desktop")]: {
        width: 420
      }
    },
    drawerClosed: {
      width: "48px"
    }
  };
});

export default function ReaderSidebar() {
  // Hooks
  const classes = useStyles();
  const dispatch = useDispatch();
  const { submission_id } = useQuery();
  // Redux State
  const action = useSelector(state => {
    return state.readerActions.persistentActionState.actionBar;
  });
  const activeGrTaskId = useSelector(state => state.tasks.activeGrTaskId);

  // Derived state
  const isTask = activeGrTaskId || Boolean(submission_id);
  return (
    <Box
      className={clsx(
        classes.drawer,
        classes.drawerLtr,
        action ? classes.drawerOpen : classes.drawerClosed
      )}
    >
      <PocBar
        color={isTask ? "primary" : "secondary"}
        onAnswerCardCopy={questions => {
          dispatch(
            updateSq3r({
              questions
            })
          );
        }}
      />
    </Box>
  );
}
