import React from "react";
import { useIntl } from "react-intl";
import clsx from "clsx";

import { PRIVACY } from "../../consts";

// Redux dependencies
import { useDispatch, useSelector } from "react-redux";
import { setCommentsPrivacy } from "../../redux/realtimeInteractionsSlice";
import { selectCommentsPrivacy } from "../../redux/firestoreSelectors";
//Components

// Material UI
import { Select, MenuItem } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles(theme => ({
  dropdown: {
    fontSize: "12px",
    float: "left",
    clear: "none",
    letterSpacing: theme.spacing(0.1)
  }
}));

function PrivacyDropdown() {
  // Hooks
  const classes = useStyles();
  const intl = useIntl();
  const dispatch = useDispatch();

  // Redux State
  const privacy = useSelector(state => selectCommentsPrivacy(state));

  // Ephemeral State

  //Derived state
  const publicComments = intl.formatMessage({
    id: "comments.community",
    defaultMessage: "Community"
  });
  const privateComments = intl.formatMessage({
    id: "comments.private",
    defaultMessage: "Private"
  });
  const instructorFeedback = intl.formatMessage({
    id: "comments.teacherFeedback",
    defaultMessage: "Instructor feedback"
  });

  // Behavior
  function handleChange(event) {
    dispatch(setCommentsPrivacy(event.target.value));
  }
  return (
    <Select
      variant="standard"
      disableUnderline
      value={privacy}
      onChange={handleChange}
      displayEmpty
      inputProps={{ "aria-label": "comments privacy filter" }}
      className={clsx(classes.dropdown)}
    >
      <MenuItem value={PRIVACY.PUBLIC}>{publicComments} comments</MenuItem>
      <MenuItem value={PRIVACY.ME_ONLY}>{privateComments} comments</MenuItem>
      <MenuItem value={PRIVACY.FEEDBACK} disabled>
        {instructorFeedback}
      </MenuItem>
    </Select>
  );
}

export default PrivacyDropdown;
