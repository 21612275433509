import React, { useEffect } from "react";
import clsx from "clsx";
import makeStyles from "@mui/styles/makeStyles";
import PropTypes from "prop-types";
import { scrollPageIntoView, scrollThumbnailIntoView } from "../utils";

const useStyles = makeStyles(theme => ({
  focused: {
    borderColor: `${theme.palette.secondary.light} !important`
  },
  placeholder: {
    width: "144px", //  240 - (48 * 2) i.e., sidebar - padding
    cursor: "pointer",
    border: `solid 1px transparent`,
    "&:hover": {
      border: `solid 1px ${theme.palette.secondary.light}`
    }
  }
}));

const Thumbnail = ({ pageNum, thumbnailSrc, currentPage }) => {
  const classes = useStyles();

  useEffect(() => {
    // scroll the thumbnail into view if it is the current page
    if (currentPage === pageNum) {
      scrollThumbnailIntoView(pageNum);
    }
  }, [currentPage, pageNum]);

  function handleClick(pageNumber) {
    scrollPageIntoView(pageNumber);
  }

  return (
    <img
      alt={pageNum}
      src={thumbnailSrc}
      data-thumbnail-number={pageNum}
      className={clsx(
        currentPage === pageNum && classes.focused,
        classes.placeholder
      )}
      onClick={() => handleClick(pageNum)}
    />
  );
};

Thumbnail.propTypes = {
  setCurrentPage: PropTypes.func,
  pageNum: PropTypes.number,
  thumbnailSrc: PropTypes.string,
  currentPage: PropTypes.number,
  setPagesState: PropTypes.func
};

export default Thumbnail;
