// Dependencies
import React, { useState, useEffect } from "react";
import { httpCallables } from "../../../../firebase";
import { useHistory } from "react-router-dom";

import { sub } from "date-fns";
import { useQuery } from "../../../../hooks";
import clsx from "clsx";
import { v4 as uuid } from "uuid";

// Redux
import { useDispatch, useSelector } from "react-redux";
import { selectCourse } from "../../../../redux/coursesSlice";
import { addSnackbarItem } from "../../../../redux/snackbarSlice";

//Components
import ScrollBox from "../../../SharedComponents/ScrollBox";
import CourseActivityReport from "../courseActivity/CourseActivityReport";
import UserActivityReport from "./UserActivityReport";

import makeStyles from '@mui/styles/makeStyles';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from "@mui/material/TextField";

import {
  Box,
  Grid,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  IconButton,
  Tooltip,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { captureException } from "../../../../utils/errorHandlers";

// Styles
const useStyles = makeStyles(theme => ({
  container: {
    width: "100%",
    padding: theme.spacing(8)
  },
  header: { marginBlockEnd: theme.spacing(8) },
  formControl: {
    minWidth: 120
  },
  dailyMetadata: {
    width: "50%",
    display: "flex",
    justifyContent: "space-between",
    marginBlockEnd: theme.spacing(3)
  },
  metadata: {
    "& span:not(:last-child)": {
      marginInlineEnd: theme.spacing(5)
    }
  },
  tableTitle: {
    marginBlockEnd: theme.spacing(3)
  },
  table: {
    marginBlockEnd: theme.spacing(3)
  },
  tableHead: {
    backgroundColor: theme.palette.secondary.p12
  },
  tableRow: {},
  tableHeadCell: {
    position: "relative",
    fontWeight: "bold",
    border: "none",
    "&:not(:first-child)::before": {
      content: '"|"',
      position: "relative",
      top: "-2px",
      left: "-10px",
      color: theme.palette.text.p30
    }
  },
  tableCell: {
    border: "none"
  }
}));

export default function ActivityReport() {
  //Hooks
  const dispatch = useDispatch();
  const classes = useStyles();
  const { course_id, user_id } = useQuery();
  const history = useHistory();

  // Ephemeral State
  const [users, setUsers] = useState([]);
  const [actions, setActions] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [start, setStart] = useState(sub(new Date(), { days: 2 }));
  const [end, setEnd] = useState(sub(new Date(), { days: 1 }));
  const [data, setData] = useState([]);
  const [submissions, setSubmissions] = useState([]);
  const [userEngagement, setUserEngagement] = useState([]);
  // Derived state
  const isUserActivityReport = Boolean(user_id);
  const course = useSelector(state => selectCourse(state, Number(course_id)));

  //Behavior

  useEffect(() => {
    const now = new Date();
    setStart(sub(now, { days: 2 }));
    setEnd(sub(now, { days: 1 }));
  }, []);

  useEffect(() => {
    httpCallables
      .readCourseUsers({ course_id: Number(course_id) })
      .then(({ data }) => {
        if (data.success) {
          const { users } = data.payload;
          setUsers(users);
        } else {
          const { error } = data.payload;
          dispatch(
            addSnackbarItem({
              intlId: "error.readCourseUsersFailed",
              intlDefaultMessage:
                "There was a problem getting the course information. Please check your connection and try again",
              id: uuid()
            })
          );
          captureException(error, `Faild to get course users`);
        }
      });
  }, [course_id, dispatch]);

  useEffect(() => {
    if (!start || !end || !selectedUser) return;
    httpCallables
      .generateUserActivityReport({
        user_id: selectedUser,
        start,
        end,
        course_id,
        course_users: users
      })
      .then(({ data }) => {
        const { success } = data;
        if (success) {
          // set the stats
        } else {
          const { error } = data;

          dispatch(
            addSnackbarItem({
              intlId: "error.readCourseUsersFailed",
              intlDefaultMessage:
                "There was a problem getting the course information. Please check your connection and try again",
              id: uuid()
            })
          );

          captureException(error, `Faild to get course users`);
        }
      });
  }, [dispatch, end, selectedUser, start]);

  // useEffect(() => {
  //   if (!start || !end) return;
  //   httpCallables
  //     .generateCourseActivityReport({
  //       course_id: Number(course_id),
  //       start,
  //       end
  //     })
  //     .then(({ data }) => {
  //       console.log(data);
  //       if (data.success) {
  //         const { users, actions } = data.payload;
  //         const timezonedActions = actions.map(action => {
  //           const timeStamp = action.created_at;
  //           const epochTime = fromUnixTime(timeStamp._seconds);
  //           return {
  //             ...action,
  //             created_at: utcToZonedTime(epochTime, course.timezone)
  //           };
  //         });

  //         setUsers(users);
  //         setActions(timezonedActions);
  //       } else {
  //         // TODO: Capture error
  //       }
  //     });
  // }, [course.timezone, course_id, end, start]);

  function handleChange(e) {
    history.push(
      `/admin/activityReport?course_id=${course_id}&user_id=${e.target.value}`
    );
  }

  return (
    <ScrollBox>
      <Box className={classes.container}>
        <Grid
          component={"section"}
          className={classes.header}
          container
          spacing={3}
          alignItems={"center"}
        >
            <Grid item xs={2}>
              <DatePicker
                className={classes.datePicker}
                disableToolbar
                variant="inline"
                inputFormat="MM/dd/yyyy"
                margin="normal"
                id="date-picker-inline"
                label="Start date"
                value={start}
                onChange={e => setStart(e)}
                renderInput={(params) => <TextField variant="standard" {...params} />}
              />
            </Grid>
            <Grid item xs={2}>
              <DatePicker
                className={classes.datePicker}
                disableToolbar
                variant="inline"
                inputFormat="MM/dd/yyyy"
                margin="normal"
                id="date-picker-inline"
                label="End date"
                value={end}
                onChange={e => setEnd(e)}
                renderInput={(params) => <TextField variant="standard" {...params} />}
              />
            </Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={4}>
              <Autocomplete
                options={users}
                onChange={(e, user) => setSelectedUser(user.course_user)}
                getOptionLabel={option => option.course_user}
                id="user"
                renderInput={(params) => (
                  <TextField variant="standard" {...params} label="User" margin="normal" />
                )}
              />
            </Grid>
            <Grid item xs={3}></Grid>
         </Grid>
        <Box component={"section"}>
          <Box className={classes.dailyMetadata}>
            <Typography
              className={classes.date}
              variant="body1"
              color="secondary"
            >
              18 Aug
            </Typography>
            <Typography
              className={classes.metadata}
              variant="body1"
              color="secondary"
            >
              <Box component={"span"}>2 Logins</Box>
              <Box component={"span"}>1 Refresh</Box>
              <Box component={"span"}> Time spent: 3h 25m</Box>
            </Typography>
          </Box>
          <Typography className={classes.tableTitle} variant="h6">
            Tasks
          </Typography>
          <Table className={classes.table} aria-label="activity report table">
            <TableHead className={classes.tableHead}>
              <TableRow>
                <TableCell
                  className={clsx(classes.tableCell, classes.tableHeadCell)}
                >
                  Standard task
                </TableCell>
                <TableCell
                  className={clsx(classes.tableCell, classes.tableHeadCell)}
                >
                  Question
                </TableCell>
                <TableCell
                  className={clsx(classes.tableCell, classes.tableHeadCell)}
                >
                  Citations
                </TableCell>
                <TableCell
                  className={clsx(classes.tableCell, classes.tableHeadCell)}
                >
                  Deleted citations
                </TableCell>
                <TableCell
                  className={clsx(classes.tableCell, classes.tableHeadCell)}
                >
                  Show in text
                </TableCell>
                <TableCell
                  className={clsx(classes.tableCell, classes.tableHeadCell)}
                >
                  Card view
                </TableCell>
                <TableCell
                  className={clsx(classes.tableCell, classes.tableHeadCell)}
                >
                  Wordcount
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell
                  className={clsx(classes.tableCell)}
                  component="th"
                  scope="row"
                >
                  Truth and Lying
                </TableCell>
                <TableCell className={classes.tableCell}>
                  What does Nietzsche say about anthrop...
                </TableCell>
                <TableCell className={classes.tableCell}>5</TableCell>
                <TableCell className={classes.tableCell}>1</TableCell>
                <TableCell className={classes.tableCell}>4</TableCell>
                <TableCell className={classes.tableCell}>2</TableCell>
                <TableCell className={classes.tableCell}>123</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Box>
        {/* </TableContainer> */}
        <Grid container alignItems={"center"}>
          {!isUserActivityReport ? (
            <Typography variant="h4">
              Course activity report for course {course_id}
            </Typography>
          ) : (
            <Typography variant="h4">
              <Tooltip title="Back to course" placement="left" arrow>
                <IconButton
                  className={classes.navigateBack}
                  onClick={() => {
                    history.push(
                      `/admin/CourseActivityReport?course_id=${course_id}`
                    );
                  }}
                  size="large">
                  <ArrowBackIcon fontSize="large" />
                </IconButton>
              </Tooltip>
              User activity report for user {user_id.substring(0, 6)}
            </Typography>
          )}
        </Grid>
        {!isUserActivityReport ? (
          <CourseActivityReport actions={actions} />
        ) : (
          <UserActivityReport actions={actions} />
        )}
      </Box>
    </ScrollBox>
  );
}
