import { firestore, firebaseApp, auth, httpCallables } from "../firebase";
import store from "../redux/store";
import { captureException } from "../utils/errorHandlers";

const textsApi = {
  deleteCourseText: async function (data) {
    try {
      await httpCallables.deleteCourseText(data);
      return true;
    } catch (err) {
      captureException(
        err,
        `Failed to delete course text`
      );
      return false;
    }
  }
};

export default textsApi;
