import React, { useRef, useEffect, useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { useIntl, FormattedMessage } from "react-intl";
import {
  CompositeDecorator,
  convertToRaw,
  convertFromRaw,
  Editor,
  EditorState,
  ContentState,
  RichUtils,
  SelectionState
} from "draft-js";
import "draft-js/dist/Draft.css";
import { useSelector, useDispatch } from "react-redux";
import { resetLink, setSelection, setCurrentLink } from "../../redux/LinkSlice";
import { selectCurrentText } from "../../redux/textsSlice";
import { getHighlightColor } from "../../utils/colors";
import CitationDialog from "../Tasks/CreateTask/CitationDialog";
import makeStyles from '@mui/styles/makeStyles';
import { Box, Typography, Button } from "@mui/material";
import { selectTextDirection } from "../../redux/firestoreSelectors";

const useStyles = makeStyles(theme => ({
  richEditorContainer: {
    height: "100%",
    display: "flex",
    flexDirection: "column"
  },
  textArea: {
    height: "100%",
    width: "100%",
    padding: theme.spacing(3),
    overflow: "auto",
    fontFamily: '"Open Sans Hebrew" ,"Crimson Pro" ,"Frank Ruhl Libre" '
  },
  textAreaDark: {
    background: "#424242"
  },
  footer: {
    width: "100%",
    height: theme.spacing(6),
    paddingInline: theme.spacing(3),
    border: "none",
    borderTop: "1px solid",
    borderColor: theme.palette.grey.main,
    flexShrink: 0,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center"
  },
  wordCounter: {
    fontFamily: "Chivo",
    fontSize: "12px",
    color: "#787877",
    fontWeight: 300
  }
}));

export function findLinkEntities(contentBlock, callback, contentState) {
  contentBlock.findEntityRanges(character => {
    const entityKey = character.getEntity();
    return (
      entityKey !== null &&
      contentState.getEntity(entityKey).getType() === "LINK"
    );
  }, callback);
}

export const Link = props => {
  const selectedText = useSelector(selectCurrentText);
  const darkMode = useSelector(state => state.user.userProfile.darkMode);

  const [openDialog, setOpenDialog] = useState(false);

  const { type, cfis, range } = props.contentState
    .getEntity(props.entityKey)
    .getData();

  const rendeHighlightsDialog = () => {
    return (
      <CitationDialog
        setOpenDialog={setOpenDialog}
        url={selectedText.url}
        location={selectedText.file_location}
        highlights={cfis.cfis.map((cfi, i) => {
          let hlColor = cfi.color ? cfi.color : "#04E3FD";
          return {
            cfi: cfi && cfi.cfi ? cfi.cfi : cfi,
            color: getHighlightColor(hlColor, darkMode)
          };
        })}
        openDialog={openDialog}
        citationTitleFormattedMessage={
          <FormattedMessage id="text.mark" defaultMessage="Mark in Text" />
        }
      />
    );
  };

  const dispatch = useDispatch();

  return (
    <Typography
      component="span"
      onClick={() => {
        if (type === "text") {
          selectedText && setOpenDialog(true);
        }
        if (type === "editor") {
          dispatch(
            setCurrentLink(
              props.contentState.getEntity(props.entityKey).getData()
            )
          );
        }
        if (type === "answer") {
          let element = document.getElementById("answer-text");
          if (element) {
            var sel = document.createRange();
            sel.setStart(element.firstChild, range.start);
            sel.setEnd(element.firstChild, range.end);
            if (window.getSelection().empty) {
              // Chrome
              window.getSelection().empty();
            } else if (window.getSelection().removeAllRanges) {
              // Firefox
              window.getSelection().removeAllRanges();
            }

            window.getSelection().addRange(sel);
          }
        }
      }}
      style={{
        color: "#168FEE",
        textDecoration: "underline",
        cursor: "pointer",
        fontFamily: "revert",
        fontSize: "revert"
      }}
    >
      {props.children}
      {openDialog && rendeHighlightsDialog()}
    </Typography>
  );
};

function getWordCount(editorState) {
  const plainText = editorState.getCurrentContent().getPlainText("");
  const regex = /(?:\r\n|\r|\n)/g; // new line, carriage return, line feed
  const cleanString = plainText.replace(regex, " ").trim(); // replace above characters w/ space
  const wordArray = cleanString.match(/\S+/g); // matches words according to whitespace
  return wordArray ? wordArray.length : 0;
}

export default function RichTextEditor({
  onChange,
  comment,
  placeholder,
  text,
  index,
  readOnly,
  subcribeToUnload,
  componentName,
  hasContent,
  hasContentOnBlur,
  hasContentOnChange,
  editorRef, //Ref passed from the parent and points to the editor, can be use to manage focus
  returnType = "raw",
  showWordCount = false,
  className,
  actionButtonText,
  actionButtonCallBack,
  ...props
}) {
  // Decorator variable is being used by editorState so it was to be up here
  const decorator = new CompositeDecorator([
    { strategy: findLinkEntities, component: Link }
  ]);

  //Hooks
  const intl = useIntl();
  const classes = useStyles();
  const dispatch = useDispatch();
  const commentRef = useRef();
  // Redux state
  const textDirection = useSelector(state => selectTextDirection(state));
  const selected = useSelector(state => state.link.selected);
  const selectedContext = useSelector(state => state.link.selectionContext);
  const linkType = useSelector(state => state.link.linkType);
  const linkTarget = useSelector(state => state.link.linkTarget);
  const currentLink = useSelector(state => state.link.currentLink);
  const linkContent = useSelector(state => {
    return state.link.linkContent;
  });
  const debounce = useRef(null);

  function debouncedOnChange(output) {
    if (!onChange) return;
    clearTimeout(debounce.current);
    debounce.current = setTimeout(() => {
      onChange(output);
    }, 1000);
  }

  // Ephemeral state
  const [linkAdded, setLinkAdded] = useState(false);
  const [editorState, setEditorState] = useState(
    EditorState.createEmpty(decorator)
  );

  useEffect(() => {
    if (!comment && !text) {
      setEditorState(EditorState.createEmpty(decorator));
    } else if (comment) {
      const currentSelection = editorState.getSelection();
      const newContentState = convertFromRaw(comment);
      const updatedEditorState = EditorState.push(editorState, newContentState);
      const updatedEditorStateWithSelection = EditorState.acceptSelection(
        updatedEditorState,
        currentSelection
      );

      if (editorState !== updatedEditorState)
        setEditorState(updatedEditorStateWithSelection);
      // TODO: we should not support text. Remove this
    } else if (text) {
      const currentSelection = editorState.getSelection();
      const newContentState = ContentState.createFromText(text);
      const updatedEditorState = EditorState.push(editorState, newContentState);
      const updatedEditorStateWithSelection = EditorState.acceptSelection(
        updatedEditorState,
        currentSelection
      );
      if (editorState !== updatedEditorState)
        setEditorState(updatedEditorStateWithSelection);
    }
  }, [comment, text]);

  // Variables
  const words = intl.formatMessage({ id: "words", defaultMessage: "words" });

  const additionalProps = {};
  if (
    textDirection === "rtl" &&
    !editorState.getCurrentContent().getPlainText("").length
  ) {
    additionalProps.textDirectionality = "RTL";
  }

  const componentContext = "feedbackComment" + index;

  // Behavior

  useEffect(() => {
    const rawState = convertToRaw(editorState.getCurrentContent());
    hasContent && hasContent(!isEmptyDraftJs(rawState));
  }, []);

  React.useEffect(() => {
    if (currentLink && currentLink.name === componentName) {
      const updateSelection = new SelectionState({
        anchorKey: currentLink.selection.anchorKey,
        startOffset: currentLink.selection.startOffset,
        endOffset: currentLink.selection.endOffset
      });
      let newEditorState = EditorState.acceptSelection(
        editorState,
        updateSelection
      );

      setEditorState(newEditorState);
      dispatch(setCurrentLink(false));
    }
  }, [currentLink, dispatch, componentName, editorState]);

  React.useEffect(() => {
    if (
      !readOnly &&
      selected &&
      componentContext === selectedContext &&
      linkType &&
      linkContent &&
      editorState
    ) {
      const contentState = editorState.getCurrentContent();
      let linkObjContent = {};
      if (linkTarget === "answer") {
        linkObjContent = { type: "answer", range: linkContent };
      } else if (linkTarget === "editor") {
        linkObjContent = {
          type: "editor",
          selection: linkContent.selection,
          name: linkContent.name
        };
      } else {
        if (linkContent && linkContent.cfis) {
          linkObjContent = { cfis: linkContent, type: "text" };
        }
      }
      if (linkObjContent && linkObjContent.type) {
        const contentStateWithEntity = contentState.createEntity(
          "LINK",
          "MUTABLE",
          linkObjContent
        );
        const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

        // Apply entity
        let nextEditorState = EditorState.set(editorState, {
          currentContent: contentStateWithEntity
        });

        // Apply selection
        nextEditorState = RichUtils.toggleLink(
          nextEditorState,
          nextEditorState.getSelection(),
          entityKey
        );

        setEditorState(nextEditorState);
        setLinkAdded(true);
      }
    }
  }, [
    selected,
    selectedContext,
    linkType,
    linkTarget,
    linkContent,
    componentContext,
    commentRef,
    editorState,
    readOnly
  ]);

  useEffect(() => {
    if (linkAdded) {
      setLinkAdded(false);
      dispatch(resetLink());
      commentRef &&
        commentRef.current &&
        commentRef.current.focus &&
        commentRef.current.focus();
    }
  }, [dispatch, linkAdded, setLinkAdded]);

  useEffect(() => {
    if (commentRef.current) {
      commentRef.current
        .getElementsByTagName("textarea")[0]
        .setAttribute("dir", "auto");
    }
  }, []);

  useEffect(() => {
    const rawState = convertToRaw(editorState.getCurrentContent());
    hasContentOnChange && hasContentOnChange(!isEmptyDraftJs(rawState));
  }, [hasContentOnChange, editorState]);

  function focus(editorState) {
    return EditorState.moveFocusToEnd(editorState);
  }

  function handleBlur() {
    const output = getPlainTextAndRichText(editorState);
    onChange && onChange(output);
  }

  function handleChange(newState) {
    //The onChange event fires on both content and selection change
    const currentContent = editorState.getCurrentContent();
    const newContent = newState.getCurrentContent();

    const newSelectionState = newState.getSelection();

    if (!readOnly && selected !== !newSelectionState.isCollapsed()) {
      dispatch(
        setSelection({
          selected: !newSelectionState.isCollapsed(),
          selectionContext: componentContext
        })
      );
    }

    const output = getPlainTextAndRichText(newState);

    if (currentContent !== newContent) debouncedOnChange(output);

    setEditorState(newState);
  }

  function handleClick() {
    if (!actionButtonCallBack) return;
    const output = getPlainTextAndRichText(editorState);
    actionButtonCallBack(output);
  }

  function getPlainTextAndRichText(state) {
    const richText = convertToRaw(state.getCurrentContent());
    const plainText = state.getCurrentContent().getPlainText(" ");
    return { plainText, richText };
  }

  function isEmptyDraftJs(rawState) {
    if (
      !rawState ||
      (Object.keys(rawState).length === 0 &&
        Object.getPrototypeOf(rawState) === Object.prototype)
    ) {
      // filter undefined and {}
      return true;
    }
    const contentState =
      returnType === "raw" ? convertFromRaw(rawState) : rawState;
    return !(
      (returnType === "raw" &&
        contentState.hasText() &&
        contentState.getPlainText() !== "") ||
      contentState !== ""
    );
  }

  return (
    <Box
      className={clsx(classes.richEditorContainer, className)}
      data-test-class="rich-text-editor"
    >
      <Box className={classes.textArea} aria-label="text-editor-input">
        <Editor
          readOnly={readOnly}
          spellCheck={!readOnly}
          onMouseUp={() => {}}
          {...additionalProps}
          placeholder={placeholder}
          editorState={editorState || ""}
          ref={(commentRef, editorRef)}
          onChange={!readOnly && handleChange}
          onBlur={!readOnly && handleBlur}
        />
      </Box>
      {showWordCount && (
        <Box className={classes.footer}>
          <Box className={clsx(classes.wordCounter)}>
            {getWordCount(editorState)}
            {props.wordLimit > 0 && `/ ${props.wordLimit}`}
            {` ${words}`}
          </Box>
          {actionButtonCallBack && (
            <Box>
              <Button color="primary" size="small" onClick={handleClick}>
                {actionButtonText}
              </Button>
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
}

RichTextEditor.propTypes = {
  text: PropTypes.string
};
