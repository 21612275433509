import { createSlice, createSelector, current } from "@reduxjs/toolkit";
import { merge, find } from "lodash";
import { shallowEqual } from "react-redux";
const initialState = {
  highlights: [],
  underlines: [],
  clientRectangle: {},
  selectedText: {},
  selectedHighlight: "",
  minimal: false,
  isClickOnMark: false,
  isAnnotatorBarOpen: false
};

export const highlightSlice = createSlice({
  name: "highlight",
  initialState,
  extraReducers: {
    gotHighlights: (state, value) => {
      state.highlights = value.payload.coll;
      state.selectedText = value.payload.text_id;
    },
    "user/logout": () => initialState
  },
  reducers: {
    // TODO: having both toggleAnnotatorBar and close and open seems redundent
    toggleAnnotatorBar: (state, value) => {
      if (state.isAnnotatorBarOpen === false) {
        const { clientRectangle, selectedText, isClickOnMark, minimal } =
          value.payload;

        state.clientRectangle = clientRectangle;
        state.isAnnotatorBarOpen = true;
        state.minimal = minimal;
        state.selectedText = selectedText;
        state.isClickOnMark = isClickOnMark;
      } else {
        state.isAnnotatorBarOpen = false;
        state.clientRectangle = {};
        state.selectedText = {};
        // state.selectedHighlight = {};
      }
    },

    closeAnnotatorBar: state => {
      state.isAnnotatorBarOpen = false;
      state.clientRectangle = {};
      state.selectedText = {};
      // TODO: closeAnnotatorBar should enpty the selectedHighlight...
      //  ... this require a refactor of the comments which relay on...
      // ... the highlight id being in persistent in redux
      // state.selectedHighlight = "";
    },
    openAnnotatorBar: (state, value) => {
      //TODO: 04/01/22 this action should only open the annotation bar, I created a new action for setting the highlight info. Need to go over the app refactore
      state.isAnnotatorBarOpen = true;
      // const { clientRectangle, selectedText, isClickOnMark, minimal } = value.payload;
      // state.clientRectangle = clientRectangle;
      // state.minimal = minimal;
      // state.selectedText = selectedText;
      // state.isClickOnMark = isClickOnMark;
    },

    setSelectedHighlight: (state, value) => {
      const { clientRectangle, selectedHighlight, isClickOnMark, minimal } =
        value.payload;

      state.clientRectangle = clientRectangle;
      state.minimal = minimal;
      state.selectedHighlight = selectedHighlight;
      state.isClickOnMark = isClickOnMark;
    },

    removeHighlight: (state, value) => {
      let newHighlights = current(state.highlights).filter(el => {
        return (
          el.cfi !== value.payload.cfi || el.text_id !== value.payload.text_id
        );
      });
      state.highlights = newHighlights;
    },
    setHighlightColor: (state, value) => {
      let highlightsFiltered = current(state.highlights).filter(el => {
        return (
          el.cfi !== value.payload.cfi || el.text_id !== value.payload.text_id
        );
      });
      highlightsFiltered.push(value.payload);
      state.highlights = highlightsFiltered;
    }
  }
});

export const {
  toggleAnnotatorBar,
  closeAnnotatorBar,
  openAnnotatorBar,
  setSelectedHighlight,
  onBarShown,
  onBarClosed
} = highlightSlice.actions;

export default highlightSlice.reducer;

export const selectSelectedHighlight = createSelector(
  [
    state => state.interactions.highlights,
    state => state.realtimeInteractions.containers,
    state => state.highlighter.selectedHighlight
  ],
  (interactions, realTimeinteractions, id) => {
    const allHighlights = merge(interactions, realTimeinteractions);
    let highlight_id = id;
    return find(allHighlights, { id: highlight_id } || {});
  },
  { memoizeOptions: { resultEqualityCheck: shallowEqual } }
);
