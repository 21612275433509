// Dependencies
import React from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import clsx from "clsx";
// Redux dependencies
import { useSelector, useDispatch } from "react-redux";
import { setLinkType, setLinkContent } from "../../../redux/LinkSlice";
import { selectSubmissionQuestions } from "../../../redux/interactionsSlice";

// Components
import OpenAnswerLinked from "./OpenAnswerLinked";
import ConceptChip from "../ConceptChip";

import makeStyles from "@mui/styles/makeStyles";
import FeedbackQuestionContainer from "./FeedbackQuestionContainer";
import {
  Paper,
  Box,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
  Chip
} from "@mui/material";

//Styles
const useStyles = makeStyles(theme => ({
  right: {
    textAlign: "right"
  },
  correct: {
    textTransform: "none",
    position: "absolute",
    right: theme.spacing(1),
    color: "white",
    backgroundColor: "#0eac72",
    "& .MuiChip-label": {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1)
    }
  },
  correctInfo: {
    position: "absolute",
    right: theme.spacing(1),
    color: "#0eac72",
    border: "1px solid #0eac72"
  },
  incorrect: {
    position: "absolute",
    right: theme.spacing(1),
    backgroundColor: "#f9413e"
  },
  container: {
    overflow: "auto",
    height: "100%",
    paddingTop: theme.spacing(5),
    display: "grid",
    gridTemplateColumns: "repeat(10,1fr)"
  },
  findFeedback: {
    gridColumn: " 1 / 9 ",
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5)
  },
  feedbackSide: {
    gridColumn: " 7 / 11 ",
    paddingRight: theme.spacing(4)
  },
  paperContainer: {
    gridColumn: " 1 / 7 ",
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    textAlign: "left"
  },
  conceptContainer: {
    marginBotton: theme.spacing(1),
    "& .MuiChip-root": {
      height: theme.spacing(3),
      "&:first-child": {
        marginLeft: "0"
      }
    }
  },
  paper: {
    padding: theme.spacing(2),
    gridColumn: " 2 / 11 ",
    height: "max-content",
    position: "relative",
    minHeight: "260px"
  },
  answerContainer: {
    fontSize: "20px",
    fontFamily: '"Crimson Pro" ,"Frank Ruhl Libre"'
  }
}));

function FeedbackScreen({
  task,
  isTeacher,
  showMatch,
  conceptMapping,
  highlightMatch
}) {
  // Hooks
  const classes = useStyles();
  const dispatch = useDispatch();
  const intl = useIntl();

  // Redux state
  const linkTarget = useSelector(state => state.link.linkTarget);
  const selected = useSelector(state => state.link.selected);
  const selectedQuestionIndex = useSelector(
    state => state.task.selectedQuestionDisplayIndex
  );
  const answers = useSelector(state => state.interactions.answers);
  const questions = useSelector(selectSubmissionQuestions);

  const question = questions[selectedQuestionIndex] || {};
  const answer =
    answers.find(answer => answer.interaction_id === question.id) || {};

  // Behavior
  const renderMultiAnswer = () => {
    return (
      <Box className={classes.paperContainer}>
        <Paper className={classes.paper}>
          <Box className={classes.answerContainer}>
            <Typography>Choose the correct answer</Typography>
            <RadioGroup name="answer" value={answer.choice}>
              {question.options.map((opt, index) => {
                return (
                  <Box className={classes.choiceContainer} key={index}>
                    <FormControlLabel
                      className={clsx(classes.label, classes.multiChoice)}
                      value={index}
                      key={index}
                      control={
                        <Radio
                          className={clsx(classes.radio)}
                          checked={index === parseInt(answer.choice)}
                        />
                      }
                      label={opt}
                    />
                    {index === parseInt(answer.choice) &&
                      parseInt(question.shouldSelect) === index && (
                        <Chip
                          className={classes.correct}
                          label={intl.formatMessage({
                            id: "task.multi.correct",
                            defaultMessage: "Correct"
                          })}
                        />
                      )}
                    {index !== parseInt(answer.choice) &&
                      parseInt(question.shouldSelect) === index && (
                        <Chip
                          className={classes.correctInfo}
                          label={intl.formatMessage({
                            id: "task.multi.correct",
                            defaultMessage: "Correct"
                          })}
                        />
                      )}

                    {index === parseInt(answer.choice) &&
                      parseInt(question.shouldSelect) !== index && (
                        <Chip
                          className={classes.incorrect}
                          label={intl.formatMessage({
                            id: "task.multi.incorrect",
                            defaultMessage: "Incorrect"
                          })}
                        />
                      )}
                  </Box>
                );
              })}
            </RadioGroup>
          </Box>
        </Paper>
      </Box>
    );
  };

  function renderOpenAnswer() {
    return (
      <Box className={classes.paperContainer}>
        <Box>
          <Paper className={classes.paper}>
            {isTeacher && conceptMapping && conceptMapping.length > 0 && (
              <Box className={classes.conceptContainer}>
                {conceptMapping.map((concept, i) => {
                  return (
                    <ConceptChip
                      key={i}
                      found={concept.found}
                      concept={concept.concept}
                    />
                  );
                })}
              </Box>
            )}
            <Box className={classes.answerContainer}>
              {answer.content && (
                <OpenAnswerLinked
                  wordLimit={question?.wordLimit}
                  showWordCount={true}
                  key={selectedQuestionIndex}
                  componentName={"answer" + selectedQuestionIndex}
                  readOnly={true}
                  selectionEvent={selection => {
                    if (linkTarget === "editor" && selected) {
                      dispatch(setLinkType("editor"));
                      dispatch(
                        setLinkContent({
                          selection: selection,
                          name: "answer" + selectedQuestionIndex
                        })
                      );
                    }
                  }}
                  index={selectedQuestionIndex}
                  text={answer.content} // answser.summary is for gr tasks
                  comment={answer.rich_text}
                />
              )}
            </Box>
          </Paper>
        </Box>
      </Box>
    );
  }

  // Render
  return (
    <Box className={classes.container}>
      {question &&
        ["OPEN_ENDED", "GUIDED_READING"].includes(
          question.interaction_subtype
        ) &&
        renderOpenAnswer(answer)}
      {question &&
        question.interaction_subtype === "MULTI_CHOICE" &&
        renderMultiAnswer()}
      <Box
        className={clsx(classes.feedbackSide, {
          [classes.findFeedback]:
            question && question.interaction_subtype === "FIND_IN_TEXT"
        })}
      >
        <FeedbackQuestionContainer
          index={selectedQuestionIndex}
          task={task}
          showMatch={showMatch}
          question={question}
          conceptMapping={conceptMapping}
          highlightMatch={highlightMatch}
        />
        <Box className={clsx(classes.action, classes.right)}></Box>
      </Box>
    </Box>
  );
}

FeedbackScreen.propTypes = {
  task: PropTypes.shape({
    task_type: PropTypes.string.isRequired
  })
};

export default FeedbackScreen;
