// Dependencies
import React, { useEffect } from "react";
import { httpCallables, firebaseApp } from "../../../firebase";
import { useIntl } from "react-intl";
import { useQuery } from "../../../hooks";

// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  selectCourse,
  selectCourseByTextId
} from "../../../redux/coursesSlice";
import {
  selectTask,
  setTasks,
  setSubmissions
} from "../../../redux/tasksSlice";
import { setBreadcrumbs } from "../../../redux/readerActionsSlice";
import {
  setInteractions,
  selectQuestions
} from "../../../redux/interactionsSlice";
import {
  setTextUrl,
  selectCurrentText,
  setSelectedTextId
} from "../../../redux/textsSlice";

//Components
import PublishedTaskView from "./PublishedTaskView";
import ScrollBox from "../../SharedComponents/ScrollBox";

import makeStyles from "@mui/styles/makeStyles";
import { Box } from "@mui/material";

//Styles
const useStyles = makeStyles(theme => {
  return {
    container: {
      flex: 1,
      height: "100%",
      width: "83.333333%", // 10 culumns on a 12 column grid
      paddingInline: 40,
      alignItems: "center",
      position: "relative",

      [theme.breakpoints.up("desktop")]: {
        maxWidth: 840,
        marginInline: "auto"
      }
    }
  };
});

export default function PublishedTaskContainer() {
  const dispatch = useDispatch();
  const intl = useIntl();
  const classes = useStyles();
  const { course_id, task_id } = useQuery();

  // const courses = useSelector(state => state.courses.courses);
  const course = useSelector(selectCourseByTextId);
  const task = useSelector(state => selectTask(state, Number(task_id)));
  const questions = useSelector(state =>
    selectQuestions(state, Number(task_id))
  );
  const selectedText = useSelector(selectCurrentText);

  useEffect(() => {
    httpCallables
      .readTaskDraft({ task_id: Number(task_id) })
      .then(({ data }) => {
        const { task, submissions, interactions } = JSON.parse(data);
        dispatch(setTasks([task]));
        dispatch(setSubmissions(submissions));
        dispatch(setInteractions(interactions));
      });
  }, [task_id, course_id, dispatch, task.id]);

  useEffect(() => {
    let parts = [];
    parts.push({
      text: intl.formatMessage({
        id: "appBar.tasks",
        defaultMessage: "Tasks"
      }),
      url: "/tasks",
      resetCourse: true
    });

    course.id &&
      parts.push({ text: course.name, url: `/tasks?course_id=${course.id}` });

    task.name &&
      course.id &&
      parts.push({
        text: task.name,
        url: `/tasks?course_id=${course.id}&task_id=${task.id}`
      });

    dispatch(
      setBreadcrumbs({ breadcrumbs: parts, blue: true, showTextMenu: false })
    );
  }, [course.id, course.name, dispatch, intl, task.id, task.name]);

  useEffect(() => {
    if (!selectedText.id) return;
    dispatch(setSelectedTextId(selectedText.id));
    firebaseApp
      .storage()
      .ref("courseTexts/" + selectedText.course_id)
      .child(selectedText.file_url)
      .getDownloadURL()
      .then(url => {
        dispatch(setTextUrl({ url, text_id: selectedText.id }));
      });
  }, [selectedText.id]); // eslint-disable-line

  if (task && course) {
    return (
      <ScrollBox alignItems="center">
        <Box className={classes.container}>
          <PublishedTaskView
            course={course}
            task={task}
            interactions={questions}
          />
        </Box>
      </ScrollBox>
    );
  } else return null;
}
