// Dependencies
import React from "react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import clsx from "clsx";

// Redux dependencies
import { useSelector, useDispatch } from "react-redux";
import { setSelectedInteractionId } from "../../../redux/interactionsSlice";
import { setSelectedQuestionDisplayIndex } from "../../../redux/taskSlice.js";

// Components
import FeedbackQuestionPanel from "./FeedbackQuestionPanel";
import QuestionsCarousel from "../QuestionsCarousel";

import makeStyles from '@mui/styles/makeStyles';
import { Button, Paper, Box, Typography, Divider } from "@mui/material";

// Styles
const useStyles = makeStyles(() => ({
  actionDetailsSidebar: {
    position: "relative",
    background: "#333333"
  },

  actionDetailsSidebarContainer: {
    width: "25%",
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#333333",
    overflowY: "auto",
    zIndex: "140",
    transition: "top 0.6s"
  },
  buttonContainer: {
    textAlign: "right"
  },
  taskBtn: {
    marginTop: "16px",
    marginBottom: "16px",
    fontSize: "13px",
    fontWeight: "bold",
    letterSpacing: "1px",
    color: "#ffffff",
    backgroundColor: "#168fee",
    "&:hover": {
      backgroundColor: "#1172be"
    }
  },
  panelHeader: {
    color: "white",
    width: "100%",
    paddingLeft: "16px",
    paddingRight: "16px",
    textAlign: "left",
    position: "relative"
  },
  divider: {
    marginTop: "8px",
    backgroundColor: "#616161",
    marginBottom: "8px"
  },
  panelSubtitle: {
    color: "white"
  },
  root: {
    backgroundColor: "#333333"
  },
  panelTaskTitle: {
    fontSize: "20px",
    textTransform: "none"
  },
  panelTaskDesc: {
    fontSize: "16px",
    whiteSpace: "pre-wrap"
  },
  panelTitle: {
    color: "#bdbdbd",
    fontSize: "12px",
    fontWeight: "300",
    fontStyle: "normal",
    letterSpacing: "1px",
    lineHeight: "24px",
    marginTop: "8px",
    textTransform: "uppercase"
  }
}));

function TaskFeedbackSidebar({
  editable,
  task,
  onQuestionIndex,
  isTeacher,
  showMatch,
  questions,
  highlights,
  validateFeedback,
  highlightMatch
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const selectedQuestionIndex = useSelector(
    state => state.task.selectedQuestionDisplayIndex
  );

  return (
    <>
      <Box
        elevation={2}
        className={clsx(
          classes.actionDetailsSidebarContainer,
          classes.actionDetailsSidebarContainerLtr
        )}
      >
        <Paper
          square={true}
          elevation={0}
          className={classes.actionDetailsSidebar}
        >
          <Paper className={classes.root} elevation={0}>
            <Box
              className={clsx(
                classes.panelHeader,
                classes.panelHeaderLtr,
                classes.left
              )}
            >
              <Typography
                className={clsx(classes.panelTitle, classes.left)}
                color="textPrimary"
              >
                <FormattedMessage id="appBar.tasks" defaultMessage="tasks" />
              </Typography>
              <Typography
                className={clsx(
                  classes.panelTaskTitle,
                  classes.panelSubtitle,
                  classes.left
                )}
                color="textPrimary"
              >
                {task && task.name}
              </Typography>

              <Typography
                className={clsx(
                  classes.panelTaskDesc,
                  classes.panelSubtitle,
                  classes.left
                )}
                color="textPrimary"
              >
                {task && task.task_description}
              </Typography>
              {task && selectedQuestionIndex < questions.length - 1 && (
                <Box component="div" className={classes.buttonContainer}>
                  <Button
                    className={classes.taskBtn}
                    onClick={() => {
                      dispatch(
                        setSelectedInteractionId(
                          questions[selectedQuestionIndex + 1].id
                        )
                      );
                      dispatch(
                        setSelectedQuestionDisplayIndex(
                          selectedQuestionIndex + 1
                        )
                      );
                    }}
                  >
                    <FormattedMessage
                      id="gr.next.question"
                      defaultMessage="Next question"
                    />
                  </Button>
                </Box>
              )}
              {editable && selectedQuestionIndex === questions.length - 1 && (
                <Box component="div" className={classes.buttonContainer}>
                  <Button
                    onClick={validateFeedback}
                    className={classes.taskBtn}
                  >
                    <FormattedMessage
                      id="task.feedback.submit"
                      defaultMessage="Send feedback"
                    />
                  </Button>
                </Box>
              )}
            </Box>
            <Divider className={classes.divider} />
            {questions && (
              <QuestionsCarousel
                questionsOrder={questions}
                updateTaskFeedbackState={selectedQuestionIndex => {
                  // this code is nessary to support the taskfeedback variation of the data becaouse it was not implemented as part of firesotre middlewere.
                  onQuestionIndex(selectedQuestionIndex);
                }}
              />
            )}

            {questions.map((question, index) => (
              <FeedbackQuestionPanel
                key={question.id}
                showMatch={showMatch}
                isTeacher={isTeacher}
                score={highlightMatch * 100}
                value={selectedQuestionIndex}
                highlights={highlights}
                question={question}
                index={index}
              />
            ))}
          </Paper>
        </Paper>
      </Box>
    </>
  );
}

TaskFeedbackSidebar.propTypes = {
  task: PropTypes.shape({
    questions: PropTypes.object
  })
};

export default TaskFeedbackSidebar;
