import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import clsx from "clsx";
import PropTypes from "prop-types";
import { motion, useAnimation } from "framer-motion";
import { useDrop } from "react-dnd";
import { makeStyles } from "@mui/styles";
import { Typography } from "@mui/material";
import { TriangleRight, TriangleLeft } from "@styled-icons/octicons";
import { selectTextDirection } from "../../../../redux/firestoreSelectors";

const useStyles = makeStyles(theme => ({
  header: {
    paddingInline: theme.spacing(2),
    paddingBlock: theme.spacing(1.5),
    display: "flex",
    flexWrap: "wrap",
    cursor: "pointer"
  },
  triangleIcon: {
    flex: "15%",
    "& > div": {
      borderRadius: 25,
      transition: ".15s ease-out",
      padding: 3,
      marginBlockStart: -3,
      marginLeftInlineStart: -3
    },
    "& > div:hover": {
      background: "rgba(255, 255, 255, 0.1)"
    }
  },
  selected: {
    "& > div:hover": {
      background: "rgba(0, 0, 0, 0.1)"
    }
  },
  title: {
    flex: "85%"
  },
  subTitle: {
    flex: "85%",
    marginInlineStart: "15%",
    opacity: 0.5
  }
}));

function QuestionsAccordionHeader({
  isSelected,
  id,
  onClick,
  onExpand,
  title,
  subTitle,
  onCardDrop = () => {},
  disableDrop = false,
  forceQuestionSelection = false
}) {
  const classes = useStyles();
  const [isHovered, setHovered] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const controls = useAnimation();
  const textDirection = useSelector(state => selectTextDirection(state));

  const [{ isOver }, drop] = useDrop({
    accept: "QUESTION_ACCORION_CARD",
    collect: monitor => {
      return {
        isOver: monitor.isOver()
      };
    },
    drop: (item, monitor) => {
      onCardDrop(item.cardIndex, item.questionIndex, id);
    },
    canDrop: !disableDrop
  });

  useEffect(() => {
    if (isHovered || isOver) {
      controls.start("hovered");
    } else {
      controls.start(isSelected ? "selected" : "default");
    }
  }, [isHovered, isSelected, isOver]); //eslint-disable-line

  useEffect(() => {
    onExpand(expanded);
  }, [expanded]); //eslint-disable-line

  return (
    <motion.div
      role="button"
      ref={drop}
      className={classes.header}
      initial={false}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      transition={{ duration: 0.1 }}
      data-test-class="standard-task-question-header"
      variants={{
        selected: {
          background: "rgba(144, 202, 249, 1)",
          color: "rgba(0, 0, 0, 0.87)"
        },
        default: {
          background: "rgba(144, 202, 249, 0)",
          color: "#FFF"
        },
        hovered: {
          background: isSelected
            ? "rgba(144, 202, 249, 1)"
            : "rgba(144, 202, 249, 0.1)",
          color: isSelected ? "rgba(0, 0, 0, 0.87)" : "#FFF"
        }
      }}
      animate={controls}
      onClick={() =>
        onClick(forceQuestionSelection ? id : isSelected ? false : id)
      }
    >
      <div
        className={clsx(classes.triangleIcon, {
          [classes.selected]: isSelected
        })}
      >
        <motion.div
          transition={{ type: "spring", damping: 15 }}
          onClick={e => {
            e.stopPropagation();
            setExpanded(!expanded);
          }}
          data-test-class="question-accordion-header-chevron"
          style={{
            display: "inline-block"
          }}
          animate={expanded ? "expanded" : "default"}
          variants={{
            expanded: {
              rotate: textDirection === "rtl" ? -90 : 90
            },
            default: {
              rotate: 0
            }
          }}
        >
          {textDirection === "rtl" ? (
            <TriangleLeft size={24} />
          ) : (
            <TriangleRight size={24} />
          )}
        </motion.div>
      </div>
      <Typography className={classes.title}>{title}</Typography>
      {subTitle && (
        <Typography variant="body2" className={classes.subTitle}>
          {subTitle}
        </Typography>
      )}
    </motion.div>
  );
}

QuestionsAccordionHeader.propTypes = {
  isSelected: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.node,
  id: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  onExpand: PropTypes.func.isRequired,
  onCardDrop: PropTypes.func.isRequired,
  disableDrop: PropTypes.bool,
  forceQuestionSelection: PropTypes.bool
};

export default QuestionsAccordionHeader;
