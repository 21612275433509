import React from "react";
import PropTypes from "prop-types";
import { useDrop } from "react-dnd";
import { Draggable } from "react-beautiful-dnd";
import { motion } from "framer-motion";
import { useSelector } from "react-redux";
import { questionHighlights } from "../../../redux/interactionsSlice";
import { selectCourseByTextId } from "../../../redux/coursesSlice";
import QuestionBox from "../QuestionBox";
import { QuestionQuoteCard } from "../QuestionQuoteCard";
import makeStyles from "@mui/styles/makeStyles";
import { ListItem, Box, Collapse, List } from "@mui/material";
import { interactionsAPI } from "../../../api";
import ToggleTriangle from "../../SharedComponents/ToggleTriangle";
import { isPdfSelector } from "../../../redux/textsSlice";

const useStyles = makeStyles(theme => ({
  spacer: {
    width: theme.spacing(2),
    flexShrink: 0
  },
  questionIcon: {
    display: "inline-flex",
    transition: "0.3s ease-out",
    alignSelf: "flex-start"
  },
  questionText: {
    color: "#fafafa",
    fontSize: "16px"
  },
  selectedQuestion: {
    color: "black"
  },
  questionContainer: {
    padding: 0,
    flexGrow: 1,
    flexFlow: "column nowrap",
    alignItems: "flex-start"
  },
  rotateChevron: {
    transform: "rotate(90deg)"
  }
}));

function Question({
  question,
  index,
  onDelete,
  moveCard,
  locked = false,
  allowQuestionEdit = true,
  color = "secondary",
  toggleOpenQuestion,
  onCardDelete
}) {
  const classes = useStyles();
  const selectedAnswer = useSelector(state => state.gr.selectedAnswer);
  const highlights = useSelector(state =>
    questionHighlights(state, question.id)
  );
  const stage = useSelector(state => state.gr.stage);
  const grMode = useSelector(state => state.gr.mode);
  const openQuestions = useSelector(state => state.gr.openQuestions);
  const selectedTextId = useSelector(state => {
    return state.texts.selectedTextId;
  });
  const isPdf = useSelector(isPdfSelector);
  const selectedCourseId = useSelector(selectCourseByTextId)?.id;

  // Variables
  const ANSWER_ITEM = "GR.Answer";

  const [{ isOver, canDrop }, drop] = useDrop({
    accept: ANSWER_ITEM,
    drop: (item, monitor) => {
      const cfiAlreadyExsist = highlights.find(h => h.cfi === item.item.cfi);
      if (cfiAlreadyExsist) return; // Do not allow a citation to be copied if it already exsists
      interactionsAPI.createHighlight(
        {
          content: item.item.content,
          cfi: item.item.cfi,
          color: item.item.color,
          interaction_id: question.id,
          pdfPosition: isPdf ? item.item.pdfPosition : null
        },
        selectedTextId,
        selectedCourseId
      );
    },
    canDrop: (item, monitor) => {
      let retVal =
        !question.answers ||
        question.answers.filter(el => el.cfi === item.item.cfi).length <= 0;
      return retVal;
    },
    collect: (monitor, collectProps) => {
      let obj = {
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
        targetId: monitor.targetId
      };
      return obj;
    }
  });

  function ExpandCollapseBtn() {
    return (grMode === "full" && [2, 4].includes(stage)) ||
      (grMode === "light" && [1, 2].includes(stage)) ? (
      <ToggleTriangle
        isOpen={openQuestions.includes(question.id)}
        onClick={e => {
          toggleOpenQuestion(question.id);
          e.stopPropagation();
        }}
      />
    ) : (
      <Box className={classes.spacer} />
    );
  }

  const renderCards = (question, index) => {
    return (
      <Collapse
        in={
          question.id === selectedAnswer.questionId ||
          openQuestions.includes(question.id)
        }
        timeout="auto"
        unmountOnExit
      >
        <List>
          {highlights.map((quote, index) => (
            <ListItem key={index}>
              <QuestionQuoteCard
                color={color}
                question={question}
                item={quote}
                index={index}
                onDelete={onCardDelete}
                locked={locked}
              />
            </ListItem>
          ))}
        </List>
      </Collapse>
    );
  };

  return (
    <Box className={classes.questionContainer} key={question.id} ref={drop}>
      <motion.div
        animate={{
          background:
            isOver && canDrop
              ? "rgba(255, 255, 255, 0.1)"
              : "rgba(255, 255, 255, 0)"
        }}
      >
        {allowQuestionEdit ? (
          <Draggable
            key={question.id}
            isDragDisabled={openQuestions.includes(question.id)}
            draggableId={question.id.toString()}
            index={index}
          >
            {(provided, snapshot) => {
              return (
                <Box
                  style={{ width: "100%" }}
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                  tabIndex="-1"
                >
                  <QuestionBox
                    onDelete={onDelete}
                    key={question.id}
                    index={index}
                    question={question}
                    moveCard={moveCard}
                    icon={<ExpandCollapseBtn />}
                    color={color}
                  />
                </Box>
              );
            }}
          </Draggable>
        ) : (
          <QuestionBox
            key={question.id}
            index={index}
            question={question}
            editable={allowQuestionEdit}
            icon={<ExpandCollapseBtn />}
            color={color}
          />
        )}
        {/* TODO: Ofer - think of a way to incapsulate this condition */}
        {((grMode === "full" && [2, 4].includes(stage)) ||
          (grMode === "light" && [1, 2].includes(stage))) &&
          renderCards(question, index)}
      </motion.div>
    </Box>
  );
}

Question.propTypes = {
  question: PropTypes.shape({
    question: PropTypes.string,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    summary: PropTypes.string,
    answers: PropTypes.arrayOf(PropTypes.shape({}))
  }),
  onDelete: PropTypes.func.isRequired,
  locked: PropTypes.bool
};

export default Question;
