// Dependencies
import React, { useState, useEffect } from "react";
import { isAfter, isFuture, isPast } from "date-fns";
import { useIntl } from "react-intl";

// Material UI
import { useTheme } from "@mui/material/styles";
import withStyles from '@mui/styles/withStyles';
import { Box, Typography, Paper, Divider } from "@mui/material";

import {
  PieSeries,
  Chart,
  Title,
  Legend,
  Tooltip
} from "@devexpress/dx-react-chart-material-ui";

import { Animation, Palette, EventTracker } from "@devexpress/dx-react-chart";

// Styles
const styles = theme => ({
  titleText: {
    lineHeight: "1",
    marginBottom: "0",
    padding: "24px 0",
    fontWeight: "bold",
    borderBottom: "1px solid rgba(0, 0, 0, 0.12)"
  },
  legend: {
    display: "flex",
    justifyContent: "space-around"
  },
  item: {
    width: "100%",
    padding: "24px 0",
    borderRight: "1px solid rgba(0, 0, 0, 0.12)",
    "&:last-child": {
      borderRight: "none"
    }
  },
  chart: {
    padding: "0"
  },
  label: {
    fontSize: "16px !important",
    display: "inline-block",
    textAlign: "left",
    verticalAlign: "middle",
    "& span": {
      fontSize: "16px !important"
    }
  },
  legendContainer: {
    width: "100%",
    borderTop: "1px solid rgba(0, 0, 0, 0.12)"
  }
});

const Marker = withStyles(styles)(({ color, ...restProps }) => {
  return (
    <svg
      style={{ verticalAlign: "middle" }}
      fill={color}
      width="24"
      height="24"
      {...restProps}
    >
      <circle r={12} cx={12} cy={12} {...restProps} />
    </svg>
  );
});

const Label = withStyles(styles)(({ classes, ...restProps }) => (
  <Legend.Label {...restProps} className={classes.label} />
));

const TitleText = withStyles(styles)(({ classes, ...restProps }) => (
  <Title.Text {...restProps} className={classes.titleText}>
    <Box component="header">
      <Typography variant="h5">{restProps.text}</Typography>
    </Box>
    <Divider />
  </Title.Text>
));

const ChartRoot = withStyles(styles)(({ classes, ...restProps }) => (
  <Chart.Root className={classes.chart} {...restProps} />
));

const RootWithTitle = withStyles(styles)(({ classes, ...restProps }) => (
  <Box className={classes.legendContainer}>
    <Box className={classes.legend} {...restProps} />
  </Box>
));

const Item = withStyles(styles)(({ classes, ...restProps }) => (
  <Box {...restProps} className={classes.item} />
));

export default function TimeManagmentStats({
  generalStatsHeight,
  submissions
}) {
  //Hooks
  const theme = useTheme();
  const intl = useIntl();

  // Ephemeral State
  const [timeManagement, setTimeManagement] = useState([]);
  //Behavior
  useEffect(() => {
    const stats = submissions.reduce((accumulator, current) => {
      const dueDate = new Date(current.due_date);
      const submissionDate = current.submission_date
        ? new Date(current.submission_date)
        : null;

      if (!submissionDate && isPast(dueDate)) {
        return accumulator.missed
          ? { ...accumulator, missed: accumulator.missed + 1 }
          : { ...accumulator, missed: 1 };
      } else if (!submissionDate && isFuture(dueDate)) {
        return accumulator.pending
          ? { ...accumulator, pending: accumulator.pending + 1 }
          : { ...accumulator, pending: 1 };
      } else if (isAfter(submissionDate, dueDate)) {
        return accumulator.late
          ? { ...accumulator, late: accumulator.late + 1 }
          : { ...accumulator, late: 1 };
      } else {
        return accumulator.onTime
          ? { ...accumulator, onTime: accumulator.onTime + 1 }
          : { ...accumulator, onTime: 1 };
      }
    }, {});

    setTimeManagement([
      {
        cat: intl.formatMessage({
          id: "task.statistics.pending",
          defaultMessage: "Pending"
        }),
        val: stats.pending || 0,
        color: "#D8D8D8"
      },
      {
        cat: intl.formatMessage({
          id: "task.statistics.onTime",
          defaultMessage: "On time"
        }),
        val: stats.onTime || 0,
        color: theme.palette.secondary.main
      },
      {
        cat: intl.formatMessage({
          id: "task.statistics.late",
          defaultMessage: "Late"
        }),
        val: stats.late || 0,
        color: theme.palette.warning.main
      },
      {
        cat: intl.formatMessage({
          id: "task.statistics.missed",
          defaultMessage: "Missed"
        }),
        val: stats.missed || 0,
        color: theme.palette.error.main
      }
    ]);
  }, [
    submissions,
    theme.palette.error.main,
    theme.palette.secondary.main,
    theme.palette.warning.main
  ]);
  return (
    <Paper elevation={0}>
      <Chart
        rootComponent={ChartRoot}
        height={generalStatsHeight}
        data={timeManagement}
      >
        <Palette scheme={timeManagement.map(el => el.color)} />
        <PieSeries
          valueField="val"
          argumentField="cat"
          outerRadius={0.8}
          innerRadius={0.6}
        />
        <Title
          text={intl.formatMessage({
            id: "task.statistics.gradeAvrage",
            defaultMessage: "Time management"
          })}
          textComponent={TitleText}
        />
        <Animation />
        <Legend
          position="bottom"
          markerComponent={Marker}
          rootComponent={RootWithTitle}
          itemComponent={Item}
          labelComponent={Label}
        />
        <EventTracker />
        <Tooltip />
      </Chart>
    </Paper>
  );
}
