import React from "react";
import clsx from "clsx";
import makeStyles from '@mui/styles/makeStyles';
import { Box } from "@mui/material";
import TooltipWithIntl from "../SharedComponents/tooltip/TooltipWithIntl";

const useStyles = makeStyles(theme => ({
  annotateBar: {},
  btn: { display: "inline" },
  disabledBtn: {
    cursor: "not-allowed",
    marginInlineEnd: theme.spacing(2),
    color: "#aaaaaa"
  },
  highlightBtn: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    marginInline: theme.spacing(1),

    // paddingBlockStart: 4, // to visualy center the icon

    "& :hover": {
      color: "#BEF3BF"
    }
  }
}));

const ReaderAction = React.forwardRef(
  (
    {
      Icon,
      selected,
      style,
      onMouseEnter,
      onMouseLeave,
      enabled = true,
      minimal = false,
      intlStringId,
      defaultMessage,
      placement,
      handleClick
    },
    ref
  ) => {
    const classes = useStyles();

    return (
      <Box
        className={clsx(
          classes.btn,
          enabled && classes.highlightBtn,
          !enabled && classes.disabledBtn,
          minimal && classes.minimal
        )}
        onClick={handleClick}
        style={{
          backgroundColor: selected ? "rgba(255,255,255, 0.15)" : "transparent",
          borderRadius: 3,
          ...style
        }}
        onMouseEnter={() => {
          onMouseEnter && onMouseEnter();
        }}
        onMouseLeave={() => {
          onMouseLeave && onMouseLeave();
        }}
      >
        <TooltipWithIntl
          intlStringId={intlStringId}
          defaultMessage={defaultMessage}
          placement={placement}
        >
          <Icon fontSize={"medium"} />
        </TooltipWithIntl>
      </Box>
    );
  }
);

ReaderAction.displayName = "ReaderAction";

export default ReaderAction;
