export function getScrolledRectPosition(
  containerRect,
  iframeRect,
  elementRect
) {
  // getScrolledRectPosition is used by reactReaderActions to calculate the position and size of a selecrtion or a clicked element. It takes containerRect, iframeRect,elementRect (DOMRect objects) and return the calculated scrolled position of the elementRect

  const topOffset = containerRect.top;
  // top offset is the distanse between the container (passes as prop) and the top of the screen. We assume that there won't be any padding top between the container and ttext

  const leftOffset = iframeRect.left - containerRect.left;
  // left offset is the distance between the text and the left of the container

  const scrolledRect = {
    x: elementRect.x + leftOffset,
    left: elementRect.left + leftOffset,

    y:
      iframeRect.top < topOffset
        ? elementRect.y + iframeRect.y - topOffset
        : elementRect.y,
    top:
      iframeRect.top < topOffset
        ? elementRect.top + iframeRect.top - topOffset
        : elementRect.top,

    bottom:
      iframeRect.top < topOffset
        ? elementRect.top + elementRect.height + iframeRect.top - topOffset
        : elementRect.top + elementRect.height,
    right: elementRect.left + elementRect.width,

    width: elementRect.width,
    height: elementRect.height
  };

  return scrolledRect;
}
