// dependancies
import { httpCallables } from "../../../firebase";
import { handleError, fetchAsObservable } from "../utils";
import { captureException } from "../../../utils/errorHandlers";


// Redux

// Rx
import { ofType } from "redux-observable";
import { empty } from "rxjs";
import { map, catchError, switchMap } from "rxjs/operators";
import { fetchGRInteractions } from "../../grSlice";
import { setInteractions } from "../../interactionsSlice";

const CALLBACK = httpCallables.interactionFunctions;
const ERROR_MSG = `FETCH_GR_INTERACTIONS`;
const ACTION = "readUserInteractionsPerText";

export const fetchGRInteractionsEpic = (
  action$,
  state$,
  { fetchAsObservable }
) => {
  return action$.pipe(
    ofType(fetchGRInteractions.type),
    map(({ action, payload }) => ({ text_id: Number(payload.text_id) })),
    switchMap(args =>
      fetchAsObservable(CALLBACK, ACTION, ERROR_MSG, args).pipe(
        map(({ interactions }) => ({
          type: setInteractions.type,
          payload: interactions
        }))
      )
    ),
    catchError(error => {
      captureException(error, "Error in fetchGRInteractionsEpic");
      return empty();
    })
  );
};
