// Dependencies
import React, { useEffect } from "react";
import { useQuery } from "../../../hooks";
import { useIntl } from "react-intl";
import store from "../../../redux/store";
import {
  FEATURE_FLAGS,
  HIGHLIGHTS,
  SECONDARY_SIDEBAR_ACTION_BUTTONS
} from "../../../consts";
import {
  handleCommentPanel,
  handleSuggestionsPanel,
  handleThumbnailPanel
} from "../../SharedComponents/buttons/utils";

// Redux
import { useDispatch, useSelector } from "react-redux";
import { selectSubmission } from "../../../redux/tasksSlice";
import {
  selectCurrentQuestion,
  selectedQuestionHighlights
} from "../../../redux/interactionsSlice";
import { pdfThumbnailsIconClicked } from "../../../redux/layoutSlice";
import {
  selectIsComments,
  selectIsFeatureFlagEnabled,
  selectIsSuggestions,
  selectIsThumbnails
} from "../../../redux/firestoreSelectors";
import { setHighlightColor } from "../../../redux/readerActionsSlice";

// Components
import BookView from "../../reader/BookView";
import { ScrollBox } from "../../SharedComponents";
import CardsManager from "../../poc/CardsManager";
import MultiChoice from "./multiChoice/MultiChoice";
import OpenEnded from "./openEnded/OpenEnded";
import useCreatePortal from "../../../hooks/useCreatePortal";
import ToggleBtnConstructor from "../../SharedComponents/buttons/toggleBtnConstructor";

// Material UI
import makeStyles from "@mui/styles/makeStyles";
import { Typography, Box } from "@mui/material";
import { isPdfSelector } from "../../../redux/textsSlice";
import { PdfControls } from "../../menus/PdfControls";
import HeaderToolbar from "../../menus/HeaderToolbar";
import clsx from "clsx";
import { CHATBOX_STATUS } from "../../../redux/chatSlice";
import { isFeatureFlagEnabled } from "../../../redux/epics/eMentorEpics/utils";

// Styles
const useStyles = makeStyles(theme => {
  return {
    innerWrapper: {
      width: "100%",
      height: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      top: 0,
      left: 0
    },
    stageHeader: {
      display: "flex",
      justifyContent: "center",
      width: "100%",
      paddingTop: theme.spacing(2)
    },
    stageHeaderContent: {
      display: "flex",
      flexDirection: "column",
      maxWidth: 700,
      width: "66%",
      justifyContent: "flex-start"
    },
    lightIcon: {
      color: "white !important",
      backgroundColor: "transparent !important"
    },
    stagePanel: {
      display: "flex",
      width: "100%",
      flexFlow: "column",
      justifyContent: "center",
      alignItems: "center"
    },
    stagePanelWithChat: { width: "calc(100% - 280px)" },
    stagePanelHeader: {
      display: "flex",
      width: "100%",
      maxWidth: 700,
      justifyContent: "flex-start",
      flexFlow: "row nowrap"
    }
  };
});

function StandardTaskContent() {
  // Hooks
  const intl = useIntl();
  const classes = useStyles();
  const { submission_id } = useQuery();
  const dispatch = useDispatch();
  const ThumbnailsPortal = useCreatePortal(
    document && document.getElementById("global-thumbnails-btn")
  );
  const CommentsPortal = useCreatePortal(
    document && document.getElementById("global-comments-btn")
  );
  const SuggestionsPortal = useCreatePortal(
    document && document.getElementById("global-suggestions-btn")
  );
  const PdfControlPortal = useCreatePortal(
    document && document.getElementById("global-pdfControl-btn")
  );
  const HeaderToolbarPortal = useCreatePortal(
    document && document.getElementById("global-headerToolBar-btn")
  );
  // Redux Selectors
  const submission = useSelector(state =>
    selectSubmission(state, Number(submission_id))
  );
  const taskStep = useSelector(state => state.task.step);
  const currentQuestion = useSelector(selectCurrentQuestion);
  const questionHighlights = useSelector(selectedQuestionHighlights);
  const currentQuestionIndex = useSelector(
    state => state.task && state.task.selectedQuestionIndex
  );
  const selectedHighlightColor = useSelector(
    state => state.readerActions.persistentActionState.highlightColor
  );
  const featureFlagEnabled = useSelector(state =>
    selectIsFeatureFlagEnabled(state, FEATURE_FLAGS.E_MENTOR_REVIEW)
  );
  const isCommentsInSecondarySidebar = useSelector(state =>
    selectIsComments(state)
  );
  const isThumbnailsInSecondarySidebar = useSelector(state =>
    selectIsThumbnails(state)
  );
  const isSuggestionsInSecondarySidebar = useSelector(state =>
    selectIsSuggestions(state)
  );
  const isFeatureEnabled = isFeatureFlagEnabled(
    store.getState(),
    FEATURE_FLAGS.E_MENTOR_JOURNEY
  );
  const isPdf = useSelector(isPdfSelector);
  const chatStatus = useSelector(state => state.chat.status);
  const chatIsVisible = chatStatus === CHATBOX_STATUS.OPEN;
  // Ephemeral state

  //Derived state

  //status
  const status = submission.status;
  const isSubmitted = status === "Submitted";

  // stage
  const isStepAnswer = taskStep === "answer";
  const isStepReview = taskStep === "review";

  //question type
  const questionType = currentQuestion?.interaction_subtype;
  const isFindInTextQuestion = questionType === "FIND_IN_TEXT";
  const isOpenEndedQuestion = questionType === "OPEN_ENDED";
  const isMultiChoiceQuestion = questionType === "MULTI_CHOICE";

  //Behavior

  useEffect(() => {
    if (!isPdf && isThumbnailsInSecondarySidebar)
      dispatch(pdfThumbnailsIconClicked());

    if (selectedHighlightColor !== HIGHLIGHTS.COLOR.STANDARD_TASK)
      dispatch(setHighlightColor(HIGHLIGHTS.COLOR.STANDARD_TASK));

    return () => {
      dispatch(setHighlightColor(HIGHLIGHTS.COLOR.GREEN));
    };
  }, []);

  // Render
  switch (true) {
    case isStepAnswer && isFindInTextQuestion:
    case isStepReview:
      return (
        <Box className={classes.innerWrapper}>
          <ScrollBox>
            <Box
              className={clsx(
                classes.stagePanel,
                featureFlagEnabled &&
                  chatIsVisible &&
                  classes.stagePanelWithChat
              )}
            >
              <Box
                className={clsx(
                  classes.stageHeader,
                  featureFlagEnabled &&
                    chatIsVisible &&
                    classes.stagePanelHeader
                )}
              >
                <Box className={classes.stageHeaderContent}>
                  <Typography variant="h6">
                    {intl.formatMessage({
                      id: "task.stage.reviewHighlights",
                      defaultMessage: "Review Highlights"
                    })}
                  </Typography>
                </Box>
              </Box>
              <Box>
                <CardsManager
                  color={"secondary"}
                  context={HIGHLIGHTS.CONTEXT.ANSWERS} // TODO: find a way to remove this prop. verry confusing...
                  cards={questionHighlights}
                  disable={submission.status === "Submitted"}
                />
              </Box>
            </Box>
          </ScrollBox>
        </Box>
      );
    case isStepAnswer && isOpenEndedQuestion:
      return (
        <OpenEnded question={currentQuestion} index={currentQuestionIndex} />
      );
    case isStepAnswer && isMultiChoiceQuestion:
      return <MultiChoice />;
    default:
      return (
        <>
          {isPdf && (
            <>
              <ThumbnailsPortal>
                <ToggleBtnConstructor
                  type={SECONDARY_SIDEBAR_ACTION_BUTTONS.THUBMNAILS}
                  handleBtnAction={handleThumbnailPanel}
                  secondarySideBarMode={isThumbnailsInSecondarySidebar}
                />
              </ThumbnailsPortal>
              <PdfControlPortal>
                <PdfControls />
              </PdfControlPortal>
              {isFeatureEnabled && (
                <SuggestionsPortal>
                  <ToggleBtnConstructor
                    type={SECONDARY_SIDEBAR_ACTION_BUTTONS.SUGGESTIONS}
                    handleBtnAction={handleSuggestionsPanel}
                    secondarySideBarMode={isSuggestionsInSecondarySidebar}
                  />
                </SuggestionsPortal>
              )}
            </>
          )}
          <CommentsPortal>
            <ToggleBtnConstructor
              type={SECONDARY_SIDEBAR_ACTION_BUTTONS.COMMENTS}
              handleBtnAction={handleCommentPanel}
              secondarySideBarMode={isCommentsInSecondarySidebar}
            />
          </CommentsPortal>
          <HeaderToolbarPortal>
            <HeaderToolbar />
          </HeaderToolbarPortal>
          <BookView
            readOnly={submission.status === "Submitted"}
            minimalBar={false}
            disableInteractions={isSubmitted}
            isVisible={true}
          />
        </>
      );
  }
}

StandardTaskContent.propTypes = {};

export default StandardTaskContent;
