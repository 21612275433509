import React from "react";

const RenditionContext = React.createContext();

function RenditionProvider({ children }) {
  // Ephemeral state
  const [rendition, setRendition] = React.useState(null);

  // TODO: Memoaize? http://kcd.im/optimize-context
  const value = { rendition, setRendition };
  return (
    <RenditionContext.Provider value={value}>
      {children}
    </RenditionContext.Provider>
  );
}

function useRendition() {
  const context = React.useContext(RenditionContext);
  if (context === undefined) {
    throw new Error("useRendition must be used within a RenditionProvider");
  }
  return context;
}

export { RenditionProvider, useRendition };
export default RenditionContext;
