import { firestore, firebaseApp, auth } from "../firebase";
import store from "../redux/store";
import { setTasksViewed } from "../redux/userSlice";
import { captureException } from "../utils/errorHandlers";

const userAPI = {
  updateUser: async function (data) {
    try {
      await firestore
        .doc(`users/${firebaseApp.auth().currentUser.uid}`)
        .update(data);
      return true;
    } catch (err) {
      captureException(
        err,
        `Failed to update user data. user ${auth.currentUser.uid}`
      );
      return false;
    }
  },

  updateViewedTasks: async function (taskId) {
    const tasksViewed = [...store.getState().user.tasksViewed];
    try {
      await firestore
        .doc(`users/${firebaseApp.auth().currentUser.uid}`)
        .update({ tasksViewed: tasksViewed.concat([taskId]) });
      // we update the store beacouse we havent set a listener to user doc changes.
      store.dispatch(setTasksViewed(tasksViewed.concat([taskId])));
      return true;
    } catch (err) {
      captureException(
        err,
        `Failed to update viewd tasks. user ${auth.currentUser.uid}`
      );
      return false;
    }
  },
  log: async function (data, shouldAwait = false) {
    try {
      async function logTransaction(t, data) {
        const current_id_ref = firestore
          .collection("appActions")
          .doc("current_id");
        const current_id_doc = await t.get(current_id_ref);
        const current_id = current_id_doc.data();
        const next_id = { id: current_id.id + 1 };
        const new_doc = firestore.collection(`appActions`).doc(`${next_id.id}`);
        t.set(new_doc, data);
        t.set(current_id_ref, next_id);
      }

      // We are using the await conditinaly becouse the log doesn't get the auth on logout
      // TODO: once the logout flow is refactored ...
      // ... we should find a better way to run callbacks before logout
      if (shouldAwait)
        await firestore.runTransaction(t => logTransaction(t, data));
      else firestore.runTransaction(t => logTransaction(t, data));
    } catch (err) {
      captureException(
        err,
        `Failed to log user behaviour. user ${auth.currentUser.uid}`
      );
    }
  }
};

export default userAPI;
