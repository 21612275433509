import PropTypes from "prop-types";

export const taskShape = {
  id: PropTypes.number.isRequired,
  collaborator_due_date: PropTypes.string,
  course_id: PropTypes.number.isRequired,
  creator: PropTypes.string.isRequired,
  display_priority: PropTypes.number,
  name: PropTypes.string.isRequired,
  original_due_date: PropTypes.string.isRequired,
  publish_date: PropTypes.string.isRequired,
  revised_due_date: PropTypes.string,
  status: PropTypes.string.isRequired,
  task_description: PropTypes.string,
  task_type: PropTypes.string,
  text_id: PropTypes.number.isRequired
};

export const submissionShape = {
  course_id: PropTypes.number.isRequired,
  due_date: PropTypes.string,
  id: PropTypes.number.isRequired,
  is_checked: PropTypes.bool,
  owner: PropTypes.string.isRequired,
  related_submission_id: PropTypes.number,
  status: PropTypes.string.isRequired,
  submission_date: PropTypes.string,
  task_id: PropTypes.number.isRequired,
  display_priority: PropTypes.number // This prop is not comeing from the DB, rather, it is derived form a related task
};

export const collaborationShape = {
  course_id: PropTypes.number,
  due_date: PropTypes.string,
  id: PropTypes.number,
  is_checked: PropTypes.bool,
  owner: PropTypes.string,
  related_submission_id: PropTypes.number,
  status: PropTypes.string,
  submission_date: PropTypes.string,
  task_id: PropTypes.number
};
