// Dependancies
import React, { createContext, useContext } from "react";
import { dimensionsPropsType } from "./utils";

import makeStyles from "@mui/styles/makeStyles";
import { Box } from "@mui/material";

// Styles
const useStyles = makeStyles(theme => ({
  container: {
    height: "100%"
  },
  chart: {
    display: "block" // important: The sizeing assumes that the svg is the same size as the container. if left at the default (inline) it will be smaller
  }
}));

const ChartContext = createContext();

export function useChartContext() {
  const context = useContext(ChartContext);
  if (!context) {
    throw new Error(
      "useChartContext have to be used inside ChartContext provider"
    );
  }

  return context;
}

const Chart = React.forwardRef(({ dimensions, children }, ref) => {
  // Hooks
  const classes = useStyles();

  // Render
  return (
    <Box className={classes.container} ref={ref}>
      <ChartContext.Provider value={{ dimensions }}>
        <svg
          className={classes.chart}
          width={dimensions.width}
          height={dimensions.height}
        >
          <g
            transform={`translate(${dimensions.marginLeft}, ${dimensions.marginTop})`}
          >
            {children}
          </g>
        </svg>
      </ChartContext.Provider>
    </Box>
  );
});
Chart.propTypes = {
  dimensions: dimensionsPropsType
};

Chart.defaultProps = {
  dimensions: {}
};

export default Chart;
