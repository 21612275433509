// Dependencies
import React, { useEffect, useState } from "react";
import { firebaseFunctions } from "../../../firebase";
import { useHistory } from "react-router-dom";
import { useQuery } from "../../../hooks";

import { FormattedMessage, useIntl } from "react-intl";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { captureException } from "../../../utils/errorHandlers";

// Redux Dependencies
import { useSelector, useDispatch } from "react-redux";
import { enqueueFlashMessage, undo } from "../../../redux/userSlice";
import { selectCourse } from "../../../redux/coursesSlice";
import {
  selectTasks,
  reorderTasks,
  selectCourseSubmissionsSortedByUserName,
  fetchTeacherCourseTasks,
  STATUS
} from "../../../redux/tasksSlice";
//Components
import TasksListItem from "./TasksListItem";
import makeStyles from "@mui/styles/makeStyles";
import { Box, Button, Typography, List, Divider } from "@mui/material";
import PangeaSpinner from "../../SharedComponents/PangeaSpinner";

const useStyles = makeStyles(theme => ({
  wideCell: {
    width: "80%"
  },
  left: {
    textAlign: "left"
  },
  tableRow: {
    height: "52px"
  },
  studenBox: {
    marginLeft: "56px"
  },
  tableContainer: {
    width: "100%",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: "48px",
    marginBottom: "24px"
  },
  taskContainer: {
    width: "100%",
    marginLeft: "auto",
    marginRight: "auto",
    marginBlockStart: theme.spacing(6),
    marginBlockEnd: theme.spacing(3)
  },
  tasksHeader: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    marginTop: theme.spacing(7.5),
    marginBottom: theme.spacing(2)
  },
  tasks: {
    backgroundColor: "transparent",
    boxShadow: "none",
    border: "none",
    "& .MuiAccordionSummary-content": {
      alignItems: "center",
      justifyContent: "space-between",
      order: 2
    }
  }
}));

export default function TasksTeacherView() {
  //Hooks
  const classes = useStyles();
  const intl = useIntl();
  const dispatch = useDispatch();
  const history = useHistory();
  const { course_id, task_id } = useQuery();

  // Redux State
  const shouldUndo = useSelector(state => state.user.undo);
  const alertsDuration = useSelector(
    state => state.user.userProfile.alertsDuration
  );

  const course = useSelector(state => selectCourse(state, Number(course_id)));
  const tasks = useSelector(state => selectTasks(state, course_id));
  const submissions = useSelector(state =>
    selectCourseSubmissionsSortedByUserName(state, Number(course_id))
  );
  const status = useSelector(state => state.tasks.status);

  // Ephemeral State
  const [taskGraders, setTaskGraders] = useState(false);
  const [undoData, setUndoData] = useState(null);

  //TODO: this is duplicated code from Library
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  function onDragEnd(result) {
    const { source, destination } = result;

    // dropped outside the list
    if (!result.destination) return;
    const items = reorder(tasks, source.index, destination.index);
    //call function for reorder:
    let taskFuncions = firebaseFunctions.httpsCallable("tasks-taskFunctions");

    taskFuncions({
      func_name: "reorderTasks",
      course: course.id,
      tasks: items
    }).catch(err => {
      captureException(
        err,
        `Failed to update teacher's tasks order. courseId ${course.id}`
      );
    });
    dispatch(reorderTasks(items));
  }

  useEffect(() => {
    dispatch(fetchTeacherCourseTasks({ course_id }));
  }, [dispatch, course_id]);

  // Behavior
  // Invoke the undo logic when the Redux undo flag is true
  useEffect(() => {
    const undoDeleteTask = () => {
      if (undoData?.type === "deleteTask") {
        let saveTask = firebaseFunctions.httpsCallable("courses-saveTask");
        saveTask({
          ...undoData.task,
          questions: undoData.task.questions.questions,
          answers: undoData.task.answers.answers,
          id: 0
        }).then(() => {
          // refreshTasks();
        });
      }
    };

    if (shouldUndo) {
      undoDeleteTask();
      dispatch(undo(false));
      // OPTION: change this when implementing multiple undos
      setUndoData(null);
    }
  }, [
    shouldUndo,
    dispatch,
    undoData
    // refreshTasks
  ]);

  // Show flash message when there is undo data
  useEffect(() => {
    if (undoData) {
      dispatch(
        enqueueFlashMessage({
          message: intl.formatMessage({
            id: "task.deleted",
            defaultMessage: "Task deleted"
          }),
          duration: alertsDuration,
          undoButton: true
        })
      );
    }
  }, [undoData, dispatch, intl]);

  if (status === STATUS.PENDING) {
    return <PangeaSpinner />;
  } else if (task_id) {
    const task = tasks.filter(task => task.id == task_id)[0];
    const submission = submissions.filter(
      submission => submission.task_id == task_id
    );
    return (
      <Box className={classes.taskContainer} key={task_id}>
        {task ? (
          <TasksListItem
            course={course}
            openTasks={task_id}
            toggleTask={false}
            task={task}
            submissions={submission}
            singleItemView={true}
          />
        ) : (
          <PangeaSpinner />
        )}
      </Box>
    );
  } else
    return (
      <>
        <Box className={classes.tasksHeader}>
          <Typography variant="h6">{course.name}</Typography>
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={() => {
              history.push(`/tasks/new?course_id=${course.id}`);
            }}
          >
            <FormattedMessage id="tasks.create" defaultMessage="Add new Task" />
          </Button>
        </Box>
        <Divider />
        {!tasks.length ? (
          <Typography variant="h6">
            There are currently no tasks for this course
          </Typography>
        ) : (
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId={"1"} direction="vertical">
              {provided => {
                return (
                  <List
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    className={classes.tasks}
                  >
                    {tasks.map((task, index) => {
                      return (
                        <TasksListItem
                          key={task.id}
                          course={course}
                          task={task}
                          taskIndex={index}
                          graders={taskGraders}
                          submissions={submissions.filter(
                            submission =>
                              submission.task_id === task.id &&
                              submission.related_submission_id === null
                          )}
                          singleItemView={false}
                        />
                      );
                    })}
                    {provided.placeholder}
                  </List>
                );
              }}
            </Droppable>
          </DragDropContext>
        )}
      </>
    );
}
