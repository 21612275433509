// Dependencies
import React, { useEffect, useState } from "react";
import { firebaseFunctions } from "../../firebase";
import { useQuery } from "../../hooks";

//Components
import PangeaSpinner from "../SharedComponents/PangeaSpinner";
import ScrollBox from "../SharedComponents/ScrollBox";
import CourseTasks from "./CourseTasks";
import CourseTexts from "./CourseTexts";
import CourseUsers from "./CourseUsers";

import makeStyles from "@mui/styles/makeStyles";
import { Box, Typography } from "@mui/material";
import CourseFeatures from "./CourseFeatures";

// Styles
const useStyles = makeStyles(theme => ({
  container: {
    width: "100%",
    height: "100%",
    display: "grid",
    gridTemplateColumns: "repeat(12, 1fr)",
    columnGap: theme.spacing(2)
  },
  courseDetails: {
    gridColumnEnd: "span 12",
    marginBlock: theme.spacing(5)
  },
  title: {
    gridColumnEnd: "span 2"
  },
  table: {
    gridColumnEnd: "span 10"
  }
}));
function Course() {
  //Hooks
  const classes = useStyles();
  const { course_id } = useQuery();

  //Ephemeral State
  const [status, setStatus] = useState("idle");
  const [courseName, setCourseName] = useState(null);
  const [academicYear, setAcademicYear] = useState(null);
  const [lmsClient, setLmsClient] = useState(null);
  const [lmsCourseId, setLmsCourseId] = useState(null);
  const [courseTimezone, setCourseTimezone] = useState(null);

  // Variables

  // Behavior
  useEffect(() => {
    setStatus("loading");
    const getCourseById = firebaseFunctions.httpsCallable(
      "adminFunctions-getCourseById"
    );

    getCourseById({ courseId: course_id }).then(response => {
      const data = response.data;
      if (data.length > 0) {
        const { academic_year, lms_client, lms_course_id, name, timezone } =
          data[0];

        setCourseName(name);
        setAcademicYear(academic_year);
        setLmsClient(lms_client);
        setLmsCourseId(lms_course_id);
        setCourseTimezone(timezone);
      }
      setStatus("idle");
    });
  }, [course_id]);

  if (status === "loading") {
    return <PangeaSpinner />;
  } else {
    return (
      <ScrollBox className={classes.container}>
        <Box className={classes.courseDetails}>
          <Typography variant="h2">{courseName}</Typography>
          <Typography variant="h4">{academicYear}</Typography>
          {courseTimezone && (
            <Typography variant="h4">
              {" "}
              {`time zone: ${courseTimezone}`}
            </Typography>
          )}
          {lmsClient && (
            <Typography variant="h4"> {`lms: ${lmsClient}`} </Typography>
          )}
          {lmsCourseId && (
            <Typography variant="h4">
              {`lms course id: ${lmsCourseId}`}{" "}
            </Typography>
          )}
        </Box>
        <Box className={classes.title}>
          <Typography variant="h4">Users</Typography>
        </Box>
        <Box className={classes.table}>
          <CourseUsers courseId={course_id} />
        </Box>
        <Box className={classes.title}>
          <Typography variant="h4">Texts</Typography>
        </Box>
        <Box className={classes.table}>
          <CourseTexts courseId={course_id} />
        </Box>
        <Box className={classes.title}>
          <Typography variant="h4">Tasks</Typography>
        </Box>
        <Box className={classes.table}>
          <CourseTasks courseId={course_id} />
        </Box>
        {/* <Box className={classes.title}>
          <Typography variant="h4">Features</Typography>
        </Box>
        <Box>
          <CourseFeatures courseId={course_id} />
        </Box> */}
      </ScrollBox>
    );
  }
}

export default Course;
