import { createSlice } from "@reduxjs/toolkit";
import { USER_TYPE } from "../consts";
import { splitParagraphs } from "./epics/eMentorEpics/textUtils";

export const CHATBOX_STATUS = {
  CLOSED: "CLOSED",
  OPEN: "OPEN"
};

export const CHAT_TYPE = {
  REVIEW: "REVIEW",
  READER: "READER"
};

export const CONVERSATION_TYPES = {
  INCOMING: "INCOMING",
  OUTGOING: "OUTGOING"
};

export const MOODS = {
  CONFUSED: { displayName: "Confused", emoji: "🤔" },
  PERPLEXED: { displayName: "Perplexed", emoji: "😕" },
  FRUSTRATED: { displayName: "Frustrated", emoji: "😬" },
  BORED: { displayName: "Bored", emoji: "😑" },
  INTERESTED: { displayName: "Interested", emoji: "😊" },
  EXCITED: { displayName: "Excited", emoji: "😁" },
  SEARCHING: { displayName: "Searching for something", emoji: "🔍" },
  BROWSING: { displayName: "Browsing", emoji: "👀" }
};

const initialState = {
  // the fab should have it's own slice once there are more actions
  status: CHATBOX_STATUS.CLOSED,
  mood: "INTERESTED",
  sessionId: null,
  isLoading: false,
  conversation: [] // [{type: INCOMING/OUTGOING, content: string}]
};

export const chatSlice = createSlice({
  name: "chat",
  initialState,
  extraReducers: {
    "@@router/LOCATION_CHANGE": state => {
      state.status = CHATBOX_STATUS.CLOSED;
    }
  },
  reducers: {
    resetConversation: state => {
      state.sessionId = null;
      state.isLoading = false;
      state.conversation = [];
    },
    loadConversation: (state, value) => {
      const { interactions, session_id } = value.payload;

      state.sessionId = session_id;
      interactions.forEach(interaction => {
        const type = // TODO: refactor. this is weak logic, it won't hold for chat between two users
          interaction.user_type === USER_TYPE.MENTOR
            ? CONVERSATION_TYPES.INCOMING
            : CONVERSATION_TYPES.OUTGOING;

        splitParagraphs(interaction.content).forEach(content => {
          // splitParagraphs returns an array of strings, so we need to iterate over it
          state.conversation.push({ content, type });
        });
      });
    },

    setIsLoading: (state, value) => {
      state.isLoading = value.payload;
    },

    openChatbox: state => {
      state.status = CHATBOX_STATUS.OPEN;
    },
    closeChatbox: state => {
      state.status = CHATBOX_STATUS.CLOSED;
    },

    // Actions for epics
    initMentorReview: () => {},

    initChat: (state, value) => {
      state.status = CHATBOX_STATUS.OPEN;
      const { content, sessionId } = value.payload;

      state.sessionId = sessionId;
      state.conversation.push({
        type: CONVERSATION_TYPES.INCOMING,
        content
      });
    },

    setMood: (state, value) => {
      const { mood } = value.payload;
      state.mood = mood;
    },
    replyToMentor: (state, value) => {
      const { content } = value.payload;
      state.conversation.push({ content, type: CONVERSATION_TYPES.OUTGOING });
    },
    mentorReplies: (state, value) => {
      const { content } = value.payload;

      state.conversation.push({
        type: CONVERSATION_TYPES.INCOMING,
        content
      });
    }
  }
});

export const {
  initChat,
  openChatbox,
  closeChatbox,
  loadConversation,
  mentorReplies,
  replyToMentor,
  setMood,
  initMentorReview,
  resetConversation,
  setIsLoading
} = chatSlice.actions;

// Selectors

// Utils

export default chatSlice.reducer;
