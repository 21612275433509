// Dependencies
import React, { useEffect, useState } from "react";
import { useHistory, Link } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import { isAfter, isPast, isToday } from "date-fns";
import clsx from "clsx";
import { QUESTION } from "../../../consts";
import { dateToEodUtc } from "./utils.js";
import { utcToZonedTime } from "date-fns-tz";
// Redux dependencies
import { useSelector, useDispatch } from "react-redux";
import { enqueueFlashMessage } from "../../../redux/userSlice";
import { selectTexts, selectText } from "../../../redux/textsSlice";
import { selectTaskSubmissions } from "../../../redux/tasksSlice";

// Components
import SelectReading from "./StepSelectReadings";
import StepAddQuestions from "./StepAddQuestions";
import SubmitTask from "./StepSubmitTask";
import RouteLeavingGuard from "./RouteLeavingGuard";

import makeStyles from "@mui/styles/makeStyles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  Box,
  Button,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Snackbar,
  SnackbarContent,
  Typography
} from "@mui/material";
import { httpCallables } from "../../../firebase";
import { selectTextDirection } from "../../../redux/firestoreSelectors";

//Styles
const useStyles = makeStyles(theme => ({
  right: {
    textAlign: "right"
  },
  left: {
    textAlign: "left"
  },
  tasksHeader: {
    marginTop: "40px",
    width: "100%",
    position: "relative"
  },
  btnContainer: {
    marginTop: "40px",
    marginBottom: "40px"
  },
  btn: {
    borderRadius: "8px",
    marginLeft: "4px",
    marginRight: "4px",
    textTransform: "none"
  },
  saveDraftlBtn: {
    textTransform: "none",
    float: "right"
  },
  goBackBtn: {
    position: "absolute",
    top: 0,
    transform: "translateX(-120%)"
  }
}));

function PublishedTaskView({ task, course, interactions }) {
  // Hooks
  const classes = useStyles();
  let history = useHistory();
  const intl = useIntl();
  const dispatch = useDispatch();

  // Ephemeral State
  const [isDirty, setIsDirty] = useState(false);
  const [textId, setTextId] = useState(null);
  const [readOnly, setReadOnly] = useState(true);
  const [showGuard, setShowGuard] = useState(false);
  const [taskType, setTaskType] = useState(null);
  const [taskTitle, setTaskTitle] = useState("");
  const [collaboratorDeadline, setCollaboratorDeadline] = useState(null);
  const [activeStep, setActiveStep] = useState(0);

  // Redux state
  const texts = useSelector(state => selectTexts(state, course.id));
  const text = useSelector(state => selectText(state, textId));
  const submissions = useSelector(state =>
    selectTaskSubmissions(state, task.id)
  );
  const textDirection = useSelector(state => selectTextDirection(state));
  const alertsDuration = useSelector(
    state => state.user.userProfile.alertsDuration
  );

  const [taskDescription, setTaskDescription] = useState(
    task && task.task_description ? task.task_description : ""
  );
  const [deadline, setDeadline] = useState(null);

  const [acceptUntil, setAcceptUntil] = useState(null);

  const [questions, setQuestions] = useState([QUESTION.OPEN_ENDED]);

  const [draftSave, setDraftSaved] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [activeGrTasks, setActiveGrTasks] = useState([]);

  // Variables
  const [message] = useState(
    intl.formatMessage({
      id: "task.saved",
      defaultMessage: "Task saved"
    })
  );

  useEffect(() => {
    setIsDirty(false);
  }, []);

  useEffect(() => {
    task.text_id && setTextId(task.text_id);
  }, [task.text_id]);

  useEffect(() => {
    task.name && setTaskTitle(task.name);
  }, [task.name]);

  useEffect(() => {
    task.task_type && setTaskType(task.task_type);
  }, [task.task_type]);

  useEffect(() => {
    task.original_due_date &&
      setDeadline(
        utcToZonedTime(task.original_due_date, course.timezone)
        // new Date(task.original_due_date)
      );
  }, [task.original_due_date]);

  useEffect(() => {
    if (task.collaborator_due_date)
      setCollaboratorDeadline(new Date(task.collaborator_due_date));
  }, [task.collaborator_due_date]);

  // useEffect(() => {
  //   task.accept_date && setAcceptUntil(new Date(task.accept_date));
  // }, [task.accept_date]);

  useEffect(() => {
    if (interactions.length) setQuestions(interactions);
  }, [interactions]);

  const saveTask = status => {
    let messages = validateForm();
    if (messages && messages.length) handelFlashMessage(messages);
    else {
      const payload = {
        func_name: "updateTask",
        task_id: task.id,
        questions: questions,
        name: taskTitle,
        description: taskDescription,
        text_id: text.id,
        dueDate: dateToEodUtc(deadline, course.timezone),
        acceptDate: dateToEodUtc(deadline, course.timezone),
        taskType: taskType,
        courseId: course.id
      };
      if (taskType === "peerReview") {
        payload.collaboratorDueDate = dateToEodUtc(
          collaboratorDeadline,
          course.timezone
        );
      }
      httpCallables.taskFunctions(payload).then(response => {
        if (response) {
          setIsDirty(false);
          history.push(`/tasks?course_id=${task.course_id}`);
        } else {
          // TODO: implement error alert
        }
      });
    }
  };

  const handelFlashMessage = message => {
    dispatch(
      enqueueFlashMessage({
        message: message,
        duration: alertsDuration,
        severity: "error"
      })
    );
  };

  const setWrapper = setFunc => state => {
    setFunc(state);
    setIsDirty(true);
  };

  const renderEditWarning = () => {
    return (
      <Dialog
        open={showWarning}
        PaperProps={{
          style: {
            direction: textDirection
          }
        }}
        onClose={() => setShowWarning(false)}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title" className={classes.dialogTitle}>
          <FormattedMessage id="task.edit.msg" defaultMessage="Edit task" />
        </DialogTitle>
        <DialogContent className={clsx(classes.dialog, classes.ltr)}>
          <Typography>
            <FormattedMessage
              id="task.edit.warning"
              defaultMessage="Some students have already started working on this task, are you sure you want to edit it?"
            />
          </Typography>
        </DialogContent>
        <DialogActions className={classes.btns}>
          <Button
            className={clsx(classes.yesBtn, classes.yesBtnLtr)}
            onClick={() => {
              setReadOnlyToFalse();
              setShowWarning(false);
            }}
            color="secondary"
          >
            <FormattedMessage id="gr.confirm.btn" defaultMessage="OK" />
          </Button>
          <Button
            className={clsx(classes.cancelBtn, classes.cancelBtnLtr)}
            onClick={() => setShowWarning(false)}
          >
            <FormattedMessage id="cancel" defaultMessage="Cancel" />
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const textValidation = intl.formatMessage({
    id: "tasks.create.validation.selectReading",
    defaultMessage: "Select a text"
  });

  const deadlineValidationMessage = intl.formatMessage({
    id: "tasks.create.validation.enterDeadline",
    defaultMessage: "Set a deadline for this task"
  });
  const deadlineValueValidationMessage = intl.formatMessage({
    id: "tasks.create.validation.deadLinePast",
    defaultMessage: "The deadline you selected has already passed"
  });
  const deadlineOrderValidationMessage = intl.formatMessage({
    id: "tasks.create.validation.deadlineOrder",
    defaultMessage:
      "Please make sure that the secound deadline is after the first deadline"
  });
  const titleValidationMessage = intl.formatMessage({
    id: "tasks.create.validation.enterTitle",
    defaultMessage: "Enter a task title"
  });
  const questionValidationMessage = intl.formatMessage({
    id: "tasks.create.validation.enterQuestion",
    defaultMessage: "Add a question"
  });
  const formulationValidationMessage = intl.formatMessage({
    id: "tasks.create.validation.enterFormulation",
    defaultMessage: "Add question formulation to question"
  });
  const questionTypeValidationMessage = intl.formatMessage({
    id: "tasks.create.validation.selectType",
    defaultMessage: `Select question type for question`
  });
  /*const pointsValidationMessage = intl.formatMessage({
    id: "tasks.create.validation.addPoints",
    defaultMessage: "Please add points for question",
  });
  const pointsValueValidationMessage = intl.formatMessage({
    id: "tasks.create.validation.pointsValue",
    defaultMessage: "Change the point value to a positive number in question ",
  });*/
  const optionsValidationMessage = intl.formatMessage({
    id: "tasks.create.validation.optionsValue",
    defaultMessage:
      "Multiple choice question must have more than one option in question "
  });
  const optionsChoiceValidationMessage = intl.formatMessage({
    id: "tasks.create.validation.optionChoice",
    defaultMessage:
      "Multiple choice must have an option marked as correct in question "
  });

  function validateForm() {
    let messages = [];

    if (!textId) {
      messages.push(textValidation);
    }
    // Check if there is a deadline
    // For standart tasks, check if there is a deadline
    if (taskType === "standard" && !deadline) {
      messages.push(deadlineValidationMessage);
    } else if (
      taskType === "peerReview" &&
      (!deadline || !collaboratorDeadline)
    ) {
      messages.push(deadlineValidationMessage);
      // If there is a deadline...
    } else {
      // Check if the deadline's day is today or after
      if (isPast(deadline) || isToday(deadline)) {
        messages.push(deadlineValueValidationMessage);
      }
      // Check if the secound deadline's day is today or after
      else if (
        collaboratorDeadline &&
        (isPast(deadline) || isToday(deadline))
      ) {
        messages.push(deadlineValueValidationMessage);
      }
      // Check if the first deadline is before the secound deadline
      if (acceptUntil && isAfter(acceptUntil, deadline)) {
        messages.push(deadlineOrderValidationMessage);
      }
    }

    if (!taskTitle) {
      messages.push(titleValidationMessage);
    }
    // Check that there is at least one question
    if (
      taskType !== "peerReview" &&
      questions.length <= 1 &&
      questions[0].question === ""
    ) {
      messages.push(questionValidationMessage);
    } else {
      // if there are questions, check that each have...
      questions.forEach((question, index) => {
        // A question formulation
        if (taskType !== "peerReview" && question.content.trim() === "") {
          messages.push(`${formulationValidationMessage} ${index + 1}`);
        }
        // A type selected
        if (
          question.interaction_subtype === "" &&
          taskType !== "guidedReading"
        ) {
          messages.push(`${questionTypeValidationMessage} ${index + 1}`);
        }

        if (question.interaction_subtype === "MULTI_CHOICE") {
          if (
            !question.options ||
            !question.options.length ||
            question.options.length < 2
          ) {
            messages.push(`${optionsValidationMessage} ${index + 1}`);
          }
          if (
            !("shouldSelect" in question) ||
            question.shouldSelect < 0 ||
            question.shouldSelect >=
              (question.options && question.options.length) ||
            typeof question.shouldSelect === "undefined"
          ) {
            messages.push(`${optionsChoiceValidationMessage} ${index + 1}`);
          }
        }
      });
    }
    return messages;
  }

  const renderBody = () => {
    return (
      <>
        <SelectReading
          course={course}
          texts={texts}
          text={text}
          setText={!readOnly && setWrapper(setTextId)}
          readOnly={readOnly}
          setIsDirty={!readOnly && setIsDirty}
        />
        <StepAddQuestions
          task={task}
          text={text}
          readOnly={readOnly}
          questions={questions}
          taskTitle={taskTitle}
          taskType={taskType}
          setTaskType={setTaskType}
          taskDescription={taskDescription}
          setQuestions={!readOnly && setWrapper(setQuestions)}
          setTaskTitle={!readOnly && setWrapper(setTaskTitle)}
          setTaskDescription={!readOnly && setWrapper(setTaskDescription)}
          saveTask={!readOnly && saveTask}
          handelFlashMessage={!readOnly && handelFlashMessage}
          activeGrTasks={activeGrTasks}
        />
        {showWarning && renderEditWarning()}
        <SubmitTask
          taskType={taskType}
          deadline={deadline}
          setDeadline={!readOnly && setWrapper(setDeadline)}
          collaboratorDeadline={collaboratorDeadline}
          setCollaboratorDeadline={setWrapper(setCollaboratorDeadline)}
          activeStep={activeStep}
          readOnly={readOnly}
          acceptUntil={acceptUntil}
          setAcceptUntil={!readOnly && setWrapper(setAcceptUntil)}
          handelFlashMessage={!readOnly && handelFlashMessage}
          setActiveStep={setActiveStep}
          setIsDirty={setIsDirty}
        />
      </>
    );
  };

  //const [openTexts, setOpenTexts] = React.useState(false);
  //var addCourseText = firebaseFunctions.httpsCallable('adminFunctions-addCourseText');
  const setReadOnlyToFalse = () => {
    setReadOnly(false);
    setShowGuard(true);
    setIsDirty(true);
  };
  return (
    <>
      <RouteLeavingGuard
        setIsDirty={setShowGuard}
        isDirty={showGuard}
        // selectCourse={props.selectCourse} // These props weren't passed
        // setTask={props.setTask}
        msg={
          isDirty
            ? intl.formatMessage({
                id: "tasks.create.SaveAsDraft",
                defaultMessage: "Save task as draft?"
              })
            : intl.formatMessage({
                id: "tasks.edit.noChange",
                defaultMessage: "No changes to task have been made"
              })
        }
        // Start blocking navigation when text is selected
        when={showGuard}
        // Navigation function provided by react router's useHistory
        navigate={path => history.push(path)}
        // adds a third button which will run a function before navigating
        additionalStep={
          isDirty
            ? () => {
                saveTask("Submitted");
              }
            : false
        }
        additionalMsg={intl.formatMessage({
          id: "task.saved",
          defaultMessage: "Task Saved"
        })}
        additionalStepButton={
          isDirty &&
          intl.formatMessage({
            id: "tasks.edit.SaveTask",
            defaultMessage: "Save task"
          })
        }
      />
      <Box position="relative">
        <Link
          to={`/tasks?course_id=${task.course_id}`}
          className={classes.goBackBtn}
        >
          <IconButton aria-label="go-back" onClick={() => {}} size="large">
            <ArrowBackIcon />
          </IconButton>
        </Link>
        <Typography
          className={clsx(classes.tasksHeader, classes.left)}
          variant="h4"
        >
          {taskTitle ? (
            taskTitle
          ) : (
            <FormattedMessage id="new.task" defaultMessage="New Task" />
          )}

          {readOnly ? (
            <Button
              variant="outlined"
              className={clsx(classes.saveDraftlBtn)}
              onClick={() => {
                submissions.filter(s => s.submission_status !== "Pending")
                  .length > 0
                  ? setShowWarning(true)
                  : setReadOnlyToFalse();
              }}
            >
              <FormattedMessage defaultMessage="Edit task" id="task.edit.msg" />
            </Button>
          ) : (
            <Button
              variant="outlined"
              // className={classes.btn}
              className={clsx(classes.saveDraftlBtn)}
              onClick={() => {
                setShowGuard(false);
                saveTask();
              }}
            >
              <FormattedMessage
                defaultMessage="Save task"
                id="tasks.edit.SaveTask"
              />
            </Button>
          )}
        </Typography>

        {renderBody()}
      </Box>
      <Snackbar
        open={draftSave}
        onClose={() => setDraftSaved(false)}
        autoHideDuration={6000}
        message={message}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: textDirection === "rtl" ? "left" : "right"
        }}
      >
        <SnackbarContent message={message} />
      </Snackbar>
    </>
  );
}

export default PublishedTaskView;
