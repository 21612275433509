import React from "react";
import PropTypes from "prop-types";
import makeStyles from "@mui/styles/makeStyles";
import { useDispatch, useSelector } from "react-redux";
import { Plus as PlusIcon } from "@styled-icons/boxicons-regular/Plus";
import { FormattedMessage } from "react-intl";
import { Button, FormControlLabel, Switch } from "@mui/material";
import { grAPI } from "../../../api";
import TYPES from "../../../types";
import { HighlightViewSwitch } from "../../SharedComponents";

const useStyles = makeStyles(theme => ({
  plusIcon: {
    marginInlineEnd: theme.spacing(1)
  },
  highlightSwitch: {
    marginInlineEnd: 4
  }
}));

export default function AddBtn({
  editable = true,
  color = "secondary",
  scrollToBottom,
  newQuestionMode,
  setNewQuestionMode
}) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const grMode = useSelector(state => state.gr.mode);
  const stage = useSelector(state => state.gr.stage);
  const showHighlights = useSelector(state => state.gr.showHighlights);
  const showAnswers = useSelector(state => state.gr.showAnswers);

  function addQuestion() {
    scrollToBottom();
    grAPI.updateSelectedQuestionId(null);
    if (!newQuestionMode.adding) {
      setNewQuestionMode({ adding: true, value: "" });
    }
  }

  return (
    <>
      {editable && (
        <Button variant="outlined" color={color} onClick={addQuestion}>
          <PlusIcon size={18} className={classes.plusIcon} />
          <FormattedMessage id="gr.addQuestion" defaultMessage="Question" />
        </Button>
      )}
      {grMode === "light" && stage === 0 && (
        <HighlightViewSwitch
          className={classes.highlightSwitch}
          color={color}
          on={showHighlights}
          onChange={() => {
            grAPI.updateGrState({ grShowHighlights: !showHighlights });
          }}
        />
      )}
      {grMode === "light" && stage === 1 && (
        <FormControlLabel
          control={
            <Switch
              color={color}
              checked={showAnswers}
              onChange={() => {
                grAPI.updateGrState({ grShowAnswers: !showAnswers });
              }}
            />
          }
          label={
            <FormattedMessage
              id="gr.answersView"
              defaultMessage="Answer view"
            />
          }
        />
      )}
    </>
  );
}

AddBtn.propTypes = {
  color: TYPES.COLOR,
  editable: PropTypes.bool,
  scrollToBottom: PropTypes.func.isRequired,
  newQuestionMode: PropTypes.shape({
    adding: PropTypes.bool.isRequired,
    value: PropTypes.string
  }).isRequired,
  setNewQuestionMode: PropTypes.func.isRequired
};
