// Dependencies
import React, {
  useState,
  useEffect,
  useReducer,
  useCallback,
  useMemo
} from "react";
import { httpCallables } from "../../../../firebase";
import { startOfDay, sub } from "date-fns";
import { useQuery } from "../../../../hooks";
import { v4 as uuid } from "uuid";
import { captureException } from "../../../../utils/errorHandlers";

// Redux
import { addSnackbarItem } from "../../../../redux/snackbarSlice";
import { useDispatch } from "react-redux";

//Components
import ScrollBox from "../../../SharedComponents/ScrollBox";
import EngagementChart from "./charts/classEngagementChart/EngagmentChart";
import CreatedGrInteractionsBarChart from "./charts/CreatedGrInteractionsBarChart";
import UsersTable from "./UsersTable";

import makeStyles from "@mui/styles/makeStyles";
import { Box, Grid } from "@mui/material";
import DateRangeSelection from "../../../SharedComponents/DateRangeSelection";
import WidgetHeader from "../../../SharedComponents/WidgetHeader";

// Styles
const useStyles = makeStyles(theme => ({
  container: {
    width: "100%",
    padding: theme.spacing(8)
  },
  engagementChart: {
    height: 700
  }
}));

function usersReducer(state, action) {
  switch (action.type) {
    case "set":
      return { ...action.payload };
    case "toggle":
      let { user } = action.payload;
      return {
        ...state,
        [user]: { ...state[user], selected: !state[user].selected }
      };
    case "update":
      let { user: userToUpdate, ...rest } = action.payload;
      return {
        ...state,
        [userToUpdate]: { ...state[userToUpdate], ...rest }
      };
    default:
      throw new Error();
  }
}

export default function CourseActivityReport() {
  //Hooks
  const dispatch = useDispatch();
  const classes = useStyles();
  const { course_id } = useQuery();

  //Redux

  // Ephemeral State
  const now = new Date();
  const twoDaysAgo = sub(now, { days: 2 });
  const nineDaysAgo = sub(now, { days: 9 });

  const [start, setStart] = useState(() => startOfDay(nineDaysAgo));
  const [end, setEnd] = useState(() => startOfDay(twoDaysAgo));

  // raw data
  const [users, dispatchUsers] = useReducer(usersReducer, {}); // user info by uid

  // Derived state
  const includeInEngagementChart = [
    "ENGAGEMENT",
    "SESSIONS",
    "READING_SESSIONS",
    "WRITING_SESSIONS",
    "COMMENTS_CREATED",
    "CITATIONS_CREATED",
    "QUESTIONS_CREATED"
  ];
  //Behavior
  const userIds = useMemo(() => {
    return Object.values(users).map(user => user.course_user);
  }, [users]);

  const selectedUsers = useMemo(() => {
    return Object.values(users)
      .filter(user => user.selected)
      .map(user => user.course_user);
  }, [users]);

  const generateInitalUsersState = useCallback(users => {
    return users.reduce((accumulator, current) => {
      const user = current.course_user;
      const selected = current.course_role === "Student";
      return {
        ...accumulator,
        [user]: { ...current, selected }
      };
    }, {});
  }, []);

  useEffect(() => {
    httpCallables
      .readCourseUsers({ course_id: Number(course_id) })
      .then(({ data }) => {
        if (data.success) {
          const { users } = data.payload;
          dispatchUsers({
            type: "set",
            payload: generateInitalUsersState(users)
          });
        } else {
          const { error } = data.payload;
          dispatch(
            addSnackbarItem({
              intlId: "error.readCourseUsersFailed",
              intlDefaultMessage:
                "There was a problem getting the course information. Please check your connection and try again",
              id: uuid()
            })
          );
          captureException(error, `Faild to get course users`);
        }
      });
  }, [course_id, dispatch, generateInitalUsersState]);

  return (
    <ScrollBox>
      <Box className={classes.container}>
        <DateRangeSelection
          start={start}
          setStart={setStart}
          end={end}
          setEnd={setEnd}
          maxDate={twoDaysAgo}
        />
        <WidgetHeader title="Users" />
        <UsersTable
          users={users}
          dispatchUsers={dispatchUsers}
          start={start}
          end={end}
        />

        <Grid
          component={"section"}
          container
          spacing={10}
          alignItems={"flex-start"}
        >
          <Grid className={classes.engagementChart} item xs={12}>
            <EngagementChart
              title={"Engagement"}
              course_id={course_id}
              users={userIds}
              start={start}
              end={end}
              includeClass={true}
              includeTypes={includeInEngagementChart}
            />
          </Grid>
          <Grid item xs={6}>
            <WidgetHeader title="Created questions" />
            <CreatedGrInteractionsBarChart
              users={selectedUsers}
              type="QUESTIONS_CREATED"
              start={start}
              end={end}
            />
          </Grid>
          <Grid item xs={6}>
            <WidgetHeader title="Created citations" />
            <CreatedGrInteractionsBarChart
              users={selectedUsers}
              type="CITATIONS_CREATED"
              start={start}
              end={end}
            />
          </Grid>
        </Grid>
      </Box>
    </ScrollBox>
  );
}
