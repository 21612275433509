import React, { useState, useRef } from "react";
import Concepts from "./Concepts";
import makeStyles from "@mui/styles/makeStyles";
import { useSelector } from "react-redux";
import { selectCurrentText } from "../../../redux/textsSlice";
import clsx from "clsx";
import CitationDialog from "./CitationDialog";
import {
  Box,
  Link,
  TextField,
  Typography,
  FormControlLabel,
  Checkbox
} from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";

const useStyles = makeStyles(theme => ({
  expandMsg: {
    display: "block",
    cursor: "pointer"
  },
  paddedContent: {
    paddingTop: "16px",
    paddingbottom: "16px",
    paddingLeft: "24px",
    paddingRight: "24px"
  },
  wordLmt: {
    // marginLeft: "8px",
    lineHeight: "32px",
    "& input": {
      textAlign: "center"
    }
  },
  concept: {
    boxSizing: "border-box",
    marginLeft: "1%",
    marginRight: "1%",
    marginTop: "4px",
    marginBottom: "4px",
    resize: "none",
    border: "none",
    lineHeight: "20px",
    letteSpacing: "0.13px",
    paddingLeft: "0px",
    paddingTop: "0px",
    paddingRight: "0px",
    paddingBottom: "0px",
    fontSize: "16px",
    width: "23%",
    fontFamily: theme.typography.fontFamily,
    "&:focus": {
      outline: "none"
    },
    "& ::placeholder": {
      /* Chrome, Firefox, Opera, Safari 10.1+ */ color: "#adadad"
    }
  },
  conceptsContainer: {
    position: "relative",
    paddingInline: theme.spacing(3),
    paddingBottom: theme.spacing(5.5)
    // paddingTop: theme.spacing(2)
  },
  spacing: {
    paddingInline: theme.spacing(3),
    paddingBlock: theme.spacing(2)
  },
  includeCitation: {
    display: "inline-block",
    width: "75%",
    color: theme.palette.text.disabled
  },
  wordLimit: {
    display: "inline-block",
    width: "25%"
  },
  conceptContainer: {
    width: "100%"
  },
  fakeLink: {
    fontSize: "1rem",
    color: "#168fee",
    "&:hover": {
      textDecoration: "none"
    }
  },
  wordInput: {
    marginLeft: "16px",
    paddingLeft: "5px",
    marginRight: "16px",
    borderRadius: "4px",
    border: "1px solid #adadad",
    "&:hover": {
      border: "1px solid #168fee"
    },
    "& input": {
      textAlign: "center"
    },
    "& input[type=number]::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0
    }
  },
  checkboxChecked: {
    color: theme.palette.text.primary
  },
  citationCheckbox: {
    color: "#BDBDBD"
  }
}));

function OpenAnswer({
  readOnly = false,
  removeHighlight,
  concepts,
  onConcepts,
  includeCitation,
  setIncludeCitation,
  onWordLimit,
  wordLimit,
  highlights,
  addHighlight
}) {
  const classes = useStyles();
  const text = useSelector(selectCurrentText);
  const [openDialog, setOpenDialog] = useState(false);
  const intl = useIntl();
  const wordLimitRef = useRef();

  return (
    <>
      <Box className={classes.spacing}>
        <Box
          className={clsx(
            classes.includeCitation,
            includeCitation && classes.checkboxChecked
          )}
        >
          <Typography component="span">
            <FormControlLabel
              control={
                <Checkbox
                  readOnly={readOnly}
                  className={classes.citationCheckbox}
                  checked={includeCitation}
                  name="include-citation-checkbox"
                  onChange={() => {
                    setIncludeCitation(!includeCitation);
                  }}
                  color="primary"
                />
              }
              label={
                <Typography>
                  {intl.formatMessage({
                    id: "task.includeCitation",
                    defaultMessage: "Include citation in answer"
                  })}
                </Typography>
              }
            />
          </Typography>
          {includeCitation && (
            <Typography component="span">
              <Link
                component="button"
                variant="body2"
                className={clsx(classes.fakeLink)}
                name="select-citation-btn"
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                  setOpenDialog(true);
                }}
                underline="hover"
              >
                <FormattedMessage
                  id="task.selectCitation"
                  defaultMessage="Select citation"
                />
              </Link>
            </Typography>
          )}
        </Box>
        <Box className={classes.wordLimit}>
          <Typography component="span" className={classes.wordLmt}>
            <FormControlLabel
              control={
                <TextField
                  variant="standard"
                  inputRef={wordLimitRef}
                  aria-label="open-answer-input"
                  id="standard-number"
                  className={classes.wordInput}
                  value={wordLimit >= 0 ? wordLimit : ""}
                  onChange={e => {
                    onWordLimit(e.target.value);
                  }}
                  onKeyPress={ev => {
                    if (ev.key === "Enter") {
                      // Do code here
                      wordLimitRef.current.blur();
                    }
                  }}
                  type="number"
                  InputLabelProps={{
                    shrink: true
                  }}
                  InputProps={{
                    readOnly: readOnly,
                    disableUnderline: true,
                    style: { height: "36px", width: "fit-content" }
                  }}
                />
              }
              label={intl.formatMessage({
                id: "task.wordLimit",
                defaultMessage: "Word Limit"
              })}
            />
          </Typography>
        </Box>
        <Concepts
          readOnly={readOnly}
          onChange={onConcepts}
          concepts={concepts}
        />
      </Box>
      <CitationDialog
        setOpenDialog={setOpenDialog}
        url={text.url}
        location={text.file_location}
        highlights={highlights}
        openDialog={openDialog}
        readOnly={readOnly}
        addHighlight={!readOnly && addHighlight}
        removeHighlight={!readOnly && removeHighlight}
        citationTitleFormattedMessage={
          <FormattedMessage id="text.mark" defaultMessage="Mark in Text" />
        }
      />
    </>
  );
}

export default OpenAnswer;
