// Dependencies
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { taskShape, submissionShape } from "../../../utils/propTypes";
import { useIntl } from "react-intl";
import clsx from "clsx";
import { SUBMISSION_STATUS } from "../../../consts";
import { calculateSubmissionStatus } from "./utills";

// Material UI
import makeStyles from "@mui/styles/makeStyles";
import { Chip } from "@mui/material";

// Styles
const useStyles = makeStyles(theme => ({
  chip: {
    cursor: "pointer"
  },
  chipLink: {
    cursor: "pointer"
  },
  missed: {
    color: theme.palette.primary.contrast,
    backgroundColor: theme.palette.error.main,
    "&:hover": { backgroundColor: theme.palette.error.dark },
    "&:focus": { backgroundColor: theme.palette.error.dark },
    "&:active": { backgroundColor: theme.palette.error.main }
  },
  late: {
    color: theme.palette.warning.contrast,
    backgroundColor: theme.palette.warning.main,
    "&:hover": { backgroundColor: theme.palette.warning.dark },
    "&:focus": { backgroundColor: theme.palette.warning.dark },
    "&:active": { backgroundColor: theme.palette.warning.main }
  },
  pending: {
    border: `1px solid ${theme.palette.text.primary}`,
    backgroundColor: "transparent",
    "&:hover": { backgroundColor: theme.palette.text.p4 },
    "&:focus": { backgroundColor: theme.palette.text.p4 }
  },
  latePending: {
    color: theme.palette.warning.main,
    border: `1px solid ${theme.palette.warning.main}`,
    backgroundColor: "transparent",
    "&:hover": { backgroundColor: theme.palette.text.p4 },
    "&:focus": { backgroundColor: theme.palette.text.p4 }
  },
  onTime: {
    color: theme.palette.primary.contrast,
    backgroundColor: theme.palette.secondary.main,
    "&:hover": { backgroundColor: theme.palette.secondary.dark },
    "&:focus": { backgroundColor: theme.palette.secondary.dark },
    "&:active": { backgroundColor: theme.palette.secondary.main }
  },
  sent: {
    color: theme.palette.primary.contrast,
    backgroundColor: theme.palette.primary.main,
    "&:hover": { backgroundColor: theme.palette.primary.dark },
    "&:focus": { backgroundColor: theme.palette.primary.dark },
    "&:active": { backgroundColor: theme.palette.primary.main }
  },
  add: {
    border: `1px solid ${theme.palette.primary.main}`,
    backgroundColor: "transparent",
    "&:hover": { backgroundColor: theme.palette.text.p4 },
    "&:focus": { backgroundColor: theme.palette.text.p4 }
  },
  reviewed: {
    backgroundColor: "#BDBDBD",
    color: "currentCollor"
  }
}));

export default function TaskStatusChip({
  task,
  submission,
  userRole,
  onClick
}) {
  //Hooks
  const classes = useStyles();
  const intl = useIntl();

  // Ephemeral State
  const [label, setLabel] = useState("");
  const [chipStyle, setChipStyle] = useState([]);

  // Variables
  const submitted = intl.formatMessage({
    id: "tasks.status.submitted",
    defaultMessage: "Submitted"
  });
  const missed = intl.formatMessage({
    id: "tasks.status.missed",
    defaultMessage: "Missed"
  });
  const pending = intl.formatMessage({
    id: "tasks.status.pending",
    defaultMessage: "Pending"
  });

  //Derived State
  const isTeacher = userRole === "Teacher";

  const submissionStatus = calculateSubmissionStatus(
    submission.due_date,
    submission.submission_date,
    task.original_due_date
  );

  // Behavior
  useEffect(() => {
    // Set the style
    switch (submissionStatus) {
      case SUBMISSION_STATUS.MISSED:
        setChipStyle("missed");
        break;

      case SUBMISSION_STATUS.LATE_PENDING:
        setChipStyle("latePending");
        break;

      case SUBMISSION_STATUS.LATE:
        setChipStyle("late");
        break;

      case SUBMISSION_STATUS.ON_TIME:
        setChipStyle("onTime");
        break;

      default:
        setChipStyle("pending");
    }

    // Set the label
    switch (submissionStatus) {
      case SUBMISSION_STATUS.MISSED:
        setLabel(missed);
        break;

      case SUBMISSION_STATUS.LATE:
        setLabel(submitted);
        break;

      case SUBMISSION_STATUS.ON_TIME:
        setLabel(submitted);
        break;

      default:
        setLabel(pending);
    }
  }, [isTeacher, missed, pending, submissionStatus, submitted]);

  // Render
  return (
    <Chip
      className={clsx(
        classes.chip,
        chipStyle === "late" && classes.late,
        chipStyle === "missed" && classes.missed,
        chipStyle === "latePending" && classes.latePending,
        chipStyle === "pending" && classes.pending,
        chipStyle === "onTime" && classes.onTime,
        chipStyle === "sent" && classes.sent
      )}
      label={label}
      size="small"
      onClick={onClick}
      clickable={true}
    />
  );
}

TaskStatusChip.propTypes = {
  task: PropTypes.shape(taskShape),
  submission: PropTypes.shape(submissionShape),
  userRole: PropTypes.oneOf(["Teacher", "Student"]).isRequired,
  onClick: PropTypes.func
};
