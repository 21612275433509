// Dependencies
import React from "react";
import PropTypes from "prop-types";

// Redux Dependencies

//Components

// Material UI
import {
  Box,
} from "@mui/system";
import {  DatePicker } from "@mui/x-date-pickers";
import makeStyles from '@mui/styles/makeStyles';


const useStyles = makeStyles(theme => ({}));

export default function DeadLineDatePicker({
  isOpen,
  setIsOpen,
  deadline,
  label,
  onChange,
  children
}) {
  //Hooks
  const classes = useStyles();

  // Redux State

  // Ephemeral State

  // Render
  return (
     <DatePicker
        open={isOpen}
        onOpen={() => setIsOpen(true)}
        onClose={() => setIsOpen(false)}
        label={label}
        PopperProps={{
          style: { zIndex: 1000000}}}

        inputFormat="d MMM yyyy"
        value={deadline}
        onChange={onChange}
        renderInput={({ inputRef, inputProps, InputProps }) => (
          <Box ref={inputRef} sx={{ display: 'flex', alignItems: 'center', position:'relative' }}>
            <input type="hidden"  />
            {children}
           </Box>
        )}
      />
  );
}

DeadLineDatePicker.propTypes = {
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  deadline: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};
