// Dependancies
import { combineEpics } from "redux-observable";

// Epics

// standard task
import { displayStandardTaskFabEpic } from "./standardTaskEpics/displayStandardTaskFabEpic";
import { submitStandardTaskEpic } from "./standardTaskEpics/submitStandardTaskEpic";
import { fetchSubmissionEpic } from "./fetchSubmissionEpic";
// Publish new task
import { publishTaskEpic } from "./publishTaskEpic";
import { fetchTeacherCourseTasksEpic } from "./fetchTeacherCourseTasksEpic";
import { fetchStudentCourseTasksEpic } from "./fetchStudentCourseTasksEpic";

export const tasksEpics = combineEpics(
  // general
  fetchSubmissionEpic,
  fetchTeacherCourseTasksEpic,
  fetchStudentCourseTasksEpic,

  // standard task
  displayStandardTaskFabEpic,
  submitStandardTaskEpic,

  // publish task
  publishTaskEpic
);
