// Dependencies
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useIntl, FormattedMessage } from "react-intl"; // We don't need both, use only the hook
import { useHistory } from "react-router-dom";
import clsx from "clsx";
import { isEmpty } from "lodash";
import { TASK } from "../../../consts";
import { motion, AnimatePresence, MotionConfig } from "framer-motion";
import { standardTaskAPI } from "../../../api";
import { useQuery } from "../../../hooks";

// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  updateTask,
  removeActiveTaskPerText
} from "../../../redux/firebaseMiddleware";
import { selectTask, selectSubmission } from "../../../redux/tasksSlice";
import { selectCourseByTaskId } from "../../../redux/coursesSlice";
import { selectQuestions } from "../../../redux/interactionsSlice";
import { selectIsImpersonation } from "../../../redux/userSlice";

// Components
import HighlightsAnswersToggler from "../TaskAnswer/HighlightsAnswersToggler";
import SubmitTaskModal from "../TaskAnswer/SubmitTaskModal";
import QuestionsAccordion from "../TaskAnswer/questions-accordion";
import { PangeaSpinner } from "../../SharedComponents";

import makeStyles from "@mui/styles/makeStyles";
import { Paper, Box, Typography, Divider, Button } from "@mui/material";
import useCreatePortal from "../../../hooks/useCreatePortal";

// Styles
const useStyles = makeStyles(theme => ({
  questionIndexContainer: {
    display: "flex",
    alignItems: "center"
  },
  carouselWrapper: {
    overflow: "hidden",
    flex: 1
  },
  carousel: {
    display: "flex",
    alignItems: "center"
  },
  questionIndex: {
    width: 48,
    height: 48,
    padding: 0,
    flexShrink: 0,
    marginLeft: "calc((25% - 48px) / 2)",
    marginRight: "calc((25% - 48px) / 2)",
    color: "white"
  },
  alignToStart: {
    marginRight: 0,
    marginLeft: theme.spacing(2)
  },
  selected: {
    border: "1px solid",
    borderColor: theme.palette.primary.main,
    color: theme.palette.primary.main
  },
  number: {
    fontFamily: "Crimson Pro",
    fontSize: 23,
    height: 38,
    color: "inherit"
  },
  carouselArrow: {
    color: "white",
    "&.Mui-disabled": {
      color: "white",
      opacity: 0.2
    }
  },
  root: {
    display: "flex",
    flexFlow: "column nowrap",
    backgroundColor: theme.palette.background.sidePanel,
    height: "100%",
    overflow: "auto",
    overflowX: "hidden",
    flexGrow: 1,
    borderLeft: "1px solid grey"
  },
  panelSubtitle: {
    whiteSpace: "pre-wrap",
    opacity: 0.7,
    color: "#FFFFFF"
  },
  taskTitle: {
    color: "white",
    fontSize: "20px",
    marginBottom: 11,
    marginTop: theme.spacing(0.5)
  },
  panelHeader: {
    color: "white",
    width: "100%",
    textAlign: "left",
    paddingInline: theme.spacing(2),
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(1),
    flexShrink: 0
  },
  divider: {
    backgroundColor: "#979797"
  },
  panelTitle: {
    color: "#bdbdbd",
    fontSize: "12px",
    fontWeight: "300",
    fontStyle: "normal",
    letterSpacing: "1px",
    lineHeight: "24px",
    marginTop: "8px",
    textTransform: "uppercase"
  },
  nextButton: {
    marginTop: "16px",
    fontSize: "13px",
    fontWeight: "bold",
    letterSpacing: "1px",
    color: "#ffffff",
    backgroundColor: "#168fee",
    "&:hover": {
      backgroundColor: "#1172be"
    },
    "&:disabled": {
      backgroundColor: theme.palette.action.disabledBackground,
      color: theme.palette.text.disabled
    }
  },
  questionTitle: {
    fontSize: "0.75rem",
    paddingLeft: "16px",
    paddingRight: "16px",
    margin: "20px 0",
    textTransform: "uppercase",
    color: "#BCD9F1"
  },
  highlightSwitch: {
    paddingInline: 24,
    color: "#FFFFFF",
    paddingBlock: 24
  },
  highlightSwtichSection: {
    overflow: "hidden",
    flexShrink: 0
  }
}));

export default function StandardTaskSidebar({ rendition }) {
  //Hooks
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const intl = useIntl();
  const { submission_id } = useQuery();

  const ActionButtonPortal = useCreatePortal(
    document && document.getElementById("global-action-btn")
  );

  //Redux State
  const isImpersonation = useSelector(selectIsImpersonation);
  const submission = useSelector(state =>
    selectSubmission(state, Number(submission_id))
  );
  const task = useSelector(state => selectTask(state, submission.task_id));
  const step = useSelector(state => state.task.step);
  const highlightViewOn = useSelector(state => state.task.highlightViewOn);
  const questions = useSelector(state =>
    selectQuestions(state, submission.task_id)
  );
  const selectedInteractionId = useSelector(
    state => state.interactions.selectedInteractionId
  );
  const answers = useSelector(state => state.task.answers);

  // Ephemeral State
  const [dialogOpen, setDialogOpen] = useState(false);
  const [higlightsDone, setHiglightsDone] = useState(false);
  const [answersDone, setAnswersDone] = useState(false);

  // Derived State

  //Behavior
  useEffect(() => {
    if (questions.length) {
      standardTaskAPI.updateSelectedQuestionId(
        questions[0].id,
        submission.task_id
      );
    }
    if (selectedInteractionId)
      standardTaskAPI.updateSelectedQuestionId(
        selectedInteractionId,
        submission.task_id
      );
  }, [questions.length]);

  const isActive =
    submission.status === "Pending" || submission.status === "Active";
  const course = useSelector(state =>
    selectCourseByTaskId(state, submission.task_id)
  );

  return (
    <>
      {/* TODO: this should be in the main content not in the sidebar */}
      <ActionButtonPortal>
        {isActive && (
          <Button
            onClick={() => setDialogOpen(true)}
            data-test-id="submit-task-button"
            size="small"
          >
            {intl.formatMessage({
              id: "task.submission.submit",
              defaultMessage: "Submit"
            })}
          </Button>
        )}

        {!isActive && (
          <Button
            onClick={() => {
              history.push(`/tasks?course_id=${course.id}`);
            }}
            data-test-id="submit-task-button"
            size="small"
          >
            {intl.formatMessage({
              id: "gr.confirm.btn",
              defaultMessage: "Done"
            })}
          </Button>
        )}
      </ActionButtonPortal>
      {!isEmpty(submission) ? (
        <>
          <SubmitTaskModal
            taskId={submission.task_id}
            modalVisible={dialogOpen}
            setModalVisible={setDialogOpen}
            validationStatue={status => {
              const highlightIncomplete = status.find(item => !item.highlight);
              const ansewerIncomplete = status.find(item => !item.answer);
              setHiglightsDone(!highlightIncomplete);
              setAnswersDone(!ansewerIncomplete);
            }}
          />
          <Paper className={classes.root} elevation={0} square>
            <Box
              className={clsx(classes.panelHeader)}
              data-test-id="standard-task-sidebar"
            >
              <Typography className={classes.panelTitle} color="textPrimary">
                <FormattedMessage id="appBar.task" defaultMessage="task" />
              </Typography>
              <Typography
                className={clsx(classes.taskTitle)}
                color="textPrimary"
              >
                {task.name}
              </Typography>

              <Typography
                className={classes.panelSubtitle}
                color="textPrimary"
                variant="body2"
              >
                {task.task_description}
              </Typography>
            </Box>
            <Divider className={classes.divider} />
            <HighlightsAnswersToggler
              firstQuestionId={questions[0]?.id}
              higlightsDone={higlightsDone}
              answersDone={answersDone}
            />

            <AnimatePresence initial={true}>
              {/* 22/03/23 - For Mentor chat update we've decided to drop highlight view toggle button in step 1 (highlight) */}
              {/* {step === "highlight" && (
                <MotionConfig transition={{ type: "spring", bounce: 0 }}>
                  <motion.section
                    key="content"
                    initial="collapsed"
                    animate="open"
                    exit="collapsed"
                    className={classes.highlightSwtichSection}
                    variants={{
                      open: {
                        opacity: 1,
                        height: "auto"
                      },
                      collapsed: {
                        opacity: 0,
                        height: 0
                      }
                    }}
                  >
                    <Divider className={classes.divider} />
                    <HighlightViewSwitch
                      className={classes.highlightSwitch}
                      onChange={() => {
                        if (isImpersonation) {
                          dispatch(setHighlightViewOn(!highlightViewOn));
                        } else {
                          dispatch(
                            updateTask({
                              highlightViewOn: !highlightViewOn
                            })
                          );
                        }
                      }}
                      on={highlightViewOn}
                    />
                  </motion.section>
                </MotionConfig>
              )} */}
            </AnimatePresence>
            <Divider className={classes.divider} />
            {questions.map((question, questionIndex) => (
              <QuestionsAccordion
                key={question.id}
                forceQuestionSelection={step === "answer"}
                disableActions={
                  submission.status === TASK.SUBMISSION_STATUS.SUBMITTED
                }
                question={question}
                showInText={step === "highlight" ? "inline" : "dialog"}
                onCardDrop={(cardIndex, questionIndex, targetQuestionIndex) => {
                  const answersCopy = answers.reduce((acc, answer, i) => {
                    if (i === targetQuestionIndex) {
                      acc.push(
                        Object.assign({}, answer, {
                          quotes: (answer.quotes || []).concat([
                            answers[questionIndex].quotes[cardIndex]
                          ])
                        })
                      );
                    } else {
                      acc.push(answer);
                    }
                    return acc;
                  }, []);
                  dispatch(
                    updateTask({
                      answers: answersCopy,
                      selectedQuestion: targetQuestionIndex
                    })
                  );
                }}
                rendition={rendition}
              />
            ))}
          </Paper>
        </>
      ) : (
        <PangeaSpinner />
      )}
    </>
  );
}

StandardTaskSidebar.propTypes = {
  rendition: PropTypes.object
};
