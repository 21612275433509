import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";
import "firebase/storage";
import "firebase/remote-config";
import "firebase/analytics";
import { captureException } from "./utils/errorHandlers";
const firebaseConfig = getFirebaseConfig();
export const projectId = firebaseConfig.options.projectId;
export const crossed = firebaseConfig.crossed;
export const isEmulated = firebaseConfig.isEmulated;
firebase.initializeApp(firebaseConfig.options);

//domain issues with cookie
if (firebaseConfig.measurementId) {
  window.dataLayer = window.dataLayer || [];
  window.gtag = (...a) => window.dataLayer.push(a);
  window.gtag("config", firebaseConfig.measurementId, {
    cookie_domain: window.location.hostname,
    cookie_flags: "SameSite=None;Secure"
  });

  firebase.analytics();
}

export const firebaseApp = firebase;
export const auth = firebase.auth();

const region =
  firebaseConfig.options.projectId === "alethea-dda54"
    ? "us-central1"
    : "europe-west3";
export const firebaseFunctions = firebase.app().functions(region);
if (isEmulated) firebaseFunctions.useEmulator("localhost", 5001);

export const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
googleAuthProvider.setCustomParameters({ prompt: "select_account" });

export const facebookAuthProvider = new firebase.auth.GoogleAuthProvider();
export const firestore = firebase.firestore();
export const remoteConfig = firebase.remoteConfig();
export const getRecaptchaKey = () =>
  firebase.remoteConfig().getValue("recaptcha_key").asString();
export const env = "staging";
remoteConfig.settings.minimumFetchIntervalMillis = 300000;

remoteConfig.defaultConfig = JSON.parse(
  localStorage.getItem("conf.lastRemoteConfig") || '{"version": "5"}'
);

remoteConfig.fetchAndActivate().then(() => {
  localStorage.setItem(
    "conf.lastRemoteConfig",
    JSON.stringify(remoteConfig.defaultConfig)
  );
});

export function getFirebaseConfig() {
  const firebaseConfig = {
    stage: {
      apiKey: "AIzaSyBIu1VSZ1DI_5TGkyHw-MJb2urhqVe7g1g",
      authDomain: "pangea-stage.firebaseapp.com",
      projectId: "pangea-stage",
      storageBucket: "pangea-stage.appspot.com",
      messagingSenderId: "247009010465",
      appId: "1:247009010465:web:7d44ef0cdda8ec5a8a5460"
    },
    demo: {
      apiKey: "AIzaSyAlz4k4hwaq_bPymSbJoPQPWr-xofqITUs",
      authDomain: "pangea-demo-3d15d.firebaseapp.com",
      projectId: "pangea-demo",
      storageBucket: "pangea-demo.appspot.com",
      messagingSenderId: "867375218514",
      appId: "1:867375218514:web:efca0d2a0f6124eea4448b"
    },
    prod: {
      apiKey: "AIzaSyAKxk1aU1FAxsZiYmbFl4QoIucFMTDCc0s",
      authDomain: "alethea-dda54.firebaseapp.com",
      projectId: "alethea-dda54",
      storageBucket: "alethea-dda54.appspot.com",
      messagingSenderId: "757420351402",
      appId: "1:757420351402:web:0095e4780ee5bf1c7455d3",
      measurementId: "G-DVM7WFWL9V"
    }
  };
  const isEmulated = getEmulated();
  //eslint-disable-line no-restricted-globals
  const { search, hostname } = window.location;

  //detect env from domain/subdomain, default to 'prod'
  let subdomain = (hostname.match(/^(demo|stage|local)/) || ["prod"])[0];
  if (subdomain == "local") subdomain = "stage";

  //if chabnnel - take the projectId prefix of the firebae url
  const projectIdMatch = hostname.match(/(.*)--.*/);
  if (
    projectIdMatch &&
    projectIdMatch[1] &&
    projectIdMatch[1] === firebaseConfig.stage.projectId
  ) {
    subdomain = "stage";
  }
  if (
    projectIdMatch &&
    projectIdMatch[1] &&
    projectIdMatch[1] === firebaseConfig.demo.projectId
  ) {
    subdomain = "demo";
  }

  let backend = subdomain;

  //detect if developer/tester directs to override
  const qsBackend = (search.match(/[?&]backend=([^&]*)&?/) || [])[1];
  if (typeof qsBackend !== "undefined") {
    if (qsBackend == "" || qsBackend == backend) {
      localStorage.removeItem("conf.backend");
    } else {
      backend = qsBackend;
      localStorage.setItem("conf.backend", backend);
    }
  }

  backend = localStorage.getItem("conf.backend") || backend;

  //pull selected config-set, default to prod
  let envConfig = firebaseConfig[backend];
  if (!envConfig) {
    backend = "prod";
    envConfig = firebaseConfig[backend];
  }

  //pull local overrides from conf.dev
  const json = localStorage.getItem("conf.dev");
  let localOverrides = {};
  if (json) {
    try {
      localOverrides = JSON.parse(json);
      console.warn(
        "lodaded local overriding values for firebase",
        localOverrides
      );
    } catch (err) {
      captureException(err, `Failed to parse conf.dev ${json}`);
    }
  }

  const options = {
    ...envConfig,
    ...localOverrides
  };

  return {
    options,
    measurementId: options.measurementId,
    crossed: backend != subdomain && backend,
    isEmulated
  };
}

function getEmulated() {
  //if found in QS - move it to cookie
  const [, emulated] =
    window.location.search.match(/[?&]emulated=(true|false)&?/) || [];
  if (emulated) localStorage.setItem("conf.emulated", emulated);

  //true only when the item has been set explicitly to "true"
  return "true" == localStorage.getItem("conf.emulated");
}

export const storage = firebase.storage();
export const httpCallables = {
  createCourseText: firebaseFunctions.httpsCallable("texts-createCourseText"),
  readConversationByInteractionId: firebaseFunctions.httpsCallable(
    "interaction-readConversationByInteractionId"
  ),
  createLearningSession: firebaseFunctions.httpsCallable(
    "sessions-createLearningSession"
  ),
  ementoringInteraction: firebaseFunctions.httpsCallable(
    "interactions-ementoringInteraction"
  ),
  getUser: firebaseFunctions.httpsCallable("users-getUser"),

  readFailedQueuedTasks: firebaseFunctions.httpsCallable(
    "adminFunctions-readFailedQueuedTasks"
  ),
  impersonateUser: firebaseFunctions.httpsCallable(
    "adminFunctions-impersonate"
  ),
  stopImpersonatingUser: firebaseFunctions.httpsCallable(
    "adminFunctions-stopImpersonate"
  ),
  readDailyCourseActivityReport: firebaseFunctions.httpsCallable(
    "reports-readDailyCourseActivityReport"
  ),
  readAggregatedCourseActivity: firebaseFunctions.httpsCallable(
    "reports-readAggregatedCourseActivity"
  ),
  readDailyUsersActivityReport: firebaseFunctions.httpsCallable(
    "reports-readDailyUsersActivityReport"
  ),
  readTasksAndSubmissionsInRange: firebaseFunctions.httpsCallable(
    "reports-readTasksAndSubmissionsInRange"
  ),
  generateUserActivityReport: firebaseFunctions.httpsCallable(
    "reports-generateUserActivityReport"
  ),
  readCourseUsers: firebaseFunctions.httpsCallable("courses-readCourseUsers"),
  readTextComments: firebaseFunctions.httpsCallable(
    "realtimeInteractions-readTextComments"
  ),
  updateRealtimeInteraction: firebaseFunctions.httpsCallable(
    "realtimeInteractions-updateRealtimeInteraction"
  ),
  deleteRealtimeInteraction: firebaseFunctions.httpsCallable(
    "realtimeInteractions-deleteRealtimeInteraction"
  ),
  createRealtimeInteraction: firebaseFunctions.httpsCallable(
    "realtimeInteractions-createRealtimeInteraction"
  ),
  readUserCoursesAndTexts: firebaseFunctions.httpsCallable(
    "courses-readUserCoursesAndTexts"
  ),
  readTaskDraft: firebaseFunctions.httpsCallable("tasks-readTaskDraft"),
  taskFunctions: firebaseFunctions.httpsCallable("tasks-taskFunctions"),
  submitPeerReviewReply: firebaseFunctions.httpsCallable(
    "courses-submitPeerReviewReply"
  ),
  submitStandardTask: firebaseFunctions.httpsCallable(
    "tasks-updateTaskSubmissionToSubmitted"
  ),
  readActiveGrTask: firebaseFunctions.httpsCallable("tasks-readActiveGrTask"),
  generateCourseActivityReport: firebaseFunctions.httpsCallable(
    "reports-generateCourseActivityReport"
  ),
  generateCourseActivityReport_OLD_VERSION: firebaseFunctions.httpsCallable(
    "reports_old-generateCourseActivityReport_OLD_VERSION"
  ),
  interactionFunctions: firebaseFunctions.httpsCallable(
    "interactions-interactionFunctions"
  ),
  textFunctions: firebaseFunctions.httpsCallable("texts-textFunctions"),
  deleteCourseText: firebaseFunctions.httpsCallable("texts-deleteCourseText"),
  resetMentorForCourseUser: firebaseFunctions.httpsCallable(
    "adminFunctions-resetMentorForCourseUser"
  )
};
