// Dependancies
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { firebaseApp } from "./firebase";
import { injectIntl, useIntl } from "react-intl";
import { motion, AnimatePresence } from "framer-motion";
import clsx from "clsx";
import { HIGHLIGHTS, SECONDARY_SIDEBAR_ACTION_BUTTONS } from "./consts";
import { useQuery } from "./hooks";
import {
  handleCommentPanel,
  handleThumbnailPanel
} from "./components/SharedComponents/buttons/utils";

// Redux dependancies
import { useSelector, useDispatch } from "react-redux";
import {
  selectGrStepOneHighlights,
  selectedQuestionHighlights
} from "./redux/interactionsSlice";
import {
  selectTask,
  selectSubmission,
  setSelectedTaskId
} from "./redux/tasksSlice";
import { selectCourseByTextId } from "./redux/coursesSlice";
import {
  setSelectedTextId,
  setTextUrl,
  selectCurrentText
} from "./redux/textsSlice";
import { setBreadcrumbs } from "./redux/readerActionsSlice";
import { isPdfSelector } from "./redux/textsSlice";

// Components
import ToggleBtnConstructor from "./components/SharedComponents/buttons/toggleBtnConstructor";
import { GrInstructions } from "./components/guidedReading/GrInstructions";
import CardsManager from "./components/poc/CardsManager";
import SummaryManager from "./components/poc/summary/SummaryManager";
import BookView from "./components/reader/BookView";
import { ScrollBox, PangeaSpinner } from "./components/SharedComponents";

// Material UI
import { Box } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import {
  selectIsComments,
  selectIsThumbnails,
  selectTextDirection
} from "./redux/firestoreSelectors";
import useCreatePortal from "./hooks/useCreatePortal";
import { PdfControls } from "./components/menus/PdfControls";
import HeaderToolbar from "./components/menus/HeaderToolbar";
import { pdfThumbnailsIconClicked } from "./redux/layoutSlice";

// Styles
const useStyles = makeStyles(theme => {
  return {
    readerViewContainer: {
      position: "relative",
      flex: 1,
      height: "100%",
      justifyContent: "center"
    },
    innerWrapper: {
      position: "absolute",
      width: "100%",
      height: "100%",
      top: 0,
      left: 0
    },
    dirLtr: {
      direction: "ltr"
    },
    instructionsWrapper: {
      position: "absolute",
      top: 0,
      left: 0,
      zIndex: 2,
      width: "100%",
      height: "100%"
    },
    stageHeader: {
      display: "flex",
      justifyContent: "center",
      paddingTop: theme.spacing(2)
    },
    stageHeaderContent: {
      display: "flex",
      flexDirection: "column",
      maxWidth: 700,
      width: "66%",
      justifyContent: "flex-start"
    },
    subTitle: {
      marginTop: theme.spacing(0.5),
      marginBottom: theme.spacing(4),
      opacity: 0.6
    }
  };
});

function ReaderView({
  grSettings = {
    showSelfFeedback: true
  },
  color = "secondary"
}) {
  // Hooks
  const intl = useIntl();
  const dispatch = useDispatch();
  const classes = useStyles();
  const { text_id, submission_id } = useQuery();
  const ThumbnailsPortal = useCreatePortal(
    document && document.getElementById("global-thumbnails-btn")
  );
  const CommentsPortal = useCreatePortal(
    document && document.getElementById("global-comments-btn")
  );
  const PdfControlPortal = useCreatePortal(
    document && document.getElementById("global-pdfControl-btn")
  );
  const HeaderToolbarPortal = useCreatePortal(
    document && document.getElementById("global-headerToolBar-btn")
  );
  //Redux State
  const PrimarySidebarWidth = useSelector(
    state => state.layout.primarySidebarWidth
  );
  const SecondarySidebarWidth = useSelector(
    state => state.layout.secondarySidebarWidth
  );
  const activeGrTaskId = useSelector(state => state.tasks.activeGrTaskId);
  const action = useSelector(state => {
    return state.readerActions.persistentActionState.actionBar;
  });
  const isLoading = useSelector(state => {
    return state.readerActions.isLoading;
  });
  const showAnswers = useSelector(state => state.gr.showAnswers);
  const showHighlights = useSelector(state => state.gr.showHighlights);
  const grMode = useSelector(state => state.gr.mode);
  const stage = useSelector(state => state.gr.stage);
  const actionBar = useSelector(
    state => state.readerActions.persistentActionState.actionBar
  );
  const textHighlights = useSelector(selectGrStepOneHighlights);
  const questionHighlights = useSelector(selectedQuestionHighlights);
  const detailedInstructions = useSelector(
    state => state.gr.detailedInstructions
  );
  const taskStep = useSelector(state => state.task.step);
  const taskShowHighlights = useSelector(state => state.task.highlightViewOn);
  const isPdf = useSelector(isPdfSelector);

  const submission = useSelector(state =>
    selectSubmission(state, Number(submission_id))
  );
  const isCommentsInSecondarySidebar = useSelector(state =>
    selectIsComments(state)
  );
  const isThumbnailsInSecondarySidebar = useSelector(state =>
    selectIsThumbnails(state)
  );
  const task = useSelector(state => selectTask(state, submission.task_id));
  const selectedText = useSelector(selectCurrentText);
  const course = useSelector(selectCourseByTextId);

  const [readText, setReadText] = useState("");
  const [showBook, setShowBook] = useState(false);

  const textDirection = useSelector(state => selectTextDirection(state));
  const isThumbnailsInView = useSelector(state => selectIsThumbnails(state));

  // Derived State
  const isTask =
    actionBar === "task" ||
    (actionBar === "" && window.location.pathname.includes("task"));

  const isGr = action === "poc";
  const disabled = submission.status === "Submitted";

  // Behavior
  //Set breadcrumbs effect
  const author =
    !task || !task.text_author
      ? selectedText && selectedText.author
      : task.text_author;

  const text_title =
    !task || !task.text_name
      ? selectedText && selectedText.name
      : task.text_name;

  useEffect(() => {
    if (!isPdf && isThumbnailsInView) dispatch(pdfThumbnailsIconClicked());
  }, []);

  useEffect(() => {
    if (text_id) dispatch(setSelectedTextId(Number(text_id)));
    else if (task.text_id) dispatch(setSelectedTextId(task.text_id));
  }, [dispatch, task.text_id, text_id]);

  useEffect(() => {
    if (!task.task_id) return;
    dispatch(setSelectedTaskId(task.task_id));
  }, [dispatch, task.task_id]);

  useEffect(() => {
    if (!selectedText.id) return;
    //TODO: check if we can also skip this fetch if there is selectedText.url
    firebaseApp
      .storage()
      .ref("courseTexts/" + selectedText.course_id)
      .child(selectedText.file_url)
      .getDownloadURL()
      .then(url => {
        dispatch(setTextUrl({ url, text_id: selectedText.id }));
      });
  }, [
    dispatch,
    selectedText.course_id,
    selectedText.file_url,
    selectedText.id
  ]);

  useEffect(() => {
    let parts = [];
    task.id &&
      actionBar === "task" &&
      parts.push({
        text: intl.formatMessage({
          id: "appBar.tasks",
          defaultMessage: "Tasks"
        }),
        url: "/tasks",
        brightText: !!task
      });

    parts.push({
      text: course.name,
      url: task.id
        ? `/tasks?course_id=${course.id}&task_id=${task.id}`
        : `/library?course_id=${course.id}`,
      brightText: !!task
    });

    task.name &&
      actionBar === "task" &&
      parts.push({
        url: `/tasks?course_id=${course.id}`,
        text: task.name,
        brightText: !!task
      });

    parts.push({
      url: task.id
        ? `/tasks?course_id=${course.id}`
        : `/library?course_id=${course.id}`,
      text: author + " - " + text_title,
      brightText: !!task
    });
    dispatch(
      setBreadcrumbs({
        breadcrumbs: parts,
        blue: isTask,
        showTextMenu: true
      })
    );
    /* eslint-disable */
  }, [
    task.id,
    task.name,
    course.name,
    course.id,
    author,
    text_title,
    actionBar
  ]);
  /* eslint-enable */

  const renderMainScreen = () => {
    if (submission_id && !submission.id) {
      return <PangeaSpinner />;
    }

    switch (true) {
      case grMode === "full" && stage === 3:
      case grMode === "light" && stage === 1 && showAnswers && actionBar !== "":
        return (
          <ScrollBox>
            <CardsManager
              color={color}
              context={HIGHLIGHTS.CONTEXT.ANSWERS} // TODO: find a way to remove this prop. verry confusing...
              cards={questionHighlights}
              readText={{
                url:
                  (readText && readText.url) ||
                  (selectedText && selectedText.url),
                file_location:
                  (readText && readText.file_location) ||
                  (selectedText && selectedText.file_location)
              }}
              disable={submission.status === "Submitted"}
            />
          </ScrollBox>
        );

      case grMode === "light" &&
        stage === 0 &&
        showHighlights &&
        actionBar !== "":
      case grMode === "full" && stage === 1:
        return (
          <ScrollBox>
            <CardsManager
              color={color}
              context={HIGHLIGHTS.CONTEXT.HIGHLIGHTS} // TODO: find a way to remove this prop. verry confusing...
              cards={textHighlights}
              readText={{
                url:
                  (readText && readText.url) ||
                  (selectedText && selectedText.url),
                file_location:
                  (readText && readText.file_location) ||
                  (selectedText && selectedText.file_location)
              }}
              disable={submission.status === "Submitted"}
            />
          </ScrollBox>
        );

      case grMode === "light" && stage === 0:
      case grMode === "light" && stage === 1:
      case grMode === "full" && [0, 2].includes(stage):
      case actionBar === "":
        return null; // Bookview is rendered. null is returned to prevent flash rendering of next cases.
      case grMode === "full" &&
        stage === 4 &&
        (isGr || task.task_type === "guidedReading"):
      case grMode === "light" &&
        stage === 2 &&
        (isGr || task.task_type === "guidedReading"):
        return (
          <SummaryManager
            color={activeGrTaskId ? "primary" : "secondary"}
            isTaskSubmitted={submission?.status === "Submitted"}
            showSelfFeedback={grSettings.showSelfFeedback}
          />
        );

      default:
        return <PangeaSpinner />;
    }
  };

  useEffect(() => {
    switch (true) {
      case task.task_type === "peerReview":
        setShowBook(false);
        break;
      case action === "":
        setShowBook(true);
        break;
      case action === "poc" &&
        grMode === "light" &&
        !showHighlights &&
        stage === 0:
      case action === "poc" &&
        grMode === "light" &&
        !showAnswers &&
        stage === 1:
      case action === "poc" && grMode === "full" && stage < 3:
      case action === "task" && taskStep === "highlight" && !taskShowHighlights:
        setShowBook(true);
        break;
      default: {
        setShowBook(false);
        break;
      }
    }
  }, [
    taskStep,
    taskShowHighlights,
    action,
    actionBar,
    grMode,
    stage,
    showHighlights,
    showAnswers,
    task
  ]);

  // TODO: 8/9/22 after seperating tasks add if(!text_id) redirect to library
  return (
    <Box
      className={clsx(classes.readerViewContainer, classes.dirLtr)}
      style={{
        width: `calc(100vw - ${PrimarySidebarWidth}px - ${SecondarySidebarWidth}px)`
      }}
    >
      {isLoading ? (
        <PangeaSpinner />
      ) : (
        <>
          {isPdf && showBook && (
            <>
              <ThumbnailsPortal>
                <ToggleBtnConstructor
                  type={SECONDARY_SIDEBAR_ACTION_BUTTONS.THUBMNAILS}
                  handleBtnAction={handleThumbnailPanel}
                  secondarySideBarMode={isThumbnailsInSecondarySidebar}
                />
              </ThumbnailsPortal>
              <PdfControlPortal>
                <PdfControls />
              </PdfControlPortal>
            </>
          )}
          {showBook && (
            <>
              <CommentsPortal>
                <ToggleBtnConstructor
                  type={SECONDARY_SIDEBAR_ACTION_BUTTONS.COMMENTS}
                  handleBtnAction={handleCommentPanel}
                  secondarySideBarMode={isCommentsInSecondarySidebar}
                />
              </CommentsPortal>
              <HeaderToolbarPortal>
                <HeaderToolbar />
              </HeaderToolbarPortal>
            </>
          )}
          <AnimatePresence>
            {detailedInstructions && (
              <motion.div
                className={classes.instructionsWrapper}
                initial={{ x: textDirection === "rtl" ? "100%" : "-100%" }}
                animate={{ x: "0" }}
                exit={{ x: textDirection === "rtl" ? "100%" : "-100%" }}
                transition={{ type: "spring", damping: 20 }}
              >
                <GrInstructions />
              </motion.div>
            )}
            {(action !== "poc" || !detailedInstructions) && !showBook && (
              <motion.div
                key="main-screen"
                className={classes.innerWrapper}
                initial={{ opacity: 0 }}
                exit={{
                  opacity: 0,
                  transition: {
                    duration: 0
                  }
                }}
                animate={{
                  opacity: 1
                }}
              >
                {renderMainScreen()}
              </motion.div>
            )}
          </AnimatePresence>
          <motion.div
            className={classes.innerWrapper}
            animate={{
              zIndex: showBook && 0,
              opacity: showBook ? 1 : 0,
              transitionEnd: {
                zIndex: !showBook && -1
              }
            }}
            style={{
              pointerEvents: showBook ? "all" : "none"
            }}
            transition={{ duration: showBook ? 0.5 : 0 }}
          >
            <BookView
              readOnly={submission.status === "Submitted"}
              minimalBar={false}
              task={action === "task" && task}
              readText={task ? readText : selectedText}
              disableInteractions={disabled}
              isVisible={showBook}
            />
          </motion.div>
        </>
      )}
    </Box>
  );
}

ReaderView.propTypes = {
  grSettings: PropTypes.shape({
    showSelfFeedback: PropTypes.bool
  }),
  color: PropTypes.oneOf(["primary", "secondary"])
};

export default injectIntl(ReaderView);
