// Dependencies
import React from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";

// Redux dependencies

//Components

// Material UI

import makeStyles from "@mui/styles/makeStyles";
import { CardActions, Link, Box } from "@mui/material";

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center"
  },
  expandButton: {
    opacity: 1,
    transition: ".2s ease-out",
    textDecoration: "none"
  },
  cardActions: {
    padding: 0
  }
}));

export default function InteractionCardFooter(props) {
  const {
    // Props that are passed implicitly from the parent Card component
    color,
    dispatch,
    interaction,
    collapseStatus,

    // Other props
    children
  } = props;

  // Hooks
  const classes = useStyles();
  const intl = useIntl();

  // Ephemeral state

  // Derived state
  const isDisabled = collapseStatus === "DISABLED";
  const isCollapsed = collapseStatus === "COLLAPSED";

  return (
    <Box className={classes.container}>
      <Box>
        {!isDisabled && (
          <Link
            edge="start"
            component="button"
            variant="subtitle2"
            color={color}
            className={classes.expandButton}
            onClick={() => {
              dispatch({
                type: "toggleCollapseStatus",
                payload: isCollapsed ? "EXPANDED" : "COLLAPSED"
              });
            }}
          >
            {intl.formatMessage({
              id: isCollapsed ? "show.text" : "showLess.text",
              defaultMessage: isCollapsed ? "Show more" : "Show less"
            })}
          </Link>
        )}
      </Box>
      <CardActions className={classes.cardActions} disableSpacing>
        {React.Children.map(children, child => {
          return React.cloneElement(child, { interaction });
        })}
      </CardActions>
    </Box>
  );
}

InteractionCardFooter.propTypes = {
  color: PropTypes.string,
  dispatch: PropTypes.func,
  interaction: PropTypes.object,
  status: PropTypes.string,
  children: PropTypes.node,
  collapseStatus: PropTypes.string
};
