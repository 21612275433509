// Dependencies
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";

// Redux dependencies

// Components

import makeStyles from "@mui/styles/makeStyles";
import {
  Table,
  TableHead,
  TableContainer,
  TableCell,
  TableRow,
  Paper,
  TableBody,
  List,
  ListItem
} from "@mui/material";
import { scrollBox } from "../../SharedComponents";
import { httpCallables } from "../../../firebase";

// Styles
const useStyles = makeStyles(theme => ({
  container: {
    height: "100%",
    width: "100%",
    display: "flex",
    position: "relative",
    padding: theme.spacing(4)
  },
  tableContainer: {}
}));

function FailedQueuedTasks() {
  //Hooks
  const classes = useStyles();
  const intl = useIntl();

  //Ephemeral State
  const [failedTasks, setFailedTasks] = useState([]);
  //Variables

  //Behavior
  useEffect(() => {
    httpCallables.readFailedQueuedTasks().then(({ data }) => {
      const { success } = data;
      if (success) {
        const { offline_tasks } = JSON.parse(data.payload);
        setFailedTasks(offline_tasks);
      }
    });
  }, []);

  console.log(failedTasks);
  //Render

  return (
    <scrollBox className={classes.container}>
      <TableContainer className={classes.tableContainer} component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>id</TableCell>
              <TableCell>Created at</TableCell>
              <TableCell>Queue</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Payload</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Location</TableCell>
              <TableCell>Project</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {failedTasks.map(task => {
              const payload = Object.entries(task.payload);
              const createdAt = new Date(task.createdAt).toLocaleDateString();

              return (
                <TableRow
                  key={task.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {task.id}
                  </TableCell>
                  <TableCell>{createdAt}</TableCell>
                  <TableCell>{task.queue}</TableCell>
                  <TableCell>{task.type}</TableCell>
                  <TableCell>
                    {payload.length ? (
                      <List>
                        {payload.map(([key, value]) => (
                          <ListItem>{`${key}: ${value}`}</ListItem>
                        ))}
                      </List>
                    ) : (
                      "empty"
                    )}
                  </TableCell>
                  <TableCell>{task.status}</TableCell>
                  <TableCell>{task.location}</TableCell>
                  <TableCell>{task.project}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </scrollBox>
  );
}

export default FailedQueuedTasks;
