//helper functions from https://github.com/agentcooper/react-pdf-highlighter +
//my own incomplete comparePositionRects

import PdfCFI from "./pdf-cfi";

export const getDocument = elm => (elm || {}).ownerDocument || document;

export const getWindow = elm => (getDocument(elm) || {}).defaultView || window;

export const isHTMLElement = elm =>
  elm instanceof HTMLElement || elm instanceof getWindow(elm).HTMLElement;

export const getPageFromElement = target => {
  const node = target.closest("[data-page-number]");

  if (!node || !isHTMLElement(node)) {
    return null;
  }

  const number = Number(node.dataset.pageNumber);

  return { node, number };
};

export const getPagesFromRange = range => {
  const startParentElement = range.startContainer.parentElement;
  const endParentElement = range.endContainer.parentElement;

  if (!isHTMLElement(startParentElement) || !isHTMLElement(endParentElement)) {
    return [];
  }

  const startPage = getPageFromElement(startParentElement);
  const endPage = getPageFromElement(endParentElement);

  if (!startPage?.number || !endPage?.number) {
    return [];
  }

  if (startPage.number === endPage.number) {
    return [startPage];
  }

  if (startPage.number === endPage.number - 1) {
    return [startPage, endPage];
  }

  const pages = [];

  let currentPageNumber = startPage.number;

  const document = startPage.node.ownerDocument;

  while (currentPageNumber <= endPage.number) {
    const currentPage = getPageFromElement(
      document.querySelector(`[data-page-number='${currentPageNumber}'`)
    );
    if (currentPage) {
      pages.push(currentPage);
    }
    currentPageNumber++;
  }

  return pages;
};

export const compareRects = (A, B) => {
  const top = (A.pageNumber || 0) * A.top - (B.pageNumber || 0) * B.top;

  if (top === 0) {
    return A.left - B.left;
  }

  return top;
};

export const getBoundingRect = clientRects => {
  const rects = Array.from(clientRects).map(rect => {
    const { left, top, width, height, pageNumber } = rect;

    const X0 = left;
    const X1 = left + width;

    const Y0 = top;
    const Y1 = top + height;

    return {
      X0,
      X1,
      Y0,
      Y1,
      pageNumber
    };
  });

  let firstPageNumber = Number.MAX_SAFE_INTEGER;

  rects.forEach(rect => {
    firstPageNumber = Math.min(
      firstPageNumber,
      rect.pageNumber ?? firstPageNumber
    );
  });

  const rectsWithSizeOnFirstPage = rects.filter(
    rect =>
      (rect.X0 > 0 || rect.X1 > 0 || rect.Y0 > 0 || rect.Y1 > 0) &&
      rect.pageNumber === firstPageNumber
  );

  const optimal = rectsWithSizeOnFirstPage.reduce((res, rect) => {
    return {
      X0: Math.min(res.X0, rect.X0),
      X1: Math.max(res.X1, rect.X1),

      Y0: Math.min(res.Y0, rect.Y0),
      Y1: Math.max(res.Y1, rect.Y1),

      pageNumber: firstPageNumber
    };
  }, rectsWithSizeOnFirstPage[0]);

  const { X0, X1, Y0, Y1, pageNumber } = optimal;

  return {
    left: X0,
    top: Y0,
    width: X1 - X0,
    height: Y1 - Y0,
    pageNumber
  };
};

const sort = rects => rects.sort(compareRects);

const overlaps = (A, B) =>
  A.pageNumber === B.pageNumber &&
  A.left <= B.left &&
  B.left <= A.left + A.width;

const sameLine = (A, B, yMargin = 5) =>
  A.pageNumber === B.pageNumber &&
  Math.abs(A.top - B.top) < yMargin &&
  Math.abs(A.height - B.height) < yMargin;

const inside = (A, B) =>
  A.pageNumber === B.pageNumber &&
  A.top > B.top &&
  A.left > B.left &&
  A.top + A.height < B.top + B.height &&
  A.left + A.width < B.left + B.width;

const nextTo = (A, B, xMargin = 10) => {
  const Aright = A.left + A.width;
  const Bright = B.left + B.width;

  return (
    A.pageNumber === B.pageNumber &&
    A.left <= B.left &&
    Aright <= Bright &&
    B.left - Aright <= xMargin
  );
};

const extendWidth = (A, B) => {
  // extend width of A to cover B
  A.width = Math.max(B.width - A.left + B.left, A.width);
};

export const optimizeClientRects = clientRects => {
  const rects = sort(clientRects);

  const toRemove = new Set();

  const firstPass = rects.filter(rect => {
    return rects.every(otherRect => {
      return !inside(rect, otherRect);
    });
  });

  let passCount = 0;

  while (passCount <= 2) {
    firstPass.forEach(A => {
      firstPass.forEach(B => {
        if (A === B || toRemove.has(A) || toRemove.has(B)) {
          return;
        }

        if (!sameLine(A, B)) {
          return;
        }

        if (overlaps(A, B)) {
          extendWidth(A, B);
          A.height = Math.max(A.height, B.height);

          toRemove.add(B);
        }

        if (nextTo(A, B)) {
          extendWidth(A, B);

          toRemove.add(B);
        }
      });
    });
    passCount += 1;
  }

  return firstPass.filter(rect => !toRemove.has(rect));
};

const isClientRectInsidePageRect = (clientRect, pageRect) => {
  if (clientRect.top < pageRect.top) {
    return false;
  }
  if (clientRect.bottom > pageRect.bottom) {
    return false;
  }
  if (clientRect.right > pageRect.right) {
    return false;
  }
  if (clientRect.left < pageRect.left) {
    return false;
  }

  return true;
};

function getNextNode(node) {
  if (node.firstChild) return node.firstChild;
  while (node) {
    if (node.nextSibling) return node.nextSibling;
    node = node.parentNode;
  }
}

function getTextNodesInRange(range) {
  const root = range.commonAncestorContainer;
  const start = range.startContainer;
  const end = range.endContainer;
  var nodes = [];
  let node;

  // walk parent nodes from start to common ancestor
  for (node = start.parentNode; node; node = node.parentNode) {
    if (node.nodeType === Node.TEXT_NODE) nodes.push(node);
    if (node === root) break;
  }

  nodes.reverse();

  // walk children and siblings from start until end is found
  for (node = start; node; node = getNextNode(node)) {
    if (node.nodeType === Node.TEXT_NODE) nodes.push(node);
    if (node === end) break;
  }

  return nodes;
}

export const getClientRects = (
  range,
  pages,
  container,
  shouldOptimize = true
) => {
  //range: Range object
  //Pages: Array of objects [{node: DOM Node, number: int (page numebr)}]
  //Container: DOM Node of parent

  const containerRect = container.getBoundingClientRect();
  const TextNodesInRange = getTextNodesInRange(range);

  //Gets an array of ranges from the text nodes
  //Ido !: This probably what causes in the highlights
  const TextNodesRanges = TextNodesInRange.map(node => {
    let nodeRange = document.createRange();
    nodeRange.selectNode(node);
    return nodeRange;
  });

  if (TextNodesRanges.length) {
    // setting the offset of the first node to match the start offset of the original range
    TextNodesRanges[0].setStart(range.startContainer, range.startOffset);

    // setting the offset of the last node to match the end offset of the original range
    TextNodesRanges[TextNodesRanges.length - 1].setEnd(
      range.endContainer,
      range.endOffset
    );
  }

  const pageParts = [];
  for (const page of pages) {
    const pageRects = [];

    // Ido: This supposedly gets the text layer nodes...
    // ...but it always gets an array with onlt the first text layer node
    const textLayerNode = page.node.getElementsByClassName("textLayer")[0];
    const pageRect = textLayerNode.getBoundingClientRect();

    let pageRange = document.createRange();
    pageRange.selectNodeContents(textLayerNode);

    // Yuval: For correct CFI, We need to create a new  range for the page always setting start to
    // page start if the original range was before the page start
    // and the end node to the end of the page if the original range ended after page end.
    // this text manipulation here is not needed , we use marks pane now.

    // Ido: This description is not clear. What text manipulation is not needed? Looks like the this map is filtering out the nodes which are not in the current page and that the function runs once for each page
    const pageRanges = TextNodesInRange.flatMap(node => {
      let nodeRange = document.createRange();
      if (textLayerNode.contains(node)) {
        nodeRange.selectNode(node);
        pageRange.selectNode(node);
        return [nodeRange];
      } else return [];
    });

    if (pageRanges.length && page === pages[0]) {
      // Yuval: setting the offset of the first node to match the start offset of the original range

      // pageRanges.length && IDO: this is a duplicated condition
      pageRanges[0].setStart(range.startContainer, range.startOffset);
      pageRange.setStart(range.startContainer, range.startOffset);
    } else {
      pageRanges.length && pageRanges[0].setStart(textLayerNode.children[0], 0);
      pageRange.setStart(textLayerNode.children[0], 0);
    }

    if (pageRanges.length && page === pages[pages.length - 1]) {
      // Yuval: setting the offset of the last node to match the end offset of the original range

      pageRanges.length &&
        pageRanges[pageRanges.length - 1].setEnd(
          range.endContainer,
          range.endOffset
        );
      pageRange.setEnd(range.endContainer, range.endOffset);
    }

    const pageClientRects = pageRanges.flatMap(range => {
      return Array.from(range.getClientRects());
    });
    for (const clientRect of pageClientRects) {
      if (
        isClientRectInsidePageRect(clientRect, pageRect) &&
        clientRect.width > 0 &&
        clientRect.height > 0
      ) {
        const highlightedRect = {
          // page.node.scrollTop will always return 0 - it is not scrolled
          top: clientRect.top - containerRect.top,
          left: clientRect.left - containerRect.left,
          width: clientRect.width,
          height: clientRect.height,
          pageNumber: page.number
        };

        pageRects.push(highlightedRect);
      }
      // Yuval: (cfiFrom, base, startContainerNode, startPage, endContainerNode, endPage){
      // Yuval: for now I use range, but actually it should be modified (see comment above)
    }
    pageParts.push({
      pageNumber: page.number,
      pageRects: optimizeClientRects(pageRects),
      cfi: new PdfCFI(
        pageRange,
        textLayerNode,
        page.number,
        textLayerNode,
        page.number
      ).toString()
    });
  }

  return pageParts;
};

export const viewportToScaled = (rect, { width, height }) => {
  return {
    x1: rect.left,
    y1: rect.top,

    x2: rect.left + rect.width,
    y2: rect.top + rect.height,

    width,
    height,

    pageNumber: rect.pageNumber
  };
};

const pdfToViewport = (pdf, viewport) => {
  const [x1, y1, x2, y2] = viewport.convertToViewportRectangle([
    pdf.x1,
    pdf.y1,
    pdf.x2,
    pdf.y2
  ]);

  return {
    left: x1,
    top: y1,

    width: x2 - x1,
    height: y1 - y2,

    pageNumber: pdf.pageNumber
  };
};

export const scaledToViewport = (
  scaled,
  viewport,
  usePdfCoordinates = false
) => {
  const { width, height } = viewport;
  if (usePdfCoordinates) {
    return pdfToViewport(scaled, viewport);
  }

  if (scaled.x1 === undefined) {
    throw new Error("You are using old position format, please update");
  }

  const x1 = (width * scaled.x1) / scaled.width;
  const y1 = (height * scaled.y1) / scaled.height;

  const x2 = (width * scaled.x2) / scaled.width;
  const y2 = (height * scaled.y2) / scaled.height;

  return {
    left: x1,
    top: y1,
    width: x2 - x1,
    height: y2 - y1,
    pageNumber: scaled.pageNumber
  };
};
