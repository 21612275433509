// Dependencies
import React, { useEffect, useState, useCallback } from "react";
import { httpCallables } from "../../firebase";
import { useQuery } from "../../hooks";

//Components
import PangeaSpinner from "../SharedComponents/PangeaSpinner";
import ScrollBox from "../SharedComponents/ScrollBox";

import makeStyles from "@mui/styles/makeStyles";
import { Box, Typography } from "@mui/material";

// Styles
const useStyles = makeStyles(theme => ({
  container: {
    width: "100%",
    height: "100%",
    marginBlockEnd: theme.spacing(4)
  },
  conversation: {
    paddingBlockEnd: theme.spacing(4)
  },
  conversationPart: {
    marginBlock: theme.spacing(2)
  }
}));
function UserConversations() {
  //Hooks
  const classes = useStyles();
  const { course_id, user_id } = useQuery();

  //Ephemeral State
  const [status, setStatus] = useState("idle");
  const [conversations, setConversations] = useState({});

  // Variables

  // Behavior
  const seperateConversations = useCallback(interactions => {
    return interactions.reduce((accumulator, current) => {
      if (current.articleName) {
        accumulator[current.id] = {
          date: new Date(current.created_at).toLocaleDateString("en-US"),
          articleName: current.articleName,
          question: current.questions.length ? current.questions[0] : null,
          content: [current]
        };
      } else {
        const sessionId = current.interaction_id;
        accumulator[sessionId] && accumulator[sessionId].content.push(current);
      }
      return accumulator;
    }, {});
  }, []);

  useEffect(() => {
    setStatus("loading");
    httpCallables
      .interactionFunctions({
        func_name: "readConversationsByUserId",
        course_id: Number(course_id),
        user_id
      })
      .then(({ data }) => {
        const { interactions } = JSON.parse(data.payload);
        const conversations = seperateConversations(interactions);
        setConversations(conversations);
        setStatus("idle");
      });
  }, [course_id, seperateConversations, user_id]);
  if (status === "loading") {
    return <PangeaSpinner />;
  } else {
    return (
      <ScrollBox className={classes.container}>
        {Object.keys(conversations).length === 0 ? (
          <Typography variant="h4">No conversations</Typography>
        ) : (
          <Typography variant="h4">Conversations</Typography>
        )}

        {Object.keys(conversations).map(conversationId => {
          const conversation = conversations[conversationId];
          return (
            <Box className={classes.conversation} key={conversationId}>
              <Typography variant="h5">{conversation.date}</Typography>
              <Typography variant="h6">
                Article: {conversation.articleName}
              </Typography>

              {conversation.question && (
                <Typography variant="h6">{`Question: ${conversation.question}`}</Typography>
              )}

              {conversation.content.map(interaction => {
                const content = interaction.content.replace(/<\/?p>/g, "");
                return (
                  <Box class={classes.conversationPart} key={interaction.id}>
                    <Typography variant="body1">
                      {/* remove </p> and <p> from content */}

                      {`${interaction.user_type}: ${content}`}
                    </Typography>
                  </Box>
                );
              })}
            </Box>
          );
        })}
      </ScrollBox>
    );
  }
}

export default UserConversations;
