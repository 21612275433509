import React from "react";
import clsx from "clsx";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";

import { Box, Button } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { selectTextDirection } from "../../../redux/firestoreSelectors";
import TooltipWithIntl from "../../SharedComponents/tooltip/TooltipWithIntl";

const useStyles = makeStyles(theme => ({
  right: {
    textAlign: "right"
  },
  left: {
    textAlign: "left"
  },
  tasksHeader: {
    marginTop: "40px",
    width: "100%",
    position: "relative"
  },
  blueBg: {
    backgroundColor: "#168FEE",
    color: "#FFFFFF",
    "&:hover": {
      backgroundColor: "#1172be"
    }
  },

  stepper: {
    marginTop: "20px",
    width: "100%",
    paddingLeft: "0px",
    paddingRight: "0px"
  },
  btnContainer: {
    marginTop: "40px",
    marginBottom: "40px",
    textAlign: "right"
  },
  btn: {
    color: theme.palette.lightText.main,
    borderRadius: "8px",
    marginLeft: "4px",
    marginRight: "4px"
  },
  btnPublish: {
    borderRadius: "8px",
    color: theme.palette.lightText.main,
    marginLeft: "4px",
    marginRight: "4px"
  },
  stepLabel: {
    fontSize: "24px"
  },
  stepRtl: {
    "& .MuiStepLabel-iconContainer": {
      paddingLeft: "8px",
      paddingRight: "0px"
    }
  },
  stepLast: {
    paddingRight: "0px"
  },
  stepIcon: {
    width: "36px",
    height: "36px"
  },
  cancelBtn: {
    position: "absolute"
  },
  cancelBtnRtl: {
    left: "0px"
  },
  cancelBtnLtr: {
    right: "0px"
  }
}));

export default function NavigationButtons(props) {
  const classes = useStyles();

  function renderButtons() {
    let backBtn = "";

    if (props.activeStep >= 1) {
      backBtn = (
        <Button
          onClick={() => props.setActiveStep(props.activeStep - 1)}
          elevation={2}
          className={clsx(classes.btn, classes.blueBg)}
        >
          {"Back"}
        </Button>
      );
    }

    if (props.activeStep === 2) {
      return (
        <Box className={classes.btnContainer}>
          {backBtn}

          <Button
            className={clsx(classes.btnPublish, classes.blueBg)}
            elevation={2}
            onClick={() => {
              props.validateForm() && props.publishTask();
            }}
            variant="contained"
          >
            <FormattedMessage defaultMessage="Publish task" id="save.task" />
          </Button>
        </Box>
      );
    }
    return (
      <Box className={classes.btnContainer}>
        {backBtn}
        <Button
          className={clsx(classes.btn, classes.blueBg)}
          name="next-step-button"
          elevation={2}
          onClick={() => {
            props.validateForm() && props.setActiveStep(props.activeStep + 1);
          }}
        >
          {"Next"}
        </Button>
      </Box>
    );
  }

  return <>{renderButtons()}</>;
}
