// Dependancies
import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import * as d3 from "d3";
import { dimensionsPropsType } from "../utils";
import { useChartContext } from "../Chart";

import makeStyles from "@mui/styles/makeStyles";

// Styles
const useStyles = makeStyles(theme => ({
  line: {
    stroke: "#bdc3c7"
  },

  label: {
    textAnchor: "middle",
    fontSize: "0.9em",
    letterSpacing: "0.01em"
  },

  tick: {
    fontSize: "0.8em",
    transition: " all 0.3s ease-out"
  },
  AxisHorizontal: {
    textAnchor: "middle"
  },
  AxisVertical: {
    dominantBaseline: "middle",
    textAnchor: "end"
  }
}));

const axisComponentsByDimension = {
  x: AxisHorizontal,
  y: AxisVertical
};

function Axis({ dimension, ...props }) {
  const { dimensions } = useChartContext();
  const Component = axisComponentsByDimension[dimension];
  if (!Component) return null;

  return <Component dimensions={dimensions} {...props} />;
}

Axis.propTypes = {
  dimension: PropTypes.oneOf(["x", "y"]),
  dimensions: dimensionsPropsType,
  scale: PropTypes.func,
  label: PropTypes.string,
  formatTick: PropTypes.func
};

Axis.defaultProps = {
  dimension: "x",
  scale: null
};

export default Axis;

function AxisHorizontal({
  dimensions,
  label,
  formatTick,
  scale,
  ticks,
  hideAxisLines,
  ...props
}) {
  // Hooks
  const classes = useStyles();

  // Derived state
  const numberOfTicks =
    dimensions.boundedWidth < 600
      ? dimensions.boundedWidth / 100
      : dimensions.boundedWidth / 250;

  // This solution supports categorical data, in which ...
  // ... the ticks ate passed in. Or quantitative who's ...
  // ... scale have a .ticks method. For other use cases ...
  // ... an update my be needed
  if (!ticks && scale.ticks) ticks = scale.ticks(numberOfTicks);

  if (!ticks) return null;
  return (
    <g
      className={clsx(classes.axis, classes.AxisHorizontal)}
      transform={`translate(0, ${dimensions.boundedHeight})`}
      {...props}
    >
      {!hideAxisLines && (
        <line className={classes.line} x2={dimensions.boundedWidth} />
      )}
      {ticks.map((tick, i) => (
        <text
          key={tick}
          className={classes.tick}
          transform={`translate(${scale(tick)}, 25)`}
        >
          {formatTick ? formatTick(tick) : tick}
        </text>
      ))}

      {label && (
        <text
          className={classes.label}
          transform={`translate(${dimensions.boundedWidth / 2}, 48)`}
        >
          {label}
        </text>
      )}
    </g>
  );
}

AxisHorizontal.propTypes = {
  dimensions: dimensionsPropsType,
  scale: PropTypes.func,
  label: PropTypes.string,
  formatTick: PropTypes.func,
  ticks: PropTypes.array,
  hideAxisLines: PropTypes.bool
};

function AxisVertical({
  dimensions,
  label,
  formatTick,
  scale,
  ticks,
  hideAxisLines,
  ...props
}) {
  // Hooks
  const classes = useStyles();

  // Derived state
  const numberOfTicks = dimensions.boundedHeight / 70;

  // This solution supports categorical data, in which ...
  // ... the ticks ate passed in. Or quantitative who's ...
  // ... scale have a .ticks method. For other use cases ...
  // ... an update my be needed

  if (!ticks && scale.ticks) ticks = scale.ticks(numberOfTicks);

  if (!ticks) return null;
  return (
    <g className={clsx(classes.axis, classes.AxisVertical)} {...props}>
      {!hideAxisLines && (
        <line className={classes.line} y2={dimensions.boundedHeight} />
      )}
      {ticks.map((tick, i) => {
        return (
          <text
            key={tick}
            className={classes.tick}
            transform={`translate(-16, ${
              // If we are useing scaleBand we want to center the label
              scale.bandwidth
                ? scale(tick) + scale.bandwidth() / 2
                : scale(tick)
            })`}
          >
            {formatTick ? formatTick(tick) : tick}
          </text>
        );
      })}

      {label && (
        <text
          className={classes.label}
          style={{
            transform: `translate(-72px, ${
              dimensions.boundedHeight / 2
            }px) rotate(-90deg)`
          }}
        >
          {label}
        </text>
      )}
    </g>
  );
}

AxisVertical.propTypes = {
  dimensions: dimensionsPropsType,
  scale: PropTypes.func,
  label: PropTypes.string,
  formatTick: PropTypes.func,
  ticks: PropTypes.array,
  hideAxisLines: PropTypes.bool
};
