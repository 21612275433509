// Dependancies
import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { useInView } from "react-intersection-observer";

// Redux dependancies
import { useSelector, useDispatch } from "react-redux";
import {
  selectPageVisibility,
  setPageVisibility
} from "../../../redux/pdfSlice";

// Components
import { PdfPosition, PdfHighlight } from "./PdfTypes";
import HighlightLayer from "./HighlightLayer";
import PdfPageMemo from "./PdfPageMemo";

import makeStyles from "@mui/styles/makeStyles";
import { Box } from "@mui/material";

// Styles
const useStyles = makeStyles(theme => {
  return {
    pageContainer: {
      contentVisibility: "auto",
      marginLeft: "auto",
      marginRight: "auto",
      position: "relative"
    }
  };
});

const PdfPageWIthHighlights = React.memo(
  ({
    highlights,
    underlines = [],
    scale,
    pageNumber,
    onHighlightClick,
    isVisible,
    width,
    renderMode,
    setPageRendered
  }) => {
    // Hooks
    const dispatch = useDispatch();
    const classes = useStyles();
    const { ref, entry } = useInView({
      // this is twice the threshold amount that worked smoothly
      threshold: [...Array(32).keys()].map(el => el / 32)
    });

    // Redux State
    const zoom = useSelector(state => state.pdf.zoom);
    const visibility = useSelector(state =>
      selectPageVisibility(state, pageNumber)
    );

    // Ephemeral state
    const [page, setPage] = useState(null);
    const [renderedText, setRenderedText] = useState(0);

    // Derived state
    const marginValue = zoom <= 1 ? 50 : 0;
    const widthWithMarging = width ? (width * zoom) - marginValue : null; // prettier-ignore

    const aspectRatio = page ? page.originalHeight / page.originalWidth : 1;

    // Behavior
    useEffect(() => {
      if (page && ref?.current) {
        ref.current.style.height = page.height;
      }
    }, [page, ref]);

    useEffect(() => {
      if (!entry) return;
      const updatedVisibility = Math.floor(100 * entry.intersectionRatio);

      if (visibility !== updatedVisibility) {
        dispatch(
          setPageVisibility({ pageNumber, visibility: updatedVisibility })
        );
      }
    }, [dispatch, entry, pageNumber, visibility]);

    const onRenderedText = useCallback(value => setRenderedText(value), []);

    const onLoadSuccess = useCallback(page => setPage(page), []);

    const onRenderSuccess = useCallback(
      () => setPageRendered(pageNumber),
      [pageNumber, setPageRendered]
    );

    return (
      <Box style={{ all: "initial", width: "fit-content" }}>
        {isVisible && (
          <Box
            className={classes.pageContainer}
            style={{
              minHeight: widthWithMarging * aspectRatio,
              // This 2 options are acceptable for horizontal scrolling. 
              // overFlowX doesn't show the scroll bar UI availablity
              // Therefore we chose minWidth.
              minWidth: widthWithMarging,
              // overflowX: "auto",
              width: width,
              display: page ? "flex" : "none"
            }}
            ref={ref}
          >
            <PdfPageMemo
              scale={scale}
              width={widthWithMarging}
              pageNumber={pageNumber}
              renderMode={renderMode}
              onLoadSuccess={onLoadSuccess}
              onRenderSuccess={onRenderSuccess}
              onRenderedText={onRenderedText}
            />
            <HighlightLayer
              onHighlightClick={onHighlightClick}
              highlights={highlights}
              underlines={underlines}
              pageNumber={pageNumber}
              rendered={renderedText}
            />
          </Box>
        )}
      </Box>
    );
  }
);

PdfPageWIthHighlights.propTypes = {
  location: PropTypes.shape(PdfPosition),
  scale: PropTypes.number,
  interactive: PropTypes.bool,
  width: PropTypes.number,
  pageNumber: PropTypes.number,
  onHighlightClick: PropTypes.func.isRequired,
  isVisible: PropTypes.bool,
  highlights: PropTypes.arrayOf(PropTypes.shape(PdfHighlight)),
  underlines: PropTypes.arrayOf(PropTypes.shape(PdfHighlight)),
  renderMode: PropTypes.oneOf(["svg", "canvas", "none"]),
  locationChanged: PropTypes.func
};
export default PdfPageWIthHighlights;
