// Dependencies
import React, { useCallback, useEffect, useReducer, useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { useIntl } from "react-intl";
import { useQuery } from "../../../../hooks";
import { v4 as uuid } from "uuid";

// Components

// Material UI
import {
  Chip,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";
import { firebaseFunctions, httpCallables } from "../../../../firebase";
import TaskStatusChip from "../../TaskManager/TaskStatusChip";
import { Skeleton } from "@mui/material";
import { calculateSubmissionStatus } from "../../TaskManager/utills";
import { SUBMISSION_STATUS } from "../../../../consts";
import { captureException } from "@sentry/react";
import { useDispatch } from "react-redux";
import { addSnackbarItem } from "../../../../redux/snackbarSlice";
import { convertEngagmentScoreToCategory } from "../../../admin/reports/courseActivity/utils";
import WidgetHeader from "../../../SharedComponents/WidgetHeader";
import { init } from "@sentry/browser";

// Styles
const useStyles = makeStyles(theme => ({
  widgetHeader: {
    display: "flex",
    justifyContent: "space-between",
    padding: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.divider}`
  },
  chip: {
    cursor: "pointer",
    marginInlineEnd: theme.spacing(1),
    "&:last-child": {
      marginInlineEnd: 0
    }
  },
  chipPending: {
    border: `1px solid ${theme.palette.text.primary}`,
    backgroundColor: "transparent"
  },
  chipSubmitted: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText
  },
  chipLate: {
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.warning.contrastText
  },
  chipMissing: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.primary.contrast
  },
  sent: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.contrast
  }
}));

const initialState = { pending: 0, submitted: 0, late: 0, missed: 0 };

function aggregatedStatusesReducer(state, action) {
  switch (action.type) {
    case "set":
      const { tasks, submissions } = action.payload;
      return tasks.reduce(
        (accumulator, current) => {
          const submission = submissions.find(
            submission => submission.task_id === current.id
          );
          if (submission) {
            const submissionsStatus = calculateSubmissionStatus(
              submission.due_date,
              submission.submission_date,
              current.original_due_date
            );

            if (submissionsStatus === SUBMISSION_STATUS.MISSED) {
              accumulator.missed++;
            } else if (
              submissionsStatus === SUBMISSION_STATUS.LATE_PENDING ||
              submissionsStatus === SUBMISSION_STATUS.LATE
            ) {
              accumulator.late++;
            } else if (submissionsStatus === SUBMISSION_STATUS.ON_TIME) {
              accumulator.submitted++;
            } else {
              accumulator.pending++;
            }
          }
          return accumulator;
        },
        { ...initialState }
      );
    default:
      throw new Error();
  }
}

function TaskListTable({ start, end }) {
  // Hooks
  const intl = useIntl();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { course_id, user_id } = useQuery();

  // Ephemeral State
  const [tasks, setTasks] = useState([]);
  const [submissions, setSubmissions] = useState([]);
  const [engagementPerTask, setEngagementPerTask] = useState({});
  const [aggregatedStatuses, dispatchAggregatedStatuses] = useReducer(
    aggregatedStatusesReducer,
    initialState
  );

  // Derived state
  const sent = intl.formatMessage({
    id: "tasks.feedback.sent",
    defaultMessage: "Sent"
  });

  //Behavior
  const handleSubmissionData = useCallback(data => {
    const { tasks, submissions } = JSON.parse(data);
    const sortedTasks = tasks.sort((a, b) => {
      let a_date = Date.parse(a.original_due_date);
      let b_date = Date.parse(b.original_due_date);
      if (a_date > b_date) return 1;
      else if (a_date < b_date) return -1;
      else return 0;
    });
    setTasks(sortedTasks);
    setSubmissions(submissions);
    dispatchAggregatedStatuses({
      type: "set",
      payload: { tasks, submissions }
    });
  }, []);

  useEffect(() => {
    let isCancelled = false;
    const taskFunctions = firebaseFunctions.httpsCallable(
      "tasks-taskFunctions"
    );
    taskFunctions({
      func_name: "readStudentCourseTasks",
      course_id: Number(course_id),
      student_id: user_id,
      start,
      end,
      ownerOnly: true
    }).then(({ data }) => {
      const { success } = data;
      if (success && !isCancelled) {
        handleSubmissionData(data.payload);
      }
    });
    return () => {
      isCancelled = true;
    };
  }, [course_id, user_id, handleSubmissionData, start, end]);

  useEffect(() => {
    if (!submissions.length) return;

    const start = submissions.at(0)?.publish_date;
    const end = submissions.at(-1)?.due_date;

    httpCallables
      .readDailyUsersActivityReport({
        course_id: Number(course_id),
        users: user_id,
        start,
        end,
        includeClass: false,
        includeTypes: ["ENGAGEMENT"]
      })
      .then(({ data }) => {
        const { success } = data;
        if (success) {
          const parsedData = JSON.parse(data.payload);
          setEngagementPerTask(
            calculateEngagmentForSubmissions(parsedData[user_id], submissions)
          );
        } else {
          const { error } = data;
          dispatch(
            addSnackbarItem({
              intlId: "error.generateUserActivityReportFailed",
              intlDefaultMessage:
                "There was a problem getting the course information. Please check your connection and try again",
              id: uuid()
            })
          );

          captureException(error, `Failed to get course users`);
        }
      });
  }, [course_id, dispatch, submissions, user_id]);

  function calculateEngagmentForSubmissions(data, submissions) {
    let start = null;
    let currentIndex = 0;

    return submissions.reduce((accumulator, current) => {
      let task = current.task_id;
      let engagment = 0;

      start = start || new Date(current.publish_date);
      const end = new Date(current.due_date);

      for (let i = currentIndex; i < data.length; i++) {
        const current = data[i];
        const date = new Date(current.date);
        if (date >= start && date < end) {
          engagment += Number(current.engagement);
          currentIndex++;
        } else break;
      }

      start = end;
      accumulator[task] = engagment;
      return accumulator;
    }, {});
  }

  function showSkeleton() {
    return Array.from({ length: 4 }, (item, index) => index).map(tableRow => (
      <TableRow key={tableRow}>
        {Array.from({ length: 5 }, (item, index) => index).map(tableCell => (
          <TableCell key={tableCell}>
            <Skeleton />
          </TableCell>
        ))}
      </TableRow>
    ));
  }

  return (
    <TableContainer component={Paper} elevation={0}>
      <WidgetHeader title="Task list">
        <Chip
          size="small"
          className={clsx(classes.chip, classes.chipPending)}
          label={`${aggregatedStatuses.pending} Pending`}
        />
        <Chip
          size="small"
          className={clsx(classes.chip, classes.chipSubmitted)}
          label={`${aggregatedStatuses.submitted} Submitted`}
        />
        <Chip
          size="small"
          className={clsx(classes.chip, classes.chipLate)}
          label={`${aggregatedStatuses.late} Late`}
        />
        <Chip
          size="small"
          className={clsx(classes.chip, classes.chipMissing)}
          label={`${aggregatedStatuses.missed} Missing`}
        />
      </WidgetHeader>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Task title</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Engagement</TableCell>
            <TableCell>Feedback</TableCell>
            <TableCell>Grade</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {tasks.length
            ? tasks.map(task => {
                const submission = submissions.find(
                  submission => submission.task_id === task.id
                );
                const engagement = engagementPerTask[task.id];
                const engagmentCategory =
                  convertEngagmentScoreToCategory(engagement);

                if (!submission) return null;
                else
                  return (
                    <TableRow key={task.id}>
                      <TableCell component="th" scope="row">
                        {task.name}
                      </TableCell>
                      <TableCell>
                        <TaskStatusChip
                          task={task}
                          submission={submission}
                          userRole={"Student"}
                        />
                      </TableCell>
                      <TableCell>
                        {engagement >= 0 ? engagmentCategory : <Skeleton />}
                      </TableCell>
                      <TableCell>
                        {submission.is_checked && (
                          <Chip
                            color="primary"
                            className={clsx(classes.chip, classes.sent)}
                            label={sent}
                            size="small"
                          />
                        )}
                      </TableCell>
                      <TableCell>{submission.grade || "-"}</TableCell>
                    </TableRow>
                  );
              })
            : showSkeleton()}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

TaskListTable.propTypes = {
  start: PropTypes.object,
  end: PropTypes.object
};

export default TaskListTable;
