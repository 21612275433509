// Dependencies
import React, { useState } from "react";
import { useHistory, Link as RouterLink } from "react-router-dom";
import { useIntl } from "react-intl";
import clsx from "clsx";
import { firestore } from "../../../firebase";
import { tasksAPI } from "../../../api";
import { dateToEodUtc } from "../CreateTask/utils";

// Redux Dependencies
import { useSelector } from "react-redux";
import { selectSubmissionCollaboration } from "../../../redux/tasksSlice";

//Components
import TaskStatusChip from "./TaskStatusChip";
import PeerReviewStatusChip from "./PeerReviewStatusChip";
import DeadlineDatePicker from "./DeadlineDatePicker";
import TooltipWithIntl from "../../SharedComponents/tooltip/TooltipWithIntl";

// Mui
import makeStyles from "@mui/styles/makeStyles";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import AssessmentIcon from "@mui/icons-material/Assessment";

import {
  Link,
  Chip,
  IconButton,
  TableCell,
  TableRow,
  Typography
} from "@mui/material";

// Styles
const useStyles = makeStyles(theme => ({
  tableContainer: {
    paddingLeft: "10%"
  },
  tableRow: {
    // Equelize the height of the table rows with and without action buttons
  },
  pointer: {
    cursor: "pointer"
  },
  chip: { margin: "-8px" },
  missed: {
    backgroundColor: "#f9413e"
  },
  late: {
    backgroundColor: "#fcb63f"
  },
  pending: {
    border: "1px solid black"
  },
  submitted: {
    backgroundColor: "transparent",
    color: "#0eac72",
    border: "1px solid #0eac72"
  },
  graded: {
    backgroundColor: "#0eac72"
  },
  sent: {
    backgroundColor: theme.palette.primary,
    color: theme.palette.text.primary
  },
  add: {
    border: `1px solid ${theme.palette.primary}`,
    color: theme.palette.primary
  },
  actions: {
    display: "flex",
    textAlign: "end",
    paddingRight: 0
  },
  link: {
    color: theme.palette.text.primary,
    display: "block"
  },
  actionIcon: {
    "&:hover": {
      color: theme.palette.primary.main
    }
  },
  grade: {
    textAlign: "center"
  }
}));

export default function SubmissionDetails({
  submission,
  course,
  task,
  graders,
  setModalVisible
}) {
  //Hooks
  const classes = useStyles();
  const intl = useIntl();
  const history = useHistory();

  // Redux State
  const currentUser = useSelector(state => state.user.auth.uid);
  const isImpersonating = useSelector(state => state.user.original_auth !== -1);
  const collaboration = useSelector(state =>
    selectSubmissionCollaboration(state, submission.id)
  );

  // Ephemeral State
  const [datePickerOpen, setDatePickerOpen] = useState(false);

  // Variables
  const add = intl.formatMessage({
    id: "tasks.feedback.add",
    defaultMessage: "add"
  });
  const sent = intl.formatMessage({
    id: "tasks.feedback.sent",
    defaultMessage: "Sent"
  });

  // Behavior

  // Display the status chips

  function getFeedback(submission) {
    if (submission.is_checked) {
      return (
        <Chip
          color="primary"
          className={classes.chip}
          label={sent}
          size="small"
        />
      );
    } else {
      if (submission.submission_date) {
        return (
          <Chip
            color="primary"
            variant="outlined"
            className={classes.chip}
            label={add}
            size="small"
            clickable={true}
          />
        );
      } else return null;
    }
  }

  function getGrade(submission) {
    const grade = submission.grade || "";
    if (submission.totalpoints) {
      return `${grade}/${submission.totalpoints}`;
    } else if (submission.is_checked) {
      return intl.formatMessage({
        id: "tasks.see.feedback",
        defaultMessage: "See feedback"
      });
    } else return "-";
  }

  const wrapLink = (submission, value) => {
    if (
      submission.submission_date &&
      (submission.is_checked ||
        !graders ||
        !graders[submission.submission_id] ||
        graders[submission.submission_id].grader === currentUser)
    ) {
      let linkTarget = `/task?submission_id=${submission.id}`;

      return (
        <Link
          className={classes.link}
          component={RouterLink}
          to={linkTarget}
          underline="hover"
        >
          {value}
        </Link>
      );
    } else return value;
  };

  const navigateToSubmission = submission => {
    if (submission.is_checked) {
      history.push(`/task?submission_id=${submission.id}`);
    } else if (submission.submission_date) {
      if (
        graders &&
        graders[submission.id] &&
        graders[submission.id].grader !== currentUser
      ) {
        setModalVisible(submission);
      } else {
        let docId = `taskGraders/${course.id}/graders/${submission.id}`;
        !isImpersonating && firestore.doc(docId).set({ grader: currentUser });
        history.push(`/task?submission_id=${submission.id}`);
      }
    }
  };

  function handleDeadlineChange(submission, newDeadline) {
    const tzDeadline = dateToEodUtc(newDeadline, course.timezone);
    tasksAPI.updateDueDateForStudent(submission, tzDeadline);
  }

  // Render
  return (
    <TableRow
      key={submission.submission_id}
      className={clsx(
        classes.tableRow,
        submission.submission_date && classes.pointer
      )}
    >
      <TableCell component="th" scope="row">
        <Link
          className={classes.link}
          onClick={() => {
            navigateToSubmission(submission);
          }}
          underline="hover"
        >
          <Typography component="span" variant="body2">
            {submission.user_name}
          </Typography>
        </Link>
      </TableCell>

      {task.task_type === "peerReview" ? (
        <>
          <PeerReviewStatusChip
            task={task}
            submission={submission}
            collaboration={collaboration}
            userRole={course.course_role}
            onClick={() => {
              navigateToSubmission(submission);
            }}
          />
        </>
      ) : (
        <TableCell>
          <TaskStatusChip
            task={task}
            submission={submission}
            userRole={course.course_role}
            onClick={() => {
              navigateToSubmission(submission);
            }}
          />
        </TableCell>
      )}

      <TableCell>{wrapLink(submission, getFeedback(submission))}</TableCell>
      <TableCell className={classes.grade}>
        {wrapLink(submission, <Typography>{getGrade(submission)}</Typography>)}
      </TableCell>
      <TableCell className={classes.actions}>
        <DeadlineDatePicker
          isOpen={datePickerOpen}
          setIsOpen={setDatePickerOpen}
          deadline={submission.due_date}
          label="Task deadline"
          onChange={newDueDate => handleDeadlineChange(submission, newDueDate)}
        >
          <TooltipWithIntl
            intlStringId="tasks.deadline"
            defaultMessage="Personal deadline"
            placement="bottom"
          >
            <IconButton
              component="button"
              onClick={() => {
                setDatePickerOpen(true);
              }}
              className={classes.taskDeadline}
              size="large"
            >
              <CalendarTodayOutlinedIcon />
            </IconButton>
          </TooltipWithIntl>
        </DeadlineDatePicker>

        <TooltipWithIntl
          intlStringId="reports.studentReport"
          defaultMessage="Student report"
          placement="bottom"
        >
          <IconButton
            component="button"
            onClick={() => {
              history.push(
                `/tasks/stats?course_id=${course.id}&user_id=${submission.owner}`
              );
            }}
          >
            <AssessmentIcon />
          </IconButton>
        </TooltipWithIntl>
      </TableCell>
    </TableRow>
  );
}
