// Dependencies
import React, { useState } from "react";
import { useIntl } from "react-intl";
import clsx from "clsx";

//Components
import CitationChart from "./CitationChart";
import HeatMap from "./HeatMap";
import {
  selectQuestionHighlights,
  selectQuestionAnswers
} from "../../../redux/interactionsSlice";
import { selectCurrentText } from "../../../redux/textsSlice";

import { useSelector } from "react-redux";

import makeStyles from "@mui/styles/makeStyles";
import {
  Box,
  Typography,
  Paper,
  Divider,
  Card,
  CardContent,
  Button
  // LinearProgress,
} from "@mui/material";

// Styles
const useStyles = makeStyles(theme => {
  return {
    statContainer: {
      padding: "32px"
    },
    generalStatsHeader: {
      padding: "24px 0",
      fontWeight: "bold"
    },
    questionTitle: {
      fontWeight: theme.typography.fontWeightBold
    },
    findInTextContainer: {
      display: "flex",
      flexFlow: "row nowrap"
    },
    preformanceContainer: {
      flexBasis: "50%",
      padding: "32px",
      borderRight: "1px solid rgba(0, 0, 0, 0.12)"
    },

    citationsContainer: {
      padding: "32px",
      flexBasis: "50%",
      display: "flex",
      flexFlow: "column wrap",
      justifyContent: "space-between"
    },
    citation: {
      cursor: "pointer",
      marginBottom: "32px"
    },
    citationBody: {
      display: "-webkit-box",
      overflow: "hidden",
      "-webkit-line-clamp": "10",
      "-webkit-box-orient": "vertical",
      textOverflow: "ellipsis"
    },
    citationsActions: {
      textAlign: "end"
    },
    actionButton: {
      textTransform: "none"
    }

    // BELLOW IS THE STRUCTURE FOR A FUTURE CONCEPTS STATS FEATURE
    // // The whole box of concepts
    // conceptsContainer: {
    //   padding: "32px",
    //   flexBasis: "50%",
    // },
    // // Each indevidual concept
    // conceptContainer: {
    //   display: "flex",
    //   flexFlow: "row nowrap",
    //   marginBottom: "24px",
    //   "&:last-child": {
    //     marginBottom: "0",
    //   },
    // },

    // conceptPresantage: {
    //   fontFamily: `Helvetica, "sans-serif"`,
    //   fontSize: "36px",
    //   flexBasis: "20%",
    // },
    // barContainer: {
    //   fontSize: "20px",
    //   lineHeight: "1.6",
    // },
    // statBar: {
    //   height: "8px",
    //   backgroundColor: "#D8D8D8",
    //   "& .MuiLinearProgress-barColorPrimary": {
    //     backgroundColor: "#168FEE",
    //   },
    // },
  };
});

export default function FindInTextStats({
  submissions,
  question,
  index,
  task,
  isTeacher = true
}) {
  // Hooks
  const classes = useStyles();
  const intl = useIntl();

  //Redux State
  const text = useSelector(selectCurrentText);

  const questionHighlights = useSelector(state =>
    selectQuestionHighlights(state, question.id)
  );

  // Ephemeral State
  const [openHeatmap, setOpenHeatmap] = useState(false);

  const teacherHighlights = question.quotes || [];
  const studentHighlights = questionHighlights.filter(
    highlight => highlight.user_type === "STUDENT"
  );

  // Helpers
  if (isTeacher)
    return (
      <>
        <Paper component="section" elevation={0}>
          <HeatMap
            totalCount={
              submissions.filter(submission => submission.submission_date)
                .length
            }
            openDialog={openHeatmap}
            setOpenDialog={setOpenHeatmap}
            teacherHighlights={teacherHighlights}
            url={text.url}
            location={text.file_location}
            hlOpacity={1}
            hlColor="#4AA96C"
            highlights={studentHighlights}
            addHighlight={() => {}}
            removeHighlight={() => {}}
          />
          <Box component="header" className={classes.statContainer}>
            <Typography className={clsx(classes.questionTitle)} variant="h5">
              {intl.formatMessage({
                id: "task.question",
                defaultMessage: "Question"
              })}{" "}
              #{index + 1} —{" "}
              {intl.formatMessage({
                id: "task.type.findInText",
                defaultMessage: "Find in text"
              })}
            </Typography>
            <Typography
              className={clsx(classes.questionSubtitle)}
              variant="h6"
              component="span"
            >
              {question.content}
            </Typography>
          </Box>
          <Divider />
          <Box className={clsx(classes.findInTextContainer)}>
            <Box className={clsx(classes.preformanceContainer)}>
              <CitationChart
                question={question}
                i={index}
                task={task}
                submissions={submissions}
                answers={studentHighlights}
              />
            </Box>
            <Box className={clsx(classes.citationsContainer)}>
              <Card
                onClick={() => {}}
                className={classes.citation}
                variant="outlined"
              >
                <CardContent>
                  <Typography
                    className={classes.citationBody}
                    variant="body1"
                    component="p"
                  >
                    {/* Showing the first citation */}
                    {teacherHighlights.length > 0 && teacherHighlights[0].text}
                  </Typography>
                </CardContent>
              </Card>
              <Box className={classes.citationsActions}>
                <Button
                  color="primary"
                  size="small"
                  style={{
                    backgroundColor: "#168FEE",
                    color: "#FFFFFF",
                    padding: "4px 8px"
                  }}
                  className={classes.actionButton}
                  onClick={() => setOpenHeatmap(true)}
                >
                  {intl.formatMessage({
                    id: "task.statistics.showHeatmap",
                    defaultMessage: "Show heatmap"
                  })}
                </Button>
              </Box>
            </Box>

            {/* BELLOW IS THE STRUCTURE FOR A FUTURE CONCEPTS STATS FEATURE */}
            {/* <Box className={clsx(classes.conceptsContainer)}>
            <Box className={clsx(classes.conceptContainer)}>
              <Box className={classes.conceptPresantage} component="span">
                78%
              </Box>
              <Box className={classes.barContainer}>
                <Box component="span">
                  Included [this concept] in their passages
                </Box>
                <LinearProgress
                  variant="determinate"
                  value={normalize(78, 0, 100)}
                  className={classes.statBar}
                />
              </Box>
            </Box>
            <Box className={clsx(classes.conceptContainer)}>
              <Box className={classes.conceptPresantage} component="span">
                67%
              </Box>
              <Box className={classes.barContainer}>
                <Box component="span">
                  Included [this concept] in their passages
                </Box>
                <LinearProgress
                  variant="determinate"
                  value={normalize(67, 0, 100)}
                  className={classes.statBar}
                />
              </Box>
            </Box>
          </Box> */}
          </Box>
        </Paper>
      </>
    );
  else
    return (
      <>
        <Card
          component="section"
          elevation={1}
          className={classes.statContainer}
        >
          <HeatMap
            totalCount={
              submissions.filter(submission => submission.submission_date)
                .length
            }
            openDialog={openHeatmap}
            setOpenDialog={setOpenHeatmap}
            teacherHighlights={teacherHighlights}
            url={text.url}
            location={text.file_location}
            hlOpacity={1}
            hlColor="#4AA96C"
            highlights={studentHighlights}
            addHighlight={() => {}}
            removeHighlight={() => {}}
          />
          <Box component="header">
            <Typography className={clsx(classes.questionTitle)} variant="h5">
              {intl.formatMessage({
                id: "task.question",
                defaultMessage: "Question"
              })}{" "}
              #{index + 1} —{" "}
              {intl.formatMessage({
                id: "task.type.findInText",
                defaultMessage: "Find in text"
              })}
            </Typography>
            <Divider />
            <Box
              style={{
                display: "flex",
                flexFlow: "row",
                justifyContent: "space-between",
                paddingTop: "15px"
              }}
            >
              <Typography
                className={clsx(classes.questionSubtitle)}
                variant="h6"
                component="span"
              >
                {question.content}
              </Typography>
              <Box className={classes.citationsActions}>
                <Button
                  color="primary"
                  size="small"
                  style={{
                    backgroundColor: "#168FEE",
                    color: "#FFFFFF",
                    padding: "4px 8px"
                  }}
                  className={classes.actionButton}
                  onClick={() => setOpenHeatmap(true)}
                >
                  {intl.formatMessage({
                    id: "task.statistics.showHeatmap",
                    defaultMessage: "Show heatmap"
                  })}
                </Button>
              </Box>
            </Box>
          </Box>
        </Card>
      </>
    );
}
