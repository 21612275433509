// Dependencies
import React from "react";

// Redux dependencies

// Components
import PeerReviewReflectionPanel from "./PeerReviewReflectionPanel";
import PeerReviewReaderPanel from "./PeerReviewReaderPanel";

// Material UI

// Styles

export default function PeerReviewTabPanel({
  currentTab,
  zenMode,
  textBookmark,
  answerRef,
  tabPanelRef
}) {
  // Hooks

  // Redux state

  // Ephemeral state

  // Variables

  // Behavior

  //render
  if (zenMode) {
    return null;
  } else if (currentTab === 0) {
    return <PeerReviewReflectionPanel answerRef={answerRef} />;
  } else {
    return (
      <PeerReviewReaderPanel
        textBookmark={textBookmark}
        tabPanelRef={tabPanelRef}
      />
    );
  }
}
