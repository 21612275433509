const he = {
  uderstood: "הבנתי",
  "delete.card": "כרטיסייה נמחקה",
  "delete.question": "שאלה נמחקה",
  undo: "ביטול",
  cancel: "ביטול",
  "task.feedback.match": "התאמה",
  "task.title.placeholder": "כותרת",
  "task.feedback.placeholder": "משוב לעבודה של $STUDENT",
  close: "סגירה",
  minimize: "הקטן",
  post: "פרסם",
  send: "שליחה",
  "task.points_of": "נקודות מתוך",
  next: "הבא",
  back: "הקודם",
  stepBack: "חזרה",
  answer: "ענה",
  citations: "סמן בטקסט",
  "save.draft": "שמירה",
  "task.savedDraft": "טיוטה נשמרה",
  "task.open.placeholder": "התשובה שלך",
  "tasks.status.pending": "טרם הוגש",
  "tasks.status.late": "הוגש באיחור",

  "tasks.status.reviewed": "התקבלה ביקורת",
  "tasks.create.validation.optionsValue":
    "לשאלה אמריקאית חייבות להיות לפחות שתיל אפשרויות",

  "tasks.create.validation.optionChoice": " אנא בחר את התשובה הנכונה לשאלה",
  "save.task": "שליחת משימה",
  "new.task": "משימה חדשה",
  "appBar.library": "ספרייה",
  "appBar.reader": "קורא",
  "appBar.tasks": "משימות",
  "appBar.task": "משימה",
  "appBar.notifications": "התראות",
  "appBar.notifications.Teacher.notifyTeachersOnSubmission":
    "משימה הוגשה על ידי $DATA",
  "appBar.notifications.Teacher.notifyOnPeerReviewReflection":
    "תגובה הוגשה על ידי $DATA",
  "appBar.notifications.Teacher.notifyOnPeerReviewResponse":
    "ביקורת עמיתים הוגשה על ידי $DATA",
  "appBar.notifications.Teacher.notifyOnPeerReviewReply":
    "תגובה הוגשה על ידי $DATA",

  "appBar.notifications.Student.notifyStudentsOnNewTask":
    "יש לך משימה חדשה בשם $DATA",
  "appBar.notifications.Student.notifyStudentOnFeedback": "קיבלת משוב על משימה",
  "appBar.notifications.Student.notifyOnPeerReviewReflection":
    "$DATA מחכה לביקורת עמיתים",
  "appBar.notifications.Student.notifyOnPeerReviewResponse":
    "קיבלת ביקורת עמיתים מאת $DATA",
  "appBar.notifications.Student.notifyOnPeerReviewReply":
    "קיבלת תגובה מאת $DATA",

  "appBar.community": "קהילה",
  "appBar.contactUs": "צור קשר",

  "library.author": "מחבר/ת",
  "library.title": "טקסט",
  "library.category": "קטגוריה",
  "task.multi.incorrect": "שגוי",
  "task.multi.correct": "נכון",
  "button.next": "המשך",
  "button.finish": "סיום",
  "courses.classes": "קורסים",

  "courses.subtitle": "הקורסים שלי",
  "courses.classes.subtitle": "הקורסים שלי",
  "gr.button.finish": "סיום והגשה",
  "gr.confirm.msg": "אישור הגשת מטלה",
  "gr.confirm.btn": "אישור",
  "feedback.tooltip.showText": "פתח טקסט",
  "feedback.tooltip.link": "יצירת קישור",
  "gr.should.submit": "האם להגיש את המטלה לבדיקה",
  "actionBar.tooltip.gr": "קריאה מודרכת",
  "actionBar.tooltip.grTask": "משימת קריאה מודרכת",
  "actionBar.tooltip.task": "משימה",
  "actionBar.tooltip.summary": "תקציר",
  "actionBar.tooltip.comments": "הערות",
  "gr.fedback.comments": "תגובות",
  "gr.feedback.addComment": "הוספת תגובה",
  "card.title": "כותרת",

  "actions.addTitle": "הוספת כותרת",
  "show.text": "הראה בטקסט",
  "show.location.inText": "מיקום בטקסט",
  "actions.makePrivate": "הפוך לפרטית",
  "actions.makePublic": "הפוך לציבורית",
  comments: {
    private: { single: "הערה פרטית", multiple: "הערות פרטיות" },
    community: { single: "הערה ציבורית", multiple: "הערות ציבוריות" },
    noCommentsYet:
      "כדי להוסיף הערה יש לסמן קטע מהטקסט וללחוץ עליו. בחלון שנפתח יש ללחוץ על כפתור ההערה.",
    commentOwner: "אני",
    singleCommentReply: "תגובה אחת",
    multipleCommentReply: "תגובות",
    noCommentReply: "הוספת תגובה..",
    showInText: "הצג מיקום בטקסט",
    thread: {
      reply: "הוספת תגובה..",
      postReply: "פרסום",
      cancelReply: "ביטול",
      newComment: "הוספת הערה"
    },
    buttons: {
      editReply: "עריכה",
      deleteReply: "מחיקה"
    },
    onDelete: "תגובה נמחקה"
  },
  logout: "ניתוק",
  "menu.tooltip.fontSize": "גודל פונט",
  "menu.tooltip.darkMode": "מצב כהה",
  "menu.tooltip.highlighter": "הגדרות הדגשה",
  "menu.tooltip.comments": "הערות",
  "view.profile": "פרטי משתמש",
  "view.admin": "ניהול קורסים",
  "gr.mode.3": "שלושה שלבים",
  "gr.mode.5": "חמישה שלבים",
  "gr.light.stage.1": "סקירה ושאלות",
  "gr.light.stage.1.instructions":
    "קראו את הכותרת הראשית, את כותרות המשנה ואת המשפט הראשון בכל פסקה. הוסיפו שאלות לגבי הטקסט. ",
  "gr.light.stage.1.instructions.task":
    "קראו את הכותרת הראשית, את כותרות המשנה ואת המשפט הראשון בכל פסקה. הוסיפו שאלות לגבי הטקסט. שימו לב לשאלות שניתנו על ידי המורה במידה ויש כאלה. ",
  "gr.light.stage.2": "קריאה בלשית",
  "gr.light.stage.2.instructions":
    "קראו את הטקסט תוך חיפוש אחר תשובות לשאלות שלכם. בכל פעם שאתם מוצאים תשובה - לחצו על השאלה המתאימה והדגישו את הקטע הרלוונטי בטקסט. ",
  "gr.light.stage.3": "ניסוח תשובות",
  "gr.light.stage.3.instructions": "ענו על כל שאלה במילים שלכם. ",
  "gr.full.stage.1": "סקירה",
  "gr.full.stage.1.instructions":
    "קראו את הכותרת הראשית, את האבסטרקט או המבוא - אם ישנם, את כותרות המשנה ואת המשפט הפותח של כל פסקה עד סוף המאמר. סמנו מושגים או משפטים שנראים לכם חשובים. נסו להתרשם מאופי המאמר, מן הבעיה או הנושא המרכזי שלו, מן הטענות ומן המסקנה.",
  "gr.full.stage.2": "שאלות",
  "gr.full.stage.2.instructions":
    "קראו את השאלות הכלליות הבאות. עברו שוב על הקטעים שסימנתם במהלך הסקירה וחשבו על שאלות נוספות העולות ביחס הטקסט. הוסיפו אותן לרשימה. ככל שהשאלות יהיו טובות ומדויקות יותר, כך ההבנה שלכם תהיה טובה יותר.",
  "gr.full.stage.3": "קריאה מעמיקה",
  "gr.full.stage.3.instructions":
    "קראו את המאמר כולו מראשיתו ועד סופו. במהלך הקריאה עליכם לאתר את התשובות לשאלות ולחבר בין כל שאלה לתשובות הרלוונטיות לה. כיצד עושים זאת בוחרים שאלה מן הרשימה ומסמנים את התשובות בטקסט. כל קטע שתסמנו יקושר אוטומטית לשאלה שבחרתם. ביכולתכם לתת לכל תשובה כותרת שתסייע לכם לזכור את ההקשר שלה",
  "gr.stage.4": "חזרה",
  "gr.stage.4.instructions":
    "חזרו על התשובות שקישרתם לכל שאלה. בדקו אם הן אכן עונות עליה. אתם יכולים למחוק, לערוך ולסדר מחדש את הקטעים שבחרתם. נסו ליצור תשובות קוהרנטיות שעונות על השאלה באופן מקיף ומדויק. ",
  "gr.stage.5": "תרגול",
  "gr.stage.5.instructions":
    "נסו לענות על השאלות במילים שלכם. אתם יכולים להציץ בקטעים ששמרתם במקרה הצורך. אחרי שעניתם תנו לעצמם פידבק. הפידבק הוא למענכם בלבד, אף אחד אחר לא רואה אותו. ",
  "gr.stage.5.ownWords": "במילים שלי",
  add: "הוספה",
  "gr.highlightsView": "תצוגת הדגשות",
  "gr.answersView": "תצוגת תשובות",
  "gr.addQuestion": "שאלה",
  "text.mark": "סמנו בטקסט",

  words: "מילים",
  "my.answer": "התשובה שלי",
  "gr.feedback.question": "איך היה?",
  "exerciseFeedback.1": "קלי קלות",
  "exerciseFeedback.2": "ככה ככה",
  "exerciseFeedback.3": "לא משהו",
  "sq3r.answer_dropped": "תשובה הועתקה",
  delete_msg: "נושא נמחק",
  "gr.sq3r_submit": "משימה נשלחה",
  "gr.next.question": "לשאלה הבאה",
  "gr.save.answer": "שמירת תשובה",
  "upgrade.msg": "יש גרסה חדשה לפנגיאה, אנא טענו מחדש את האפליקציה",
  "upgrade.reload": "״אישור",
  "gr.backToQuestion": "חזרה לשאלות",
  "gr.saving": "שומר..",
  "gr.answerSaved": "תשובה נשמרה",
  "feedback.reply.placholder": "השיבו להערה זאת",
  "feedback.title.placholder": "כותרת חדשה",
  "feedback.comment.placholder": "הערה חדשה",
  "actions.edit": "עריכה",
  "actions.delete": "מחיקה",
  "colors.green": "ירוק",
  "colors.cyan": "כחול",
  "colors.orange": "כתום",
  "colors.red": "אדום",
  "colors.pink": "ורוד",
  "colors.yellow": "צהוב",
  "highlight.highlightMode": "מצב הדגשה",
  "highlight.underline": "קו תחתון",
  "highlight.showMode": "הצגת הדגשות",
  interactions: {
    highlightsView: "תצוגת הדגשות",
    mandatory: "הדגשות חובה",
    optional: "הדגשות רשות"
  },
  task: {
    submit: "הגש",
    question: "שאלה",
    sidebar: {
      highlight: "הדגש",
      answer: "ענה"
    },
    stage: {
      yourHighlights: "ההדגשות שלך",
      highlight: "בחרו שאלה והדגישו את החלקים הרלוונטיים בטקסט",
      answer: "בחרו שאלה וענו עליה בהתאם להנחיות"
    },
    submission: {
      taskSubmission: "הגשת משימה",
      incomplete: "משימה לא הושלמה",
      complete: "האם להגיש את המשימה?",
      publish: "הגשה",
      submit: "הגשה",
      justSubmit: "רק סיים",
      submitAndCopy: "סיים והעתק",
      submitAnyway: "הגש בכל זאת",
      didntAnswer: "לא ענית על",
      missingCitations: "לא בחרת קטעים בטקסט ב",
      canceled: "הגשה בוטלה",
      continue: "המשך לעבוד בקורא"
    },

    statistics: {
      pageTitle: "דוח משימה",
      general: "נתונים כלליים",
      participants: "משתתפים",
      gradeAvrage: "ממוצע ציונים",
      timeManagement: "ניהול זמן",
      pending: "ממתין",
      onTime: "הוגש בזמן",
      late: "איחור",
      missed: "לא הוגש",
      gradeDist: "התפלגות ציונים",
      performance: "ביצועי תלמידים",
      noSubmissionsYet: "טרם הוגשו מטלות",
      rightOnTheSpot: "בדיוק במקום",
      prettyClose: "קרוב למדי",
      wayOff: "רחוק מאוד",
      studentsAnswers: "תשובות תלמידים",
      showHeatmap: "הראה מפת חום",
      correct: "נכון",
      keyword: "מילת מפתח",
      incorrect: "שגוי"
    },
    type: {
      openEnded: "שאלה פתוחה",
      findInText: "איתור בטקסט",
      multi: "בחירה מרובה"
    }
  },
  errorPage: {
    title: "אופס, משהו השתבש",
    body: "נסו לטעון את העמוד מחדש, ואם הבעיה ממשיכה פנו אלינו לאימייל support@alethea.co",
    btnText: "טען מחדש"
  },
  errorMessgaes: {
    deleteQuestion: "בעיה במחיקת שאלה"
  },
  "tasks.manager": "ניהול משימות",
  "tasks.create": "יצירת משימה",
  "feedback.textRef": "עיגון בטקסט",
  "tasks.student": "סטודנט",
  "task.readings": "חומרי קריאה",
  "task.submit": "הגשה",
  "task.questions": "שאלות",
  "task.question": "שאלה",
  "task.instructions": "הוראות",
  "task.answer": "תשובה",
  "task.answers": "תשובות",
  "task.and": "ו-",
  "task.dot": ".",
  "task.share": "שתף",
  "task.saved": "משימה נשמרה",
  "task.saveing.error": "השמירה נכשלה, אנא נסה שוב",
  "task.chooseText": "בחרו טקסט מהספרייה",
  "task.enterText": "שם המחבר/ת או הטקסט",
  "task.title": "שם המטלה",
  "task.type": "סוג המטלה",
  "task.publishing": "מפרסם מטלה",

  "task.highlightsToggler": "בחרו שאלה והדגישו את החלקים הרלוונטיים בטקסט",
  "task.standard.highlightsToggler": "בחרו שאלה וענו עליה בהתאם להנחיות",
  "task.description": "תיאור המטלה",
  "task.type.standard": "סטנדרטי",
  "task.type.peerReview": "ביקורת עמיתים",
  "task.type.guidedReading": "קריאה מודרכת",
  "task.type.find": "מצא בטקסט",
  "task.type.open": "שאלה פתוחה",
  "task.type.open.inYouOwnWords": "במילים שלך",
  "task.type.multiple": "שאלה אמריקאית",
  "task.question.formulation": "ניסוח שאלה",
  "task.question.type": "סוג שאלה",
  "task.question.peerReviewInstructions": "הנחיות לביקורת עמיתים",
  "task.question.peerReviewPlaceholder": " הוספת הנחיות למשיב ולמבקר",
  "task.find.instructions": "לסימון הקטעים הרלוונטיים בטקסט",
  "task.find.details": "המערכת תשווה בין תשובותיך לתשובות הסטודנט לצורך ניקוד",
  "task.points": "נקודות",
  "tasks.status": "סטטוס",
  "tasks.published": "תאריך פירסום",
  "tasks.deadline": "מועד הגשה",
  "tasks.dueDateChangedFailed": "שינוי מועד ההגשה נכשל, נא נסו שנית",
  "tasks.dueDateChanged": "מועד ההגשה עודכן בהצלחה",
  "tasks.personalDeadline": "מועד הגשה אישי",
  "tasks.editDeadline": "עריכת מועד הגשה",
  "tasks.responderDeadline": "מועד הגשה למגיב",
  "tasks.reviewerDeadline": "מועד הגשה למבקר",
  "tasks.taskInstructions": "הוראות",
  "tasks.general_evaluation": "משוב כללי",
  "tasks.general_evaluation_score": "הציון שלך",
  "tasks.status.submitted": "הוגש",
  "tasks.published_msg": "משימה פורסמה",
  "task.submission_msg": "משימה נשלחה",
  "task.feedback_sent": "משוב נשלח",
  "tasks.grade": "ציון",
  "task.timeFrame": "טווח זמן",
  "tasks.acceptUntil": "מועד הארכה",
  "tasks.pickDate": "בחירת תאריך",
  "tasks.feedback": "משוב",
  "task.question.feedback": "משוב על שאלה",
  "task.submission.copyInteractions": "העתק שאלות והדגשות לקריאה מודרכת",
  "task.submission.didntAddCitations": "לא בחרת קטעים בטקסט ב",
  "task.submission.grConfirmation": "אתה עומד להגיש מטלת קריאה מודרכת.",
  "task.submission.addGrWork": "האם להוסיף את כל העבודה לטקסט שבספרייה?",
  "task.feedback.confirmation": "האם להמשיך בשליחת המשוב?",
  "task.feedback.didntAddCommentOverAll": "לא הוספת משוב כללי",
  "task.feedback.didntAddComment": "$ANSWERS נותרו ללא משוב",
  "task.feedback.didntAddPoint": "לא נתת ניקוד על $POINTS",
  "tasks.task": "משימה",
  "tasks.submitting.error": "הגשת המשימה נכשלה, אנא נסו שוב",
  "tasks.submitting": "מגיש משימה",
  "tasks.submitted": "המשימה הוגשה",
  "tasks.status.missed": "לא הוגש",
  "tasks.status.delivered": "נבדק",
  "task.feedback.link.answer": "קישור לתשובת הסטודנט",
  "task.feedback.link.text": "קישור לטקסט ",
  "task.feedback.submit": "שליחה",
  "task.edit.msg": "עריכת משימה",
  "task.edit.warning": "המשימה נשלחה כבר, האם ברצונך לערוך אותה בכל זאת?",
  "tasks.edit.SaveTask": "שמירת משימה",
  "tasks.status.graded": "נבדק",
  "tasks.status.active": "פעיל",
  "tasks.feedback.add": "הוספה",
  "tasks.feedback.sent": "נשלח",
  "task.addOption": "הוסיפו תשובה חדשה",
  "tasks.feedback.see": "ראה",
  "task.open.concepts": "מילות מפתח",
  "task.includeCitation": "להוספת קטעים לסימון",
  "task.findCitation": "מצא קטעים רלוונטים בטקסט",
  "task.selectCitation": "בחרו קטעים מהטקסט",
  "task.addCitation": "להוספת קטעים לסימון",
  "task.deletedQuestion": "שאלה נמחקה",
  "tasks.see.feedback": "משוב",
  "task.create.multiple.instructions": "הוסיפו אפשרויות וסמנו את התשובה הנכונה",
  "task.answer.multiple.instructions": "סמן את התשובה הנכונה",
  "task.concept": "מילת מפתח",
  "task.wordLimit": "מספר מלים",
  "tasks.create.validation.selectReading": "בחרו טקסט מהספרייה",
  "tasks.create.validation.enterTitle": "הוסף שם מטלה",
  "tasks.create.validation.enterQuestion": "הוסף שאלה",
  "tasks.create.validation.enterFormulation": "הוסף ניסוח שאלה לשאלה מספר",
  "tasks.create.validation.selectType": "בחרו סוג שאלה לשאלה מספר",
  "tasks.create.validation.addPoints": "הוסף ניקוד לשאלה מספר",
  "tasks.create.validation.pointsValue": "שנה את הניקוד למספר חיובי בשאלה",
  "tasks.create.validation.enterDeadline": "בחרו תאריך הגשה",
  "tasks.create.validation.deadlinePast":
    "תאריך ההגשה שבחרת עבר, בחרו תאריך חדש",
  "tasks.create.validation.deadlineOrder":
    "תאריך ההגשה השני שבחרת קודם לתאריך ההגשה הראשון",

  "tasks.create.beforYouLeave": "לפני שאתם עוזבים",
  "tasks.create.SaveAsDraft": "האם לשמור כטיוטה?",
  "tasks.edit.noChange": "לא נעשו שינויים במשימה",
  "tasks.create.SaveDraft": "שמור טיוטה",
  "task.deleted": "משימה נמחקה",
  "task.deleteFailed": "המחיקה נכשלה, אנא נסו שנית",
  discard: "עזוב (ללא שמירה)",
  "task.answer.citationTip.header": "טיפ",
  "task.answer.citationTip.body":
    "בחרו קטעים רלוונטיים בטקסט ולאחר מכן השב במילים שלך",
  "task.taskmanager.viewTaskForm": "הצג משימה",
  "task.taskmanager.taskStats": "הצג סטטיסטיקה",
  "task.peerReview.modal.title": "שתף על מנת לקבל ביקורת",
  "task.peerReview.modal.content": "בחר עם מי לשתף",
  "got.it": "הבנתי",
  "learn.more": "הסבר מפורט",
  Draft: "טיוטה",
  "draft.deleteDraft": "מחק טיוטה",
  "draft.editDraft": "ערוך טיוטה",
  Submitted: "נשלחה",
  "tasks.submission_time": "הוגש ב",
  "contactUs.success": "ההודעה נשלחה",
  "contactUs.fail": "ההודעה לא נשלחה בגלל בעיה טכנית, אנא נסו שוב",
  "menu.tooltip.comingSoon": "בקרוב",
  gr: {
    misc: {
      learnMore: {
        close: "הבנתי"
      }
    },
    light: {
      step: {
        1: {
          learnMore: `<h2>סקירה מהירה</h2>
          <p>על מנת להבין את הטקסט בקלות, חשוב קודם כל להתרשם מהנושא, מהסוגיות המרכזיות, מהטענות ומן המסקנה.{br}סמנו את הכותרת הראשית, את כותרות המשנה, את המשפט הראשון בכל פסקה וכל ביטוי או מושג אחר שנראה לכם חשוב. כל קטע שתסמנו יודגש באופן אוטומטי. ניתן לבטל את ההדגשות בכל שלב - פשוט לחצו על הטקסט המודגש ואז על ה׳מרקר׳ שמופיע מעליו.</p>
          <h2>טיפים</h2>
          <ul>
            <li>נסו להשלים את התהליך ברצף. לא כדאי לסכם את הטקסט או להיכנס לפרטים בשלב הזה.</li>
            <li>קחו את הזמן כדי להבין את המשפטים שאתם מסמנים אך אבל מבלי להתעכב. נסו להיות יעילים.</li>
          </ul>
          <h2>שאילת שאלות</h2>
          <p>לאחר סקירת הטקסט חשבו על שאלות לגבי החלקים שסימנתם. קריאת הטקסט תוך חיפוש תשובות לשאלות שלכם תהיה ממוקדת, מהירה ויעילה יותר, ותסייע לכם להבין את הטקסט ביתר קלות. כדי להוסיף שאלה לחצו על כפתור ״הוספת שאלה״ והקלידו אותה. ניתן לערוך ולמחוק שאלות באמצעות התפריט הצמוד לכל שאלה.</p>
          <h2>טיפים</h2>
          <ul>
            <li>שאלות טובות וממוקדות יעזרו לכם בהמשך ויאפשרו לכם להבין את הטקסט בקלות.</li>
            <li>השתמשו ב״תצוגת הדגשות״ כדי לסקור את כל ההדגשות שלכם .</li>
          </ul>`
        },
        2: {
          learnMore: `<h2>קריאה בלשית - חיפוש אחר תשובות לשאלות</h2>
          <p>שיטת הקריאה הבלשית הופכת את תהליך הקריאה לממוקד, יעיל ומשמעותי יותר. השיטה חוסכת לכם זמן ומעודדת הבנה טובה יותר של הטקסט. בנוסף, כרטיסיות התשובה שלכם נשמרות וממשיכות לשמש אתכם בעתיד - לקראת שיעור, בחינה או כתיבת עבודה. אתם תמיד יכולים לחזור בקלות אל החלקים החשובים ביותר בטקסט.</p>
          <h2>איך ליצור כרטיסיות תשובה</h2>
          <p>כשאתם נתקלים בתשובה לאחת מן השאלות שלכם, לחצו על השאלה וסמנו את החלק הרלוונטי בטקסט. הטקסט שסימנתם יודגש ויישמר ככרטיסיית תשובה. אתם יכולים לראות את הכרטיסיות על ידי לחיצה על החץ הצמוד לכל שאלה או על ידי שימוש ב״תצוגת תשובות״. אתם יכולים להעתיק תשובה משאלה אחת לאחרת על ידי גרירה של הכרטיסיה. הכרטיסיה תקושר אוטומטית לשתי השאלות.</p>
          <h2>טיפים</h2>
          <ul>
            <li>תנו לכרטיסיות שלכם כותרות - הכותרות עוזרות לכם לארגן את החומרים ומסייעות בשימור התשובות בזיכרון לאורך זמן.</li>
            <li>השתמשו ב״תצוגת תשובות״ כדי לארגן את הכרטיסיות שלכם ולהסתיר כרטיסיות מיותרות. עברו על הכותרות והתאימו אותן לרצף שנוצר.</li>
          </ul>`
        },
        3: {
          learnMore: `<h2>חשיבות התרגול</h2>
              <p>תרגול עצמאי הוא כלי שבעזרתו תוכלו להבין איפה אתם עומדים ביחס לטקסט. ניסוח תשובות במילים שלכם ומתן פידבק עצמי יאפשרו לכם לבחון את רמת ההבנה והביטחון שלכם ביחס למה שקראתם, ויסייעו לכם במציאת נושאים הדורשים עוד עבודה. עם סיום השלב הזה אתם מוכנים להיבחן, לכתוב עבודה או להשתתף בדיון בנוגע לטקסט.</p>
              <h2>איך לתרגל</h2>
              <p>לחצו על אחת השאלות וכתבו את התשובה בחלון המיועד לכך. אתם מוזמנים להסתכל בכרטיסיות שלכם בכל שלב אבל הקפידו לדבוק במילים ובנוסחים מקוריים שלכם. כשאתם מסיימים לענות על שאלה תנו לעצמכם פידבק ועברו לשאלה הבאה. אתם תמיד יכולים לחזור ולשנות את תשובתכם עד שתרגישו איתה בנוח.</p>
              <h2>טיפים</h2>
              <ul>
                <li>פידבק עוזר לכם להבחין בין החומר שהטמעתם לבין חומר שדורש עוד עבודה. השתמשו בכלי הזה כדי להמשיך לשפר ולהעמיק את ההבנה שלכם. אל תדאגו, הפידבק הוא למענכם ולעיניכם בלבד, רק אתם יכולים לראות אותו.</li>
              </ul>`
        }
      }
    },
    full: {
      step: {
        1: {
          learnMore: `<h2>למה סקירה מהירה?</h2>
          <p>על מנת להבין את הטקסט בקלות, חשוב קודם כל להתרשם מהנושא, מהסוגיות המרכזיות, מהטענות ומהמסקנה. אם סוקרים את הטקסט לפני שעוברים לקריאה מעמיקה, הקריאה הופכת פשוטה יותר ומהירה יותר.</p>
          <h2>איך להשתמש בסקירה מהירה</h2>
          <p>סמנו את המשפטים הראשונים בכל פסקה, וכל ביטוי או מושג אחר שנראה לכם חשוב. תמיד תוכלו לשנות את דעתכם ולבטל את ההדגשות - פשוט לחצו על הטקסט המודגש ואז על ה׳מרקר׳ שמופיע מעליו.</p>
          <h2>טיפים</h2>
          <ul>
            <li>נסו להשלים את התהליך ברצף. אל תסכמו או תיכנסו לפרטים עדיין.</li>
            <li>קחו את הזמן כדי להבין את המשפטים שאתם מסמנים, אבל מבלי להתעכב. נסו להיות יעילים.</li>
            <li>אם יעלו לכם שאלות במהלך תהליך הסקירה זה מצוין, שמרו אותן לשלב הבא.</li>
          </ul>`
        },
        2: {
          learnMore: `<h2>למה שאילת שאלות?</h2>
          <ul>
            <li>שאילת שאלות לקראת הקריאה מעוררת את הסקרנות הטבעית שלכם ומשלבת את הגישה הייחודית שלכם בתהליך הלימודי.</li>
            <li>חיפוש תשובות לשאלות ממקד את תשומת הלב במהלך הקריאה, וחוסך זמן ומאמץ.</li>
            <li>שאילת שאלות היא הבסיס של כל פרויקט מחקרי משמעותי. זוהי ההזדמנות שלכם לחדד את הכלי החיוני הזה.</li>
          </ul>
          <h2>איך להשתמש בשאילת שאלות</h2>
          <ul>
            <li>כדי להוסיף שאלה לחצו על כפתור ״הוספת שאלה״ והקלידו אותה.</li>
            <li>ניתן לערוך ולמחוק שאלות באמצעות התפריט הצמוד לכל שאלה.</li>
            <li>ניתן לסדר את הרשימה לפי ראות עיניכם על ידי גרירת השאלות מעלה או מטה.</li>
          </ul>
          <h2>טיפים</h2>
          <ul>
            <li>נסחו שאלות שמעניינות אתכם - השתמשו בסקרנות הטבעית שלכם ובתחומי העניין הייחודיים לכם בתור השראה.</li>
            <li>שאלות טובות וממוקדות יעזרו לכם בהמשך ויאפשרו לכם להבין את הטקסט בקלות.</li>
          </ul>`
        },
        3: {
          learnMore: `<h2>למה קריאה בלשית?</h2>
              <p>שיטת הקריאה הבלשית הופכת את תהליך הקריאה לממוקד, יעיל ומשמעותי יותר. השיטה חוסכת לכם זמן ומעודדת הבנה טובה יותר של הטקסט. בנוסף, כרטיסיות התשובה שלכם נשמרות וממשיכות לשמש אתכם בעתיד - לקראת שיעור, בחינה או כתיבת עבודה. אתם תמיד יכולים לחזור בקלות אל החלקים החשובים ביותר בטקסט.</p>
              <h2>איך להשתמש בקריאה בלשית</h2>
              <p>סמנו כל תשובה שנראית לכם רלוונטית. אתם יכולים לקשר תשובה אחת ליותר משאלה אחת. כדי לראות את הכרטיסיות שלכם לחצו על החץ הקטן הצמוד לכל שאלה. אתם יכולים לשנות את דעתכם ולמחוק כרטיסיות לפי הצורך. הוסיפו כותרות לכרטיסיות באמצעות מילוי שדה הכותרת.</p>
              <h2>טיפים</h2>
              <ul>
                <li>תנו לכרטיסיות שלכם כותרות - הכותרות עוזרות לכם לארגן את החומרים ומסייעות בשימור התשובות בזיכרון לאורך זמן.</li>
                <li>לפעמים צריך לנסח את השאלות מחדש בעקבות הקריאה - זה חלק מהתהליך. פשוט לחצו על ״עריכה״ בתפריט הצמוד לשאלה.</li>
              </ul>`
        },
        4: {
          learnMore: `<h2>למה מבט על?</h2>
              <p>עכשיו שהעבודה הקשה מאחוריכם, הגיע הזמן לארגן, לתעדף ולחבר את התשובות שמצאתם לתמונה אחת ברורה. ככל שהתמונה מתבהרת תוכלו לזהות את הקשרים הסמויים בין חלקי הטקסט השונים. השלב הזה אולי נראה פשוט אבל למעשה אתם משתמשים כאן בכישורים קוגנטיביים גבוהים, מה שמגדיל את הסיכויים לשמירת הידע בזיכרון לטווח ארוך.</p>
              <h2>איך להשתמש במבט על</h2>
              <p>ארגנו את הכרטיסיות בצורה שנראית לכם הגיונית{br}הסתירו כרטיסיות שנראות לכם לא רלוונטיות באמצעות לחיצה על העין{br}"עברו על הכותרות והתאימו אותן לרצף שנוצר{br}כדי למחוק כרטיסייה לחצו על ״הראה בטקסט״ ואז על מחיקה</p>`
        },
        5: {
          learnMore: `<h2>למה בחן את עצמך?</h2>
              <p>בחינה עצמית היא כלי שבעזרתו תוכלו להביו איפה אתם עומדים ביחס לטקסט. ניסוח תשובות במילים שלכם ומתן פידבק עצמאי יאפשרו לכם לבחון את רמת ההבנה והביטחון שלכם ביחס למה שקראתם, ויסייעו לכם במציאת הדברים שדורשים עוד עבודה. עם סיום השלב הזה אתם מוכנים להיבחן, לכתוב עבודה או להשתתף בדיון בנוגע לטקסט.</p>
              <h2>איך להשתמש בבחן את עצמך</h2>
              <p>בחרו שאלה על ידי לחיצה וכתבו את התשובה בחלון המיועד לכך. אתם מוזמנים להסתכל בכרטיסיות שלכם בכל שלב אבל הקפידו לדבוק במילים ובנוסחים מקוריים שלכם. כשאתם מסיימים לענות על שאלה תנו לעצמכם פידבק ועברו לשאלה הבאה. אתם תמיד יכולים לחזור אחורה ולשנות את תשובתכם עד שתרגישו בנוח איתה.</p>
              <h2>טיפים</h2>
              <p>פידבק עוזר לכם להבחין בין הדברים שהבנתם לבין הדברים שדורשים עוד עבודה. השתמשו בכלי הזה כדי להמשיך לשפר ולהעמיק את ההבנה שלכם. אל תדאגו, הפידבק הוא למענכם ולעיניכם בלבד, רק אתם יכולים לראות אותו.</p>`
        }
      }
    },
    requiredQuestion: "שאלת חובה"
  },
  "admin.courseAdmin": "ניהול קורסים",
  "undo.undoPublishTaskFailed": "ביטול פרסום המשימה נכשל",
  "undo.undoSubmissionFailed": "ביטול הגשת המשימה נכשל",
  "error.deleteFaild": "המחיקה נכשלה, אנא נסו שוב",
  "error.createCommentFaild": "שליחת ההודעה נכשלה, אנא נסו שוב",
  "error.readCourseUsersFailed": "קריאת נתוני הקורס נכשלה, אנא נסו שוב",
  "icon.enterFullScreen": "הגדל",
  "icon.exitFullScreen": "הקטן",

  "profilePage.myProfile": "הפרופיל שלי",
  "profilePage.name": "שם",
  "profilePage.language": "שפה",
  "profilePage.alertsDuration": "זמן התראות",
  "profilePage.email": "דואר אלקטרוני",
  "profilePage.addProfilePicture": "הוסף תמונת פרופיל",
  "profilePage.seconds": "שניות",
  "error.uploadingAvatarFailed": "העלאת התמונה נכשלה, אנא נסו שנית",
  "error.fileTypeNotAllowed": "אנא בחרו קובץ תמונה",
  "error.exceededSizeLimitation": "הקובץ שהחרתם גדול מידי, אנא נסו קובץ אחר",
  "reports.studentReport": "דו״ח סטודנט",

  // error messages
  "error.undoFailed": "ביטול הפעולה נכשל",
  "error.defaultSavingError": "השמירה נכשלה, אנא נסו שוב",
  "error.saveingAnswerFailed": "השמירה נכשלה, אנא נסו שוב",

  // For tests
  "test.intl": "טקסט מקובץ תרגום"
};
export default he;
