import clsx from "clsx";

import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import makeStyles from "@mui/styles/makeStyles";
import { TextField, Box, Typography } from "@mui/material";

import { useDispatch, useSelector } from "react-redux";
import { setCurrentPage, setPdfZoom } from "../../redux/pdfSlice";
import { scrollPageIntoView } from "../reader/pdf/utils";
import DirectionalChevronIcon from "../SharedComponents/DirectionalChevronIcon";
import HeaderButtonConstructor from "../SharedComponents/HeadeButtonConstructor";

const useStyles = makeStyles(theme => ({
  barIcon: {
    color: "#FFFFFF",
    backgroundColor: "transparent",
    "&:hover": {
      backgroundColor: "rgba(255, 255, 255, 0.1)"
    }
  },
  pageNumbering: {
    color: "#FFFFFF",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    "& input[type=number]": {
      "-moz-appearance": "textfield"
    },
    "& input[type=number]::-webkit-outer-spin-button": {
      color: "#FFFFFF",
      margin: 0
    },
    "& input[type=number]::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0
    }
  },
  pageNavInput: {
    marginRight: theme.spacing(1.5),
    display: "inline-block",
    marginTop: "7px",
    width: "30px",
    "&:hover": {
      borderBottom: `1px solid white`,
      textDecoration: "none",
      "-webkit-appearance": "none"
    },
    "&:active": {
      borderBottom: `1px solid white`,
      textDecoration: "none"
    },
    "& input": {
      borderBottom: `1px solid white`,
      backgroundColor: "transparent",
      textDecorationColor: "white",
      textAlign: "end",
      maxHeight: "50px",
      "-moz-appearance": "textfield",
      " -webkit-outer-spin-button": {
        "-webkit-appearance": "none",
        margin: 0
      },
      color: "#FFFFFF",
      fontSize: "16px"
    }
  },
  totalPages: {
    display: "inline-block",
    marginLeft: theme.spacing(1.5),
    marginTop: "10px",
    color: "#FFFFFF",
    marginRight: theme.spacing(1)
  }
}));

export const PdfControls = () => {
  // Hooks
  const dispatch = useDispatch();
  const classes = useStyles();

  // Global State
  const totalPages = useSelector(state => state.pdf.totalPages);
  const currentPage = useSelector(state => state.pdf.currentPage);
  const zoom = useSelector(state => state.pdf.zoom);

  // Derived State

  //Behavior

  return (
    <>
      <Box>
        <HeaderButtonConstructor
          intlStringId="pdf.zoomOut"
          defaultMessage="Zoom out"
          placement="bottom"
          aria-label="zoom out"
          className={clsx(classes.barIcon)}
          handleClick={() => {
            if (zoom > 0.1) {
              dispatch(setPdfZoom(zoom - 0.1));
            }
          }}
          icon={<ZoomOutIcon />}
        />
      </Box>
      <Box>
        <HeaderButtonConstructor
          intlStringId="pdf.zoomIn"
          defaultMessage="Zoom In"
          placement="bottom"
          aria-label="zoomIn"
          className={clsx(classes.barIcon)}
          handleClick={() => {
            dispatch(setPdfZoom(zoom + 0.1));
          }}
          icon={<ZoomInIcon />}
        />
      </Box>
      <Box>
        <HeaderButtonConstructor
          intlStringId="pdf.previousPage"
          defaultMessage="Previous page"
          placement="bottom"
          aria-label="Previous page"
          disabled={currentPage === 1}
          className={clsx(classes.barIcon)}
          handleClick={() => {
            const options = {
              behavior: "smooth",
              block: "start"
            };
            scrollPageIntoView(currentPage - 1, options);
          }}
          icon={<DirectionalChevronIcon reverse />}
        />
      </Box>
      <Typography component="span" className={classes.pageNumbering}>
        <TextField
          variant="standard"
          className={classes.pageNavInput}
          id="pdf-current-page-nav-input"
          aria-label="task-overall-points-input"
          type="number"
          InputProps={{ disableUnderline: false }}
          value={currentPage}
          onChange={e => {
            const options = {
              inline: "start",
              block: "start"
            };
            const pageNumber = Number(e.target.value);
            dispatch(setCurrentPage(pageNumber));
            scrollPageIntoView(pageNumber, options);
          }}
        />
        <Typography component="span" className={classes.totalPages}>
          {" / "}
        </Typography>
        <Typography component="span" className={classes.totalPages}>
          {totalPages || " "}
        </Typography>
      </Typography>
      <Box>
        <HeaderButtonConstructor
          intlStringId="pdf.nextPage"
          defaultMessage="Next page"
          placement="bottom"
          aria-label="next Page"
          disabled={currentPage === totalPages}
          className={clsx(classes.barIcon)}
          handleClick={() => {
            const options = {
              behavior: "smooth",
              block: "start"
            };
            scrollPageIntoView(currentPage + 1, options);
          }}
          icon={<DirectionalChevronIcon />}
        />
      </Box>
    </>
  );
};
