//Dependancies
import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { motion } from "framer-motion";

// Material UI
import makeStyles from "@mui/styles/makeStyles";
import { useTheme } from "@mui/material";

// Styles
const useStyles = makeStyles(theme => {
  return {
    container: {
      display: "inline-block",
      height: "100%",
      backgroundColor: "transparent"
    },
    small: { marginInlineStart: theme.spacing(0.5) },
    large: { marginInlineStart: theme.spacing(1) }
  };
});

export default function BouncingDots({
  NumberOfDots = 3,
  size = "small",
  color
}) {
  const classes = useStyles();
  const theme = useTheme();

  const isLarge = size === "large";
  const radius = isLarge ? 2 : 1;
  const diameter = radius * 2;
  const margin = isLarge ? 4 : 2;
  const dimWithMargin = diameter + margin;

  return (
    <svg
      style={{
        height: isLarge ? 12 : 8,
        width: NumberOfDots * dimWithMargin
      }}
      className={clsx(classes.container, classes[size])}
    >
      {Array.from({ length: NumberOfDots }, () => ".").map((dot, i) => {
        const delay = i / 10; // Ex. 0.1, 0.2...
        const x = radius + i * (diameter + margin);
        return (
          <motion.circle
            key={i}
            cx={x}
            cy={7}
            r={radius}
            style={{
              fill: color || theme.palette.text.primary
            }}
            animate={{ y: [0, -3, 0] }}
            transition={{ repeat: Infinity, duration: 0.8, delay }}
          />
        );
      })}
    </svg>
  );
}

BouncingDots.propTypes = {
  NumberOfDots: PropTypes.number,
  size: PropTypes.oneOf(["small", "large"]),
  color: PropTypes.string
};
