// Dependencies
import React, { useRef, useState, useEffect } from "react";
import { firebaseApp, firebaseFunctions } from "../../../firebase";
import { FormattedMessage, injectIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import clsx from "clsx";
import { useQuery } from "../../../hooks";
import { captureException } from "../../../utils/errorHandlers";
import { mapConcepts, calculateFindInTextScore } from "./utils";
import { isEmpty } from "lodash";

// Redux dependencies
import { useSelector, useDispatch } from "react-redux";
import {
  selectCurrentText,
  setSelectedTextId,
  setTextUrl
} from "../../../redux/textsSlice";
import {
  selectSubmission,
  selectTask,
  updateSubmissionIsChecked
} from "../../../redux/tasksSlice";
import { selectCourseByTaskId } from "../../../redux/coursesSlice";

import { sendFeedback } from "../../../redux/taskSlice";

import {
  selectSubmissionTaskFeedback,
  selectSubmissionQuestions
} from "../../../redux/interactionsSlice";
import { setBreadcrumbs } from "../../../redux/readerActionsSlice";

// Components
import TaskFeedbackSidebar from "./TaskFeedbackSidebar";
import FeedbackScreen from "./FeedbackScreen";
import FeedbackSubmmisionModal from "./FeedbackSubmissionModal";
import FeedbackValidationModal from "./FeedbackValidationModal";
import PeerReview from "../PeerReview/PeerReview";

import makeStyles from "@mui/styles/makeStyles";
import EditIcon from "@mui/icons-material/Edit";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  InputAdornment,
  IconButton,
  TextField,
  Typography
} from "@mui/material";
import PangeaSpinner from "../../SharedComponents/PangeaSpinner";
import { selectTextDirection } from "../../../redux/firestoreSelectors";
import useCreatePortal from "../../../hooks/useCreatePortal";

//Styles
const useStyles = makeStyles(theme => {
  return {
    drawerContainer: {
      position: "relative",
      width: "100%"
    },
    btnContainer: {
      position: "absolute",
      right: "16px"
    },
    actions: {
      padding: "16px",
      position: "relative",
      display: "block",
      minHeight: "64px"
    },
    pointsMsg: {
      display: "inline-block",
      lineHeight: "34px"
    },
    points: {
      display: "inline-block",
      border: "1px solid #168fee",
      borderRadius: "4px",
      paddingLeft: "8px",
      paddingRight: "8px",
      width: "64px",
      "& input": {
        textAlign: "center"
      }
    },
    adornment: {
      color: "#168fee"
    },
    eval: {
      color: "#168fee",
      fontSize: "20px",
      lineHeight: "32px",
      "& ::placeholder": {
        /* Chrome, Firefox, Opera, Safari 10.1+ */ color: "#168fee",
        opacity: 1
      },
      "& button": {
        visibility: "hidden"
      },
      "&:hover button": {
        visibility: "visible"
      }
    },
    writenEval: {
      "& :focus::placeholder": {
        color: "#bdc1c9"
      },
      "& ::placeholder": {
        color: "#9197a3"
      }
    },
    hidden: {
      visibility: "hidden",
      fontSize: "20px",
      position: "absolute",
      maxWidth: "95%"
    },
    content: {
      position: "relative",
      width: "100%",
      height: "max-content",
      minHeight: "calc(100vh - 120px)",
      display: "grid"
    },
    buttonCancel: {
      color: "#787877"
    },
    button: { textTransform: "none" }
  };
});

function TaskFeedback({ intl }) {
  // Hooks
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const evalTitleSpanRef = useRef();
  const editTitleRef = useRef();
  const ActionButtonPortal = useCreatePortal(
    document && document.getElementById("global-action-btn")
  );
  let { submission_id } = useQuery();

  // Redux state
  const textDirection = useSelector(state => selectTextDirection(state));

  const submission = useSelector(state =>
    selectSubmission(state, Number(submission_id))
  );
  const task = useSelector(state => selectTask(state, submission.task_id));

  const text = useSelector(selectCurrentText);
  const questions = useSelector(selectSubmissionQuestions);
  const currentQuestionId = useSelector(
    state => state.interactions.selectedInteractionId
  );
  const highlights = useSelector(state => state.interactions.highlights);
  const answers = useSelector(state => state.interactions.answers);
  const feedbacks = useSelector(state => state.interactions.feedbacks);
  const taskEval = useSelector(state =>
    selectSubmissionTaskFeedback(state, Number(submission_id))
  );

  const selectedQuestionIndex = useSelector(
    state => state.tasks.selectedQuestionIndex
  );

  const course = useSelector(state =>
    selectCourseByTaskId(state, submission.task_id)
  );
  // Ephemeral state
  const [isLoading, setIsLoading] = useState(false);
  const [evalTitle, setEvalTitle] = useState("");
  const [editingTitle, setEditingTitle] = useState(false);
  const [feedbackComment, setFeedbackComment] = useState("");
  const [grade, setGrade] = useState(0);
  const [conceptMapping, setConceptMapping] = useState([]);
  const [highlightMatch, setHighlightMatch] = useState(0);
  const [openEval, setOpenEval] = useState(submission.is_checked);
  const [showSubmissionDialog, setShowSubmissionDialog] = useState(false);
  const [dialogValidationResult, setDialogValidationResult] = useState({
    grade: true,
    comment: true
  });
  const [feedbackValidationResult, setFeedbackValidationResult] = useState({
    noPoints: [],
    invalidPoints: [],
    comment: []
  });

  const [showValidationDialog, setShowValidationDialog] = useState(false);

  // Derived state
  const isTeacher = course.course_role === "Teacher";
  const isChecked = submission.is_checked;
  const currentUser = firebaseApp.auth().currentUser;
  const totalPoints = questions.reduce((accumulator, current) => {
    // TODO: check that points can only be a number of a string representing a number and get rid of the check
    const points =
      current.points && !isNaN(current.points) ? Number(current.points) : 0;
    return accumulator + points;
  }, 0);

  const titlePlaceholder = intl.formatMessage({
    defaultMessage: "Enter evaluation title",
    id: "task.title.placeholder"
  });

  // Behavior

  //set Breadcrumbs
  useEffect(() => {
    let parts = [];
    // let course = false;
    parts.push({
      course: course.id,
      url: "/tasks",
      text: intl.formatMessage({ id: "appBar.tasks", defaultMessage: "Tasks" })
    });

    course.name &&
      parts.push({
        course: course.id,
        url: `/tasks?course_id=${course.id}`,
        text: course.name
      });

    task.name &&
      parts.push({
        course: course.id,
        url: `/tasks?course_id=${task.course_id}&task_id=${task.id}&submission_id=${submission_id}`,

        text: task.name
      });

    submission.user_name &&
      submission.owner !== currentUser.uid &&
      parts.push({
        course: course.id,
        url: `/task?submission_id=${submission_id}`,
        text: submission.user_name
      });
    dispatch(setBreadcrumbs({ breadcrumbs: parts, blue: true }));
  }, [
    currentUser.uid,
    dispatch,
    intl,
    submission.owner,
    submission_id,
    task.course_id,
    task.id,
    task.name,
    task.student,
    submission.user_name,
    course.name,
    course.id
  ]);

  useEffect(() => {
    if (!task.text_id) return;
    dispatch(setSelectedTextId(task.text_id));
  }, [dispatch, task.text_id]);

  useEffect(() => {
    if (!text.course_id || !text.file_url) return;
    //TODO: check if we can also skip this fetch if there is selectedText.url
    firebaseApp
      .storage()
      .ref("courseTexts/" + text.course_id)
      .child(text.file_url)
      .getDownloadURL()
      .then(url => {
        dispatch(setTextUrl({ url, text_id: text.id }));
      });
  }, [dispatch, text.course_id, text.file_url]);

  useEffect(() => {
    if (taskEval?.id) {
      setEvalTitle(taskEval.title || "");
      setFeedbackComment(taskEval.content || "");
    }
  }, [taskEval?.id]);

  useEffect(() => {
    if (!currentQuestionId) return;

    const answer = answers.find(
      answer => answer.interaction_id === currentQuestionId
    );

    const question = questions.find(
      question => question.id === currentQuestionId
    );

    const conceptMap = mapConcepts(question, answer);
    setConceptMapping(conceptMap);
  }, [answers, currentQuestionId, questions]);

  useEffect(() => {
    if (!currentQuestionId) return;

    const question = questions.find(
      question => question.id === currentQuestionId
    );
    const studentHighlights = highlights.filter(
      highlight => highlight.interaction_id === currentQuestionId
    );

    const score = calculateFindInTextScore(question, studentHighlights);
    setHighlightMatch(score);
  }, [currentQuestionId, highlights, questions]);

  useEffect(() => {
    setGrade(
      feedbacks.reduce((acc, curr) => {
        return acc + (Number(curr.points) || 0);
      }, 0)
    );
  }, [feedbacks]);

  useEffect(() => {
    setOpenEval(isChecked);
  }, [isChecked]);

  const titleEditMargin = () => {
    let marginVal = "0px";
    marginVal =
      evalTitleSpanRef && evalTitleSpanRef.current
        ? evalTitleSpanRef.current.getBoundingClientRect().width + "px"
        : evalTitle * 0.7 + "ch";

    if (textDirection === "rtl") return { marginRight: marginVal };
    else return { marginLeft: marginVal };
  };

  const renderPoints = grade => {
    if (!isChecked || task.max_grade || submission.grade > 0) {
      return (
        <>
          <TextField
            variant="standard"
            className={classes.points}
            id="standard-number"
            type="number"
            InputProps={{
              disableUnderline: true,
              readOnly: isChecked
            }}
            aria-label="task-feedback-points-input"
            inputProps={{
              "aria-label": `Points out of ${totalPoints}`
            }}
            defaultValue={
              isChecked
                ? submission.grade !== null
                  ? submission.grade
                  : ""
                : grade
            }
            onChange={e => {
              setGrade(Math.round(e.target.value));
            }}
          />
          <Typography
            className={classes.pointsMsg}
            variant="body1"
            component="span"
          >
            {" "}
            <FormattedMessage
              defaultMessage="Points out of"
              id="task.points_of"
            />{" "}
            {totalPoints}
          </Typography>
        </>
      );
    }
  };

  const getDialogTitle = () => {
    if (!isChecked || submission.feedbackcomment || submission.evaltitle) {
      return (
        <FormattedMessage
          id="tasks.general_evaluation"
          defaultMessage="Overall evaluation"
        />
      );
    } else
      return (
        <FormattedMessage
          id="tasks.general_evaluation_score"
          defaultMessage="Your score"
        />
      );
  };

  function validateFeedback() {
    // Return and object with arrays of invalid answers and citations
    const noPoints = [];
    const invalidPoints = [];
    const comment = [];

    questions.forEach((question, index) => {
      const questionFeedback = feedbacks.find(
        questionFeedback => questionFeedback.interaction_id === question.id
      );
      if (!questionFeedback) {
        comment.push(index);
        return;
      }
      if (question.points > 0 && !questionFeedback) {
        noPoints.push(index);
        return;
      }
      if (
        !questionFeedback.points ||
        questionFeedback.points === "" ||
        questionFeedback.points < 0 ||
        Number.isNaN(questionFeedback.points)
      ) {
        noPoints.push(index);
      } else if (Number(questionFeedback.points) > Number(question.points)) {
        invalidPoints.push(index);
      }

      // the rich text editor returns an array of text lines
      // checkling to see if at least one line isn't empty
      let commentIsValid = false;
      if (questionFeedback.rich_text && questionFeedback.rich_text.blocks) {
        commentIsValid = questionFeedback.rich_text.blocks.some(
          element => element.text !== ""
        );
      }

      if (
        !commentIsValid &&
        (!questionFeedback.title || questionFeedback.title === "")
      ) {
        comment.push(index);
      }
    });
    setFeedbackValidationResult({ noPoints, invalidPoints, comment });

    // if something failed validation - open the dialog
    if (noPoints.length > 0 || invalidPoints.length > 0 || comment.length > 0) {
      setShowValidationDialog(true);
    } else {
      setOpenEval(true);
    }
    // }
  }

  function validateDialog() {
    setDialogValidationResult({
      grade: true,
      comment: true
    });

    let gradePresent = true;
    let commentPresent = true;

    if (Number.isNaN(grade) || grade === "") {
      gradePresent = false;
    }
    if (evalTitle === "" && feedbackComment === "") {
      commentPresent = false;
    }
    setDialogValidationResult({ grade: gradePresent, comment: commentPresent });

    if (!gradePresent || !commentPresent) {
      setShowSubmissionDialog(true);
    } else {
      submitFeedback();
    }
  }

  function submitFeedback() {
    setIsLoading(true);

    dispatch(
      updateSubmissionIsChecked({
        id: Number(submission_id),
        is_checked: true
      })
    );

    //Update the submission is_checked in Redux
    const taskFuncions = firebaseFunctions.httpsCallable("tasks-taskFunctions");
    taskFuncions({
      func_name: "updateTaskSubmissionWithFeedback",
      id: Number(submission_id),
      content: feedbackComment,
      title: evalTitle,
      grade: Number(grade)
    })
      .then(() => {
        // Snackbar
        setIsLoading(false);
        history.push(`/tasks?course_id=${task.course_id}&task_id=${task.id}`);
      })
      .catch(err => {
        // Snackbar
        dispatch(
          updateSubmissionIsChecked({
            id: Number(submission_id),
            is_checked: false
          })
        );
        captureException(err);
      });
  }

  const renderDialog = () => {
    if (!task) return <></>;

    return (
      <Dialog
        open={openEval}
        PaperProps={{
          style: {
            width: "70%",
            direction: textDirection
          }
        }}
        onClose={() => {
          dispatch(sendFeedback(false));
          setOpenEval(false);
        }}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">{getDialogTitle()}</DialogTitle>
        <Divider />
        <DialogContent className={classes.dialog}>
          <TextField
            variant="standard"
            inputRef={editTitleRef}
            fullWidth
            inputProps={{
              "aria-label": `total points`
            }}
            aria-label="feedback-title-input"
            InputProps={{
              readOnly: !editingTitle,
              disableUnderline: true,

              startAdornment: !editingTitle && !isChecked && (
                <InputAdornment
                  style={{ ...titleEditMargin(), position: "absolute" }}
                  position="start"
                >
                  <IconButton
                    className={classes.adornment}
                    onClick={e => {
                      setEditingTitle(true);
                      editTitleRef.current.focus();
                      if (editTitleRef.current.setSelectionRange && evalTitle) {
                        editTitleRef.current.setSelectionRange(
                          evalTitle.length,
                          evalTitle.length
                        );
                      }
                      e.stopPropagation();
                    }}
                    aria-label="edit"
                    size="large"
                  >
                    <EditIcon />
                  </IconButton>
                </InputAdornment>
              ),
              className: classes.eval
            }}
            className={classes.eval}
            label=""
            placeholder={isChecked ? evalTitle : titlePlaceholder}
            defaultValue={evalTitle}
            onChange={e => {
              setEvalTitle(e.target.value);
            }}
            onKeyPress={e => {
              if (e.key === "Enter") {
                setEditingTitle(false);
              }
            }}
            onBlur={() => {
              setEditingTitle(false);
            }}
          />
          <TextField
            variant="standard"
            className={classes.writenEval}
            multiline={true}
            InputProps={{
              readOnly: isChecked
            }}
            aria-label="task-general-evaluation-input"
            fullWidth
            defaultValue={feedbackComment}
            onChange={e => {
              setFeedbackComment(e.target.value);
            }}
            placeholder={
              !isChecked &&
              intl.formatMessage({
                id: "tasks.feedback",
                defaultMessage: "Write the general task evaluation"
              })
            }
          />
        </DialogContent>
        {(!isChecked || submission.feedbackcomment || submission.evaltitle) && (
          <Divider />
        )}
        <DialogActions className={classes.actions}>
          <Box className={classes.btnContainer}>
            {!isChecked && (
              <Button
                className={clsx(classes.button, classes.buttonCancel)}
                onClick={() => {
                  setOpenEval(false);
                  dispatch(sendFeedback(false));
                }}
              >
                <FormattedMessage id="cancel" defaultMessage="Cancel" />
              </Button>
            )}
            {!isChecked && (
              <Button
                color="primary"
                className={classes.button}
                onClick={() => {
                  // setShowSubmissionDialog(true);
                  validateDialog();
                  setOpenEval(false);
                }}
              >
                <FormattedMessage
                  id="task.feedback.submit"
                  defaultMessage="Send feedback"
                />
              </Button>
            )}
            {isChecked && (
              <Button
                onClick={() => {
                  setOpenEval(false);
                  dispatch(sendFeedback(false));
                }}
                variant="contained"
                color="primary"
              >
                <FormattedMessage id="close" defaultMessage="Close" />
              </Button>
            )}
          </Box>
          {renderPoints(grade)}
        </DialogActions>
      </Dialog>
    );
  };

  //Render
  if (isLoading || !answers || isEmpty(task)) {
    return <PangeaSpinner />;
  } else if (task.task_type === "peerReview") {
    return <PeerReview />;
  } else
    return (
      <>
        <ActionButtonPortal>
          {!isChecked && isTeacher && (
            <Button
              onClick={validateFeedback} // TODO: refactor validations
              data-test-id="submit-task-button"
              size="small"
            >
              <FormattedMessage
                id="task.feedback.submit"
                defaultMessage="Send feedback"
              />
            </Button>
          )}

          {isChecked && (
            <Button
              onClick={() => {
                isTeacher
                  ? history.push(
                      `/tasks?course_id=${course.id}&task_id=${task.id}`
                    )
                  : history.push(`/tasks?course_id=${course.id}`);
              }}
              data-test-id="submit-task-button"
              size="small"
            >
              <FormattedMessage id="gr.confirm.btn" defaultMessage="Done" />
            </Button>
          )}
        </ActionButtonPortal>
        <FeedbackValidationModal
          task={task}
          showValidationDialog={showValidationDialog}
          setShowValidationDialog={setShowValidationDialog}
          onSubmit={() => setOpenEval(true)}
          validationResult={feedbackValidationResult}
        />
        <FeedbackSubmmisionModal
          grade={grade}
          evalTitle={evalTitle}
          feedbackComment={feedbackComment}
          showSubmissionDialog={showSubmissionDialog}
          setShowSubmissionDialog={setShowSubmissionDialog}
          validationResult={dialogValidationResult}
          openEval={openEval}
          setOpenEval={setOpenEval}
          submitFeedback={submitFeedback}
        />
        <TaskFeedbackSidebar
          editable={task.creator === currentUser.uid && !isChecked}
          onQuestionIndex={i => {}}
          //TODO: isTeacher and showMatch are mostly doing the same thing, check if we can get rid of showMatch
          isTeacher={course.course_role === "Teacher"}
          showMatch={course.course_role === "Teacher"}
          selectedQuestionIndex={selectedQuestionIndex}
          task={task}
          questions={questions}
          highlights={highlights}
          validateFeedback={validateFeedback}
          highlightMatch={highlightMatch}
        />
        <Box className={classes.drawerContainer} id="drawer-container">
          <FeedbackScreen
            task={task}
            selectedQuestionIndex={selectedQuestionIndex}
            showMatch={task.creator === currentUser.uid}
            isTeacher={task.creator === currentUser.uid}
            onQuestionIndex={i => {}}
            conceptMapping={conceptMapping}
            highlightMatch={highlightMatch}
          />
        </Box>
        {openEval && renderDialog()}
        <Box component="span" ref={evalTitleSpanRef} className={classes.hidden}>
          {!evalTitle.length ? titlePlaceholder : evalTitle}
        </Box>
      </>
    );
}

export default injectIntl(TaskFeedback);
