// Dependancies

import React, {
  useReducer,
  useState,
  useMemo,
  useEffect,
  useCallback
} from "react";

import * as d3 from "d3";
import _ from "lodash";
import { v4 as uuid } from "uuid";
import { useChartDimensions } from "../../../../../../hooks";

// Redux
import { useDispatch } from "react-redux";
import { addSnackbarItem } from "../../../../../../redux/snackbarSlice";

// Components
import EngagementChartHeader from "./EngagmentChartHeader";
import EngagementChartBody from "./EngagmentChartBody";
import EngagementChartLegend from "./EngagmentChartLegend";

// Mui
import { Box, Skeleton } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { startOfDay } from "date-fns";
import { httpCallables } from "../../../../../../firebase";
import { captureException } from "../../../../../../utils/errorHandlers";

// styles
const useStyles = makeStyles(theme => ({
  container: {
    position: "relative",
    height: "100%",
    display: "flex",
    flexDirection: "column"
  },
  skeletonContainer: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center"
  }
}));

const activityFilters = {
  SESSIONS: {
    displayName: "General engagement",
    value: true
  },
  READING_SESSIONS: {
    displayName: "Reading time",
    value: true
  },
  WRITING_SESSIONS: {
    displayName: "Writing time",
    value: true
  },
  COMMENTS_CREATED: {
    displayName: "Comments",
    value: true
  },
  CITATIONS_CREATED: {
    displayName: "Citations",
    value: true
  },
  QUESTIONS_CREATED: {
    displayName: "Questions",
    value: true
  }
};

const initialState = {
  FILTER_BY_USER: {
    displayName: "Filter by user",
    value: {}
  },
  FILTER_BY_ACTIVITY: {
    displayName: "Filter by activity",
    value: activityFilters
  }
};
// filter reducer

function filterReducer(state, action) {
  switch (action.type) {
    case "setUsers": {
      // set the users after fetching them from DB
      const { value } = action.payload;

      const curretUserFilter = state.FILTER_BY_USER.value;
      const usersToSet = value.reduce((accumulator, current) => {
        const user = current.user;
        const isClass = current.user === "class"; // class is the aggregation of all users
        const initialValue = current.initialValue;

        if (user in curretUserFilter)
          // if the user is already in the filter, leave it as is
          accumulator[user] = curretUserFilter[user];

        accumulator[user] = {};
        accumulator[user]["order"] = isClass ? 0 : 1;
        accumulator[user]["displayName"] = isClass ? "Class total" : user;
        accumulator[user]["value"] = initialValue;

        return accumulator;
      }, {});

      let updatedState = { ...state };
      updatedState.FILTER_BY_USER.value = usersToSet;

      return updatedState;
    }

    case "update": {
      // add/remove user from filter
      const { filter, item, update } = action.payload;

      //update the filter
      const updatedFilter = { ...state[filter].value };
      updatedFilter[item].value = update;
      let updatedState = { ...state };

      //update the state
      updatedState[filter].value = updatedFilter;
      return updatedState;
    }
    default:
      throw new Error();
  }
}

function EngagementChart({
  title,
  course_id,
  users,
  start,
  end,
  includeTypes,
  includeClass = true,
  showUsers = false
}) {
  // Hooks
  const dispatch = useDispatch();
  const classes = useStyles();
  const { ref, dimensions } = useChartDimensions({ marginBottom: 40 });

  // Ephemeral State
  const [data, setData] = useState({});
  const [filters, dispatchFilters] = useReducer(filterReducer, initialState);
  const [isLoading, setIsLoading] = useState(true);
  // Derived State

  const selectedUsers = useMemo(() => {
    const usersFilter = filters.FILTER_BY_USER.value;
    return Object.keys(usersFilter).filter(
      key => usersFilter[key].value === true
    );
  }, [filters.FILTER_BY_USER.value]);

  const filteredIncludeTypes = useMemo(() => {
    const activityFilter = filters.FILTER_BY_ACTIVITY.value;
    return Object.keys(activityFilter).filter(
      key => activityFilter[key]["value"] === true
    );
  }, [filters.FILTER_BY_ACTIVITY.value]);
  const flattenedData = flattenData(data);

  // D3;

  //  1/18/32 useing startOfDay here becouse the dates coming from...
  // ... the DB and the dates n the local system do not align. Once...
  // ... there is a date handling flow the start of day should be removed
  const xAccessor = d => startOfDay(new Date(d.date));
  const yAccessor = d => d.engagement;
  // const zAccessor = d => d.group;

  // const groupedData = groupBy(courseEngagement, zAccessor);
  const xScale = d3
    .scaleTime()
    .domain([start, end])
    .range([0, dimensions.boundedWidth]);

  const yScale = d3
    .scaleLinear()
    .domain(d3.extent(flattenedData, yAccessor))
    .range([dimensions.boundedHeight, 0])
    .nice();

  const zScale = d3.scaleOrdinal(d3.schemePaired).domain(Object.keys(data));

  // Utils

  function flattenData(data) {
    return _.chain(data).toArray().flatten().value();
  }

  // Behavior
  const setUsersFilter = useCallback(
    users => {
      // generate the initial user filter state: [{user: bool},...]
      const usersFilterInitialState = users.map(user => ({
        user: user,
        initialValue: user === "class" ? true : showUsers
        // the current behavior always shows the class and ...
        // ... hideing/showing all the other users based on the passed showUsers prop
      }));

      dispatchFilters({
        type: "setUsers",
        payload: {
          value: usersFilterInitialState
        }
      });
    },
    [showUsers]
  );

  useEffect(() => {
    if (!start || !end) return;
    const timezoneOffset = new Date().getTimezoneOffset();
    httpCallables
      .readDailyUsersActivityReport({
        course_id: Number(course_id),
        users: users,
        start: start + timezoneOffset,
        end: end + timezoneOffset,
        includeTypes: filteredIncludeTypes,
        includeClass: includeClass
      })
      .then(({ data }) => {
        const { success } = data;
        if (success) {
          const parsedData = JSON.parse(data.payload);
          setData(parsedData);
          setUsersFilter(Object.keys(parsedData));
          setIsLoading(false);
        } else {
          const { error } = data;
          dispatch(
            addSnackbarItem({
              intlId: "error.generateUserActivityReportFailed",
              intlDefaultMessage:
                "There was a problem getting the student's information. Please check your connection and try again",
              id: uuid()
            })
          );

          captureException(error, `Faild to get course users`);
        }
      });
  }, [
    course_id,
    dispatch,
    end,
    filteredIncludeTypes,
    includeClass,
    includeTypes,
    setUsersFilter,
    start,
    users
  ]);

  return (
    <Box className={classes.container}>
      <EngagementChartHeader
        title={title}
        filters={filters}
        dispatchFilters={dispatchFilters}
      />
      {isLoading ? (
        <Box className={classes.skeletonContainer}>
          <Skeleton variant="rectangular" width="90%" height="80%" />
          <Skeleton width="90%" />
        </Box>
      ) : (
        <>
          <EngagementChartBody
            data={data}
            dataGroups={selectedUsers}
            ref={ref}
            dimensions={dimensions}
            xScale={xScale}
            xAccessor={xAccessor}
            yScale={yScale}
            yAccessor={yAccessor}
            zScale={zScale}
          />
          <EngagementChartLegend scale={zScale} series={selectedUsers} />
        </>
      )}
    </Box>
  );
}

export default EngagementChart;
