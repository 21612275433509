// Dependencies
import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { fromEvent } from "rxjs";
import { switchMap, map, takeUntil, tap } from "rxjs/operators";
// Redux

import {
  replyToMentor,
  closeChatbox,
  CONVERSATION_TYPES,
  CHATBOX_STATUS
} from "../../redux/chatSlice";
import { useDispatch, useSelector } from "react-redux";

// Components
import {
  TextEditor,
  TextEditorFooter,
  TextEditorInput
} from "../SharedComponents/textEditor";
import { ScrollBox, TooltipWithIntl } from "../SharedComponents";

// Material UI
import {
  Paper,
  IconButton,
  Box,
  Grow,
  Typography,
  useTheme,
  Button
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import MinimizeIcon from "@mui/icons-material/Minimize";
import ChatBubble from "./ChatBubble";
import TextEditorButton from "../SharedComponents/textEditor/TextEditorButton";
import BouncingDots from "../SharedComponents/BouncingDots";

// Styles
const useStyles = makeStyles(theme => {
  return {
    container: {
      position: "absolute",
      bottom: 16,
      right: 16
    },
    chatBox: {
      position: "relative",
      width: 280,
      height: "80Vh",
      display: "flex",
      flexDirection: "column",
      zIndex: theme.zIndex.drawer + 3
    },
    header: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      borderBottom: "1px solid",
      borderBottomColor: theme.palette.grey.main,
      paddingInline: theme.spacing(2),
      paddingBlock: theme.spacing(0.5),
      minHeight: 48
    },
    dragHandle: {
      flexGrow: 1,
      display: "inline-flex",
      cursor: "move",
      alignItems: "center",
      userSelect: "none",
      "-webkit-user-select": "none" /* Safari */
    },
    title: {
      fontFamily: "Chivo",
      fontSize: "12px",
      fontWeight: "400",
      lineHeight: "32px",
      letterSpacing: "1px",
      textAlign: "left",
      textTransform: "uppercase",
      marginInlineStart: theme.spacing(1)
    },
    avatar: {
      width: 36,
      height: 36,
      backgroundColor: theme.palette.secondary.main
    },
    headerAction: {
      display: "flex"
    },
    main: {
      display: "flex",
      paddingBlockStart: theme.spacing(1),
      paddingInline: theme.spacing(1)
    },
    footer: {
      display: "flex",
      maxHeight: 332,
      height: "auto",
      paddingInline: theme.spacing(2),
      paddingBlock: theme.spacing(0.5),
      borderTop: "1px solid",
      borderTopColor: theme.palette.grey.main
    },
    footerActions: {
      paddingInline: 0,
      display: "flex",
      flexDirection: "row-reverse",
      justifyContent: "space-between",
      minHeight: 52
    },
    editor: {},
    input: {},
    sendButton: {
      color: theme.palette.primary.dark
    },
    betaButton: {
      backgroundColor: theme.palette.background.blue,
      color: "#FFFFFF",
      marginInlineStart: theme.spacing(2),
      minWidth: "46px",
      height: "37px",
      "&:hover": {
        backgroundColor: theme.palette.background.blue
      },
      padding: 0,
      borderRadius: "8px"
    }
  };
});

function Chat() {
  // Hooks
  const dispatch = useDispatch();
  const classes = useStyles();
  const theme = useTheme();
  const dragHandleRef = useRef();
  const chatBoxRef = useRef();
  const chatContentRef = useRef();

  // Redux Selectors
  const status = useSelector(state => state.chat.status);
  const conversation = useSelector(state => state.chat.conversation);
  const isLoading = useSelector(state => state.chat.isLoading);

  // Ephermeral state

  // Derived state

  // Behavior

  useEffect(() => {
    if (!chatContentRef.current) return;

    setTimeout(() => {
      scrollChatToBottom();
    }, 100);
  }, [conversation, isLoading]);

  function scrollChatToBottom() {
    const targetNode = chatContentRef.current;
    if (
      targetNode &&
      targetNode.scrollTop + targetNode.clientHeight !== targetNode.scrollHeight
    ) {
      targetNode.scroll({
        top: targetNode.scrollHeight,
        behavior: "smooth"
      });
    }
  }

  useEffect(() => {
    if (status === CHATBOX_STATUS.CLOSED) return;
    const mouseDown$ = fromEvent(dragHandleRef.current, "mousedown");
    const mouseMove$ = fromEvent(window, "mousemove");
    const mouseUp$ = fromEvent(window, "mouseup");

    const dragMove$ = mouseDown$.pipe(
      switchMap(start =>
        mouseMove$.pipe(
          map(moveEvent => ({
            originalEvent: moveEvent,
            deltaX: moveEvent.pageX - start.pageX,
            deltaY: moveEvent.pageY + start.pageY,
            startOffsetX: start.offsetX,
            startOffsetY: start.offsetY
          })),
          takeUntil(mouseUp$)
        )
      )
    );

    const sub = dragMove$.subscribe(move => {
      const offsetX = move.originalEvent.x - move.startOffsetX;
      const offsetY = move.originalEvent.y - move.startOffsetY;
      chatBoxRef.current.style.left = offsetX + "px";
      chatBoxRef.current.style.top = offsetY + "px";

      // override the default buttom right position
      chatBoxRef.current.style.buttom = "auto";
      chatBoxRef.current.style.right = "auto";
    });

    return () => sub.unsubscribe();
  }, [status]);
  function PendingBubble() {
    return (
      <ChatBubble
        variation={CONVERSATION_TYPES.INCOMING}
        content={
          <BouncingDots
            size="large"
            color={theme.palette.primary.contrastText}
          />
        }
      ></ChatBubble>
    );
  }

  if (status === CHATBOX_STATUS.CLOSED) return null;
  return (
    <Box ref={chatBoxRef} className={classes.container}>
      <Grow in={true}>
        <Paper elevation={3} className={classes.chatBox}>
          <Box className={classes.header}>
            <Box className={classes.dragHandle} ref={dragHandleRef}>
              <Typography component="h4" className={classes.title}>
                Alethea Coach
              </Typography>
              <Button className={classes.betaButton}>Beta</Button>
            </Box>
            <Box className={classes.headerAction}>
              <TooltipWithIntl
                intlStringId={"minimize"}
                defaultMessage={"Hide"}
                placement="left"
              >
                <IconButton
                  size="small"
                  onClick={() => dispatch(closeChatbox())}
                >
                  <MinimizeIcon />
                </IconButton>
              </TooltipWithIntl>
            </Box>
          </Box>
          <ScrollBox ref={chatContentRef} className={classes.main}>
            {conversation.map((item, index) => {
              const shouldAnimate =
                index === conversation.length - 1 &&
                item.type === CONVERSATION_TYPES.INCOMING &&
                item.seenByUser === false;
              return (
                <ChatBubble
                  key={index}
                  variation={item.type}
                  content={item.content}
                  animate={shouldAnimate}
                  scroll={scrollChatToBottom}
                />
              );
            })}

            {isLoading && <PendingBubble />}
          </ScrollBox>
          <Box className={classes.footer}>
            <TextEditor variant="contained" className={classes.editor}>
              <TextEditorInput
                className={classes.input}
                placeholder="enter your text here"
              />
              <TextEditorFooter
                showWordCount={true}
                wordLimit={300}
                className={classes.footerActions}
              >
                <TextEditorButton
                  color="primary"
                  className={classes.sendButton}
                  onClick={({ plainText }) =>
                    dispatch(replyToMentor({ content: plainText }))
                  }
                  resetOnClick={true}
                  disabled={isLoading}
                >
                  Send
                </TextEditorButton>
              </TextEditorFooter>
            </TextEditor>
          </Box>
        </Paper>
      </Grow>
    </Box>
  );
}

Chat.propTypes = {};
export default Chat;
