// dependancies
import { httpCallables } from "../../../firebase";
import { captureException } from "../../../utils/errorHandlers";

// Redux
import store from "../../store";
import {
  CHATBOX_STATUS,
  CHAT_TYPE,
  initMentorReview,
  loadConversation,
  resetConversation,
  closeChatbox
} from "../../chatSlice";
import { setSelectedInteractionId } from "../../interactionsSlice";

// rx
import { ofType } from "redux-observable";
import { EMPTY } from "rxjs";
import {
  tap,
  map,
  catchError,
  switchMap,
  withLatestFrom,
  ignoreElements
} from "rxjs/operators";
import { questionHasHighlights } from "./utils";
import { FAB_TYPE, hideFab, updateFabType } from "../../fabSlice";

const CALLBACK = httpCallables.interactionFunctions;
const ACTION = "readConversationByInteractionId";
const ERROR_MSG = `FETCH_CONVERSATION_BY_INTERACTION_ID`;

export const fetchConversationEpic = (
  action$,
  state$,
  { fetchAsObservable }
) => {
  return action$.pipe(
    /* TODO: This is a naive implementaion of the loading state.
     * improve on this by not delaying the loader for fast API ~250ms
     * and setting the min time for showing the loader ~500ms
     */
    ofType(setSelectedInteractionId.type),
    tap(() => store.dispatch(resetConversation())),
    tap(() => store.dispatch(updateFabType({ type: FAB_TYPE.LOADING }))),
    map(({ action, payload }) => ({ interaction_uuid: payload })),
    switchMap(args =>
      fetchAsObservable(CALLBACK, ACTION, ERROR_MSG, args).pipe(
        withLatestFrom(state$),
        tap(() => store.dispatch(updateFabType({ type: FAB_TYPE.MENTOR }))),
        map(([result, state]) => mapStateToAction(result, state))
      )
    ),
    catchError(error => {
      captureException(error, "Error in fetchConversationEpic");
      return EMPTY;
    })
  );
};

function mapStateToAction(data, state) {
  const { session_id, interactions } = data;
  const conversationExists = interactions.length;
  const highlightsExists = questionHasHighlights(state);
  const isChatboxOpen = state.chat.status === CHATBOX_STATUS.OPEN;

  if (conversationExists) {
    // Load previus conversation if there is one
    return {
      type: loadConversation.type,
      payload: { session_id, interactions }
    };
  } else if (!highlightsExists && !isChatboxOpen) {
    // hide the fab if no highlights and no prev conversation
    return {
      type: hideFab.type,
      payload: {}
    };
  } else if (!highlightsExists && isChatboxOpen) {
    // hide the chatbox if no highlights and no prev conversation
    return {
      type: closeChatbox.type,
      payload: {}
    };
  } else if (isChatboxOpen) {
    // init the mentor if the chat is open and no prev conversation
    return {
      type: initMentorReview.type,
      payload: { chatType: CHAT_TYPE.REVIEW }
    };
  } else {
    // do nothing
    ignoreElements();
  }
}
