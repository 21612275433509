import React, { useRef, useEffect } from "react";
import makeStyles from "@mui/styles/makeStyles";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";

import {
  Box,
  TextField,
  InputAdornment,
  IconButton,
  Radio,
  FormControlLabel
} from "@mui/material";

const useStyles = makeStyles(theme => ({
  input: {
    width: "100%",
    "& .MuiFormControlLabel-label": {
      width: "100%"
    }
  },
  blue: {
    "&$checked": {
      color: "#168fee"
    },
    "&.Mui-checked": {
      color: "#168fee"
    }
  },
  txtInput: {
    width: "100%",
    "& ::placeholder": {
      color: "#168fee"
    }
  },
  editBtn: {
    color: theme.palette.grey["400"]
  },
  deleteBtn: {
    display: "none"
  },
  choiceText: {
    width: "100%",
    "& :focus-within": {
      "& $deleteBtn": {
        display: "block"
      },
      "& $editBtn": {
        display: "none"
      }
    },
    "& $editBtn": {
      color: theme.palette.grey["400"]
    },
    "&:hover": {
      "& $editBtn": {
        color: theme.palette.blue.main
      }
    }
  }
}));

function EditChoice(props) {
  const item = props.item;
  const index = props.index;
  const editingMode = props.editingmode;
  const inputRef = useRef();
  const classes = useStyles();

  useEffect(() => {
    if (editingMode === index) {
      inputRef.current.focus();
    }

    if (
      editingMode === index &&
      inputRef.current.setSelectionRange &&
      inputRef.current.value
    ) {
      inputRef.current.setSelectionRange(
        inputRef.current.value.length,
        inputRef.current.value.length
      );
    }
  }, [editingMode, inputRef, index]);

  return (
    <FormControlLabel
      className={classes.input}
      value={index}
      control={
        <Radio
          className={classes.blue}
          inputProps={{
            readOnly: props.readOnly
          }}
          checked={index === parseInt(props.shouldSelect)}
          onClick={e => {
            e.stopPropagation();
          }}
          onChange={e => {
            if (e.target.checked) {
              props.onUpdateChoice(index);
            }
          }}
        />
      }
      label={
        <Box>
          <TextField
            variant="standard"
            aria-label="edit-choice-input"
            readOnly={props.readOnly}
            onClick={e => {
              e.stopPropagation();
              e.preventDefault();
              if (editingMode !== index) {
                props.setEditingMode(index);
                inputRef.current.focus();
              }
            }}
            inputRef={inputRef}
            multiline={true}
            minRows={1}
            value={item}
            className={classes.choiceText}
            InputProps={{
              readOnly: editingMode !== index || props.readOnly,

              endAdornment: (
                <InputAdornment position="end">
                  {/* {editingMode === index && !props.readOnly && ( */}
                  {!props.readOnly && (
                    <IconButton
                      className={classes.deleteBtn}
                      onClick={e => {
                        e.stopPropagation();
                        e.preventDefault();
                        props.deleteItem(index);
                      }}
                      size="large"
                    >
                      <CloseIcon />
                    </IconButton>
                  )}
                  {/* {editingMode !== index && !props.readOnly && ( */}
                  {!props.readOnly && (
                    <IconButton
                      className={classes.editBtn}
                      onClick={e => {
                        e.stopPropagation();
                        e.preventDefault();
                        props.setEditingMode(index);
                        inputRef.current.focus();
                      }}
                      aria-label="edit"
                      size="large"
                    >
                      <EditIcon />
                    </IconButton>
                  )}
                </InputAdornment>
              )
            }}
            onChange={props.changeAnswer}
            onKeyPress={e => props.possiblyDeleteAnswer(e, index)}
            editingMode={index === editingMode}
          />
        </Box>
      }
    />
  );
}

export default EditChoice;
