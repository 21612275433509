// Dependancies
import React from "react";
import PropTypes from "prop-types";
import { Box, Paper, Popper, Typography } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

// Styles
// styles
const useStyles = makeStyles(theme => ({
  tooltip: {
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "column"
  }
}));

function ChartTooltip({ open, anchorEl, children }) {
  const classes = useStyles();
  return (
    <Popper
      open={open}
      anchorEl={anchorEl}
      placement="bottom"
      disablePortal={false}
      modifiers={[
        {
          name: "flip",
          options: {
            fallbackPlacements: ["top"]
          }
        }
      ]}
    >
      <Paper elevation={3} id="tooltip" className={classes.tooltip}>
        {children}
      </Paper>
    </Popper>
  );
}

ChartTooltip.propTypes = {};

ChartTooltip.defaultProps = {};

export default ChartTooltip;
