// Dependencies
import React from "react";
import PropTypes from "prop-types";

import makeStyles from "@mui/styles/makeStyles";
import { TextField, Grid } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";

// Styles
const useStyles = makeStyles(theme => ({
  container: { marginBlockEnd: theme.spacing(2) }
}));

export default function DateRangeSelection({
  start,
  setStart,
  end,
  setEnd,
  maxDate
}) {
  //Hooks
  const classes = useStyles();

  //Redux

  // Ephemeral State

  // Derived state

  //Behavior

  return (
    <Grid
      component={"section"}
      className={classes.container}
      container
      spacing={3}
      alignItems={"flex-start"}
    >
      <Grid item xs={2}>
        <DatePicker
          disableToolbar
          variant="inline"
          inputFormat="MM/dd/yyyy"
          margin="normal"
          id="date-picker-inline"
          label="Start date"
          value={start}
          onChange={date => setStart(date)}
          disableFuture
          maxDate={maxDate}
          renderInput={params => <TextField variant="standard" {...params} />}
        />
      </Grid>
      <Grid item xs={2}>
        <DatePicker
          disableToolbar
          variant="inline"
          inputFormat="MM/dd/yyyy"
          margin="normal"
          id="date-picker-inline"
          label="End date"
          value={end}
          onChange={date => setEnd(date)}
          disableFuture
          maxDate={maxDate}
          renderInput={params => <TextField variant="standard" {...params} />}
        />
      </Grid>
    </Grid>
  );
}

DateRangeSelection.propTypes = {
  start: PropTypes.object,
  setStart: PropTypes.func,
  end: PropTypes.object,
  setEnd: PropTypes.func,
  maxDate: PropTypes.object
};
