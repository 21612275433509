// Dependencies
import React from "react";
import PropTypes from "prop-types";
import { firebaseApp } from "../../../firebase";
import { useIntl, FormattedMessage } from "react-intl";
import clsx from "clsx";
import { isEmpty } from "lodash";

// Redux dependencies
import { useDispatch, useSelector } from "react-redux";
import { enqueueFlashMessage } from "../../../redux/userSlice";
import { setSelectedText, setTextUrl } from "../../../redux/textsSlice";

// Components
import NavigationButtons from "./NavigationButtons";

import makeStyles from "@mui/styles/makeStyles";
import { Box, Paper, Divider, TextField, Typography } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";

//Styles
const useStyles = makeStyles(theme => ({
  right: {
    textAlign: "right"
  },
  left: {
    textAlign: "left"
  },
  paperClass: {
    marginTop: "20px",
    borderRadius: "0px",
    paddingBottom: "16px",
    textAlign: "left",

    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
      color: "#168FEE"
    },
    "& .MuiInput-underline": {
      "&:after": {
        borderBottom: "2px solid #168FEE"
      }
    },
    "&:after": {
      borderBottom: "2px solid #168FEE"
    },
    "&:focused::after": {
      borderBottom: "2px solid #168FEE"
    },
    "& .MuiInputLabel-root.Mui-focused": {
      color: "#168FEE"
    }
  },
  select: {
    width: "95%",
    textAlign: "left",
    " &:after": {
      borderBottom: "1px solid #168FEE"
    },
    "&:before": {
      borderBottom: `1px solid #979797`
    }
  },
  divider: {
    backgroundColor: "#dedede",
    marginBottom: "8px",
    marginTop: "8px"
  },
  paddedContent: {
    paddingLeft: "24px",
    paddingRight: "24px",
    paddingTop: "16px"
  },
  marginContent: {
    marginTop: "24px",
    marginBottom: "30px"
  }
}));

function StepSelectReading({
  course,
  texts,
  text,
  setTextId,
  activeStep,
  setActiveStep,
  publishTask,
  setIsDirty,
  readOnly = false,
  resetQuestions
}) {
  //Hooks
  const intl = useIntl();
  const classes = useStyles();
  const dispatch = useDispatch();

  const alertsDuration = useSelector(
    state => state.user.userProfile.alertsDuration
  );
  // Variables
  const validationMessage = intl.formatMessage({
    id: "tasks.create.validation.selectReading",
    defaultMessage: "Select a text"
  });

  //Behavior
  function validateForm() {
    if (!text.id) {
      dispatch(
        enqueueFlashMessage({
          message: validationMessage,
          duration: alertsDuration,
          severity: "error"
        })
      );
      return false;
    } else return true;
  }

  //Render
  return (
    <React.Fragment>
      {}
      <Paper elevation={1} className={clsx(classes.paperClass)}>
        <Typography className={clsx(classes.paddedContent)} variant="h6">
          <FormattedMessage
            id="task.chooseText"
            defaultMessage="Choose text from Library"
          />
        </Typography>
        <Divider className={classes.divider} />
        <Box className={clsx(classes.paddedContent, classes.marginContent)}>
          <Autocomplete
            disabled={readOnly}
            debug="true"
            id="selectReading"
            options={texts}
            getOptionLabel={option => {
              return option ? option.author + " - " + option.name : "";
            }}
            className={classes.select}
            value={!isEmpty(text) ? text : ""}
            renderInput={params => (
              <TextField
                variant="standard"
                readOnly={readOnly}
                {...params}
                name="select-text"
                aria-label="select-text-input"
                label={intl.formatMessage({
                  id: "task.enterText",
                  defaultMessage: "Type text title or author"
                })}
              />
            )}
            onChange={(event, newValue) => {
              resetQuestions();
              if (newValue && newValue.file_url) {
                firebaseApp
                  .storage()
                  .ref("courseTexts/" + course.id)
                  .child(newValue.file_url)
                  .getDownloadURL()
                  .then(url => {
                    dispatch(setSelectedText(newValue.id));
                    dispatch(setTextUrl({ url, text_id: newValue.id }));
                    setTextId(newValue.id);
                    setIsDirty(true);
                  });
              }
            }}
          />
        </Box>
      </Paper>
      {!readOnly && setActiveStep && (
        <NavigationButtons
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          publishTask={publishTask}
          validateForm={validateForm}
        />
      )}
    </React.Fragment>
  );
}

// I added proptype sto start ceaning up eslint errors but i just put "any" - need to add correct proptypes here
StepSelectReading.propTypes = {
  course: PropTypes.any,
  texts: PropTypes.any,
  text_id: PropTypes.number,
  setTextId: PropTypes.func,
  activeStep: PropTypes.any,
  setActiveStep: PropTypes.any,
  publishTask: PropTypes.func,
  setIsDirty: PropTypes.any,
  readOnly: PropTypes.any,
  resetQuestions: PropTypes.any,
  setQuestions: PropTypes.any
};

export default StepSelectReading;
