// Dependencies
import React, { useEffect, useState } from "react";
import { firebaseFunctions, firebaseApp, httpCallables } from "../../firebase";
import { useHistory } from "react-router-dom";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { v4 as uuid } from "uuid";
import clsx from "clsx";
import { FormattedMessage, injectIntl, useIntl } from "react-intl";
import { captureException } from "../../utils/errorHandlers";
import { useQuery } from "../../hooks";
import Epub from "epubjs/lib/index";

// Redux dependencies
import { useDispatch, useSelector } from "react-redux";
import { setBreadcrumbs } from "../../redux/readerActionsSlice";
import { selectCourse } from "../../redux/coursesSlice";
import { enqueueFlashMessage } from "../../redux/userSlice";
import { selectTexts, reorderTexts, addText } from "../../redux/textsSlice";

// Components
import PangeaSpinner from "../SharedComponents/PangeaSpinner";
import ScrollBox from "../SharedComponents/ScrollBox";
import CustomUploadButton from "react-firebase-file-uploader/lib/CustomUploadButton";

import makeStyles from "@mui/styles/makeStyles";
import {
  Box,
  Link,
  Table,
  TableContainer,
  TableCell,
  TableBody,
  TableRow,
  TableHead,
  Typography,
  Button,
  FormControl,
  InputLabel,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  MenuItem,
  Select,
  CircularProgress,
  TextField,
  Chip,
  Divider
} from "@mui/material";
import { addSnackbarItem } from "../../redux/snackbarSlice";

//Styles
const useStyles = makeStyles(theme => ({
  container: {
    height: "100%",
    flex: 1,
    display: "flex",
    position: "relative",
    justifyContent: "center",
    alignItems: "center"
  },
  pointer: {
    cursor: "pointer"
  },
  libraryContainer: {
    height: "100%",
    width: "83.333333%", // 10 culumns on a 12 column grid
    alignItems: "center",
    position: "relative",
    margin: "0 auto",

    [theme.breakpoints.up("desktop")]: {
      maxWidth: 840,
      marginInline: "auto"
    }
  },
  tableContainer: {
    width: "auto", // 10 culumns on a 12 column grid,
    overflow: "nonw",
    marginTop: theme.spacing(3)
  },
  tableHead: {
    fontWeight: "800"
  },
  headerFont: {
    fontSize: "20px"
  },
  link: {
    color: theme.palette.text.primary
  },
  libraryHeader: {
    marginTop: theme.spacing(7.5),
    marginBottom: theme.spacing(2),
    display: "flex",
    justifyContent: "space-between"
  },

  cell: {
    marginTop: "16px",
    marginBottom: "16px"
  },
  authorName: {
    whiteSpace: "nowrap",
    maxWidth: "200px",
    overflow: "hidden",
    textOverflow: "ellipsis"
  },
  textName: {
    display: "-webkit-box",
    "-webkit-line-clamp": 2,
    textOverflow: "ellipsis",
    "-webkit-box-orient": "vertical",
    overflow: "hidden"
  },
  center: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh"
  },
  readerViewContainer: {
    position: "relative",
    width: "100%",
    height: "max-content",
    minHeight: "calc(100vh - 120px)"
  },
  drawerContainer: {
    position: "relative",
    width: "100%",
    height: "max-content",
    minHeight: "calc(100vh - 120px)"
  },
  drawerLtr: {
    left: 0
  },
  drawer: {
    width: "56px",
    height: "100vh",
    position: "fixed",
    background: "black",
    zIndex: 120,
    top: 0,
    bottom: 0,
    whiteSpace: "nowrap"
  },
  dirLtr: {
    direction: "ltr"
  },
  content: {
    position: "relative",
    width: "100%",
    height: "max-content",
    minHeight: "calc(100vh - 120px)",
    display: "grid"
  },
  selectEmpty: {
    width: "50%",
    height: "80%",
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  chip: {
    margin: theme.spacing(0.5)
  }
}));

function CircularProgressWithLabel(props) {
  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      <CircularProgress variant="determinate" {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center"
        }}
      >
        <Typography variant="caption" component="div" color="text.secondary">
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}

const DraggableComponent = (id, index, isDarkMode) => props => {
  return (
    <Draggable draggableId={id} index={index}>
      {(provided, snapshot) => (
        <TableRow
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={getItemStyle(
            snapshot.isDragging,
            provided.draggableProps.style,
            isDarkMode
          )}
          {...props}
        >
          {props.children}
        </TableRow>
      )}
    </Draggable>
  );
};

const DroppableComponent = onDragEnd => props => {
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId={"1"} direction="vertical">
        {provided => {
          return (
            <TableBody
              ref={provided.innerRef}
              {...provided.droppableProps}
              {...props}
            >
              {props.children}
              {provided.placeholder}
            </TableBody>
          );
        }}
      </Droppable>
    </DragDropContext>
  );
};

const getItemStyle = (isDragging, draggableStyle, isDarkMode) => {
  return {
    // styles we need to apply on draggables
    ...draggableStyle,
    ...(!isDragging && { cursor: "pointer" }),
    ...(isDragging && {
      cursor: "grabbing",
      display: "table",
      background: isDarkMode ? "rgb(5,15,15)" : "rgb(235,235,235)"
    })
  };
};

function Library() {
  // Hooks
  const intl = useIntl();
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const { course_id } = useQuery();

  // Redux state
  const userProfile = useSelector(state => state.user.auth);
  const course = useSelector(state => selectCourse(state, Number(course_id)));
  const texts = useSelector(state => selectTexts(state, Number(course_id)));
  const isDarkMode = useSelector(state => state.user.userProfile.darkMode);
  const alertsDuration = useSelector(
    state => state.user.userProfile.alertsDuration
  );
  const userRole = useSelector(
    state => state.user.userProfile.selectedCourse.course_role
  );
  // Ephemeral state
  const [openTexts, setOpenTexts] = useState(false);
  const [author, setAuthor] = useState("");
  const [title, setTitle] = useState("");
  const [textLanguage, setTextLanguage] = useState("en");
  const [link, setLink] = useState("");
  const [SQ3RQuestionslanguage, setSQ3RQuestionslanguage] = useState("en");
  const [uploadState, setUploadState] = useState({
    uploading: false,
    progress: 0
  });
  const [location, setLocation] = useState("");
  const [chapters, setChapters] = useState([]);
  const [category, setCategory] = useState("");
  // Bahavior

  const languages = ["he", "en"];
  const isTeacher = userRole === "Teacher";

  useEffect(() => {
    let parts = [];
    parts.push({
      text: intl.formatMessage({
        id: "appBar.library",
        defaultMessage: "Library"
      }),
      resetCourse: true
    });

    if (course.name) {
      parts.push({ text: course.name });
    }
    dispatch(setBreadcrumbs({ breadcrumbs: parts }));
  }, [course.name, dispatch]);

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = result => {
    // dropped outside the list
    if (!result.destination) return;

    const items = reorder(texts, result.source.index, result.destination.index);
    //call function for reorder:
    let func = firebaseFunctions.httpsCallable("texts-reorderCourseTexts");

    func({ course: course.id, texts: items }).catch(err => {
      captureException(err, `Failed to reorder texts`);
    });

    dispatch(reorderTexts(items));
  };

  // For new texts
  const getAllTocItems = toc => {
    /*toc.flatMap( chapter=> {
    if (chapter.subItems && chapter.subItems.length)
    return getAllTocItems(chapter.subItems) 
    return chapterAr;

  })*/
    return toc;
  };

  const handleUploadSuccess = (filename, task) => {
    try {
      firebaseApp
        .storage()
        .ref("courseTexts/" + course.id)
        .child(task.snapshot.ref.name)
        .getDownloadURL()
        .then(url => {
          let book = new Epub(url, {});
          book.loaded.navigation.then(toc => {
            setLocation(toc.toc[0].href);
            setChapters(getAllTocItems(toc.toc));
          });
          setUploadState({ progress: 100, uploading: false });
        });

      setLink(task.snapshot.ref.name);
    } catch (e) {
      setUploadState({ progress: 0, uploading: false });
      const func = firebaseFunctions.httpsCallable(
        "courses-NotifySupportOnfailedToUploadText"
      );
      func({
        fullName: userProfile.displayName,
        email: userProfile.email,
        error_content: e,
        text_name: task.snapshot.ref.name,
        course_id: course_id
      }).then(response => {
        if (response.data === "success") {
          dispatch(
            enqueueFlashMessage({
              message: intl.formatMessage({
                id: "failedToUplod.success",
                defaultMessage: "The text was uploaded successfully."
              }),
              duration: alertsDuration
            })
          );
        } else {
          dispatch(
            enqueueFlashMessage({
              message: intl.formatMessage({
                id: "failedToUplod.fail",
                defaultMessage:
                  "There was a problem uploading your text, a message was sent to Alethea's support team"
              }),
              severity: "error",
              duration: alertsDuration
            })
          );
        }
      });
    }
  };
  const handleUploadStart = () => {
    setUploadState({ uploading: true, progress: 0 });
  };

  const handleProgress = progress =>
    setUploadState({ ...uploadState, progress: progress });

  const handleUploadError = error => {
    setUploadState({ ...uploadState, uploading: false });
    captureException(error, `Failed to to upload text.`);
  };
  const handleClose = () => {
    setUploadState({ uploading: false, progress: 0 });
    setOpenTexts(false);
    setLocation("");
    setAuthor("");
    setTitle("");
    setLink("");
    setCategory("");
  };
  const saveText = () => {
    let text = {
      course: Number(course.id),
      name: title,
      author: author,
      text_language: textLanguage,
      SQ3R_questions_language: SQ3RQuestionslanguage,
      categories: category,
      file_location: location,
      file_url: link
    };
    httpCallables.createCourseText(text).then(({ data }) => {
      const { success } = data;
      if (success) {
        const { text, offline_task_id = null } = JSON.parse(data.payload);

        dispatch(addText(text));
        dispatch(
          addSnackbarItem({
            actions: [
              {
                intlId: "undo",
                intlDefaultMsg: "undo",
                callBack: "undoUploadText"
              }
            ],
            intlId: "text.uploadSuccessful",
            intlDefaultMessage: "Text added to library",
            id: uuid(),
            text,
            offline_task_id
          })
        );
      }
    });

    handleClose();
  };

  const renderCreateCourseDialog = () => {
    return (
      <Dialog
        open={openTexts}
        PaperProps={{
          style: {
            // backgroundColor: "white",
          }
        }}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Add text</DialogTitle>
        <DialogContent className={classes.dialog}>
          <Box
            style={{
              display: "flex",
              flexFlow: "row",
              justifyContent: "space-between",
              margin: 0,
              padding: 0
            }}
          >
            <Button size="medium" style={{ padding: 0, margin: 0 }}>
              <CustomUploadButton
                hidden
                accept="application/epub+zip,.pdf"
                storageRef={firebaseApp
                  .storage()
                  .ref("courseTexts/" + course.id)}
                metadata={{ name: "book" }}
                onUploadStart={handleUploadStart}
                onUploadError={handleUploadError}
                onUploadSuccess={handleUploadSuccess}
                onProgress={handleProgress}
                style={{
                  backgroundColor: "#2E7D32",
                  color: "white",
                  padding: "5px 20px",
                  borderRadius: 4
                }}
              >
                Upload file
              </CustomUploadButton>
            </Button>
            <CircularProgressWithLabel
              color="secondary"
              variant="determinate"
              value={uploadState.progress}
            />
          </Box>
          <TextField
            variant="outlined"
            onChange={e => {
              setAuthor(e.target.value);
            }}
            autoFocus
            margin="dense"
            id="author"
            aria-label="text-author-input"
            label="author"
            type="text"
            value={author}
            fullWidth
          />
          <TextField
            variant="outlined"
            onChange={e => {
              setTitle(e.target.value);
            }}
            aria-label="text-title-input"
            value={title}
            margin="dense"
            id="title"
            label="title"
            type="text"
            fullWidth
          />

          <Box
            style={{
              display: "flex",
              flexFlow: "row",
              justifyContent: "space-around"
            }}
          >
            <FormControl fullWidth className={classes.selectEmpty}>
              <InputLabel id="text-language-select-label">
                Text language
              </InputLabel>
              <Select
                variant="outlined"
                label="Text language"
                labelId="text-language-label"
                id="text-language"
                value={textLanguage}
                displayEmpty
              >
                {languages.map((item, index) => {
                  return (
                    <MenuItem
                      key={index}
                      onClick={() => {
                        setTextLanguage(item);
                        setSQ3RQuestionslanguage(item);
                      }}
                      value={item}
                    >
                      {item}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <TextField
              variant="outlined"
              onChange={e => {
                setCategory(e.target.value);
              }}
              aria-label="text-category-input"
              margin="dense"
              id="category"
              label="category"
              type="text"
              value={category}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <Button
            onClick={saveText}
            color="secondary"
            disabled={uploadState.progress !== 100}
          >
            Add Text
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  //Render
  return (
    <Box className={classes.container}>
      {!texts ? (
        <PangeaSpinner />
      ) : (
        <ScrollBox className={classes.scrollBox}>
          <Box className={classes.libraryContainer}>
            <Box className={classes.libraryHeader}>
              <Typography className={classes.headerFont} variant="h6">
                {course
                  ? course.name
                  : intl.formatMessage({
                      id: "appBar.library",
                      defaultMessage: "Library"
                    })}
              </Typography>
              {course_id && isTeacher && (
                <Box>
                  <Button
                    className={classes.addTextBtn}
                    variant="contained"
                    color="secondary"
                    onClick={() => {
                      setOpenTexts(true);
                    }}
                    size="small"
                  >
                    Add Text
                  </Button>
                </Box>
              )}
            </Box>
            <Divider />
            <TableContainer className={classes.tableContainer}>
              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell
                      scope="col"
                      className={clsx(classes.cell, classes.tableHead)}
                    >
                      <FormattedMessage
                        id="library.author"
                        defaultMessage="Author"
                      />
                    </TableCell>
                    <TableCell
                      scope="col"
                      className={clsx(classes.cell, classes.tableHead)}
                    >
                      <FormattedMessage
                        id="library.title"
                        defaultMessage="Title"
                      />
                    </TableCell>
                    <TableCell
                      scope="col"
                      className={clsx(classes.cell, classes.tableHead)}
                    >
                      <FormattedMessage
                        id="library.category"
                        defaultMessage="Category"
                      />
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody
                  component={
                    course && course.course_role === "Teacher"
                      ? DroppableComponent(onDragEnd)
                      : "tbody"
                  }
                >
                  {texts?.map((text, index) => (
                    <TableRow
                      className={classes.pointer}
                      onClick={e => {
                        history.push(`/reader?text_id=${text.id}`);
                        e.stopPropagation();
                        e.preventDefault();
                      }}
                      component={
                        course?.course_role === "Teacher"
                          ? DraggableComponent("t" + text.id, index, isDarkMode)
                          : "tr"
                      }
                      key={text.id}
                    >
                      <TableCell className={classes.cell}>
                        <Link
                          className={clsx(classes.link, classes.authorName)}
                          data-test-class="library-item"
                          href={`/reader?text_id=${text.id}`}
                          underline="hover"
                          style={{
                            display: "inline-block",
                            flexFlow: "row nowrap",
                            width: "max-content"
                          }}
                        >
                          {text.author}
                        </Link>
                      </TableCell>
                      <TableCell className={classes.cell} align="left">
                        <Link
                          className={clsx(classes.link, classes.textName)}
                          href={`/reader?text_id=${text.id}`}
                          underline="hover"
                        >
                          {" "}
                          {text.name}
                        </Link>
                      </TableCell>
                      <TableCell className={classes.cell} align="left">
                        {text.categories && text.categories.length
                          ? text.categories.split(",").map((item, index) => {
                              return (
                                <Chip
                                  className={classes.chip}
                                  key={index}
                                  label={item}
                                />
                              );
                            })
                          : " "}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </ScrollBox>
      )}
      {renderCreateCourseDialog()}
    </Box>
  );
}

export default injectIntl(Library);
