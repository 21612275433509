// Dependencies
import React, { useState, useRef, useContext, useEffect } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { FormattedMessage } from "react-intl";
import { useSelector, useDispatch } from "react-redux";
import { useQuery } from "../../hooks";

// Redux
import { setDetailedInstructions } from "../../redux/grSlice";
import { selectTask, selectSubmission } from "../../redux/tasksSlice";
import {
  selectGrTaskQuestions,
  selectUserGrQuestions
} from "../../redux/interactionsSlice";
import { grAPI } from "../../api";

// Components
import QuestionManager from "./questionManager/QuestionManager";
import SubmitDialog from "./SubmitDialog";
import makeStyles from "@mui/styles/makeStyles";
import { Box, Typography, IconButton } from "@mui/material";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import GrSteps from "./GrSteps";
// Styles
const useStyles = makeStyles(theme => ({
  panelHeader: {
    color: "white",
    width: "auto",
    overscrollBehavior: "none",
    paddingInline: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    position: "sticky",
    zIndex: 10,
    top: 0,
    backgroundColor: theme.palette.background.sidePanel,
    borderBottom: "1px solid grey"
  },
  panelTitle: {
    color: "#bdbdbd",
    fontSize: "12px",
    fontWeight: "300",
    fontStyle: "normal",
    letterSpacing: "1px",
    marginTop: theme.spacing(1),
    display: "block",
    lineHeight: "32px",
    textTransform: "uppercase"
  },
  left: {
    textAlign: "left"
  },
  stagePanelAndIcon: {
    display: "flex",
    justifyContent: "space-between"
  },
  stageTitle: {
    display: "inline-block",
    textTransform: "none",
    fontSize: "20px",
    lineHeight: "32px",
    marginTop: theme.spacing(1)
  },
  learnMoreBtn: {
    cursor: "pointer",
    textDecoration: "underline",
    opacity: 0.7,
    transition: ".2s ease-out",
    "&:hover": {
      opacity: 1
    }
  },
  instructions: {
    marginTop: theme.spacing(1.5)
  },
  instructionsTxt: {
    opacity: 0.7
  },
  showInstructionsBtn: {
    color: "#bdbdbd"
  }
}));

export default function MyDefaultPanel({
  index,
  color = "secondary",
  locked,
  onAnswerCardCopy,
  ...other
}) {
  //Hooks
  const classes = useStyles();
  const dispatch = useDispatch();
  const panelRef = useRef();
  const { submission_id } = useQuery();

  //Redux state
  const stage = useSelector(state => state.gr.stage);
  const grMode = useSelector(state => state.gr.mode);

  const detailedInstructions = useSelector(
    state => state.gr.detailedInstructions
  );
  const grTaskQuestions = useSelector(selectGrTaskQuestions);
  const userQuestions = useSelector(selectUserGrQuestions);

  const activeGrTaskId = useSelector(state => state.tasks.activeGrTaskId);
  const selectedQuestionId = useSelector(
    state => state.interactions.selectedInteractionId
  );
  const submission = useSelector(state =>
    selectSubmission(state, Number(submission_id))
  );

  const task = useSelector(state =>
    selectTask(state, submission.task_id || activeGrTaskId)
  );
  const [openSubmit, setOpenSubmit] = useState(false);
  const [showInstructions, setShowInstructions] = useState(false);
  const panels = [
    {
      title: `gr.${grMode}.stage.1`,
      defaultMessage: grMode === "full" ? "Survey" : "Survey and Questions",
      details: (
        <FormattedMessage
          id={
            submission.task_id && grMode === "light"
              ? `gr.${grMode}.stage.1.instructions.task`
              : `gr.${grMode}.stage.1.instructions`
          }
        />
      ),
      actions:
        grMode === "full" ? undefined : (
          <>
            <QuestionManager
              scrollToBottom={() => {
                panelRef.current.scrollTop = panelRef.current.scrollHeight;
              }}
              color={color}
              locked={locked}
              onAnswerCardCopy={onAnswerCardCopy}
            />
          </>
        )
    },
    {
      title: `gr.${grMode}.stage.2`,
      defaultMessage: grMode === "full" ? "My Questions" : "Read and ansewr",
      details: <FormattedMessage id={`gr.${grMode}.stage.2.instructions`} />,
      actions: (
        <>
          <QuestionManager
            scrollToBottom={() => {
              panelRef.current.scrollTop = panelRef.current.scrollHeight;
            }}
            color={color}
            locked={locked}
            onAnswerCardCopy={onAnswerCardCopy}
          />
        </>
      )
    },
    {
      title: `gr.${grMode}.stage.3`,
      defaultMessage: grMode === "full" ? "Read and Answer" : "Exercise",
      details: <FormattedMessage id={`gr.${grMode}.stage.3.instructions`} />,
      actions: (
        <>
          <QuestionManager
            color={color}
            locked={locked}
            onAnswerCardCopy={onAnswerCardCopy}
          />
          {grMode === "light" ? (
            <SubmitDialog
              open={openSubmit}
              onClose={() => setOpenSubmit(false)}
            />
          ) : null}
        </>
      )
    },
    {
      title: "gr.stage.4",
      defaultMessage: "Recite",
      details: <FormattedMessage id="gr.stage.4.instructions" />,
      actions: (
        <>
          <QuestionManager
            color={color}
            locked={locked}
            onAnswerCardCopy={onAnswerCardCopy}
          />
        </>
      )
    },
    {
      title: "gr.stage.5",
      defaultMessage: "Review",
      details: <FormattedMessage id="gr.stage.5.instructions" />,
      actions: (
        <>
          <QuestionManager
            color={color}
            locked={locked}
            onAnswerCardCopy={onAnswerCardCopy}
          />
          <SubmitDialog
            open={openSubmit}
            onClose={() => setOpenSubmit(false)}
          />
        </>
      )
    }
  ];

  useEffect(() => {
    if (index === 1 && !selectedQuestionId) selectFirstQuestionOfTask();
  }, [index]);

  const selectFirstQuestionOfTask = () => {
    if (
      (grMode === "full" && stage === 2) ||
      (grMode === "light" && stage === 1)
    ) {
      const qId = grTaskQuestions[0]?.id || userQuestions[0]?.id || null;
      if (selectedQuestionId)
        grAPI.updateSelectedQuestionId(selectedQuestionId);
      grAPI.updateSelectedQuestionId(qId);
    }
  };

  const renderActions = () => {
    if (panels[stage].actions) {
      return panels[stage].actions;
    } else return "";
  };

  return (
    <div
      ref={panelRef}
      role="tabpanel"
      hidden={stage !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      style={{
        // All this is in order to allow the questions box to have a scroll bar
        // A better solution would be to restructure the sidebar
        width: "inherit",
        display: "flex",
        flexDirection: "column",
        overflow: "hidden"
      }}
    >
      {stage === index && (
        <>
          <Box className={clsx(classes.panelHeader)} id="panel1a-header">
            <GrSteps color={color} />
            <Typography
              component="span"
              className={clsx(classes.panelTitle, classes.left)}
            >
              {task.name || (
                <FormattedMessage
                  id="actionBar.tooltip.gr"
                  defaultMessage="Guided Reading"
                />
              )}
            </Typography>
            <Box className={classes.stagePanelAndIcon}>
              <Typography variant="h6" className={classes.stageTitle}>
                <FormattedMessage
                  id={panels[stage].title}
                  defaultMessage={panels[stage].defaultMessage}
                />
              </Typography>
              <IconButton
                className={classes.showInstructionsBtn}
                onClick={() => setShowInstructions(!showInstructions)}
                size="medium"
                aria-label={showInstructions ? "collapse" : "expand"}
              >
                {!showInstructions ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </IconButton>
            </Box>

            {showInstructions && (
              <Typography
                variant="subtitle2"
                className={clsx(classes.instructions)}
              >
                <span className={classes.instructionsTxt}>
                  {panels[stage].details}
                </span>
                {panels[stage].defaultMessage === "Survey and Questions" ? (
                  <br></br>
                ) : (
                  ""
                )}
                <span
                  role="button"
                  className={classes.learnMoreBtn}
                  onClick={() => {
                    dispatch(setDetailedInstructions(!detailedInstructions));
                  }}
                >
                  <FormattedMessage
                    id="learn.more"
                    defaultMessage="Learn more"
                  />
                </span>
              </Typography>
            )}
          </Box>
          {renderActions()}
        </>
      )}
    </div>
  );
}

MyDefaultPanel.propTypes = {
  color: PropTypes.oneOf(["secondary", "primary"]).isRequired,
  locked: PropTypes.bool,
  onAnswerCardCopy: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired
};
