// Dependencies
import React, { useLayoutEffect, useState } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { convertEngagmentScoreToCategory } from "./utils";

// Redux

//Components

// Material UI
import { Chip } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

// Styles
const useStyles = makeStyles(theme => ({
  pending: {
    backgroundColor: theme.palette.grey[300]
  },
  teacher: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText
  },
  low: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText
  },
  medium: {
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.warning.contrastText
  },
  high: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText
  }
}));

export default function EngagementChip({ user, engagement }) {
  //Hooks
  const classes = useStyles();

  //Redux

  // Ephemeral State

  //   // Ephemeral State
  const [label, setLabel] = useState("");
  const [style, setStyle] = useState([]);
  // Derived state

  const isTeacher = user.course_role === "Teacher";

  //Behavior
  useLayoutEffect(() => {
    // Set the style
    if (isTeacher) setStyle("teacher");
    else {
      const engagmentCategoly = convertEngagmentScoreToCategory(engagement);
      setStyle(engagmentCategoly);
    }
  }, [isTeacher, engagement]);

  useLayoutEffect(() => {
    // Set the style
    if (isTeacher) setLabel("Teacher");
    else {
      const engagmentCategoly = convertEngagmentScoreToCategory(engagement);
      setLabel(engagmentCategoly);
    }
  }, [engagement, isTeacher, user.engagement]);

  return (
    <Chip
      className={clsx(classes.chip, style && classes[style])}
      label={label}
      size="small"
      clickable={false}
    />
  );
}

EngagementChip.propTypes = {
  user: PropTypes.object.isRequired,
  engagement: PropTypes.number.isRequired
};
