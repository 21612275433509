import React from "react";
import PropTypes from "prop-types";
import { Box, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

// Styles
const useStyles = makeStyles(theme => ({
  widgetHeader: {
    display: "flex",
    justifyContent: "space-between",
    padding: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.divider}`
  },
  actions: {
    display: "flex",
    alignItems: "center"
  }
}));

export default function WidgetHeader({ title, children }) {
  const classes = useStyles();

  // Redux State

  return (
    <Box className={classes.widgetHeader}>
      <Typography variant="h5">{title}</Typography>
      <Box className={classes.actions}>{children}</Box>
    </Box>
  );
}

WidgetHeader.propTypes = {
  title: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};
