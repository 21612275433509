// Dependencies
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  taskShape,
  submissionShape,
  collaborationShape
} from "../../../utils/propTypes";
import { useIntl } from "react-intl";
import clsx from "clsx";
import { isAfter, isBefore } from "date-fns";

//Redux dependencies
import { useSelector } from "react-redux";

import makeStyles from "@mui/styles/makeStyles";
import { Chip, TableCell } from "@mui/material";

// Styles
const useStyles = makeStyles(theme => ({
  chip: {},
  chipLink: {
    cursor: "pointer"
  },
  missed: {
    color: theme.palette.primary.contrast,
    backgroundColor: theme.palette.error.main,
    "&:hover": { backgroundColor: theme.palette.error.dark },
    "&:focus": { backgroundColor: theme.palette.error.dark },
    "&:active": { backgroundColor: theme.palette.error.main }
  },
  late: {
    color: theme.palette.warning.contrast,
    backgroundColor: theme.palette.warning.main,
    "&:hover": { backgroundColor: theme.palette.warning.dark },
    "&:focus": { backgroundColor: theme.palette.warning.dark },
    "&:active": { backgroundColor: theme.palette.warning.main }
  },
  pending: {
    border: `1px solid ${theme.palette.text.primary}`,
    backgroundColor: "transparent",
    "&:hover": { backgroundColor: theme.palette.text.p4 },
    "&:focus": { backgroundColor: theme.palette.text.p4 }
  },
  latePending: {
    color: theme.palette.primary.contrast,
    border: `1px solid ${theme.palette.warning.main}`,
    backgroundColor: "transparent",
    "&:hover": { backgroundColor: theme.palette.text.p4 },
    "&:focus": { backgroundColor: theme.palette.text.p4 }
  },
  onTime: {
    color: theme.palette.primary.contrast,
    backgroundColor: theme.palette.secondary.main,
    "&:hover": { backgroundColor: theme.palette.secondary.dark },
    "&:focus": { backgroundColor: theme.palette.secondary.dark },
    "&:active": { backgroundColor: theme.palette.secondary.main }
  },
  sent: {
    color: theme.palette.primary.contrast,
    backgroundColor: theme.palette.primary.main,
    "&:hover": { backgroundColor: theme.palette.primary.dark },
    "&:focus": { backgroundColor: theme.palette.primary.dark },
    "&:active": { backgroundColor: theme.palette.primary.main }
  },
  add: {
    border: `1px solid ${theme.palette.primary.main}`,
    backgroundColor: "transparent",
    "&:hover": { backgroundColor: theme.palette.text.p4 },
    "&:focus": { backgroundColor: theme.palette.text.p4 }
  },
  reviewed: {
    backgroundColor: "#BDBDBD",
    color: "currentCollor"
  }
}));

export default function PeerReviewTaskStatusChip({
  task,
  submission,
  collaboration,
  userRole,
  onClick
}) {
  //Hooks
  const classes = useStyles();
  const intl = useIntl();

  //Redux state
  const currentUserId = useSelector(state => state.user.auth.uid);

  // Ephemeral State
  const [reflectionLabel, setReflectionLabel] = useState("");
  const [reflectionChipStyle, setReflectionChipStyle] = useState([]);

  const [reviewLabel, setReviewLabel] = useState("");
  const [reviewChipStyle, setReviewChipStyle] = useState([]);

  // Variables
  const submitted = intl.formatMessage({
    id: "tasks.status.submitted",
    defaultMessage: "Submitted"
  });
  const missed = intl.formatMessage({
    id: "tasks.status.missed",
    defaultMessage: "Missed"
  });
  const pending = intl.formatMessage({
    id: "tasks.status.pending",
    defaultMessage: "Pending"
  });
  const late = intl.formatMessage({
    id: "tasks.status.late",
    defaultMessage: "Late"
  });
  const reviewed = intl.formatMessage({
    id: "tasks.status.reviewed",
    defaultMessage: "Reviewed"
  });

  //Derived State
  const isTeacher = userRole === "Teacher";
  const isCollaboration = submission.owner !== currentUserId;

  const today = new Date();

  // reflection dtatus logic
  const originalReflectionDueDate = new Date(task.original_due_date);

  const reflectionDueDate = new Date(submission.due_date);

  const reflectionSubmissionDate = submission.submission_date
    ? new Date(submission.submission_date)
    : null;

  const reflectionHasExtension = isAfter(
    reflectionDueDate,
    originalReflectionDueDate
  );

  const reflectionSubmitted = Boolean(reflectionSubmissionDate);

  const reflectionSubmittedOnTime =
    reflectionSubmitted &&
    isBefore(reflectionSubmissionDate, reflectionDueDate);

  const reflectionLatePending =
    !reflectionSubmitted &&
    reflectionHasExtension &&
    isAfter(today, originalReflectionDueDate);

  const reflectionSubmittedLate =
    reflectionSubmitted && isAfter(reflectionSubmissionDate, reflectionDueDate);

  const reflectionMissedSubmission =
    !reflectionSubmitted && isAfter(today, reflectionDueDate);

  // review status logic
  const originalReviewDueDate = new Date(task.collaboration_due_date);

  const reviewDueDate = new Date(collaboration.due_date);

  const reviewSubmissionDate = collaboration.submission_date
    ? new Date(collaboration.submission_date)
    : null;

  const reviewHasExtension = isAfter(reviewDueDate, originalReviewDueDate);

  const reviewSubmitted = Boolean(reviewSubmissionDate);

  const reviewSubmittedOnTime =
    (reviewSubmitted && isBefore(reviewSubmissionDate, reviewDueDate)) ||
    (reflectionSubmittedLate && reviewSubmitted);

  const reviewLatePending =
    !reviewSubmitted &&
    reviewHasExtension &&
    isAfter(today, originalReviewDueDate);

  const reviewSubmittedLate =
    reflectionSubmittedOnTime &&
    reviewSubmitted &&
    isAfter(reviewSubmissionDate, reviewDueDate);

  const reviewMissedSubmission =
    reflectionSubmittedOnTime &&
    !reviewSubmitted &&
    isAfter(today, reviewDueDate);

  const unableToSubmitReview =
    (reflectionMissedSubmission || reflectionSubmittedLate) &&
    !reviewSubmitted &&
    isAfter(today, reviewDueDate);

  // Behavior
  // Reflection Chip
  useEffect(() => {
    // Set the style
    switch (true) {
      case reflectionMissedSubmission:
        setReflectionChipStyle("missed");
        break;

      case !isTeacher && reflectionLatePending:
        setReflectionChipStyle("latePending");
        break;

      case reflectionSubmittedLate:
        setReflectionChipStyle("late");
        break;

      case reflectionSubmittedOnTime:
        setReflectionChipStyle("onTime");

        break;

      default:
        setReflectionChipStyle("pending");
    }

    // Set the label
    switch (true) {
      case reflectionMissedSubmission:
        setReflectionLabel(missed);
        break;

      case reviewSubmitted:
        setReflectionLabel(reviewed);
        break;

      case reflectionSubmittedLate:
        setReflectionLabel(late);
        break;

      case reflectionSubmittedOnTime:
        setReflectionLabel(submitted);
        break;

      default:
        setReflectionLabel(pending);
    }
  }, [
    isTeacher,
    late,
    missed,
    pending,
    reflectionLatePending,
    reflectionMissedSubmission,
    reflectionSubmittedLate,
    reflectionSubmittedOnTime,
    reviewSubmitted,
    reviewed,
    submitted
  ]);

  // Review Chip
  useEffect(() => {
    // Set the style
    switch (true) {
      case unableToSubmitReview:
        setReviewChipStyle("hide");
        break;

      case reviewMissedSubmission:
        setReviewChipStyle("missed");
        break;

      case !isTeacher && reviewLatePending:
        setReviewChipStyle("latePending");
        break;

      case reviewSubmittedLate:
        setReviewChipStyle("late");
        break;

      case reviewSubmittedOnTime:
        setReviewChipStyle("onTime");
        break;

      default:
        setReviewChipStyle("pending");
    }

    // Set the label
    switch (true) {
      case reviewMissedSubmission:
        setReviewLabel(missed);
        break;

      case reviewSubmittedLate:
        setReviewLabel(late);
        break;

      case reviewSubmittedOnTime:
        setReviewLabel(submitted);
        break;

      default:
        setReviewLabel(pending);
    }
  }, [
    isTeacher,
    missed,
    pending,
    late,
    unableToSubmitReview,
    reviewLatePending,
    reviewMissedSubmission,
    reviewSubmitted,
    reviewSubmittedLate,
    reviewSubmittedOnTime,
    reviewed,
    submitted
  ]);

  function renderReflectionChip() {
    return (
      <Chip
        className={clsx(
          classes.chip,
          reflectionChipStyle === "late" && classes.late,
          reflectionChipStyle === "missed" && classes.missed,
          reflectionChipStyle === "latePending" && classes.latePending,
          reflectionChipStyle === "pending" && classes.pending,
          reflectionChipStyle === "onTime" && classes.onTime
        )}
        label={reflectionLabel}
        size="small"
        onClick={onClick}
        clickable={true}
      />
    );
  }

  function renderReviewChip() {
    return (
      <Chip
        className={clsx(
          classes.chip,
          reviewChipStyle === "late" && classes.late,
          reviewChipStyle === "missed" && classes.missed,
          reviewChipStyle === "latePending" && classes.latePending,
          reviewChipStyle === "pending" && classes.pending,
          reviewChipStyle === "onTime" && classes.onTime
        )}
        label={reviewLabel}
        size="small"
        onClick={onClick}
        clickable={true}
      />
    );
  }
  // Render
  if (isTeacher)
    return (
      <>
        <TableCell>{renderReflectionChip()}</TableCell>
        <TableCell>
          {reviewChipStyle !== "hide" && renderReviewChip()}
        </TableCell>
      </>
    );
  else if (isCollaboration) return renderReviewChip();
  else return renderReflectionChip();
}

PeerReviewTaskStatusChip.propTypes = {
  task: PropTypes.shape(taskShape),
  submission: PropTypes.shape(submissionShape),
  collaboration: PropTypes.shape(collaborationShape),
  userRole: PropTypes.oneOf(["Teacher", "Student"]).isRequired,
  onClick: PropTypes.func
};
