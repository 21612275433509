// Dependencies
import React, { useEffect, useState } from "react";
import flatten from "flat";
import { withRouter, useHistory, useLocation } from "react-router-dom";
import { IntlProvider } from "react-intl";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { create } from "jss";
import rtl from "jss-rtl";
import { useErrorBoundary } from "use-error-boundary";
import SnackBars from "./SnackBars";
import ErrorPage from "./ErrorPage";
import { userAPI } from "./api";
import { captureException } from "./utils/errorHandlers";
import { firebaseApp, firestore, httpCallables } from "./firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { useDocument } from "react-firebase-hooks/firestore";
import { useFirestoreConnect } from "react-redux-firebase";

import { add } from "date-fns";
// Redux
import { useDispatch, useSelector } from "react-redux";
import { setCourses } from "./redux/coursesSlice";
import { setTexts, setSelectedTextId } from "./redux/textsSlice";
import { userNavigated } from "./redux/firebaseMiddleware";
import {
  setAuth,
  setAdmin,
  defaultProfile,
  setProfile,
  selectIsImpersonation,
  setTasksViewed
} from "./redux/userSlice";
import {
  setReaderActionData,
  defaultPersisentActionState
} from "./redux/readerActionsSlice";

// Components
import "./App.css";
import Layout from "./Layout";
import AdminNotificaitons from "./components/admin/AdminNotificaiton";
import useGetTheme from "./hooks/useGetTheme";
import SignIn from "./components/auth/SignIn";
import PangeaSpinner from "./components/SharedComponents/PangeaSpinner";
import TermsOfUseDialog from "./components/auth/TermsOfUseDialog";
import { OfflineDialog } from "./components/SharedComponents/OfflineDialog";

// Intl
import Hebrew from "./translations/he.js";
import English from "./translations/en";

//Locatlization for date pickers
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

// Material UI
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import StylesProvider from "@mui/styles/StylesProvider";
import jssPreset from "@mui/styles/jssPreset";

//rendition context
import { RenditionProvider } from "./RenditionContext";
import { useQuery } from "./hooks";
import { selectLocale, selectTextDirection } from "./redux/firestoreSelectors";
import RedirectBanner from "./components/RedirectBanner";

function App() {
  useFirestoreConnect([
    {
      collection: "userProfiles",
      doc: "defaultConfig",
      storeAs: "defaultConfig"
    }
  ]);

  const dispatch = useDispatch();
  // const locale = useSelector(state => state.user.userProfile.language);
  const locale = useSelector(state => selectLocale(state));
  const location = useLocation();
  const history = useHistory();
  const theme = useGetTheme();
  const { text_id } = useQuery();
  const [tokenRedirect, setTokenRedirect] = useState(false);
  const [user, authLoading, error] = useAuthState(firebaseApp.auth());
  const [userProfileDoc] = useDocument(firestore.doc("users/" + user?.uid));

  const textDirection = useSelector(state => selectTextDirection(state));
  const auth = useSelector(state => state.user.auth);
  const readerActionsData = useSelector(
    state => state.readerActions.persistentActionState
  );
  const isImpersonation = useSelector(selectIsImpersonation);
  const { ErrorBoundary, didCatch } = useErrorBoundary({
    onDidCatch: error => captureException(error)
  });
  const isLoggedin = Boolean(firebaseApp.auth().currentUser); // There is a problem with the current logout flow which created a bug where there is a userId for a moment after loggin out. using firebase directly to overcome this until there's a better fix.
  const userUid = user ? user.uid : null;
  const userDisplayName = user ? user.displayName : null;
  const userPhotoUrl = user ? user.photoURL : null;
  const userEmail = user ? user.email : null;

  let lang;
  switch (locale) {
    case "en":
      lang = English;
      break;
    case "he":
      lang = Hebrew;
      break;
    default:
      lang = English;
  }

  // Configure JSS
  const jss = create({
    plugins: [...jssPreset().plugins, textDirection === "rtl" && rtl()]
  });

  useEffect(() => {
    if (isLoggedin) {
      httpCallables.readUserCoursesAndTexts().then(({ data }) => {
        const { courses, texts } = JSON.parse(data);

        dispatch(setCourses(courses));
        dispatch(setTexts(texts));
      });

      firestore
        .doc("users/" + userUid)
        .get()
        .then(doc => {
          if (doc.exists) {
            let data = doc.data();
            if (!tokenRedirect && location.pathname === "/") {
              history.push(data.location);
            }
            dispatch(setProfile(data));

            if (data?.readerActionData?.highlightColor) {
              // override the default setting with the saved ones in case simething is added to the default
              const readerActionData = {
                ...defaultPersisentActionState,
                ...data.readerActionData
              };
              dispatch(setReaderActionData(readerActionData));
            } else {
              dispatch(setReaderActionData(defaultPersisentActionState));
            }
            if (!text_id) dispatch(setSelectedTextId(data.selectedTextId));
            dispatch(setTasksViewed(data.tasksViewed));
          } else {
            dispatch(setReaderActionData(defaultPersisentActionState));

            dispatch(setProfile(defaultProfile));
            if (!tokenRedirect && location.pathname === "/") {
              history.push("/library");
            }
          }
        })
        .catch(err =>
          captureException(
            err,
            `Error fetcfhing user profile from firebase, user: ${userUid}`
          )
        );
    }
  }, [isLoggedin, isImpersonation, userUid]);

  useEffect(() => {
    if (userUid) {
      const now = new Date();
      const diff =
        now.getTime() - new Date(user.metadata.lastSignInTime).getTime();
      if (diff < 20000) {
        // if user just signed in in the past 20 seconds
        userAPI.log({
          action_name: "LOGIN",
          user_id: user.uid,
          created_at: now,
          ttl: add(now, { months: 1 }),
          payload: {}
        });
      }
    }
  }, [userUid]); // eslint-disable-line

  useEffect(() => {
    const relativeUrl = location.pathname + location.search;
    if (
      !isImpersonation &&
      userUid &&
      !location.pathname.includes("logout") &&
      location.pathname !== "/"
    ) {
      firestore
        .doc("users/" + userUid)
        .update({ location: relativeUrl })
        .catch(err =>
          captureException(
            err,
            `Error updating firestore with current location, user: ${userUid} location: ${relativeUrl}`
          )
        );
    }

    if (userUid) dispatch(userNavigated({ location: relativeUrl }));
  }, [dispatch, location, userUid, isImpersonation]);

  useEffect(() => {
    if (
      !isImpersonation &&
      readerActionsData &&
      readerActionsData.highlightColor &&
      userUid
    ) {
      try {
        firestore
          .doc("users/" + userUid)
          .update({ readerActionData: readerActionsData });
      } catch (err) {
        captureException(
          err,
          `Error updating readerAction in firestore, user: ${userUid} data: ${readerActionsData}`
        );
      }
    }
  }, [readerActionsData, userUid, isImpersonation]);

  useEffect(() => {
    if (!isLoggedin) return;

    firebaseApp
      .auth()
      .currentUser.getIdTokenResult(true)
      .then(idTokenResult => {
        // Confirm the user is an Admin.
        if (
          idTokenResult.claims.role &&
          idTokenResult.claims.role === "admin"
        ) {
          dispatch(setAdmin(true));
        }
      });

    dispatch(
      setAuth({
        displayName: !isImpersonation ? userDisplayName : userUid,
        photoURL: !isImpersonation ? userPhotoUrl : "",
        uid: userUid,
        email: !isImpersonation ? userEmail : "",
        isAdmin: !isImpersonation ? auth.isAdmin : false
      })
    );

    // Check if the user is an app admin
  }, [dispatch, isLoggedin, userDisplayName, userEmail, userPhotoUrl, userUid]);

  useEffect(() => {
    let sp = new URLSearchParams(location.search);
    let token = sp.get("token");
    let path = sp.get("redirect");
    let course = sp.get("course_id");
    if (token) {
      setTokenRedirect(true);
      firebaseApp
        .auth()
        .signInWithCustomToken(token)
        .then(() => {
          if (userProfileDoc?.exists) {
            dispatch(setProfile(userProfileDoc.data()));
          }
          history.push(`/tasks?course_id=${course}`);
        });
    } else {
      if (firebaseApp.auth().isSignInWithEmailLink(window.location.href)) {
        //we saved the email in local storage when generated,
        //but if the user used another browser it is not available , so promprt them.
        let savedEmail = window.localStorage.getItem("emailForSignIn");
        if (!savedEmail) {
          savedEmail = window.prompt(
            "Please provide your email for confirmation"
          );
        }
        // The client SDK logs in the user based on link
        firebaseApp
          .auth()
          .signInWithEmailLink(savedEmail, window.location.href)
          .then(() => {
            // Clear email from storage.
            window.localStorage.removeItem("emailForSignIn");
          })
          .catch(err => {
            //TODO: add a more descriptive err
            captureException(err);
            setAuth(0);
          });
      }
    }
  }, []);

  const renderApp = () => {
    //adding token to if for LTI - sometimes slowness cause the login screen to appear when laoder should
    //still be visible  need to verify this doesn't break anything
    let sp = new URLSearchParams(location.search);
    let token = sp.get("token");
    if (
      // This whole flow need to be refactored
      isLoggedin &&
      auth &&
      user &&
      readerActionsData &&
      readerActionsData.highlightColor
    ) {
      return (
        <>
          <TermsOfUseDialog />
          <RenditionProvider>
            <Layout />
          </RenditionProvider>
        </>
      );
    } else if (!user && !token && !authLoading) {
      //TODO: add a more descriptive err
      if (error) captureException(error);
      return <SignIn />;
    } else return <PangeaSpinner />;
  };

  const [isOffline, setIsOffline] = useState(
    window.Offline && window.Offline.state !== "up"
  );

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <RedirectBanner />
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default withRouter(App);
