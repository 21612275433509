import React, { useEffect, useState } from "react";
import { firebaseFunctions } from "../../firebase";
import makeStyles from '@mui/styles/makeStyles';
import clsx from "clsx";
import { useSelector } from "react-redux";
import { selectCourse } from "../../redux/coursesSlice";

import useConvertToTimeZone from "../../hooks/useConvertToTimezone";

import { useHistory } from "react-router-dom";
import {
  Box,
  Table,
  TableContainer,
  TableCell,
  TableBody,
  TableRow,
  TableHead
} from "@mui/material";

const useStyles = makeStyles(theme => ({
  container: {
    width: "100w",
    height: "100vh"
  },
  dialog: {
    backgroundColot: "red"
  },
  tableContainer: {
    width: "80%",
    marginLeft: "auto",
    marginRight: "auto",
    marginBottom: "24px"
  },
  selectEmpty: {
    width: "80%"
  },
  tableHead: {
    fontWeight: "800"
  }
}));

function Tasks({ task }) {
  const classes = useStyles();
  const history = useHistory();

  const course = useSelector(state => selectCourse(state, task.course_id));

  const [formattedDueDate] = useConvertToTimeZone(
    task.original_due_date,
    course.timezone
  );

  return (
    <TableRow
      key={task.id}
      onClick={e => {
        history.push("adminTaskView/" + task.id);
      }}
    >
      <TableCell className={classes.cell} component="th" scope="row">
        {task.id}
      </TableCell>
      <TableCell className={classes.cell} align="left">
        {task.name}
      </TableCell>
      <TableCell className={classes.cell} align="left"></TableCell>
      <TableCell className={classes.cell} align="left">
        {formattedDueDate}
      </TableCell>
      <TableCell className={classes.cell} align="left">
        {/* {formattedAcceptDate} */}
      </TableCell>
      <TableCell className={classes.cell} align="left">
        {task.task_users}
      </TableCell>
      <TableCell className={classes.cell} align="left">
        {task.submitted_count}
      </TableCell>
    </TableRow>
  );
}

function CourseTasks({ courseId }) {
  const classes = useStyles();

  const [tasks, setTasks] = useState([]);
  useEffect(() => {
    const getAllCourseTasks = firebaseFunctions.httpsCallable(
      "adminFunctions-getAllCourseTasks"
    );

    getAllCourseTasks({ course: courseId }).then(response => {
      const tasks = response.data;
      setTasks(tasks);
    });
  }, [courseId]);

  return (
    <Box>
      <TableContainer className={classes.tableContainer}>
        <Table className={classes.table} aria-label="course tasks table">
          <TableHead>
            <TableRow className={classes.tableHead}>
              <TableCell className={clsx(classes.cell)}>ID</TableCell>
              <TableCell className={classes.cell}>Task Name</TableCell>
              <TableCell className={classes.cell}>Description</TableCell>
              <TableCell className={classes.cell}>Deadline</TableCell>
              <TableCell className={classes.cell}>Accept until</TableCell>
              <TableCell className={classes.cell}>All Users</TableCell>
              <TableCell className={classes.cell}>Submitted Count</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tasks.map((task, index) => {
              <Tasks key={index} task={task} />;
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

export default CourseTasks;
