// Dependencies
import React from "react";
import { useQuery } from "../../../hooks";

// Redux dependencies
import { useSelector } from "react-redux";
import { selectSubmission } from "../../../redux/tasksSlice";
import makeStyles from '@mui/styles/makeStyles';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from "@mui/material";
import { selectTextDirection } from "../../../redux/firestoreSelectors";

// Styles
const useStyles = makeStyles(theme => ({
  // this is styling the paper component within the dialog
  modal: {
    position: "relative",
    width: "50%",
    padding: "10px",
    textAlign: "start"
  },

  modalActions: {
    justifyContent: "end",
    "& button": {
      fontSize: "inharit",
      fontWeight: "600"
    }
  },

  button: { textTransform: "none" }
}));

export default function PeerReviewModalMissingReviewModal({
  dialogOpen,
  setDialogOpen
}) {
  //Hooks
  const classes = useStyles();
  const { submission_id } = useQuery();
  //Redux state
  const textDireciton = useSelector(state => selectTextDirection(state));
  const submission = useSelector(state =>
    selectSubmission(state, Number(submission_id))
  );

  //Ephemeral state

  // Variables

  //Behavior
  const closeModal = () => {
    setDialogOpen(false);
  };

  // Render
  if (submission.status === "pendingResponse") {
    return (
      <>
        <div>
          <Dialog
            open={dialogOpen}
            onClose={closeModal}
            aria-labelledby="alert-dialog-title"
            PaperProps={{
              className: classes.modal,
              style: {
                direction: textDireciton
              }
            }}
          >
            <DialogTitle id="alert-dialog-title">Missing Review</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {`${submission.user_name} hasn’t submitted their review yet. Once they do you will be able to share your feedback. `}
              </DialogContentText>
            </DialogContent>
            <DialogActions className={classes.modalActions}>
              <Button
                className={classes.button}
                onClick={closeModal}
                color="primary"
                variant="contained"
                disableElevation
              >
                OK
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </>
    );
  } else return null;
}
