import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { useGetTheme } from "../../hooks";
import { useSelector } from "react-redux";

// Redux Dependencies

// Material UI
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import { Box } from "@mui/material";
import { selectIsSuggestions } from "../../redux/firestoreSelectors";

// Styles
const useStyles = makeStyles(theme => {
  return {
    sidebar: {
      background: theme.palette.background.sidePanel,
      display: "flex",
      flexShrink: 0,
      zIndex: 3
    },
    expanded: {
      height: "100%",
      width: 384, //sidebar + side panel, 336px + 48px
      [theme.breakpoints.up("desktop")]: {
        width: 468
      }
    },
    collapsed: {
      width: 48
    },
    sidebarWithSuggestions: { top: "24px" }
  };
});

// This is a presentational component that abstract the styling of the sidebar so that it could be controlled in a single locaiton

// Requierd props: children,
// Optional props: className, collapsed (defaults to false)

function PrimarySidebarLayout({ children, className, collapsed = false }) {
  //Hooks
  const classes = useStyles();
  const theme = useGetTheme();
  const isSuggestions = useSelector(state => selectIsSuggestions(state));

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <Box
          id="sidebar"
          className={clsx(
            classes.sidebar,
            collapsed ? classes.collapsed : classes.expanded,
            isSuggestions && classes.sidebarWithSuggestions,
            className
          )}
        >
          {children}
        </Box>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

PrimarySidebarLayout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.element]).isRequired,
  className: PropTypes.string,
  collapsed: PropTypes.bool
};

export default PrimarySidebarLayout;
