// Dependancies
import { httpCallables } from "../../../firebase";
import { minutesToMilliseconds } from "date-fns";
import {splitParagraphs} from "./textUtils";

// Redux
import store from "../../store";
import {
  CHATBOX_STATUS,
  CHAT_TYPE,
  MOODS,
  openChatbox,
  setIsLoading
} from "../../chatSlice";

// rxjs
import { of, from, interval, EMPTY } from "rxjs";
import {
  tap,
  map,
  concatMap,
  delayWhen,
  delay,
  take,
  takeUntil
} from "rxjs/operators";

// utils

export function isFeatureFlagEnabled(state, flag) {
  const { firestore } = state;
  const featureFlags = firestore?.data?.crossFeatureConfig?.FEATURE_FLAGS;
  return featureFlags ? featureFlags[flag] : false; // return false if featureFlags is undefined
}

export function fetchMentor({
  userUid,
  textId,
  submissionId = null,
  questionId = null,
  sessionId = null,
  currentUserResp = null,
  textSegment,
  chatType = null
}) {
  return from(
    httpCallables
      .ementoringInteraction({
        userUid,
        textId,
        questionId,
        textSegment,
        sessionId,
        submissionId,
        currentUserResp,
        chatType
      })
      .then(({ data }) => {
        const { success } = data;
        if (success) {
          return JSON.parse(data.payload);
        } else {
          const { error } = data;
          throw new Error(`Error fetching ementoringInteraction. ${error}`);
        }
      })
  );
}

export function mapStateToFetchProps(action, state) {
  const chatType = action?.payload ? action.payload.chatType : CHAT_TYPE.READER;
  const readingSession = state.reader.readingSession.map(
    session => session.content
  );

  const userUid = state.firebase.auth.uid;
  const textId = Number(state.texts.selectedTextId);
  const submissionId =
    Number(state.router.location.query?.submission_id) || null;
  const questionId = state.interactions?.selectedInteractionId || null;
  const textSegment = overlapConcat(readingSession);
  const mood = MOODS[state.chat.mood].displayName;
  const sessionId = state.chat.sessionId;
  const currentUserResp = action.payload?.content || null;
  return {
    chatType,
    userUid,
    textId,
    submissionId,
    questionId,
    textSegment,
    currentUserResp,
    sessionId,
    mood
  };
}

export function questionHasHighlights(state) {
  // checks if current interaction has highlights, returns a boolean
  const selectedInteraction = state.interactions.selectedInteractionId;
  const highlights = state.interactions.highlights;

  return highlights.some(
    highlight => highlight.interaction_id === selectedInteraction
  );
}

export function openChatboxIfClosed(state) {
  if (state.chat.staus !== CHATBOX_STATUS.OPEN) {
    store.dispatch(openChatbox());
  }
}

function overlapConcat(arr) {
  let result = arr[0];

  for (let i = 0; i < arr.length - 1; i++) {
    let str1 = arr[i];
    let str2 = arr[i + 1];
    let maxLen = Math.min(str1.length, str2.length);
    let foundOverlap = false;
    for (let j = 0; j < maxLen; j++) {
      let k = maxLen - j;
      let substring1 = str1.substring(str1.length - k);
      let substring2 = str2.substring(0, k);

      if (substring1 === substring2) {
        result += str2.substring(k);
        foundOverlap = true;
        break;
      }
    }
    if (!foundOverlap) result += `[...] ${str2}`;
  }
  return result;
}

export function orchestrateMentorResponse(mentorResponse, sessionId, state) {
  const currentSessionId = state.chat.sessionId;
  const isResponseRelevant =
    !currentSessionId || currentSessionId === sessionId;

  if (!isResponseRelevant) return EMPTY;

  // fakeing a chat bubble delay as if the user is chating with a mentor
  const parsedMentorResponse = splitParagraphs(mentorResponse);
  return from(parsedMentorResponse).pipe(
    concatMap((content, index) =>
      of(content).pipe(
        take(parsedMentorResponse.length),
        delay(1000), // pause between showing a chat bubble and the loading state
        tap(() => store.dispatch(setIsLoading(true))),
        delayWhen(() => calculateChatBubbleDelay(index, parsedMentorResponse)),
        map(() => ({
          content: parsedMentorResponse[index],
          sessionId
        })),
        tap(() => store.dispatch(setIsLoading(false)))
      )
    )
  );
}

function calculateChatBubbleDelay(index, mentorResponse) {
  // delay bubble by the time it takes to read the previous one
  const isFirstChatBubble = index === 0;
  const previousContent = mentorResponse[index - 1];

  return interval(
    !isFirstChatBubble ? calculateAvarageReadingTimeForText(previousContent) : 0
  );
}

function calculateAvarageReadingTimeForText(text) {
  const wordsPerMinute = 300; // a little faster then the average reading speed for an adult
  const words = text.split(" ").length;
  const minutes = words / wordsPerMinute;
  const delayInMilliseconds = minutesToMilliseconds(minutes);
  return delayInMilliseconds;
}
