// Dependancies
import { combineEpics } from "redux-observable";

// Epics
import { fetchConversationEpic } from "./fetchConversationEpic";
import { replyToMentorEpic } from "./replyToMentorEpic";
import { initMentorOnTimerEpic } from "./initMentorOnTimerEpic";
import { initMentorReviewEpic } from "./initMentorReviewEpic";
import { mentorReviewFabClickEpic } from "./mentorReviewFabClickEpic";

export const eMentorEpics = combineEpics(
  initMentorReviewEpic,
  initMentorOnTimerEpic,
  replyToMentorEpic,
  fetchConversationEpic,
  mentorReviewFabClickEpic
);
