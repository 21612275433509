// Dependencies
import {
  fetchMentor,
  mapStateToFetchProps,
  orchestrateMentorResponse
} from "./utils";

// redux
import store from "../../store";
import { reFetchSubmission } from "../../tasksSlice";
import { replyToMentor, mentorReplies, setIsLoading } from "../../chatSlice";

// rxJs
import { ofType } from "redux-observable";
import { EMPTY } from "rxjs";

import {
  tap,
  map,
  withLatestFrom,
  concatMap,
  catchError
} from "rxjs/operators";
import { captureException } from "../../../utils/errorHandlers";

export const replyToMentorEpic = (action$, state$) => {
  return action$.pipe(
    ofType(replyToMentor.type),
    withLatestFrom(state$),
    tap(() => store.dispatch(setIsLoading(true))),
    map(([action, state]) => mapStateToFetchProps(action, state)),
    concatMap(({ currentUserResp, sessionId, mood }) =>
      fetchMentor({ currentUserResp, sessionId, mood }).pipe(
        tap(
          ({ newAssetsSaved, submissionId }) =>
            newAssetsSaved && store.dispatch(reFetchSubmission(submissionId))
        ),
        withLatestFrom(state$),
        concatMap(([{ mentorResponse }, state]) => {
          return orchestrateMentorResponse(mentorResponse, sessionId, state);
        })
      )
    ),
    map(payload => ({ type: mentorReplies.type, payload })),
    catchError(error => {
      captureException(error, "Error in replyToMentorEpic");
      return EMPTY;
    })
  );
};
