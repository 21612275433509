import { firestore, auth } from "../firebase";
import { httpCallables } from "../firebase";
import { v4 as uuid } from "uuid";
import {
  createAnswer,
  deleteAnswer,
  setSelectedInteractionId
} from "../redux/interactionsSlice";
import { setIsLoading } from "../redux/readerActionsSlice";
import { updateTaskSubmissionStatus } from "../redux/tasksSlice";
import { addSnackbarItem } from "../redux/snackbarSlice";
import store from "../redux/store";
import { captureException } from "../utils/errorHandlers";
import history from "../history";

const standardTaskAPI = {
  createAnswerComment: async function (answer) {
    if (!answer) return;
    const id = uuid();
    try {
      store.dispatch(createAnswer({ id, ...answer }));
      const answerForDB = {
        func_name: "createAnswerComment",
        id,
        ...answer
      };
      httpCallables.interactionFunctions(answerForDB);
    } catch (err) {
      captureException(err);
      store.dispatch(deleteAnswer(answer));
    }
  },
  createAnswerSelection: async function (answer) {
    if (!answer) return;
    const id = uuid();
    try {
      store.dispatch(createAnswer({ id, ...answer }));
      const answerForDB = {
        id,
        ...answer,
        func_name: "createAnswerSelection"
      };
      httpCallables.interactionFunctions(answerForDB);
    } catch (err) {
      captureException(err);
      store.dispatch(deleteAnswer(answer));
    }
  },

  undoStandardTaskSubmission: async function (submission_id, offline_task_id) {
    try {
      store.dispatch(setIsLoading(true));
      await httpCallables
        .taskFunctions({
          func_name: "undoStandardTaskSubmission",
          offline_task_id,
          submission_id
        })
        .then(({ data }) => {
          const { success } = data;

          if (success) {
            store.dispatch(
              updateTaskSubmissionStatus({
                id: submission_id,
                status: "Active",
                submission_date: null
              })
            );
            history.push(`/task?submission_id=${submission_id}`);
          } else {
            store.dispatch(
              addSnackbarItem({
                intlId: "undo.undoSubmissionFailed",
                intlDefaultMessage: "Undo failed", // TODO: need text from product
                id: uuid() // TODO: we are not capturing this id, why do we need to pass it and not create it in the SnackBar component?
              })
            );
            let error = new Error(`UNDO_TASK_SUBMISSION_FAILED`);
            error.message = data.error;
            error.data = { submission_id };
            throw error;
          }
        });
    } catch (err) {
      captureException(err);
    } finally {
      store.dispatch(setIsLoading(false));
    }
  },
  updateSelectedQuestionId: async function (questionId, taskId) {
    const currentInteractionId =
      store.getState().interactions.selectedInteractionId;
    try {
      store.dispatch(setSelectedInteractionId(questionId));
      const doc = await firestore
        .doc(`tasks/${auth.currentUser.uid}/task/${taskId}`)
        .get();
      doc.ref.set({ selectedQuestion: questionId }, { merge: true });
    } catch (err) {
      captureException(err);
      store.dispatch(setSelectedInteractionId(currentInteractionId));
    }
  }
};

export default standardTaskAPI;
