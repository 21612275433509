// Dependencies
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { firebaseFunctions } from "../../firebase";

// Redux dependencies

// Components
import PangeaSpinner from "../SharedComponents/PangeaSpinner";
import ScrollBox from "../SharedComponents/ScrollBox";
import CreateCourseDialog from "./CreateCourseDialog";
import CoursesList from "./CoursesList";

import makeStyles from '@mui/styles/makeStyles';
import { Box, Typography, Button } from "@mui/material";

// Styles
const useStyles = makeStyles(theme => ({
  container: {
    height: "100%",
    flex: 1,
    display: "flex",
    position: "relative",
    alignItems: "center"
  },
  header: {
    display: "flex",
    flexFlow: "row nowrap",
    alignItems: "center",
    justifyContent: "space-between",
    marginBlock: theme.spacing(5),
    flexGrow: 0,
    width: "83.333%"
  },
  libraryContainer: {
    width: "83.333333%", // 10 culumns on a 12 column grid
    height: "100%",
    paddingInline: 40,
    alignItems: "center",
    position: "relative",

    [theme.breakpoints.up("desktop")]: {
      maxWidth: 840,
      marginInline: "auto"
    }
  },
  headerFont: {
    fontSize: "3.75rem"
  }
}));

function Admin() {
  //Hooks
  const classes = useStyles();
  const intl = useIntl();

  //Ephemeral State
  const [courses, setCourses] = useState(null);
  const [newCourseDialogVisable, setNewCourseDialogVisable] = useState(false);

  //Variables

  //Behavior
  useEffect(() => {
    var getAllCourses = firebaseFunctions.httpsCallable(
      "adminFunctions-getAllCourses"
    );

    getAllCourses().then(({ data }) => {
      const sortedCourses = data.sort((a, b) => a.id - b.id);
      setCourses(sortedCourses);
    });
  }, []);

  //Render
  if (!courses) {
    return <PangeaSpinner />;
  } else
    return (
      <>
        <CreateCourseDialog
          courses={setCourses}
          setCourses={setCourses}
          newCourseDialogVisable={newCourseDialogVisable}
          setNewCourseDialogVisable={setNewCourseDialogVisable}
        />
        <ScrollBox className={classes.container}>
          <Box className={classes.header}>
            <Typography className={classes.headerFont} variant="h1">
              {intl.formatMessage({
                id: "admin.courseAdmin",
                defaultMessage: "Courses Admin"
              })}
            </Typography>

            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                setNewCourseDialogVisable(true);
              }}
            >
              Create Course
            </Button>
          </Box>
          <CoursesList courses={courses} setCourses={setCourses} />
        </ScrollBox>
      </>
    );
}

export default Admin;
