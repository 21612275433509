import { createSlice } from "@reduxjs/toolkit";
import {
  USER_PROFILE,
  FEATURES,
  READER_MODES,
  PRIMARY_SIDEBAR_STATE
} from "../consts";
import { determineConfig } from "./firestoreSelectors";
import { captureException } from "../utils/errorHandlers";
export const defaultPersisentActionState = {
  actionBar: "poc",
  annotatorMode: false,
  showHighlights: true,
  showAllHighlights: false,
  clearSelection: false,
  highlightColor: "#BEF3BF"
};

//Thunk

export function ToggleReaderMode() {
  return (dispatch, getState, { getFirestore }) => {
    const state = getState();
    const firestore = getFirestore();
    const userId = state.firebase.auth.uid;

    // We persist the state per feature. The context will be the name of the feature the user is currently in (reader, task, etc)
    const context = FEATURES.READER;
    const { readerMode } = determineConfig(state, context);
    const updatedReaderMode =
      readerMode === READER_MODES.GUIDED_READING
        ? READER_MODES.FREE_ANNOTATIONS
        : READER_MODES.GUIDED_READING;

    const updatePrimarySidebarState =
      readerMode === READER_MODES.GUIDED_READING
        ? PRIMARY_SIDEBAR_STATE.COLLAPSED
        : PRIMARY_SIDEBAR_STATE.EXPANDED;
    firestore.set(
      `${USER_PROFILE.CUSTOM_CONFIG_PATH}/${userId}/${context}`,
      {
        readerMode: updatedReaderMode,
        primarySidebarState: updatePrimarySidebarState
      },
      { merge: true }
    );
  };
}

export const readerActionsSlice = createSlice({
  name: "readerActions",
  initialState: {
    breadcrumbs: false,
    breadCrumbsClicked: false,
    blueBreadcrumbs: false,
    showTextMenu: false,
    selectedLocation: false,
    shownLocation: false,
    isLoading: false,
    persistentActionState: false
    // persistentActionState: {
    //   actionBar: false,
    //   annotatorMode: false,
    //   showHighlights: true,
    //   clearSelection: false,
    //   highlightColor: "#91fcc2",
    // },
  },

  extraReducers: {
    "gr/setStage": (state, value) => {
      state.shownLocation = false;
    },
    "gr/setSelectedQuestion": (state, value) => {
      state.shownLocation = false;
    },
    "gr/deleteSelectedAnswer": (state, value) => {
      state.shownLocation = false;
    },
    "gr/selectLocation": (state, value) => {
      state.selectedLocation = value.payload;
      if (value.payload) {
        state.shownLocation = value.payload;
      }
    },
    "task/setSelectedTask": (state, value) => {
      state.shownLocation = false;
    },
    "task/setQuestionIndex": (state, value) => {
      state.shownLocation = false;
    },
    "realtimeInteractions/setIsSingleThread": (state, value) => {
      if (value.payload.ref && value.payload.ref.cfi) {
        state.selectedLocation = value.payload.ref;
      }
    },
    gotAnswers: (state, value) => {
      state.shownLocation = false;
    }
  },
  reducers: {
    setLocation: (state, value) => {
      state.selectedLocation = value.payload;
      if (value.payload) {
        state.shownLocation = value.payload;
      }
    },
    setHighlightColor: (state, value) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.persistentActionState.highlightColor = value.payload;
    },
    setShowHighlights: (state, value) => {
      state.persistentActionState.showHighlights = value.payload;
      state.selectedLocation = false;
      state.shownLocation = false;
    },
    setBreadcrumbs: (state, action) => {
      state.breadCrumbsClicked = false;
      state.breadcrumbs = action.payload.breadcrumbs;
      if (action.payload.blue) {
        state.blueBreadcrumbs = action.payload.blue;
      } else state.blueBreadcrumbs = false;
      if (action.payload.showTextMenu) {
        state.showTextMenu = action.payload.showTextMenu;
      } else state.showTextMenu = false;
      if (action.payload.showDoneButton) {
        state.showDoneButton = action.payload.showDoneButton;
      } else state.showDoneButton = false;
    },
    setAction: (state, action) => {
      try {
        state.persistentActionState.actionBar = action.payload;
        if (state.persistentActionState.actionBar === "poc") {
          state.persistentActionState.annotatorMode = "poc";
        }

        state.selectedLocation = false;
        state.shownLocation = false;
      } catch (err) {
        captureException(err);
      }
    },

    setBreadcrumbsClicked: (state, value) => {
      state.breadCrumbsClicked = value.payload;
    },
    setAnnotatorMode: (state, mode) => {
      state.persistentActionState.annotatorMode = mode.payload;
      state.selectedLocation = false;
      state.shownLocation = false;
    },
    setShowAllHighlights: (state, mode) => {
      state.persistentActionState.showAllHighlights =
        !state.persistentActionState.showAllHighlights;
    },
    setReaderActionData: (state, value) => {
      state.persistentActionState = value.payload;
    },
    setIsLoading: (state, value) => {
      state.isLoading = value.payload;
    },
    setReaderMode: (state, value) => {
      state.readerMode = value.payload;
    }
  }
});

export const {
  setBreadcrumbsClicked,
  setReaderActionData,
  setLocation,
  setHighlightColor,
  setUnderline,
  setAction,
  setAnnotatorMode,
  setShowHighlights,
  setBreadcrumbs,
  setShowAlternativeView,
  setIsLoading,
  toggleCommentsPanelOrPdfThumbBar,
  setReaderMode,
  setShowAllHighlights
} = readerActionsSlice.actions;

export default readerActionsSlice.reducer;
