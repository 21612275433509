// Dependencies
import React, { useState, useEffect, useReducer, useRef } from "react";
import { httpCallables } from "../../../../firebase";
import clsx from "clsx";

import { useHistory } from "react-router-dom";
import { sub, startOfDay, isBefore, isWithinInterval } from "date-fns";
import {
  useGetTheme,
  useImpersonate,
  useQuery,
  useOnClickOutside
} from "../../../../hooks";
import { v4 as uuid } from "uuid";
import { captureException } from "../../../../utils/errorHandlers";
import { filter } from "lodash";
import {
  TASK,
  ENGAGEMENT_SCORE_COEFFICIENT,
  COURSE_ACTIONS_FOR_ANALYSES
} from "../../../../consts";

// Redux
import { addSnackbarItem } from "../../../../redux/snackbarSlice";
import { useDispatch, useSelector } from "react-redux";

//Components
import ScrollBox from "../../../SharedComponents/ScrollBox";
import EngagementChart from "./ClassEngagementChart";
import CreatedGrInteractionsBarChart from "./CreatedGrInteractionsBarChart";

import makeStyles from "@mui/styles/makeStyles";

import {
  Box,
  Grid,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  Checkbox,
  Menu,
  ListItemText,
  MenuItem,
  IconButton,
  Link,
  Divider,
  TextField
} from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";

import { DatePicker } from "@mui/x-date-pickers";
import UserSubmissionBarChart from "./UserSubmissionsBarChart";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { FormattedMessage } from "react-intl";

// Styles
const useStyles = makeStyles(theme => ({
  container: {
    width: "100%",
    padding: theme.spacing(8)
  },
  header: { marginBlockEnd: theme.spacing(8) },
  formControl: {
    minWidth: 120
  },
  dailyMetadata: {
    width: "50%",
    display: "flex",
    justifyContent: "space-between",
    marginBlockEnd: theme.spacing(3)
  },
  metadata: {
    "& span:not(:last-child)": {
      marginInlineEnd: theme.spacing(5)
    }
  },
  tableTitle: {
    marginBlockEnd: theme.spacing(3)
  },
  table: {
    marginBlockEnd: theme.spacing(3)
  },
  tableHead: {
    backgroundColor: theme.palette.secondary.p12
  },
  tableRow: {},
  tableHeadCell: {
    position: "relative",
    fontWeight: "bold",
    border: "none",
    "&:not(:first-child)::before": {
      content: '"|"',
      position: "relative",
      top: "-2px",
      left: "-10px",
      color: theme.palette.text.p30
    }
  },
  tableCell: {
    border: "none"
  },
  submissionChartContainer: {
    maxWidth: 270
  },
  engagementChart: {
    height: 700
  }
}));

function usersReducer(state, action) {
  switch (action.type) {
    case "set":
      return { ...action.payload };
    case "toggle":
      const user = action.payload;
      return {
        ...state,
        // keep the user
        [user]: { ...state[user], selected: !state[user].selected }
      };
    default:
      throw new Error();
  }
}
const MuiMenu = React.forwardRef((props, ref) => {
  return <Menu ref={ref} {...props} />;
});

MuiMenu.displayName = "MuiMenu";

export default function CourseActivityReportOld() {
  //Hooks
  const dispatch = useDispatch();
  const classes = useStyles();
  const { course_id, user_id } = useQuery();
  const ref = useRef();
  const history = useHistory();
  const theme = useGetTheme();
  const selectionRef = useRef(null);
  //Redux
  const rtl = useSelector(state => state.user.userProfile.rtl);
  const { impersonate } = useImpersonate();

  // Ephemeral State

  const [users, dispatchUsers] = useReducer(usersReducer, {}); // user info by uid
  const [allUserIds, setAllUserIds] = useState([]); // array of all the uids
  const [data, setData] = useState([]);
  const [start, setStart] = useState(sub(new Date(), { days: 2 }));
  const [end, setEnd] = useState(sub(new Date(), { days: 1 }));
  const [submissions, setSubmissions] = useState([]);
  const [userEngagement, setUserEngagement] = useState([]);
  const [courseEngagement, setCourseEngagement] = useState([]);
  const [courseTasks, setCourseTasks] = useState([]);
  const [selectedUsersIds, setSelectedUsersIds] = useState([]);
  const [usersBucketActivity, setUserBucketActivity] = useState([]);
  const [anchorEl, setAnchorEl] = useState(false);
  const [showUsersList, setShowUsersList] = useState(false);
  const [actionsForAnalysis, setActionsForAnalysis] = useState([]);
  // Global state

  // Derived state
  const selectedUsers = filter(users, value => value.selected).map(
    users => users.course_user
  );
  //Behavior
  useEffect(() => {
    const now = new Date();
    setStart(sub(now, { days: 2 }));
    setEnd(sub(now, { days: 1 }));

    // Set all actions in array
    Object.keys(COURSE_ACTIONS_FOR_ANALYSES).forEach(action => {
      handleActionsChange(action);
    });
  }, []);

  useEffect(() => {
    httpCallables
      .readCourseUsers({ course_id: Number(course_id) })
      .then(({ data }) => {
        if (data.success) {
          const { users } = data.payload;
          dispatchUsers({
            type: "set",
            payload: users.reduce(
              (accumulator, current) => ({
                ...accumulator,
                [current.course_user]: { ...current, selected: true }
              }),
              {}
            )
          });
          setAllUserIds(users.map(user => user.course_user));
        } else {
          const { error } = data.payload;
          dispatch(
            addSnackbarItem({
              intlId: "error.readCourseUsersFailed",
              intlDefaultMessage:
                "There was a problem getting the course information. Please check your connection and try again",
              id: uuid()
            })
          );
          captureException(error, `Faild to get course users`);
        }
      });
  }, [course_id, dispatch]);

  useEffect(() => {
    if (!start || !end || !allUserIds.length) return;

    httpCallables
      .taskFunctions({
        func_name: "readTeacherCourseTasks",
        course_id: Number(course_id)
      })
      .then(({ data }) => {
        if (!data.length) return;
        const { tasks } = JSON.parse(data);
        const filtere_tasks = tasks.filter(task => {
          const due_date = new Date(task.original_due_date).getTime();
          return isWithinInterval(due_date, {
            start: start.getTime(),
            end: end.getTime()
          });
        });
        setCourseTasks(filtere_tasks);
      });
  }, [dispatch, course_id, allUserIds.length, end, start]);

  useEffect(() => {
    if (!start || !end || !allUserIds.length) return;
    httpCallables
      .generateCourseActivityReport_OLD_VERSION({
        user_ids: allUserIds,
        course_id: Number(course_id),
        start,
        end,
        actionsForAnalysis
      })
      .then(({ data }) => {
        const { success } = data;
        if (success) {
          // set the stats for data
          setData(data.payload.data);
          // set the stats for engagement and submissions
          setEngagementAndSubmissionsData(data.payload.course_submissions);
          const { user_engagement } = data.payload.userEngagementScore;
          setUserEngagement(user_engagement);
          let group_engagement = "class";
          setCourseEngagement([
            {
              [group_engagement]: data.payload.bucketed_data
            }
          ]);
        } else {
          const { error } = data;
          dispatch(
            addSnackbarItem({
              intlId: "error.generateUserActivityReportFailed",
              intlDefaultMessage:
                "There was a problem getting the course information. Please check your connection and try again",
              id: uuid()
            })
          );

          captureException(error, `Faild to get course users`);
        }
      });
  }, [allUserIds, dispatch, end, start, course_id, actionsForAnalysis]);

  useEffect(() => {
    if (!start || !end || !selectedUsersIds.length)
      return setUserBucketActivity([]);
    setUserBucketActivity([]);
    for (let i = 0; i < selectedUsersIds.length; i++) {
      httpCallables
        .generateCourseActivityReport_OLD_VERSION({
          user_ids: selectedUsersIds[i],
          course_id: Number(course_id),
          start,
          end,
          actionsForAnalysis
        })
        .then(({ data }) => {
          const { success } = data;
          if (success) {
            let usersBucket = data.payload.bucketed_data;
            setUserBucketActivity(usersBucketActivity => [
              ...usersBucketActivity,
              { [selectedUsersIds[i]]: usersBucket }
            ]);
          } else {
            const { error } = data;
            dispatch(
              addSnackbarItem({
                intlId: "error.generateUserActivityReportFailed",
                intlDefaultMessage: `There was a problem getting ${selectedUsersIds[i]} information. Please check your connection and try again`,
                id: uuid()
              })
            );

            captureException(error, `Faild to get course users`);
          }
        });
    }
  }, [selectedUsersIds, dispatch, course_id, end, start, actionsForAnalysis]);

  function setEngagementAndSubmissionsData(data) {
    let user_submissions = populateUserDataBySubmissionStatus(data);
    user_submissions = calculateEngagementScore(
      user_submissions,
      userEngagement
    );
    setSubmissions(user_submissions);
  }

  function populateUserDataBySubmissionStatus(submissions) {
    let user_data = {};
    submissions
      .filter(submission => {
        let due_date = startOfDay(new Date(submission.due_date)).getTime();
        return isWithinInterval(due_date, {
          start: start.getTime(),
          end: end.getTime()
        });
      })
      .forEach(submission => {
        const user_id = submission.owner;
        const status = submission.status;
        if (!(user_id in user_data)) {
          user_data[user_id] = {
            [TASK.SUBMISSION_STATUS.MISSED]: 0,
            [TASK.SUBMISSION_STATUS.LATE]: 0,
            [TASK.SUBMISSION_STATUS.PENDING]: 0,
            [TASK.SUBMISSION_STATUS.SUBMITTED]: 0
          };
        }
        const s_status = calculateSubmissionStatus(submission, status);

        if (s_status === TASK.SUBMISSION_STATUS.LATE) {
          user_data[user_id][TASK.SUBMISSION_STATUS.LATE] += 1;
        } else if (s_status === TASK.SUBMISSION_STATUS.MISSED) {
          user_data[user_id][TASK.SUBMISSION_STATUS.MISSED] += 1;
        } else if (s_status === TASK.SUBMISSION_STATUS.SUBMITTED) {
          user_data[user_id][TASK.SUBMISSION_STATUS.SUBMITTED] += 1;
        } else if (s_status === TASK.SUBMISSION_STATUS.PENDING)
          user_data[user_id][TASK.SUBMISSION_STATUS.PENDING] += 1;
      });
    return user_data;
  }

  function calculateSubmissionStatus(submission, status) {
    const due_date = new Date(submission.due_date).getTime();
    const submission_date = new Date(submission.submission_date).getTime();
    const now = new Date().getTime();
    const submission_missed =
      status === TASK.SUBMISSION_STATUS.PENDING && isBefore(due_date, now);
    const submission_late =
      (status === TASK.SUBMISSION_STATUS.SUBMITTED ||
        status === TASK.SUBMISSION_STATUS.GRADED) &&
      isBefore(due_date, submission_date);

    if (submission_late) return TASK.SUBMISSION_STATUS.LATE;
    else if (submission_missed) return TASK.SUBMISSION_STATUS.MISSED;
    else if (submission_date) return TASK.SUBMISSION_STATUS.SUBMITTED;
    else return TASK.SUBMISSION_STATUS.PENDING;
  }

  function calculateEngagementScore(single_user_data, userEngagement) {
    let engagement_score = 0;
    let data_array = hashToArray(single_user_data);
    for (let i = 0; i < data_array.length; i++) {
      if (!data_array[i].missed) data_array[i].missed = 0;
      if (!data_array[i].late) data_array[i].late = 0;
      engagement_score =
        data_array[i].missed * ENGAGEMENT_SCORE_COEFFICIENT.MISSED +
        data_array[i].late * ENGAGEMENT_SCORE_COEFFICIENT.LATE +
        ENGAGEMENT_SCORE_COEFFICIENT[
          `${userEngagement[data_array[i].key]}_SCORE`
        ];
      data_array[i]["engagement_score"] = Number(engagement_score);
    }

    data_array.sort((a, b) => {
      return a.engagement_score < b.engagement_score ? 1 : -1;
    });
    return data_array;
  }

  function hashToArray(hash, copyKey = false, optionalRenameKey) {
    let data_array = [];
    Object.keys(hash).forEach(key => {
      let value = hash[key];
      let obj = {
        key,
        ...value
      };
      data_array.push(obj);
    });

    return data_array;
  }

  function setUserEngagementScoreToTable(user, userEngagement) {
    const user_id = user.course_user;
    return <TableCell>{userEngagement[user_id]}</TableCell>;
  }

  const handleChange = user_id => {
    if (!selectedUsersIds.includes(user_id))
      setSelectedUsersIds(selectedUsersIds => [...selectedUsersIds, user_id]);
    else {
      setSelectedUsersIds(selectedUsersIds => [
        ...selectedUsersIds.filter(id => id !== user_id)
      ]);
    }
  };

  const handleActionsChange = action => {
    if (!actionsForAnalysis.includes(action)) {
      setActionsForAnalysis(actionsForAnalysis => [
        ...actionsForAnalysis,
        action
      ]);
    } else {
      setActionsForAnalysis(actionsForAnalysis => [
        ...actionsForAnalysis.filter(
          actionAnalysis => actionAnalysis !== action
        )
      ]);
    }
  };
  // If user clicks outside, close the students selection menu
  useOnClickOutside(selectionRef, () => {
    if (showUsersList) setShowUsersList(!showUsersList);
    if (anchorEl) setAnchorEl(false);
  });

  function setCourseActionsForAnalysisList() {
    return Object.entries(COURSE_ACTIONS_FOR_ANALYSES).map(action => {
      return (
        <MenuItem
          className={clsx(classes.menuItem, classes.left)}
          onClick={() => {
            handleActionsChange(action[0]);
          }}
          key={action[0]}
        >
          <Checkbox checked={actionsForAnalysis.includes(action[0])} />

          <Typography variant="inherit" id="highlightModeLabel">
            <FormattedMessage
              id={`chart.${action[1]}`}
              defaultMessage={`${action[1]}`}
            />
          </Typography>
        </MenuItem>
      );
    });
  }
  return (
    <ScrollBox>
      <Box className={classes.container}>
        <Grid
          component={"section"}
          className={classes.header}
          container
          spacing={3}
          alignItems={"flex-start"}
        >
          <Grid item xs={2}>
            <DatePicker
              className={classes.datePicker}
              disableToolbar
              variant="inline"
              inputFormat="MM/dd/yyyy"
              margin="normal"
              id="date-picker-inline"
              label="Start date"
              value={start}
              onChange={e => setStart(e)}
              renderInput={params => (
                <TextField variant="standard" {...params} />
              )}
            />
          </Grid>
          <Grid item xs={2}>
            <DatePicker
              className={classes.datePicker}
              disableToolbar
              variant="inline"
              inputFormat="MM/dd/yyyy"
              margin="normal"
              id="date-picker-inline"
              label="End date"
              value={end}
              onChange={e => setEnd(e)}
              renderInput={params => (
                <TextField variant="standard" {...params} />
              )}
            />
          </Grid>
        </Grid>

        <TableContainer component="section">
          <Typography variant="h6">Users</Typography>
          <Table className={classes.table} aria-label="users table">
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>Active users</TableCell>
                <TableCell>Task submissions</TableCell>
                <TableCell>Role</TableCell>
                <TableCell>Engagement</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {submissions.map(submission => {
                let user = users[submission.key];
                return (
                  <TableRow key={user.course_user}>
                    <TableCell padding="checkbox" component="th" scope="row">
                      <Checkbox
                        checked={user.selected}
                        onChange={() =>
                          dispatchUsers({
                            type: "toggle",
                            payload: submission.key
                          })
                        }
                        // inputProps={{ "aria-labelledby": labelId }}
                      />
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <Link
                        component="button"
                        onClick={() => {
                          impersonate(user.course_user);
                        }}
                        underline="hover"
                      >
                        {user.course_user}
                      </Link>
                    </TableCell>
                    <TableCell className={classes.submissionChartContainer}>
                      <UserSubmissionBarChart
                        users={selectedUsers}
                        data={[submission]}
                      />
                    </TableCell>
                    <TableCell>{user.course_role}</TableCell>
                    {setUserEngagementScoreToTable(user, userEngagement)}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>

        <Grid
          component={"section"}
          container
          spacing={10}
          alignItems={"flex-start"}
        >
          <Grid className={classes.engagementChart} item xs={12}>
            <Box
              style={{
                display: "flex",
                flexFlow: "row",
                justifyContent: "space-between"
              }}
            >
              <Typography variant="h6">Class Engagement</Typography>
              <IconButton
                ref={ref}
                onClick={e => {
                  setAnchorEl(ref.current);
                  e.stopPropagation();
                }}
                size="large"
              >
                <MoreVertIcon />
              </IconButton>
              <MuiMenu
                ref={selectionRef}
                direction={rtl ? "rtl" : "auto"}
                onClick={e => {
                  e.stopPropagation();
                }}
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                keepMounted
                classes={{ list: rtl ? classes.menuRtl : classes.menuLtr }}
                onClose={() => {
                  setAnchorEl(null);
                }}
                disableScrollLock={false}
              >
                {!showUsersList && (
                  <Box>
                    <MenuItem
                      className={clsx(classes.menuItem, classes.left)}
                      onClick={() => {
                        setShowUsersList(!showUsersList);
                      }}
                    >
                      <Checkbox checked={selectedUsersIds.length > 0} />

                      <Typography variant="inherit" id="highlightModeLabel">
                        <FormattedMessage
                          id="chart.add-user-graph"
                          defaultMessage="Add user graph"
                        />
                      </Typography>
                    </MenuItem>
                    <Divider />
                    {setCourseActionsForAnalysisList()}
                  </Box>
                )}
                {showUsersList &&
                  allUserIds.map(user_id => (
                    <MenuItem
                      key={user_id}
                      value={user_id}
                      onClick={() => handleChange(user_id)}
                    >
                      <Checkbox
                        checked={selectedUsersIds.indexOf(user_id) > -1}
                      />
                      <ListItemText primary={user_id} />
                    </MenuItem>
                  ))}
              </MuiMenu>
            </Box>
            <EngagementChart
              data={courseEngagement}
              start={start}
              end={end}
              courseTasks={courseTasks}
              usersBucketActivity={usersBucketActivity}
              allUserIds={allUserIds}
              actionsForAnalysis={
                actionsForAnalysis.length && actionsForAnalysis
              }
            />
          </Grid>
          <Grid item xs={6}>
            <Typography paragraph={true}>Created questions</Typography>
            <CreatedGrInteractionsBarChart
              users={selectedUsers}
              data={data.GUIDED_READING_INTERACTIONS?.data}
            />
          </Grid>
          <Grid item xs={6}>
            <Typography paragraph={true}>Created citations</Typography>
            <CreatedGrInteractionsBarChart
              users={selectedUsers}
              data={data.QUOTE_INTERACTIONS?.data}
            />
          </Grid>
        </Grid>
      </Box>
    </ScrollBox>
  );
}
