import React, { useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import clsx from "clsx";
import { Typography, Link } from "@mui/material";
import CitationDialog from "./CitationDialog";
import Concepts from "./Concepts";
import TYPES from "../../../types";

const useStyles = makeStyles(theme => ({
  guidedReadingQuestionWrapper: {
    paddingInline: theme.spacing(3),
    paddingBottom: 16,
    paddingTop: theme.spacing(2)
  }
}));

export default function GuidedReadingQuestion({
  url,
  location,
  highlights,
  readOnly = false,
  addHighlight,
  removeHighlight,
  onConcepts,
  concepts
}) {
  const classes = useStyles();
  const [openDialog, setOpenDialog] = useState(false);
  return (
    <>
      <div className={classes.guidedReadingQuestionWrapper}>
        <Typography component="span">
          <Link
            component="button"
            variant="body2"
            className={clsx(classes.fakeLink)}
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              setOpenDialog(true);
            }}
            underline="hover"
          >
            <FormattedMessage
              id="task.selectCitation"
              defaultMessage=" Select citation"
            />
          </Link>
        </Typography>
        <Concepts
          readOnly={readOnly}
          onChange={onConcepts}
          concepts={concepts}
        />
      </div>
      <CitationDialog
        setOpenDialog={setOpenDialog}
        url={url}
        citationTitleFormattedMessage={
          <FormattedMessage id="text.mark" defaultMessage="Mark in Text" />
        }
        location={location}
        highlights={highlights}
        openDialog={openDialog}
        addHighlight={!readOnly && addHighlight}
        removeHighlight={!readOnly && removeHighlight}
      />
    </>
  );
}

GuidedReadingQuestion.propTypes = {
  url: PropTypes.string.isRequired,
  location: PropTypes.string.isRequired,
  highlights: PropTypes.arrayOf(
    PropTypes.shape({
      cfi: PropTypes.string.isRequired,
      color: PropTypes.string.isRequired,
      source: PropTypes.string.isRequired,
      content: PropTypes.string.isRequired
    })
  ),
  readOnly: PropTypes.bool,
  addHighlight: PropTypes.func.isRequired,
  removeHighlight: PropTypes.func.isRequired,
  onConcepts: PropTypes.func.isRequired,
  concepts: TYPES.CONCEPTS
};
