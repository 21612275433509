// Dependencies
import {
  isFeatureFlagEnabled,
  mapStateToFetchProps,
  fetchMentor,
  orchestrateMentorResponse
} from "./utils";
import { captureException } from "../../../utils/errorHandlers";
import { FEATURE_FLAGS } from "../../../consts";

// Redux
import { initChat, initMentorReview, setIsLoading } from "../../chatSlice";
import store from "../../store";

// rxJs
import { ofType } from "redux-observable";
import { EMPTY } from "rxjs";
import {
  tap,
  map,
  filter,
  withLatestFrom,
  catchError,
  switchMap,
  concatMap
} from "rxjs/operators";

export const initMentorReviewEpic = (action$, state$) => {
  return action$.pipe(
    ofType(initMentorReview.type),
    filter(() =>
      isFeatureFlagEnabled(state$.value, FEATURE_FLAGS.E_MENTOR_REVIEW)
    ),
    tap(() => store.dispatch(setIsLoading(true))),
    withLatestFrom(state$),
    map(([action, state]) => mapStateToFetchProps(action, state)),
    switchMap(props => fetchMentor(props)),
    withLatestFrom(state$),
    concatMap(([{ mentorResponse, sessionId }, state]) => {
      return orchestrateMentorResponse(mentorResponse, sessionId, state);
    }),
    map(payload => ({ type: initChat.type, payload })),
    catchError(error => {
      captureException(error, "Error in initMentorReviewEpic");
      return EMPTY;
    })
  );
};
