// Dependencies
import React, { useEffect, useState, useCallback } from "react";

// Redux
import { useSelector } from "react-redux";

// Componenets
import { firebaseFunctions, firestore } from "../../firebase";

// Material UI
import makeStyles from "@mui/styles/makeStyles";
import { Box, FormControlLabel, Switch } from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";
import { FEATURES } from "../../consts";
import { enqueueFlashMessage } from "../../redux/userSlice";

const useStyles = makeStyles(theme => ({
  container: {
    width: "100w",
    height: "100vh",
    display: "inline-block"
  }
}));

// Firebase functions
const getAllUsers = firebaseFunctions.httpsCallable(
  "adminFunctions-getAllCourseUsers"
);

function CourseFeatures({ courseId }) {
  // Hooks
  const classes = useStyles();
  const intl = useIntl();

  // Redux state
  const defaultFeatureFlags = useSelector(state => {
    const defaultConfig = state.firestore.data.defaultConfig;
    return defaultConfig?.CROSS_FEATURE?.FEATURE_FLAGS;
  });

  const alertsDuration = useSelector(
    state => state.user.userProfile.alertsDuration
  );
  // Ephemeral state
  const [users, setUsers] = useState([]);
  const [checked, setChecked] = useState({});

  // Variables

  //Behaviour

  const setCheckedButtonsByTeacherConfig = useCallback(featureFlags => {
    setChecked(featureFlags);
  }, []);

  const fetchTeacherCustomConfig = useCallback(
    async users => {
      if (!users || users.length === 0) {
        // Empty or undefined users array
        return;
      }

      // Find the user with role "TEACHER"
      const teacher = users.find(
        user => user.role?.toUpperCase() === "TEACHER"
      );

      if (!teacher) {
        // No teacher found
        return;
      }

      try {
        // Fetch the teacher's customConfig from Firestore
        const snapshot = await firestore
          .collection("userProfiles")
          .doc("customConfig")
          .collection(teacher.course_user)
          .doc("CROSS_FEATURE")
          .get();

        if (snapshot.exists) {
          // Access the customConfig data
          const customConfig = snapshot.data();
          // Do something with the customConfig
          const featureFlags = customConfig.FEATURE_FLAGS;
          setCheckedButtonsByTeacherConfig(featureFlags);
        }
      } catch (error) {
        // Handle any errors that occurred during the fetch
        enqueueFlashMessage({
          message: intl.formatMessage({
            id: "fetchTeachConfig.error",
            defaultMessage: `Error fetching teacher's customConfig:
          ${error}`
          }),
          duration: alertsDuration
        });
      }
    },
    [alertsDuration, intl, setCheckedButtonsByTeacherConfig]
  );

  useEffect(() => {
    getAllUsers({ course: courseId }).then(response => {
      setUsers(response.data);
      fetchTeacherCustomConfig(response.data);
    });
  }, [courseId, fetchTeacherCustomConfig]);

  const updateFeatureFlagsForCourseUsers = async (users, key, checked) => {
    try {
      const docSets = users.map(async user => {
        const docRef = firestore
          .collection("userProfiles")
          .doc("customConfig")
          .collection(user.course_user)
          .doc(FEATURES.CROSS_FEATURE);

        let baseObj = {
          FEATURE_FLAGS: {
            [key]: !checked[key]
          }
        };
        return docRef.set(baseObj, { merge: true });
      });

      await Promise.all(docSets);
    } catch (error) {
      console.error("Error updating feature flags:", error);
    }
  };

  function renderButtons() {
    return Object.keys(defaultFeatureFlags).map(key => {
      return (
        <FormControlLabel
          key={key}
          control={
            <Switch
              color="primary"
              checked={checked[key] || false} // Ensure a default value is set
              onChange={async () => {
                setChecked(prevState => ({
                  ...prevState,
                  [key]: !prevState[key]
                }));
                await updateFeatureFlagsForCourseUsers(users, key, checked);
              }}
            />
          }
          label={
            <FormattedMessage
              id="admin.activateFeature"
              defaultMessage={checked[key] ? `Disable ${key}` : `Enable ${key}`}
            />
          }
        />
      );
    });
  }

  return (
    <Box className={classes.container}>
      {users.length > 0 && renderButtons()}
    </Box>
  );
}

export default CourseFeatures;
