import { createTheme } from "@mui/material/styles";
import { BREAKPOINTS } from "./consts";
const paletteLight = {
  primary: {
    main: "#1565C0",
    dark: "#003C8F",
    light: "#168FEE",
    contrast: "#FFFFFF"
  },

  // secondary: { main: "#5ec891" },
  secondary: {
    main: "#2E7D32",
    light: "#5E92F3",
    dark: "#1B5E20",
    contrast: "#FFFFFF",
    p12: "rgba(46, 125, 50, 0.12)"
  },
  warning: {
    main: "#ED6C02",
    dark: "#E65100",
    contrast: "#FFFFFF"
  },
  error: {
    main: "#D32F2F",
    dark: "#C62828",
    contrast: "#FFFFFF"
  },
  background: {
    default: "#FAFAFA",
    dark: "#121212",
    hover: "#c9c9c9",
    paper: "#FFFFFF",
    sidePanel: "#252525",
    readerBar: "#1B5E20",
    blue: "#096BDE"
  },
  greenText: { main: "#226341" },
  card: {
    background: {
      main: "#ffffff",
      hover: "#f5f5f5",
      disabled: "#EEEEEE"
    }
  },
  text: {
    primary: "rgba(0, 0, 0, 0.87)",
    blue: "#168FEE",
    green: "#467fcf",
    secondary: "rgba(0, 0, 0, 0.6)",
    p4: "rgba(0, 0, 0, 0.04)",
    p12: "rgba(0, 0, 0, 0.12)",
    p30: "rgba(0, 0, 0, 0.30)"
  },
  blue: {
    main: "#168fee"
  },
  lightText: {
    main: "#FFFFFF",
    muted: "#0000008A"
  },
  grey: {
    main: "#c9c9c9"
  }
};

const PaletteDark = {
  primary: {
    main: "#168FEE",
    light: "#90CAF9",
    dark: "#42A5F5",
    contrast: "#000000"
  },
  secondary: { main: "#5ec891", light: "#81C784" },
  warning: { main: "#FFA726", contrast: "#000000" },

  background: {
    default: "#121212",
    sidePanel: "#1e1e1e",
    readerBar: "#66BB69"
  },
  card: {
    background: {
      main: "#424242",
      hover: "#303030",
      disabled: "#212121"
    }
  },
  text: {
    primary: "#FFFFFF",
    blue: "#BCD9F1",
    green: "#467fcf",
    disabled: "#EEEEEE",
    secondary: "#000000 60%"
  },

  blue: {
    main: "#168fee"
  },
  lightText: {
    main: "#000000",
    muted: "#FFFFFF8F"
  }
};

const Typography = {
  fontSize: 14,
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontFamily: "Chivo",
  small: {
    fontSize: 12
  },
  caption: {
    fontSize: 12,
    lineHeight: "20px"
  },
  subtitle2: {
    fontSize: 14,
    lineHeight: "22px",
    letterSpacing: "0.1px"
  },
  body1: {
    fontSize: 16,
    lineHeight: "24px",
    letterSpacing: "0.15px"
  },
  body2: {
    fontSize: 14,
    fontWeight: 400,
    lineHeight: "20px",
    letterSpacing: "0.2px"
  },
  h2: {
    fontSize: 60,
    lineHeight: "72px",
    letterSpacing: "-0.5px"
  },
  h4: {
    fontSize: 34,
    lineHeight: "42px",
    letterSpacing: "0.25px"
  },
  h6: {
    fontSize: 20,
    lineHeight: "32px",
    letterSpacing: "0.15px"
  }
};

const BaseStyles = {
  MuiButton: {
    root: {
      textTransform: "none",
      fontFamily: "Chivo",
      fontSize: 14,
      lineHeight: "24px",
      fontWight: 400
    }
  },
  MuiStepper: {
    root: {
      backgroundColor: "transparent"
    }
  },
  MuiInputBase: {
    input: {
      fontFamily: "Chivo",
      "&[type=number]": {
        MozAppearance: "textfield"
      },
      "&::-webkit-outer-spin-button": {
        WebkitAppearance: "none",
        margin: 0
      },
      "&::-webkit-inner-spin-button": {
        WebkitAppearance: "none",
        margin: 0
      }
    }
  },
  MuiInput: {
    input: {
      "&::placeholder": {
        color: "white"
      }
    }
  },
  MuiStepLABEL: {
    fontSize: "24px"
  }
};

const Breakpoints = {
  values: {
    desktop: BREAKPOINTS.DESKTOP,
    xs: 0,
    sm: 600,
    md: 960,
    lg: 1280,
    xl: 1920
  }
};

const globalTheme = {
  components: {
    ...BaseStyles,
    MuiFab: {
      styleOverrides: {
        primary: {
          backgroundColor: paletteLight.primary.dark
        }
      }
    },
    MuiPickersToolbar: {
      toolbar: {}
    },
    MuiPickersCalendarHeader: {
      switchHeader: {}
    },
    MuiDataGridMenu: {
      root: {
        "& .MuiPaper-root": {}
      }
    },
    "MuiPickersBasePicker-container": {},
    "MuiDataGridMenu-root": {},
    MuiPickersDay: {
      day: {
        color: "black"
      },
      daySelected: {
        backgroundColor: "#33abb6"
      },
      dayDisabled: {
        color: "#ccc"
      },
      current: {
        color: "red"
      }
    },
    MuiPickersModal: {
      dialogAction: {
        color: "#33abb6"
      }
    },
    MuiSwitch: {
      styleOverrides: {
        colorSecondary: {
          "&.Mui-checked": {
            color: "#90CAF9"
          }
        },
        track: {
          backgroundColor: "#cfcfcf"
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          ...BaseStyles.MuiButton.root
        },
        outlinedSecondary: {
          color: "black"
        }
      }
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: "#333"
        }
      }
    }
  },
  palette: { ...paletteLight },
  typography: {
    ...Typography
  },
  breakpoints: Breakpoints
};

export function getGlobalTheme() {
  return createTheme(globalTheme);
}

const hebrewTheme = {
  isRtl: true,
  direction: "rtl",
  components: {
    ...BaseStyles,
    MuiPopover: {
      styleOverrides: {
        root: {
          direction: "rtl"
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          ...BaseStyles.MuiButton.root
        },
        outlinedSecondary: {
          color: "black"
        }
      }
    },
    MuiListItemIcon: {
      root: {
        color: "#333"
      }
    }
  },
  palette: { ...paletteLight },
  typography: {
    ...Typography,
    fontFamily: '"Open Sans Hebrew", "Rosario", "Helvetica", "Arial"'
  },
  breakpoints: Breakpoints
};

export function getHebrewTheme() {
  return createTheme(hebrewTheme);
}

const globalDarkTheme = {
  components: {
    ...BaseStyles,
    MuiFab: {
      styleOverrides: {
        primary: {
          backgroundColor: PaletteDark.primary.light
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          ...BaseStyles.MuiButton.root
        },
        outlinedPrimary: {
          color: "#90CAF9",
          borderColor: "#90CAF9"
        },
        containedPrimary: {
          color: "black",
          backgroundColor: "#90CAF9",
          "&:hover": {
            backgroundColor: "#4eaaf5"
          }
        }
      }
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: "white"
        }
      }
    }
  },
  palette: {
    mode: "dark",
    ...PaletteDark
  },
  typography: {
    ...Typography
  },
  breakpoints: Breakpoints
};

export function getGlobalDarkTheme() {
  return createTheme(globalDarkTheme);
}

const hebrewDarkTheme = {
  isRtl: true,
  direction: "rtl",
  components: {
    ...BaseStyles,
    MuiPopover: {
      root: {
        direction: "rtl"
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          ...BaseStyles.MuiButton.root
        },
        outlinedSecondary: {
          color: "white"
        },
        outlinedPrimary: {
          color: "white"
        }
      }
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: "white"
        }
      }
    }
  },
  palette: {
    mode: "dark",
    ...PaletteDark
  },
  typography: {
    ...Typography,
    fontFamily: '"Open Sans Hebrew", "Rosario", "Helvetica", "Arial"'
  },
  breakpoints: Breakpoints
};
export function getHebrewDarkTheme() {
  return createTheme(hebrewDarkTheme);
}

// DatePicker Theme

export const datePickerLightTheme = createTheme({});
