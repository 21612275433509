import React, { useLayoutEffect, useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Box, Typography, Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import grAPI from "../../api/gr";
import { useQuery } from "../../hooks";
import { selectSubmission, selectTask } from "../../redux/tasksSlice";
import { TASK } from "../../consts";
import { updateTask } from "../../redux/firebaseMiddleware";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
  container: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
  },
  Inner: {
    width: 500,
    textAlign: "center",
    padding: theme.spacing(2)
  },
  title: { marginBlock: theme.spacing(3) },
  paragraph: { marginBottom: theme.spacing(6) },
  btn: {
    color: theme.palette.secondary.main
  },
  taskBtn: {
    color: theme.palette.primary.main
  }
}));

function EmptyState() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { text_id, submission_id } = useQuery();

  const stage = useSelector(state => state.gr.stage);
  const grMode = useSelector(state => state.gr.mode);

  const [image, setImage] = useState();
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [buttonText, setButtonText] = useState("");
  const [callback, setCallback] = useState();

  // Redux state
  const submission = useSelector(state =>
    selectSubmission(state, Number(submission_id))
  );
  const task = useSelector(state => selectTask(state, submission.task_id));
  const selectedQuestionIndex = useSelector(
    state => state.task.selectedQuestionIndex
  );
  // Derived state
  const graphicsUrl = `${process.env.PUBLIC_URL}/emptyStateGraphics`;
  const standardTask = task.task_type === TASK.TYPE.STANDARD;
  // Behavior
  useLayoutEffect(() => {
    switch (true) {
      case grMode === "light" && stage === 1:
      case grMode === "full" && stage === 3:
      case standardTask:
        setImage("homework");
        setTitle(
          standardTask
            ? "Create highlights"
            : "Focus your reading with highlights"
        );
        setContent(
          standardTask
            ? "Create highlights to your instructor’s questions in step 1 to be able to review them here"
            : "Your highlights are saved and can be used as an index of the most important parts of the text."
        );
        setButtonText(standardTask ? "Back to step 1" : "Back to text view");
        setCallback(() => () => {
          if (standardTask) {
            dispatch(
              updateTask({
                step: "highlight",
                selectedQuestion: selectedQuestionIndex
              })
            );
          } else {
            dispatch(grAPI.updateGrState({ grShowAnswers: false }));
          }
        });
        break;
      case grMode === "light" && stage === 0:
      case grMode === "full" && stage === 1:
        setImage("reading");
        setTitle("Use highlights for a better survey");
        setContent(
          "Highlights let you go over the most important parts of the text and efficiently. Add highlights to see them here"
        );
        setButtonText("");
        setCallback(() => () => {});
        break;
      case grMode === "light" && stage === 2:
      case grMode === "full" && stage === 4:
        setImage("student");
        setTitle("Test yourself For better results");
        setContent(
          "Ask questions and find answers in previous steps to be able to test yourself and gain better control and long-term retention of the material"
        );
        setButtonText("");
        setCallback(() => () => {});
        break;
      default:
        break;
    }
  }, [dispatch, grMode, stage]);

  return (
    <Box className={classes.container}>
      <Box className={classes.Inner}>
        <picture>
          <source type="image/svg+xml" srcSet={`${graphicsUrl}/${image}.svg`} />
          <img src={`${graphicsUrl}/${image}.png`} alt="empty state" />
        </picture>

        <Typography variant="h5" className={classes.title}>
          {title}
        </Typography>
        <Typography className={classes.paragraph}>{content}</Typography>
        {buttonText && (
          <Button
            className={clsx(classes.btn, standardTask && classes.taskBtn)}
            variant="outlined"
            color={standardTask ? "primary" : "secondary"}
            onClick={callback}
          >
            {buttonText}
          </Button>
        )}
      </Box>
    </Box>
  );
}

export default EmptyState;
