// dependancies
import { v4 as uuid } from "uuid";
import { httpCallables } from "../../../firebase";
import {
  interactionUpdatedSuccessfully,
  updateInteraction
} from "../../interactionsSlice";
import { captureException } from "../../../utils/errorHandlers";
// Redux

// Rx
import { ofType } from "redux-observable";
import { of, EMPTY } from "rxjs";
import { filter, map, exhaustMap, catchError } from "rxjs/operators";
import store from "../../store";
import { addSnackbarItem } from "../../snackbarSlice";

const CALLBACK = httpCallables.interactionFunctions;
const ACTION = "updateInteraction";
const ERROR_MSG = `UPDATE_INTERACTION`;

export const updateInteractionEpic = (action$, state$, { fetchAsObservable }) =>
  action$.pipe(
    ofType(updateInteraction.type),
    filter(({ payload }) => !payload.onlyUi),
    exhaustMap(
      ({ payload: { interaction, update, disableRollback, errorMessage } }) => {
        const args = { ...update, id: interaction.id };
        return fetchAsObservable(CALLBACK, ACTION, ERROR_MSG, args).pipe(
          map(() => ({
            type: interactionUpdatedSuccessfully.type,
            payload: { interaction }
          })),
          catchError(error => {
            alertUser(errorMessage);
            captureException(error, "Error in updateInteractionEpic");
            if (!disableRollback) {
              return of({
                type: updateInteraction.type,
                payload: { interaction, onlyUi: true }
              });
            }
            return EMPTY;
          })
        );
      }
    )
  );

function alertUser(errorMessage = null) {
  const defaultErrorMessage = "error.defaultSavingError";

  store.dispatch({
    type: addSnackbarItem.type,
    payload: {
      intlId: errorMessage || defaultErrorMessage,
      id: uuid()
    }
  });
}
