// dependancies
import { httpCallables } from "../../../firebase";

// Redux

// Rx
import { ofType } from "redux-observable";
import { EMPTY } from "rxjs";
import {
  tap,
  map,
  catchError,
  switchMap,
  withLatestFrom
} from "rxjs/operators";
import {
  fetchStudentCourseTasks,
  setTaskAndSubmissions,
  setStatus,
  STATUS
} from "../../tasksSlice";
import { captureException } from "../../../utils/errorHandlers";
import store from "../../store";

const CALLBACK = httpCallables.taskFunctions;
const ACTION = "readStudentCourseTasks";
const ERROR_MSG = `FETCH_STUDENT_COURSE_TASKS`;

export const fetchStudentCourseTasksEpic = (
  action$,
  state$,
  { fetchAsObservable }
) => {
  return action$.pipe(
    ofType(fetchStudentCourseTasks.type),
    tap(() => showLoader()), // temp solution, until we get rid of loading state
    withLatestFrom(state$),
    map(([action, state]) => ({
      course_id: action.payload,
      student_id: state.firebase.auth.uid
    })),
    switchMap(args =>
      fetchAsObservable(CALLBACK, ACTION, ERROR_MSG, args).pipe(
        tap(() => hideLoader()),
        map(payload => ({
          type: setTaskAndSubmissions.type,
          payload: payload
        })),

        catchError(error => {
          hideLoader(); // TODO: handle error
          captureException(error, "Error in submitTaskEpic");
          return EMPTY;
        })
      )
    )
  );
};

function showLoader() {
  store.dispatch(setStatus({ status: STATUS.PENDING }));
}

function hideLoader() {
  store.dispatch(setStatus({ status: STATUS.IDLE }));
}
