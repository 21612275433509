import { ofType } from "redux-observable";
import { from, EMPTY } from "rxjs";
import {
  map,
  filter,
  withLatestFrom,
  concatMap,
  pairwise,
  catchError
} from "rxjs/operators";
import { uniq } from "lodash";
import { captureException } from "../../../utils/errorHandlers";

export const queuedTasksEpic = (action$, state$) => {
  const DOCUMENT_MODIFIED = "@@reduxFirestore/DOCUMENT_MODIFIED";
  const DOCUMENT_ADDED = "@@reduxFirestore/DOCUMENT_ADDED";
  const LISTENER_RESPONSE = "@@reduxFirestore/LISTENER_RESPONSE";

  return action$.pipe(
    ofType(DOCUMENT_MODIFIED, DOCUMENT_ADDED, LISTENER_RESPONSE),
    filter(action => action.meta.collection === "queuedTasks"),
    withLatestFrom(state$),
    map(([_, state]) => state.firestore.data?.queuedTasks),
    pairwise(),
    map(([prev, current]) => getDelta(prev, current)),
    concatMap(tasks => from(tasks)),
    map(task => handleStatusChange(task, config)),
    catchError(error => {
      captureException(error, "Error in queuedTasksEpic");
      return EMPTY;
    })
  );
};

// Utils

function handleStatusChange(task, config) {
  // takes a task objects and a configuration
  // returns an action based on the task status and callbacks
  const callbacks = config[task.type];
  if (task.status === "SUCCESS") {
    return { type: callbacks.onSuccess, payload: task.payload };
  } else if (task.status === "ERROR") {
    return { type: callbacks.onError, payload: task.payload };
  }
}

function getDelta(prevTasks, currentTasks) {
  // takes the prev and current offline tasks objects
  // returns and array of tasks objects  based on the differance in status attr

  if (!prevTasks) return []; // there is no delta

  const tasksIds = uniq([
    ...Object.keys(prevTasks),
    ...Object.keys(currentTasks)
  ]);
  const delta = tasksIds.reduce((accumulator, current) => {
    const prevTaskStatus = prevTasks[current]
      ? prevTasks[current]["status"]
      : null;
    const currentTaskStatus = currentTasks[current]["status"];

    if (prevTaskStatus !== currentTaskStatus) {
      accumulator.push({ id: current, ...currentTasks[current] });
    }

    return accumulator;
  }, []);

  return delta;
}

// config
const config = {
  // Example:
  // submitTask: {
  //   onSuccess: "tasks/taskSubmittedSuccessfully",
  //   onError: "tasks/taskSubmissionError"
  // }
};
