// Dependencies
import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import clsx from "clsx";

// Redux dependencies
import { useSelector } from "react-redux";
import { selectCurrentText } from "../../../redux/textsSlice";
// Components
import CitationDialog from "./CitationDialog";
import EditChoice from "./EditChoice";

import makeStyles from "@mui/styles/makeStyles";
import {
  Box,
  Link,
  TextField,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
  Checkbox
} from "@mui/material";

//Styles
const useStyles = makeStyles(theme => ({
  input: {
    width: "100%",
    "& .MuiFormControlLabel-label": {
      width: "100%"
    }
  },
  editBtn: {
    color: theme.palette.grey["400"],
    "&:hover": {
      color: theme.palette.secondary.main
    }
  },
  blue: {
    "&$checked": {
      color: "#168fee"
    },
    "&.Mui-checked": {
      color: "#168fee"
    }
  },
  includeCitation: {
    display: "inline-block",
    width: "75%",
    color: theme.palette.text.disabled
  },
  fakeLink: {
    fontSize: "1rem",
    color: "#168fee",
    "&:hover": {
      textDecoration: "none"
    }
  },
  txtInput: {
    width: "100%",
    "& ::placeholder": {
      color: "#168fee"
    }
  },
  paddedContent: {
    paddingTop: "16px",
    paddingbottom: "16px",
    paddingLeft: "24px",
    paddingRight: "24px"
  },
  checkboxChecked: {
    color: theme.palette.text.primary
  },
  spacing: {
    paddingInline: theme.spacing(3),
    paddingBlock: theme.spacing(2)
  },
  endPadding: {
    paddingBlockEnd: 44
  }
}));

function CreateMultiQuestion({
  options,
  onUpdate,
  shouldSelect,
  readOnly,
  includeCitation,
  setIncludeCitation,
  highlights,
  addHighlight,
  removeHighlight
}) {
  //Hooks
  const classes = useStyles();
  const intl = useIntl();
  const choicesRef = React.useRef();
  const newInputRef = React.useRef();

  // Redux state
  const text = useSelector(selectCurrentText);

  //Ephemeral State
  const [editingMode, setEditingMode] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);

  //Behavior
  const changeAnswer = (index, e) => {
    let updatedOptions = options;

    updatedOptions[index] = e.target.value;
    onUpdate(updatedOptions, shouldSelect);
  };

  const deleteItem = index => {
    let updatedOptions = [...options];
    updatedOptions.splice(index, 1);
    setEditingMode(false);
    let newShouldSelect = -1;
    if (shouldSelect > index) {
      newShouldSelect = shouldSelect - 1;
    }
    if (shouldSelect < index) {
      newShouldSelect = shouldSelect;
    }
    onUpdate([...updatedOptions], newShouldSelect);
  };

  const possiblyDeleteAnswer = (e, index) => {
    if (e.key === "Enter") {
      setEditingMode(false);
      if (e.target.value === "") {
        let updatedOptions = [...options];
        updatedOptions.splice(index, 1);
        onUpdate(
          updatedOptions,
          shouldSelect > index ? shouldSelect - 1 : shouldSelect
        );
      } else {
        if (editingMode === options.length - 1) {
          if (newInputRef && newInputRef.current) {
            newInputRef.current.focus();
          }
        }
      }
    }
  };

  const renderNewOption = () => {
    if (readOnly) return null;
    else
      return (
        <FormControlLabel
          key={options.length + 1}
          id="addNewOption"
          className={classes.input}
          control={<Radio className={classes.blue} checked={false} />}
          label={
            <TextField
              variant="standard"
              id="addOption"
              className={classes.txtInput}
              value={""}
              inputRef={newInputRef}
              multiline={true}
              text={""}
              name="multi-choise-option"
              aria-label="multi-choice-input"
              placeholder={intl.formatMessage({
                id: "task.addOption",
                defaultMessage: "Add new option"
              })}
              onChange={e => {
                onUpdate([...options, e.target.value], shouldSelect);
                setEditingMode(options.length);
                e.preventDefault();
              }}
            />
          }
        />
      );
  };

  //Render
  return (
    <Box ref={choicesRef}>
      <Box
        className={clsx(
          classes.paddedContent,
          classes.includeCitationContainer
        )}
      >
        <Box className={classes.includeCitation}>
          <Typography component="span">
            <FormControlLabel
              control={
                <Checkbox
                  readOnly={readOnly}
                  checked={includeCitation}
                  name="include-citation-checkbox"
                  onChange={() => {
                    setIncludeCitation(!includeCitation);
                  }}
                  color="primary"
                />
              }
              label={
                <Typography
                  className={clsx(includeCitation && classes.checkboxChecked)}
                >
                  {intl.formatMessage({
                    id: "task.includeCitation",
                    defaultMessage: "Include citation in answer"
                  })}
                </Typography>
              }
            />
          </Typography>
          {includeCitation && (
            <Typography component="span">
              <Link
                component="button"
                variant="body2"
                className={clsx(classes.fakeLink)}
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                  setOpenDialog(true);
                }}
                underline="hover"
              >
                <FormattedMessage
                  id="task.selectCitation"
                  defaultMessage=" Select citation"
                />
              </Link>
            </Typography>
          )}
        </Box>
      </Box>
      <Box className={clsx(classes.spacing, classes.endPadding)}>
        <Typography className={classes.expandMsg} variant="body1">
          <FormattedMessage
            defaultMessage="Type answer options and mark the correct answer"
            id="task.create.multiple.instructions"
          />
        </Typography>
        <RadioGroup name="options" value={shouldSelect}>
          {options.map((item, index) => {
            return (
              <EditChoice
                readOnly={readOnly}
                editingmode={editingMode}
                key={index} //using just index as key
                //won't refresh items when  deleting options in the middle causes problems
                deleteItem={deleteItem}
                setEditingMode={index => {
                  setEditingMode(index);
                }}
                onUpdateChoice={index => {
                  onUpdate(options, index);
                }}
                changeAnswer={e => {
                  changeAnswer(index, e);
                }}
                possiblyDeleteAnswer={possiblyDeleteAnswer}
                shouldSelect={shouldSelect}
                item={item}
                index={index}
              />
            );
          })}

          {renderNewOption()}
        </RadioGroup>
        <CitationDialog
          setOpenDialog={setOpenDialog}
          citationTitleFormattedMessage={
            <FormattedMessage id="text.mark" defaultMessage="Mark in Text" />
          }
          url={text.url}
          location={text.file_location}
          highlights={highlights}
          openDialog={openDialog}
          addHighlight={!readOnly && addHighlight}
          removeHighlight={!readOnly && removeHighlight}
        />
      </Box>
    </Box>
  );
}

export default CreateMultiQuestion;
