import { createSlice, createSelector, current } from "@reduxjs/toolkit";

const initialState = {
  startPosition: null,
  endPosition: null,
  readingSession: []
};

export const readerSlice = createSlice({
  name: "reader",
  initialState,
  extraReducers: {},
  reducers: {
    updateTextPosition: (state, value) => {
      const { startPosition, endPosition } = value.payload;
      state.startPosition = startPosition;
      state.endPosition = endPosition;
    },
    addToReadingSession: (state, value) => {
      state.readingSession.push(value.payload);
    }
  }
});

export const { updateTextPosition, addToReadingSession } = readerSlice.actions;

// Selectors
// Utils

export default readerSlice.reducer;
