import React from "react";
import { firebaseFunctions } from "../../firebase";
import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";
import {
  Button,
  Dialog,
  DialogTitle,
  TextField,
  DialogActions,
  DialogContent,
  Box,
  Grid
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { useIntl } from "react-intl";

const useStyles = makeStyles(theme => ({
  startDate: {
    "& .MuiPickersDay-current": {
      color: "#168fee"
    },
    "& .MuiPickersDay-daySelected": {
      color: "#fff"
    }
  },
  acceptUntil: {
    "& .MuiPickersDay-current": {
      color: "#168fee"
    },
    "& .MuiPickersDay-daySelected": {
      color: "#fff"
    },
    width: "100%"
  }
}));

function CreateCourseDialog({
  newCourseDialogVisable,
  setNewCourseDialogVisable,
  setCourses,
  courses
}) {
  //Hooks
  const classes = useStyles();
  const intl = useIntl();
  //Ephemeral State
  const [courseName, setCourseName] = React.useState(null);
  const [year, setYear] = React.useState(null);
  const [semester, setSemester] = React.useState(null);
  const [courseStart, setCourseStart] = React.useState(null);
  const [courseEnd, setCourseEnd] = React.useState(null);

  //Variabels
  const addCourse = firebaseFunctions.httpsCallable(
    "adminFunctions-createCourse"
  );

  // Behavior
  const saveCourse = () => {
    addCourse({
      name: courseName,
      year: year,
      semester: semester,
      course_start: courseStart,
      course_end: courseEnd
    }).then(response => {
      if (response.data.length > 0)
        setCourses([
          ...courses,
          {
            id: response.data[0].id,
            name: courseName,
            academic_year: year,
            semester: semester,
            course_start: courseStart,
            course_end: courseEnd
          }
        ]);
    });
    handleClose();
  };

  function handleClose() {
    setYear(null);
    setSemester(null);
    setCourseName(null);
    setCourseStart(null);
    setCourseEnd(null);
    setNewCourseDialogVisable(false);
  }

  //Render
  return (
    <Dialog
      open={newCourseDialogVisable}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Add a course</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              onChange={e => {
                setCourseName(e.target.value);
              }}
              autoFocus
              margin="dense"
              id="course_name"
              aria-label="course-name-input"
              label="Name"
              type="text"
              value={courseName}
              fullWidth
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              variant="outlined"
              onChange={e => {
                setYear(e.target.value);
              }}
              value={year}
              aria-label="course-academic-year-input"
              autoFocus
              margin="dense"
              id="academic_year"
              label="Academic year"
              type="text"
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              variant="outlined"
              onChange={e => {
                setSemester(e.target.value);
              }}
              value={semester}
              autoFocus
              margin="dense"
              aria-label="course-semester-input"
              id="semester"
              label="Semester"
              type="text"
              fullWidth
            />
          </Grid>

          <Grid item xs={6}>
            <DatePicker
              id="startDate"
              name="startDate"
              label={"Start date"}
              openTo="day"
              className={classes.acceptUntil}
              DialogProps={{ className: classes.startDate }}
              minDate={new Date()}
              inputFormat="dd/MM/yyyy"
              value={courseStart}
              emptyLabel={intl.formatMessage({
                id: "tasks.pickDate",
                defaultMessage: "Choose Course Start Date"
              })}
              onChange={e => {
                setCourseStart(e);
              }}
              leftArrowButtonProps={{ "aria-label": "previous month" }}
              rightArrowButtonProps={{ "aria-label": "next month" }}
              renderInput={params => (
                <TextField variant="outlined" {...params} />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <DatePicker
              id="endDate"
              minDate={"deadline"}
              className={classes.acceptUntil}
              DialogProps={{ className: classes.startDate }}
              label={"End date"}
              openTo="day"
              inputFormat="dd/MM/yyyy"
              value={courseEnd}
              emptyLabel={intl.formatMessage({
                id: "tasks.pickDate",
                defaultMessage: "Choose Date"
              })}
              onChange={e => {
                setCourseEnd(e);
              }}
              leftArrowButtonProps={{ "aria-label": "previous month" }}
              rightArrowButtonProps={{ "aria-label": "next month" }}
              renderInput={params => (
                <TextField variant="outlined" {...params} />
              )}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={saveCourse} color="primary">
          Create Course
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default CreateCourseDialog;
