// Dependencies
import React from "react";
import clsx from "clsx";

// Redux dependencies
import { useDispatch, useSelector } from "react-redux";
import {
  FAB_STATUS,
  FAB_TYPE,
  mentorReviewFabClick
} from "../../redux/fabSlice";
// Components

// Material UI
import makeStyles from "@mui/styles/makeStyles";
import { useTheme } from "@mui/material/styles";
import { Fab, Zoom, CircularProgress } from "@mui/material";
import ChatOutlinedIcon from "@mui/icons-material/ChatOutlined";
// Styles
const useStyles = makeStyles(theme => {
  return {
    fab: {
      position: "absolute",
      zIndex: 99999
    },
    large: { bottom: 24, right: 24 },
    medium: { bottom: 28, right: 28 },
    small: { bottom: 32, right: 32 }
  };
});

export default function CustomFab() {
  // Hooks
  const dispatch = useDispatch();
  const theme = useTheme();
  const classes = useStyles();

  // Redux state
  const fabStatus = useSelector(state => state.fab.status);
  const fabType = useSelector(state => state.fab.type);
  // Ephemeral state

  // Derived state
  const isVisible = fabStatus === FAB_STATUS.VISIBLE;
  const transitionDuration = {
    enter: theme.transitions.duration.enteringScreen,
    exit: theme.transitions.duration.leavingScreen
  };

  const fabs = [
    {
      type: FAB_TYPE.MENTOR,
      color: "primary",
      icon: <ChatOutlinedIcon />,
      label: "Chat",
      size: "large",
      callback: () => {
        dispatch(mentorReviewFabClick());
      }
    },
    {
      type: FAB_TYPE.LOADING,
      color: "primary",
      icon: <CircularProgress color="inherit" />,
      label: "Loading",
      size: "large",
      disabled: true,
      callback: () => {}
    }
  ];

  // Behavior

  //Render
  return (
    <>
      {fabs.map(fab => {
        return fab.type === fabType ? (
          <Zoom
            key={fab.color}
            in={isVisible}
            timeout={transitionDuration}
            style={{
              transitionDelay: `${isVisible ? transitionDuration.exit : 0}ms`
            }}
            unmountOnExit
          >
            <Fab
              className={clsx(classes.fab, classes[fab.size])}
              aria-label={fab.label}
              color={fab.color}
              onClick={fab.callback}
              size={fab.size}
            >
              {fab.icon}
            </Fab>
          </Zoom>
        ) : null;
      })}
    </>
  );
}
