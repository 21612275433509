// Dependencies
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { FormattedMessage, useIntl } from "react-intl";
import { grAPI } from "../../../api";
import { useGetTheme, useQuery } from "../../../hooks";

// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  selectTask,
  selectSubmission,
  submitStandardTask
} from "../../../redux/tasksSlice";
import { selectQuestions } from "../../../redux/interactionsSlice";
import { setIsLoading } from "../../../redux/readerActionsSlice";
import { selectCurrentText } from "../../../redux/textsSlice";

// Components

// Material UI
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";

import makeStyles from "@mui/styles/makeStyles";

import {
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Checkbox,
  FormGroup,
  FormControlLabel
} from "@mui/material";
import { selectTextDirection } from "../../../redux/firestoreSelectors";

// Styles
const useStyles = makeStyles(theme => ({
  modal: {
    position: "relative",
    width: "50%",
    paddingInline: theme.spacing(3),
    paddingBlock: theme.spacing(2)
  },
  dialogTitle: {
    padding: 0,
    marginBottom: theme.spacing(3)
  },
  dialogContent: {
    padding: 0,
    marginBottom: theme.spacing(2.5)
  },
  modalCancel: {
    position: "absolute",
    top: "10px",
    right: "10px",
    cursor: "pointer"
  },
  modalActions: {
    padding: 0,
    justifyContent: "flex-end",
    "& button": {
      fontSize: "inharit",
      fontWeight: "600"
    }
  },
  buttonDiscard: {
    "& > .MuiButton-label": {
      opacity: 0.6
    }
  },
  button: {
    textTransform: "none",
    color: "inherit"
  },
  primaryColor: {
    color: theme.palette.primary.main
  },
  validationList: {
    listStyle: "none",
    textAlign: "left",
    margin: 0,
    padding: 0,
    "& > li": {
      marginBottom: theme.spacing(1),
      paddingInline: theme.spacing(1),
      paddingBlock: theme.spacing(1),
      display: "flex",
      "&:last-child": {
        marginBottom: 0
      },
      "&:nth-child(even)": {
        backgroundColor: "rgba(0,0,0,0.1)"
      }
    }
  },
  validationListQuestion: {
    width: "50%"
  },
  spacer: {
    width: theme.spacing(2)
  },
  validationListStatus: {
    width: "50%",
    color: theme.palette.primary.main
  },
  dialogPaper: {
    textAlign: "start",
    unicodeBidi: "plaintext"
  },
  dialogPaperWargning: {
    background: theme.palette.error.main,
    color: "#FFF"
  },
  checkbox: {
    marginBlockStart: theme.spacing(3)
  }
}));

export default function SubmitTaskModal({
  modalVisible,
  setModalVisible,
  validationStatue = () => {},
  afterSubmit = () => {}
}) {
  //Hooks
  const dispatch = useDispatch();
  const classes = useStyles();
  const intl = useIntl();
  const theme = useGetTheme({ alwase: "light" });
  const { submission_id } = useQuery();

  const submission = useSelector(state =>
    selectSubmission(state, Number(submission_id))
  );
  const task = useSelector(state => selectTask(state, submission.task_id));
  const selectedText = useSelector(selectCurrentText);
  const textDirection = useSelector(state => selectTextDirection(state));
  const questions = useSelector(state =>
    selectQuestions(state, submission.task_id)
  );
  const highlights = useSelector(state => state.interactions.highlights);
  const answers = useSelector(state => state.interactions.answers);
  const [validation, setValidation] = useState([]);
  const [copyInteractionsToReader, setCopyInteractionsToReader] =
    useState(true);

  function validateGR(questionHighlights, questionAnswer) {
    let highlight = true;
    let answer = true;
    if (!questionHighlights?.length) {
      highlight = false;
    }
    if (
      !questionAnswer ||
      (questionAnswer.rich_text.blocks &&
        !questionAnswer.rich_text.blocks.reduce((acc, block) => {
          return (acc += block.text);
        }, "").length)
    ) {
      answer = false;
    }
    return {
      highlight,
      answer
    };
  }

  useEffect(() => {
    if (!questions.length) return;
    const validate = [];
    questions.forEach((question, index) => {
      const questionHighlights = highlights.filter(
        item => item.interaction_id === question.id
      );
      const questionAnswer = answers.find(
        item => item.interaction_id === question.id
      );
      let validation = {
        highlight: true,
        answer: true
      };

      switch (question.interaction_subtype) {
        case "FIND_IN_TEXT":
          if (!questionHighlights.length) {
            validation.highlight = false;
          }
          break;
        case "OPEN_ENDED":
          if (!questionHighlights?.length && question.includeCitation) {
            validation.highlight = false;
          }
          if (
            !questionAnswer ||
            (questionAnswer.rich_text.blocks &&
              !questionAnswer.rich_text.blocks.reduce((acc, block) => {
                return (acc += block.text);
              }, "").length)
          ) {
            validation.answer = false;
          }
          break;
        case "MULTI_CHOICE":
          if (!questionHighlights?.length && question.includeCitation) {
            validation.highlight = false;
          }
          if (!questionAnswer) {
            validation.answer = false;
          }
          break;
        case "GUIDED_READING":
          validation = validateGR(questionHighlights, questionAnswer);
          break;
        default:
          break;
      }
      validate.push(validation);
    });
    setValidation(validate);
    /*eslint-disable */
  }, [
    JSON.stringify(questions),
    JSON.stringify(highlights),
    JSON.stringify(answers),
    task.id
  ]);
  /*eslint-enable */

  useEffect(() => {
    validationStatue(validation);
  }, [validation]); // eslint-disable-line

  const closeModal = () => {
    setModalVisible(false);
  };

  async function handleSubmit() {
    setModalVisible(false);
    dispatch(setIsLoading(true));
    if (task.task_type === "guidedReading") {
      await grAPI.updateGrTaskSubmission(
        submission.id,
        selectedText.id,
        task.id
      );
      dispatch(setIsLoading(false));
    } else {
      dispatch(
        submitStandardTask({
          text_id: task.text_id,
          submission_id: submission.id,
          copyInteractionsToReader
        })
      );
    }
  }

  function generateMessage() {
    if (!questions.length) return "";
    let msg = validation.reduce((acc, item, i) => {
      if (!item.highlight) {
        acc = `${acc}${acc.length > 0 ? ";" : ""} ${intl.formatMessage({
          id: "task.submission.missingCitations",
          defaultMessage: "Missing mandatory highlights in question"
        })} "${questions[i].content}"`;
      }
      if (!item.answer) {
        acc = `${acc}${acc.length > 0 ? ";" : ""} ${intl.formatMessage({
          id: "task.submission.didntAnswer",
          defaultMessage: "Missing your answer in question"
        })} "${questions[i].content}"`;
      }
      return acc;
    }, "");
    msg = msg.length
      ? msg
      : intl.formatMessage({
          id: "task.submission.complete",
          defaultMessage: "Are you ready to submit your task?"
        });
    return <Typography variant="body2">{msg}</Typography>;
  }

  const highlightIncomplete = validation.find(item => !item.highlight);
  const ansewerIncomplete = validation.find(item => !item.answer);

  // Render
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <Dialog
          open={modalVisible}
          onClose={closeModal}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          PaperProps={{
            "data-test-id": "submit-task-dialog",
            className: classes.modal,
            style: {
              direction: textDirection
            }
          }}
          classes={{
            paper: clsx(classes.dialogPaper, {
              [classes.dialogPaperWargning]:
                highlightIncomplete || ansewerIncomplete
            })
          }}
        >
          <DialogTitle
            id="alert-dialog-title"
            data-test-id="submit-task-dialog-title"
            classes={{ root: classes.dialogTitle }}
          >
            <FormattedMessage
              id={
                highlightIncomplete || ansewerIncomplete
                  ? "task.submission.incomplete"
                  : "task.submission.taskSubmission"
              }
              defaultMessage={
                highlightIncomplete || ansewerIncomplete
                  ? "Incomplete task"
                  : "Task submission"
              }
            />
          </DialogTitle>
          <DialogContent
            classes={{ root: classes.dialogContent }}
            data-test-id="submit-task-dialog-content"
          >
            {generateMessage()}

            <FormGroup className={classes.checkbox}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={copyInteractionsToReader}
                    onChange={() => {
                      setCopyInteractionsToReader(!copyInteractionsToReader);
                    }}
                  />
                }
                // label="All questions and highlights will be copied to reader"
                label={intl.formatMessage({
                  id: "task.submission.copyInteractionsToReader",
                  defaultMessage:
                    "All questions and highlights will be copied to reader"
                })}
              />
            </FormGroup>
          </DialogContent>
          <DialogActions className={classes.modalActions}>
            <Button
              onClick={closeModal}
              className={clsx(classes.buttonDiscard, classes.button)}
            >
              <FormattedMessage id="cancel" defaultMessage="Cancel" />
            </Button>
            <Button
              className={clsx(classes.button, {
                [classes.primaryColor]:
                  !highlightIncomplete && !ansewerIncomplete
              })}
              onClick={handleSubmit}
            >
              {task?.task_type === "guidedReading" ? (
                <FormattedMessage
                  id="task.submission.submit"
                  defaultMessage={"Submit"}
                />
              ) : (
                <FormattedMessage
                  id={
                    !highlightIncomplete && !ansewerIncomplete
                      ? "task.submission.submit"
                      : "task.submission.submitAnyway"
                  }
                  defaultMessage={
                    !highlightIncomplete && !ansewerIncomplete
                      ? "task.submission.submit"
                      : "Submit anyway"
                  }
                />
              )}
            </Button>
          </DialogActions>
        </Dialog>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

SubmitTaskModal.propTypes = {
  modalVisible: PropTypes.bool.isRequired,
  setModalVisible: PropTypes.func.isRequired,
  validationStatue: PropTypes.func,
  afterSubmit: PropTypes.func,
  taskId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};
