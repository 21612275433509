// Dependencies
import React, { useRef, useState, useEffect } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { useGetTheme, useQuery } from "../../hooks";

// Redux
import { useSelector, useDispatch } from "react-redux";
import { setGrMode } from "../../redux/grSlice";
import { grAPI } from "../../api";

// Components

// Material UI
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import { Box, IconButton, Menu, MenuItem, Button } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";

// Styles
const useStyles = makeStyles(theme => ({
  stepWrapper: {
    backgroundColor: theme.palette.background.sidePanel,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    position: "relative",
    zIndex: 10,
    width: "inherit",
    backgroundImage: "none"
  },
  stepTabs: {
    display: "flex",
    listStyle: "none",
    width: "100%",
    paddingBlock: "15px",
    paddingInlineStart: "17px",
    margin: 0
  },
  tabHeader: {
    borderRadius: 50,
    width: 40,
    height: 40,
    fontSize: 16,
    marginInlineEnd: theme.spacing(1),
    fontFamily: "monospace",
    minWidth: "unset",
    transition: ".15s ease-out",
    "&:last-of-type": {
      marginInlineEnd: 0
    }
  },
  notSelectedTab: {
    background: "#3f3f3f"
  },
  menuIcon: {
    color: "#e0e0e0",
    height: 48,
    flexShrink: 0
  },
  menuItem: {
    fontSize: "14px",
    fontWeight: "300",
    fontStyle: "normal",
    letterSpacing: "0.1px",
    lineHeight: "22px",
    "&:hover": {}
  }
}));

export default function GrSteps({ color = "secondary" }) {
  //Hooks
  const dispatch = useDispatch();
  const classes = useStyles();
  const theme = useGetTheme({ alwase: "dark" });
  const menuElRef = useRef();
  const { submission_id } = useQuery();

  //Redix state
  const stage = useSelector(state => state.gr.stage);
  const grMode = useSelector(state => state.gr.mode);
  const activeGrTaskId = useSelector(state => state.tasks.activeGrTaskId);

  //Ephemeral state
  const [menuOpen, setMenuOpen] = useState(false);

  //Derived state
  const numberOfSteps = grMode === "full" ? 5 : 3;

  //Behavior
  useEffect(() => {
    // When gr is a task it shold always be in light mode
    if (submission_id) {
      dispatch(setGrMode("light"));
    }
  }, [submission_id]); // eslint-disable-line

  // creates an array if integers from 1 to num
  function getStepsArray(num) {
    return Array.from({ length: num }, (step, index) => index);
  }

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <Box className={classes.stepWrapper}>
          <Box value={stage} className={classes.stepTabs}>
            {getStepsArray(numberOfSteps).map(step => (
              <Button
                key={step}
                data-test-class="gr-step"
                color={color}
                variant={stage === step ? "contained" : "outlined"}
                className={clsx(
                  classes.tabHeader,
                  stage !== step && classes.notSelectedTab
                )}
                sx={
                  stage === step
                    ? {
                        backgroundColor: theme.palette[color].light
                      }
                    : {}
                }
                onClick={() => {
                  grAPI.updateGrState({ grStage: step });
                }}
              >
                {step + 1}
              </Button>
            ))}
          </Box>
          {!activeGrTaskId && (
            <IconButton
              aria-label="search, coming soon"
              className={clsx(classes.menuIcon)}
              ref={menuElRef}
              onClick={() => {
                setMenuOpen(!menuOpen);
              }}
              size="large"
            >
              <MoreVertIcon />
            </IconButton>
          )}

          <Menu
            anchorEl={menuElRef.current}
            keepMounted
            open={Boolean(menuOpen)}
            onClose={() => setMenuOpen(false)}
            disableScrollLock={true}
          >
            <MenuItem
              className={classes.menuItem}
              onClick={() => {
                setMenuOpen(false);
                grAPI.updateGrState({ grStage: 0, grMode: "light" });
              }}
            >
              <FormattedMessage id="gr.mode.3" defaultMessage="3 step guide" />
            </MenuItem>
            <MenuItem
              className={classes.menuItem}
              onClick={() => {
                setMenuOpen(false);
                grAPI.updateGrState({
                  grStage: 0,
                  grMode: "full"
                });
              }}
            >
              <FormattedMessage id="gr.mode.5" defaultMessage="5 step guide" />
            </MenuItem>
          </Menu>
        </Box>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

GrSteps.propTypes = {
  color: PropTypes.oneOf(["secondary", "primary"])
};
