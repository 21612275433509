import { createSlice } from "@reduxjs/toolkit";
import {
  determineConfig,
  determineFeatureByPathname
} from "./firestoreSelectors";
import {
  BREAKPOINTS,
  PRIMARY_SIDEBAR_STATE,
  SECONDARY_SIDEBAR_STATE,
  USER_PROFILE,
  FEATURES,
  READER_MODES,
  TASK
} from "../consts";
// import and use breakpoints

const initialState = {
  clientWindowWidth: null,
  primarySidebarWidth: 384,
  secondarySidebarWidth: 0,
  mainContentWidth: null,
  isTextCursor: false
};

// Thunk
export function initializeAppLayout(params) {
  return (dispatch, getState) => {
    const state = getState();
    const config = determineConfig(state);
    dispatch(setWindowSize(params)).then(() =>
      dispatch(calculateLayout(config))
    );
  };
}

export function initializeFeatureLayout() {
  return (dispatch, getState) => {
    const state = getState();
    const config = determineConfig(state);

    dispatch(calculateLayout(config));
  };
}

export function initializeTaskLayout() {
  return (dispatch, getState) => {
    const state = getState();
    const config = determineConfig(state);

    dispatch(resetToSingleSidebar());
  };
}

export function setSecondarySidebarViewMode(mode) {
  return (dispatch, getState, { getFirestore }) => {
    const state = getState();
    const firestore = getFirestore();
    const userId = state.firebase.auth.uid;
    const pathname = state.router.location.pathname;
    // We persist the state per feature. The context will be the name of the feature the user is currently in (reader, task, etc)
    const feature = determineFeatureByPathname(pathname);
    firestore.set(
      // update the user profile in firebase
      `${USER_PROFILE.CUSTOM_CONFIG_PATH}/${userId}/${feature}`,
      { secondarySidebarState: mode },
      { merge: true }
    );
  };
}

export function clickOnTaskIcon(task_type) {
  return (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();
    const state = getState();
    const userId = state.firebase.auth.uid;
    const pathname = state.router.location.pathname;
    const feature = determineFeatureByPathname(pathname);

    if (task_type === TASK.TYPE.PEER_REVIEW) {
      firestore.set(
        // update the user profile in firebase
        `${USER_PROFILE.CUSTOM_CONFIG_PATH}/${userId}/${FEATURES.TASK}`,
        {
          primarySidebarState: PRIMARY_SIDEBAR_STATE.COLLAPSED
        },
        { merge: true }
      );
    } else if (feature === FEATURES.TASK)
      firestore.set(
        // update the user profile in firebase
        `${USER_PROFILE.CUSTOM_CONFIG_PATH}/${userId}/${FEATURES.READER}`,
        {
          primarySidebarState: PRIMARY_SIDEBAR_STATE.COLLAPSED,
          readerMode: READER_MODES.FREE_ANNOTATIONS
        },
        { merge: true }
      );
    else if (feature === FEATURES.READER)
      firestore.set(
        // update the user profile in firebase
        `${USER_PROFILE.CUSTOM_CONFIG_PATH}/${userId}/${FEATURES.TASK}`,
        {
          primarySidebarState: PRIMARY_SIDEBAR_STATE.EXPANDED
        },
        { merge: true }
      );
  };
}

export function pdfThumbnailsIconClicked() {
  return (dispatch, getState, { getFirestore }) => {
    const state = getState();
    const firestore = getFirestore();
    const userId = state.firebase.auth.uid;
    const pathname = state.router.location.pathname;

    // We persist the state per feature. The context will be the name of the feature the user is currently in (reader, task, etc)
    const feature = determineFeatureByPathname(pathname);

    const newSidebarState = determineSecondarySidebarStateChange(
      state,
      SECONDARY_SIDEBAR_STATE.THUMBNAILS
    );

    firestore.set(
      // update the user profile in firebase
      `${USER_PROFILE.CUSTOM_CONFIG_PATH}/${userId}/${feature}`,
      { secondarySidebarState: newSidebarState },
      { merge: true }
    );
  };
}

export function suggestionsIconClicked(mode) {
  return (dispatch, getState, { getFirestore }) => {
    const state = getState();
    const firestore = getFirestore();
    const userId = state.firebase.auth.uid;
    const pathname = state.router.location.pathname;

    // We persist the state per feature. The context will be the name of the feature the user is currently in (reader, task, etc)
    const feature = determineFeatureByPathname(pathname);
    firestore.set(
      // update the user profile in firebase
      `${USER_PROFILE.CUSTOM_CONFIG_PATH}/${userId}/${feature}`,
      { suggestions: mode },
      { merge: true }
    );
  };
}

export function togglePrimarySidebar() {
  return (dispatch, getState, { getFirestore }) => {
    const state = getState();
    const firestore = getFirestore();
    const userId = state.firebase.auth.uid;
    const pathname = state.router.location.pathname;

    const feature = determineFeatureByPathname(pathname);
    const newSidebarState = determinePrimarySidebarStateChange(state);
    if (feature === FEATURES.TASK)
      firestore.set(
        // update the user profile in firebase
        `${USER_PROFILE.CUSTOM_CONFIG_PATH}/${userId}/${feature}`,
        { primarySidebarState: newSidebarState },
        { merge: true }
      );
    else
      firestore.set(
        // update the user profile in firebase
        `${USER_PROFILE.CUSTOM_CONFIG_PATH}/${userId}/${FEATURES.READER}`,
        {
          primarySidebarState: PRIMARY_SIDEBAR_STATE.COLLAPSED,
          readerMode: READER_MODES.FREE_ANNOTATIONS
        },
        { merge: true }
      );
  };
}

export const layoutSlice = createSlice({
  name: "layout",
  initialState,
  reducers: {
    setWindowSize: (state, value) => {
      const window = value.payload;
      state.clientWindowWidth = window;
    },
    resetToSingleSidebar: state => {
      // Opens the primary sidebar and closes the secoundary sidebar
      state.primarySidebarWidth = 384;
      state.secondarySidebarWidth = 0;
    },
    calculateLayout: (state, value) => {
      const window = state.clientWindowWidth;
      const { primarySidebarState, secondarySidebarState } = value.payload;

      const primarySidebarWidth = getPrimarySidebarWidth(
        window,
        primarySidebarState
      );
      const secondarySidebarWidth = getSecondarySidebarWidth(
        window,
        secondarySidebarState
      );

      const mainContentWidth = getMainContentWidth(
        window,
        primarySidebarWidth,
        secondarySidebarWidth
      );

      state.mainContentWidth = mainContentWidth;
      state.primarySidebarWidth = primarySidebarWidth;
      state.secondarySidebarWidth = secondarySidebarWidth;
    },
    setCustomCursor: (state, value) => {
      state.isTextCursor = value.payload;
    }
  }
});

// Selectors

// Util
function getPrimarySidebarWidth(window, state) {
  //sidebar + side panel,
  if (state === PRIMARY_SIDEBAR_STATE.COLLAPSED) return 48;

  // sidebar + sidepanel
  if (window >= BREAKPOINTS.DESKTOP) return 468; // 420px + 48px
  else return 384; // 336px + 48px
}
function getSecondarySidebarWidth(window, state) {
  if (state === SECONDARY_SIDEBAR_STATE.COLLAPSED) return 0;
  else return 240;
}

function getMainContentWidth(window, primarySibar, secondarySidebar) {
  return window - (primarySibar + secondarySidebar);
}

function determineSecondarySidebarStateChange(state, newSidebarState) {
  const { secondarySidebarState } = determineConfig(state);
  if (secondarySidebarState === newSidebarState) {
    // if we are already seeing the newSidebarState, set the sidebar to closed
    return SECONDARY_SIDEBAR_STATE.COLLAPSED;
  } else {
    // otherwise, set it to the new state
    return newSidebarState;
  }
}

function determinePrimarySidebarStateChange(state) {
  const { primarySidebarState } = determineConfig(state);
  // toggle the current change
  if (primarySidebarState === PRIMARY_SIDEBAR_STATE.EXPANDED) {
    return PRIMARY_SIDEBAR_STATE.COLLAPSED;
  } else return PRIMARY_SIDEBAR_STATE.EXPANDED;
}

export const {
  setWindowSize,
  calculateLayout,
  initializeLayout,
  resetToSingleSidebar,
  setCustomCursor
} = layoutSlice.actions;

export default layoutSlice.reducer;
