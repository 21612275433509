import React, {
  useState
  //  useRef
} from "react";
import makeStyles from '@mui/styles/makeStyles';
import clsx from "clsx";
import { Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { selectCurrentText } from "../../../redux/textsSlice";
import { FormattedMessage } from "react-intl";
import CitationDialog from "./CitationDialog";

const useStyles = makeStyles(theme => ({
  findTaskAnswerWrapper: {
    paddingInline: theme.spacing(3),
    paddingBottom: theme.spacing(5.5),
    paddingTop: theme.spacing(2.5)
  },
  dialogTitle: {
    background: "#168FEE",
    color: "#ffffff"
  },
  dialogHeader: {},
  dialogActions: {
    justifyContent: "left",
    position: "relative"
    //  justifyContent:'flex-start',
  },
  dialogActionsRtl: {
    justifyContent: "right",
    position: "relative"
    //  justifyContent:'flex-start',
  },
  blue: {
    color: "#168FEE"
  },
  dialogBtn: {
    position: "absolute",
    right: "16px",
    color: "#ffffff"
  },
  expandMsg: {
    cursor: "pointer",
    textAlign: "left"
  },
  span: {
    textAlign: "left"
  }
}));

function FindTaskAnswer(props) {
  const classes = useStyles();
  const text = useSelector(selectCurrentText);
  const [openDialog, setOpenDialog] = useState(false);

  return (
    <div className={classes.findTaskAnswerWrapper}>
      <Typography
        onClick={() => setOpenDialog(true)}
        onKeyPress={e => {
          if (e.key === "Enter") {
            setOpenDialog(true);
          }
        }}
        className={clsx(classes.expandMsg, classes.blue)}
        variant="body1"
        role="button"
        name="mark-relevant-passeges-link"
        tabIndex="0"
      >
        <FormattedMessage
          id="task.find.instructions"
          defaultMessage="Mark the relevant passages in text"
        />
      </Typography>
      <Typography className={classes.span} variant="body1">
        <FormattedMessage
          id="task.find.details"
          defaultMessage="Alethea will compare your marks with the students' and help you understand how well they did"
        />
      </Typography>
      <CitationDialog
        setOpenDialog={setOpenDialog}
        url={text.url}
        location={text.file_locaiton}
        highlights={props.highlights}
        openDialog={openDialog}
        addHighlight={!props.readOnly && props.addHighlight}
        removeHighlight={!props.readOnly && props.removeHighlight}
        citationTitleFormattedMessage={
          <FormattedMessage id="text.mark" defaultMessage="Mark in Text" />
        }
      />
    </div>
  );
}

export default FindTaskAnswer;
