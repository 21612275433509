// Dependencies
import React from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import { FormattedMessage } from "react-intl";
import { isEmpty } from "lodash";
import { captureException } from "../../../utils/errorHandlers";
import {
  TextEditor,
  TextEditorFooter,
  TextEditorInput,
  TextEditorSavingIndicator
} from "../../SharedComponents/textEditor";

// Redux Dependencies
import { useSelector, useDispatch } from "react-redux";
import {
  updateInteraction,
  selectAnswerComment
} from "../../../redux/interactionsSlice";

//Components
import SummaryActions from "./SummaryActions";
import { ScrollBox } from "../../SharedComponents";
import EmptyState from "../EmptyState";

import makeStyles from "@mui/styles/makeStyles";
import { Box, Typography } from "@mui/material";
import { interactionsAPI } from "../../../api";
import { selectCourseByTextId } from "../../../redux/coursesSlice";
import { enqueueFlashMessage } from "../../../redux/userSlice";
import { INTERACTION_SUBTYPES, INTERACTION_TYPES } from "../../../consts";

// Styles
const useStyles = makeStyles(theme => ({
  scrollBox: {
    height: "calc(100vh - 120px)",
    display: "flex",
    alignItems: "center"
  },
  container: {
    height: "calc(100vh - 120px)",
    width: 640,
    display: "flex",
    flexGrow: 1,
    flexDirection: "column",
    paddingTop: "5vh",
    paddingBottom: "5vh",
    justifyContent: "space-between"
  },
  header: {},
  title: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    fontSize: 16
  },
  editor: {
    minHeight: 240,
    flexGrow: 1,
    marginTop: theme.spacing(5)
  },
  actions: {},
  btn: {
    marginRight: "8px"
  }
}));

function SummaryManager({
  isTaskSubmitted,
  showSelfFeedback = true,
  color = "secondary"
}) {
  // Hooks
  const classes = useStyles();
  const intl = useIntl();
  const dispatch = useDispatch();

  //Redux state

  const selectedTextId = useSelector(state => state.texts.selectedTextId);
  const selectedCourse = useSelector(selectCourseByTextId);
  const selectedQuestionId = useSelector(state => state.gr.selectedQuestionId);
  const alertsDuration = useSelector(
    state => state.user.userProfile.alertsDuration
  );
  const summary = useSelector(state =>
    selectAnswerComment(state, selectedQuestionId)
  );

  const selectedQuestion = useSelector(state => {
    return state.interactions.questions.find(
      question => question.id === selectedQuestionId
    );
  });

  // Ephemeral state

  // Variables

  const ERROR_MSG = {
    message: intl.formatMessage({
      id: "error.saveingFailed",
      defaultMessage:
        "There was an problem saving yor answer. Please check you connection and try again"
    }),
    severity: "error",
    duration: alertsDuration
  };

  //Behavior
  function createSummary(content) {
    const { plainText, richText, wordCount } = content;
    interactionsAPI
      .createAnswer({
        content: plainText,
        rich_text: richText,
        word_count: wordCount,
        interaction_id: selectedQuestionId,
        text_id: selectedTextId,
        course_id: selectedCourse.id,
        interaction_type: INTERACTION_TYPES.ANSWER,
        interaction_subtype: INTERACTION_SUBTYPES.COMMENT
      })
      .then(({ data }) => {})
      .catch(err => {
        dispatch(enqueueFlashMessage(ERROR_MSG));
        captureException(err);
      });
  }

  function updateSummary(summary, content) {
    const { plainText, richText, wordCount } = content;
    dispatch(
      updateInteraction({
        disableRollback: true, // we don't want to to erase users input if the request fails
        interaction: summary,
        errorMessage: "error.saveingAnswerFailed",
        update: {
          content: plainText,
          rich_text: richText,
          word_count: wordCount
        }
      })
    );
  }

  // render
  return selectedQuestion ? (
    <ScrollBox className={classes.scrollBox}>
      <Box className={classes.container}>
        <Box className={classes.header}>
          <Typography variant="h4">
            <FormattedMessage
              id="gr.stage.5.ownWords"
              defaultMessage="In my own words"
            />
          </Typography>
          <Typography variant="h6">{selectedQuestion.content}</Typography>
        </Box>
        <TextEditor
          content={summary.rich_text}
          disabled={isTaskSubmitted}
          className={classes.editor}
        >
          <TextEditorInput
            onChange={content => {
              isEmpty(summary)
                ? createSummary(content)
                : updateSummary(summary, content);
            }}
            placeholder={intl.formatMessage({
              id: "task.open.placeholder.answer",
              defaultMessage: "Type your answer here"
            })}
          />
          <TextEditorFooter showWordCount={true}>
            <TextEditorSavingIndicator status={summary.status} />
          </TextEditorFooter>
        </TextEditor>
        {showSelfFeedback && (
          <Box className={classes.actions}>
            <SummaryActions color={color} />
          </Box>
        )}
      </Box>
    </ScrollBox>
  ) : (
    <EmptyState />
  );
}

SummaryManager.propTypes = {
  isTaskSubmitted: PropTypes.bool,
  showSelfFeedback: PropTypes.bool,
  color: PropTypes.oneOf(["primary", "secondary"])
};

export default SummaryManager;
