// Dependencies
import React, { useRef, useState } from "react";
import clsx from "clsx";
import { FormattedMessage } from "react-intl";
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import { useGetTheme } from "../../hooks";

// Redux Dependencies
import { useSelector, useDispatch } from "react-redux";

import makeStyles from "@mui/styles/makeStyles";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import EditIcon from "@mui/icons-material/Edit";
import DeleteFilledIcon from "@mui/icons-material/Delete";
import {
  IconButton,
  ListItemIcon,
  Typography,
  Menu,
  MenuItem
} from "@mui/material";
import { selectTextDirection } from "../../redux/firestoreSelectors";

// Styles
const useStyles = makeStyles(theme => ({
  left: {
    textAlign: "left"
  },
  editBtn: {
    color: "white",
    opacity: 0,
    "&:hover": {
      backgroundColor: theme.palette.background.hover
    },
    display: "flex",
    maxHeight: "40px",
    maxWidth: "40px"
  },
  selectedQuestionEditBtn: {
    color: "black"
  },
  right: {
    textAlign: "right"
  },
  leftDir: {
    textAlign: "left",
    direction: "ltr"
  },
  rightDir: {
    textAlign: "right",
    direction: "rtl"
  },
  listItem: {
    width: "24px",
    height: "24px",
    cursor: "pointer"
  },
  colorBtnRtl: {
    marginRight: "8px",
    marginLeft: "40px"
  },
  colorBtnLtr: {
    marginRight: "40px",
    marginLeft: "8px"
  },

  barIcon: {
    color: theme.palette.grey[900]
  },

  menu: {},
  menuLtr: {
    justifyContent: "left",
    direction: "ltr",
    textAlign: "left"
  },
  menuRtl: {
    justifyContent: "right",
    direction: "rtl",
    textAlign: "right"
  },

  menuItem: {
    fontSize: "14px",
    fontWeight: "300",
    fontStyle: "normal",
    letterSpacing: "0.1px",
    lineHeight: "22px"
  }
}));

const MuiMenu = React.forwardRef((props, ref) => {
  return <Menu ref={ref} {...props} />;
});

MuiMenu.displayName = "MuiMenu";

export function EditMenuItem(props) {
  const classes = useStyles();
  // Render
  return (
    <MenuItem
      className={clsx(classes.menuItem, classes.left)}
      onClick={props.click}
    >
      {props.icon && (
        <ListItemIcon className={classes.listItem}>{props.icon}</ListItemIcon>
      )}
      <Typography variant="inherit">{props.message}</Typography>
    </MenuItem>
  );
}

export function EditMenu(props) {
  // Hooks
  const textDirection = useSelector(state => selectTextDirection(state));
  const classes = useStyles();
  const ref = useRef();

  // Ephemeral State
  const [anchorEl, setAnchorEl] = useState(false);

  // Render
  return (
    <React.Fragment>
      <IconButton
        ref={ref}
        className={clsx(classes.editBtn, {
          [classes.selectedQuestionEditBtn]: props.selected
        })}
        onClick={e => {
          setAnchorEl(ref.current);
          // setMousePos({  X: e.clientX - 2,Y: e.clientY - 4,} );
          e.stopPropagation();
        }}
        size="large"
      >
        <MoreVertIcon />
      </IconButton>
      <MuiMenu
        direction={textDirection}
        onClick={e => {
          setAnchorEl(false);
          e.stopPropagation();
        }}
        //anchorReference="anchorPosition"
        // anchorPosition={mousePos}
        //getContentAnchorEl={()=>{

        // anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        //transformOrigin={{ vertical: "top", horizontal: "center" }}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        keepMounted
        classes={{
          list: textDirection === "rtl" ? classes.menuRtl : classes.menuLtr
        }}
        onClose={() => {
          // setMousePos({});
          setAnchorEl(null);
          props.handleClose && props.handleClose();
        }}
        // getContentAnchorEl={null}
        disableScrollLock={true}
        //anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
        //transformOrigin={{vertical: 'top', horizontal: 'center'}}
      >
        <div>{props.children}</div>
      </MuiMenu>
    </React.Fragment>
  );
}

export default function QuestionEditMenu(props) {
  const theme = useGetTheme();

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <EditMenu
          selected={props.selected}
          open={Boolean(props.open)}
          handleClose={props.handleClose}
          anchorPosition={{
            left: props.mouseX ? props.mouseX : 0,
            top: props.mouseY ? props.mouseY : 0
          }}
        >
          <EditMenuItem
            icon={<EditIcon />}
            message={
              <FormattedMessage id="actions.edit" defaultMessage="Edit" />
            }
            click={props.onEdit}
          />
          <EditMenuItem
            icon={<DeleteFilledIcon />}
            message={
              <FormattedMessage id="actions.delete" defaultMessage="Delete" />
            }
            click={props.onDelete}
          />
        </EditMenu>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}
