// Dependencies
import React, { useEffect, useState } from "react";
import { firebaseFunctions, httpCallables, firestore } from "../../firebase";
import { useHistory } from "react-router-dom";
import { useQuery } from "../../hooks";
import { captureException } from "../../utils/errorHandlers";

// Redux
import { useFirestoreConnect } from "react-redux-firebase";
import { useDispatch, useSelector } from "react-redux";
import { setInteractions } from "../../redux/interactionsSlice";
import { setSelectedTextId } from "../../redux/textsSlice";
import {
  setAction,
  setAnnotatorMode,
  ToggleReaderMode
} from "../../redux/readerActionsSlice";
import { selectIsImpersonation } from "../../redux/userSlice";
// Components
import ReaderView from "../../ReaderView";
import {
  PrimarySidebarLayout,
  SecondarySidebarLayout
} from "../SharedComponents";
import ReaderSidebar from "../poc/ReaderSidebar";
import CommentPanel from "../comments/CommentPanel";
import SidePanel from "../SharedComponents/SidePanel";
import { PangeaSpinner } from "../SharedComponents";
import PDFThumbBar from "./pdf/sidebar/PDFThumbBar";
import { initializeFeatureLayout } from "../../redux/layoutSlice";

import {
  selectIsComments,
  selectIsThumbnails,
  selectIsPrimarySidebar,
  selectSecondarySidebarCollapsed,
  selectPrimarySidebarState,
  selectSecondarySidebarState,
  selectReadrMode
} from "../../redux/firestoreSelectors";
import { FEATURES } from "../../consts";
import { resetThreadsState } from "../../redux/realtimeInteractionsSlice";

// Material UI

function Reader() {
  // Hooks
  const dispatch = useDispatch();
  const history = useHistory();
  const { text_id } = useQuery();

  // Redux Selectors
  const userId = useSelector(state => state.firebase.auth.uid);

  useFirestoreConnect([
    {
      collection: "userProfiles",
      doc: "customConfig",
      subcollections: [{ collection: userId, doc: FEATURES.READER }],
      storeAs: "customConfig"
      // type: "once"
    }
  ]);

  const readerMode = useSelector(state => selectReadrMode(state));
  const primarySidebarState = useSelector(state =>
    selectPrimarySidebarState(state)
  );
  const secondarySidebarState = useSelector(state =>
    selectSecondarySidebarState(state)
  );
  const isPrimarySidebar = useSelector(state => selectIsPrimarySidebar(state));
  const isSecondarySidebar = useSelector(state =>
    selectSecondarySidebarCollapsed(state)
  );
  const isImpersonation = useSelector(selectIsImpersonation);

  const action = useSelector(
    state => state.readerActions.persistentActionState.actionBar
  );
  const grMode = useSelector(state => state.gr.mode);
  const grStage = useSelector(state => state.gr.stage);
  const annotatorMode = useSelector(
    state => state.readerActions.persistentActionState.annotatorMode
  );
  // Ephermeral state
  const [isLoading, setIsLoading] = useState(false);
  // Derived state
  // const isSidebarVisible = action !== "";
  const isThumbnails = useSelector(state => selectIsThumbnails(state));
  const isComments = useSelector(state => selectIsComments(state));
  const disableSeconderySidebar =
    ["poc", "", "task"].includes(action) &&
    ((grMode === "light" && grStage === 2) ||
      (grMode === "full" && [3, 4].includes(grStage)));

  // Behavior
  useEffect(() => {
    // if states are inverted ->
    // action == "poc" && primary sidebar == collapsed
    // This means we need to revert the reader mode to guided_reading and sidebar expanded.
    if (!isPrimarySidebar && action === "poc") {
      dispatch(ToggleReaderMode());
    }
  }, [dispatch, isPrimarySidebar, action]);

  useEffect(() => {
    dispatch(initializeFeatureLayout());
  }, [dispatch, primarySidebarState, secondarySidebarState]);

  //hack: 9/22/22 this is a band aid for a bug where annotator grMode inits as false, we’re separating ‘’ as a legit value ..whereas false || undefined are not.
  // A better fix would be to get rid of the actions / annotatorMode couple and have a seperate attr for highlight grMode
  useEffect(() => {
    if (action === "task") dispatch(setAction("poc"));
    if (!annotatorMode && annotatorMode !== "")
      dispatch(setAnnotatorMode(action));
  }, [action, annotatorMode, dispatch]);

  useEffect(() => {
    // Set selected text_id in firestore
    if (!text_id) return;
    dispatch(setSelectedTextId(Number(text_id)));
    !isImpersonation &&
      firestore
        .doc(`users/${userId}`)
        .update({ selectedTextId: Number(text_id) });
  }, [text_id, dispatch, userId, isImpersonation]);

  useEffect(() => {
    // Get text related interactions
    if (!text_id) return;
    let isCancelled = false;

    try {
      setIsLoading(true);
      httpCallables
        .interactionFunctions({
          text_id: Number(text_id),
          func_name: "readUserInteractionsPerText"
        })
        .then(({ data }) => {
          const { success } = data;
          if (success && !isCancelled) {
            const { interactions } = JSON.parse(data.payload);
            dispatch(setInteractions(interactions));
          } else {
            let error = new Error(`READ_TEXT_INTERACTIONS_FAILED`);
            error.message = data.error;
            error.data = { text_id };
            throw error;
          }
        })
        .finally(() => setIsLoading(false));
    } catch (err) {
      captureException(err);
    }

    return () => {
      isCancelled = true;
      dispatch(resetThreadsState());
    };
  }, [dispatch, text_id]);

  if (isLoading) return <PangeaSpinner />;
  else
    return (
      <>
        <PrimarySidebarLayout collapsed={!isPrimarySidebar || action === ""}>
          <SidePanel />
          {action !== "" && <ReaderSidebar />}
        </PrimarySidebarLayout>
        <ReaderView />
        <SecondarySidebarLayout
          collapsed={!isSecondarySidebar || disableSeconderySidebar}
        >
          {isThumbnails && <PDFThumbBar />}
          {isComments && <CommentPanel />}
        </SecondarySidebarLayout>
      </>
    );
}

Reader.propTypes = {};

export default Reader;
