// Dependencies
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router-dom";
import { userAPI, grAPI } from "../../api";
import { httpCallables } from "../../firebase";
import { useQuery } from "../../hooks";
import useCreatePortal from "../../hooks/useCreatePortal";

// Redux
import { useSelector, useDispatch } from "react-redux";
import { taskViewed } from "../../redux/userSlice";
import { selectCourseByTextId } from "../../redux/coursesSlice";
import { setActiveGrTaskId, selectSubmission } from "../../redux/tasksSlice";

// Components
import SubmitTaskModal from "../Tasks/TaskAnswer/SubmitTaskModal";
import MyDefaultPanel from "./MyDefaultPanel";

import makeStyles from "@mui/styles/makeStyles";
import { Box, Button } from "@mui/material";

// Styles
const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.sidePanel,
    display: "flex",
    flexDirection: "column"
  },
  ripple: {
    width: "20px",
    height: "20px"
  }
}));

export default function PocBar({ color = "secondary", onAnswerCardCopy }) {
  //Hooks
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const { submission_id } = useQuery();

  const ActionButtonPortal = useCreatePortal(
    document && document.getElementById("global-action-btn")
  );

  // Redux state
  const selectedTextId = useSelector(state => state.texts.selectedTextId);
  const stage = useSelector(state => state.gr.stage);
  const _taskViewed = useSelector(taskViewed);
  const activeGrTaskId = useSelector(state => state.tasks.activeGrTaskId);
  const course = useSelector(selectCourseByTextId);

  const submission = useSelector(state =>
    selectSubmission(state, Number(submission_id))
  );

  // Ephemeral state
  const [dialogOpen, setDialogOpen] = useState(false);

  //Deravied state
  const locked = submission?.status === "Submitted";
  const isTeacher = course.course_role === "Teacher";
  const isTask = submission_id || activeGrTaskId;
  useEffect(() => {
    if (!_taskViewed && activeGrTaskId) {
      userAPI.updateViewedTasks(activeGrTaskId);
      grAPI.updateGrState({ grStage: 0 });
    }
  }, [_taskViewed, activeGrTaskId]); // eslint-disable-line

  return (
    <>
      {!locked && !isTeacher && isTask ? (
        <>
          <ActionButtonPortal>
            <Button
              onClick={() => setDialogOpen(true)}
              data-test-id="submit-task-button"
              size="small"
            >
              <FormattedMessage
                id="task.submission.submit"
                defaultMessage="Submit"
              />
            </Button>
          </ActionButtonPortal>
          <SubmitTaskModal
            modalVisible={dialogOpen}
            setModalVisible={setDialogOpen}
            afterSubmit={async () => {
              dispatch(setActiveGrTaskId(null));
              await httpCallables.taskFunctions({
                func_name: "readGr",
                text_id: selectedTextId,
                course_id: course.id
              });
              history.push(`/tasks?course_id=${course.id}`);
            }}
          />
        </>
      ) : null}
      <Box className={classes.root} data-test-id="poc-bar">
        <MyDefaultPanel
          value={stage}
          index={stage}
          color={color}
          locked={locked}
          onAnswerCardCopy={onAnswerCardCopy}
        />
      </Box>
    </>
  );
}

PocBar.propTypes = {
  color: PropTypes.oneOf(["secondary", "primary"]),
  onAnswerCardCopy: PropTypes.func.isRequired
};
