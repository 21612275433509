// Dependencies
import React, { useState, useEffect, useRef, useCallback } from "react";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";
import { firebaseApp } from "../../../firebase";
import { useQuery, useResizeObserver } from "../../../hooks";
import {
  removeAllAnotationsOfType,
  getHighlightColor,
  mergeOverlappingHighlightsBySelection,
  clearSelection,
  getEpubContentInRange,
  fetchAllUserHighlightsPerText
} from "../utils";
import { interactionsAPI } from "../../../api";
import { useRendition } from "../../../RenditionContext";
import {
  ANNOTATION_TYPES,
  COMMENT_PANEL_VIEW,
  INTERACTION_SUBTYPES,
  INTERACTION_TYPES
} from "../../../consts";

// Redux dependencies
import { useSelector, useDispatch } from "react-redux";
import { selectTask, selectSubmission } from "../../../redux/tasksSlice";
import {
  isPdfSelector,
  selectText,
  setTextUrl
} from "../../../redux/textsSlice";
import {
  selectedQuestionHighlights,
  selectCurrentInteraction,
  selectGrStepOneHighlights,
  selectCurrentTaskHighlights,
  selectReaderHighlights,
  setSelectedInteractionId
} from "../../../redux/interactionsSlice";
import { selectCourseByTextId } from "../../../redux/coursesSlice";
import {
  updateHighlights,
  updateSq3r
} from "../../../redux/firebaseMiddleware";
import {
  closeAnnotatorBar,
  toggleAnnotatorBar
} from "../../../redux/highlightSlice";
import { setSelectedQuestion } from "../../../redux/grSlice";
import { selectIsComments } from "../../../redux/firestoreSelectors";
import {
  selectThread,
  setCommentPanelState,
  setNewCommentObject
} from "../../../redux/realtimeInteractionsSlice";

// Components
import EpubView from "../EpubView/EpubView";
import ReactReaderActions from "../../annotations/ReactReaderActions";
import useOnClickOutside from "../../../hooks/useOnClickOutside";

// Material UI
import { Box } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useFirestoreConnect } from "react-redux-firebase";
import { setCustomCursor } from "../../../redux/layoutSlice";

// Styles
const useStyles = makeStyles(theme => ({
  container: {
    height: "100%",
    position: "relative"
  }
}));
function ReactReader({
  minimalBar,
  readOnly,
  disableInteractions = false,
  isVisible = false
}) {
  const { submission_id } = useQuery();

  //Hooks
  const classes = useStyles();
  const dispatch = useDispatch();
  const intl = useIntl();
  const containerRef = useRef(null);
  const highlightsRef = useRef([]);
  const { rendition } = useRendition();
  const { width } = useResizeObserver(containerRef);

  //Redux State
  const submission = useSelector(state =>
    selectSubmission(state, Number(submission_id))
  );
  const user_id = useSelector(state => state.firebase.auth.uid);

  const task = useSelector(state => selectTask(state, submission.task_id));
  const highlightColor = useSelector(
    state => state.readerActions.persistentActionState.highlightColor
  );
  const isCommentsInSecondarySidebar = useSelector(state =>
    selectIsComments(state)
  );
  const readerHighlights = useSelector(selectReaderHighlights);
  const grStepOneHighlights = useSelector(selectGrStepOneHighlights);
  const questionHighlights = useSelector(selectedQuestionHighlights);
  const taskHighlights = useSelector(selectCurrentTaskHighlights);
  const socialHighlights = useSelector(selectThread);

  const action = useSelector(
    state => state.readerActions.persistentActionState.actionBar
  );
  const darkMode = useSelector(state => state.user.userProfile.darkMode);
  const annotatorMode = useSelector(
    state => state.readerActions.persistentActionState.annotatorMode
  );
  const grMode = useSelector(state => state.gr.mode);
  const stage = useSelector(state => state.gr.stage);
  const showHighlights = useSelector(
    state => state.readerActions.persistentActionState.showHighlights
  );
  const showAllHighlights = useSelector(
    state => state.readerActions.persistentActionState.showAllHighlights
  );

  const fontSizeValue = useSelector(state => state.user.userProfile.fontSize);
  const fontSize = useSelector(state => state.user.fontSizeOptions);
  const shownLocation = useSelector(state => {
    return state.readerActions.shownLocation;
  });
  const selectedTextId = useSelector(state => {
    return state.texts.selectedTextId;
  });
  const text = useSelector(state => selectText(state, selectedTextId));
  const selectedCourse = useSelector(selectCourseByTextId);

  const selectedQuestion = useSelector(selectCurrentInteraction);
  const questions = useSelector(state => state.interactions.questions);

  const isAnnotatorBarOpen = useSelector(
    state => state.highlighter.isAnnotatorBarOpen
  );
  const isPdf = useSelector(isPdfSelector);
  const textStartPosition = useSelector(state => state.reader.startPosition);
  const textEndPosition = useSelector(state => state.reader.endPosition);

  useFirestoreConnect([
    {
      collection: "textLocations",
      doc: `${user_id}`,
      subcollections: [{ collection: "texts" }],
      storeAs: "textLocations"
    }
  ]);
  const lastLocation = useSelector(
    ({ firestore: { data } }) =>
      data.textLocations && data.textLocations[text.id]
  );
  //Ephemeral State
  const [underlinedElement, setUnderlinedElement] = useState(null); // this is the under line that is displayd after clicking show in text

  const [highlights, setHighlights] = useState([]);
  const [underlines, setUnderlines] = useState([]);

  // Derived State
  const isTask = action === "task";
  const isGuidedReading = task.task_type === "guidedReading";
  const isSelectedQuestion = Boolean(selectedQuestion.id);
  //Behavior

  useEffect(() => {
    if (!text.id) return;
    //TODO: check if we can also skip this fetch if there is selectedText.url
    firebaseApp
      .storage()
      .ref("courseTexts/" + text.course_id)
      .child(text.file_url)
      .getDownloadURL()
      .then(url => {
        dispatch(setTextUrl({ url, text_id: text.id }));
      });
  }, [dispatch, text.course_id, text.file_url, text.id]);

  async function setUsersHighlightsPerText() {
    let userInteractions = await fetchAllUserHighlightsPerText(text.id);
    if (taskHighlights && taskHighlights.length) {
      userInteractions = userInteractions.concat(taskHighlights);
    }
    userInteractions = userInteractions.filter((obj, index, self) => {
      return index === self.findIndex(t => t.cfi === obj.cfi);
    });
    if (isCommentsInSecondarySidebar)
      userInteractions = userInteractions.filter(
        obj =>
          obj.interaction_type !== "COMMENT" &&
          obj.interaction_type !== "CONTAINER"
      );
    if (userInteractions.length) setHighlights(userInteractions);
    else return;
  }

  // Thi`s effect sets the highlight
  useEffect(() => {
    switch (true) {
      case showAllHighlights:
        setUsersHighlightsPerText();
        break;
      case action === "" && showHighlights && !showAllHighlights:
        setHighlights(readerHighlights);
        break;
      case isTask && Boolean(selectedQuestion?.id) && !showAllHighlights:
      case action === "poc" &&
        stage > 0 &&
        Boolean(selectedQuestion?.id) &&
        !showAllHighlights:
        setHighlights(questionHighlights);
        break;
      case action === "poc" && stage === 0 && !showAllHighlights:
        setHighlights(grStepOneHighlights);
        break;
      case isTask && !Boolean(selectedQuestion?.id) && !showAllHighlights:
        setHighlights([]);
        break;

      default:
        setHighlights([]);
    }
    /* eslint-disable */
  }, [
    JSON.stringify(readerHighlights),
    JSON.stringify(questionHighlights),
    JSON.stringify(grStepOneHighlights),
    JSON.stringify(taskHighlights),
    JSON.stringify(socialHighlights),
    action,
    showAllHighlights,
    selectedQuestion,
    stage,
    isTask,
    showHighlights
  ]);
  /* eslint-enable */
  useEffect(() => {
    if (!questions.length) return;
    // This part auto selects first question to enable direct highlighting in GR task
    if (isGuidedReading && !isSelectedQuestion && questions.length)
      dispatch(setSelectedInteractionId(questions[0].id));
    dispatch(setSelectedQuestion(questions[0].id));
  }, []);

  // This effect sets the undelines
  useEffect(() => {
    switch (true) {
      case action === "poc" && stage > 0:
        setUnderlines(grStepOneHighlights);
        break;
      case action === "poc" && stage === 0:
        setUnderlines([]);
        break;
      default:
        setUnderlines([]);
    }
    /* eslint-disable */
  }, [JSON.stringify(grStepOneHighlights), action, stage]);

  useEffect(() => {
    highlightsRef.current = highlights;
  }, [JSON.stringify(highlights)]);

  useEffect(() => {
    /** update ref of higlights for updates state to be used in
     * a callback function for a rendition event */
    if (!highlights.length) return;
    highlightsRef.current = highlights;
    const higlightFound = highlights.find(
      highlight => highlight.cfi === underlinedElement
    );
    /** Clear underline if highlight was deleted   */
    if (!higlightFound && rendition) {
      rendition.annotations.remove(underlinedElement, "underline");
      setUnderlinedElement(null);
    }
  }, [JSON.stringify(highlights)]); // eslint-disable-line

  useEffect(() => {
    rendition && rendition.themes.default(fontSize[fontSizeValue]);
  }, [fontSize, fontSizeValue, rendition]);

  useEffect(() => {
    const shownCfi = shownLocation?.cfi;
    if (!shownCfi) return;
    rendition.annotations.remove(underlinedElement, "underline");
    setUnderlinedElement(shownCfi);
  }, [shownLocation?.cfi]); // eslint-disable-line

  useEffect(() => {
    if (underlinedElement) {
      rendition.annotations.underline(
        underlinedElement,
        {},
        () => {},
        ANNOTATION_TYPES.UNDERLINE.toLowerCase(),
        {
          stroke: "none",
          "z-index": -1
        }
      );
    }
  }, [underlinedElement]); //eslint-disable-line

  useEffect(() => {
    if (!rendition || !underlines) return;

    removeAllAnotationsOfType(rendition, "underline");
    // render underlines from redux
    underlines.forEach(underline => {
      const cfi = underline.cfi;

      rendition.annotations.add(
        "underline",
        cfi,
        { id: underline.id },
        () => {},
        ANNOTATION_TYPES.UNDERLINE.toLowerCase(),
        {
          stroke: "none",
          "z-index": 10,
          "fill-opacity": 0.8,
          fill: "#333333"
        }
      );
    });
  }, [rendition, underlines]);
  useEffect(() => {
    if (!rendition || !highlights) return;

    if (!isCommentsInSecondarySidebar)
      removeAllAnotationsOfType(rendition, ANNOTATION_TYPES.THREAD);
  }, [isCommentsInSecondarySidebar]);

  useEffect(() => {
    if (!rendition || !highlights) return;
    removeAllAnotationsOfType(rendition, ANNOTATION_TYPES.HIGHLIGHT);
    // render highlights from redux
    highlights.forEach(highlight => {
      const cfi = highlight.cfi;
      const color = highlight.color;
      rendition.annotations.add(
        ANNOTATION_TYPES.HIGHLIGHT,
        cfi,
        { id: highlight.id },
        () => {},
        ANNOTATION_TYPES.HIGHLIGHT.toLowerCase(),
        {
          "z-index": 20,
          "mix-blend-mode": "multiply",
          "fill-opacity": 0.8,
          fill: getHighlightColor(color, darkMode)
        }
      );
    });
  }, [darkMode, highlights, rendition]);

  useEffect(() => {
    if (rendition && Object.hasOwn(rendition, "View")) {
      rendition.resize();
    }
  }, [width]);

  function onTextSelected(params) {
    const { selection, clientRect } = params;

    let { newHighlight, mergedHighlights } =
      mergeOverlappingHighlightsBySelection(
        highlights,
        false,
        selection,
        rendition
      );

    if (disableInteractions) return;

    switch (true) {
      /** Do Nothing */
      case readOnly:
      case (isGuidedReading && grMode === "full" && stage === 2) ||
        (grMode === "light" && stage === 1 && !selectedQuestion):
        return;
      case annotatorMode === "comment": {
        newHighlight = Object.assign(newHighlight, {
          interaction_type: INTERACTION_TYPES.CONTAINER,
          interaction_subtype: INTERACTION_SUBTYPES.COMMENT
        });
        dispatch(setCustomCursor(false));
        dispatch(setCommentPanelState(COMMENT_PANEL_VIEW.NEW_COMMENT));
        dispatch(setNewCommentObject(newHighlight));
        break;
      }
      /** Update answer quotes */
      case isTask && isGuidedReading && isSelectedQuestion:
      case isTask && task.task_type === "standard" && isSelectedQuestion: {
        const foundOrder = questionHighlights.find(el => el.order);
        let order = foundOrder ? Number(questionHighlights.length) : null;
        newHighlight = Object.assign(newHighlight, {
          interaction_type: INTERACTION_TYPES.ANSWER,
          interaction_subtype: INTERACTION_SUBTYPES.QUOTE,
          interaction_id: selectedQuestion.id,
          order: order,
          task_id: task.id,
          submission_id: submission.id
        });
        addHighlight(newHighlight, mergedHighlights);

        break;
      }

      case action === "" && annotatorMode === "highlight": {
        newHighlight = Object.assign(newHighlight, {
          interaction_type: INTERACTION_TYPES.READER,
          interaction_subtype: INTERACTION_SUBTYPES.QUOTE
        });
        addHighlight(newHighlight, mergedHighlights);
        break;
      }

      case action === "poc" && annotatorMode === "poc": {
        if (
          ((grMode === "full" && stage === 2) ||
            (grMode === "light" && stage === 1)) &&
          !selectedQuestion.id
        )
          return;
        if (
          ((grMode === "full" && stage === 2) ||
            (grMode === "light" && stage === 1)) &&
          selectedQuestion.id
        ) {
          const foundOrder = questionHighlights.find(el => el.order);
          let order = foundOrder ? Number(questionHighlights.length) : null;
          newHighlight = Object.assign(newHighlight, {
            interaction_type: INTERACTION_TYPES.ANSWER,
            interaction_subtype: INTERACTION_SUBTYPES.QUOTE,
            interaction_id: selectedQuestion.id,
            order: order
          });
          addHighlight(newHighlight, mergedHighlights);
        } else if (stage === 0) {
          const foundOrder = grStepOneHighlights.find(el => el.order);
          let order = foundOrder ? Number(grStepOneHighlights.length) : null;
          newHighlight = Object.assign(newHighlight, {
            interaction_type: INTERACTION_TYPES.ANSWER,
            interaction_subtype: INTERACTION_SUBTYPES.QUOTE,
            order: order
          });
          addHighlight(newHighlight, mergedHighlights);
        } else {
          if (newHighlight?.content) {
            dispatch(
              toggleAnnotatorBar({
                clientRectangle: clientRect,
                selectedText: newHighlight
              })
            );
          }
        }
        break;
      }

      case !annotatorMode: {
        if (newHighlight.content) {
          dispatch(
            toggleAnnotatorBar({
              clientRectangle: clientRect,
              selectedText: newHighlight
            })
          );
        }
        break;
      }

      default:
        break;
    }
  }

  function addHighlight(highlight, mergedHighlights = []) {
    let textInView = "";
    if (!isPdf && textStartPosition && textEndPosition) {
      textInView = getEpubContentInRange(
        rendition,
        textStartPosition,
        textEndPosition
      );
    } else textInView = "";
    highlight = { ...highlight, textInView };

    interactionsAPI.createHighlight(
      {
        ...highlight,
        color: highlightColor
      },
      selectedTextId,
      selectedCourse.id
    );
    deleteHighlights(mergedHighlights);
    clearSelection(rendition);
  }

  const addHighlightToBook = (highlight, color, shouldDelete = false) => {
    if (annotatorMode === "poc") {
      let updatedGrHighlights = [
        ...highlights.filter(el => el.cfi !== highlight.cfi),
        {
          ...highlight,
          source: "poc",
          color: highlightColor
        }
      ];
      dispatch(
        updateSq3r({
          text_id: selectedTextId,
          questions: questions,
          highlights: updatedGrHighlights
        })
      );
    } else {
      let adjustedHighlightColor = color ? color : highlightColor;

      let adjustedHighlight = {
        ...highlight,
        source: "highlight",
        color: adjustedHighlightColor
      };
      let highlightColl = highlights.filter(
        el => el.source === "highlight" && el.cfi !== highlight.cfi
      );
      if (!shouldDelete) {
        highlightColl.push(adjustedHighlight);
      }

      dispatch(
        updateHighlights({ text_id: selectedTextId, highlights: highlightColl })
      );
    }
  };

  const setHighlightColorCb = (highlight, color) => {
    if (highlight.source === "poc" && annotatorMode === "poc") {
      let updatedGrHighlights = [
        ...highlights.filter(el => el.cfi !== highlight.cfi),
        {
          ...highlight,
          source: "poc",
          color: color
        }
      ];
      dispatch(
        updateSq3r({
          text_id: selectedTextId,
          questions: questions,
          highlights: updatedGrHighlights
        })
      );
    } else if (highlight.source === "grQuestion" && annotatorMode === "poc") {
      let item = selectedQuestion.answers.filter(
        a => a.cfi === highlight.cfi
      )[0];
      let answers = [
        ...selectedQuestion.answers.filter(a => a.cfi !== highlight.cfi),
        { ...item, color: color }
      ];
      let question = { ...selectedQuestion, answers: answers };

      let updatedQuestions = questions.map(q => {
        if (q.id === selectedQuestion?.id) {
          return question;
        } else return q;
      });

      dispatch(
        updateSq3r({
          text_id: selectedTextId,
          questions: updatedQuestions,
          highlights
        })
      );
    } else addHighlightToBook(highlight, color);
  };

  function deleteHighlights(oldIntersectedHighlights) {
    for (const el of oldIntersectedHighlights) {
      interactionsAPI.deleteHighlight(el, false);
    }
  }

  return (
    <>
      <Box ref={containerRef} className={classes.container}>
        <ReactReaderActions
          id="reactReaderActions"
          minimal={minimalBar}
          // highlightFunc={addHighlightToBook}
          addHighlight={addHighlight}
          canComment={true}
          setHighlightColor={setHighlightColorCb}
          deleteHighlight={interactionsAPI.deleteHighlight}
        >
          <EpubView
            fullSize={true}
            fontSize={fontSize[fontSizeValue]}
            epubOptions={{ flow: "scrolled-doc" }}
            darkMode={darkMode}
            readOnly={readOnly}
            highlights={highlights}
            handleTextSelected={onTextSelected}
            textDirection={text.text_language === "he" ? "rtl" : "ltr"}
            bodyClassName="gr"
            url={text.url}
            location={lastLocation?.position}
            defaultLocation={text.file_location}
            text_id={text.id}
          />
        </ReactReaderActions>
      </Box>
    </>
  );
}

ReactReader.propTypes = {
  isVisible: PropTypes.bool
};

export default ReactReader;
