import { httpCallables, auth } from "../firebase";
import store from "../redux/store";
import { v4 as uuid } from "uuid";
import {
  setInteractionsOrder,
  deleteQuestion,
  createAnswer,
  deleteAnswer,
  addHighlight,
  deleteHighlight,
  createQuestion
} from "../redux/interactionsSlice";
import { addSnackbarItem } from "../redux/snackbarSlice";
import { captureException } from "../utils/errorHandlers";
import { INTERACTION_TYPES, INTERACTION_SUBTYPES } from "../consts";

const interactionsAPI = {
  createQuestion: async function (question, text_id, courseId, options = {}) {
    const _options = Object.assign(
      {
        highlights: [],
        summary: "",
        afterReduxCallback: () => {}
      },
      options
    );
    const id = uuid();
    store.dispatch(
      createQuestion({
        id,
        interaction_type: INTERACTION_TYPES.QUESTION,
        interaction_subtype: INTERACTION_SUBTYPES.GUIDED_READING,
        ...question
      })
    );
    _options.afterReduxCallback(id);
    try {
      const questionForDataBase = { ...question };
      delete questionForDataBase.user_type;

      await httpCallables.interactionFunctions({
        func_name: "createQuestion",
        id,
        ...questionForDataBase,
        text_id,
        course_id: courseId
      });
      this.createAnswer(_options.summary);
      _options.highlights.forEach(h => {
        this.createHighlight(h, text_id, courseId);
      });
      return { id };
    } catch (err) {
      captureException(
        err,
        `Failed to create guided reading question. user ${auth.currentUser.uid}`
      );
      store.dispatch(deleteQuestion(id));
      return false;
    }
  },
  deleteQuestion: async function (question, highlights, summary) {
    try {
      store.dispatch(deleteQuestion(question.id));
      await httpCallables.interactionFunctions({
        func_name: "deleteInteraction",
        id: question.id
      });
      store.dispatch(
        addSnackbarItem({
          question,
          highlights,
          summary,
          actions: [
            {
              intlId: "undo",
              intlDefaultMsg: "undo",
              callBack: "createQuestion"
            }
          ],
          intlId: "delete.question",
          intlDefaultMessage: "Question deleted",
          id: uuid()
        })
      );
      return true;
    } catch (err) {
      // Rollback
      store.dispatch(createQuestion(question));
      store.dispatch(
        addSnackbarItem({
          intlId: "errorMessgaes.deleteQuestion",
          intlDefaultMessage: "Problem deleting question",
          id: uuid()
        })
      );
      captureException(
        err,
        `Failed to delete guided reading question. user ${auth.currentUser.uid}, questionId:${question.id}`
      );
      return false;
    }
  },
  createHighlight: async function (highlight, text_id, courseId) {
    const id = uuid();
    try {
      store.dispatch(
        addHighlight({
          id,
          ...highlight,
          text_id: text_id,
          course_id: courseId,
          interaction_type: highlight.interaction_type,
          interaction_subtype: highlight.interaction_subtype
        })
      );
      const highlightForDB = { ...highlight };
      httpCallables.interactionFunctions({
        func_name: "createInteraction",
        id,
        ...highlightForDB,
        text_id: text_id,
        interaction_type: highlight.interaction_type,
        interaction_subtype: highlight.interaction_subtype,
        course_id: courseId
      });
      return true;
    } catch (err) {
      captureException(
        err,
        `Failed to create guided reading highlight. user ${auth.currentUser.uid}`
      );

      //TODO: alert user
      store.dispatch(deleteHighlight(id)); // Rollback
      return false;
    }
  },
  deleteHighlight: async function (highlight, showSnackbar = true) {
    try {
      store.dispatch(deleteHighlight(highlight.id));
      await httpCallables.interactionFunctions({
        func_name: "deleteInteraction",
        id: highlight.id
      });
      showSnackbar &&
        store.dispatch(
          addSnackbarItem({
            actions: [
              {
                intlId: "undo",
                intlDefaultMsg: "undo",
                callBack: "createHighlight"
              }
            ],
            highlight,
            intlId: "delete.card",
            intlDefaultMessage: "Card deleted",
            id: uuid()
          })
        );
      return true;
    } catch (err) {
      // Rollback
      showSnackbar &&
        store.dispatch(
          addSnackbarItem({
            intlId: "error.deleteFaild",
            intlDefaultMessage:
              "There was a problem deleting the card, please try again",
            id: uuid()
          })
        );
      store.dispatch(addHighlight(highlight));
      captureException(
        err,
        `Failed to delete guided reading highlight. user ${auth.currentUser.uid}, questionId:${highlight.id}`
      );
      return false;
    }
  },
  createAnswer: async function (answer) {
    if (!answer) return;
    const id = uuid();
    try {
      store.dispatch(createAnswer({ id, ...answer }));
      const answerForDB = {
        func_name: "createAnswerComment",
        id,
        ...answer
      };
      return httpCallables.interactionFunctions(answerForDB);
    } catch (err) {
      store.dispatch(deleteAnswer(answer));
      captureException(
        err,
        `Failed to create guided reading answer. user ${auth.currentUser.uid}`
      );
    }
  },
  updateQuestionOrder: async function (order, rollback) {
    try {
      store.dispatch(
        setInteractionsOrder({ order, interactionType: "questions" })
      );
      return httpCallables.interactionFunctions({
        order,
        func_name: "updateInteractionsOrder"
      });
    } catch (err) {
      captureException(
        err,
        `Failed to update guided reading questions order. user ${auth.currentUser.uid}`
      );
      if (rollback)
        store.dispatch(
          setInteractionsOrder({ rollback, interactionType: "questions" })
        );
    }
  },
  updateHighlightsOrder: async function (order, rollback) {
    try {
      store.dispatch(
        setInteractionsOrder({ order, interactionType: "highlights" })
      );
      httpCallables.interactionFunctions({
        func_name: "updateInteractionsOrder",
        order
      });
    } catch (err) {
      captureException(
        err,
        `Failed to update higlihgts order. user ${auth.currentUser.uid}`
      );
      if (rollback)
        store.dispatch(
          setInteractionsOrder({ rollback, interactionType: "highlights" })
        );
    }
  },
  updateInteractionsOrder: async function (interactionType, order, rollback) {
    try {
      store.dispatch(setInteractionsOrder({ type: interactionType, order }));
      httpCallables.interactionFunctions({
        func_name: "updateInteractionsOrder",
        order
      });
    } catch (err) {
      captureException(
        err,
        `Failed to update interactions order. user ${auth.currentUser.uid}`
      );
      if (rollback) store.dispatch(setInteractionsOrder(rollback));
    }
  }
};

export default interactionsAPI;
