// Dependancies
import React from "react";
import PropTypes from "prop-types";

// Redux

// Components

// Mui
import { Box, Divider } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import WidgetHeader from "../../../SharedComponents/WidgetHeader";

// styles
const useStyles = makeStyles(theme => ({
  container: {
    position: "relative",
    height: "100%",
    display: "flex",
    flexDirection: "column"
  },
  section: {
    paddingBlockStart: theme.spacing(3),
    paddingBlockEnd: theme.spacing(5),
    paddingInline: theme.spacing(2)
  },
  sectionHeader: {
    marginBlockEnd: theme.spacing(1),
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "20px",
    lineHeight: "32px",
    textAlign: "center",
    letterSpacing: "0.15px"
  },
  sectionContent: {
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "72px",
    lineHeight: "86px",
    textAlign: "center",
    letterSpacing: "-1.425px"
  }
}));

function AvaragesWidget({ studentName, classAvarage, studentAvarage }) {
  // Hooks
  const classes = useStyles();

  // Ephemeral State

  // Derived State

  // Behavior

  if (studentAvarage <= 0 && classAvarage <= 0) return null;
  return (
    <Box className={classes.container}>
      <WidgetHeader title="Average" />
      <Box className={classes.section}>
        <Box className={classes.sectionHeader}> {studentName} avarage </Box>
        <Box className={classes.sectionContent}> {studentAvarage} </Box>
      </Box>

      <Divider />

      <Box className={classes.section}>
        <Box className={classes.sectionHeader}>Class avarage</Box>
        <Box className={classes.sectionContent}> {classAvarage} </Box>
      </Box>
    </Box>
  );
}

AvaragesWidget.propTypes = {
  studentName: PropTypes.string.isRequired,
  classAvarage: PropTypes.number,
  studentAvarage: PropTypes.number
};

export default AvaragesWidget;
