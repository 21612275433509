import ePub from "epubjs";

const EpubCFI = new ePub.CFI();

export const findIndexToInsert = (collection, newValue) => {
  let insertAt = collection.length;
  for (let i = 0; i < collection.length; i++) {
    if (
      collection[i].cfi &&
      EpubCFI.compare(collection[i].cfi, newValue.cfi) > 0
    ) {
      insertAt = i;
      break;
    }
  }
  return insertAt;
};
