import { EditorState, SelectionState } from "draft-js";

// Dependencies
import { convertToRaw } from "draft-js";

// Utils

export function generateEditorOutput(editorState) {
  // Input: a draftJS editorState obj
  // Output: an object with the current editor content as plain and rich text
  const richText = convertToRaw(editorState.getCurrentContent());
  const plainText = editorState.getCurrentContent().getPlainText(" ");
  const wordCount = getWordCount(editorState);
  return { plainText, richText, wordCount };
}

export function moveFocusToEnd(editorState) {
  const contentState = editorState.getCurrentContent();
  const lastBlock = contentState.getLastBlock();
  const lastKey = lastBlock.getKey();
  const length = lastBlock.getLength();

  return EditorState.forceSelection(
    editorState,
    new SelectionState({
      anchorKey: lastKey,
      anchorOffset: length,
      focusKey: lastKey,
      focusOffset: length,
      hasFocus: true
    })
  );
}

export function getWordCount(editorState) {
  const plainText = editorState.getCurrentContent().getPlainText("").trim();
  const wordsArray = plainText.trim().split(/\s+/);
  return plainText ? wordsArray.length : 0;
}
