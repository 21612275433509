import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  linkType: "",
  selected: false,
  selectionContext: "",
  currentLink: "",
  linkTarget: "",
  linkContent: null
};

export const linkSlice = createSlice({
  name: "linking",
  initialState,
  extraReducers: {
    "task/setQuestionIndex": (state, value) => {
      state.selectionContext = "";
      state.linkType = "";
      state.currentLink = "";
      state.selectionContext = "";
      state.linkTarget = "";
      state.linkContent = null;
    },
    "user/logout": () => initialState
  },
  reducers: {
    setSelection: (state, value) => {
      state.selected = value.payload.selected;
      state.selectionContext = value.payload.selectionContext;
    },
    setLinkType: (state, value) => {
      state.linkType = value.payload;
    },
    setLinkContent: (state, value) => {
      state.linkContent = value.payload;
    },
    setLinkTarget: (state, value) => {
      state.linkTarget = value.payload;
    },
    setCurrentLink: (state, value) => {
      state.currentLink = value.payload;
    },
    resetLink: (state, value) => {
      state.selectionContext = "";
      state.currentLink = 0;
      state.linkType = "";
      state.selectionContext = "";
      state.linkTarget = "";
      state.linkContent = null;
    }
  }
});

export const {
  setSelection,
  setLinkContent,
  setCurrentLink,
  setLinkTarget,
  resetLink,
  setLinkType
} = linkSlice.actions;

export default linkSlice.reducer;
