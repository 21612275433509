import { useEffect, useReducer } from "react";
import { useResizeObserver } from "./index";

// The dimensions are initialized, stored, and updated with useReducer
function init(presets) {
  const defaults = {
    // default values
    width: 0,
    height: 0,
    marginTop: 40,
    marginRight: 24,
    marginBottom: 40,
    marginLeft: 72,
    boundedHeight: 0,
    boundedWidth: 0,
    ...presets
  };

  return {
    ...defaults,
    boundedHeight: Math.max(
      defaults.height - defaults.marginTop - defaults.marginBottom,
      0
    ),
    boundedWidth: Math.max(
      defaults.width - defaults.marginLeft - defaults.marginRight,
      0
    )
  };
}

function reducer(state, action) {
  const marginInline = state.marginLeft + state.marginRight;
  const marginBlock = state.marginTop + state.marginBottom;
  switch (action.type) {
    case "setHeight":
      const { height } = action;
      return {
        ...state,
        height,
        boundedHeight: Math.max(height - marginBlock, 0)
      };
    case "setWidth":
      const { width } = action;
      return {
        ...state,
        width,
        boundedWidth: Math.max(width - marginInline, 0)
      };
    default:
      throw new Error(
        `dimensions reducer in Chart.js recived a wrong action type ${action.type}`
      );
  }
}

export default function useChartDimensions(presets = {}) {
  // Ephemeral state
  const { ref, width, height } = useResizeObserver();
  const [dimensions, dispatch] = useReducer(reducer, presets, init);

  // width
  useEffect(() => {
    if (width && width !== dimensions.width) {
      dispatch({ type: "setWidth", width });
    }
  }, [dimensions.width, width]);

  // width
  useEffect(() => {
    // passed in height overwrites the resizeObserver if...
    // ... this allows for an expandable chart
    if (presets.height) {
      presets.height !== dimensions.height &&
        dispatch({
          type: "setHeight",
          height: presets.height
        });
    } else if (height && height !== dimensions.height) {
      dispatch({ type: "setHeight", height });
    }
  }, [dimensions.height, height, presets.height]);

  return { ref, dimensions };
}
