// Dependencies
import React, { useState, useRef } from "react";
import { FormattedMessage } from "react-intl";
import { useDrag } from "react-dnd";
import PropTypes from "prop-types";
import { useRendition } from "../../../../RenditionContext";
import { useQuery } from "../../../../hooks";
import { standardTaskAPI, interactionsAPI } from "../../../../api";

// Redux dependencies
import { useSelector } from "react-redux";
import { selectCurrentText } from "../../../../redux/textsSlice";
import { selectSubmission } from "../../../../redux/tasksSlice";

//Components
import CitationDialog from "../../../Tasks/CreateTask/CitationDialog";
import { showInText as showInTextFunction } from "../../../../utils/showInTextHelper";
import { appActions } from "../../../../consts";
import {
  InteractionCardEditableHeader,
  InteractionCardContent,
  InteractionCardFooter,
  InteractionCard,
  InteractionCardAction
} from "../../../SharedComponents/card";

// Material UI
import makeStyles from "@mui/styles/makeStyles";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";

const useStyles = makeStyles(theme => ({}));

function QuestionsAccordionCard({
  card,
  qId,
  cardIndex,
  questionIndex,
  disableActions = false,
  showInText
}) {
  // Hooks
  const { submission_id } = useQuery();
  const { rendition } = useRendition();
  const titleRef = useRef(null);

  // Redux
  const submission = useSelector(state =>
    selectSubmission(state, Number(submission_id))
  );
  const user_id = useSelector(state => state.user.auth.uid);
  const highlightViewOn = useSelector(state => state.task.highlightViewOn);
  const selectedText = useSelector(selectCurrentText);

  //Ephemeral State
  const [showCitation, setShowCitation] = useState(false);

  // Derived State

  //Behavior

  const [{ isDragging }, drag] = useDrag({
    item: {
      type: "QUESTION_ACCORION_CARD",
      cardIndex: cardIndex,
      questionIndex: questionIndex
    },
    collect: monitor => ({
      isDragging: monitor.isDragging()
    }),
    canDrag: !disableActions
  });

  const showInTextHandler = () => {
    const cb = determineShowInTextCB(card);
    showInTextFunction(appActions.SHOULD_LOG, card, user_id, rendition, cb);
  };

  const determineShowInTextCB = card => {
    if (showInText === "dialog" || highlightViewOn)
      return () => {
        setShowCitation(card);
      };
    if (showInText === "inline")
      return () => {
        standardTaskAPI.updateSelectedQuestionId(qId, submission.task_id);
      };
  };

  return (
    <>
      <InteractionCard
        ref={drag}
        color={"primary"}
        showInText={showInText}
        interaction={card}
        collapseContent={true}
      >
        <InteractionCardEditableHeader ref={titleRef} />
        <InteractionCardContent />
        <InteractionCardFooter>
          {/* show in text */}
          <InteractionCardAction
            onClick={() => {
              showInTextHandler(card);
            }}
            intlStringId="highlights.showInText"
            defaultMessage="Show in text"
          >
            <LocationOnOutlinedIcon />
          </InteractionCardAction>

          {/* edit title */}
          <InteractionCardAction
            disabled={disableActions}
            onClick={() => {
              titleRef.current.focus();
            }}
            intlStringId={
              !card?.title ? "highlights.addTitle" : "highlights.editTitle"
            }
            defaultMessage={!card?.title ? "Add title" : "Edit title"}
          >
            <EditOutlinedIcon />
          </InteractionCardAction>

          {/* delete highlight */}
          <InteractionCardAction
            disabled={disableActions}
            onClick={() => {
              interactionsAPI.deleteHighlight(card);
            }}
            intlStringId="highlights.delete"
            defaultMessage="Delete highlight"
          >
            <DeleteOutlineOutlinedIcon />
          </InteractionCardAction>
        </InteractionCardFooter>
      </InteractionCard>

      {showCitation && ( // This is not the best place for this dialog, I was left here in order to avoid breaking the code and should be refactored
        <CitationDialog
          color="primary"
          setOpenDialog={setShowCitation}
          citationTitleFormattedMessage={
            <FormattedMessage
              id="show.location.inText"
              defaultMessage="Show in text"
            />
          }
          url={selectedText.url}
          location={selectedText.file_location}
          highlights={[showCitation]}
          openDialog={!!showCitation}
          removeHighlight={() => {
            interactionsAPI.deleteHighlight(card);
            setShowCitation(false);
          }}
        />
      )}
    </>
  );
}

QuestionsAccordionCard.propTypes = {
  card: PropTypes.object.isRequired,
  qId: PropTypes.number.isRequired,
  cardIndex: PropTypes.number.isRequired,
  questionIndex: PropTypes.number.isRequired,
  disableActions: PropTypes.bool,
  showInText: PropTypes.oneOf(["inline", "dialog"])
};

export default QuestionsAccordionCard;
