// Dependancies
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import * as d3 from "d3";
import clsx from "clsx";
import { firebaseFunctions } from "../../../../firebase";
import { useChartDimensions } from "../../../../hooks";
import { calculateSubmissionStatus } from "../../TaskManager/utills";

// Redux

// Components
import WidgetHeader from "../../../SharedComponents/WidgetHeader";
import Chart from "../../../SharedComponents/chart/Chart";
import Axis from "../../../SharedComponents/chart/chartPrimitives/Axis";

// Mui
import { Box, useTheme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

// styles
const useStyles = makeStyles(theme => ({
  container: {
    height: 400,
    flexGrow: 1,
    position: "relative",
    display: "flex",
    flexDirection: "column"
  },
  legend: {
    display: "flex"
  },
  legendItem: {
    display: "flex",
    alignItems: "center",
    marginInlineEnd: theme.spacing(3),
    "&:last-child": {
      marginInlineEnd: 0
    }
  },
  legendIcon: {
    display: "inline-block",
    height: 16,
    width: 16,
    borderRadius: "50%",
    marginInlineEnd: theme.spacing(1)
  },
  legendIconOntime: { backgroundColor: theme.palette.secondary.light },
  legendIconLate: { backgroundColor: theme.palette.warning.light },
  legendIconMissed: { backgroundColor: theme.palette.error.light }
}));

function StudentProgressChart({ course, user, start, end }) {
  // Hooks
  const theme = useTheme();
  const classes = useStyles();
  const { ref, dimensions } = useChartDimensions({
    marginBottom: 56,
    marginLeft: 100
  });

  // Ephemeral State
  const [tasks, setTasks] = useState([]);
  const [submissions, setSubmissions] = useState([]);
  // Derived State
  const BAR_WIDTH = 24;

  // const PENDING = "pending";
  const ON_TIME = "onTime";
  const LATE = "late";
  const MISSED = "missed";

  const GREEN = theme.palette.secondary.light;
  const ORANGE = theme.palette.warning.light;
  const RED = theme.palette.error.light;

  const colors = [GREEN, ORANGE, RED];
  const statuses = [ON_TIME, LATE, MISSED];

  // D3;

  const xAccessor = d => d.id; // submission id
  const yAccessor = d => `${d.x0}-${d.x1}`; // the grade bin
  const zAccessor = d =>
    calculateSubmissionStatus(
      d.due_date,
      d.submission_date,
      getTask(tasks, d.task_id)
    );

  const binGenerator = d3
    // returns a functions that generates 10 bins from submissions grades
    .bin()
    .domain([0, 100])
    .value(d => d.grade)
    .thresholds([0, 50, 60, 70, 80, 90]);

  const bins = binGenerator(submissions);

  const xDomain = submissions.map(xAccessor);
  const xScale = d3
    .scaleBand()
    .domain(xDomain)
    .range([0, dimensions.boundedWidth])
    .padding(1); // padding equales the width of the band

  const yDomain = bins.map(yAccessor);
  const yScale = d3
    .scaleBand()
    .domain(yDomain)
    .range([dimensions.boundedHeight, 0]);

  const zScale = d3.scaleOrdinal().domain(statuses).range(colors);

  //the width/2 weirdness is
  const keyAccessorScaled = d => xScale(xAccessor(d));
  const xAccessorScaled = d => xScale(xAccessor(d)) - BAR_WIDTH / 2; // deviding to center the fixed width bars
  const yAccessorScaled = d => yScale(yAccessor(d)) + yScale.bandwidth() / 2; // deviding to center hight with tick
  const zAccessorScaled = d => zScale(zAccessor(d));
  const heightAccessor = d => dimensions.boundedHeight - yAccessorScaled(d);

  // Utils
  function formatTick(tick, submissions) {
    // returns the submission number given a sorted submissions array
    return submissions.findIndex(submission => submission.id === tick) + 1;
  }

  // Behavior

  useEffect(() => {
    let isCancelled = false;

    const taskFunctions = firebaseFunctions.httpsCallable(
      "tasks-taskFunctions"
    );

    taskFunctions({
      func_name: "readStudentCourseTasks",
      course_id: Number(course),
      student_id: user,
      start,
      end
    }).then(({ data }) => {
      const { success } = data;
      if (success && !isCancelled) {
        const { tasks, submissions } = JSON.parse(data.payload);
        // submissions are sorted in the BE
        const filteredSubmissions = submissions.filter(
          // submissions comes with related submissions (ex. peer review collaboration)
          submission => submission.owner === user
        );
        setTasks(tasks);
        setSubmissions(filteredSubmissions);
      }
    });
    return () => {
      isCancelled = true;
    };
  }, [course, end, start, user]);

  function getTask(tasks, taskId) {
    tasks.find(task => task.id === taskId);
  }

  function checkGrades(array) {
    let hasValidGrade = false;

    for (let i = 0; i < array.length; i++) {
      if (
        array[i].hasOwnProperty("grade") &&
        typeof array[i].grade === "number" &&
        Number.isInteger(array[i].grade)
      ) {
        hasValidGrade = true;
        break;
      }
    }

    if (!hasValidGrade) {
      return null;
    } else {
      return (
        <Box className={classes.container}>
          <WidgetHeader title="Student progress">
            <Box className={classes.legend}>
              <Box className={classes.legendItem}>
                <Box
                  component={"span"}
                  className={clsx(classes.legendIcon, classes.legendIconOntime)}
                />
                On time
              </Box>
              <Box className={classes.legendItem}>
                <Box
                  component={"span"}
                  className={clsx(classes.legendIcon, classes.legendIconLate)}
                />
                Late
              </Box>
              <Box className={classes.legendItem}>
                <Box
                  component={"span"}
                  className={clsx(classes.legendIcon, classes.legendIconMissed)}
                />
                Missed
              </Box>
            </Box>
          </WidgetHeader>
          <Chart ref={ref} dimensions={dimensions}>
            <Axis
              dimension="x"
              scale={xScale}
              label="Task"
              hideAxisLines
              ticks={xDomain}
              formatTick={d => formatTick(d, submissions)}
            />
            <Axis
              dimension="y"
              scale={yScale}
              label="Grade"
              ticks={yDomain}
              hideAxisLines
            />
            {bins.map(bin => {
              // each grade bin in the histogram
              return bin.map(bar => {
                // The submissions in the current grade bin
                return (
                  <rect
                    className={classes.bars}
                    key={keyAccessorScaled(bar)}
                    fill={zAccessorScaled(bar)}
                    x={xAccessorScaled(bar)}
                    y={yAccessorScaled(bin)}
                    width={BAR_WIDTH}
                    height={heightAccessor(bin)}
                  />
                );
              });
            })}
          </Chart>
        </Box>
      );
    }
  }

  return checkGrades(submissions);
}

StudentProgressChart.propTypes = {
  course: PropTypes.string.isRequired,
  user: PropTypes.string.isRequired,
  start: PropTypes.object,
  end: PropTypes.object
};

export default StudentProgressChart;
