import { useMemo } from "react";
import { useLocation } from "react-router-dom";

function useQuery() {
  const { search } = useLocation();
  const result = {};

  const searchParams = useMemo(() => new URLSearchParams(search), [search]);

  searchParams.forEach((value, key) => {
    result[key] = value;
  });

  return result;
}

export default useQuery;
