// Dependencies
import React, { useEffect } from "react";
import clsx from "clsx";
import { FEATURE_FLAGS, SECONDARY_SIDEBAR_STATE } from "../../consts";
import store from "../../redux/store";
// Redux
import { useDispatch, useSelector } from "react-redux";
import {
  setSecondarySidebarViewMode,
  suggestionsIconClicked
} from "../../redux/layoutSlice";
import { setShouldShowLocation } from "../../redux/pdfSlice";
import {
  selectIsSuggestions,
  selectIsSuggestionsInSidebar,
  selectSecondarySidebarCollapsed
} from "../../redux/firestoreSelectors";
import {
  findCurrentSuggestionIndex,
  selectCurrentQuestion,
  selectSuggestionsByQuestionId,
  setSelectedSuggestion
} from "../../redux/interactionsSlice";
// Components

// Material UI
import makeStyles from "@mui/styles/makeStyles";
import { Paper, Box, Typography, IconButton, Button } from "@mui/material";

import { TooltipWithIntl } from "../SharedComponents";
import DirectionalChevronIcon from "../SharedComponents/DirectionalChevronIcon";
import LightbulbOutlinedIcon from "@mui/icons-material/LightbulbOutlined";
import CloseIcon from "@mui/icons-material/Close";
import { isFeatureFlagEnabled } from "../../redux/epics/eMentorEpics/utils";

const useStyles = makeStyles(theme => {
  return {
    container: {
      height: "48px",
      position: "fixed",
      flexGrow: "1",
      display: "block",
      zIndex: 100
    },
    hiddenContainer: {
      display: "none"
    },
    suggestionsContent: {
      height: "100%",
      width: "100%",
      alignItems: "center",
      display: "inline-flex",
      flexFlow: "row",
      justifyContent: "space-between",
      paddingInlineStart: theme.spacing(5),
      paddingInlineEnd: theme.spacing(3)
    },
    suggestionBox: {
      display: "inline-flex",
      paddingInline: theme.spacing(1)
    },
    suggestionButtonsBox: {
      display: "flex",
      flexFlow: "row",
      height: "48px",
      width: "fit-content",
      alignItems: "center"
    },
    btnContainer: {
      marginInline: theme.spacing(0.5),
      height: "32px",
      width: "33.39px"
    },
    skipSuggestionBtn: {
      border: `1px solid ${theme.palette.primary.main}`,
      color: theme.palette.primary.main
    },
    active: { color: "#003C8F" },
    suggestionsStops: { color: theme.palette.lightText.muted },
    closeIcon: { color: theme.palette.lightText.muted },
    betaButton: {
      backgroundColor: theme.palette.background.blue,
      color: "#FFFFFF",
      marginInlineStart: theme.spacing(2),
      minWidth: "46px",
      height: "37px",
      "&:hover": {
        backgroundColor: theme.palette.background.blue
      },
      padding: 0,
      borderRadius: "8px"
    }
  };
});
const JourneyHeaderMenu = () => {
  // Hooks
  const classes = useStyles();
  const dispatch = useDispatch();

  // Redux state

  const PrimarySidebarWidth = useSelector(
    state => state.layout.primarySidebarWidth
  );

  const taskStep = useSelector(state => state.task.step);

  const currentQuestion = useSelector(selectCurrentQuestion);
  const isSuggestions = useSelector(state => selectIsSuggestions(state));
  const selectIsSuggestionsInSecondarySidebar = useSelector(state =>
    selectIsSuggestionsInSidebar(state)
  );
  const suggestions = useSelector(selectSuggestionsByQuestionId);
  const currentSuggestionIndex = useSelector(findCurrentSuggestionIndex) + 1;
  const selectedSuggestion = useSelector(
    state => state.interactions.selectedSuggestion
  );
  const isFeatureEnabled = isFeatureFlagEnabled(
    store.getState(),
    FEATURE_FLAGS.E_MENTOR_JOURNEY
  );

  // Ephemeral state
  // Variables
  const isStepHighlight = taskStep === "highlight";
  // Behavior

  useEffect(() => {
    if (
      (suggestions.length && !currentSuggestionIndex) ||
      !selectedSuggestion
    ) {
      dispatch(setSelectedSuggestion(suggestions[0]));
    }
  }, [
    dispatch,
    currentQuestion,
    currentSuggestionIndex,
    suggestions,
    selectedSuggestion
  ]);
  if (!isSuggestions || !isFeatureEnabled) return null;
  return (
    <Paper
      className={clsx(
        classes.container,
        !isStepHighlight && classes.hiddenContainer
      )}
      style={{
        width: `calc(100vw - ${PrimarySidebarWidth}px`
      }}
    >
      <Box className={classes.suggestionsContent}>
        <Box className={classes.suggestionButtonsBox}>
          <TooltipWithIntl
            className={classes.btnContainer}
            intlStringId="Suggestion.backToPrevious"
            defaultMessage="Back to previous suggestion"
            placement="bottom"
          >
            <IconButton
              onClick={() => {
                // TODO - move to previous Suggestion
                let suggestionToUpdate = {};
                if (currentSuggestionIndex === 1) {
                  suggestionToUpdate = {
                    ...suggestions[suggestions.length]
                  };
                } else if (1 < currentSuggestionIndex <= suggestions.length)
                  suggestionToUpdate = {
                    ...suggestions[currentSuggestionIndex - 2]
                  };
                suggestionToUpdate.color = "#90CAF9";
                dispatch(setSelectedSuggestion(suggestionToUpdate));
                dispatch(setShouldShowLocation(suggestionToUpdate.id));
              }}
              className={clsx(classes.skipSuggestionBtn)}
              size="small"
            >
              <DirectionalChevronIcon reverse />
            </IconButton>
          </TooltipWithIntl>
          <TooltipWithIntl
            className={classes.btnContainer}
            intlStringId="Suggestion.nextToNext"
            defaultMessage="next suggestion"
            placement="bottom"
          >
            <IconButton
              onClick={() => {
                let suggestionToUpdate = {};
                // TODO - move to next Suggestion
                if (currentSuggestionIndex === suggestions.length) {
                  suggestionToUpdate = { ...suggestions[0] };
                } else {
                  suggestionToUpdate = {
                    ...suggestions[currentSuggestionIndex]
                  };
                }
                suggestionToUpdate.color = "#90CAF9";
                dispatch(setSelectedSuggestion(suggestionToUpdate));
                dispatch(setShouldShowLocation(suggestionToUpdate.id));
              }}
              className={clsx(classes.skipSuggestionBtn)}
              size="small"
            >
              <DirectionalChevronIcon />
            </IconButton>
          </TooltipWithIntl>
          <Box>
            <Typography
              variant="caption"
              className={clsx(classes.suggestionBox, classes.active)}
            >
              Task navigator
            </Typography>{" "}
            <Typography
              variant="caption"
              className={clsx(classes.suggestionBox, classes.active)}
            >
              Question #{currentQuestion?.order ? currentQuestion.order + 1 : 1}{" "}
              Journey
            </Typography>
            <Typography
              variant="caption"
              className={clsx(classes.suggestionBox, classes.suggestionsStops)}
            >
              Stop {currentSuggestionIndex} of {suggestions.length}
            </Typography>
          </Box>
          <Button className={classes.betaButton}>Beta</Button>
        </Box>
        <Box>
          <TooltipWithIntl
            className={classes.btnContainer}
            intlStringId="Suggestion.nextToNext"
            defaultMessage="Toggle insights"
            placement="bottom"
          >
            <IconButton
              onClick={() => {
                if (selectIsSuggestionsInSecondarySidebar) {
                  dispatch(
                    setSecondarySidebarViewMode(
                      SECONDARY_SIDEBAR_STATE.COLLAPSED
                    )
                  );
                } else {
                  dispatch(
                    setSecondarySidebarViewMode(
                      SECONDARY_SIDEBAR_STATE.SUGGESTIONS
                    )
                  );
                }
              }}
              className={clsx(
                classes.toggleInsightsBtn,
                isSuggestions && classes.active
              )}
              size="small"
            >
              <LightbulbOutlinedIcon />
            </IconButton>
          </TooltipWithIntl>
          <TooltipWithIntl
            className={classes.btnContainer}
            intlStringId="Suggestion.nextToNext"
            defaultMessage="End journey"
            placement="bottom"
          >
            <IconButton
              onClick={() => {
                // TODO - end journey
                dispatch(
                  setSecondarySidebarViewMode(SECONDARY_SIDEBAR_STATE.COLLAPSED)
                );
                dispatch(
                  suggestionsIconClicked(SECONDARY_SIDEBAR_STATE.COLLAPSED)
                );
              }}
              className={clsx(classes.closeIcon)}
              size="small"
            >
              <CloseIcon />
            </IconButton>
          </TooltipWithIntl>
        </Box>
      </Box>
    </Paper>
  );
};

export default JourneyHeaderMenu;
