import { ofType } from "redux-observable";
import { empty } from "rxjs";
import { map, debounceTime, catchError } from "rxjs/operators";
import { updateTextPosition, addToReadingSession } from "../../readerSlice";
import { captureException } from "../../../utils/errorHandlers";

export const trackReadingSessionEpic = (action$, state$) => {
  return action$.pipe(
    ofType(updateTextPosition.type),
    debounceTime(200),
    map(value => value.payload),
    map(payload => ({ type: addToReadingSession.type, payload })),
    catchError(error => {
      captureException(error, "Error in trackReadingSessionEpic");
      return empty();
    })
  );
};
