import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { FormattedMessage, useIntl } from "react-intl";

// Redux dependencies
import { useSelector } from "react-redux";
import { selectCurrentText } from "../../../redux/textsSlice";

// Components
import FindTaskAnswer from "./FindTaskAnswer";
import OpenAnswer from "./OpenAnswer";
import CreateMultiQuestion from "./CreateMultiQuestion";
import { QUESTION, QUESTION_INTL } from "../../../consts";

import makeStyles from "@mui/styles/makeStyles";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import CopyIcon from "@mui/icons-material/FileCopyOutlined";
import {
  Box,
  MenuItem,
  TextField,
  IconButton,
  Typography,
  ListItemText
} from "@mui/material";
import GuidedReadingQuestion from "./GuidedReadingQuestion";
import { TASK } from "../../../consts";
import { clearSelection } from "../../reader/utils";

// Styles
const useStyles = makeStyles(theme => ({
  questionIndex: {
    borderBottom: "1px solid #dedede",
    paddingInline: theme.spacing(3),
    paddingBlock: theme.spacing(1.5)
  },
  stepper: {
    marginTop: "20px",
    // background: "white",
    width: "100%"
  },
  menu: {
    // background: "white",
  },
  paperClass: {
    marginTop: "20px",
    marginBottom: "10px",
    // background: "white",
    borderRadius: "0px",
    paddingBottom: "16px",
    textAlign: "left",

    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
      color: "#168FEE"
    },
    "& .MuiInput-underline": {
      "&:after": {
        borderBottom: "2px solid #168FEE"
      }
    },
    "&:after": {
      borderBottom: "2px solid #168FEE"
    },
    "&:focused::after": {
      borderBottom: "2px solid #168FEE"
    },
    "& .Muilabel-root.Mui-focused": {
      color: "#168FEE"
    }
  },
  menuBg: {
    "& ul": {},
    borderRadius: "8px"
  },
  inputContainer: {
    display: "flex",
    justifyContent: "space-between",
    paddingInline: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  pntText: {
    lineHeight: "36px"
  },
  pointsCtr: {},
  pntInput: {
    marginLeft: "16px",
    marginRight: "16px",
    marginBottom: "2px",
    paddingLeft: "5px",
    paddingRight: "5px",
    width: "60px",
    borderRadius: "4px",
    border: "1px solid #adadad",
    "&:hover": {
      border: "1px solid #168fee"
    },
    "& input": {
      textAlign: "center"
    },
    "& input[type=number]": {
      "-moz-appearance": "textfield"
    },
    "& input[type=number]::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0
    },
    "& input[type=number]::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0
    }
  },
  selectContainer: {
    width: "25%",
    verticalAlign: "bottom",
    marginLeft: theme.spacing(4),
    "& .MuiListItemText-root": {
      marginTop: "3px"
    }
  },
  noPad: {
    padding: "0px",
    marginBottom: "0px",
    lineHeight: "1rem"
  },

  divider: {
    backgroundColor: "#dedede",
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(3)
  },
  btncontainer: {},
  btn: {
    borderRadius: "8px",
    marginLeft: "4px",
    marginRight: "4px"
  },
  stepLabel: {
    fontSize: "24px"
  },
  removeQuote: {
    width: "36px",
    height: "36px"
  },
  questionTxt: {
    width: "100%"
  },
  actions: {
    display: "flex",
    justifyContent: "space-between",
    fontSize: "14px",
    lineHeight: "36px",
    borderTop: "1px solid #dedede",
    paddingInline: theme.spacing(3),
    paddingBlock: theme.spacing(1.5)
  },
  questionOnly: {
    paddingBlockEnd: theme.spacing(3)
  }
}));

function TaskQuestionView({
  quotes = [],
  includeCitation,
  interaction_subtype = "OPEN_ENDED",
  content,
  concepts,
  taskType,
  points,
  questionIndex,
  options,
  wordLimit,
  readOnly,
  onUpdate,
  onDelete,
  onCopy,
  shouldSelect = -1
}) {
  // Hooks
  const classes = useStyles();
  const intl = useIntl();

  // Redux State
  const text = useSelector(selectCurrentText);

  //Variables
  const questionTypes = [
    QUESTION.OPEN_ENDED,
    QUESTION.FIND_IN_TEXT,
    QUESTION.MULTI_CHOICE
  ];
  function addHighlight(highlight) {
    onUpdate({ quotes: [...quotes, highlight] }, questionIndex);
    return clearSelection();
  }

  function removeHighlight(highlight) {
    onUpdate(
      { quotes: quotes.filter(quote => quote.id !== highlight) },
      questionIndex
    );
  }

  function renderQuesionByType() {
    switch (true) {
      case interaction_subtype === "GUIDED_READING":
        return (
          <GuidedReadingQuestion
            url={text.url}
            location={text.file_location}
            highlights={quotes}
            readOnly={readOnly}
            addHighlight={addHighlight}
            removeHighlight={removeHighlight}
            concepts={concepts}
            onConcepts={concepts => {
              onUpdate({ concepts: concepts }, questionIndex);
            }}
          />
        );
      case interaction_subtype === "OPEN_ENDED":
        return (
          <OpenAnswer
            readOnly={readOnly}
            addHighlight={highlight => {
              onUpdate({ quotes: [...quotes, highlight] }, questionIndex);
              return clearSelection();
            }}
            removeHighlight={hl => {
              onUpdate(
                { quotes: quotes.filter(quote => quote.id !== hl) },
                questionIndex
              );
            }}
            wordLimit={wordLimit}
            url={text.url}
            location={text.location}
            includeCitation={includeCitation}
            setIncludeCitation={input => {
              onUpdate({ includeCitation: input }, questionIndex);
            }}
            highlights={quotes}
            onWordLimit={newLimit => {
              onUpdate({ wordLimit: Number(newLimit) }, questionIndex);
            }}
            concepts={concepts}
            onConcepts={concepts => {
              onUpdate({ concepts: concepts }, questionIndex);
            }}
          />
        );
      case interaction_subtype === "MULTI_CHOICE":
        return (
          <CreateMultiQuestion
            readOnly={readOnly}
            addHighlight={addHighlight}
            removeHighlight={removeHighlight}
            shouldSelect={shouldSelect}
            url={text.url}
            location={text.file_location}
            options={options}
            highlights={quotes}
            includeCitation={includeCitation}
            setIncludeCitation={input => {
              onUpdate({ includeCitation: input }, questionIndex);
            }}
            onUpdate={(newOptions, correctAnswer) => {
              onUpdate(
                {
                  options: newOptions,
                  shouldSelect: correctAnswer
                },
                questionIndex
              );
            }}
          />
        );

      default: // Find in text
        return (
          <FindTaskAnswer
            readOnly={readOnly}
            addHighlight={addHighlight}
            removeHighlight={removeHighlight}
            highlights={quotes}
            url={text.url}
            location={text.file_location}
          />
        );
    }
  }

  // START
  function renderQuesionFooter() {
    return (
      <Box className={classes.actions}>
        {[TASK.TYPE.STANDARD, TASK.TYPE.GUIDED_READING].includes(taskType) ? (
          <Box className={classes.pointsCtr}>
            <TextField
              variant="standard"
              id={`points${questionIndex}`}
              aria-label="task-points-input"
              value={Number(points)}
              className={classes.pntInput}
              onChange={e => {
                onUpdate({ points: Number(e.target.value) }, questionIndex);
              }}
              type="number"
              InputLabelProps={{ shrink: true }}
              InputProps={{
                readOnly: readOnly,
                disableUnderline: true,
                style: { height: "36px" }
              }}
              placeholder="Points"
            />
          </Box>
        ) : (
          <Box />
        )}
        <Box className={classes.btncontainer}>
          <IconButton
            onClick={onDelete}
            className={clsx(classes.removeQuote)}
            aria-label="delete question"
            size="large"
          >
            <DeleteIcon />
          </IconButton>

          {interaction_subtype && (
            <IconButton
              name="duplicate-question"
              onClick={() => {
                onCopy({
                  content: content,
                  interaction_subtype: interaction_subtype,
                  points: points,
                  options: options,
                  wordLimit: wordLimit
                });
              }}
              className={clsx(classes.removeQuote)}
              aria-label="duplicate question"
              size="large"
            >
              <CopyIcon />
            </IconButton>
          )}
        </Box>
      </Box>
    );
  }

  // Render
  return (
    <>
      <Typography variant="h6" className={classes.questionIndex}>
        {["guidedReading", "standard"].includes(taskType) &&
          `${intl.formatMessage({
            id: "task.question",
            defaultMessage: "Question"
          })} #${questionIndex + 1}`}

        {interaction_subtype === "PEER_REVIEW" &&
          `${intl.formatMessage({
            id: "task.question",
            defaultMessage: "Question"
          })}`}
      </Typography>
      <Box className={clsx(classes.inputContainer)}>
        <TextField
          variant="standard"
          name="question-formulation"
          aria-label="task-question-formulation-input"
          id={`questionFormulation${questionIndex}`}
          InputProps={{
            readOnly: readOnly
          }}
          className={clsx(
            classes.questionTxt,
            interaction_subtype === "PEER_REVIEW" && classes.questionOnly
          )}
          multiline={true}
          label={intl.formatMessage({
            id: "task.question.formulation",
            defaultMessage: "Question formulation"
          })}
          value={content || ""}
          onChange={e => {
            onUpdate({ content: e.target.value }, questionIndex);
          }}
        />
        {taskType === TASK.TYPE.STANDARD && (
          <TextField
            variant="standard"
            aria-label="task-question-type-input"
            id={`questionType${questionIndex}`}
            InputProps={{
              readOnly: readOnly
            }}
            name="select-question-type"
            className={clsx(classes.selectContainer)}
            label={intl.formatMessage({
              id: "task.question.type",
              defaultMessage: "Type of question"
            })}
            select
            value={interaction_subtype}
            dropdownmenuprops={{ classes: { paper: classes.menuBg } }}
            menuprops={{ classes: { paper: classes.menuBg } }}
            onChange={e => {
              onUpdate(
                { includeCitation: true, interaction_subtype: e.target.value },
                questionIndex
              );
            }}
          >
            {questionTypes.map((item, index) => {
              return (
                <MenuItem
                  className={classes.menu}
                  key={index}
                  onClick={() => {
                    onUpdate(
                      { interaction_subtype: item.interaction_subtype },
                      questionIndex
                    );
                  }}
                  value={item.interaction_subtype}
                >
                  <ListItemText
                    disableTypography={true}
                    className={classes.noPad}
                  >
                    {intl.formatMessage({
                      id: QUESTION_INTL[item.interaction_subtype].translationId,
                      defaultMessage:
                        QUESTION_INTL[item.interaction_subtype].defaultMessage
                    })}
                  </ListItemText>
                </MenuItem>
              );
            })}
          </TextField>
        )}
      </Box>
      {interaction_subtype !== "PEER_REVIEW" && (
        <>
          {renderQuesionByType()}
          {renderQuesionFooter()}
        </>
      )}
    </>
  );
}

TaskQuestionView.propTypes = {
  question: PropTypes.object,
  quotes: PropTypes.array,
  concepts: PropTypes.array,
  includeCitation: PropTypes.bool,
  type: PropTypes.number,
  points: PropTypes.number,
  questionIndex: PropTypes.number,
  options: PropTypes.array,
  text: PropTypes.object,
  wordLimit: PropTypes.number,
  // shouldAutoFocus: PropTypes.string,
  showType: PropTypes.bool,
  showPoints: PropTypes.bool,
  readOnly: PropTypes.bool,
  onUpdate: PropTypes.oneOfType([ PropTypes.func,PropTypes.bool]),
  onDelete: PropTypes.oneOfType([ PropTypes.func,PropTypes.bool]),
  onCopy: PropTypes.oneOfType([ PropTypes.func,PropTypes.bool]),
};

export default TaskQuestionView;
