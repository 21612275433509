import { createSlice, createSelector, current } from "@reduxjs/toolkit";
import {
  INTERACTION_SUBTYPES,
  USER_PROFILE,
  INTERACTION_TYPES,
  COMMENT_PANEL_VIEW,
  PRIVACY,
  FEATURES
} from "../consts";
import { determineConfig } from "./firestoreSelectors";

const initialState = {
  selectedRealtimeInteractions: [], // Allowing multiple selected interactions in case there are overlapping elements.
  selectedThreadId: null, //TODO: use selectedRealtimeInteractions and remove this one
  containers: [],
  threads: {},
  newComment: false,
  commentPanelState: COMMENT_PANEL_VIEW.ALL_THREADS,
  privacy: PRIVACY.PUBLIC
};

// Thunk
export function initializeCommentsMode() {
  return (dispatch, getState) => {
    const state = getState();
    const config = determineConfig(state, FEATURES.COMMENTS);
    dispatch(setCommentPrivacyState(config.privacy));
  };
}

export function setCommentsPrivacy(mode) {
  return (dispatch, getState, { getFirestore }) => {
    dispatch(setCommentPrivacyState(mode));
    const state = getState();
    const firestore = getFirestore();
    const userId = state.firebase.auth.uid;
    // We persist the state per feature. The context will be the name of the feature the user is currently in (reader, task, etc)
    const feature = FEATURES.COMMENTS;
    firestore.set(
      // update the user profile in firebase
      `${USER_PROFILE.CUSTOM_CONFIG_PATH}/${userId}/${feature}`,
      { privacy: mode },
      { merge: true }
    );
  };
}

export const realtimeInteractionsSlice = createSlice({
  name: "realtimeInteractions",
  initialState,
  extraReducers: {
    "texts/setSelectedTextId": (state, value) => {
      if (!state.selectedThreadId) return initialState;
    }
  },
  reducers: {
    setSelectedRealtimeInteractions: (state, value) => {
      state.selectedRealtimeInteractions = value.payload;
    },
    setSelectedThreadId: (state, value) => {
      state.selectedThreadId = value.payload;
    },
    setRealtimeInteractions: (state, value) => {
      let realtimeInteractions = {
        comments: [],
        containers: []
      };
      value.payload.forEach(interaction => {
        const bucket = matchInteractionWithBucket(interaction);
        realtimeInteractions[bucket].push(interaction);
      });

      state.containers = realtimeInteractions.containers;
      state.threads = createThreadsMap(realtimeInteractions.comments);
    },
    updateRealtimeInteraction: (state, value) => {
      const interactionToUpdate = value.payload;
      const interaction_id = interactionToUpdate.interaction_id;

      const comment_id = interactionToUpdate.id;
      if (interaction_id in state.threads) {
        let previousInteraction = state.threads[interaction_id].find(
          comment => comment.id === comment_id
        );

        previousInteraction.content = interactionToUpdate.content;
      }
    },
    deleteRealtimeInteraction: (state, value) => {
      const { interaction_id, id } = value.payload;
      if (interaction_id in state.threads) {
        state.threads[interaction_id] = state.threads[interaction_id].filter(
          comment => comment.id !== id
        );
      }
    },
    createContainer: (state, value) => {
      const containerToCreate = value.payload;
      state.containers.push(containerToCreate);
    },
    createComment: (state, value) => {
      const commentToCreate = value.payload;
      const key = commentToCreate.interaction_id;

      state.threads = addToMap(state.threads, key, commentToCreate);
    },
    resetThreadsState: (state, value) => {
      return initialState;
    },
    resetComments: (state, value) => {
      return initialState;
    },
    setCommentPanelState: (state, value) => {
      const newState = value.payload;
      state.commentPanelState = newState;
      if (state.commentPanelState !== COMMENT_PANEL_VIEW.SELECTED_THREADS)
        state.selectedRealtimeInteractions = [];
    },
    setCommentPrivacyState: (state, value) => {
      state.privacy = value.payload;
    },
    deleteContainer: (state, value) => {
      let container_id_to_remove = value.payload;
      state.containers = state.containers.filter(
        container => container.id !== container_id_to_remove
      );
    },
    setNewCommentObject: (state, value) => {
      state.newComment = value.payload;
    }
  }
});

export const {
  setSelectedRealtimeInteractions,
  setSelectedThreadId,
  setRealtimeInteractions,
  deleteRealtimeInteraction,
  updateRealtimeInteraction,
  createContainer,
  createComment,
  resetThreadsState,
  setCommentPanelState,
  setCommentPrivacyState,
  deleteContainer,
  resetComments,
  setNewCommentObject
} = realtimeInteractionsSlice.actions;

// Selectors
export const selectThreads = createSelector(
  [state => state.realtimeInteractions.containers],
  containers => {
    return containers
      .filter(
        container =>
          container.interaction_subtype === INTERACTION_SUBTYPES.COMMENT
      )
      .sort((a, b) => sortByDateCreated(a, b));
  }
);

export const selectThread = createSelector(
  [state => state.realtimeInteractions.threads, (state, threadId) => threadId],
  (threads, threadId) => {
    return threadId in threads ? threads[threadId] : [];
  }
);

export function createThreadsMap(comments) {
  const threads = comments.reduce((accumulator, current) => {
    const key = current.interaction_id;

    accumulator = addToMap(accumulator, key, current);

    return accumulator;
  }, {});

  for (const key in threads) {
    threads[key].sort((a, b) => sortByDateCreated(a, b));
  }

  return threads;
}

function matchInteractionWithBucket(interaction) {
  const type = interaction.interaction_type;
  if (type === INTERACTION_TYPES.CONTAINER) return "containers";
  else if (type === INTERACTION_TYPES.COMMENT) return "comments";
}

export const selectIsSingleThread = createSelector(
  [state => state.realtimeInteractions.commentPanelState],
  state => state === COMMENT_PANEL_VIEW.SINGLE_THREAD
);
export const selectIsNewComment = createSelector(
  [state => state.realtimeInteractions.commentPanelState],
  state => state === COMMENT_PANEL_VIEW.NEW_COMMENT
);
export const selectIsSelectedThreads = createSelector(
  [state => state.realtimeInteractions.commentPanelState],
  state => state === COMMENT_PANEL_VIEW.SELECTED_THREADS
);

export const selectAllThreads = createSelector(
  [state => state.realtimeInteractions.commentPanelState],
  state => state === COMMENT_PANEL_VIEW.ALL_THREADS
);

export default realtimeInteractionsSlice.reducer;

// Utils
function addToMap(map, key, value) {
  if (key in map) map[key].push(value);
  else map[key] = [value];
  return map;
}

function sortByDateCreated(a, b) {
  let a_date = Date.parse(a.created_at);
  let b_date = Date.parse(b.created_at);
  if (a_date > b_date) return 1;
  else if (a_date < b_date) return -1;
  else return 0;
}
