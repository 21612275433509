// Dependencies
import React, { useRef, useEffect, useCallback, useState } from "react";
import { useQuery } from "../../hooks";
import clsx from "clsx";
import { useRendition } from "../../RenditionContext";
import { firestore, httpCallables } from "../../firebase";
import { ANNOTATION_TYPES, COMMENT_PANEL_VIEW } from "../../consts";
import { removeAllAnotationsOfType } from "../reader/utils";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  setRealtimeInteractions,
  setSelectedRealtimeInteractions,
  selectThreads,
  setSelectedThreadId,
  selectIsSingleThread,
  selectIsNewComment,
  selectAllThreads,
  setCommentPanelState,
  selectIsSelectedThreads
} from "../../redux/realtimeInteractionsSlice";

import makeStyles from "@mui/styles/makeStyles";
import { Paper } from "@mui/material";
import NewThread from "./NewThread";
import ThreadsList from "./ThreadsList";
import Thread from "./Thread";
import {
  selectIsComments,
  selectCommentsPrivacy,
  selectIsSuggestions
} from "../../redux/firestoreSelectors";
import { isPdfSelector } from "../../redux/textsSlice";
import { suggestionsIconClicked } from "../../redux/layoutSlice";

// Styles
const useStyles = makeStyles(theme => ({
  container: {
    width: "250px",
    height: "100%",
    background: theme.palette.background.paper,
    display: "flex",
    border: "none",
    alignItems: "center",
    justifyContent: "center",
    transition: ".3s ease-out",
    position: "relative"
  },
  containerWithSuggestions: {
    height: "calc(100% - 90px)",
    alignSelf: "flex-end"
  },
  isClosed: {
    width: "0px"
  }
}));

export default function CommentPanel({}) {
  // Hooks
  const classes = useStyles();
  const dispatch = useDispatch();
  let { text_id } = useQuery();
  const unsubscribeRef = useRef(null);
  const { rendition } = useRendition();

  // Redux State
  const isPdf = useSelector(isPdfSelector);
  const threads = useSelector(state => selectThreads(state));
  const isAllThreads = useSelector(state => selectAllThreads(state)); // display in panel all threads view
  const isNewComment = useSelector(state => selectIsNewComment(state)); // display in panel new thread view
  const isSelectedThreads = useSelector(state =>
    selectIsSelectedThreads(state)
  );
  const privacy = useSelector(state => selectCommentsPrivacy(state));
  const selectedRealtimeInteractions = useSelector(
    state => state.realtimeInteractions.selectedRealtimeInteractions
  );
  const selectedThreadId = useSelector(
    state => state.realtimeInteractions.selectedThreadId
  );
  const isSuggestions = useSelector(state => selectIsSuggestions(state));
  const isSingleThread = useSelector(state => selectIsSingleThread(state)); // display in panel a single selected thread
  const isComments = useSelector(state => selectIsComments(state)); // display in panel several selected threads
  const selectedTextId = useSelector(state => state.texts.selectedTextId);
  if (!text_id) text_id = selectedTextId; // This can happen when we're in a task since text_id is a query param.

  // Ephemeral state
  const [sortedComments, setSortedComments] = useState([]);

  //Variables
  // Behavior

  const handleClickOnThreadHighlight = useCallback(e => {
    const comments = e.detail;
    let threadIds = comments.map(comment => comment.id);
    threadIds = Array.from(new Set(threadIds));
    dispatchSelectedThreads(threadIds);
  }, []);

  useEffect(() => {
    setSortedComments([]);
    // unsubscribe from previus snapshot when text_id changes
    unsubscribe(unsubscribeRef);

    unsubscribeRef.current = firestore
      .collection("realtime_interactions")
      .where("text_id", "==", Number(text_id))
      .onSnapshot(
        () => {
          httpCallables
            .readTextComments({ text_id: Number(text_id) })
            .then(({ data }) => {
              const { success } = data;
              if (success) {
                const { interactions } = JSON.parse(data.payload);
                setSortedComments([]);
                dispatch(setRealtimeInteractions(interactions));
              } else {
              }
            });
        }
        // error => {
        // console.log(error);
        // eponential backoff
        // }
      );
    // unsubscribe from snapshot when component unmount
    return () => {
      unsubscribe(unsubscribeRef);
    };
  }, [dispatch, text_id, unsubscribeRef, selectedTextId]);

  useEffect(() => {
    if (!rendition || isPdf) return;

    removeAllAnotationsOfType(rendition, ANNOTATION_TYPES.THREAD);

    // render social highlights (threads) from realTimeInteractions
    threads.forEach(highlight => {
      const cfi = highlight.cfi;
      if (highlight.privacy === privacy)
        rendition.annotations.add(
          ANNOTATION_TYPES.THREAD,
          cfi,
          { id: highlight.id },
          handleClickOnThreadHighlight,
          ANNOTATION_TYPES.THREAD.toLowerCase(),
          {
            "z-index": 1,
            "mix-blend-mode": "multiply",
            "fill-opacity": 1,
            fill: "rgba(0, 0, 0, 0.12)"
          }
        );
    });
  }, [handleClickOnThreadHighlight, isPdf, privacy, rendition, threads]);

  useEffect(() => {
    if (!isSingleThread && selectedThreadId) {
      dispatch(setCommentPanelState(COMMENT_PANEL_VIEW.SINGLE_THREAD));
    }
  }, []);
  const navigateToThread = interaction_id => {
    dispatch(setCommentPanelState(COMMENT_PANEL_VIEW.SINGLE_THREAD));
    dispatch(setSelectedThreadId(interaction_id));
  };

  const filterThreadsByCfiOnHighlightClick = (
    threads,
    selectedRealtimeInteractions
  ) => {
    if (!selectedRealtimeInteractions) return;
    const comments = selectedRealtimeInteractions.reduce(
      (accumulator, threadId) => {
        threadId in threads && accumulator.push(threads[threadId][0]);
        return accumulator;
      },
      []
    );
    if (comments.length === 0) return;
    if (comments.length === 1) {
      navigateToThread(comments[0].interaction_id);
      return;
    } else {
      return comments;
    }
  };

  function dispatchSelectedThreads(threadIds) {
    if (selectedRealtimeInteractions.length)
      dispatch(setSelectedRealtimeInteractions([]));
    if (threadIds.length > 1) {
      dispatch(setSelectedRealtimeInteractions(threadIds));
      if (!isSelectedThreads)
        dispatch(setCommentPanelState(COMMENT_PANEL_VIEW.SELECTED_THREADS));
    } else {
      if (!isSingleThread)
        dispatch(setCommentPanelState(COMMENT_PANEL_VIEW.SINGLE_THREAD));
    }
    dispatch(setSelectedThreadId(threadIds[0].toString()));
  }

  function unsubscribe(unsubscribeRef) {
    if (unsubscribeRef.current) {
      unsubscribeRef.current();
    }
  }

  //render
  if (isSingleThread) {
    return (
      <Paper className={clsx(classes.container)}>
        <Thread />
      </Paper>
    );
  } else if (isNewComment) {
    return (
      <Paper className={clsx(classes.container)}>
        <NewThread />
      </Paper>
    );
  } else if (isAllThreads || isSelectedThreads) {
    return (
      <Paper className={clsx(classes.container)}>
        <ThreadsList
          filterThreadsByCfiOnHighlightClick={
            filterThreadsByCfiOnHighlightClick
          }
          setSortedComments={setSortedComments}
          sortedComments={sortedComments}
          navigateToThread={navigateToThread}
        />
      </Paper>
    );
  }
}
