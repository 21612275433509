// Dependencies
import React, { useState } from "react";
import clsx from "clsx";
import { stemmer } from "stemmer";
import FlexSearch from "flexsearch";
import { useSelector } from "react-redux";
import { normalize } from "./utils";
import { selectCurrentText } from "../../../redux/textsSlice";
import { useIntl } from "react-intl";

// Redux
import {
  selectQuestionHighlights,
  selectQuestionAnswers,
  selectTaskFeedbacks
} from "../../../redux/interactionsSlice";

// Components
import OpenQuestionStudentAnswer from "./OpenQuestionStudentAnswer";
import CitationChart from "./CitationChart";
import HeatMap from "./HeatMap";

import makeStyles from "@mui/styles/makeStyles";
import {
  Box,
  Button,
  Typography,
  Paper,
  Divider,
  LinearProgress,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Card
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { selectTextDirection } from "../../../redux/firestoreSelectors";

// Styles
const useStyles = makeStyles(theme => {
  return {
    statContainer: {
      padding: "32px"
    },
    generalStatsHeader: {
      padding: "24px 0",
      fontWeight: "bold"
    },
    OpenQuestionContainer: {
      display: "grid",
      gridTemplateColumns: "repeat(3, 33.33%)",
      gridAutoFlow: "row",
      // Important - it sets height of the pie chart to 2X height of a keyword box
      gridAutoRows: "1fr",
      margin: "-1px",
      border: "1px solid rgba(0, 0, 0, 0.12)",
      "&.withCitations": {
        gridTemplateColumns: "repeat(4, 25%)"
      }
    },
    citationContainer: {
      gridColumn: "1 / 3",
      gridRow: "1 / 3",
      margin: "-1px",
      padding: "32px",
      borderRight: "1px solid rgba(0, 0, 0, 0.12)",
      borderBottom: "1px solid rgba(0, 0, 0, 0.12)"
    },
    questionTitle: {
      fontWeight: theme.typography.fontWeightBold
    },
    stat: {
      margin: "-1px",
      borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
      borderRight: "1px solid rgba(0, 0, 0, 0.12)",
      "&:nth-child(3)": {
        // borderRight: "none",
      },
      "&.withCitations": {
        // flexBasis: "50%",
        "&:nth-child(3)": {
          // borderRight: "1px solid rgba(0, 0, 0, 0.12)",
        },
        "&:nth-child(2)": {
          // borderRight: "none",
        }
      }
    },

    statTitle: {
      marginBottom: "32px"
    },
    statValuesContainer: {
      display: "flex",
      justifyContent: "space-between",
      overflow: "hidden",
      lineHeight: "initial",
      marginBottom: "6px",
      fontSize: "36px",
      fontFamily: "Helvetica"
    },
    statKey: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      color: "#168FEE",
      "&:hover": {
        overflow: "initial"
      }
    },
    statBar: {
      height: "8px",
      backgroundColor: "#D8D8D8",
      "& .MuiLinearProgress-barColorPrimary": {
        backgroundColor: "#168FEE"
      }
    },
    answers: {
      marginTop: "24px",
      borderRadius: "4px",
      backgroundColor: "#168FEE",
      "& .MuiAccordionSummary-content": {
        order: 2
      }
    },
    answersHeading: {
      //   fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightBold,
      color: "#FFFFFF",
      padding: "0 16px"
    },
    answersDetails: {
      display: "block",
      columnCount: "2",
      columnFill: "balance",
      backgroundColor: theme.palette.background.paper,
      borderRadius: " 0 0 4px 4px"
    },
    answer: {
      marginBottom: "16px",
      breakInside: "avoid",
      pageBreakInside: "avoid",
      cursor: "pointer"
    },
    answerBody: {
      display: "-webkit-box",
      overflow: "hidden",
      "-webkit-line-clamp": "6",
      "-webkit-box-orient": "vertical",
      textOverflow: "ellipsis"
    },
    answerActions: {
      padding: "16px",
      color: "#168FEE"
    },
    citationsActions: {
      textAlign: "end",
      padding: "34px"
    }
  };
});

export default function OpenQuestionStats({
  question,
  index,
  task,
  submissions,
  annonymizeForm,
  isTeacher = true
}) {
  // Hooks
  const classes = useStyles();
  const intl = useIntl();

  // Redux State
  const textDirection = useSelector(state => selectTextDirection(state));
  const questionHighlights = useSelector(state =>
    selectQuestionHighlights(state, question.id)
  );
  const questionAnswers = useSelector(state =>
    selectQuestionAnswers(state, question.id)
  );

  const taskFeedbacks = useSelector(state =>
    selectTaskFeedbacks(state, task.id)
  );

  const text = useSelector(selectCurrentText);

  // Ephemeral State
  const [openHeatmap, setOpenHeatmap] = useState(false);

  // Derived State
  const withCitations = question.includeCitation;
  const concepts = question.concepts || [];

  const submittedSubmissions = submissions.filter(
    submission => submission.submission_date
  );

  const submittedSubmissionsIds = submittedSubmissions.map(
    submission => submission.id
  );

  const questionFeedbacks = taskFeedbacks.filter(
    feedback =>
      submittedSubmissionsIds.includes(feedback.submission_id) &&
      feedback.interaction_id === question.id
  );

  const participants = submittedSubmissions.length;
  let points = questionFeedbacks.map(feedback => feedback.points || 0);
  const sum = points.reduce((a, b) => Number(a) + Number(b), 0);
  const avg = points.length > 0 ? sum / points.length : 0;

  // this is what it is now
  const teacherHighlights = question.quotes || [];
  const studentHighlights = questionHighlights.filter(
    highlight => highlight.user_type === "STUDENT"
  );

  // index concepts in answers
  const createIndex = answer => {
    let flexIndex = new FlexSearch({
      filter: [],

      /**
       * @type {Object<string, string>}
       * @export
       */

      stemmer: function (value) {
        // apply some replacements
        // ...

        return stemmer(value);
      },
      encode: textDirection === "rtl" ? false : "balance",
      rtl: textDirection === "rtl",
      split: textDirection === "rtl" ? /\s+/ : /\W+/
    });

    questionAnswers.forEach((answer, index) => {
      flexIndex.add(index, answer.content);
    });

    // changed to an object with the concept as key and result as value, easier to access in the render
    let output = {};
    if (question.concepts) {
      question.concepts.forEach(concept => {
        let results = flexIndex.search(stemmer(concept));
        let exactResults = flexIndex.search(concept);
        output[concept] = Math.max(results.length, exactResults.length);
      });
    }
    return output;
  };

  const conceptResults = createIndex(index, questionAnswers);
  if (isTeacher)
    return (
      <>
        <HeatMap
          totalCount={submittedSubmissions.length}
          openDialog={openHeatmap}
          teacherHighlights={teacherHighlights}
          setOpenDialog={setOpenHeatmap}
          url={text.url}
          location={text.file_location}
          hlOpacity={1}
          hlColor="#4AA96C"
          highlights={studentHighlights}
          addHighlight={() => {}}
          removeHighlight={() => {}}
        />
        <Paper component="section" elevation={0}>
          <Box component="header" className={classes.statContainer}>
            <Typography className={clsx(classes.questionTitle)} variant="h5">
              {intl.formatMessage({
                id: "task.question",
                defaultMessage: "Question"
              })}{" "}
              #{index + 1} —{" "}
              {intl.formatMessage({
                id: "task.type.open",
                defaultMessage: "Open Ended"
              })}
            </Typography>
            <Typography className={clsx(classes.questionSubtitle)} variant="h6">
              {question.content}
            </Typography>
          </Box>
          <Divider />

          <Box
            className={clsx(
              classes.stat,
              classes.OpenQuestionContainer,
              withCitations && "withCitations"
            )}
          >
            {withCitations && (
              <Box className={classes.citationContainer}>
                <CitationChart
                  question={question}
                  i={index}
                  task={task}
                  submissions={submittedSubmissions}
                  answers={studentHighlights}
                />
              </Box>
            )}
            <Box
              className={clsx(
                classes.stat,
                classes.statContainer,
                withCitations && "withCitations"
              )}
            >
              <Typography className={classes.statTitle} variant="h6">
                {intl.formatMessage({
                  id: "task.statistics.gradeAvrage",
                  defaultMessage: "Grade average"
                })}
              </Typography>
              <Box className={classes.statValuesContainer}>
                <Box component="span" className={classes.statKey}>
                  {avg}
                </Box>
                <Box component="span">{`of ${question.points}`}</Box>
              </Box>
              <LinearProgress
                variant="determinate"
                value={normalize(avg, 0, question.points)}
                className={classes.statBar}
              />
            </Box>
            {concepts.map((concept, index) => {
              return (
                <Box
                  key={index}
                  className={clsx(
                    classes.stat,
                    classes.statContainer,
                    withCitations && "withCitations"
                  )}
                >
                  <Typography className={classes.statTitle} variant="h6">
                    {intl.formatMessage({
                      id: "task.statistics.keyword",
                      defaultMessage: "Keyword"
                    })}
                  </Typography>
                  <Box className={classes.statValuesContainer}>
                    <Box component="span" className={classes.statKey}>
                      {concept}
                    </Box>

                    <Box component="span">
                      {`${normalize(
                        conceptResults[concept],
                        0,
                        participants
                      )}%`}
                    </Box>
                  </Box>
                  <LinearProgress
                    variant="determinate"
                    value={normalize(conceptResults[concept], 0, participants)}
                    className={classes.statBar}
                  />
                </Box>
              );
            })}
          </Box>

          {withCitations && (
            <Box className={classes.citationsActions}>
              <Button
                color="primary"
                size="small"
                style={{
                  backgroundColor: "#168FEE",
                  color: "#FFFFFF",
                  padding: "4px 8px"
                }}
                className={classes.actionButton}
                onClick={() => setOpenHeatmap(true)}
              >
                {intl.formatMessage({
                  id: "task.statistics.showHeatmap",
                  defaultMessage: "Show heatmap"
                })}
              </Button>
            </Box>
          )}
        </Paper>
        <Accordion
          className={classes.answers}
          elevation={0}
          disabled={submittedSubmissions.length === 0}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon style={{ color: "#FFFFFF" }} />}
            aria-controls="details-stats"
            id="panel-header"
          >
            <Typography variant="h5" className={classes.answersHeading}>
              {intl.formatMessage({
                id: "task.statistics.studentsAnswers",
                defaultMessage: "Students answers"
              })}
            </Typography>
          </AccordionSummary>
          <AccordionDetails
            id="details-stats"
            className={classes.answersDetails}
          >
            {questionAnswers.map((answer, index) => (
              <OpenQuestionStudentAnswer
                key={answer.id}
                answer={answer}
                index={index}
                annonymizeForm={annonymizeForm}
              />
            ))}
          </AccordionDetails>
        </Accordion>
      </>
    );
  else
    return (
      <>
        <Card
          component="section"
          elevation={1}
          className={classes.statContainer}
        >
          <HeatMap
            totalCount={
              submissions.filter(submission => submission.submission_date)
                .length
            }
            openDialog={openHeatmap}
            teacherHighlights={teacherHighlights}
            setOpenDialog={setOpenHeatmap}
            url={text.url}
            location={text.file_location}
            hlOpacity={1}
            hlColor="#4AA96C"
            highlights={studentHighlights}
            addHighlight={() => {}}
            removeHighlight={() => {}}
          />

          <Box component="header">
            <Typography
              className={clsx(classes.questionTitle)}
              variant="h5"
              style={{
                marginBottom: "15px"
              }}
            >
              {intl.formatMessage({
                id: "task.question",
                defaultMessage: "Question"
              })}{" "}
              #{index + 1} —{" "}
              {intl.formatMessage({
                id: "task.type.open",
                defaultMessage: "Open Ended"
              })}
            </Typography>
            <Divider />
            <Box
              style={{
                display: "flex",
                flexFlow: "row",
                justifyContent: "space-between",
                paddingTop: "15px"
              }}
            >
              <Typography
                className={clsx(classes.questionSubtitle)}
                variant="h6"
              >
                {question.content}
              </Typography>
              {withCitations && (
                <Box>
                  <Button
                    color="primary"
                    size="small"
                    style={{
                      backgroundColor: "#168FEE",
                      color: "#FFFFFF",
                      padding: "4px 8px"
                    }}
                    className={classes.actionButton}
                    onClick={() => setOpenHeatmap(true)}
                  >
                    {intl.formatMessage({
                      id: "task.statistics.showHeatmap",
                      defaultMessage: "Show heatmap"
                    })}
                  </Button>
                </Box>
              )}
            </Box>
          </Box>
        </Card>
      </>
    );
}
