// Dependancies

import React, { useState, useRef, useEffect } from "react";
import clsx from "clsx";
import * as d3 from "d3";
import {
  ACTION_OBJ,
  CHART_ACTIVITY_BUCKET,
  COURSE_ACTIONS_FOR_ANALYSES
} from "../../../../consts";
import { fakeData } from "./classEngagmentFakeData";
import { useImpersonate } from "../../../../hooks";

import {
  eachDayOfInterval,
  isWeekend,
  fromUnixTime,
  startOfDay,
  format
} from "date-fns";
import _, { groupBy, flatten } from "lodash";

// Components
import Chart from "../../../SharedComponents/chart/Chart";
import Line from "../../../SharedComponents/chart/chartPrimitives/Line";
import Circles from "../../../SharedComponents/chart/chartPrimitives/Circles";
import Bars from "../../../SharedComponents/chart/chartPrimitives/Bars";
import Axis from "../../../SharedComponents/chart/chartPrimitives/Axis";
import ChartTooltip from "../../../SharedComponents/chart/chartPrimitives/ChartTooltip";
import { useChartDimensions } from "../../../../hooks";

// Mui
import { Box, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';

// styles
const useStyles = makeStyles(theme => ({
  container: {
    position: "relative",
    height: "100%"
  },
  hide: { opacity: 0 },
  tooltip: {
    position: "absolute",
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "column"
  },
  tooltipHeader: {
    marginBlockEnd: theme.spacing(4)
  },
  legend: {
    position: "absolute",
    left: 0,
    display: "flex",
    flexFlow: "row wrap",
    alignItems: "end",
    justifyContent: "center",
    paddingBlock: theme.spacing(3),
    "& div": {
      marginInlineEnd: theme.spacing(3),
      display: "flex",
      alignItems: "center"
    }
  },
  legendIcon: {
    display: "inline-block",
    height: 25,
    width: 25,
    marginInlineEnd: theme.spacing(1)
  }
}));

const formatDate = d3.timeFormat("%-b %-d");

const EngagementChartOld = ({
  data = [],
  xAccessor,
  yAccessor,
  label,
  courseTasks,
  start,
  end,
  usersBucketActivity = [],
  allUserIds,
  actionsForAnalysis
}) => {
  const classes = useStyles();
  const containerRef = useRef();
  const tooltipRef = useRef();
  const { ref, dimensions } = useChartDimensions({ marginBottom: 75 });
  // Ephemeral state
  const [showTooltip, setShowTooltip] = useState(false);
  const [tooltipData, setTooltipData] = useState(null);
  const [courseEngagement, setCourseEngagement] = useState([]);
  const [chartActivityBucket, setChartActivityBucket] = useState(
    CHART_ACTIVITY_BUCKET.PER_USER
  ); // This is meant to be a flag to control the state of the chart data, in the future we'd might want to set a toggle button and switch between daily activity per class instead of daily activity per active users.
  //to change this to daily activity per class switch PER_USER with PER_CLASS

  // Behavior
  useEffect(() => {
    setCourseEngagement([]);
    if (!data || !data.length) return;

    let course_engagement = populateEngagementDataPerBucket(data);
    setCourseEngagement(course_engagement);
    if (usersBucketActivity.length) {
      let user_engagement =
        populateEngagementDataPerBucket(usersBucketActivity);
      setCourseEngagement([...course_engagement, ...user_engagement]);
    }
  }, [data, usersBucketActivity]);
  // Derived state

  xAccessor = d => d.date;
  yAccessor = d => d.value;
  const zAccessor = d => d.group;

  const keyAccessor = (d, i) => i;
  const groupedData = groupBy(courseEngagement, zAccessor);
  const xScale = d3
    .scaleTime()
    .domain(d3.extent(courseEngagement, xAccessor))
    .range([0, dimensions.boundedWidth]);

  const yScale = d3
    .scaleLinear()
    .domain(d3.extent(courseEngagement, yAccessor))
    .range([dimensions.boundedHeight, 0])
    .nice();

  const zScale = d3
    .scaleOrdinal(d3.schemePaired)
    .domain(Object.keys(groupedData));

  // ;

  // Main chart path
  const xAccessorScaled = d => xScale(xAccessor(d));
  const yAccessorScaled = d => yScale(yAccessor(d));

  // Day/task due date circles
  const colorAccessor = d => d.taskDueDate && "primary";
  const variantAccessor = d => d.taskDueDate && "outlined";
  const radiusAccessor = d => (d.taskDueDate ? 12 : 3);

  // Weekend marks
  const singleDayWidth = Math.abs(
    xScale(courseEngagement[1]?.date) - xScale(courseEngagement[0]?.date)
  );
  const weekendXAccessor = d => xScale(xAccessor(d)) - singleDayWidth / 2;

  // Interactions
  function handleMouseEnter(e, d, x, y) {
    tooltipRef.current = e.currentTarget;
    setTooltipData({ ...d, x: Number(x), y: Number(y) });
    setShowTooltip(true);
  }
  function handleMouseLeave() {
    setShowTooltip(false);
    tooltipRef.current = null;
    setTooltipData(null);
  }

  function populateEngagementDataPerBucket(raw_data) {
    let bucket_engagement = [];

    if (!raw_data.length || !raw_data) return;
    raw_data.forEach(bucket => {
      let data = Object.values(bucket)[0];
      let group_by_bucket_type = Object.keys(bucket)[0];

      let filler_data = createParsedCourseEngagementData();
      let data_start_of_day = resetDataBucketToStartOfDay(data);

      let combined_data = { ...filler_data, ...data_start_of_day };

      let due_dates = createTaskDueDatesObj();

      let data_bucket = combineDataWithDueDates(combined_data, due_dates);
      data_bucket = flattenCourseEngagementForChart(
        data_bucket,
        group_by_bucket_type
      );

      bucket_engagement.push(data_bucket);
    });
    return flatten(bucket_engagement);
  }

  function flattenCourseEngagementForChart(
    course_engagement,
    group_by_bucket_type
  ) {
    let chart_engagement = [];
    for (const bucket in course_engagement) {
      const {
        score_breakdown,
        score,
        taskDueDate,
        participants_factor,
        total_users
      } = course_engagement[bucket];
      let flatten_obj = {
        date: startOfDay(fromUnixTime(bucket / 1000))
          .getTime()
          .toString(),
        ReadingTime:
          score_breakdown?.READING_SESSIONS?.[`${chartActivityBucket}`] || 0,
        writingTime:
          score_breakdown?.WRITING_SESSIONS?.[`${chartActivityBucket}`] || 0,
        citationsCreated:
          score_breakdown?.QUOTE_INTERACTIONS?.[
            `count_${chartActivityBucket}`
          ] || 0,
        questionsCreated:
          score_breakdown?.GUIDED_READING_INTERACTIONS?.[
            `count_${chartActivityBucket}`
          ] || 0,
        comments:
          score_breakdown?.REAL_TIME_INTERACTIONS?.[
            `count_${chartActivityBucket}`
          ] || 0,
        sessions_sum:
          score_breakdown?.SESSIONS?.[`${chartActivityBucket}`] || 0,
        sessions_count:
          score_breakdown?.SESSIONS?.[`count_${chartActivityBucket}`] || 0,
        value: score * participants_factor,
        taskDueDate,
        group: group_by_bucket_type
      };
      flatten_obj["participants"] = [];
      Object.keys(ACTION_OBJ).forEach(action => {
        if (score_breakdown[action]) {
          flatten_obj["participants"] = flatten_obj["participants"].concat(
            score_breakdown[action]["participants"]
          );
        } else if (total_users === 1) {
          flatten_obj["participants"] = flatten_obj["participants"].concat([
            group_by_bucket_type
          ]);
        }
        flatten_obj["participants"] = Array.from(
          new Set(flatten_obj["participants"])
        );
        return flatten_obj;
      });
      chart_engagement.push(flatten_obj);
    }
    return chart_engagement.sort((a, b) => {
      return a.date < b.date ? 1 : -1;
    });
  }

  function createParsedCourseEngagementData() {
    let parsed_dates = {};
    let days_range = eachDayOfInterval({ start, end });

    for (let i = 0; i < days_range.length; i++) {
      let date = days_range[i].getTime();
      parsed_dates[date] = {
        score: 0,
        participants_factor: 0,
        score_breakdown: {
          PARTICIPANTS_PERCENTAGE: 0,
          READING_SESSIONS: {
            participants: [],
            daily_activity_per_class: 0,
            daily_activity_per_active_users: 0
          },
          WRITING_SESSIONS: {
            participants: [],
            daily_activity_per_class: 0,
            daily_activity_per_active_users: 0
          },
          REAL_TIME_INTERACTIONS: {
            participants: [],
            daily_activity_per_class: 0,
            daily_activity_per_active_users: 0
          },
          QUESTION_INTERACTION: {
            participants: [],
            daily_activity_per_class: 0,
            daily_activity_per_active_users: 0,
            count_daily_activity_per_class: 0,
            count_daily_activity_per_active_users: 0
          },
          QUOTE_INTERACTIONS: {
            participants: [],
            daily_activity_per_class: 0,
            daily_activity_per_active_users: 0,
            count_daily_activity_per_class: 0,
            count_daily_activity_per_active_users: 0
          },
          SESSIONS: {
            participants: [],
            daily_activity_per_class: 0,
            daily_activity_per_active_users: 0,
            count_daily_activity_per_class: 0,
            count_daily_activity_per_active_users: 0
          }
        },
        total_users: 0
      };
    }
    return parsed_dates;
  }

  function resetDataBucketToStartOfDay(data) {
    let dates_obj = {};
    for (let bucket in data) {
      let start_of_day = startOfDay(fromUnixTime(bucket / 1000)).getTime();
      dates_obj[start_of_day] = data[bucket];
    }
    return dates_obj;
  }

  function createTaskDueDatesObj() {
    const tasksObj = {};
    if (!courseTasks.length) return tasksObj;
    courseTasks.forEach(task => {
      let due_date = new Date(Date.parse(task.original_due_date)).getTime();
      due_date = startOfDay(due_date).getTime();

      if (!(due_date in tasksObj)) tasksObj[due_date] = [];
      tasksObj[due_date].push(task.name);
    });
    return tasksObj;
  }

  function combineDataWithDueDates(data, due_dates) {
    for (const day in data) {
      data[day].taskDueDate = due_dates[day];
    }
    return data;
  }

  function toHoursAndMinutes(totalMinutes) {
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    if (totalMinutes < 60) return `${totalMinutes}min`;
    return `${hours}h,${minutes}min`;
  }

  function setToolTipData(tooltipData) {
    const classToolTip = tooltipData?.group === "class";
    const userToolTip = tooltipData?.group !== "class";

    if (tooltipData) {
      return (
        <>
          <Box className={classes.tooltipHeader}>
            <Typography variant="h5">Engagement</Typography>
            {/* hack: forcing a line brake by doubling the tag */}
            <Typography variant="h5">Breakdown</Typography>
            {classToolTip && (
              <>
                <Typography variant="body2">Class average</Typography>
                <Typography variant="body2">
                  {format(
                    new Date(fromUnixTime(tooltipData.date / 1000)),
                    "MM-dd-yyyy"
                  )}
                </Typography>
              </>
            )}
            <Typography variant="body2">
              Score: {Math.round(tooltipData.value)}
            </Typography>
          </Box>
          <Box>
            {userToolTip && (
              <Box>
                User: {_.truncate(`${tooltipData.group}`, { length: 10 })}
              </Box>
            )}
            {classToolTip && (
              <>
                <Box>Total Course Users: {allUserIds.length}</Box>
                <Box>Total Active Users: {tooltipData.participants.length}</Box>
              </>
            )}
            {actionsForAnalysis.includes("READING_SESSIONS") && (
              <Box>
                Reading Time: {toHoursAndMinutes(tooltipData.ReadingTime)}
              </Box>
            )}
            {actionsForAnalysis.includes("WRITING_SESSIONS") && (
              <Box>
                Writing Time: {toHoursAndMinutes(tooltipData.writingTime)}
              </Box>
            )}
            {actionsForAnalysis.includes("QUOTE_INTERACTIONS") && (
              <Box>Citations created: {tooltipData.citationsCreated}</Box>
            )}
            {actionsForAnalysis.includes("GUIDED_READING_INTERACTIONS") && (
              <Box>
                Questions created:
                {tooltipData.questionsCreated}
              </Box>
            )}
            {actionsForAnalysis.includes("REAL_TIME_INTERACTIONS") && (
              <Box>Comments: {tooltipData.comments}</Box>
            )}
            {actionsForAnalysis.includes("SESSIONS") && (
              <Box>
                Sessions: Time:{toHoursAndMinutes(tooltipData.sessions_sum)}{" "}
                Total:
                {tooltipData.sessions_count}
              </Box>
            )}
          </Box>
        </>
      );
    }
  }
  return (
    <Box id="wrapper" ref={containerRef} className={classes.container}>
      <ChartTooltip anchorEl={tooltipRef.current} open={showTooltip}>
        {setToolTipData(tooltipData)}
      </ChartTooltip>
      <Chart ref={ref} dimensions={dimensions}>
        <Axis dimension="x" scale={xScale} formatTick={formatDate} />
        <Axis dimension="y" scale={yScale} label={label} />

        <g>
          <Bars
            data={courseEngagement.filter(d =>
              isWeekend(fromUnixTime(d.date / 1000))
            )}
            keyAccessor={keyAccessor}
            xAccessor={weekendXAccessor}
            yAccessor={0}
            widthAccessor={singleDayWidth}
            heightAccessor={dimensions.boundedHeight}
            fill={"gray"}
            fillOpacity={0.2}
          />
        </g>
        <g>
          {Object.keys(groupedData).map(group => (
            <Line
              key={group}
              data={groupedData[group]}
              // data={data}
              stroke={`${zScale(group)}`}
              xAccessor={xAccessorScaled}
              yAccessor={yAccessorScaled}
            />
          ))}
        </g>
        <g>
          <Circles
            data={courseEngagement}
            keyAccessor={keyAccessor}
            xAccessor={xAccessorScaled}
            yAccessor={yAccessorScaled}
            radius={radiusAccessor}
            color={colorAccessor}
            variant={variantAccessor}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          />
        </g>
      </Chart>
      <Box
        className={classes.legend}
        style={{
          height: dimensions.marginBottom,
          width: dimensions.width,
          top: dimensions.height - dimensions.marginBottom
        }}
      >
        {Object.keys(groupedData).map(group => {
          return (
            <Box key={group}>
              <Box
                component="span"
                className={classes.legendIcon}
                style={{
                  backgroundColor: `${zScale(group)}`
                }}
              />
              {_.truncate(`${group}`, { length: 10 })}
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

EngagementChartOld.defaultProps = {
  xAccessor: d => d.x,
  yAccessor: d => d.y
};
export default EngagementChartOld;
