// dependancies
import { httpCallables } from "../../../firebase";
import { v4 as uuid } from "uuid";
import { captureException } from "../../../utils/errorHandlers";

// Redux
import { addSnackbarItem } from "../../snackbarSlice";
import store from "../../store";

// Rx
import { ofType } from "redux-observable";
import { of } from "rxjs";
import {
  map,
  catchError,
  exhaustMap,
  tap,
  ignoreElements
} from "rxjs/operators";
import { addText, undoCreateText } from "../../textsSlice";

const CALLBACK = httpCallables.textFunctions;
const ACTION = "undoCreateText";
const ERROR_MSG = `UNDO_CREATE_TEXT`;

export const undoCreateTextEpic = (action$, state$, { fetchAsObservable }) => {
  return action$.pipe(
    // queues an offline task for submitting a task
    ofType(undoCreateText.type),
    map(({ payload }) => ({
      text: payload.text,
      args: {
        text_id: payload.text.id,
        offline_task_id: payload.offline_task_id
      }
    })),
    exhaustMap(({ text, args }) =>
      fetchAsObservable(CALLBACK, ACTION, ERROR_MSG, args).pipe(
        // Don't do anything with the response
        ignoreElements(),

        catchError(error => {
          captureException(error, "Error in undoCreateTextEpic");
          alertUser();

          // rollback
          return of({ type: addText.type, payload: text });
        })
      )
    )
  );
};

function alertUser() {
  store.dispatch({
    type: addSnackbarItem.type,
    payload: {
      intlId: "error.undoFailed",
      id: uuid()
    }
  });
}
