import React, { useState } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { makeStyles } from "@mui/styles";
import { uniq } from "lodash";
import QuestionsAccordionHeader from "./QuestionsAccordionHeader";
import QuestionsAccordionCard from "./QuestionsAccordionCard";
import { useSelector } from "react-redux";
import { interactionsAPI, standardTaskAPI } from "../../../../api";
import { selectQuestionHighlights } from "../../../../redux/interactionsSlice";
import TYPES from "../../../../types";
import { useQuery } from "../../../../hooks";
import { selectSubmission } from "../../../../redux/tasksSlice";
import { Box, List, ListItem, Collapse } from "@mui/material";
const useStyles = makeStyles(() => ({
  cardsList: {
    overflow: "hidden"
  }
}));

export default function QuestionsAccordion({
  question,
  onCardDrop = () => {},
  disableActions = false,
  forceQuestionSelection = false,
  showInText
}) {
  const classes = useStyles();
  const { submission_id } = useQuery();

  const submission = useSelector(state =>
    selectSubmission(state, Number(submission_id))
  );
  const selectedInteractionId = useSelector(
    state => state.interactions.selectedInteractionId
  );
  const highlights = useSelector(state =>
    selectQuestionHighlights(state, question.id)
  );
  const [expandedItems, setExpandedItems] = useState([]);

  function getSubTitle(question) {
    return question.includeCitation ||
      question.interaction_subtype === "FIND_IN_TEXT" ? (
      <FormattedMessage
        defaultMessage="Highlighting is mandatory"
        id="interactions.mandatory"
      />
    ) : (
      <FormattedMessage
        defaultMessage="Highlighting is optional"
        id="interactions.optional"
      />
    );
  }

  function deleteHighlight(highlight) {
    interactionsAPI.deleteHighlight(highlight);
  }

  return (
    <Box key={question.id} data-test-class="question-accordion-question">
      <QuestionsAccordionHeader
        forceQuestionSelection={forceQuestionSelection}
        isSelected={question.id === selectedInteractionId}
        id={question.id}
        onClick={value => {
          standardTaskAPI.updateSelectedQuestionId(value, submission.task_id);
        }}
        onExpand={exapnded => {
          if (exapnded) {
            setExpandedItems(uniq(expandedItems.concat([question.id])));
          } else {
            setExpandedItems(expandedItems.filter(itm => itm !== question.id));
          }
        }}
        title={question.content}
        subTitle={getSubTitle(question)}
        onCardDrop={onCardDrop}
        disableDrop={disableActions}
      />
      <Collapse in={expandedItems.includes(question.id)}>
        <List>
          {highlights.map((highlight, index) => (
            <ListItem>
              <QuestionsAccordionCard
                showInText={showInText}
                card={highlight}
                key={highlight.id}
                qId={question.id}
                onDelete={highlight => deleteHighlight(highlight)}
                disableActions={disableActions}
                cardIndex={index}
              />
            </ListItem>
          ))}
        </List>
      </Collapse>
    </Box>
  );
}

QuestionsAccordion.propTypes = {
  question: PropTypes.shape({
    id: PropTypes.string.isRequired,
    content: PropTypes.string,
    interaction_subtype: TYPES.INTERACTION_SUBTYPE,
    includeCitation: PropTypes.bool
  }),
  getSubTitle: PropTypes.func,
  onCardDrop: PropTypes.func,
  disableActions: PropTypes.bool,
  forceQuestionSelection: PropTypes.bool,
  showInText: PropTypes.oneOf(["inline", "dialog"]).isRequired
};
