// dependancies
import { captureException } from "../../../../utils/errorHandlers";

// Redux
import { CHATBOX_STATUS } from "../../../chatSlice";
// Rx
import { ofType } from "redux-observable";
import { empty } from "rxjs";
import {
  map,
  withLatestFrom,
  delayWhen,
  switchMap,
  catchError
} from "rxjs/operators";
import { interval, of } from "rxjs";
import { FEATURE_FLAGS, TASK } from "../../../../consts";
import {
  isFeatureFlagEnabled,
  questionHasHighlights
} from "../../eMentorEpics/utils";
import { hideFab, showFab } from "../../../fabSlice";

export const displayStandardTaskFabEpic = (action$, state$) => {
  return action$.pipe(
    // These types are coming from a firebase middleware. The upload flow is currently messy and should be changed
    ofType("gotAnswers"),
    withLatestFrom(state$),
    switchMap(([action, state]) =>
      shouldDisplayFab(state, action).pipe(
        // delaying inside the inner observ' because the diffrent delay value
        delayWhen(shouldDisplay => interval(shouldDisplay ? 500 : 0))
      )
    ),
    map(shouldDisplay => ({
      type: shouldDisplay ? showFab.type : hideFab.type,
      payload: {}
    })),
    catchError(error => {
      captureException(error, "Error in displayStandardTaskFabEpic");
      return empty();
    })
  );
};

// utils
function shouldDisplayFab(state, action) {
  const submissionId = Number(state.router.location.query.submission_id);
  const submission = state.tasks.submissions.find(
    submission => submission.id === submissionId
  );
  const selectedInteraction = state.interactions.selectedInteractionId;

  const featureTurnedOn = isFeatureFlagEnabled(
    state,
    FEATURE_FLAGS.E_MENTOR_REVIEW
  );
  const isNotSubmitted = submission.status !== TASK.SUBMISSION_STATUS.SUBMITTED;
  const isReviewStep = action.payload?.step === "review";
  const hasSelectedInteraction = !!selectedInteraction;
  const hasHighlights = questionHasHighlights(state);
  const isChatClosed = state.chat.status === CHATBOX_STATUS.CLOSED;

  // Conditions for displaying the chat FAB
  return of(
    isNotSubmitted &&
      isReviewStep &&
      hasSelectedInteraction &&
      hasHighlights &&
      isChatClosed &&
      featureTurnedOn
  );
}
