// dependancies
import { reFetchSubmission } from "../../tasksSlice";
import { httpCallables } from "../../../firebase";

// Redux
import { setInteractions } from "../../interactionsSlice";

// Rx
import { ofType } from "redux-observable";
import { EMPTY } from "rxjs";
import { map, switchMap, catchError } from "rxjs/operators";
import { captureException } from "../../../utils/errorHandlers";

const CALLBACK = httpCallables.taskFunctions;
const ACTION = "readSubmission";
const ERROR_MSG = `FETCH_SUBMISSION`;

export const fetchSubmissionEpic = (action$, state$, { fetchAsObservable }) => {
  return action$.pipe(
    ofType(reFetchSubmission.type),
    map(({ payload }) => ({ submission_id: Number(payload) })),
    switchMap(args =>
      fetchAsObservable(CALLBACK, ACTION, ERROR_MSG, args).pipe(
        map(({ interactions }) => ({
          type: setInteractions.type,
          payload: interactions
        }))
      )
    ),
    catchError(error => {
      captureException(error, "Error in fetchSubmissionEpic");
      return EMPTY;
    })
  );
};
