// Dependencies
import React, { useCallback, useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { interval, of, fromEvent } from "rxjs";
import { map, take, concatMap, tap, mergeMap, takeUntil } from "rxjs/operators";
// Redux

// Material UI
import { Typography, Grow } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

// Styles
const useStyles = makeStyles(theme => {
  return {
    bubble: {
      display: "block",
      width: "fit-content",
      marginBottom: theme.spacing(1),
      padding: theme.spacing(1.5),
      borderRadius: "8px"
    },
    incoming: {
      marginInlineEnd: theme.spacing(3),
      background: theme.palette.primary.main,
      color: theme.palette.primary.contrastText
    },
    outgoing: {
      marginInlineStart: theme.spacing(3),
      background: theme.palette.action.focus,
      alignSelf: "end"
    }
  };
});

function ChatBubble({ content, variation, scroll, animate = false }) {
  // Hooks
  const classes = useStyles();
  const ref = useRef();
  // Redux Selectors

  // Ephermeral state
  const [contentForDisplay, setContentForDisplay] = useState("");
  // Derived state

  // Behavior

  // typewriter effect
  const scrollContainer = useCallback(stream$ => {
    return stream$.pipe(tap(scroll()));
  }, []);

  const typeEffect = useCallback(({ content, speed }) => {
    return interval(speed).pipe(
      map(x => content.substr(0, x + 1)),
      take(content.length)
    );
  }, []);

  useEffect(() => {
    if (animate) {
      const words$ = of(content);
      const scroll$ = fromEvent(ref.current.parentElement, "wheel");
      const typedWords$ = words$.pipe(
        concatMap(words => typeEffect({ content: words, speed: 70 }))
      );

      // TODO: need to add a logic for the user to tap back to autoscroll
      const scrollContainer$ = typedWords$.pipe(
        map(words => of(words)),
        mergeMap(stream$ => scrollContainer(stream$)),
        takeUntil(scroll$)
      );

      typedWords$.subscribe(content => setContentForDisplay(content));
      scrollContainer$.subscribe();
    } else setContentForDisplay(content);
  }, [animate, content, scrollContainer, typeEffect]);

  return (
    <Grow in={true} timeout={500}>
      <Typography
        variant="body2"
        ref={ref}
        component={"span"}
        className={clsx(classes.bubble, classes[variation?.toLowerCase()])}
      >
        {contentForDisplay}
      </Typography>
    </Grow>
  );
}

ChatBubble.propTypes = {
  content: PropTypes.node.isRequired,
  variation: PropTypes.oneOf(["INCOMING", "OUTGOING"]).isRequired,
  animate: PropTypes.bool,
  scroll: PropTypes.func
};

export default ChatBubble;
