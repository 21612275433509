// Dependencies
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useIntl } from "react-intl";
import { format } from "date-fns";
import clsx from "clsx";
import { useQuery, useConvertToTimezone } from "../../../hooks";

// Redux Dependencies
import { selectCourse } from "../../../redux/coursesSlice";
import {
  selectTask,
  selectSubmission,
  selectSubmissionCollaboration
} from "../../../redux/tasksSlice";

//Components
import TaskStatusChip from "./TaskStatusChip";
import PeerReviewStatusChip from "./PeerReviewStatusChip";
import { navigateToTaskHandler } from "../../../utils/navigateToTaskHandler";

// Material UI
import makeStyles from "@mui/styles/makeStyles";
import {
  TableRow,
  TableCell,
  Typography,
  IconButton,
  Chip
} from "@mui/material";
import MapOutlinedIcon from "@mui/icons-material/MapOutlined";
import TooltipWithIntl from "../../SharedComponents/tooltip/TooltipWithIntl";
import SubmittingChip from "../../SharedComponents/SubmittingChip";

// Styles
const useStyles = makeStyles(theme => ({
  tasksHeader: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    margin: "60px 0"
  },
  chip: {
    cursor: "pointer",
    "&:hover": {
      backgroundColor: theme.palette.text.blue
    }
  },
  active: {
    backgroundColor: "transparent",
    color: "#0eac72",
    border: "1px solid #0eac72"
  },
  pending: {
    backgroundColor: "transparent",
    border: "1px solid black"
  },
  delivered: {
    backgroundColor: "#0eac72"
  },
  graded: {
    backgroundColor: "#0eac72"
  },
  new: {
    border: "1px solid black"
  },
  left: {
    textAlign: "left"
  },
  right: {
    textAlign: "right"
  },
  submitted: {
    backgroundColor: "transparent",
    color: "#0eac72",
    border: "1px solid #0eac72"
  },
  received: {
    color: theme.palette.primary.contrast,
    backgroundColor: theme.palette.primary.main
  },
  createBtn: {
    borderRadius: "4px",
    valign: "bottom",
    position: "absolute",
    bottom: "0px",
    right: "0px"
  },
  container: {
    width: "100w",
    height: "100vh"
  },
  clickableTask: {
    cursor: "pointer",
    "&:focus": {
      backgroundColor: "rgba(46, 125, 50, 0.04)"
    },
    height: "70px"
  },
  newTask: {
    fontWeight: "800"
  },
  dialog: {
    backgroundColot: "red"
  },
  tableContainer: {
    width: "100%",
    marginLeft: "auto",
    marginRight: "auto",
    marginBottom: "24px"
  }
}));
export default function SubmissionsListItem({ submission }) {
  //Hooks
  const classes = useStyles();
  const intl = useIntl();
  const dispatch = useDispatch();
  const history = useHistory();
  const { course_id } = useQuery();

  // Redux State

  const course = useSelector(state => selectCourse(state, Number(course_id)));
  const task = useSelector(state => selectTask(state, submission.task_id));
  const currentUserId = useSelector(state => state.user.auth.uid);
  const collaboration = useSelector(state =>
    selectSubmissionCollaboration(state, submission.id)
  );

  const parent_submission = useSelector(state =>
    selectSubmission(state, submission.related_submission_id)
  );
  const [formattedDueDate, TZdueDate] = useConvertToTimezone(
    submission.due_date,
    course.timezone
  );

  const [submissionDate, TZsubmissionDate] = useConvertToTimezone(
    submission.submission_date
  );

  // Derived State
  // 05/01/22 until we re-normalize the DB and refactor this:
  // if this is a collaboration it won't have a submission id.
  const isOwner = submission.owner === currentUserId;
  const isCollaboration = submission.related_submission_id !== null;

  const isNew = task => {
    let now = new Date();
    // If this is the owener
    if (!isCollaboration) {
      return now < TZdueDate && submission.status === "Pending";

      // If this is the collaborator
    } else {
      return now < TZdueDate && submission.status === "Pending";
    }
  };

  function handleClick() {
    navigateToTaskHandler(task, submission, history, dispatch);
  }

  if (!isOwner) return null;
  return (
    <TableRow
      data-test-class="task-row"
      className={clsx(isNew(task) && classes.newTask, classes.clickableTask)}
      onClick={handleClick}
      onKeyPress={e => {
        if (e.key === "Enter") handleClick();
      }}
      role="link"
      tabIndex="0"
    >
      <TableCell
        className={clsx(classes.cell, isNew(task) && classes.newTask)}
        component="th"
        scope="row"
      >
        {task.task_type === "peerReview" && isCollaboration
          ? `Review request from ${parent_submission.user_name}: ${task.name}`
          : task.name}
      </TableCell>

      {submission.status === "SUBMITTING" ? (
        <TableCell className={clsx(classes.cell)} align="left">
          <SubmittingChip />
        </TableCell>
      ) : (
        <>
          <TableCell
            className={clsx(classes.cell, isNew(task) && classes.newTask)}
            align="left"
          >
            {task.task_type === "peerReview" ? (
              <PeerReviewStatusChip
                task={task}
                submission={submission}
                collaboration={collaboration}
                userRole="Student"
                onClick={handleClick}
              />
            ) : (
              <TaskStatusChip
                task={task}
                submission={submission}
                userRole="Student"
                onClick={handleClick}
              />
            )}
          </TableCell>
        </>
      )}
      <TableCell
        className={clsx(classes.cell, isNew(task) && classes.newTask)}
        align="left"
      >
        {format(new Date(formattedDueDate), "MMM dd")}
      </TableCell>
      <TableCell
        className={clsx(classes.cell, isNew(task) && classes.newTask)}
        align="left"
      >
        {submission.is_checked && (
          <Chip
            color="primary"
            label="Recieved"
            size="small"
            className={clsx(classes.chip, classes.received)}
          />
        )}
      </TableCell>
      <TableCell
        className={clsx(
          classes.cell,
          isNew(task) && classes.newTask,
          classes.link,
          classes.gradeWithHeatmap
        )}
        align="left"
      >
        <Typography className={classes.pointer} style={{ margin: "16px" }}>
          {submission.grade
            ? "" +
              (submission.grade ? submission.grade : "-") +
              "/" +
              task.max_grade
            : submission.is_checked &&
              intl.formatMessage({
                id: "tasks.see.feedback",
                defaultMessage: "See feedback"
              })}
        </Typography>
      </TableCell>
      <TableCell
        className={clsx(
          classes.cell,
          isNew(task) && classes.newTask,
          classes.link,
          classes.gradeWithHeatmap
        )}
        align="left"
      >
        {submissionDate && task.task_type !== "peerReview" && (
          <TooltipWithIntl
            intlStringId="task.see.heatmap"
            defaultMessage="View task's heatmap"
            placement="bottom"
          >
            <IconButton
              onClick={e => {
                history.push(`/task/stats?task_id=${task.id}`);
                e.stopPropagation();
                e.preventDefault();
              }}
              className={classes.actionIcon}
              aria-label="task heatmap"
              size="large"
            >
              <MapOutlinedIcon />
            </IconButton>
          </TooltipWithIntl>
        )}
      </TableCell>
    </TableRow>
  );
}
