// Dependencies
import React, { useEffect } from "react";
import { useQuery } from "../../../hooks";

// Redux
import { useDispatch, useSelector } from "react-redux";
import { selectSubmission } from "../../../redux/tasksSlice";
import clsx from "clsx";

// Components
import TaskFeedback from "../TaskFeedback/taskFeedback";
import StandardTaskContent from "./StandardTaskContent";
import StandardTaskSidebar from "./StandardTaskSidebar";
import SidePanel from "../../SharedComponents/SidePanel";
import PrimarySidebarLayout from "../../SharedComponents/PrimarySidebarLayout";
import { SecondarySidebarLayout } from "../../SharedComponents";

// Material UI
import CommentPanel from "../../comments/CommentPanel";

import {
  selectIsComments,
  selectSecondarySidebarCollapsed,
  selectIsThumbnails,
  selectIsPrimarySidebar,
  selectIsSuggestionsInSidebar,
  selectIsSuggestions
} from "../../../redux/firestoreSelectors";

import PDFThumbBar from "../../reader/pdf/sidebar/PDFThumbBar";

import makeStyles from "@mui/styles/makeStyles";
import { Box } from "@mui/material";
import {
  setSecondarySidebarViewMode,
  togglePrimarySidebar
} from "../../../redux/layoutSlice";
import JourneyHeaderMenu from "../../menus/JourneyHeaderMenu";
import JourneyPanel from "../../journey/journeyPanel";
import { isPdfSelector } from "../../../redux/textsSlice";
import { SECONDARY_SIDEBAR_STATE } from "../../../consts";

const useStyles = makeStyles(theme => {
  return {};
});

function StandardTask() {
  // Hooks
  const { submission_id } = useQuery();
  const classes = useStyles();
  const dispatch = useDispatch();

  // Redux Selectors
  const isThumbnails = useSelector(state => selectIsThumbnails(state));
  const isComments = useSelector(state => selectIsComments(state));
  const taskStep = useSelector(state => state.task.step);
  const isSuggestions = useSelector(state => selectIsSuggestions(state));
  const selectIsSuggestionsInSecondarySidebar = useSelector(state =>
    selectIsSuggestionsInSidebar(state)
  );
  const isSecondarySidebar = useSelector(state =>
    selectSecondarySidebarCollapsed(state)
  );
  const isPrimarySidebar = useSelector(state => selectIsPrimarySidebar(state));
  const submission = useSelector(state =>
    selectSubmission(state, Number(submission_id))
  );
  const isPdf = useSelector(isPdfSelector);

  const courseRole = useSelector(
    state => state.user.userProfile.selectedCourse.course_role
  );
  const PrimarySidebarWidth = useSelector(
    state => state.layout.primarySidebarWidth
  );
  const SecondarySidebarWidth = useSelector(
    state => state.layout.secondarySidebarWidth
  );
  // Ephemeral state

  //Derived state

  //user rolr
  const isTeacher = courseRole === "Teacher";
  // yes, we can use !isTeacher, but it is less readable
  const isStudent = courseRole === "Student";
  const isStepHighlight = taskStep === "highlight";
  //submission status
  const status = submission.status;
  const isGraded = status === "Graded";
  const isSubmitted = status === "Submitted";

  //Behavior
  useEffect(() => {
    if (!isPrimarySidebar) dispatch(togglePrimarySidebar());
    if (
      !isPdf &&
      (selectIsSuggestionsInSecondarySidebar || isThumbnails || isSuggestions)
    )
      dispatch(setSecondarySidebarViewMode(SECONDARY_SIDEBAR_STATE.COLLAPSED));
  }, []);

  function shouldDisplaySuggestions() {
    if (isPdf && isSuggestions) {
      return <JourneyHeaderMenu />;
    }
    return null;
  }
  // Render
  if (isStudent && !isGraded)
    return (
      <>
        <PrimarySidebarLayout>
          <SidePanel />
          <StandardTaskSidebar />
        </PrimarySidebarLayout>
        <Box
          className={clsx(classes.readerViewContainer, classes.dirLtr)}
          style={{
            width:
              isStepHighlight && isSecondarySidebar
                ? `calc(100vw - ${PrimarySidebarWidth}px - ${SecondarySidebarWidth}px)`
                : `calc(100vw - ${PrimarySidebarWidth}px`
          }}
        >
          {shouldDisplaySuggestions()}
          <StandardTaskContent />
        </Box>
        {isStepHighlight ? ( // only show the secoundary sidebar when the reader is on view
          <SecondarySidebarLayout collapsed={!isSecondarySidebar}>
            {isThumbnails && <PDFThumbBar />}
            {isComments && <CommentPanel />}
            {isPdf && isSuggestions && <JourneyPanel suggestions />}
          </SecondarySidebarLayout>
        ) : (
          <></>
        )}
      </>
    );
  if (isTeacher && isSubmitted) return <TaskFeedback />;
  if (isGraded) return <TaskFeedback />;
}

StandardTask.propTypes = {};

export default StandardTask;
