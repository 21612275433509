// Dependencies
import React from "react";
import PropTypes from "prop-types";
import { generateEditorOutput } from "./utils";
import { useTextEditorContext } from "./TextEditor";

// Material UI
import { Button } from "@mui/material";

function TextEditorButton({
  onClick,
  children,
  type = "custom",
  resetOnClick = false,
  ...restProps
}) {
  //Hooks
  const {
    editorState,
    disabled,
    resetEditorState,
    editEditorState,
    restorePreviousContentEditorState,
    setIsDisabled
  } = useTextEditorContext();

  // Derived state
  const excludeFromDisable = ["edit"];
  // Behavior

  function OnClickWithState(type, editorState) {
    // I don't want to expose the rest and edit to the parent so I am hard codeing them and controling them with the type prop. A diffrent solution should be implemented if the list of hard coded methods grow
    switch (type) {
      case "reset":
        resetEditorState();
        break;
      case "restore":
        restorePreviousContentEditorState(editorState);
        onClick();
        break;
      case "post":
        let postOutput = generateEditorOutput(editorState);
        onClick(postOutput);
        resetEditorState();
        break;
      case "update":
        let updatedOutput = generateEditorOutput(editorState);
        setIsDisabled(true);
        onClick(updatedOutput);
        break;
      case "edit":
        editEditorState();
        onClick();
        break;
      default:
        // Passing a function decleration with an argument to onClick will return the current content as rich text and plain text
        let output = generateEditorOutput(editorState);
        onClick(output);
        resetOnClick && resetEditorState();
    }
  }

  //Render
  return (
    <Button
      onClick={() => OnClickWithState(type, editorState)}
      disabled={!excludeFromDisable.includes(type) && disabled}
      // allowing for all the MUI button props to be passed from the parent
      {...restProps}
    >
      {children}
    </Button>
  );
}

TextEditorButton.propTypes = {
  children: PropTypes.string,
  onClick: PropTypes.func,
  type: PropTypes.oneOf([
    "custom",
    "reset",
    "edit",
    "restore",
    "update",
    "post"
  ]),
  resetOnClick: PropTypes.bool
};

export default TextEditorButton;
