import { httpCallables } from "../firebase";
import { v4 as uuid } from "uuid";
import {
  createAnswer,
  deleteAnswer,
  createReview,
  deleteReview,
  createFeedback,
  deleteFeedback,
  createComment,
  deleteComment
} from "../redux/interactionsSlice";
import store from "../redux/store";
import { captureException } from "../utils/errorHandlers";
import { setIsLoading } from "../redux/readerActionsSlice";
import { updateTaskSubmissionStatus } from "../redux/tasksSlice";
import history from "../history";
import { addSnackbarItem } from "../redux/snackbarSlice";

const peerReviewAPI = {
  createReflection: async function (reflection) {
    if (!reflection) return;
    const id = uuid();
    try {
      const reflectionWithId = {
        id,
        ...reflection,
        func_name: "createAnswerComment"
      };
      store.dispatch(createAnswer(reflectionWithId));
      return await httpCallables.interactionFunctions(reflectionWithId);
    } catch (err) {
      captureException(err);
      store.dispatch(deleteAnswer(reflection));
    }
  },
  createReview: async function (review) {
    if (!review) return;
    const id = uuid();
    try {
      const reviewWithId = { id, ...review, func_name: "createReview" };
      store.dispatch(createReview(reviewWithId));
      return await httpCallables.interactionFunctions(reviewWithId);
    } catch (err) {
      captureException(err);
      store.dispatch(deleteReview({ review }));
    }
  },
  undoReflectionSubmission: async function (submission_id) {
    try {
      store.dispatch(setIsLoading(true));
      await httpCallables
        .taskFunctions({
          func_name: "updatePeerReviewReflectionUndoSubmission",
          submission_id
        })
        .then(({ data }) => {
          const { success } = data;
          if (success) {
            store.dispatch(
              updateTaskSubmissionStatus({
                id: submission_id,
                status: "Active",
                submission_date: null
              })
            );
            history.push(`/task?submission_id=${submission_id}`);
          } else {
            store.dispatch(
              addSnackbarItem({
                intlId: "undo.undoSubmissionFailed",
                intlDefaultMessage: "Undo failed", // TODO: need text from product
                id: uuid() // TODO: we are not capturing this id, why do we need to pass it and not create it in the SnackBar component?
              })
            );
            let error = new Error(`UNDO_TASK_SUBMISSION_FAILED`);
            error.message = data.error;
            error.data = { submission_id };
            throw error;
          }
        });
    } catch (err) {
      captureException(err);
    } finally {
      store.dispatch(setIsLoading(false));
    }
  },
  undoReviewSubmission: async function (queueItem) {
    try {
      store.dispatch(setIsLoading(true));
      await httpCallables
        .taskFunctions({
          func_name: "updatePeerReviewSubmissionUndoSubmission",
          queueItem
        })
        .then(({ data }) => {
          const { success } = data;

          if (success) {
            store.dispatch(
              updateTaskSubmissionStatus({
                id: queueItem.submission_id,
                status: "Pending",
                submission_date: null
              })
            );
            history.push(
              `/task?submission_id=${queueItem.related_submission_id}`
            );
          } else {
            store.dispatch(
              addSnackbarItem({
                intlId: "undo.undoSubmissionFailed",
                intlDefaultMessage: "Undo failed", // TODO: need text from product
                id: uuid() // TODO: we are not capturing this id, why do we need to pass it and not create it in the SnackBar component?
              })
            );
            let error = new Error(`UNDO_TASK_SUBMISSION_FAILED`);
            error.message = data.error;
            error.data = { queueItem };
            throw error;
          }
        });
    } catch (err) {
      captureException(err);
    } finally {
      store.dispatch(setIsLoading(false));
    }
  },
  createFeedback: async function (feedback) {
    if (!feedback) return;
    const id = uuid();
    try {
      const feedbackWithId = {
        func_name: "createTaskFeedback",
        id,
        ...feedback
      };
      store.dispatch(createFeedback(feedbackWithId));
      httpCallables.interactionFunctions(feedbackWithId);
    } catch (err) {
      captureException(err);
      store.dispatch(deleteFeedback({ feedback }));
    }
  },
  createComment: async function (reply) {
    if (!reply) return;
    const id = uuid();
    try {
      const replyWithId = { id, ...reply };
      store.dispatch(createComment(replyWithId));
      httpCallables.submitPeerReviewReply(replyWithId);
    } catch (err) {
      captureException(err);
      store.dispatch(deleteComment({ reply }));
    }
  }
};

export default peerReviewAPI;
