// Dependencies
import React, { useEffect, useState, useRef } from "react";
import { useIntl } from "react-intl";
import clsx from "clsx";

// Redux dependencies
import { useSelector, useDispatch } from "react-redux";
import { enqueueFlashMessage, undo } from "../../../redux/userSlice";

// Components
import TaskQuestionView from "./TaskQuestionView";
import NavigationButtons from "./NavigationButtons";
import { QUESTION, TASK } from "../../../consts";

import makeStyles from "@mui/styles/makeStyles";
import {
  Box,
  Paper,
  FormControl,
  TextField,
  Typography,
  ListItemText,
  MenuItem
} from "@mui/material";

//Styles
const useStyles = makeStyles(theme => ({
  right: {
    textAlign: "right"
  },
  left: {
    textAlign: "left"
  },
  coloredUnderline: {
    " &:after": {
      borderBottom: "2px solid #168FEE"
    }
  },
  paperClass: {
    marginTop: "20px",
    borderRadius: "0px",
    textAlign: "left",
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
      color: "#168FEE"
    },
    "& .MuiInput-underline": {
      "&:after": {
        borderBottom: "2px solid #168FEE"
      }
    },
    "&:after": {
      borderBottom: "2px solid #168FEE"
    },
    "&:focused::after": {
      borderBottom: "2px solid #168FEE"
    },
    "& .MuiInputLabel-root.Mui-focused": {
      color: "#168FEE"
    }
  },
  addQuestion: {
    marginTop: "20px",
    borderRadius: "0px",
    textAlign: "left",
    cursor: "pointer",
    color: theme.palette.text.disabled,
    "& :hover": {
      color: theme.palette.text.primary
    },
    "&:focus-visible": {
      color: theme.palette.text.primary
    }
  },
  paddedContent: {
    paddingLeft: "24px",
    paddingTop: "16px",
    paddingBottom: "16px"
  },
  taskDesc: {
    marginBottom: "16px"
  },

  paddedWholeContent: {
    display: "flex",
    flexFlow: "column nowrap",
    paddingInline: "24px",
    paddingBlock: "16px"
  },

  formRow: {
    marginBlockEnd: "16px",
    display: "flex",
    flexFlow: "row nowrap",
    "& .MuiFormControl-root": {
      flex: "1 1 0",
      marginInlineEnd: "8px"
    },
    "& .MuiFormControl-root:last-child": {
      marginInlineEnd: "0"
    },
    "& $taskTitle": {
      flexGrow: "2"
    }
  },
  // Dont delete, .taskTitle is referenced somewhere else
  taskTitle: {},
  listItem: {
    marginBottom: "0px",
    lineHeight: "1rem"
  },
  // for peer review
  questionContainer: {
    marginTop: "20px",
    borderRadius: "0px",
    textAlign: "left"
  },

  questionHeader: {
    paddingInline: "24px",
    paddingBlock: "16px"
  },
  questionBody: {
    display: "flex",
    flexFlow: "column nowrap",
    paddingInline: "24px",
    paddingBlock: "16px"
  },
  questionActions: {
    display: "flex",
    flexFlow: "row nowrap",
    alignItems: "center",
    paddingInline: "24px",
    paddingBlock: "16px"
  },
  wordLimitInputRoot: {
    width: "60px",
    marginInlineEnd: "16px",
    "& input": {
      padding: "16px 8px",
      textAlign: "center"
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "#168fee"
    },
    "&.Mui-focused fieldset": {
      borderColor: "rgba(0, 0, 0, 0.23) !important",
      borderWidth: "1px !important"
    }
  },
  wordLimitLabel: {
    cursor: "pointer"
  }
}));

export default function StepAddQuestions({
  questions,
  setQuestions,
  activeGrTasks = [],
  readOnly,
  text,
  taskType,
  taskTitle,
  setTaskTitle,
  setTaskType,
  taskDescription,
  setTaskDescription,
  setActiveStep,
  activeStep,
  publishTask
}) {
  //Hooks
  const classes = useStyles();
  const intl = useIntl();
  const dispatch = useDispatch();
  const wordLimitRef = useRef();

  //Redux State
  const shouldUndo = useSelector(state => state.user.undo);
  const alertsDuration = useSelector(
    state => state.user.userProfile.alertsDuration
  );

  //Ephemeral State
  const [undoData, setUndoData] = useState(null);

  //Variables

  const taskTypes = [
    {
      value: "guidedReading",
      text: intl.formatMessage({
        id: "task.type.guidedReading",
        defaultMessage: "Guided Reading"
      })
    },
    {
      value: "standard",
      text: intl.formatMessage({
        id: "task.type.standard",
        defaultMessage: "Standard"
      })
    },
    {
      value: "peerReview",
      text: intl.formatMessage({
        id: "task.type.peerReview",
        defaultMessage: "Peer review"
      })
    }
  ];

  const titleValidationMessage = intl.formatMessage({
    id: "tasks.create.validation.enterTitle",
    defaultMessage: "Enter a task title"
  });
  const questionValidationMessage = intl.formatMessage({
    id: "tasks.create.validation.enterQuestion",
    defaultMessage: "Add a question"
  });
  const formulationValidationMessage = intl.formatMessage({
    id: "tasks.create.validation.enterFormulation",
    defaultMessage: "Add question formulation to question"
  });
  const questionTypeValidationMessage = intl.formatMessage({
    id: "tasks.create.validation.selectType",
    defaultMessage: `Select question type for question`
  });

  const optionsValidationMessage = intl.formatMessage({
    id: "tasks.create.validation.optionsValue",
    defaultMessage: "Multiple choice question must have more than one option "
  });
  const optionsChoiceValidationMessage = intl.formatMessage({
    id: "tasks.create.validation.optionChoice",
    defaultMessage: "Please select correct answer choice for question"
  });

  //Behavior
  useEffect(() => {
    const undoDeleteQuestion = () => {
      if (undoData.type === "deleteQuestion") {
        let currentQuestions = [...questions];
        currentQuestions.splice(undoData.index, 0, undoData.question);

        setQuestions(currentQuestions);
        setUndoData(null);
      }
    };

    if (shouldUndo) {
      undoDeleteQuestion();
      dispatch(undo(false));
    }
  }, [shouldUndo, dispatch, undoData, questions, setQuestions]);

  // UPDATE - 06/06/23, we've decided to allow only standard task in pilot.
  // If we want to return all task types we should keep this function
  function handleTaskTypeChange(taskType) {
    // hard coding question types for peer review and GR because they dont have a question type dropdown
    if (taskType === TASK.TYPE.PEER_REVIEW) {
      setQuestions([QUESTION.PEER_REVIEW]);
    } else if (taskType === TASK.TYPE.GUIDED_READING) {
      setQuestions([QUESTION.GUIDED_READING]);
    } else {
      setQuestions([QUESTION.OPEN_ENDED]);
    }
  }

  const updateQuestions = (update, index) => {
    let updatedQuestions = [...questions];
    updatedQuestions[index] = { ...questions[index], ...update };
    updatedQuestions[index].order = index;
    setQuestions(updatedQuestions);
  };

  const addQuestion = () => {
    const questionType =
      taskType === TASK.TYPE.GUIDED_READING
        ? QUESTION.GUIDED_READING
        : QUESTION.OPEN_ENDED;

    setQuestions([...questions, questionType]);
  };

  const copyQuestion = question => {
    setQuestions([...questions, question]);
  };

  function deleteQuestion(index) {
    let currentQuestions = [...questions];
    if (currentQuestions.length > 1 && currentQuestions.length > index) {
      let deletedQuestion = currentQuestions[index];
      currentQuestions.splice(index, 1);

      setUndoData({
        type: "deleteQuestion",
        question: deletedQuestion,
        index: index
      });

      dispatch(
        enqueueFlashMessage({
          message: intl.formatMessage({
            id: "task.deletedQuestion",
            defaultMessage: "Deleted question"
          }),
          duration: alertsDuration,
          undoButton: true
        })
      );

      setQuestions(currentQuestions);
    }
  }

  function validateForm() {
    let message = [];
    // Check if there is a title
    if (!taskTitle) {
      message.push(titleValidationMessage);
    }
    // Check that there is at least one question
    if (
      taskType !== "peerReview" &&
      questions.length <= 1 &&
      questions[0].question === ""
    ) {
      message.push(questionValidationMessage);
    } else {
      // if there are questions, check that each have...
      questions.forEach((question, index) => {
        // A question formulation
        if (taskType !== "peerReview" && question.content.trim() === "") {
          message.push(`${formulationValidationMessage} ${index + 1}`);
        }
        // A type selected
        if (
          question.interaction_subtype === "" &&
          taskType !== "guidedReading"
        ) {
          message.push(`${questionTypeValidationMessage} ${index + 1}`);
        }
        if (question.interaction_subtype === "MULTI_CHOICE") {
          if (
            !question.options ||
            !question.options.length ||
            question.options.length < 2
          ) {
            message.push(`${optionsValidationMessage} ${index + 1}`);
          }
          if (
            !questions ||
            !questions[index] ||
            questions[index].shouldSelect < 0 ||
            questions[index].shouldSelect >=
              (question.options && question.options.length) ||
            typeof questions[index].shouldSelect === "undefined"
          ) {
            message.push(`${optionsChoiceValidationMessage} ${index + 1}`);
          }
        }
      });
    }
    if (message.length === 0 || message === "") {
      return true;
    } else {
      dispatch(
        enqueueFlashMessage({
          message: message,
          duration: alertsDuration,
          severity: "error"
        })
      );
    }
  }
  //Render
  return (
    <>
      <Paper
        elevation={1}
        data-testid="form-box"
        className={clsx(classes.paperClass, classes.paddedWholeContent)}
      >
        <Box className={classes.formRow}>
          <FormControl
            variant="standard"
            className={clsx(classes.taskTitle, classes.taskDesc)}
          >
            <TextField
              variant="standard"
              id="taskTitle"
              aria-label="task-title-input"
              autoFocus={!readOnly}
              disabled={readOnly}
              InputProps={{
                readOnly: readOnly
              }}
              label={intl.formatMessage({
                id: "task.title",
                defaultMessage: "Task Title"
              })}
              className={clsx(classes.coloredUnderline)}
              value={taskTitle}
              onChange={e => {
                setTaskTitle(e.target.value);
              }}
            />
          </FormControl>
          <FormControl variant="standard" className={clsx(classes.taskDesc)}>
            <TextField
              variant="standard"
              id={`taskType`}
              aria-label="task-subtitle-input"
              disabled={readOnly}
              label={intl.formatMessage({
                id: "task.type",
                defaultMessage: "Type of task"
              })}
              InputProps={{
                readOnly: true
              }}
              value={"standard"}
              dropdownmenuprops={{ classes: { paper: classes.menuBg } }}
              menuprops={{ classes: { paper: classes.menuBg } }}
              onChange={e => {
                // Clear any currently saved questions and answers
                // TODO: Better define UX, alert user or save previus entries
                // handleTaskTypeChange(e.target.value);
                // // setTaskType in order to show the correct form
                // setTaskType(e.target.value);
              }}
            >
              {/* {taskTypes.map((item, index) => {
                return (
                  <MenuItem
                    className={classes.menu}
                    key={index}
                    value={item.value}
                  >
                    <ListItemText
                      disableTypography={true}
                      className={classes.listItem}
                    >
                      {item.text}
                    </ListItemText>
                  </MenuItem>
                );
              })} */}
            </TextField>
          </FormControl>
        </Box>

        <FormControl variant="standard" className={classes.taskDesc}>
          <TextField
            variant="standard"
            aria-label="task-description-input"
            id="taskDescription"
            readOnly={readOnly}
            InputProps={{
              readOnly: readOnly
            }}
            label={intl.formatMessage({
              id: "task.description",
              defaultMessage: "Task Description"
            })}
            className={classes.coloredUnderline}
            multiline={true}
            value={taskDescription}
            disabled={taskType === "peerReview" || taskType === "guidedReading"}
            onChange={e => {
              setTaskDescription(e.target.value);
            }}
          />
        </FormControl>
      </Paper>
      {questions.map((question, index) => {
        return (
          <Paper key={index} elevation={1} className={clsx(classes.paperClass)}>
            <TaskQuestionView
              text={text}
              readOnly={readOnly}
              taskType={taskType}
              key={index}
              questionIndex={index}
              {...question}
              onUpdate={!readOnly && updateQuestions}
              onAdd={!readOnly && addQuestion}
              onCopy={!readOnly && copyQuestion}
              onDelete={() => {
                !readOnly && deleteQuestion(index);
              }}
              showType={taskType === TASK.TYPE.STANDARD}
              showPoints={taskType === TASK.TYPE.STANDARD}
            />
          </Paper>
        );
      })}

      {["standard", "guidedReading"].includes(taskType) && !readOnly && (
        <Paper
          elevation={1}
          className={classes.addQuestion}
          onClick={addQuestion}
          onKeyPress={e => e.key === "Enter" && addQuestion()}
          role="button"
          tabIndex="0"
        >
          <Typography className={classes.paddedContent} variant="h6">
            {`${intl.formatMessage({
              id: "task.question",
              defaultMessage: "Question"
            })} #${questions?.length + 1} `}
          </Typography>
        </Paper>
      )}

      {!readOnly && setActiveStep && (
        <NavigationButtons
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          publishTask={publishTask}
          validateForm={validateForm}
        />
      )}
    </>
  );
}
