import React, { useCallback, useState, useRef } from "react";
import { v4 as uuid } from "uuid";
import { FormattedMessage } from "react-intl";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import PropTypes from "prop-types";
import makeStyles from "@mui/styles/makeStyles";
import EmptyState from "./EmptyState";
import update from "immutability-helper";
import { updateSq3r, updateTask } from "../../redux/firebaseMiddleware";
import { selectCurrentText } from "../../redux/textsSlice";
import CitationDialog from "../Tasks/CreateTask/CitationDialog";
import { Box, ListItem, List } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { interactionsAPI } from "../../api";
import { showInText } from "../../utils/showInTextHelper";
import { useRendition } from "../../RenditionContext";
import { FEATURE_FLAGS, appActions } from "../../consts";
import clsx from "clsx";
import { selectIsFeatureFlagEnabled } from "../../redux/firestoreSelectors";
import { CHATBOX_STATUS } from "../../redux/chatSlice";
import {
  InteractionCardEditableHeader,
  InteractionCardContent,
  InteractionCardFooter,
  InteractionCard,
  InteractionCardAction
} from "../SharedComponents/card";
import { useRouteMatch } from "react-router-dom";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { addSnackbarItem } from "../../redux/snackbarSlice";
import { updateInteraction } from "../../redux/interactionsSlice";

const useStyles = makeStyles(theme => ({
  container: {
    width: "100%",
    maxWidth: 700,
    margin: "0 auto",
    marginTop: 24,
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  containerWithChat: {
    maxWidth: 700,
    marginTop: 24,
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  cardList: {
    width: "100%"
  },
  hidden: {
    background: theme.palette.card.background.disabled
  }
}));

export function Cards({ cards = [], showInText, disable = false }) {
  const classes = useStyles();
  const visibleCards = cards && cards.filter && cards.filter(el => !el.hidden);
  const hiddenCards = cards && cards.filter && cards.filter(el => el.hidden);
  const featureFlagEnabled = useSelector(state =>
    selectIsFeatureFlagEnabled(state, FEATURE_FLAGS.E_MENTOR_REVIEW)
  );
  const chatStatus = useSelector(state => state.chat.status);
  const chatIsVisible = chatStatus === CHATBOX_STATUS.OPEN;
  return cards.length ? (
    <Box
      className={clsx(
        classes.container,
        featureFlagEnabled && chatIsVisible && classes.containerWithChat
      )}
    >
      {visibleCards && (
        <DragDropContext
          onDragEnd={result => {
            if (!result.destination) return;
            const order = visibleCards.map(card => card.id);
            const rollback = [...order];
            const sourceRef = order[result.source.index];
            order.splice(result.source.index, 1);
            order.splice(result.destination.index, 0, sourceRef);
            interactionsAPI.updateHighlightsOrder(order, rollback);
          }}
        >
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <List
                disablePadding
                dense
                {...provided.droppableProps}
                ref={provided.innerRef}
                className={classes.cardList}
              >
                {[...visibleCards, ...hiddenCards].map((answer, index) => (
                  <Draggable
                    key={answer.id}
                    draggableId={answer.id}
                    index={index}
                    isDragDisabled={answer.hidden || disable}
                  >
                    {(provided, snapshot) => (
                      <ListItem
                        disableGutters
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        tabIndex="-1"
                      >
                        <AnswerCard
                          answer={answer}
                          showInText={showInText}
                          disable={disable}
                        />
                      </ListItem>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </List>
            )}
          </Droppable>
        </DragDropContext>
      )}
    </Box>
  ) : (
    <EmptyState />
  );
}

function CardsManager({ context, cards = [], disable = false }) {
  const dispatch = useDispatch();
  const { rendition } = useRendition();
  const path = useRouteMatch().path;
  const task = useSelector(state => state.tasks.tasks[0]);
  const taskAnswers = useSelector(state => state.task.answers);
  const highlights = useSelector(state => state.interactions.highlights);
  const questions = useSelector(state => {
    return state.interactions.questions;
  });
  const user_id = useSelector(state => state.user.auth.uid);
  const selectedTextId = useSelector(state => state.texts.selectedTextId);
  const selectedText = useSelector(selectCurrentText);
  const selectedQuestion = useSelector(state => {
    let filtered = state.gr?.questions?.filter(
      q => q.id === state.gr.selectedQuestionId
    );
    if (filtered && filtered.length) return filtered[0];
    else return false;
  });
  const color = path.includes("task") ? "primary" : "secondary";

  const answers = selectedQuestion && selectedQuestion.answers;
  const visibleAnswers =
    answers && answers.filter && answers.filter(el => !el.hidden);
  const hiddenAnswers =
    answers && answers.filter && answers.filter(el => el.hidden);
  const moveCard = useCallback(
    (dragIndex, hoverIndex) => {
      const dragCard = visibleAnswers[dragIndex];
      const updatedAnswers = update(visibleAnswers, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragCard]
        ]
      });
      const updatedQuestions = questions.map(el => {
        return el.id === selectedQuestion.id
          ? {
              ...selectedQuestion,
              answers: [...updatedAnswers, ...hiddenAnswers]
            }
          : el;
      });
      const updatedTaskAnswers = taskAnswers.map(tAns => ({
        ...tAns,
        quotes: [...updatedAnswers, ...hiddenAnswers]
      }));
      dispatch(
        task?.task_type
          ? updateTask({
              answers: updatedTaskAnswers,
              grHighlights: highlights
            })
          : updateSq3r({
              text_id: selectedTextId,
              questions: updatedQuestions,
              highlights: highlights
            })
      );
    },
    [
      dispatch,
      hiddenAnswers,
      highlights,
      questions,
      taskAnswers,
      task?.task_type,
      selectedQuestion,
      selectedTextId,
      visibleAnswers
    ]
  );

  const [showDialog, setShowDialog] = useState(false);
  const [highlight, setHighlight] = useState([]);

  const showInTextHandler = answer => {
    const cb = () => {
      setHighlight([answer]);
      setShowDialog(true);
    };

    showInText(appActions.SHOULD_LOG, answer, user_id, rendition, cb);
  };

  return (
    <>
      <Cards
        disable={disable}
        cards={cards}
        moveCard={moveCard}
        showInText={answer => {
          showInTextHandler(answer);
        }}
      />
      {showDialog && (
        <CitationDialog
          color={color}
          setOpenDialog={setShowDialog}
          url={selectedText.url}
          location={selectedText.file_location}
          highlights={highlight}
          openDialog={showDialog}
          context={context}
          removeHighlight={highlight => {
            interactionsAPI.deleteHighlight(highlight);
            setHighlight([]);
          }}
          citationTitleFormattedMessage={
            <FormattedMessage
              id="show.location.inText"
              defaultMessage="show in Text"
            />
          }
        />
      )}
    </>
  );
}

CardsManager.propTypes = {
  cards: PropTypes.array, // TODO: add shape definitian of array items
  color: PropTypes.oneOf(["secondary", "primary"]),
  disable: PropTypes.bool
};

export default CardsManager;

function AnswerCard({ answer, showInText, disable }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const titleRef = useRef();
  const { path } = useRouteMatch();

  const color = path.includes("task") ? "primary" : "secondary";

  function toggleAnswerVisibility(answer) {
    dispatch(
      updateInteraction({
        interaction: answer,
        update: { hidden: !answer.hidden }
      })
    );
  }

  function copyContentToClipBoard(content) {
    navigator.clipboard.writeText(content);
    dispatch(
      addSnackbarItem({
        intlId: "textCopied.success",
        intlDefaultMessage: "Text copied successfully",
        id: uuid()
      })
    );
  }

  return (
    <InteractionCard
      showInText={showInText}
      interaction={answer}
      color={color}
      collapseContent={true}
      // this is passed from the parent since the hidden state was only implamented in the answe view
      // When the hidden state is implamented for all cards it should be moved to the Card component
      className={answer?.hidden && classes.hidden}
    >
      <InteractionCardEditableHeader ref={titleRef} />
      <InteractionCardContent />
      <InteractionCardFooter>
        {/* copy highlights */}
        <InteractionCardAction
          onClick={({ content }) => {
            copyContentToClipBoard(content);
          }}
          intlStringId="menu.tooltip.copyText"
          defaultMessage="Copy highlight content"
        >
          <ContentCopyIcon />
        </InteractionCardAction>

        {/* show in text */}
        <InteractionCardAction
          onClick={() => {
            showInText(answer);
          }}
          intlStringId="highlights.showInText"
          defaultMessage="Show in text"
        >
          <LocationOnOutlinedIcon />
        </InteractionCardAction>

        {/* edit title */}
        <InteractionCardAction
          disabled={disable}
          onClick={() => {
            titleRef.current.focus();
          }}
          intlStringId={
            !answer?.title ? "highlights.addTitle" : "highlights.editTitle"
          }
          defaultMessage={!answer?.title ? "Add title" : "Edit title"}
        >
          <EditOutlinedIcon />
        </InteractionCardAction>

        {/* hide highlight */}
        {answer.hidden ? (
          <InteractionCardAction
            disabled={disable}
            onClick={() => {
              toggleAnswerVisibility(answer);
            }}
            intlStringId="highlights.hideQuote"
            defaultMessage="Hide"
          >
            <VisibilityOffOutlinedIcon />
          </InteractionCardAction>
        ) : (
          <InteractionCardAction
            disabled={disable}
            onClick={() => {
              toggleAnswerVisibility(answer);
            }}
            intlStringId="highlights.hideQuote"
            defaultMessage="Hide"
          >
            <VisibilityOutlinedIcon />
          </InteractionCardAction>
        )}

        {/* delete highlight */}
        <InteractionCardAction
          disabled={disable}
          onClick={() => {
            interactionsAPI.deleteHighlight(answer);
          }}
          intlStringId="highlights.delete"
          defaultMessage="Delete highlight"
        >
          <DeleteOutlineOutlinedIcon />
        </InteractionCardAction>
      </InteractionCardFooter>
    </InteractionCard>
  );
}

AnswerCard.propTypes = {
  answer: PropTypes.object,
  showInText: PropTypes.func,
  disable: PropTypes.bool
};
