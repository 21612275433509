import React, { useState, useEffect } from "react";
import { Typography } from "@mui/material";
import { useIntl, FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import makeStyles from "@mui/styles/makeStyles";
import { Check2 as CheckIcon } from "@styled-icons/bootstrap/Check2";
import {
  motion,
  useAnimation,
  MotionConfig,
  AnimatePresence
} from "framer-motion";
import { updateTask } from "../../../redux/firebaseMiddleware";
import { setStep } from "../../../redux/taskSlice";
import { selectIsImpersonation } from "../../../redux/userSlice";

const useStyles = makeStyles(theme => ({
  highlightsAnswersTogglerWrapper: {
    width: "100%",
    paddingInline: theme.spacing(3),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(2),
    flexShrink: 0
  },
  toggler: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: theme.spacing(2)
  },
  divider: {
    height: 1,
    flex: 1,
    background: "#BDBDBD",
    marginInline: theme.spacing(1)
  },
  tab: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer"
  },
  indexCircle: {
    background: "yellow",
    borderRadius: 15,
    width: 24,
    height: 24,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginInlineEnd: theme.spacing(1)
  },
  index: {
    color: "rgba(0, 0, 0, 0.87)"
  },
  checkIcon: {
    color: "rgba(0, 0, 0, 0.87)",
    marginTop: 1
  },
  txt: {
    textTransform: "capitalize",
    color: "#FFFFFF",
    opacity: 0.7
  },
  description: {
    color: "#FFFFFF",
    opacity: 0.7
  },
  descriptionContainer: {
    // position: "absolute",
    width: `calc(100% - ${theme.spacing(6)})`
  }
}));

function Tab({ i, selected = false, value, done, firstQuestionId }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [isHovered, setHovered] = useState(false);
  const controls = useAnimation();
  const intl = useIntl();

  const selectedQuestionIndex = useSelector(
    state => state.task.selectedQuestionIndex
  );
  const isImpersonation = useSelector(selectIsImpersonation);

  useEffect(() => {
    if (isHovered) {
      controls.start("hovered");
    } else {
      controls.start(selected ? "selected" : "default");
    }
  }, [isHovered, selected]); //eslint-disable-line

  return (
    <MotionConfig transition={{ duration: 0.2 }}>
      <motion.div
        data-test-class="standard-question-mode-tab"
        role="button"
        className={classes.tab}
        initial={selected ? "selected" : "default"}
        animate={controls}
        onClick={() => {
          if (isImpersonation) {
            dispatch(setStep(value));
          } else {
            dispatch(
              updateTask({
                step: value,
                selectedQuestion: selectedQuestionIndex
              })
            );
          }
        }}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
      >
        <motion.div
          data-test-class="standard-question-mode-tab-index"
          className={classes.indexCircle}
          variants={{
            selected: {
              background: "#90CAF9"
            },
            default: {
              background: "rgba(255, 255, 255, 0.5)"
            },
            hovered: {
              background: selected ? "#90CAF9" : "rgba(255, 255, 255, 1)"
            }
          }}
        >
          {done ? (
            <CheckIcon size={16} className={classes.checkIcon} />
          ) : (
            <Typography className={classes.index} variant="caption">
              {i}
            </Typography>
          )}
        </motion.div>
        <motion.div
          variants={{
            selected: {
              opacity: 1
            },
            default: {
              opacity: 0.5
            },
            hovered: {
              opacity: 1
            }
          }}
          // animate={controls}
        >
          <Typography variant="subtitle2" className={classes.txt}>
            {intl.formatMessage({
              id: `task.sidebar.${value}`,
              defaultMessage: value
            })}
          </Typography>
        </motion.div>
      </motion.div>
    </MotionConfig>
  );
}

Tab.propTypes = {
  i: PropTypes.number.isRequired,
  selected: PropTypes.bool.isRequired,
  done: PropTypes.bool.isRequired,
  value: PropTypes.oneOf(["highlight", "answer", "review"]).isRequired,
  firstQuestionId: PropTypes.string
};

export default function HighlightsAnswersToggler({
  higlightsDone,
  answersDone,
  firstQuestionId = null
}) {
  const intl = useIntl();
  const classes = useStyles();
  const step = useSelector(state => state.task.step);

  return (
    <div className={classes.highlightsAnswersTogglerWrapper}>
      <div className={classes.toggler}>
        <Tab
          firstQuestionId={firstQuestionId}
          i={1}
          selected={step === "highlight"}
          value="highlight"
          done={higlightsDone}
        />
        <div className={classes.divider} />
        <Tab
          firstQuestionId={firstQuestionId}
          i={2}
          selected={step === "review"}
          value="review"
          done={step === "answer"}
        />
        <div className={classes.divider} />
        <Tab
          firstQuestionId={firstQuestionId}
          i={3}
          selected={step === "answer"}
          value="answer"
          done={answersDone}
        />
      </div>
      <MotionConfig transition={{ type: "spring", damping: 15 }}>
        <AnimatePresence>
          {step === "highlight" ? (
            <motion.div
              data-test-id="standard-task-mode-descrioption"
              className={classes.descriptionContainer}
              initial={{ x: "-100%", opacity: 0 }}
              animate={{ x: "0", opacity: 1 }}
              exit={{ x: "-100%", opacity: 0 }}
            >
              <Typography variant="body2" className={classes.description}>
                <FormattedMessage
                  id="task.stage.highlight"
                  defaultMessage="task.stage.highlight"
                />
              </Typography>
            </motion.div>
          ) : step === "review" ? (
            <motion.div
              data-test-id="standard-task-mode-descrioption"
              className={classes.descriptionContainer}
              initial={{ x: "-100%", opacity: 0 }}
              animate={{ x: "0", opacity: 1 }}
              exit={{ x: "-100%", opacity: 0 }}
            >
              <Typography variant="body2" className={classes.description}>
                <FormattedMessage
                  id="task.stage.review"
                  defaultMessage="task.stage.review"
                />
              </Typography>
            </motion.div>
          ) : (
            <motion.div
              data-test-id="standard-task-mode-descrioption"
              className={classes.descriptionContainer}
              initial={{ x: "100%", opacity: 0 }}
              animate={{ x: "0", opacity: 1 }}
              exit={{ x: "100%", opacity: 0 }}
            >
              <Typography variant="body2" className={classes.description}>
                <FormattedMessage
                  id="task.stage.answer"
                  defaultMessage="Select a question and answer it according to instructions"
                />
              </Typography>
            </motion.div>
          )}
        </AnimatePresence>
      </MotionConfig>
    </div>
  );
}

HighlightsAnswersToggler.propTypes = {
  higlightsDone: PropTypes.bool.isRequired,
  answersDone: PropTypes.bool.isRequired,
  firstQuestionId: PropTypes.string
};
