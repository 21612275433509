import React from "react";
import { Paper, Typography, Button } from "@mui/material";
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import { useIntl } from "react-intl";
import useGetTheme from "./hooks/useGetTheme";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles(theme => ({
  errorPageWrapper: {
    borderRadius: 0,
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center"
  },
  errorPageTitle: {
    marginBottom: theme.spacing(2)
  },
  errorPageBody: {
    marginBottom: theme.spacing(12)
  }
}));

export default function ErrorPage() {
  const classes = useStyles();
  const theme = useGetTheme({ alwase: "dark" });
  const intl = useIntl();
  const history = useHistory();

  function cacheReloadCount() {
    const previousCount = Number(localStorage.getItem("reloadCount")) || 0;
    if (previousCount === 0) {
      localStorage.setItem("reloadCount", previousCount + 1);
    } else if (previousCount > 0) {
      history.push("/library");
      localStorage.setItem("reloadCount", 0);
    }
    window.location.reload();
  }

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <Paper
          className={classes.errorPageWrapper}
          data-test-id="error-page-wrapper"
        >
          <Typography variant="h2" className={classes.errorPageTitle}>
            {intl.formatMessage({
              id: "errorPage.title",
              defaultMessage: "oops, something went wrong"
            })}
          </Typography>
          <Typography className={classes.errorPageBody}>
            {intl.formatMessage({
              id: "errorPage.body",
              defaultMessage:
                "Try refreshing, and if this problem persists please email us at support@alethea.co"
            })}
          </Typography>
          <Button
            variant="outlined"
            color="secondary"
            size="large"
            onClick={() => {
              cacheReloadCount();
            }}
          >
            {intl.formatMessage({
              id: "errorPage.btnText",
              defaultMessage: "Try Again"
            })}
          </Button>
        </Paper>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}
