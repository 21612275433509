import PropTypes from "prop-types";

export const Rect = {
  x1: PropTypes.number,
  y1: PropTypes.number,
  x2: PropTypes.number,
  y2: PropTypes.number,
  width: PropTypes.number,
  height: PropTypes.number,
  pageNumber: PropTypes.number
};

export const PdfPosition = {
  boundingRect: PropTypes.shape(Rect),
  rects: PropTypes.arrayOf(PropTypes.shape(Rect)),
  pageCfi: PropTypes.string,
  pageNumber: PropTypes.number
};

export const PdfHighlight = {
  id: PropTypes.string,
  cfi: PropTypes.string,
  boundingRect: PropTypes.shape(Rect),
  rects: PropTypes.arrayOf(PropTypes.shape(Rect)),
  pageNumber: PropTypes.number,
  content: PropTypes.string,
  color: PropTypes.string.isRequired
};
