// Dependencies
import React, { useState, useEffect } from "react";
import { useHistory, Link as RouterLink } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import clsx from "clsx";
import { Draggable } from "react-beautiful-dnd";
import { tasksAPI } from "../../../api";
import { dateToEodUtc } from "../CreateTask/utils";

// Redux Dependencies
import { useDispatch, useSelector } from "react-redux";
import { setBreadcrumbs } from "../../../redux/readerActionsSlice";

//Components
import TaskListItemDetails from "./TaskListItemDetails";
import ToggleTriangle from "../../SharedComponents/ToggleTriangle";
import useConvertToTimezone from "../../../hooks/useConvertToTimezone";
import DeadlineDatePicker from "./DeadlineDatePicker";

// Material UI
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import InsertChartIcon from "@mui/icons-material/InsertChart";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import makeStyles from "@mui/styles/makeStyles";
import {
  Box,
  IconButton,
  Collapse,
  Typography,
  ListItem,
  ListItemIcon,
  ListItemText,
  Link
} from "@mui/material";
import TooltipWithIntl from "../../SharedComponents/tooltip/TooltipWithIntl";
import { setSelectedTextId } from "../../../redux/textsSlice";
import { selectQueuedTasks } from "../../../redux/queuedTaskSelectors";
import BouncingDots from "../../SharedComponents/BouncingDots";
import { useTheme } from "@mui/styles";

const useStyles = makeStyles(theme => ({
  task: {
    minHeight: 72, // TODO: not great, find a better way to force the height
    borderBottomColor: theme.palette.divider,
    borderBottomStyle: "solid",
    borderBottomWidth: "1px"
  },
  taskHeader: {
    padding: "8px 16px"
  },
  taskSummary: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },

  taskName: {
    flexBasis: "33%",
    fontWeight: "bold",
    color: theme.palette.text.primary,
    textAlign: "start",
    cursor: "pointer"
  },
  taskDates: {
    display: "inline-block",
    fontWeight: "100",
    flexShrink: "0",
    zIndex: 1500
  },
  taskDeadline: {
    color: theme.palette.primary.dark
  },
  actionContainer: {
    flexShrink: "0"
  },
  actionIcon: {
    "&:hover": {
      color: theme.palette.primary.main
    }
  },
  taskStatus: {
    color: theme.palette.text.secondary,
    margin: "0 48px"
  },
  disableLink: {
    pointerEvents: "none",
    color: theme.palette.action.disabled
  }
}));

export default function TasksListItem({
  taskIndex,
  task,
  submissions,
  graders,
  course,
  singleItemView
}) {
  //Hooks
  const theme = useTheme();
  const classes = useStyles();
  const intl = useIntl();
  const history = useHistory();
  const dispatch = useDispatch();
  const isDarkMode = useSelector(state => state.user.userProfile.darkMode);
  const [TZOriginalDueDate] = useConvertToTimezone(
    task.original_due_date,
    course.timezone,
    "MMM, do"
  );
  const [TZRevisedDueDate] = useConvertToTimezone(
    task.revised_due_date,
    course.timezone,
    "MMM, do"
  );

  // Redux State
  const queuedTasks = useSelector(state => selectQueuedTasks(state));
  // Ephemeral State

  const [isOpen, setIsOpen] = useState(singleItemView || false);
  const [datePickerOpen, setDatePickerOpen] = useState(false);

  const isDraft = task.status === "Draft";
  const isPending = task.status === "PENDING";
  const isDisabled = isDraft || isPending;
  const taskName = task.name;
  //Behavior

  const handleClick = e => {
    setIsOpen(!isOpen);
    e.stopPropagation();
    e.preventDefault();
  };

  function getLinkTarget(task) {
    if (isDraft) {
      return `/tasks/new?course_id=${task.course_id}&task_id=${task.id}`;
    } else if (submissions?.length) {
      return `/tasks?course_id=${task.course_id}&task_id=${task.id}`;
    } else return `#`;
  }

  // Display the task's name and the status if aplicable
  function renderTaskName(task) {
    return (
      <Box>
        <Link
          to={() => getLinkTarget(task)}
          component={RouterLink}
          className={clsx(classes.taskName, isDisabled && classes.disableLink)}
          underline="hover"
        >
          <Typography component="span" variant="body2">
            {taskName}
          </Typography>
        </Link>
        {isDraft && (
          <Typography
            className={classes.taskStatus}
            component="span"
            variant="body2"
          >
            <FormattedMessage id="Draft" defaultMessage="Draft" />
          </Typography>
        )}
        {isPending && (
          <Typography
            className={clsx(classes.taskStatus, classes.disableLink)}
            component="span"
            variant="body2"
          >
            <FormattedMessage
              id="task.publishing"
              defaultMessage="publishing"
            />
            <BouncingDots size="small" color={theme.palette.action.disabled} />
          </Typography>
        )}
      </Box>
    );
  }
  // display the avaliable action icons
  function renderTaskActions() {
    if (isPending) return null;
    else if (isDraft) {
      return (
        <Box className={classes.actionContainer}>
          <TooltipWithIntl
            intlStringId="draft.deleteDraft"
            defaultMessage="Delete draft"
            placement="bottom"
          >
            <IconButton
              onClick={() => {
                tasksAPI.deleteTaskDraft(task);
              }}
              aria-label={`delete draft ${taskName}`}
              size="large"
            >
              <DeleteOutlineOutlinedIcon />
            </IconButton>
          </TooltipWithIntl>
          <TooltipWithIntl
            intlStringId="draft.editDraft"
            defaultMessage="Edit draft"
            placement="bottom"
          >
            <IconButton
              onClick={e => {
                history.push(
                  `/tasks/new?course_id=${task.course_id}&task_id=${task.id}`,
                  { task: task }
                );

                e.stopPropagation();
                e.preventDefault();
              }}
              className={classes.actionIcon}
              aria-label={`edit draft ${taskName}`}
              size="large"
            >
              <EditOutlinedIcon />
            </IconButton>
          </TooltipWithIntl>
        </Box>
      );
    } else {
      return (
        <Box className={classes.actionContainer}>
          <TooltipWithIntl
            intlStringId="task.taskmanager.viewTaskForm"
            defaultMessage="View task form"
            placement="bottom"
          >
            <IconButton
              onClick={e => {
                dispatch(setSelectedTextId(task.text_id));
                history.push(`/tasks/edit?task_id=${task.id}`, {
                  task: { ...task, submissions: submissions },
                  course: course
                });

                e.stopPropagation();
                e.preventDefault();
              }}
              className={classes.actionIcon}
              aria-label="View task form"
              size="large"
            >
              <InsertDriveFileIcon />
            </IconButton>
          </TooltipWithIntl>

          <TooltipWithIntl
            intlStringId="task.taskmanager.taskStats"
            defaultMessage="View task's statistics"
            placement="bottom"
          >
            <IconButton
              onClick={e => {
                history.push(`/task/stats?task_id=${task.id}`);
                e.stopPropagation();
                e.preventDefault();
              }}
              className={classes.actionIcon}
              aria-label="task's statistics"
              size="large"
            >
              <InsertChartIcon />
            </IconButton>
          </TooltipWithIntl>
        </Box>
      );
    }
  }

  const getItemStyle = (isDragging, draggableStyle, isDarkMode) => {
    return {
      // styles we need to apply on draggables
      ...draggableStyle,

      ...(isDragging && {
        display: "table",
        background: isDarkMode ? "rgb(5,15,15)" : "rgb(235,235,235)"
      })
    };
  };

  function handleDeadlineChange(newDeadline) {
    const tzDeadline = dateToEodUtc(newDeadline, course.timezone);
    tasksAPI.updateDueDateForAllStudents(task, tzDeadline);
  }

  //set breadcrumbs for single task view by teacher
  useEffect(() => {
    let task = submissions[0];
    let parts = [];
    parts.push({
      url: "/tasks",
      resetCourse: true,
      text: intl.formatMessage({
        id: "appBar.tasks",
        defaultMessage: "Tasks"
      })
    });
    task &&
      course.name &&
      parts.push({
        url: `/tasks?course_id=${task.course_id}`,
        text: course.name,
        course: course
      });
    singleItemView &&
      task &&
      parts.push({
        url: `/tasks?course_id=${task.course_id}&task_id=${task.task_id}`,
        text: taskName
      });
    dispatch(
      setBreadcrumbs({ breadcrumbs: parts, blue: true, showTextMenu: false })
    );
  }, []);

  // Render
  return (
    <>
      {singleItemView ? (
        <>
          <Box className={clsx(classes.task, classes.taskHeader)}>
            <Box className={classes.taskSummary}>
              {renderTaskName(task)}
              {isOpen && !isDisabled && (
                <Box className={classes.taskDates}>
                  {TZOriginalDueDate && (
                    <DeadlineDatePicker
                      isOpen={datePickerOpen}
                      setIsOpen={setDatePickerOpen}
                      deadline={TZRevisedDueDate || TZOriginalDueDate}
                      label="Task deadline"
                      onChange={newDueDate => handleDeadlineChange(newDueDate)}
                    >
                      <TooltipWithIntl
                        intlStringId="tasks.deadline"
                        defaultMessage="Edit deadline"
                        placement="bottom"
                      >
                        <Link
                          component="button"
                          onClick={() => {
                            setDatePickerOpen(true);
                          }}
                          className={classes.taskDeadline}
                          underline="hover"
                        >
                          <Typography component="span" variant="body1">
                            <FormattedMessage
                              id="tasks.deadline"
                              defaultMessage="Deadline"
                            />
                            {` ${TZRevisedDueDate || TZOriginalDueDate}`}
                          </Typography>
                        </Link>
                      </TooltipWithIntl>
                    </DeadlineDatePicker>
                  )}
                </Box>
              )}
              {renderTaskActions()}
            </Box>
          </Box>
          <TaskListItemDetails
            taskIndex={taskIndex}
            course={course}
            task={task}
            submissions={submissions}
            graders={graders}
          />
        </>
      ) : (
        <Draggable draggableId={"task_" + task.id} index={taskIndex}>
          {(provided, snapshot) => (
            <>
              <ListItem
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                style={getItemStyle(
                  snapshot.isDragging,
                  provided.draggableProps.style,
                  isDarkMode
                )}
                className={classes.task}
                disableGutters={true}
              >
                <ListItemIcon onClick={handleClick}>
                  {!isDisabled && <ToggleTriangle isOpen={isOpen} />}
                </ListItemIcon>
                <ListItemText
                  disableTypography={true}
                  className={classes.taskSummary}
                >
                  {renderTaskName(task)}
                  {isOpen && !isDisabled && (
                    <Box className={classes.taskDates}>
                      {TZOriginalDueDate && (
                        <DeadlineDatePicker
                          isOpen={datePickerOpen}
                          setIsOpen={setDatePickerOpen}
                          deadline={TZRevisedDueDate || TZOriginalDueDate}
                          label="Task deadline"
                          onChange={newDueDate =>
                            handleDeadlineChange(newDueDate)
                          }
                        >
                          <TooltipWithIntl
                            intlStringId="tasks.deadline"
                            defaultMessage="Edit deadline"
                            placement="bottom"
                          >
                            <Link
                              component="button"
                              onClick={() => {
                                setDatePickerOpen(true);
                              }}
                              className={classes.taskDeadline}
                              underline="hover"
                            >
                              <Typography component="span" variant="body1">
                                <FormattedMessage
                                  id="tasks.deadline"
                                  defaultMessage="Deadline"
                                />
                                {` ${TZRevisedDueDate || TZOriginalDueDate}`}
                              </Typography>
                            </Link>
                          </TooltipWithIntl>
                        </DeadlineDatePicker>
                      )}
                    </Box>
                  )}
                  {renderTaskActions()}
                </ListItemText>
              </ListItem>
              <Collapse in={isOpen} timeout="auto" unmountOnExit>
                <TaskListItemDetails
                  taskIndex={taskIndex}
                  course={course}
                  task={task}
                  submissions={submissions}
                  graders={graders}
                />
              </Collapse>
            </>
          )}
        </Draggable>
      )}
    </>
  );
}
