// Dependencies
import React, { useEffect, useState } from "react";
import { httpCallables } from "../../../firebase";
import clsx from "clsx";
import { useLocation, Redirect, useHistory } from "react-router-dom";

import { captureException } from "../../../utils/errorHandlers";
import { useQuery } from "../../../hooks";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  setTasks,
  setSubmissions,
  selectTask
} from "../../../redux/tasksSlice";
import { setInteractions } from "../../../redux/interactionsSlice";
import { selectCourse } from "../../../redux/coursesSlice";

import makeStyles from "@mui/styles/makeStyles";
import { Box } from "@mui/material";

// Components
import CreateTask from "./CreateTask";
import { ScrollBox } from "../../SharedComponents";

const useStyles = makeStyles(theme => {
  return {
    readerViewContainer: {
      position: "relative",
      width: "100%",
      minHeight: "calc(100vh - 120px)"
    },
    drawerContainer: {
      position: "relative",
      width: "100%",
      minHeight: "100%"
    },
    dirLtr: {
      direction: "ltr"
    },

    bookContainerActionOpen: {
      gridColumn: " 7 / 17 "
    },
    bookContainerNoSidebat: {
      gridColumn: " 5 / 15 "
    },

    bookContainer: {
      width: "50%",
      margin: "0 auto",
      paddingBottom: theme.spacing(2)
    }
  };
});

function CreateTaskScreen() {
  // Hooks
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();

  const { task_id, course_id } = useQuery();
  const task = useSelector(state => selectTask(state, Number(task_id)));
  const course = useSelector(state => selectCourse(state, Number(course_id)));
  const location = useLocation();

  // Ephemeral State
  const [isDirty, setIsDirty] = useState(false);

  //Variables
  useEffect(() => {
    // this should happen the the router level
    if (!course.course_role) return;
    if (course.course_role === "Student")
      history.replace(`/tasks?course_id=${course_id}`);
  });

  useEffect(() => {
    let isCancelled = false;

    if (task_id) {
      httpCallables
        .readTaskDraft({ task_id: Number(task_id) })
        .then(({ data }) => {
          if (!isCancelled) {
            const { task, submissions, interactions } = JSON.parse(data);
            dispatch(setTasks([task]));
            dispatch(setSubmissions(submissions));
            dispatch(setInteractions(interactions));
          }
        })
        .catch(err => {
          captureException(err, `Failed fetch task draft. taskId ${task_id}`);
        });
    }
    return () => {
      isCancelled = true;
    };
  }, [dispatch, task_id]);

  if (!course_id) return <Redirect to="/tasks" />;
  return (
    <ScrollBox className={clsx(classes.readerViewContainer, classes.dirLtr)}>
      <Box className={classes.drawerContainer} id="drawer-container">
        <Box
          id="readerViewBookContainer"
          className={clsx(
            classes.bookContainer,
            location.pathname !== "/tasks/new" &&
              classes.bookContainerActionOpen,
            location.pathname === "/tasks/new" && classes.bookContainerNoSidebat
          )}
        >
          <CreateTask task={task} isDirty={isDirty} setIsDirty={setIsDirty} />
        </Box>
      </Box>
    </ScrollBox>
  );
}

export default CreateTaskScreen;
