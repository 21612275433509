// Dependencies
import React, { useState } from "react";
import clsx from "clsx";
import { useQuery } from "../../../hooks";
import { motion, AnimatePresence } from "framer-motion";

// Redux
import { useSelector } from "react-redux";
import { selectSubmission } from "../../../redux/tasksSlice";
import {
  selectSecondarySidebarCollapsed,
  selectIsComments,
  selectIsThumbnails,
  selectTextDirection
} from "../../../redux/firestoreSelectors";

// Components
import TaskFeedback from "../TaskFeedback/taskFeedback";
import SidePanel from "../../SharedComponents/SidePanel";
import PrimarySidebarLayout from "../../SharedComponents/PrimarySidebarLayout";
import GuidedReadingContent from "./GuidedReadingContent";
import ReaderSidebar from "../../poc/ReaderSidebar";
import { GrInstructions } from "../../guidedReading/GrInstructions";
// Material UI
import { Box } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { SecondarySidebarLayout } from "../../SharedComponents";
import PDFThumbBar from "../../reader/pdf/sidebar/PDFThumbBar";
import CommentPanel from "../../comments/CommentPanel";

// Styles
const useStyles = makeStyles(theme => {
  return {
    readerViewContainer: {
      position: "relative",
      flex: 1,
      height: "100%",
      justifyContent: "center"
    },
    innerWrapper: {
      width: "100%",
      height: "100%",
      top: 0,
      left: 0
    },
    dirLtr: {
      direction: "ltr"
    },
    instructionsWrapper: {
      position: "absolute",
      top: 0,
      left: 0,
      zIndex: 2,
      width: "100%",
      height: "100%"
    },
    stageHeader: {
      paddingInline: `${100 / 6}%`,
      marginTop: theme.spacing(5)
    },
    subTitle: {
      marginTop: theme.spacing(0.5),
      marginBottom: theme.spacing(4),
      opacity: 0.6
    }
  };
});
function GuidedReading() {
  // Hooks
  const classes = useStyles();

  const { submission_id } = useQuery();

  // Redux Selectors

  const PrimarySidebarWidth = useSelector(
    state => state.layout.primarySidebarWidth
  );
  const SecondarySidebarWidth = useSelector(
    state => state.layout.secondarySidebarWidth
  );

  const submission = useSelector(state =>
    selectSubmission(state, Number(submission_id))
  );
  const detailedInstructions = useSelector(
    state => state.gr.detailedInstructions
  );
  const courseRole = useSelector(
    state => state.user.userProfile.selectedCourse.course_role
  );
  const textDirection = useSelector(state => selectTextDirection(state));
  const isSecondarySidebarCollapsed = useSelector(state =>
    selectSecondarySidebarCollapsed(state)
  );
  const isCommentsInSecondarySidebar = useSelector(state =>
    selectIsComments(state)
  );
  const grMode = useSelector(state => state.gr.mode);
  const action = useSelector(
    state => state.readerActions.persistentActionState.actionBar
  );
  const grStage = useSelector(state => state.gr.stage);

  const isThumbnailsInSecondarySidebar = useSelector(state =>
    selectIsThumbnails(state)
  );
  // Ephemeral state
  const [showBook, setShowBook] = useState(true);
  const disableSeconderySidebar =
    ["poc", "", "task"].includes(action) &&
    ((grMode === "light" && grStage === 2) ||
      (grMode === "full" && [3, 4].includes(grStage)));
  //Derived state

  //user role
  const isTeacher = courseRole === "Teacher";
  const isStudent = courseRole === "Student";

  //submission status
  const status = submission.status;
  const isGraded = status === "Graded";
  const isSubmitted = status === "Submitted";

  //Behavior

  // Render
  if (isStudent && !isGraded)
    return (
      <>
        <PrimarySidebarLayout>
          <SidePanel />
          <ReaderSidebar />
        </PrimarySidebarLayout>
        <Box
          className={clsx(classes.readerViewContainer, classes.dirLtr)}
          style={{
            width: `calc(100vw - ${PrimarySidebarWidth}px - ${SecondarySidebarWidth}px)`
          }}
        >
          <AnimatePresence>
            {detailedInstructions && (
              <motion.div
                className={classes.instructionsWrapper}
                initial={{ x: textDirection === "rtl" ? "100%" : "-100%" }}
                animate={{ x: "0" }}
                exit={{ x: textDirection === "rtl" ? "100%" : "-100%" }}
                transition={{ type: "spring", damping: 20 }}
              >
                <GrInstructions />
              </motion.div>
            )}
          </AnimatePresence>
          <motion.div
            className={classes.innerWrapper}
            animate={{
              zIndex: showBook && 0,
              opacity: showBook ? 1 : 0,
              transitionEnd: {
                zIndex: !showBook && -1
              }
            }}
            style={{
              pointerEvents: showBook ? "all" : "none"
            }}
            transition={{ duration: showBook ? 0.5 : 0 }}
          >
            <GuidedReadingContent />
          </motion.div>
        </Box>

        <SecondarySidebarLayout
          collapsed={!isSecondarySidebarCollapsed || disableSeconderySidebar}
        >
          {isThumbnailsInSecondarySidebar && <PDFThumbBar />}
          {isCommentsInSecondarySidebar && <CommentPanel />}
        </SecondarySidebarLayout>
      </>
    );
  if (isTeacher && isSubmitted) return <TaskFeedback />;
  if (isGraded) return <TaskFeedback />;
}

GuidedReading.propTypes = {};

export default GuidedReading;
