// Dependencies
import React, { useState, useEffect } from "react";
import { normalize } from "./utils";
import { useIntl } from "react-intl";

import makeStyles from "@mui/styles/makeStyles";
import { Box, Typography, Paper, Divider, LinearProgress } from "@mui/material";
// Styles
const useStyles = makeStyles(theme => {
  return {
    statContainer: {
      padding: "32px"
    },
    generalStatsHeader: {
      padding: "24px 0",
      fontWeight: "bold",
      lineHeight: "1"
    },
    statNum: {
      fontSize: "72px",
      lineHeight: "1.2",
      fontFamily: "Helvetica",
      color: "#168FEE"
    },
    statBar: {
      height: "8px",
      backgroundColor: "#D8D8D8",
      "& .MuiLinearProgress-barColorPrimary": {
        backgroundColor: "#168FEE"
      }
    }
  };
});

export default function GeneraStats({
  submissions,
  generalStatsNumRef,
  totalPoints
}) {
  // Hooks
  const classes = useStyles();
  const intl = useIntl();
  const [participants, setParticipants] = useState(0);
  const [submitted, setSubmitted] = useState(0);
  const [average, setAverage] = useState(0);
  // Helpers

  useEffect(() => {
    setParticipants(submissions.length);
  }, [submissions]);

  useEffect(() => {
    const submittedTasks = submissions.filter(
      submission => submission.submission_date
    );

    setSubmitted(submittedTasks.length);
  }, [submissions]);

  useEffect(() => {
    const gradedSubmissions = submissions.filter(
      submission => submission.is_checked
    );

    const sum = gradedSubmissions
      .map(submission => submission.grade)
      .reduce((total, current) => total + current, 0);

    setAverage(
      gradedSubmissions.length > 0 ? sum / gradedSubmissions.length : "-"
    );
  }, [submissions, totalPoints]);

  return (
    <Paper elevation={0} ref={generalStatsNumRef}>
      <Box component="header">
        <Typography variant="h5" className={classes.generalStatsHeader}>
          {intl.formatMessage({
            id: "task.statistics.general",
            defaultMessage: "General"
          })}
        </Typography>
      </Box>
      <Divider />
      <Box className={classes.statContainer}>
        <Typography variant="h6">
          {intl.formatMessage({
            id: "task.statistics.participants",
            defaultMessage: "Participants"
          })}
        </Typography>
        <Typography className={classes.statNum}>{participants}</Typography>
        <LinearProgress
          variant="determinate"
          value={normalize(submitted, 0, participants)}
          className={classes.statBar}
        />
      </Box>
      <Divider />
      <Box className={classes.statContainer}>
        <Typography variant="h6">
          {intl.formatMessage({
            id: "task.statistics.gradeAvrage",
            defaultMessage: "Grade average"
          })}
        </Typography>
        <Typography className={classes.statNum}>
          {typeof average === "number" ? normalize(average, 0, 100) : average}
        </Typography>
        <LinearProgress
          variant="determinate"
          value={typeof average === "number" ? normalize(average, 0, 100) : 0}
          className={classes.statBar}
        />
      </Box>
    </Paper>
  );
}
