import { useEffect, useState } from "react";

import { format } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";

// useConvertToTimeZone takes in a an ISO date, a timezine and an optional pattern string as arguments
// It returns a formated converted date/time and an converted date obj based on the passed in timezone
// for pattern strings: https://date-fns.org/v2.28.0/docs/format

export default function useConvertToTimeZone(
  date,
  timezone,
  pattern = "MM/dd/yyyy"
) {
  const [timezonedDate, setTimezonedDate] = useState(null);
  const [formatedDate, setFormatedDate] = useState(null);

  useEffect(() => {
    if (!date) return;
    const parsedDate = utcToZonedTime(date, timezone);
    setTimezonedDate(parsedDate);
    setFormatedDate(format(parsedDate, pattern));
  }, [date, pattern, timezone]);

  return [formatedDate, timezonedDate];
}
