// Dependencies
import React, { useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import clsx from "clsx";
import { isPast, isToday } from "date-fns";

// Redux dependencies
import { useDispatch, useSelector } from "react-redux";
import { enqueueFlashMessage } from "../../../redux/userSlice";

// Components
import NavigationButtons from "./NavigationButtons";

import makeStyles from "@mui/styles/makeStyles";
import { Box, Paper, Divider, Typography, TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { useLocation } from "react-router-dom";

//Styles
const useStyles = makeStyles(theme => ({
  right: {
    textAlign: "right"
  },
  left: {
    textAlign: "left"
  },
  paperClass: {
    marginTop: "20px",
    borderRadius: "0px",
    paddingBottom: "16px",
    textAlign: "left",

    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
      color: "#168FEE"
    },
    "& .MuiInput-underline": {
      "&:after": {
        borderBottom: "2px solid #168FEE"
      }
    },
    "&:after": {
      borderBottom: "2px solid #168FEE"
    },
    "&:focused::after": {
      borderBottom: "2px solid #168FEE"
    },
    "& .MuiInputLabel-root.Mui-focused": {
      color: "#168FEE"
    }
  },
  divider: {
    backgroundColor: "#dedede",
    marginBottom: "8px",
    marginTop: "8px"
  },
  taskDesc: {
    width: "90%",
    marginBottom: "16px"
  },
  paddedContent: {
    paddingLeft: "24px",
    paddingRight: "24px",
    paddingTop: "16px"
  },
  marginContent: {
    marginTop: "24px",
    marginBottom: "30px"
  },
  deadline: {
    "& .MuiPickersDay-current": {
      color: "#168fee"
    },
    "& .MuiPickersDay-daySelected": {
      color: "#fff"
    }
  },
  acceptUntil: {
    "& .MuiPickersDay-current": {
      color: "#168fee"
    },
    "& .MuiPickersDay-daySelected": {
      color: "#fff"
    }
  },
  deadlinesContainer: {
    display: "flex",
    justifyContent: "space-between"
  }
}));

export default function StepSubmitTask({
  taskType,
  deadline,
  setDeadline,
  collaboratorDeadline,
  setCollaboratorDeadline,
  readOnly,
  activeStep,
  setActiveStep,
  publishTask,
  setIsDirty
}) {
  //Hooks
  const intl = useIntl();
  const classes = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();
  //Redux state
  const alertsDuration = useSelector(
    state => state.user.userProfile.alertsDuration
  );

  //Variables
  const deadlineValidationMessage = intl.formatMessage({
    id: "tasks.create.validation.enterDeadline",
    defaultMessage: "Set a deadline for this task"
  });
  const deadlineValueValidationMessage = intl.formatMessage({
    id: "tasks.create.validation.deadlinePast",
    defaultMessage: "The deadline you selected has already passed"
  });
  const deadlineOrderValidationMessage = intl.formatMessage({
    id: "tasks.create.validation.deadlineOrder",
    defaultMessage:
      "Please make sure that the secound deadline is after the first deadline"
  });

  // Behavior
  function validateForm() {
    let message = "";
    // For standart tasks, check if there is a deadline
    if (taskType === "standard" && !deadline) {
      message = deadlineValidationMessage;
    } else if (
      taskType === "peerReview" &&
      (!deadline || !collaboratorDeadline)
    ) {
      message = deadlineValidationMessage;
      // If there is a deadline...
    }
    // Check if the deadline's day is today or after
    else if (isPast(deadline) || isToday(deadline)) {
      message = deadlineValueValidationMessage;
    }
    if (message === "") {
      setIsDirty(false);
      return true;
    } else {
      dispatch(
        enqueueFlashMessage({
          message: message,
          duration: alertsDuration,
          severity: "error"
        })
      );
      return false;
    }
  }

  //Render
  return (
    <>
      <Paper elevation={1} className={clsx(classes.paperClass)}>
        <Typography className={classes.paddedContent} variant="h6">
          <FormattedMessage defaultMessage="Time Frame" id="task.timeFrame" />
        </Typography>
        <Divider className={classes.divider} />
        <Box
          className={clsx(
            classes.deadlinesContainer,
            classes.paddedContent,
            classes.marginContent
          )}
        >
          <DatePicker
            id="deadline"
            name="deadline"
            disabled={readOnly}
            label={
              taskType === "peerReview"
                ? // Peer Review
                  intl.formatMessage({
                    id: "tasks.responderDeadline",
                    defaultMessage: "Reflection deadline"
                  })
                : // All other Tasks
                  intl.formatMessage({
                    id: "tasks.deadline",
                    defaultMessage: "Deadline"
                  })
            }
            openTo="day"
            className={classes.deadline}
            DialogProps={{ className: classes.deadline }}
            minDate={new Date()}
            inputFormat="dd/MM/yyyy"
            value={deadline}
            emptyLabel={intl.formatMessage({
              id: "tasks.pickDate",
              defaultMessage: "Choose Date"
            })}
            onChange={e => {
              setDeadline(e);
            }}
            leftArrowButtonProps={{ "aria-label": "previous month" }}
            rightArrowButtonProps={{ "aria-label": "next month" }}
            renderInput={params => <TextField variant="standard" {...params} />}
          />

          {taskType === "peerReview" && (
            <DatePicker
              id="latestDelivery"
              minDate={deadline}
              disabled={readOnly}
              className={classes.acceptUntil}
              DialogProps={{ className: classes.deadline }}
              label={
                taskType === "peerReview"
                  ? // Peer Review
                    intl.formatMessage({
                      id: "tasks.reviewerDeadline",
                      defaultMessage: "Review deadline"
                    })
                  : // All other Tasks
                    intl.formatMessage({
                      id: "tasks.acceptUntil",
                      defaultMessage: "Latest delivery"
                    })
              }
              openTo="day"
              inputFormat="dd/MM/yyyy"
              value={collaboratorDeadline}
              emptyLabel={intl.formatMessage({
                id: "tasks.pickDate",
                defaultMessage: "Choose Date"
              })}
              onChange={e => {
                setCollaboratorDeadline(e);
              }}
              leftArrowButtonProps={{ "aria-label": "previous month" }}
              rightArrowButtonProps={{ "aria-label": "next month" }}
              renderInput={params => (
                <TextField variant="standard" {...params} />
              )}
            />
          )}
        </Box>
      </Paper>
      {!location.pathname.includes("edit") && (
        <NavigationButtons
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          publishTask={publishTask}
          validateForm={validateForm}
        />
      )}
    </>
  );
}
