import React from "react";
import draftToHtml from "draftjs-to-html";
import DOMPurify from "dompurify";
import { Box } from "@mui/material";

export default function RenderEditorContent({ children }) {
  // Converting the raw DraftJS content to HTML
  // See: https://www.npmjs.com/package/draftjs-to-html
  const dirtyMarkup = draftToHtml(children);

  //Sanitaizing the HTML
  let cleanMarkup = DOMPurify.sanitize(dirtyMarkup);

  return <Box dangerouslySetInnerHTML={{ __html: cleanMarkup }} />;
}
