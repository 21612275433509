// Dependencies
import React, { Children } from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import { useTextEditorContext } from "./TextEditor";
import { getWordCount } from "./utils";
import clsx from "clsx";

import makeStyles from "@mui/styles/makeStyles";
import { Box } from "@mui/material";
// Styles
const useStyles = makeStyles(theme => ({
  footer: {
    height: 48,
    paddingInline: theme.spacing(2),
    borderTop: "1px solid",
    borderTopColor: theme.palette.grey.main,
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "space-between",
    flexGrow: 0
  },
  outlined: {
    borderTop: "1px solid",
    borderTopColor: theme.palette.grey.main
  },
  contained: {
    border: "none"
  },
  wordCount: {
    //TODO: Ideally all the fonst stlyle will go in a typography component
    fontFamily: "Chivo",
    fontSize: 12,
    textTransform: "uppercase",
    fontWeight: 300,
    letterSpacing: "0.1px",

    //TODO: get this from theme so it will have a dark mode settings
    color: "#616161"
  },
  actions: {
    display: "flex",
    flexFlow: "row nowrap",
    alignItems: "flex-end"
  },
  action: {
    "&:not(:last-child)": {
      marginInlineEnd: theme.spacing(1)
    }
  }
}));

// Behavior
function TextEditorFooter({
  children,
  showWordCount = true,
  wordLimit,
  className
  // editorState, // implicitly passes from TextEditor
}) {
  //Hooks
  const classes = useStyles();
  const intl = useIntl();
  const { editorState, variant } = useTextEditorContext();
  // Redux state

  // Ephemeral state

  // Variables
  const childrenArray = Children.toArray(children);
  const words = intl.formatMessage({ id: "words", defaultMessage: "words" });

  // Behavior

  //Render
  return (
    <Box className={clsx(classes.footer, classes[variant], className)}>
      {/* Actions ↓ */}
      <Box className={classes.actions}>
        {childrenArray?.map((child, index) => (
          <Box key={index} className={classes.action}>
            {child}
          </Box>
        ))}
      </Box>

      {/* Word count ↓ */}
      {showWordCount && (
        <Box className={classes.wordCount}>
          {getWordCount(editorState)}
          {wordLimit > 0 && `/${wordLimit}`}
          {` ${words}`}
        </Box>
      )}
    </Box>
  );
}

TextEditorFooter.propTypes = {
  showWordCount: PropTypes.bool,
  wordLimit: PropTypes.number,
  editorState: PropTypes.object, //TODO: this should be more specific
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]), // TODO: tighten the enforcment of what can be passes in as a child
  className: PropTypes.string
};

export default TextEditorFooter;
