// Dependencies
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import clsx from "clsx";
import { firebaseApp } from "../../../firebase";

// Redux Dependencies
//Components
import TaskStatusChip from "../TaskManager/TaskStatusChip";

import makeStyles from "@mui/styles/makeStyles";

import {
  Chip,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from "@mui/material";

// Styles
const useStyles = makeStyles(theme => ({
  tableContainer: {
    paddingLeft: "10%"
  },
  tableRow: {
    // Equelize the height of the table rows with and without action buttons
    "& > *": {
      padding: "8px",
      "& > *": {
        paddingTop: "12px",
        paddingBottom: "12px"
      }
    }
  },
  pointer: {
    cursor: "pointer"
  },
  missed: {
    backgroundColor: "#f9413e"
  },
  late: {
    backgroundColor: "#fcb63f"
  },
  pending: {
    border: "1px solid black"
  },
  submitted: {
    backgroundColor: "transparent",
    color: "#0eac72",
    border: "1px solid #0eac72"
  },
  graded: {
    backgroundColor: "#0eac72"
  },
  sent: {
    backgroundColor: theme.palette.primary,
    color: theme.palette.text.primary
  },
  add: {
    border: `1px solid ${theme.palette.primary}`,
    color: theme.palette.primary
  },
  actions: {
    textAlign: "end",
    paddingRight: 0
  },
  actionIcon: {
    "&:hover": {
      color: theme.palette.primary.main
    }
  }
}));

export default function TaskDetails({ course, task, graders, submissions }) {
  //Hooks
  const classes = useStyles();
  const intl = useIntl();
  const history = useHistory();

  // Firestore State
  const currentUser = firebaseApp.auth().currentUser;

  // Ephemeral State
  const [modalVisible, setModalVisible] = useState(false);

  // Derived state

  // Display the status chips

  function getFeedback(submission) {
    if (submission && submission.is_checked) {
      return (
        <Chip
          color="primary"
          className={classes.chip}
          label={"feedback sent"}
          size="small"
        />
      );
    } else {
      if (submission && submission.submission_date) {
        return (
          <Chip
            color="primary"
            variant="outlined"
            className={classes.chip}
            label={"no feedback yet"}
            size="small"
          />
        );
      } else return null;
    }
  }

  function getGrade(submission) {
    const grade = submission.grade ? submission.grade : "";
    if (submission.totalpoints) {
      return `${grade}/${submission.totalpoints}`;
    } else if (submission.is_checked) {
      return intl.formatMessage({
        id: "tasks.see.feedback",
        defaultMessage: "See feedback"
      });
    } else return "-";
  }

  // Render
  return (
    <>
      <TableContainer className={classes.tableContainer}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography color="textSecondary">
                  <FormattedMessage
                    id="tasks.student"
                    defaultMessage="Student"
                  />
                </Typography>
              </TableCell>
              <TableCell>
                <Typography color="textSecondary">
                  <FormattedMessage id="tasks.status" defaultMessage="Status" />
                </Typography>
              </TableCell>
              <TableCell>
                <Typography color="textSecondary">
                  <FormattedMessage
                    id="tasks.feedback"
                    defaultMessage="Feedback"
                  />
                </Typography>
              </TableCell>
              <TableCell>
                <Typography color="textSecondary">
                  <FormattedMessage id="tasks.grade" defaultMessage="Grade" />
                </Typography>
              </TableCell>
              <TableCell>
                <Typography color="textSecondary">
                  <FormattedMessage
                    id="tasks.evaluation"
                    defaultMessage="Evaluation"
                  />
                </Typography>
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {submissions.map(
              submission =>
                !submission.related_submission_id && (
                  <TableRow
                    key={submission.submission_id}
                    className={clsx(
                      classes.tableRow,
                      submission.submission_date && classes.pointer
                    )}
                    onClick={() => {
                      if (submission.is_checked) {
                        history.push(
                          "/adminEvaluation/" + submission.submission_id
                        );
                      } else if (submission.submission_date) {
                        if (
                          graders &&
                          graders[submission.submission_id] &&
                          graders[submission.submission_id].grader !==
                            currentUser.uid
                        ) {
                          setModalVisible(submission);
                        } else {
                          history.push(
                            "/adminEvaluation/" + submission.submission_id
                          );
                        }
                      }
                    }}
                  >
                    <TableCell component="th" scope="row">
                      <Typography>{submission.student_name}</Typography>
                    </TableCell>
                    <TableCell>
                      <TaskStatusChip
                        original_due_date={task.original_due_date}
                        due_date={submission.due_date}
                        submission_date={submission.submission_date}
                        userRole={course.course_role}
                      />
                    </TableCell>
                    <TableCell>{getFeedback(submission)}</TableCell>
                    <TableCell>
                      <Typography>{getGrade(submission)}</Typography>
                    </TableCell>

                    <TableCell>{submission && submission.evaluation}</TableCell>
                  </TableRow>
                )
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
