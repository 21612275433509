import { createSlice, createSelector } from "@reduxjs/toolkit";
import { shallowEqual } from "react-redux";

const initialState = {
  courses: [],
  lastUpdate: 0 // TODO: check if we need this
};
export const coursesSlice = createSlice({
  name: "courses",
  initialState,
  extraReducers: {
    "user/setAuth": (state, value) => {
      // TODO: check if we need this
      state.courses = [];
      state.lastUpdate = 0;
    },
    "user/logout": () => initialState // TODO: check if we need this
  },
  reducers: {
    updateCourses: (state, value) => {
      // TODO: check if we need this
      state.courses = value.payload;
      state.lastUpdate = new Date().getTime();
    },
    setCourses: (state, value) => {
      state.courses = value.payload;
    }
  }
});

export const { updateCourses, setCourses } = coursesSlice.actions;

export const selectCourse = createSelector(
  [state => state.courses.courses, (state, course_id) => course_id],
  (courses, course_id) => courses.find(course => course.id === course_id) || {},
  { memoizeOptions: { resultEqualityCheck: shallowEqual } }
);

export const selectCurrentCourse = createSelector(
  state => state.courses,
  state => state.user.userProfile.selectedCourse.id,
  (courses, selectedCourse) => {
    return courses.find(course => course.id === selectedCourse);
  }
);

export const selectCourseByTextId = createSelector(
  // selectCurrentCourseTimezone returns the timezone of the current selected course
  state => state.texts.texts,
  state => state.courses.courses,
  state => state.texts.selectedTextId,
  (texts, courses, text_id) => {
    const text = texts.find(text => text.id === text_id) || {};
    return courses.find(course => course.id === text.course_id) || {};
  },
  { memoizeOptions: { resultEqualityCheck: shallowEqual } }
);

export const selectCourseByTaskId = createSelector(
  // selectCurrentCourseTimezone returns the timezone of the current selected course
  state => state.tasks.tasks,
  state => state.courses.courses,
  (state, taskId) => taskId,
  (tasks, courses, taskId) => {
    const task = tasks.find(task => task.id === Number(taskId)) || {};
    return courses.find(course => course.id === task.course_id) || {};
  },
  { memoizeOptions: { resultEqualityCheck: shallowEqual } }
);

export default coursesSlice.reducer;
